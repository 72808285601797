import React, { FC } from "react";

//Design imports
import { alpha, Box, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";

//Internal imports
import CoreTooltip from "./CoreTooltip";

interface Props {
  label?: string;
  tooltip: string;
  disabled?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
}

const CoreTooltipInputLabel: FC<Props> = ({
  label,
  tooltip,
  disabled,
  placement = "right",
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: disabled
        ? alpha(theme.palette.text.primary, 0.5)
        : theme.palette.text.primary,
    },
    text: {
      color: disabled
        ? alpha(theme.palette.text.primary, 0.5)
        : theme.palette.text.primary,
    },
    infoIcon: { marginLeft: "10px", fontSize: "20px" },
  });

  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      {label && <Typography className={classes.text}>{label}</Typography>}

      <CoreTooltip title={tooltip} placement={placement}>
        <InfoOutlinedIcon className={classes.infoIcon} />
      </CoreTooltip>
    </Box>
  );
};

export default CoreTooltipInputLabel;
