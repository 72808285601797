import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CSSProperties, createStyles, makeStyles } from "@mui/styles";

import { observer } from "mobx-react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { CoreCell } from "./CoreCell";
import { HOVER_COLUMNS } from "../../../types/constants";
import {
  TableCellStyleDef,
  TableContentDef,
  TableHeaderDef,
  TableRowStyleDef,
} from "../../../types/interfaces";

interface Props {
  headers?: TableHeaderDef[];
  content: TableContentDef[];
  rowsNumber: number;
  columnWidth?: TableContentDef;
  rowHeight?: TableContentDef;
  handleRowClick?: (event: React.MouseEvent<unknown>, id: string) => void;
  hoverActions?: boolean;
  noVisibleCells?: number; // Number of columns to display once the row is hovered (if hoverActions is enabled)
  customRowStyles?: TableRowStyleDef; // Style applied per row
  customCellStyle?: TableCellStyleDef; // Style applied per cell
  globalCellStyle?: CSSProperties; // Style applied to all cells
  onHoverRow?: boolean;
}

const CoreBody: FC<Props> = observer(
  ({
    headers,
    content,
    rowsNumber,
    handleRowClick,
    columnWidth = {},
    rowHeight = {},
    hoverActions = false,
    noVisibleCells = 1,
    customRowStyles = {},
    customCellStyle = {},
    globalCellStyle = {},
    onHoverRow = true,
  }) => {
    const { t } = useTranslation("flow");
    const [hoveredRowIndex, setShowActions] = React.useState<number | null>(
      null
    );

    const useStyles = makeStyles(() =>
      createStyles({
        message: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        noDataCell: {
          borderBottom: "none",
          paddingBottom: "3rem",
          paddingTop: "2rem",
        },
      })
    );

    const classes = useStyles();

    const handleMouse = (index: number | null) => {
      if (hoverActions) setShowActions(index);
    };

    return (
      <>
        <TableBody>
          {content.length === 0 ? (
            <TableRow>
              <TableCell colSpan={rowsNumber} className={classes.noDataCell}>
                <Typography className={classes.message}>
                  {t("noDataToDisplay")}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            content.map((row, rowIndex) => (
              <TableRow
                key={`${Object.values(row)[0] as string}-${rowIndex}`}
                hover={onHoverRow}
                sx={customRowStyles?.[rowIndex] || {}}
                onClick={
                  handleRowClick
                    ? (event) =>
                        handleRowClick(
                          event,
                          (row.id || row.identifier) as string
                        )
                    : undefined
                }
                onMouseEnter={() => handleMouse(rowIndex)}
                onMouseLeave={() => handleMouse(null)}
              >
                {headers?.map((headerDef, index, list) => {
                  const isRowHovered = hoveredRowIndex === rowIndex;
                  const cellStyle =
                    {
                      ...globalCellStyle,
                      ...customCellStyle?.[rowIndex]?.[headerDef.accessor],
                    } || {};

                  if (
                    hoverActions && // row hovering is enabled
                    isRowHovered && // the current row iteration is the one hovered
                    !(index < noVisibleCells) && // index is not within noVisibleCells range
                    index !== list.length - 1 // index is not of the last element (which will display the actions)
                  ) {
                    return (
                      <TableCell
                        key={`${headerDef.accessor}-${index}`}
                        sx={cellStyle}
                      ></TableCell>
                    );
                  }

                  // Send 'actions' cell accessor to display row actions instead of the original cell content (if row is hovered)
                  const cellAccessor =
                    isRowHovered && index === list.length - 1
                      ? HOVER_COLUMNS.actions
                      : headerDef.accessor;

                  return (
                    <CoreCell
                      cell={cellAccessor}
                      row={row}
                      key={`${headerDef.accessor}-${index}`}
                      columnWidth={columnWidth}
                      rowHeight={rowHeight}
                      styleProps={cellStyle}
                    />
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </>
    );
  }
);

export default CoreBody;
