import React, { FC } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { ConnectionField, FormData } from "../../../types/interfaces";
import { ConnectionConfigRenderer } from "./ConnectionConfigRenderer";
import { TFunction } from "i18next";
import { Box } from "@mui/material";

interface Props {
  config: ConnectionField[];
  translation: TFunction;
  onChange: (value: FormData) => void;
  data: FormData;
  errors: FormData;
  id?: string;
  disableForm?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  })
);

export const ConnectionForm: FC<Props> = ({
  config,
  translation,
  onChange,
  data,
  errors,
  id,
  disableForm,
}) => {
  const classes = useStyles();

  const onFormChange = (value: FormData) => {
    onChange(value);
  };

  return (
    <Box className={classes.formContainer}>
      <ConnectionConfigRenderer
        config={config}
        data={data}
        translation={translation}
        onChange={onFormChange}
        errors={errors}
        id={id}
        disableForm={disableForm}
        forConnections={true}
      />
    </Box>
  );
};
