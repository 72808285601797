import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import CoreDrawer from "../../../../core/CoreDrawer";
import CoreButton from "../../../../core/CoreButton";
import { useNotification } from "../../../../../context/useNotification";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreInput from "../../../../core/CoreInput";
import { GlobalEventType } from "../../../../../types/types";
import { emailRegex } from "../../../../../types/regex";

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "30px",
  },
  formInput: {
    margin: "0px 0px 10px 0px",
  },
});

interface Props {
  isOpen: boolean;
  planId: string; // Plan for which this contact form is available
  planName: string;
  onClose: () => void;
}

const DEFAULT_FORM_STATE = {
  email: "",
  phone: "",
  name: "",
  message: "",
};

export const ContactSalesDrawer: FC<Props> = ({
  planId,
  planName,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("subscription");
  const notification = useNotification();
  const { subscriptionStore } = useStores();

  const [isOperationInProgress, toggleProgress] = useState(false);
  const [form, setForm] = useState(DEFAULT_FORM_STATE);
  const [formErrors, setFormErrors] = useState<{
    [key: string]: string | undefined;
  }>({});

  const isContactSalesBtnDisabled = useMemo(
    () =>
      isOperationInProgress ||
      form?.name?.trim() === "" ||
      form?.email?.trim() === "" ||
      form?.message?.trim() === "",
    [isOperationInProgress, form?.name, form?.email, form?.message]
  );

  const handleUpgrade = () => {
    if (!emailRegex.test(form?.email)) {
      setFormErrors({ email: t("contact_sales_drawer_email_invalid") });
      return;
    }

    setFormErrors({});
    toggleProgress(true);

    subscriptionStore
      .sendContactSalesMessage(
        form?.email,
        t("contact_sales_drawer_email_subject"),
        { ...form, planId, planName }
      )
      .then(() => {
        notification.success(t("contact_sales_drawer_email_send_success"));
        onClose();
        toggleProgress(false);
        setForm(DEFAULT_FORM_STATE);
      })
      .catch((error: Error) => {
        notification.error(
          t(error?.message || "contact_sales_drawer_email_send_fail")
        );
        toggleProgress(false);
      });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = event as GlobalEventType;

    setForm({ ...form, [name]: value });
  };

  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={() => onClose()}
      title={t("contact_sales_drawer_title")}
    >
      <CoreInput
        title={t("contact_sales_drawer_name_field_title")}
        onChange={handleFieldChange}
        name="name"
        errorText={formErrors?.name}
        value={form?.name}
        isMandatory
        formControlClass={classes.formInput}
        disabled={isOperationInProgress}
      />

      <CoreInput
        title={t("contact_sales_drawer_email_field_title")}
        onChange={handleFieldChange}
        name="email"
        errorText={formErrors?.email}
        value={form?.email}
        isMandatory
        formControlClass={classes.formInput}
        disabled={isOperationInProgress}
      />

      <CoreInput
        title={t("contact_sales_drawer_phone_field_title")}
        onChange={handleFieldChange}
        type="number"
        name="phone"
        errorText={formErrors?.phone}
        value={form?.phone}
        formControlClass={classes.formInput}
        disabled={isOperationInProgress}
        inputProps={{ inputMode: "numeric", min: 0 }}
      />

      <CoreInput
        multiline
        rows={4}
        title={t("contact_sales_drawer_message_field_title")}
        onChange={handleFieldChange}
        name="message"
        errorText={formErrors?.message}
        value={form?.message}
        formControlClass={classes.formInput}
        disabled={isOperationInProgress}
        isMandatory
      />

      <Box className={classes.footer}>
        <CoreButton onClick={() => onClose()} disabled={isOperationInProgress}>
          {t("contact_sales_drawer_close_btn")}
        </CoreButton>

        <CoreButton
          variant="contained"
          onClick={handleUpgrade}
          disabled={isContactSalesBtnDisabled}
          isLoading={isOperationInProgress}
        >
          {t("contact_sales_drawer_send_btn")}
        </CoreButton>
      </Box>
    </CoreDrawer>
  );
};
