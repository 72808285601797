import React from "react";

import { Divider, MenuList, Box, useTheme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlowSettingsMenuContainer from "./FlowSettingsMenuContainer";
import { useStores } from "../../../../stores/StoresProvider";
import {
  FLOW_SETTINGS_TABS,
  FLOW_SETTINGS_CATEGORY,
} from "../../../../types/constants";

const FlowSettingsMenu = () => {
  const theme = useTheme();
  const { flowSettingsStore } = useStores();
  const useStyles = makeStyles({
    container: {
      padding: 0,
      marginTop: "64px",
      position: "relative",
      width: "225px",
      borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    menuList: {
      padding: 0,
      marginBottom: "8px",
      width: "225px",
      paddingTop: "3px",
    },
  });

  const createMenuItem = (name: string) => ({
    name,
    onClick: () => {
      if (
        flowSettingsStore.selectedTab === FLOW_SETTINGS_TABS.designer &&
        name !== FLOW_SETTINGS_TABS.designer &&
        flowSettingsStore.diagramHasBeenUpdated
      ) {
        flowSettingsStore.setPendingTab(name);
        return;
      }

      flowSettingsStore.setSelectedTab(name);
    },
  });

  const MENU_ITEMS = [
    {
      menuItems: [
        createMenuItem(FLOW_SETTINGS_TABS.overview),
        createMenuItem(FLOW_SETTINGS_TABS.accessControl),
      ],
    },
    {
      category: FLOW_SETTINGS_CATEGORY.developmentTools,
      menuItems: [
        createMenuItem(FLOW_SETTINGS_TABS.designer),
        createMenuItem(FLOW_SETTINGS_TABS.versions),
        createMenuItem(FLOW_SETTINGS_TABS.dataSource),
        createMenuItem(FLOW_SETTINGS_TABS.dataExport),
      ],
    },
    {
      category: FLOW_SETTINGS_CATEGORY.repositoryTools,
      menuItems: [
        createMenuItem(FLOW_SETTINGS_TABS.repository),
        createMenuItem(FLOW_SETTINGS_TABS.rejectReasons),
        createMenuItem(FLOW_SETTINGS_TABS.accessKeys),
      ],
    },
    {
      category: FLOW_SETTINGS_CATEGORY.monitoring,
      menuItems: [
        createMenuItem(FLOW_SETTINGS_TABS.alerts),
        createMenuItem(FLOW_SETTINGS_TABS.logs),
        createMenuItem(FLOW_SETTINGS_TABS.metrics),
      ],
    },
  ];

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <MenuList className={classes.menuList}>
        {MENU_ITEMS.map((item, index) => {
          return (
            <div key={index}>
              {index > 0 && <Divider />}
              <FlowSettingsMenuContainer
                key={index}
                category={item.category || undefined}
                options={item.menuItems}
              />
            </div>
          );
        })}
      </MenuList>
    </Box>
  );
};

export default FlowSettingsMenu;
