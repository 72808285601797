import { TFunction } from "i18next";
import React, { FC } from "react";

import { CheckboxProps } from "@mui/material";

import { FormField, FormData } from "../../types/interfaces";
import { FormFieldProps } from "../../types/types";
import CoreCheckbox from "../core/CoreCheckbox";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  description?: string;
  otherProps?: FormFieldProps;
}

export const CheckboxRenderer: FC<Props> = ({
  translation,
  value = false,
  onChange,
  field,
  otherProps,
  description,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { [field.key]: event.target.checked };
    onChange(newValue);
  };

  return (
    <CoreCheckbox
      onChange={handleChange}
      checked={value as boolean}
      label={translation(field.translationKey) || field.name}
      labelPlacement={field.labelPlacement}
      description={translation(description || "")}
      {...(otherProps as CheckboxProps)}
    />
  );
};
