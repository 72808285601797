import React, { FC, useCallback } from "react";
import { TFunction } from "i18next";
import { useDropzone } from "react-dropzone";
import { observer } from "mobx-react";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";

import { Theme, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { DefaultTheme, makeStyles, Styles } from "@mui/styles";

import CoreButton from "../../../core/CoreButton";
import { ExtendedFile, FlowDiagram } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";
import {
  useNotification,
  NotificationTypes,
} from "../../../../context/useNotification";
import NodesHelper from "./helper/nodesHelper";
import CoreNotificationBox from "../../../core/CoreNotificationBox";

interface Props {
  t: TFunction;
  isOpen: boolean;
  handleClose: () => void;
}

const getStyles = (theme: Theme) => {
  return {
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "35%",
      minWidth: "800px",
      height: "600px",
      alignSelf: "center",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    divider: {
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      "@media screen and (max-width:1200px)": {
        overflow: "auto",
        display: "block",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "5px",
      height: "50%",
    },
    modalHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: "1.5rem",
      paddingRight: "20px",
      paddingLeft: "30px",
    },
    dragAndDropBox: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.neutral.main,
      border: "5px dashed",
      borderColor: theme.palette.secondary.main,
      justifyContent: "center",
      flexDirection: "column",
      opacity: "0.4",
    },
    dropTitle: {
      fontSize: "1.5rem",
    },
    dropIcon: {
      fontSize: "8.5rem",
    },
    modalFooter: {
      width: "100%",
      height: "100%",
      maxHeight: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "20px 20px 20px 0",
    },
    paper: {
      minHeight: "50%",
      width: "70%",
      minWidth: "300px",
      display: "block",
    },
    boxSupportedFiles: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      opacity: "0.4",
      marginTop: "20px",
    },
    supportedFiles: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    boxDnDText: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2rem",
    },
    btnUpload: {
      marginTop: "auto",
      alignSelf: "flex-end",
    },
    modal: {
      display: "flex",
      justifyContent: "center",
    },
    boxFile: {
      marginTop: "2rem",
      width: "100%",
      height: "100%",
      display: "flex",
      padding: "0rem 2rem",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
    },
    selectedText: {
      alignSelf: "center",
      margin: "30px 30px 0 30px",
    },
    contentBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    infoBox: {
      display: "flex",
      padding: "10px 15px 0 15px",
    },
    iconAndName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
  } as Styles<DefaultTheme, object, string>;
};

export const ImportDiagramModal: FC<Props> = observer(
  ({ isOpen, t, handleClose }) => {
    const theme = useTheme();
    const useStyles = makeStyles(getStyles(theme));

    const { flowStore, flowSettingsStore } = useStores();
    const notification = useNotification();
    const classes = useStyles();

    const [files, setFiles] = React.useState<ExtendedFile[]>([]);
    const [isOnDrop, setIsOnDrop] = React.useState(false);

    const { getRootProps, getInputProps, open } = useDropzone({
      multiple: false,
      noClick: true,
      noKeyboard: true,
      onDrop: (newFiles: File[]) => {
        if (newFiles.length > 0) {
          setFiles([
            ...files,
            ...newFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ]);
        } else {
          notification.error(t("fileTypeError"));
        }
        setIsOnDrop(false);
      },
      onDragEnter: () => setIsOnDrop(true),
      onDragLeave: () => setIsOnDrop(false),
      accept: {
        "application/json": [".json"],
      },
    });

    const discardFiles = () => {
      handleClose();
      setFiles([]);
    };

    const getJsonText = useCallback(async () => {
      const text = await new Response(files[0]).text();

      return text;
    }, [files]);

    const handleConfirm = () => {
      getJsonText()
        .then((text) => {
          const diagram = JSON.parse(text) as unknown as FlowDiagram;
          const isValid = NodesHelper.validateDiagram(diagram);
          if (isValid) {
            const hasConnectionId = diagram?.specs?.nodes?.some(
              (node) => node?.parameters?.connectionId
            );

            if (hasConnectionId) {
              flowSettingsStore.checkConnectionErrors(diagram?.specs?.nodes);
            }

            flowSettingsStore.setDiagram(diagram);
            flowSettingsStore.setDiagramHasBeenUpdated(true);
            notification.success(t("diagramImportSuccess"));
            discardFiles();
            flowSettingsStore
              .loadContextObjects({
                nodes: diagram?.specs?.nodes || [],
                edges: diagram?.specs?.edges || [],
              })
              .catch((error: Error) => {
                notification.error(t(error?.message || "contextObjectsError"));
              });
          } else {
            notification.error(t("diagramInvalid"));
          }
        })
        .catch(() => {
          notification.error(t("fileNotValid"));
        });
    };

    return (
      <Modal className={classes.modal} open={isOpen} onClose={discardFiles}>
        <Box className={classes.main} {...getRootProps()}>
          <input {...getInputProps()} />

          <Paper className={classes.paper}>
            <Box className={classes.mainContainer}>
              <div className={classes.modalHeader}>
                <Typography variant="h6">{t("uploadDiagram")}</Typography>

                <IconButton onClick={discardFiles}>
                  <CloseOutlinedIcon />
                </IconButton>
              </div>

              <Divider className={classes.divider} />

              {files.length === 0 ? (
                <Box className={classes.contentContainer}>
                  {isOnDrop ? (
                    <Box
                      className={classes.dragAndDropBox}
                      style={{ width: "100%" }}
                    >
                      <CloudUploadOutlinedIcon className={classes.dropIcon} />
                      <Typography className={classes.dropTitle}>
                        {t("dropDocMessage")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box className={classes.uploadBox}>
                      <Box className={classes.boxDnDText}>
                        <Typography variant="h6">{t("dragAndDrop")}</Typography>
                        <Typography variant="h6">{t("or")}</Typography>
                        <CoreButton
                          className={classes.btnUpload}
                          onClick={open}
                          variant="outlined"
                          disabled={flowStore.isDocumentUploading}
                        >
                          {t("browseFiles")}
                        </CoreButton>
                      </Box>
                      <Box className={classes.boxSupportedFiles}>
                        <Typography
                          variant="caption"
                          className={classes.supportedFiles}
                        >
                          {t("supportedFiles")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                files?.length === 1 && (
                  <Box className={classes.contentBox}>
                    <Box className={classes.infoBox}>
                      <CoreNotificationBox
                        description={t("importDiagramInfo")}
                        type={NotificationTypes.info}
                      />
                    </Box>

                    <Box className={classes.boxFile}>
                      <Box className={classes.iconAndName}>
                        <DataObjectIcon />

                        <Typography fontSize="14px">{files[0].name}</Typography>
                      </Box>

                      <IconButton onClick={() => setFiles([])}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                )
              )}

              <Box className={classes.modalFooter}>
                <CoreButton
                  onClick={handleConfirm}
                  variant="contained"
                  disabled={
                    flowStore.isDocumentUploading || files?.length === 0
                  }
                  isLoading={flowStore.isDocumentUploading}
                >
                  {t("confirm")}
                </CoreButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    );
  }
);
