import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { useStores } from "../../../../../../stores/StoresProvider";
import {
  MAIN_FIELDS,
  getBillingInformationFields,
  getCompanyInformationFields,
} from "../resources/billingFields";
import { BillingCardRender } from "../BillingCardRender";

export const BillingInformation = observer(() => {
  const { t } = useTranslation("subscription");
  const { subscriptionStore } = useStores();

  const allFields = useMemo(
    () => [
      ...MAIN_FIELDS,
      ...getBillingInformationFields(subscriptionStore.countries),
    ],
    [subscriptionStore.countries]
  );

  const companyFields = useMemo(
    () =>
      getCompanyInformationFields(
        subscriptionStore.countries,
        !!subscriptionStore.flatBillingCompany?.["name"] &&
          subscriptionStore.flatBillingCompany?.["name"]?.trim() !== ""
      ),
    [subscriptionStore.countries, subscriptionStore.flatBillingCompany]
  );

  const updateData = useCallback(
    (data: { [key: string]: string }) => {
      subscriptionStore.setFlattenCustomerData(data);
    },
    [subscriptionStore]
  );

  const updateCompanyData = useCallback(
    (data: { [key: string]: string }) => {
      subscriptionStore.setFlattenCompanyData(data);
    },
    [subscriptionStore]
  );

  return (
    <>
      <BillingCardRender
        translate={t}
        disabled={subscriptionStore.changeSubscriptionInProgress}
        form={subscriptionStore.flatBillingCustomer}
        errors={subscriptionStore.flatBillingErrors}
        fields={allFields}
        onFormUpdate={updateData}
      />

      <BillingCardRender
        translate={t}
        disabled={subscriptionStore.changeSubscriptionInProgress}
        form={subscriptionStore.flatBillingCompany}
        errors={subscriptionStore.flatCompanyErrors}
        fields={companyFields}
        onFormUpdate={updateCompanyData}
      />
    </>
  );
});
