import React from "react";
import { TFunction } from "i18next";

import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
  FormData,
  FormField,
  SelectOptionDef,
  Variable,
} from "../../types/interfaces";
import CoreTooltip from "../core/CoreTooltip";
import { FormFieldProps } from "../../types/types";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES, DYNAMIC_FIELDS } from "../../types/constants";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.dark,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

interface Props {
  translation: TFunction;
  field: FormField;
  onChange: (value: FormData, key: string) => void;
  value: unknown;
  formData: FormData;
  variables?: Variable[];
  otherProps?: FormFieldProps;
  errorText?: string;
  fullWidth?: boolean;
  inputTitleSize?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
}

export const FieldSelectRenderer: React.FC<Props> = ({
  field,
  onChange,
  translation,
  value = "",
  variables,
  otherProps = {},
  errorText,
  fullWidth = true,
  inputTitleSize,
  description,
  tooltipLocation = "title",
}) => {
  const { mainStore } = useStores();

  const title = field.name;
  let options = field.options ?? [];
  const theme = useTheme();
  const { currentTheme } = mainStore;

  const classes = makeStyles({
    infoContainer: {
      display: "flex",
      alignItems: "center",
    },
    description: {
      marginLeft: "5px",
      fontSize: "20px",
      marginBottom: "3px",
    },
    icon: {
      alignSelf: "center",
      marginLeft: "15px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    formText: {
      margin: "auto 0",
      height: "auto",
    },
    autocompleteBox: {
      display: "flex",
      width: "100%",
    },
    "@global": {
      ".MuiAutocomplete-root .MuiAutocomplete-tag": {
        backgroundColor:
          theme.palette.mode === THEME_MODES.light
            ? theme.palette.text.primary
            : undefined,
      },
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
  })();

  if (variables) {
    variables?.forEach((field) => {
      options.push({
        key: field.key,
        label: field.name,
        type: DYNAMIC_FIELDS.flowFields,
      });
    });
  }

  const { disabled } = otherProps;

  const getValue = () => {
    if (field?.isMultipleSelect) return (value as SelectOptionDef[]) ?? [];
    return (value as string) ?? "";
  };

  options = options.sort((a, b) =>
    a?.type?.localeCompare(b?.type as string) ? 1 : -1
  );

  return (
    <FormControl size="small" error={!!errorText} fullWidth={fullWidth}>
      <Box className={classes.infoContainer}>
        {title && (
          <Typography
            variant="subtitle2"
            style={{ fontSize: inputTitleSize }}
            color={
              errorText
                ? currentTheme === THEME_MODES.light
                  ? theme.palette.error.main
                  : theme.palette.error.light
                : theme.palette?.text.primary
            }
          >
            {title}
            {field.isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
        )}
        {title && description && tooltipLocation === "title" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.description} />
          </CoreTooltip>
        )}
      </Box>

      <Box className={classes.autocompleteBox}>
        <Autocomplete
          disabled={disabled}
          multiple={field?.isMultipleSelect}
          value={getValue()}
          freeSolo
          groupBy={(option) => option.type ?? ""}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          onChange={(_, newValue) => {
            onChange(
              {
                [field.key]: newValue,
              },
              field.key
            );
          }}
          options={options ?? []}
          clearOnBlur
          sx={{ width: "100%" }}
          selectOnFocus
          renderOption={(props, option) => {
            const opt = option;
            return (
              <li {...props} key={opt.key}>
                <Typography noWrap>{opt.label}</Typography>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={
                (value as SelectOptionDef[])?.length > 0
                  ? ""
                  : translation("selectUniqueKey")
              }
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{translation(params.group)}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
        {title && description && tooltipLocation === "input" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.icon} />
          </CoreTooltip>
        )}
      </Box>
      {errorText && (
        <Box className={classes.boxError}>
          <CancelOutlinedIcon className={classes.cancelIcon} />

          <FormHelperText error={!!errorText} className={classes.formText}>
            {errorText}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};
