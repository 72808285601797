import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { StatusDef } from "../../types/interfaces";
import CoreTooltip from "./CoreTooltip";
import { CoreStatusTag } from "./CoreStatusTag";

const useStyles = makeStyles({
  statusBox: {
    padding: "3px",
  },
  statusBoxError: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
  },
});

interface Props {
  status: StatusDef;
}

/**
 * Component used in displaying the status in a table (see Flow details page)
 * @returns
 */
export const CoreStatusCell: FC<Props> = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation("flow");

  return (
    <CoreTooltip title={status.details.message || ""}>
      <Box
        className={classNames([
          classes.statusBox,
          status.type === "error" && classes.statusBoxError,
        ])}
      >
        <CoreStatusTag label={t(status.type)} type={status.type} />
      </Box>
    </CoreTooltip>
  );
};
