import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import {
  CircularProgress,
  Typography,
  Box,
  ListItem,
  ListItemButton,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CoreInput from "../../../../core/CoreInput";
import { useStores } from "../../../../../stores/StoresProvider";
import DiagramNodeIcon from "../diagramBuilder/DiagramNodeIcon";

interface Props {
  t: TFunction;
  onSelect: (key: string) => void;
}

const ApprovalRulesDisplay: React.FC<Props> = observer(({ t, onSelect }) => {
  const { flowSettingsStore } = useStores();
  const [searchFilter, setSearchFilter] = useState("");

  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "15px",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "25px",
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      marginTop: "10px",
    },
    noSetups: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "20px",
    },
    search: {
      width: "100%",
      marginTop: "1rem",
    },
    card: {
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      border: "1px solid rgba(217,217,217,0.2)",
      height: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        border: `1px solid`,
        cursor: "pointer",
      },
    },
    highlight: {
      color: theme.palette.highlight.main,
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    iconTextBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: 0,
    },
    iconStyle: {
      fontSize: "25px",
    },
    svgIconStyle: {
      width: "25px",
    },
    iconAndText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
    text: {
      display: "flex",
      flexDirection: "column",
    },
  });

  const filteredOptions = useMemo(
    () =>
      flowSettingsStore.approvalRules?.filter((item) =>
        item.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
      ),
    [searchFilter, flowSettingsStore]
  );

  const classes = useStyles();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
  };

  return (
    <Box className={classes.container}>
      <Typography>{t("selectRule")}</Typography>

      <CoreInput
        onChange={onSearchChange}
        className={classes.search}
        placeholder={t("searchRule")}
      />

      {flowSettingsStore.loadingRules ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box className={classes.optionsContainer}>
          {filteredOptions && filteredOptions?.length > 0 ? (
            filteredOptions?.map(
              (item) =>
                item && (
                  <ListItem key={item?.key} style={{ padding: "8px 0px" }}>
                    <ListItemButton
                      className={classes.card}
                      onClick={() => onSelect(item?.key)}
                    >
                      <Box className={classes.iconAndText}>
                        <DiagramNodeIcon
                          nodeKey={item?.key}
                          textBoxStyle={classes.iconTextBox}
                          iconStyle={classes.iconStyle}
                          svgIconStyle={classes.svgIconStyle}
                        />
                        <Box className={classes.text}>
                          <Typography variant="h6">{item?.name}</Typography>

                          <Typography className={classes.highlight}>
                            {item?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                )
            )
          ) : (
            <Box className={classes.noSetups}>
              <Typography>{t("noRules")}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});

export default ApprovalRulesDisplay;
