import { gql } from "@apollo/client";
import { DocumentLineItem } from "../../types/interfaces";

export interface MUTATION_UPDATE_DOCUMENT_RESPONSE {
  updateDocument: boolean;
}

export interface MUTATION_REJECT_DOCUMENT_RESPONSE {
  rejectDocument: boolean;
}
export interface MUTATION_RETRY_DOCUMENT_RESPONSE {
  retryDocument: boolean;
}

export interface MUTATION_RETRY_DOCUMENTS_RESPONSE {
  retryDocuments: boolean;
}

export interface MUTATION_RERUN_DOCUMENTS_RESPONSE {
  rerunDocuments: boolean;
}

export interface MUTATION_GET_SIMILAR_TEMPLATES_RESPONSE {
  getSimilarTemplates: { [key: string]: string[] };
}

export interface MUTATION_GET_SIMILAR_DOCUMENTS_RESPONSE {
  getSimilarDocuments: { [key: string]: number };
}

export interface MUTATION_DELETE_DOCUMENTS_RESPONSE {
  deleteDocuments: boolean;
}

export interface MUTATION_MOVE_DOCUMENTS_RESPONSE {
  moveDocuments: boolean;
}

export interface MUTATION_REORDER_DOCUMENT_PAGES_RESPONSE {
  reorderDocumentPages: boolean;
}

export interface MUTATION_EXTRACT_DOCUMENT_PAGES_RESPONSE {
  extractDocumentPages: boolean;
}

export interface MUTATION_DELETE_DOCUMENT_PAGES_RESPONSE {
  deleteDocumentPages: boolean;
}

export interface MUTATION_UNLOCK_DOCUMENT_RESPONSE {
  unlockDocument: boolean;
}

export interface MUTATION_LINE_ITEMS_BORDER_TABLE_DETECT_RESPONSE {
  extractLineItem: DocumentLineItem[];
}

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($documentId: String!, $data: JSON!, $state: String) {
    updateDocument(documentId: $documentId, data: $data, state: $state)
  }
`;
export const REJECT_DOCUMENT = gql`
  mutation rejectDocument(
    $documentId: String!
    $code: Int!
    $message: String!
    $state: String
  ) {
    rejectDocument(
      documentId: $documentId
      code: $code
      message: $message
      state: $state
    )
  }
`;
export const RETRY_DOCUMENT = gql`
  mutation retryDocument($documentId: String!) {
    retryDocument(documentId: $documentId)
  }
`;

export const RETRY_DOCUMENTS = gql`
  mutation retryDocuments(
    $documents: [String]
    $status: String
    $flowId: String
  ) {
    retryDocuments(documents: $documents, status: $status, flowId: $flowId)
  }
`;

export const RERUN_DOCUMENTS = gql`
  mutation rerunDocuments($documents: [String], $statuses: [String]) {
    rerunDocuments(documents: $documents, statuses: $statuses)
  }
`;

export const GET_SIMILAR_DOCUMENTS = gql`
  mutation getSimilarDocuments($documents: [String]) {
    getSimilarDocuments(documents: $documents)
  }
`;

export const GET_SIMILAR_TEMPLATES = gql`
  mutation getSimilarTemplates($flowId: String!, $documents: [String]) {
    getSimilarTemplates(flowId: $flowId, documents: $documents)
  }
`;

export const MOVE_DOCUMENTS = gql`
  mutation moveDocuments($documents: [String!]!, $destinationFlow: String!) {
    moveDocuments(documents: $documents, destinationFlow: $destinationFlow)
  }
`;

export const DELETE_DOCUMENTS = gql`
  mutation deleteDocuments($documents: [String!]!) {
    deleteDocuments(documents: $documents)
  }
`;

export const REORDER_DOCUMENT_PAGES = gql`
  mutation reorderDocumentPages(
    $documentId: String!
    $pages: [ReorderedDocumentPages!]!
  ) {
    reorderDocumentPages(documentId: $documentId, pages: $pages)
  }
`;

export const EXTRACT_DOCUMENT_PAGES = gql`
  mutation extractDocumentPages(
    $documentId: String!
    $pages: [String!]!
    $targetFlow: String!
  ) {
    extractDocumentPages(
      documentId: $documentId
      pages: $pages
      targetFlow: $targetFlow
    )
  }
`;

export const DELETE_DOCUMENT_PAGES = gql`
  mutation deleteDocumentPages($documentId: String!, $pages: [String!]!) {
    deleteDocumentPages(documentId: $documentId, pages: $pages)
  }
`;

export const UNLOCK_DOCUMENT = gql`
  mutation unlockDocument($documentId: String!) {
    unlockDocument(documentId: $documentId)
  }
`;

export const LINE_ITEMS_BORDER_TABLE_DETECT = gql`
  mutation extractLineItem(
    $documentId: String!
    $pageIdentifier: String!
    $coords: JSON
  ) {
    extractLineItem(
      documentId: $documentId
      pageIdentifier: $pageIdentifier
      coords: $coords
    )
  }
`;
