//Library imports
import React, { FC, memo } from "react";

//Design imports
import { ListItem, ListItemButton, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { User } from "../../../../../../types/interfaces";
interface UserSetupProps {
  user: User;
  users: User[];
  handleClick: (user: User) => void;
  isLoading: boolean;
}

const UserSetup: FC<UserSetupProps> = ({
  user,
  users,
  handleClick,
  isLoading,
}) => {
  const isSelected = users.includes(user);
  const theme = useTheme();
  const classes = makeStyles({
    card: {
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "flex-start",
      border: isSelected
        ? `1px solid ${theme.palette.highlight.main}`
        : "1px solid transparent",
      "&:hover": {
        border: `1px solid`,
        cursor: "pointer",
        backgroundColor: theme.palette.primary.dark,
      },
    },
    highlight: {
      color: theme.palette.highlight.main,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    listItemStyle: {
      padding: "8px 0 8px 0",
    },
  })();

  return (
    <ListItem className={classes.listItemStyle}>
      <ListItemButton
        className={classes.card}
        onClick={() => handleClick(user)}
        disabled={isLoading}
      >
        <Typography>{`${user.first_name} ${user.last_name}`}</Typography>

        <Typography className={classes.highlight}>{user.email}</Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default memo(UserSetup);
