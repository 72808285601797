import React, { FC, memo, useState, useEffect, useMemo } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useStores } from "../../../../../../../stores/StoresProvider";
import { SubscriptionReceipt } from "../../../../../../../types/interfaces/subscription";
import { TableContentDef } from "../../../../../../../types/interfaces";
import CoreTable from "../../../../../../core/CoreTable";
import { backendRoutes } from "../../../../../../../configs/backendRoutes";
import BillingHelper from "../../../../../../../helper/billingHelper";
import { useNotification } from "../../../../../../../context/useNotification";

const useStyles = makeStyles({
  progressContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "100px",
  },
  tableContainer: {
    margin: "30px 60px",
  },
});

const TABLE_HEADERS = [
  {
    accessor: "date",
    label: "receipt_table_col_date",
  },
  {
    accessor: "amount",
    label: "receipt_table_col_amount",
  },
  {
    accessor: "status",
    label: "receipt_table_col_status",
  },
  {
    accessor: "card",
    label: "receipt_table_col_card",
  },
  {
    accessor: "id",
    label: "receipt_table_col_downloadLink",
  },
];

const Component: FC<{ translate: TFunction }> = ({ translate }) => {
  const classes = useStyles();
  const notifier = useNotification();
  const { subscriptionStore } = useStores();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [receipts, setReceipts] = useState<SubscriptionReceipt[]>([]);
  const [downloadingReceiptId, setDownloadingReceiptId] = useState<
    string | null
  >(null);

  const tableHeaders = useMemo(
    () =>
      TABLE_HEADERS.map((item) => ({ ...item, label: translate(item.label) })),
    [translate]
  );

  const tableData = useMemo(
    () =>
      receipts && receipts?.length > 0
        ? receipts.map((receipt) => ({
            ...receipt,
            date: BillingHelper.formatTimestamp(
              receipt.date as unknown as number
            ),
            id: (
              <IconButton
                aria-label="download_receipts"
                disabled={downloadingReceiptId === receipt.id}
                onClick={() => downloadReceipt(receipt.id, receipt.date)}
              >
                {downloadingReceiptId === receipt.id ? (
                  <CircularProgress size="24px" />
                ) : (
                  <FileDownloadIcon />
                )}
              </IconButton>
            ),
            amount: BillingHelper.convertSumToCurrency(
              receipt.amount,
              receipt.currency
            ),
            card: receipt.card
              ? `${translate("hiddenCardNumber")} ${receipt.card}`
              : "-",
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receipts, downloadingReceiptId]
  );

  useEffect(() => {
    subscriptionStore
      .getReceipts()
      .then((list) => {
        setReceipts(list);
        setIsDataLoading(false);
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  }, [subscriptionStore]);

  const downloadReceipt = (reportId: string, reportDate: string) => {
    setDownloadingReceiptId(reportId);

    void BillingHelper.downloadFile(
      backendRoutes.DownloadReceipt(
        reportId,
        subscriptionStore.billingCustomer?.id as string
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${translate("receiptTitle")} - ${reportDate}.pdf`;
        a.click();
        setDownloadingReceiptId(null);
      })
      .catch(() => {
        notifier.error(translate("usage_history_download_receipt_error"));
        setDownloadingReceiptId(null);
      });
  };

  if (isDataLoading) {
    return (
      <Box className={classes.progressContainer}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.tableContainer}>
      <CoreTable
        headers={tableHeaders}
        isLoading={false}
        data={tableData as unknown as TableContentDef[]}
        currentPage={0}
        numberOfPages={0}
      />
    </Box>
  );
};

export const Receipts = memo(Component);
