import React, { FC, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";

import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DiagramNodeHandle from "./DiagramNodeHandle";
import { useStores } from "../../../../../stores/StoresProvider";
import DiagramNodeActions from "./DiagramNodeActions";
import DiagramNodeLabel from "./DiagramNodeLabel";
import {
  NODE_TYPES,
  NODE_HANDLE_TYPES,
  NODE_ASSET_TYPE,
} from "../../../../../types/constants";
import { NodeData } from "../../../../../types/interfaces";
import NodesHelper from "../helper/nodesHelper";

interface NodeProps {
  data: NodeData;
  groupId?: string;
}

const DiagramNode: FC<NodeProps> = observer(({ data, groupId }) => {
  const {
    type = NODE_TYPES.passthrough,
    onHandleClick,
    value,
    identifier,
    key,
  } = data;
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();
  const theme = useTheme();

  const classes = makeStyles({
    mainBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    diagramNodeContainer: {
      display: "flex",
      alignItems: "center",
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderRadius: "2px",
      borderLeft: `2px solid ${theme.palette.neutral.dark}`,
      height: "inherit",
      position: "relative",
    },
    actionsBox: {
      visibility:
        flowSettingsStore.nodeAnchor?.id === data?.identifier
          ? "visible"
          : "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    diagramNodeHover: {
      "&:hover $actionsBox": {
        visibility: "visible",
      },
    },
    nodeTitle: {
      minWidth: "40px",
      maxWidth: "100px",
      marginLeft: "5px",
      fontSize: "12px",
      textWrap: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })();

  const outgoing = useMemo(
    () => NodesHelper.findOutgoing(reactFlow, identifier),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowSettingsStore.layoutingNeeded]
  );

  const nodeTarget = useMemo(
    () =>
      type === NODE_TYPES.input
        ? NodesHelper.findGroupOutgoing(reactFlow, identifier)
        : NodesHelper.findOutgoing(reactFlow, identifier),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowSettingsStore.layoutingNeeded]
  );

  const showTopNodeHandler = useMemo(
    () => type === NODE_TYPES.output || type === NODE_TYPES.passthrough,
    [type]
  );

  const showBottomNodeHandler = useMemo(
    () =>
      type === NODE_TYPES.input ||
      type === NODE_TYPES.passthrough ||
      type === NODE_TYPES.group,
    [type]
  );

  const onNodeClick = () => {
    if (identifier !== NODE_ASSET_TYPE.startNode) {
      flowSettingsStore.setNodeId(identifier);
    }
  };

  return (
    <>
      {showTopNodeHandler ? (
        <DiagramNodeHandle
          type={NODE_HANDLE_TYPES.target}
          hidden={true}
          identifier={identifier}
        />
      ) : (
        <></>
      )}

      <Box
        className={`nodrag ${classes.diagramNodeContainer} ${classes.diagramNodeHover}`}
        onDoubleClick={onNodeClick}
        onClick={onNodeClick}
      >
        <Box className={classes.mainBox}>
          <DiagramNodeLabel
            nodeKey={key}
            value={value}
            nodeTitleStyle={classes.nodeTitle}
            nodeIdentifier={identifier}
          />

          <Box className={classes.actionsBox}>
            <DiagramNodeActions
              type={type}
              nodeId={identifier}
              hasTarget={nodeTarget?.length > 0}
            />
          </Box>
        </Box>
      </Box>

      {showBottomNodeHandler ? (
        <DiagramNodeHandle
          type={NODE_HANDLE_TYPES.source}
          onHandleClick={() =>
            onHandleClick && onHandleClick(identifier, groupId)
          }
          hidden={outgoing.length > 0 || !flowSettingsStore.isDiagramEditable}
          identifier={identifier}
        />
      ) : (
        <></>
      )}
    </>
  );
});

export default DiagramNode;
