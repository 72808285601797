import React, { FC, useState } from "react";

import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";

import { makeStyles } from "@mui/styles";
import { alpha, useTheme, Box, BoxProps, Typography } from "@mui/material";

export const StripeCard: FC<{
  emptyMessage: string;
  onChange: (event: StripeCardElementChangeEvent) => void;
  setValidState?: (state: boolean) => void;
  containerProps?: BoxProps;
}> = ({ onChange, emptyMessage, containerProps = {} }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(emptyMessage);

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: theme.palette.surface.contrastText,
        fontSize: "15px",
        "::placeholder": {
          color: theme.palette.text.primary,
        },
      },
      invalid: {
        color: theme.palette.error.main,
        iconColor: theme.palette.error.main,
      },
    },
  };

  const classes = makeStyles({
    card: {
      padding: "11px 14px",
      border: `1px solid ${theme.palette.neutral.main}`,
      backgroundColor: alpha(theme.palette.surface.dark, 0.8),
    },
    errorMessage: {
      color: theme.palette.error.main,
      marginTop: "10px",
      fontSize: "14px",
    },
  })();

  const handleChange = (event: StripeCardElementChangeEvent) => {
    // If card is empty, then message is not received
    // Case must be treated
    let message = event?.error?.message || "";
    if (!message && !event?.complete) {
      message = emptyMessage;
    }

    setErrorMessage(message);
    onChange(event);
  };

  return (
    <>
      <Box sx={{ margin: "0px 0 15px 0", ...containerProps }}>
        <Box className={classes.card}>
          <CardElement
            id="card-element"
            onChange={handleChange}
            options={cardStyle}
          />
        </Box>

        {!!errorMessage && (
          <Typography id="card-error" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </>
  );
};
