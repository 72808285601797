import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./StoresProvider";
import { GraphQLError } from "graphql";
import {
  GET_CHAT_GPT_RESPONSE,
  QUERY_GET_CHAT_GPT_RESPONSE,
  QUERY_GET_CHAT_GPT_SUGGESTIONS,
  GET_CHAT_GPT_SUGGESTIONS,
  QUERY_GET_DEV_SETTINGS_RESPONSE,
  GET_DEV_SETTINGS,
  GET_CHAT_LANGUAGES,
  QUERY_GET_CHAT_LANGUAGES,
} from "./queries/chatGpt";
import {
  MUTATION_REGENERATE_CLARICHAT_RESPONSE,
  REGENERATE_CLARICHAT_RESPONSE,
} from "./mutations/chatGpt";
import { ChatGptTabs } from "../types/constants";
import {
  ChatGptMessage,
  DevSettingsParameters,
  DevSettings,
  ChatLanguages,
} from "../types/interfaces";
import ChatGptHelper from "../helper/chatGptHelper";

export class ChatGptStore {
  root: RootStore;
  isChatGptPopupOpen = false;
  viewTab = ChatGptTabs.home;
  isDataLoading = false;
  focusedChatId = "";
  messages: ChatGptMessage[] = [];
  tabToReturn = "";
  isResponseGenerating = false; // used for text animation
  isResponseLoading = false; // used for getChatGptResponse
  isOnSuggestions = false;
  homeMessages: ChatGptMessage[] = [];
  userInput = "";
  isScrollDisabled = false;

  devSettingsConfig: DevSettingsParameters[] = [];
  devSettings: DevSettings = {};
  selectedChatLanguage = localStorage.getItem("chatLanguage") || "en";
  chatLanguages: ChatLanguages[] = [];
  loadingDevSettings = false;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  setIsChatGptPopupOpen = (isOpen: boolean) => {
    this.isChatGptPopupOpen = isOpen;
  };

  setChatGptViewTab = (tab: string) => {
    this.viewTab = tab;
  };

  setFocusedChatId = (id: string) => {
    this.focusedChatId = id;
  };

  setIsResponseGenerating = (isGenerating: boolean) => {
    this.isResponseGenerating = isGenerating;
  };

  setIsResponseLoading = (isLoading: boolean) => {
    this.isResponseLoading = isLoading;
  };

  setIsDataLoading = (isLoading: boolean) => {
    this.isDataLoading = isLoading;
  };

  setUserInput = (input: string) => {
    this.userInput = input;
  };

  setIsScrollDisabled = (isDisabled: boolean) => {
    this.isScrollDisabled = isDisabled;
  };

  addMessage = (newMessage: ChatGptMessage) => {
    this.messages = [...this.messages, newMessage];
  };

  clearMessages = () => {
    this.messages = [];
  };

  addHomeMessage = (newMessage: ChatGptMessage) => {
    this.homeMessages = [...this.homeMessages, newMessage];
  };

  clearHomeMessages = () => {
    this.homeMessages = [];
  };

  setTabToReturn = (tab: string) => {
    this.tabToReturn = tab;
  };

  setIsOnSuggestions = (isOnSuggestion: boolean) => {
    this.isOnSuggestions = isOnSuggestion;
  };

  setSelectedChatLanguage = (language: string) => {
    this.selectedChatLanguage = language;
    localStorage.setItem("chatLanguage", language);
  };

  setLoadDevSettings = (isLoading: boolean) => {
    this.loadingDevSettings = isLoading;
  };

  getChatGptResponse = async (
    question: string,
    isForHome: boolean,
    history: ChatGptMessage[],
    config: { [key: string]: string },
    flowIdentifier?: string
  ) => {
    const {
      data: { getChatGptResponse },
      errors,
    } = await this.root.client.query<QUERY_GET_CHAT_GPT_RESPONSE>({
      query: GET_CHAT_GPT_RESPONSE,
      variables: {
        question: {
          question: question,
          history: ChatGptHelper.messageBoxToApi([
            ...(history?.slice(0, history?.length - 1) || []),
          ]),
        },
        config,
        flowIdentifier: flowIdentifier,
        language: this.selectedChatLanguage,
      },
    });

    if (!getChatGptResponse || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      // Slice is being used in order to remove last message from history
      // Last message in history is being replaced by response content which also contains the question of the user
      // TODO: Fint a better way to handle this
      if (isForHome) {
        this.homeMessages = [
          ...(this.homeMessages?.slice(0, this.homeMessages?.length - 1) || []),
          getChatGptResponse,
        ];
      } else {
        this.messages = [
          ...(this.messages?.slice(0, this.messages?.length - 1) || []),
          getChatGptResponse,
        ];
      }
    });

    return getChatGptResponse;
  };

  getChatGptResponseHelper = async (
    question: string,
    isForHome: boolean,
    flowIdentifier?: string
  ) => {
    this.setIsResponseLoading(true);

    const config = this.devSettings;

    await this.getChatGptResponse(
      question?.trim(),
      isForHome,
      isForHome ? this.homeMessages : this.messages,
      config,
      flowIdentifier
    )
      .then((response) => {
        if (response.answer !== "") this.setIsResponseGenerating(true);
      })
      .finally(() => this.setIsResponseLoading(false));
  };

  regenerateClariChatResponse = async (
    messageBoxIndex: number,
    isHomeChat = false,
    flowIdentifier?: string
  ) => {
    const history = isHomeChat ? this.homeMessages : this.messages;
    const messageBox = history[messageBoxIndex];
    const config = this.devSettings;

    const response =
      await this.root.client.mutate<MUTATION_REGENERATE_CLARICHAT_RESPONSE>({
        mutation: REGENERATE_CLARICHAT_RESPONSE,
        variables: {
          question: {
            question: messageBox?.question,
            history: ChatGptHelper.messageBoxToApi(history),
          },
          config,
          flowIdentifier,
          language: this.selectedChatLanguage,
        },
      });

    const {
      data: { regenerateClarichatResponse },
      errors,
    } = response as unknown as {
      data: { regenerateClarichatResponse: ChatGptMessage };
      errors: GraphQLError[];
    };

    if (!regenerateClarichatResponse || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    if (regenerateClarichatResponse.answer) {
      runInAction(() => {
        if (isHomeChat) {
          this.homeMessages = ChatGptHelper.setNewAnswer(
            this.homeMessages,
            messageBoxIndex,
            regenerateClarichatResponse.answer as string
          );
        } else {
          this.messages = ChatGptHelper.setNewAnswer(
            this.messages,
            messageBoxIndex,
            regenerateClarichatResponse.answer as string
          );
        }
      });
    }

    return regenerateClarichatResponse;
  };

  getChatGptSuggestions = async () => {
    const {
      data: { getChatGptSuggestions },
      errors,
    } = await this.root.client.query<QUERY_GET_CHAT_GPT_SUGGESTIONS>({
      query: GET_CHAT_GPT_SUGGESTIONS,
    });

    if (!getChatGptSuggestions || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getChatGptSuggestions;
  };

  // DEV Settings
  //TODO: This api call is not used for now
  loadDevSettingsConfig = async (): Promise<DevSettingsParameters[]> => {
    const {
      data: { getDevSettings },
      errors,
    } = await this.root.client.query<QUERY_GET_DEV_SETTINGS_RESPONSE>({
      query: GET_DEV_SETTINGS,
    });

    if (!getDevSettings || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.devSettingsConfig = getDevSettings.parameters;
    });

    return getDevSettings.parameters;
  };

  isDevMode = () => {
    return process.env.REACT_APP_ENV === "development";
  };

  setDevSettings = (formattedConfig: { [key: string]: string } | null) => {
    if (formattedConfig && Object.keys(formattedConfig)?.length > 0) {
      this.devSettings = formattedConfig;
      return;
    }
    this.devSettings = {};
  };

  validateDevSettings = () => {
    if (this.devSettingsConfig?.length > 0) {
      let formattedSettings = {};

      for (const config of this.devSettingsConfig) {
        formattedSettings = {
          ...formattedSettings,
          [config.key]: this.devSettings[config.key] || config.value,
        };
      }

      this.devSettings = formattedSettings;
    }
  };

  getChatLanguages = async () => {
    const {
      data: { getChatLanguages },
      errors,
    } = await this.root.client.query<QUERY_GET_CHAT_LANGUAGES>({
      query: GET_CHAT_LANGUAGES,
    });

    if (!getChatLanguages || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.chatLanguages = getChatLanguages;
    });

    return getChatLanguages;
  };
}
