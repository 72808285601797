import LinearProgress from "@mui/material/LinearProgress";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { makeStyles } from "@mui/styles";

import React, { FC } from "react";
import { FIELD_TYPE } from "../../../types/constants";

import { TableHeaderDef, TableContentDef } from "../../../types/interfaces";

interface Props {
  headers: TableHeaderDef[];
  loading?: boolean;
  handleSorting?: (cell: TableHeaderDef) => void;
  columnWidth?: TableContentDef;
  rowHeight?: TableContentDef;
}

const useStyles = makeStyles({
  tableCell: {
    padding: "0px",
    borderBottom: "none",
    height: "0px",
  },
  linearProgress: {
    marginTop: "-5px",
  },
});

const CoreHeader: FC<Props> = ({
  headers,
  loading,
  handleSorting,
  columnWidth = {},
  rowHeight = {},
}) => {
  const classes = useStyles();

  const onRequestSort = (cell: TableHeaderDef) => {
    if (handleSorting) handleSorting(cell);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((cell, index) => (
          <TableCell
            sx={{
              width: (columnWidth[cell.accessor] as string) || undefined,
              height: (rowHeight[cell.accessor] as string) || undefined,
            }}
            key={`${cell.accessor}-${index}`}
            padding={FIELD_TYPE.checkbox}
          >
            {cell.options?.isSortable ? (
              <TableSortLabel
                active={cell.options.isSortActive}
                direction={cell.options.sortDirection}
                onClick={() => onRequestSort(cell)}
              >
                {/* TODO: Add span instead of typography */}
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell colSpan={headers.length + 1} className={classes.tableCell}>
          {loading && <LinearProgress className={classes.linearProgress} />}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CoreHeader;
