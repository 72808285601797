import { alpha, Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiDivider = (
  theme: ThemeOptions
): Components["MuiDivider"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        borderColor: alpha(palette.divider, 0.2),
      },
    },
  };
};
