import { gql } from "@apollo/client";

export const GET_CONNECTIONS = gql`
  query getConnectionTypes {
    getConnectionTypes {
      conn_types
    }
  }
`;

export const GET_CONNECTION_CONFIG = gql`
  query getConnectionConfig($id: String) {
    getConnectionConfig(id: $id) {
      config
    }
  }
`;

export const GET_CONNECTION_FIELDS = gql`
  query getConnectionFields($type: String) {
    getConnectionFields(type: $type)
  }
`;
