import { gql } from "@apollo/client";

import {
  BillingCountry,
  BillingCustomer,
  BillingIntentAPI,
  PaginatedSubscriptionUsageHistory,
  SubscriptionPlan,
  SubscriptionReceipt,
  SubscriptionUsage,
} from "../../types/interfaces/subscription";

export interface QUERY_BILLING_CUSTOMER_RESPONSE {
  billingCustomer: BillingCustomer;
}

export interface QUERY_ACTIVE_SUBSCRIPTION_RESPONSE {
  activeSubscription: SubscriptionPlan;
}

export interface QUERY_SUBSCRIPTION_RECEIPTS_RESPONSE {
  activeSubscriptionReceipts: SubscriptionReceipt[];
}

export interface QUERY_SUBSCRIPTION_USAGE_RESPONSE {
  activeSubscriptionUsage: SubscriptionUsage;
}

export interface QUERY_SUBSCRIPTION_PACKAGES_RESPONSE {
  subscriptionPlans: SubscriptionPlan[];
}

export interface QUERY_BILLING_COUNTRIES_RESPONSE {
  billingCountries: BillingCountry[];
}

export interface QUERY_BILLING_INTENT_RESPONSE {
  billingSetupIntent: BillingIntentAPI;
}

export interface QUERY_SUBSCRIPTION_USAGE_HISTORY_RESPONSE {
  subscriptionUsageHistory: PaginatedSubscriptionUsageHistory;
}

export const GET_SUBSCRIPTION_PACKAGES = gql`
  query subscriptionPlans {
    subscriptionPlans {
      id
      plan
      productId
      priceId
      price
      priceExtra
      priceExtraUnit
      period
      subscribable
      name
      description
      currency
      billable
      subscriptionProperties
      subscriptionBenefits
      subscriptionAdditionalPricing
      subscriptionModelType
      subscriptionModelSpecs
    }
  }
`;

export const GET_BILLING_CUSTOMER = gql`
  query billingCustomer {
    billingCustomer {
      id
      name
      email
      phone
      billingInformation
      companyInformation
      metadata
    }
  }
`;

export const GET_ACTIVE_SUBSCRIPTION = gql`
  query activeSubscription {
    activeSubscription {
      id
      productId
      priceId
      name
      description
      currency
      price
      period
      billable
      subscriptionProperties
      subscriptionBenefits
      subscriptionAdditionalPricing
      subscriptionModelType
      subscriptionModelSpecs
      card
      status
      createdAt
      expiresAt
      details
    }
  }
`;

export const GET_ACTIVE_SUBSCRIPTION_RECEIPTS = gql`
  query activeSubscriptionReceipts {
    activeSubscriptionReceipts {
      date
      amount
      currency
      status
      id
      card
    }
  }
`;

export const GET_ACTIVE_SUBSCRIPTION_USAGE = gql`
  query activeSubscriptionUsage(
    $start: Int!
    $end: Int!
    $type: String
    $namespaces: [String]
    $flows: [String]
  ) {
    activeSubscriptionUsage(
      start: $start
      end: $end
      type: $type
      namespaces: $namespaces
      flows: $flows
    ) {
      currentUsage
      transaction
    }
  }
`;

export const GET_BILLING_COUNTRIES = gql`
  query billingCountries {
    billingCountries {
      name
      code
    }
  }
`;

export const GET_BILLING_SETUP_INTENT_KEY = gql`
  query billingSetupIntent {
    billingSetupIntent
  }
`;

export const GET_SUBSCRIPTION_USAGE_HISTORY = gql`
  query subscriptionUsageHistory($page: Int!, $size: Int!) {
    subscriptionUsageHistory(page: $page, size: $size) {
      items {
        date
        included
        extra
        group
      }
      pages
    }
  }
`;
