import React, { FC, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Box,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CoreButton from "../../../../../core/CoreButton";
import { useStores } from "../../../../../../stores/StoresProvider";
import CoreNotificationBox from "../../../../../core/CoreNotificationBox";
import {
  useNotification,
  NotificationTypes,
} from "../../../../../../context/useNotification";

interface Props {
  closeRightClickMenu: () => void;
  open: boolean;
  handleClose: () => void;
  selectedPages: string[];
  documentPages: string[];
}

const DeleteDocumentPages: FC<Props> = observer(
  ({
    closeRightClickMenu,
    open,
    handleClose,
    selectedPages,
    documentPages,
  }) => {
    const { documentStore, flowStore } = useStores();
    const notification = useNotification();
    const client = useApolloClient();
    const theme = useTheme();
    const { t } = useTranslation("documents");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const classes = makeStyles({
      dialog: {
        backgroundImage: "unset",
        backgroundColor: theme.palette?.background.paper,
        maxWidth: "640px",
      },
      deletePagesModalHeader: {
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
        marginBottom: "20px",
      },
      deletePageModalNotification: {
        backgroundColor: alpha(theme.palette.surface.light, 0.1),
        boxShadow: "none",
        margin: "20px 0",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      deletePageModalActions: {
        padding: "20px",
      },
      listContainer: {
        marginTop: "5px",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "150px",
      },
      listItem: {
        width: "100%",
        marginLeft: "5px",
        "&::before": {
          content: "'•'",
          marginRight: "10px",
          fontSize: "20px",
        },
      },
      deleteLastPageNotification: {
        backgroundColor: alpha(theme.palette.warning.main, 0.4),
        boxShadow: "none",
        marginTop: "20px",
        width: "100%",
      },
      iconButton: {
        position: "absolute",
        right: 8,
        top: 8,
      },
    })();

    //  Find the indexes of the selected pages
    const selectedPagesIndexes = selectedPages.map((item) =>
      documentPages.findIndex((page) => page === item)
    );

    // Increase indexes by 1 and arange them in ascending order
    const PagesIndexesToBeDeleted = selectedPagesIndexes
      .map((item) => item + 1)
      .sort((a, b) => a - b)
      .join(", ");

    // Selected pages indexes
    const pageForFieldIndex = documentStore.documentFields?.map((item) =>
      documentStore.document?.pages.findIndex(
        (page) => item.pageIdentifier === page && selectedPages.includes(page)
      )
    ) as number[];

    // Get field name for the selected pages
    const fieldName = documentStore.documentFields?.map(
      (item) =>
        flowStore.flowFields?.find((field) => field.key === item.type)?.name
    );

    // Create an array with Field name coresponding to the page is comming from
    const noteContent: string[] = [];

    if (
      pageForFieldIndex?.length &&
      fieldName?.length === pageForFieldIndex.length
    ) {
      for (let i = 0; i < pageForFieldIndex?.length; i++) {
        if (pageForFieldIndex[i] !== -1) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          noteContent.push(`${fieldName[i]} #(${pageForFieldIndex[i] + 1})`);
        }
      }
    }

    const handleDeletePages = () => {
      setButtonDisabled(true);
      if (selectedPages.length === documentPages.length) {
        notification.error(t("deleteLastPageError"));
        return;
      }
      documentStore
        .deleteDocumentPages(client, selectedPages)
        .then(() => {
          handleClose();
          closeRightClickMenu();
          documentStore.clearDocument();
        })
        .then(() => {
          notification.success(t("deletePagesSuccess"));
        })
        .then(() => {
          documentStore
            .loadDocument(
              client,
              documentStore.document?.identifier || "",
              false
            )
            .catch((error: Error) => {
              notification.error(t(error?.message || "loadDocumentPageError"));
            })
            .finally(() => {
              setButtonDisabled(false);
            });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "deletePagesError"));
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    };

    return (
      <Dialog
        open={open}
        fullWidth
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.deletePagesModalHeader}>
          {t("deletePages")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {documentPages.length > selectedPages.length ? (
          <DialogContent>
            {`${t("pagesMessage")}
        ${PagesIndexesToBeDeleted}.`}
            {noteContent.length > 0 && (
              <CoreNotificationBox
                type={NotificationTypes.info}
                className={classes.deletePageModalNotification}
              >
                {t("removingFields")}
                <Box className={classes.listContainer}>
                  {noteContent.map((item, index) => (
                    <div key={index} className={classes.listItem}>
                      {item}
                    </div>
                  ))}
                </Box>
              </CoreNotificationBox>
            )}
            <Box>{t("areYouSure")}</Box>
          </DialogContent>
        ) : (
          <DialogContent>
            <CoreNotificationBox
              type={NotificationTypes.warning}
              className={classes.deleteLastPageNotification}
            >
              {t("deleteLastPageError")}
            </CoreNotificationBox>
          </DialogContent>
        )}
        <DialogActions className={classes.deletePageModalActions}>
          <CoreButton
            onClick={handleClose}
            variant="outlined"
            disabled={documentStore.updatingPage}
          >
            {t("cancel")}
          </CoreButton>
          {documentPages.length > selectedPages.length && (
            <CoreButton
              autoFocus
              onClick={handleDeletePages}
              variant="contained"
              isLoading={documentStore.updatingPage || buttonDisabled}
              disabled={documentStore.updatingPage || buttonDisabled}
            >
              {t("confirm")}
            </CoreButton>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default DeleteDocumentPages;
