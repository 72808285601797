import React, { useMemo, useState } from "react";
import { useReactFlow } from "reactflow";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { alpha, Box, IconButton, useTheme } from "@mui/material";
import FlashIcon from "@mui/icons-material/FlashOn";

import JsonEditorRenderer from "./JsonEditorRenderer";
import { FlowNode, FormField } from "../../types/interfaces";
import SuggestionsModal from "./suggestions/SuggestionsModal";
import { SuggestionsModalTabs, THEME_MODES } from "../../types/constants";
import { useStores } from "../../stores/StoresProvider";
import NodesHelper from "../pages/flow/flow-settings/helper/nodesHelper";

interface Props {
  onChange: (value: Record<string, string>) => void;
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  errorText: string | undefined;
  disableDiagramField?: boolean;
}

const DynamicContentJsonEditorRenderer: React.FC<Props> = observer(
  ({ onChange, translation, field, value, errorText, disableDiagramField }) => {
    const theme = useTheme();
    const reactFlow = useReactFlow();
    const { mainStore, flowSettingsStore } = useStores();

    const [modalTab, setModalTab] = useState("");
    const [dynamicContentTextAtCursor, setDynamicContentTextAtCursor] =
      useState<string>("");

    const classes = makeStyles({
      mainBox: {
        display: "flex",
        width: "100%",
      },
      iconsBox: {
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
      },
      modal: {
        display: "flex",
        justifyContent: "center",
      },
      editorBox: {
        width: "100%",
        height: "100%",
        marginRight: "2.4375rem",
        backgroundColor:
          mainStore.currentTheme === THEME_MODES.mono
            ? alpha(theme.palette.surface.dark, 0.8)
            : "inherit",
      },
    })();

    const nodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );
    const edges = useMemo(
      () => reactFlow.getEdges(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const handleOnClose = () => {
      flowSettingsStore.setSuggestionsModalOpen(false);
      setModalTab("");
    };

    const handleClickDynamicContent = () => {
      flowSettingsStore.setSuggestionsModalOpen(true);
      setModalTab(SuggestionsModalTabs.dynamicContent);
    };

    const allContextVariables = useMemo(
      () => {
        const formattedNodes = NodesHelper.attachSource(nodes, edges);
        return flowSettingsStore.getNodeOutputOptions(
          formattedNodes,
          translation,
          field.allowListSchemaObjects
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [nodes, edges, flowSettingsStore.layoutingNeeded]
    );

    const options = useMemo(() => {
      if (!allContextVariables) {
        return [];
      }

      return allContextVariables;
    }, [allContextVariables]);

    return (
      <>
        <Box className={classes.mainBox}>
          <Box className={classes.iconsBox}>
            <IconButton onClick={handleClickDynamicContent}>
              <FlashIcon />
            </IconButton>
          </Box>
          <Box className={classes.editorBox}>
            <JsonEditorRenderer
              onChange={(value) => {
                if (dynamicContentTextAtCursor) {
                  setDynamicContentTextAtCursor("");
                }
                onChange(value);
              }}
              value={value}
              field={field}
              errorText={errorText}
              translation={translation}
              textAtCursor={dynamicContentTextAtCursor}
              disableDiagramField={disableDiagramField}
            />
          </Box>
        </Box>

        <SuggestionsModal
          t={translation}
          onClose={handleOnClose}
          setModalTab={setModalTab}
          selectedModalTab={modalTab}
          suggestions={options}
          setTextInQuill={(value: string) => {
            setDynamicContentTextAtCursor(`"[${value}]"`);
          }}
          disableDiagramField={disableDiagramField}
        />
      </>
    );
  }
);

export default DynamicContentJsonEditorRenderer;
