import React from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";

import CoreInput from "../../core/CoreInput";

type Props = {
  title: string;
  value: string;
  translate: TFunction;
};

const useStyle = makeStyles({
  gridContainer: {
    padding: "10px 10px 10px 20px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    opacity: 0.6,
    fontSize: "12px",
  },
});

const InfoField: React.FC<Props> = ({ title, value, translate }) => {
  const classes = useStyle();

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={3} className={classes.titleContainer}>
        <Typography className={classes.title}>{translate(title)}</Typography>
      </Grid>

      <Grid item xs={6} className={classes.itemsContainer}>
        <CoreInput readOnly={true} value={value} />
      </Grid>
    </Grid>
  );
};

export default InfoField;
