import React, { FC, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { useStores } from "../../../../stores/StoresProvider";
import ChatGptHelper from "../../../../helper/chatGptHelper";

interface Props {
  text: string;
  onDone?: () => void;
}

const useStyles = makeStyles({
  responseText: {
    fontSize: "14px",
  },
});

const ChatGptTypeWriter: FC<Props> = observer(({ text: _text, onDone }) => {
  const { chatGptStore } = useStores();
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const classes = useStyles();

  const text = useMemo(() => ChatGptHelper.preProcessText(_text), [_text]);

  useEffect(() => {
    if (currentIndex < (text || "").length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);

        if (text?.length === currentIndex + 1 && onDone) {
          onDone();
        }
      }, 10);

      if (!chatGptStore.isScrollDisabled) {
        document?.getElementById("homeChatGptDiv")?.scrollIntoView(false);
        document?.getElementById("clariChatBox")?.scrollIntoView(false);
      }

      return () => clearTimeout(timeout);
    } else {
      chatGptStore.setIsResponseGenerating(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatGptStore, currentIndex, text, chatGptStore.isScrollDisabled]);

  useEffect(() => {
    const handleWheel = () => {
      chatGptStore.setIsScrollDisabled(true);
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && currentIndex < text.length) {
        setCurrentText(text);
        setCurrentIndex(text.length);
        document?.getElementById("clariChatBox")?.scrollIntoView(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentIndex, text]);

  return <Box className={classes.responseText}>{currentText}</Box>;
});

export default ChatGptTypeWriter;
