import { alpha, Components, ThemeOptions } from "@mui/material";
import { ThemePalette } from "../../types/interfaces";
import { THEME_MODES } from "../../types/constants";

export const initMuiListItemButton = (
  theme: ThemeOptions
): Components["MuiListItemButton"] => {
  const palette = theme.palette as ThemePalette;
  const isLightMode = theme.palette?.mode === THEME_MODES.light;

  return {
    styleOverrides: {
      root: {
        borderRadius: "2px",
        "&:hover": { backgroundColor: theme.palette?.action?.hover },
        "&.Mui-selected": {
          backgroundColor: alpha(palette.highlight.dark, 0.4),
          color: palette.highlight.main,
          "&:hover": {
            backgroundColor: isLightMode ? palette.canvas.contrastText : "",
          },
        },
      },
    },
  };
};
