import React, { FC, useState, useEffect } from "react";
import { TFunction } from "i18next";
import { useApolloClient } from "@apollo/client";

import DocumentHelper from "../../../../helper/documentHelper";
import { CheckedStatuses } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import RerunDocumentsDialog from "./RerunDocumentsDialog";

interface Props {
  t: TFunction;
  documents: string[];
  isRerunDialogOpen: boolean;
  setIsRerunDialogOpen: (isRerunDialogOpen: boolean) => void;
  successfullyRerun: () => void;
  finallyRerun?: () => void;
  refreshData: () => void;
}

const RerunDocumentsAction: FC<Props> = ({
  t,
  documents,
  isRerunDialogOpen,
  setIsRerunDialogOpen,
  successfullyRerun,
  finallyRerun,
  refreshData,
}) => {
  const { documentStore } = useStores();
  const client = useApolloClient();
  const notification = useNotification();

  const [isRerunLoading, setIsRerunLoading] = useState(false);
  const [similarDocuments, setSimilarDocuments] = useState<{
    [key: string]: number;
  }>({});
  const [similarDocumentsLoading, setSimilarDocumentsLoading] = useState(false);
  const [checkedStatuses, setCheckedStatuses] = useState<CheckedStatuses[]>([]);

  const rerunDocuments = () => {
    setIsRerunLoading(true);
    documentStore
      .rerunDocuments(
        client,
        DocumentHelper.getCheckedStatuses(checkedStatuses),
        documents
      )
      .then(() => {
        notification.success(t("rerunSuccess"));
        successfullyRerun();
        refreshData();
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "rerunAllError"));
      })
      .finally(() => {
        finallyRerun && finallyRerun();
        setIsRerunDialogOpen(false);
        setIsRerunLoading(false);
      });
  };

  useEffect(() => {
    if (isRerunDialogOpen) {
      setSimilarDocumentsLoading(true);
      documentStore
        .getSimilarDocuments(client, documents)
        .then((res) => {
          setSimilarDocuments(
            res.data?.getSimilarDocuments as { [key: string]: number }
          );
          setCheckedStatuses(
            Object.keys(
              res.data?.getSimilarDocuments as { [key: string]: number }
            )?.map((status) => ({
              status,
              isChecked: false,
            }))
          );
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "getSimilarDocsError"));
        })
        .finally(() => setSimilarDocumentsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRerunDialogOpen]);

  return (
    <RerunDocumentsDialog
      t={t}
      open={isRerunDialogOpen}
      handleClose={() => {
        setIsRerunDialogOpen(false);
      }}
      handleConfirm={rerunDocuments}
      loadingRerun={isRerunLoading}
      loadingData={similarDocumentsLoading}
      data={similarDocuments}
      checkedStatuses={checkedStatuses}
      setCheckedStatuses={setCheckedStatuses}
    />
  );
};

export default RerunDocumentsAction;
