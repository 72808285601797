import React, { FC } from "react";
import BeatLoader from "react-spinners/BeatLoader";

interface Props {
  loading: boolean;
  speed?: number;
  color?: string;
  size?: number;
}

export const Loader: FC<Props> = ({ loading, speed = 1, color, size = 15 }) => {
  return (
    <BeatLoader
      color={color}
      loading={loading}
      size={size}
      speedMultiplier={speed}
    />
  );
};
