import React, { FC } from "react";
import classNames from "classnames";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  InputProps,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import CoreTooltip from "./CoreTooltip";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface PropsInput extends InputProps {
  isTextArea?: boolean;
  errorText?: string;
  title?: string;
  titleIcon?: React.ReactNode;
  fullWidth?: boolean;
  inputTitleSize?: string;
  placeholder?: string;
  formControlClass?: string;
  titleClassname?: string;
  isMandatory?: boolean;
  description?: string;
  boxClassname?: string;
  tooltipLocation?: "title" | "input";
  extraActions?: React.ReactNode;
  icon?: React.ComponentType<SvgIconProps>;
  iconOnClick?: () => void;
  disableBorder?: boolean;
}

const CoreInput: FC<PropsInput> = ({
  errorText,
  title,
  fullWidth = true,
  inputTitleSize,
  placeholder,
  size = "small",
  formControlClass,
  titleClassname,
  isMandatory = false,
  description,
  boxClassname,
  tooltipLocation,
  extraActions,
  icon,
  titleIcon,
  iconOnClick,
  isTextArea,
  disableBorder = false,
  ...extended
}) => {
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const useStyles = makeStyles({
    input: {
      width: "100%",
    },
    formText: {
      margin: "auto 0",
      height: "auto",
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    outlinedInput: {
      width: "100%",
      borderRadius: "2px",
    },
    description: {
      marginLeft: "5px",
      fontSize: "20px",
      marginBottom: "3px",
    },
    icon: {
      alignSelf: "center",
      marginLeft: "15px",
    },
    boxInput: {
      display: "flex",
      width: "100%",
    },
    infoContainer: {
      display: "flex",
      alignItems: "center",
    },
    extraActions: {
      alignSelf: "center",
      marginLeft: "5px",
      marginRight: "-10px",
    },
    iconStyle: {
      marginLeft: "5px",
      marginRight: "-11px",
    },
    noBorder: {
      border: "none",
    },
    title: {
      display: "flex",
      alignItems: "flex-start",
    },
  });

  const classes = useStyles();
  const InputIcon = icon;

  const titleClass = `${titleClassname as unknown as string} ${classes.title}`;

  return (
    <FormControl
      size={size}
      error={!!errorText}
      fullWidth={fullWidth}
      className={classNames(formControlClass)}
    >
      <Box className={classes.infoContainer}>
        {title && (
          <Typography
            variant="subtitle2"
            style={{ fontSize: inputTitleSize }}
            color={
              errorText
                ? currentTheme === THEME_MODES.light
                  ? theme.palette.error.main
                  : theme.palette.error.light
                : theme.palette?.text.primary
            }
            className={titleClass}
          >
            {title} {isMandatory && <span className={classes.asterisk}>*</span>}
            {titleIcon}
          </Typography>
        )}
        {description && tooltipLocation === "title" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.description} />
          </CoreTooltip>
        )}
      </Box>
      <Box className={boxClassname ?? classes.boxInput}>
        <OutlinedInput
          fullWidth={fullWidth}
          className={classes.outlinedInput}
          error={!!errorText}
          placeholder={placeholder || undefined}
          classes={
            disableBorder ? { notchedOutline: classes.noBorder } : undefined
          }
          inputProps={{ className: classes.input }}
          {...(isTextArea && { multiline: true, rows: 4 })}
          {...extended}
        />
        {InputIcon && (
          <IconButton onClick={iconOnClick} className={classes.iconStyle}>
            <InputIcon />
          </IconButton>
        )}

        {extraActions && (
          <Box className={classes.extraActions}>{extraActions}</Box>
        )}

        {description && tooltipLocation === "input" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.icon} />
          </CoreTooltip>
        )}
      </Box>
      {errorText && (
        <Box className={classes.boxError}>
          <CancelOutlinedIcon className={classes.cancelIcon} />

          <FormHelperText error={!!errorText} className={classes.formText}>
            {errorText}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default CoreInput;
