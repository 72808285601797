import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useStores } from "../../../../../../../stores/StoresProvider";
import { useNotification } from "../../../../../../../context/useNotification";
import { REASONS_FIELDS } from "../../../../../../../types/constants";
import { codeRegex } from "../../../../../../../types/regex";
import { RejectionInterface } from "../../../../../../../types/interfaces";
import CoreButton from "../../../../../../core/CoreButton";
import CoreInput from "../../../../../../core/CoreInput";

const useStyles = makeStyles({
  title: {
    fontWeight: "bolder",
    fontSize: "18px",
  },
  actions: {
    "&.MuiDialogActions-root": {
      padding: "1rem",
    },
  },
  content: {
    paddingTop: "2rem",
    "&.MuiDialogContent-root": {
      alignSelf: "stretch",
    },
    margin: "1rem",
  },
  dialogPaper: {
    "& .MuiDialog-paper": {
      padding: 0,
      height: "420px",
      width: "600px",
    },
  },
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px",
  },
});

interface Props {
  field?: RejectionInterface;
  closeModal: () => void;
}

//TODO: Remove after implementing new reject reasons drawer in canvas
const AddReason: FC<Props> = observer(({ field, closeModal }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { flowStore } = useStores();
  const { t } = useTranslation("addFlow");

  const notification = useNotification();

  const [formData, setFormData] = useState<RejectionInterface | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const isFormInvalid =
    !formData?.message || formData?.message?.trim() === "" || !formData?.code;

  useEffect(() => {
    flowStore.isRejectionModalOnEdit && field
      ? setFormData(field)
      : setFormData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowStore.isRejectionModalOnEdit]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.name === REASONS_FIELDS.code &&
      !codeRegex.test(event.target.value)
    ) {
      return;
    }
    setFormData({
      ...(formData || {}),
      [event.target.name]: event.target.value,
    } as RejectionInterface);
  };

  const handleCreate = () => {
    if (!isFormInvalid) {
      setIsLoading(true);
      const condition = (flowStore.rejections ?? []).some(
        (reason) => reason.code === formData.code
      );

      if (condition) {
        setIsLoading(false);
        notification.error(t("duplicateCodeError"));
        return;
      }

      flowStore
        .addRejection(client, formData)
        .then(() => {
          flowStore.getAllRejections(client).catch((error: Error) => {
            notification.error(t(error?.message || "fetchReasonsError"));
          });
        })
        .then(() => {
          closeModal();
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "createRejectionError"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={!!flowStore.isRejectionModalOnEdit}
      onClose={closeModal}
      className={classes.dialogPaper}
    >
      {flowStore.isRejectionModalOnEdit && (
        <>
          <DialogTitle id="alert-dialog-title">
            <Typography className={classes.title}>
              {flowStore.isRejectionModalOnEdit === "edit"
                ? t("reasonEdit")
                : t("reasonCreate")}
            </Typography>
          </DialogTitle>

          <Divider />

          <DialogContent className={classes.content}>
            <Box className={classes.optionContainer}>
              <CoreInput
                placeholder={t("createReason_namePlaceholder")}
                fullWidth
                title={t("reasonMessage")}
                value={t(formData?.message as string) || ""}
                name={REASONS_FIELDS.message}
                onChange={handleChange}
                inputTitleSize="12px"
                description={t("message_tooltip")}
              />
            </Box>
            <Box className={classes.optionContainer}>
              <CoreInput
                placeholder={t("createReason_descriptionPlaceholder")}
                fullWidth
                title={t("reasonDescription")}
                value={t(formData?.description as string) || ""}
                name={REASONS_FIELDS.description}
                onChange={handleChange}
                description={t("description_tooltip")}
              />
            </Box>

            <Box className={classes.optionContainer}>
              <CoreInput
                placeholder={t("createReason_codePlaceholder")}
                title={t("reasonCode")}
                value={formData?.code || ""}
                name={REASONS_FIELDS.code}
                onChange={handleChange}
                inputTitleSize="12px"
                type="text"
                description={t("code_tooltip")}
              />
            </Box>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <CoreButton
              disabled={flowStore.loadingRejections}
              onClick={closeModal}
              variant="neutral"
            >
              {t("cancel")}
            </CoreButton>

            <CoreButton
              disabled={isFormInvalid || isLoading}
              autoFocus
              onClick={handleCreate}
              isLoading={isLoading}
              variant="contained"
            >
              {flowStore.isRejectionModalOnEdit === "edit"
                ? t("update")
                : t("create")}
            </CoreButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
});

export default AddReason;
