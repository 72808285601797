import { TFunction } from "i18next";
import React, { FC, memo } from "react";

import { makeStyles } from "@mui/styles";
import { Divider, Typography, useTheme } from "@mui/material";

import { BillingField } from "./BillingField";
import { CoreCard } from "../../../../core/CoreCard";
import { Fields } from "./resources/billingFields";
import { BILLING_FIELD_CONFIG } from "../../../../../types/interfaces/subscription";

const useStyles = makeStyles({
  cardContainer: {
    padding: "0px !important",
    marginBottom: "20px",
  },
  sectionTitle: { padding: "10px" },
});

interface Props {
  translate: TFunction;
  title?: string;
  disabled: boolean;
  fields: BILLING_FIELD_CONFIG[];
  form: { [key: string]: string };
  errors: { [key: string]: string };
  onFormUpdate: (form: { [key: string]: string }) => void;
  extraContent?: React.ReactNode;
  containerClassName?: string;
}

const Component: FC<Props> = ({
  translate,
  fields,
  form,
  errors,
  title,
  disabled,
  extraContent,
  containerClassName,
  onFormUpdate,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleUpdate = (name: string, value: string) => {
    onFormUpdate({ ...form, [name]: value });
  };

  return (
    <>
      <CoreCard className={containerClassName || classes.cardContainer}>
        {!!title && (
          <>
            <Typography
              className={classes.sectionTitle}
              sx={{ backgroundColor: theme.palette.primary.dark }}
            >
              {translate(title)}
            </Typography>
            <Divider />
          </>
        )}

        {fields.map((field, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}

            <BillingField
              // isReadOnly
              isDisabled={disabled}
              translate={translate}
              key={field.name}
              field={field}
              value={form?.[field.name] || ""}
              error={errors?.[field.name]}
              postalCodeError={errors?.[Fields.postalCode]}
              postalCode={form?.["postalCode"] || ""}
              onUpdate={handleUpdate}
            />
          </React.Fragment>
        ))}

        {extraContent}
      </CoreCard>
    </>
  );
};

export const BillingCardRender = memo(Component);
