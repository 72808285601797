import { createSchema } from "genson-js";
import { Schema, JSONSchemaFaker } from "json-schema-faker";
import { JSON_EDITOR_TYPES, SCHEMA_ERROR_TYPES } from "../types/constants";
import { SchemaValidationResult, FormData } from "../types/interfaces";
import { TFunction } from "i18next";

export default class JsonSchemaConverter {
  static convertToSchema = (data: unknown) => {
    let parsedData = data;

    try {
      if (typeof data === "string") {
        parsedData = JSON.parse(data);
      }

      return createSchema(parsedData);
    } catch (e) {
      return null;
    }
  };

  static convertSchemaToJson = (schema: Schema) => {
    return JSONSchemaFaker.generate(schema);
  };

  static isValidJSON = (obj: string | null) => {
    if (!obj) {
      return false;
    }

    return Object.keys(obj)?.length > 0 && !Array.isArray(obj);
  };

  static isValidArray = (data: unknown) => {
    try {
      return Array.isArray(data) && data?.length > 0;
    } catch (e) {
      return false;
    }
  };

  static isDataValid = (schema: unknown, type: string) => {
    let parsedSchema = schema;

    try {
      if (typeof schema === "string") {
        parsedSchema = JSON.parse(schema);
      }

      if (type === JSON_EDITOR_TYPES.list) {
        return JsonSchemaConverter.isValidArray(parsedSchema);
      }

      return JsonSchemaConverter.isValidJSON(parsedSchema as string);
    } catch (e) {
      return false;
    }
  };

  static isObjectOrList = (type: string) => {
    if (!type) {
      return false;
    }

    return type === JSON_EDITOR_TYPES.object || type === JSON_EDITOR_TYPES.list;
  };

  static validateSchema(
    schema: Schema | string | null,
    formType: JSON_EDITOR_TYPES,
    t: TFunction
  ): SchemaValidationResult {
    if (!schema) {
      return {
        isValid: false,
        errorMessage: t(SCHEMA_ERROR_TYPES.missingSchema),
      };
    }

    const parsedSchema =
      typeof schema === "string"
        ? (() => {
            try {
              return JSON.parse(schema) as Schema;
            } catch {
              return null;
            }
          })()
        : typeof schema === "object"
        ? schema
        : null;

    if (!parsedSchema || typeof parsedSchema !== "object") {
      return {
        isValid: false,
        errorMessage: t(SCHEMA_ERROR_TYPES.invalidJSON),
      };
    }

    switch (formType) {
      case JSON_EDITOR_TYPES.object:
        if (parsedSchema.type !== "object") {
          return {
            isValid: false,
            errorMessage: t(SCHEMA_ERROR_TYPES.invalidObject),
          };
        }
        if (
          !parsedSchema.properties ||
          typeof parsedSchema.properties !== "object"
        ) {
          return {
            isValid: false,
            errorMessage: t(SCHEMA_ERROR_TYPES.missingProperties),
          };
        }
        break;

      case JSON_EDITOR_TYPES.list:
        if (parsedSchema.type !== "array") {
          return {
            isValid: false,
            errorMessage: t(SCHEMA_ERROR_TYPES.invalidArraySchema),
          };
        }
        if (!parsedSchema.items) {
          return {
            isValid: false,
            errorMessage: t(SCHEMA_ERROR_TYPES.missingItems),
          };
        }
        break;

      default:
        return {
          isValid: false,
          errorMessage: t(SCHEMA_ERROR_TYPES.invalidSchema),
        };
    }

    return { isValid: true };
  }

  static formatSchemaForPayload = (schema: Schema | string | null) => {
    if (!schema) {
      return null;
    }

    let formattedData = schema;

    if (typeof schema === "string") {
      try {
        formattedData = JSON.parse(schema) as Schema;
      } catch (e) {
        return null;
      }
    }

    return formattedData;
  };

  static formatSchema(schema: Schema): Schema {
    const updatedSchema: Schema = {
      title: "",
      description: "",
      ...schema,
    };

    if (
      updatedSchema?.properties &&
      typeof updatedSchema?.properties === "object"
    ) {
      (updatedSchema.properties as { [key: string]: Schema }) = Object.entries(
        updatedSchema?.properties
      )?.reduce((acc, [key, value]) => {
        acc[key] = this.formatSchema({
          title: "",
          description: "",
          ...value,
        } as { [key: string]: Schema });
        return acc;
      }, {} as { [key: string]: Schema });
    }

    if (
      updatedSchema?.type === "array" &&
      updatedSchema?.items &&
      typeof updatedSchema?.items === "object"
    ) {
      if (!Array.isArray(updatedSchema.items)) {
        updatedSchema.items = {
          ...updatedSchema.items,
          properties: updatedSchema?.items?.properties
            ? Object.entries(updatedSchema?.items?.properties)?.reduce(
                (acc, [key, value]) => {
                  acc[key] = this.formatSchema({
                    title: "",
                    description: "",
                    ...value,
                  } as { [key: string]: Schema });
                  return acc;
                },
                {} as { [key: string]: Schema }
              )
            : undefined,
        } as Schema;
      }
    }

    return updatedSchema;
  }

  static validateAndSetError = (
    formDataKey: string,
    errorKey: string,
    errors: { [key: string]: string },
    formData: FormData,
    t: TFunction
  ) => {
    if (formData?.[formDataKey]) {
      const validationResult = this.validateSchema(
        formData?.[formDataKey] as Schema,
        JSON_EDITOR_TYPES.object,
        t
      );
      if (!validationResult.isValid) {
        errors[errorKey] = validationResult?.errorMessage || "";
      }
    }
  };
}
