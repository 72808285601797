//Module exports
import React, { FC, useState, useMemo, useEffect } from "react";
import { TFunction } from "i18next";

//Design imports
import {
  CircularProgress,
  Typography,
  Box,
  ListItem,
  ListItemButton,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Internal imports
import { AssetType } from "../../../../../types/interfaces";
import { ASSET_TYPES } from "../../../../../types/constants";
import CoreInput from "../../../../core/CoreInput";
import { useNotification } from "../../../../../context/useNotification";
import { useStores } from "../../../../../stores/StoresProvider";

interface Props {
  onSelect: (key: string) => void;
  t: TFunction;
  type: ASSET_TYPES;
  subtitle: string;
}

const AssetListDisplay: FC<Props> = ({ subtitle, onSelect, t, type }) => {
  const { flowStore } = useStores();
  const notification = useNotification();
  const theme = useTheme();

  const [formData, setFormData] = useState<AssetType[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "15px",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "25px",
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      marginTop: "10px",
    },
    noSetups: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "20px",
    },
    search: {
      width: "100%",
      marginTop: "1rem",
    },
    card: {
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      border: "1px solid rgba(217,217,217,0.2)",
      height: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        border: `1px solid`,
        cursor: "pointer",
      },
    },
    highlight: {
      color: theme.palette.highlight.main,
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  });

  useEffect(() => {
    setLoading(true);
    flowStore
      .getAssetList(type)
      .then((data) =>
        setFormData(
          data.getAssetList.filter((item) => {
            if (!item.flowType || item.flowType === flowStore.flow?.flowType) {
              return true;
            }
            return false;
          })
        )
      )
      .catch((error: Error) => {
        notification.error(t(error?.message || "selectCategory_error"));
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredOptions = useMemo(
    () =>
      formData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      ),
    [search, formData]
  );

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Typography>{subtitle}</Typography>

      <CoreInput
        onChange={onSearchChange}
        className={classes.search}
        placeholder={t("search", {
          title: t(`${type}_title`),
        })}
      />

      {loading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box className={classes.optionsContainer}>
          {filteredOptions && filteredOptions.length > 0 ? (
            filteredOptions.map(
              (item) =>
                item && (
                  <ListItem key={item.key} style={{ padding: "8px 0px" }}>
                    <ListItemButton
                      className={classes.card}
                      onClick={() => onSelect(item?.key)}
                    >
                      <Typography variant="h6">{item?.name}</Typography>

                      <Typography className={classes.highlight}>
                        {item?.description}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )
            )
          ) : (
            <Box className={classes.noSetups}>
              <Typography>
                {t("noSetup", {
                  title: t(`${type}_title`),
                })}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AssetListDisplay;
