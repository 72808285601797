import React, { FC, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { TFunction } from "i18next";

import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import { AppSuspense } from "../../../../main/AppSuspense";
import FiltersContent from "../../../activityLogs/components/FiltersContent";
import { useStores } from "../../../../../stores/StoresProvider";
import { ActivityLogsFilterTypes } from "../../../../../types/enums";
import FlowLogsTabHeaderActions from "../FlowLogsTabHeaderActions";
import DataTable from "../../../activityLogs/components/DataTable";
import LocalStorageHelper from "../../../../../helper/localStorageHelper";
import {
  ActivityLogsFilters,
  LOCAL_STORAGE_KEYS,
} from "../../../../../types/constants";
import { useNotification } from "../../../../../context/useNotification";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const extractStorageRowsPerPage = () => {
  return (
    LocalStorageHelper.getValue<{
      rowsPerPage: number;
    }>(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage: 10,
    }).rowsPerPage || 10
  );
};

export const FlowLogsTab: FC<Props> = observer(({ t, flowSettingsStore }) => {
  const { activityLogStore, userStore, flowStore } = useStores();
  const notification = useNotification();
  const { search } = useLocation<{ search: string }>();
  const { isRefreshData, currentPage, filters } = activityLogStore;

  const [paginationFilters, setPaginationFilters] = useState({
    rowsPerPage: extractStorageRowsPerPage(),
  });

  const setRowsPerPage = (rowsPerPage: number) => {
    activityLogStore.setCurrentPage(1);
    setPaginationFilters({
      rowsPerPage,
    });

    LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage,
    });
  };

  const setLocalStorageFilters = useCallback(() => {
    const activityLogsPagination = LocalStorageHelper.getValue<{
      rowsPerPage: number;
    }>(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage: 10,
    });

    setPaginationFilters({
      rowsPerPage: activityLogsPagination?.rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActivityLogs = useCallback(() => {
    const staticFilter = flowSettingsStore.flow?.identifier
      ? ({
          [ActivityLogsFilterTypes.flowsFilter]: [
            flowSettingsStore.flow?.identifier,
          ],
        } as unknown as ActivityLogsFilters)
      : undefined;

    activityLogStore
      .loadActivityLog(staticFilter)
      .then(() => setLocalStorageFilters())
      .catch((error: Error) => {
        notification.error(t(error?.message || "fetchFlowsError"));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      activityLogStore.resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set possible filters received through url
    activityLogStore.setUrlFilters(search);

    if (
      userStore.currentUserPermissions?.can("read", "flows") &&
      !flowSettingsStore.flow?.identifier
    ) {
      flowStore.getAllFlows().catch((error: Error) => {
        notification.error(t(error?.message || "fetchFlowsError"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    activityLogStore.setPageSize(paginationFilters.rowsPerPage);
    loadActivityLogs();
  }, [
    currentPage,
    filters,
    activityLogStore,
    paginationFilters.rowsPerPage,
    loadActivityLogs,
  ]);

  useEffect(() => {
    if (isRefreshData) {
      loadActivityLogs();
    }
  }, [
    isRefreshData,
    activityLogStore,
    notification,
    t,
    setLocalStorageFilters,
    loadActivityLogs,
  ]);

  return (
    <CoreTabContainer
      t={t}
      title={t(flowSettingsStore.selectedTab || "")}
      extraHeaderActions={<FlowLogsTabHeaderActions />}
      addPadding={false}
    >
      {activityLogStore.isPageLoading ||
      flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <>
          <FiltersContent
            unsupportedFilters={
              flowSettingsStore.flow?.identifier
                ? [ActivityLogsFilterTypes.flowsFilter]
                : undefined
            }
          />

          <DataTable
            rowsPerPage={paginationFilters?.rowsPerPage}
            setRowsPerPage={(rowsPerPage) =>
              setRowsPerPage(rowsPerPage as number)
            }
            totalCount={activityLogStore.totalData}
          />
        </>
      )}
    </CoreTabContainer>
  );
});
