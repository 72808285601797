import React, { FC } from "react";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";

import { Box } from "@mui/material";

import { ContextEventTypes } from "../../../../types/enums";
import {
  ContextEvent,
  ContextEventData,
} from "../../../../types/interfaces/contextEvent";
import { LOG_LEVEL_TYPES } from "../../../core/CoreLevelIcon";
import CoreTextLink from "../../../core/CoreTextLink";

interface Props {
  translation: TFunction;
  event: ContextEvent;
}

export const OperationData: FC<Props> = ({ event, translation }) => {
  const { eventType, status } = event;
  const {
    flow,
    flowIdentifier,
    documentIdentifier,
    pageIdentifier,
    triggerType,
    step,
  } = event?.eventData as ContextEventData;

  const getLinkAndText = (isPage: boolean) => {
    if (!isPage) {
      const docLink = `/flows/view/${
        flow || flowIdentifier || ""
      }/documents/${documentIdentifier}`;
      return { href: docLink, text: documentIdentifier };
    }

    const pageLink = `/flows/${
      flow || flowIdentifier || ""
    }/${documentIdentifier}/view?page=${pageIdentifier}`;

    return { href: pageLink, text: pageIdentifier };
  };

  const getComponent = (i18nKey: string, isPage: boolean, values = {}) => {
    const { href, text } = getLinkAndText(isPage);

    return (
      <Trans
        values={values}
        t={translation}
        i18nKey={i18nKey}
        components={{
          Link: <CoreTextLink to={href} label={text}></CoreTextLink>,
        }}
      />
    );
  };

  if (eventType === ContextEventTypes.UI && status === LOG_LEVEL_TYPES.error) {
    return <Box>{translation("context_event_message_ui_error")}</Box>;
  }

  if (
    eventType === ContextEventTypes.SYSTEM &&
    status === LOG_LEVEL_TYPES.error
  ) {
    return <Box>{translation("context_event_message_system_error")}</Box>;
  }

  if (
    eventType === ContextEventTypes.EXECUTE &&
    status === LOG_LEVEL_TYPES.success &&
    !!documentIdentifier &&
    !pageIdentifier
  ) {
    return getComponent(
      "context_event_message_execute_success_document",
      false,
      { documentIdentifier }
    );
  }

  if (
    eventType === ContextEventTypes.EXECUTE &&
    status === LOG_LEVEL_TYPES.error &&
    !!documentIdentifier &&
    !pageIdentifier
  ) {
    return getComponent("context_event_message_execute_error_document", false, {
      step,
    });
  }

  if (
    eventType === ContextEventTypes.EXECUTE &&
    status === LOG_LEVEL_TYPES.success &&
    !!documentIdentifier &&
    !!pageIdentifier
  ) {
    return getComponent(
      "context_event_message_execute_success_document_page",
      true,
      { step }
    );
  }

  if (
    eventType === ContextEventTypes.EXECUTE &&
    status === LOG_LEVEL_TYPES.error &&
    !!documentIdentifier &&
    !!pageIdentifier
  ) {
    return getComponent(
      "context_event_message_execute_error_document_page",
      true,
      { step }
    );
  }

  if (
    eventType === ContextEventTypes.TRIGGERS &&
    status === LOG_LEVEL_TYPES.success &&
    !!documentIdentifier &&
    !!pageIdentifier &&
    triggerType === "dataSource"
  ) {
    return (
      <Box>
        {translation("context_event_message_trigger_success_data_source", {
          triggerType,
        })}
      </Box>
    );
  }

  if (
    eventType === ContextEventTypes.TRIGGERS &&
    status === LOG_LEVEL_TYPES.error &&
    !!documentIdentifier &&
    !!pageIdentifier &&
    triggerType === "dataSource"
  ) {
    return (
      <Box>
        {translation("context_event_message_trigger_error_data_source", {
          triggerType,
        })}
      </Box>
    );
  }

  if (
    eventType === ContextEventTypes.TRIGGERS &&
    status === LOG_LEVEL_TYPES.success &&
    !!documentIdentifier &&
    !!pageIdentifier &&
    triggerType === "dataExport"
  ) {
    return (
      <Box>
        {translation("context_event_message_trigger_success_data_export", {
          triggerType,
        })}
      </Box>
    );
  }

  if (
    eventType === ContextEventTypes.TRIGGERS &&
    status === LOG_LEVEL_TYPES.error &&
    !!documentIdentifier &&
    !!pageIdentifier &&
    triggerType === "dataExport"
  ) {
    return (
      <Box>
        {translation("context_event_message_trigger_error_data_export", {
          triggerType,
        })}
      </Box>
    );
  }

  if (status === LOG_LEVEL_TYPES.pause) {
    return (
      <Box>
        {translation("context_event_message_pause_step", {
          step,
        })}
      </Box>
    );
  }

  return <>-</>;
};
