/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";
import { TFunction } from "react-i18next";

import { Box, Checkbox, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import CoreTooltip from "../../core/CoreTooltip";
import CoreCheckbox from "../../core/CoreCheckbox";
import { NAMESPACE_PERMISSIONS } from "../../../types/constants";
import { Ability } from "../../../types/interfaces";
import _ from "lodash";
import CoreAccordion from "../../core/CoreAccordion";

const useStyles = makeStyles({
  accordionDetails: {
    marginLeft: "12px",
  },
  boxAccordion: {
    display: "flex",
    padding: "5px 0",
  },
  selectAll: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "25px",
  },
});

interface Props {
  t: TFunction;
  permissions: Ability[];
  setPermissions: React.Dispatch<React.SetStateAction<Ability[]>>;
  checkboxDisabled: boolean;
}

const NamespacePermissions: React.FC<Props> = ({
  t,
  permissions,
  setPermissions,
  checkboxDisabled,
}) => {
  const groups = Object.keys(NAMESPACE_PERMISSIONS);
  const classes = useStyles();

  const allPermissions = useMemo(() => {
    return groups.flatMap((group) =>
      NAMESPACE_PERMISSIONS[group as keyof typeof NAMESPACE_PERMISSIONS].map(
        (perm) => ({ action: perm, subject: group })
      )
    );
  }, [groups]);

  const allPermissionsSelected = useMemo(() => {
    return allPermissions.every((perm) =>
      permissions.some(
        (p) => p.action === perm.action && p.subject === perm.subject
      )
    );
  }, [allPermissions, permissions]);

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setPermissions((prevPermissions) => [
        ...prevPermissions,
        ...allPermissions,
      ]);
    } else {
      const filteredPermissions = permissions.filter(
        (perm) =>
          !allPermissions.some(
            (p) => p.action === perm.action && p.subject === perm.subject
          )
      );
      setPermissions(filteredPermissions);
    }
  };

  return (
    <>
      <Box className={classes.selectAll}>
        <CoreCheckbox
          label={t("selectAllPermissions")}
          checked={allPermissionsSelected}
          onChange={handleSelectAllChange}
          disabled={checkboxDisabled}
        />
      </Box>
      {groups.map((group) => {
        const groupPermissions =
          NAMESPACE_PERMISSIONS[group as keyof typeof NAMESPACE_PERMISSIONS];

        const isChecked = groupPermissions?.every((perm) =>
          permissions?.some((p) => p.action === perm && p.subject === group)
        );

        const isIndeterminate =
          groupPermissions?.some((perm) =>
            permissions?.some((p) => p.action === perm && p.subject === group)
          ) && !isChecked;

        return (
          <CoreAccordion
            shouldRenderExpandIcon
            key={`${group}`}
            summaryChildren={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={isChecked}
                  indeterminate={isIndeterminate}
                  disabled={checkboxDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // check all permissions from group
                    if (!isChecked) {
                      const newArr = groupPermissions.map((perm) => ({
                        action: perm,
                        subject: group,
                      }));
                      setPermissions([...permissions, ...newArr]);
                    } else {
                      // uncheck all permissions from group
                      setPermissions(
                        permissions.filter(
                          (perm) =>
                            !groupPermissions.some(
                              (p) => p === perm.action && perm.subject === group
                            )
                        )
                      );
                    }
                  }}
                />
                <Typography variant="caption">{t(group)}</Typography>
              </Box>
            }
            showAccordionDetails
            accordionDetailsClassName={classes.accordionDetails}
            customAccordionBoxStyle={classes.boxAccordion}
          >
            {NAMESPACE_PERMISSIONS[
              group as keyof typeof NAMESPACE_PERMISSIONS
            ]?.map((permission) => {
              const isChecked = permissions?.some((perm) =>
                _.isEqual(perm, { action: permission, subject: group })
              );

              const handleChange = (checked: boolean) => {
                if (checked) {
                  // check if group has "read" permission
                  const hasRead = NAMESPACE_PERMISSIONS[
                    group as keyof typeof NAMESPACE_PERMISSIONS
                  ]?.some((perm) => perm === "read");

                  const newPermissions = permissions?.filter(
                    (perm) =>
                      !(perm.action === permission && perm.subject === group)
                  );

                  setPermissions([
                    ...newPermissions,
                    {
                      action: permission,
                      subject: group,
                    },
                    ...(hasRead && permission !== "read"
                      ? newPermissions?.some(
                          (perm) =>
                            perm.action === "read" && perm.subject === group
                        )
                        ? []
                        : [{ action: "read", subject: group }]
                      : []),
                  ]);
                } else {
                  setPermissions(
                    permissions?.filter(
                      (perm) =>
                        !_.isEqual(perm, {
                          action: permission,
                          subject: group,
                        })
                    )
                  );
                }
              };

              return (
                <Box
                  key={`${group}_${permission}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CoreCheckbox
                    key={permission}
                    label={
                      (
                        <Typography variant="caption">
                          {t(permission)}
                        </Typography>
                      ) as unknown as string
                    }
                    checked={isChecked}
                    onChange={(_, checked) => handleChange(checked)}
                    disabled={
                      (permissions?.some(
                        (perm) =>
                          perm.subject === group && perm.action !== "read"
                      ) &&
                        permission === "read") ||
                      checkboxDisabled
                    }
                  />
                  <CoreTooltip title={t(`${group}_${permission}_tooltip`)}>
                    <Info />
                  </CoreTooltip>
                </Box>
              );
            })}
          </CoreAccordion>
        );
      })}
    </>
  );
};

export default NamespacePermissions;
