export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/;
export const paginationInputRegex = /^[0-9]*$/;
export const codeRegex = /^$|[1-9][0-9]*$/;
export const urlRegex =
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const numberMin0 = /(^[1-9][0-9]*$)|(^$)/;
export const inputRegex = /^([a-zA-Z0-9_\-]+\s?)*$/;
export const decimalRegexOrEmpty =
  /(^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$)|(^$)/;
export const thresholdRegex = /(^[0-1]$)|(^0\.$)|(^0(?:\.|\,)\d+$)|(^$)/;
export const alphanumericRegex = /[^\w]/;
export const validUrlRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const htmlRegex =
  /<([a-z][\w-]*(?:(?:\s+[a-z][\w-]*(?:\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)?)+\s*|\s*)\/?>/;
