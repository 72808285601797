import React from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography, useTheme } from "@mui/material";

import ChatGptMessageBox from "./ChatGptMessageBox";
import {
  CHAT_GPT_MESSAGE_TYPES,
  THEME_MODES,
} from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";

interface Props {
  t: TFunction;
}

const SearchingResponse: React.FC<Props> = ({ t }) => {
  const {
    mainStore: { currentTheme },
  } = useStores();
  const theme = useTheme();

  const useStyles = makeStyles({
    loadingBox: {
      display: "flex",
      flexDirection: "row",
    },
    loadingMessage: {
      width: "250px",
    },
    loading: {
      fontSize: "14px",
      paddingRight: "5px",
      color:
        currentTheme === THEME_MODES.light
          ? theme.palette.neutral.dark
          : theme.palette.primary.light,
      "&:after": {
        content: '" ."',
        animation: "$dots 1s steps(5, end) infinite",
      },
    },
    "@keyframes dots": {
      "0%, 20%": {
        color:
          currentTheme === THEME_MODES.light
            ? "rgba(0,0,0,0.5)"
            : "rgba(0,0,0,0)",
        textShadow:
          currentTheme === THEME_MODES.light
            ? ".25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)"
            : ".25em 0 0 rgba(0,0,0,0.5), .5em 0 0 rgba(0,0,0,0.5)",
      },
      "40%": {
        color: currentTheme === THEME_MODES.light ? "black" : "white",
        textShadow:
          currentTheme === THEME_MODES.light
            ? ".25em 0 0 rgba(0,0,0,0.5), .5em 0 0 rgba(0,0,0,0.5)"
            : ".25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)",
      },
      "60%": {
        textShadow:
          currentTheme === THEME_MODES.light
            ? ".25em 0 0 black, .5em 0 0 rgba(0,0,0,0.5)"
            : ".25em 0 0 white, .5em 0 0 rgba(0,0,0,0)",
      },
      "80%, 100%": {
        textShadow:
          currentTheme === THEME_MODES.light
            ? ".25em 0 0 black, .5em 0 0 black"
            : ".25em 0 0 white, .5em 0 0 white",
      },
    },
  });

  const classes = useStyles();

  return (
    <ChatGptMessageBox
      forceHideActions
      type={CHAT_GPT_MESSAGE_TYPES.claritext}
      text={
        <Box className={classes.loadingBox}>
          <Typography className={classes.loading}>
            {t("searchingResponse")}
          </Typography>
        </Box>
      }
      messageStyle={classes.loadingMessage}
    />
  );
};

export default SearchingResponse;
