import React, { useState, useEffect } from "react";
import { TFunction } from "i18next";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import CoreDrawer from "../../core/CoreDrawer";
import CoreButton from "../../core/CoreButton";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { FIELD_TYPE } from "../../../types/constants";
import { Ability, FormData, FormField } from "../../../types/interfaces";
import { emailRegex } from "../../../types/regex";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import NamespacePermissions from "./NamespacePermissions";

interface InviteMemberProps {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  icon: {
    fontSize: 20,
  },
  footer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    marginTop: "auto",
    paddingTop: "30px",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "12px",
  },
});

export const InviteMember: React.FC<InviteMemberProps> = ({
  isOpen,
  t,
  onClose,
}) => {
  const classes = useStyles();
  const { userStore } = useStores();
  const apolloClient = useApolloClient();
  const notification = useNotification();
  const [permissions, setPermissions] = useState<Ability[]>([]);

  const [fields, setFields] = useState<FormData>({});
  const [sendInProgress, setSendInProgress] = useState(false);

  //Clear fields on after closing drawer
  useEffect(() => {
    if (!isOpen) {
      setFields({});
      setSendInProgress(false);
      setPermissions([]);
    }
  }, [isOpen]);

  const sendLink = () => {
    if (Object.keys(permissions)?.length === 0) {
      notification.error(t("onePermissionRequired"));
      return;
    }

    if (!emailRegex.test((fields?.email as string) ?? "")) {
      notification.error(t("emailNotValid"));
      return;
    }
    setSendInProgress(true);
    userStore
      .checkInvitationLinkEmail(apolloClient, fields?.email as string)
      .then(() => {
        void userStore
          .sendInvitationLink(apolloClient, {
            ...fields,
            perms: permissions,
          })
          .then(() => {
            setSendInProgress(false);
            onClose();
            notification.success(t("sendSuccess"));
          })
          .catch(() => {
            setSendInProgress(false);
            notification.error(t("emailNotSent"));
          });
      })
      .catch((error: Error) => {
        setSendInProgress(false);
        notification.error(t(error?.message || "emailExists"));
      });
  };

  const formFields: FormField[] = [
    {
      key: "email",
      type: FIELD_TYPE.input,
      isMandatory: true,
      props: {
        title: t("email"),
        value: fields?.first_name,
        type: "email",
        placeholder: t("insertEmailAddress"),
        disabled: sendInProgress,
      },
      translationKey: "email",
    },
  ];

  const handleInputChange = (value: FormData) =>
    setFields({ ...fields, ...value });

  return (
    <CoreDrawer isOpen={isOpen} onClose={onClose} title={t("inviteTeamMember")}>
      <CoreFormRenderer
        fields={formFields}
        data={fields}
        onChange={handleInputChange}
        translation={t}
      />

      <Box className={classes.optionsContainer}>
        <NamespacePermissions
          t={t}
          permissions={permissions}
          setPermissions={setPermissions}
          checkboxDisabled={sendInProgress}
        />
      </Box>
      <Box className={classes.footer}>
        <CoreButton
          disabled={
            sendInProgress ||
            Object.keys(permissions)?.length === 0 ||
            (fields?.email as string)?.length === 0
          }
          onClick={sendLink}
          variant="contained"
          isLoading={sendInProgress}
        >
          {t("invite_member")}
          <ChevronRightIcon className={classes.icon} />
        </CoreButton>
      </Box>
    </CoreDrawer>
  );
};
