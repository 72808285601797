import React from "react";
import { TFunction } from "i18next";
import Flag from "react-flagkit";

import { MenuItem, MenuList, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";

import i18n from "../../../../i18n";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { THEME_MODES } from "../../../../types/constants";

interface Country {
  code: string;
  name: string;
}

interface Props {
  countries: Country[];
  t: TFunction;
}

const LanguagesMenu: React.FC<Props> = ({ t, countries }) => {
  const theme = useTheme();

  const { mainStore } = useStores();
  const notification = useNotification();

  const useStyle = makeStyles({
    listItem: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    selectedLanguage: {
      backgroundColor:
        mainStore.currentTheme === THEME_MODES.dark
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    },
    flagBox: {
      display: "flex",
      flex: 1,
    },
    flag: {
      width: "20px",
      height: "20px",
    },
    menuText: {
      fontSize: "14px",
      display: "flex",
      flex: 1,
    },
  });
  const classes = useStyle();

  const handleGBlanguage = (countryCode: string) => {
    if (countryCode === "en") {
      return "GB";
    }
    return countryCode.toUpperCase();
  };

  const changeLanguage = (countryCode: string) => {
    let hasError = false;

    i18n.changeLanguage(countryCode).catch(() => {
      hasError = true;
      notification.error(t("changeLanguageError"));
    });

    if (!hasError) {
      const finalCountryCode =
        countryCode === "en" ? "GB" : countryCode.toUpperCase();
      mainStore.setLanguageFlag(finalCountryCode);
    }
  };

  return (
    <MenuList>
      {countries.map((country, index) => {
        const languageCode = handleGBlanguage(country?.code);
        return (
          <MenuItem
            key={index}
            className={`
            ${
              mainStore.languageFlag === languageCode
                ? `${classes.selectedLanguage} `
                : ""
            }${classes.listItem}
          `}
            onClick={() => {
              changeLanguage(country.code);
            }}
          >
            <Box className={classes.flagBox}>
              <Flag country={languageCode} className={classes.flag} />
            </Box>

            <span className={classes.menuText}>{t(country.name)}</span>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

export default LanguagesMenu;
