import { gql } from "@apollo/client";
import { Ability, DevSettings } from "../../types/interfaces";

export interface FILTER_CURRENT_USER_IDENTITY_IDENTITY {
  filterIdentityForUser: Ability[] | Record<string, Ability[]>;
}

export const FILTER_CURRENT_USER_IDENTITY = gql`
  query filterIdentityForUser($userId: String) {
    filterIdentityForUser(userId: $userId)
  }
`;

export interface FILTER_IDENTITY_TYPE {
  filterIdentity: {
    appId: string;
    permissionSchema: Record<string, unknown>;
  }[];
}

export const FILTER_IDENTITY = gql`
  query filterIdentity($appId: String!, $permissionSchemaFilters: JSON) {
    filterIdentity(
      appId: $appId
      permissionSchemaFilters: $permissionSchemaFilters
    )
  }
`;

export interface GET_IDENTITY_TYPE {
  getIdentity: {
    appId: string;
    permissionSchema: Record<string, unknown>;
    devSettings: DevSettings;
  };
}

export const GET_IDENTITY = gql`
  query getIdentity($appId: String!) {
    getIdentity(appId: $appId)
  }
`;
