import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { Menu, MenuItem, MenuList, Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import EditIcon from "@mui/icons-material/Edit";

import Portal from "./Portal";
import { DocumentStore } from "../../../../../../../stores/DocumentStore";
import {
  DocumentLineItemLine,
  DocumentZoneBox,
} from "../../../../../../../types/interfaces";
import CanvasHelper from "../../../../../../../helper/canvas/canvasHelper";
import { ASSET_BACKEND_TYPES } from "../../../../../../../types/constants";
import { NestedMenuItem } from "../../../../../../core/nestedDropdown/NestedMenuItem";

interface Props {
  open: boolean;
  onClose: () => void;
  headerKey: string | null;
  documentStore: DocumentStore;
  anchorReference: "anchorPosition" | "anchorEl";
  tableCoords: DocumentZoneBox | null;
  anchorPosition:
    | {
        top: number;
        left: number;
      }
    | undefined;
  theme: Theme;
}

interface MenuOption {
  name: string;
  key?: string;
  icon?: React.ReactElement;
  action?: () => void;
  hidden: boolean;
  disabled?: boolean;
  isHeaderInUse?: boolean;
  children?: MenuOption[];
}
const RightClickMenuHeader: FC<Props> = observer(
  ({
    onClose,
    open,
    anchorReference,
    anchorPosition,
    documentStore,
    headerKey,
    tableCoords,
    theme,
  }) => {
    const useStyles = makeStyles({
      rightClickMenuText: {
        marginLeft: 10,
      },
      headerInUse: {
        color: alpha(theme.palette.surface.dark, 0.7),
      },
    });
    const classes = useStyles();
    const { t } = useTranslation("documents");

    const isHeaderIgnored = useMemo(() => {
      const unusedHeaders = documentStore?.unusedLineItemHeaders?.map(
        (header) => header.key
      );

      if (unusedHeaders?.includes(headerKey ?? "")) {
        return true;
      }
      return false;
    }, [documentStore?.unusedLineItemHeaders, headerKey]);

    const handleIgnoreColumn = () => {
      documentStore.replaceLineItemColumn(headerKey as string, uuidv4());
    };

    const headerCoords = useMemo(() => {
      const header = documentStore?.usedLineItemHeaders?.find(
        (header) => header.key === headerKey
      );
      return header?.box ?? null;
    }, [documentStore?.usedLineItemHeaders, headerKey]);

    const isLeftmostColumn = useMemo(() => {
      return CanvasHelper.isDifferenceLessThan2px(
        headerCoords?.x ?? 0,
        tableCoords?.x ?? 0
      );
    }, [headerCoords?.x, tableCoords?.x]);

    const isRightmostColumn = useMemo(() => {
      const headerRight = (headerCoords?.x ?? 0) + (headerCoords?.width ?? 0);
      const tableRight = (tableCoords?.x ?? 0) + (tableCoords?.width ?? 0);
      return CanvasHelper.isDifferenceLessThan2px(headerRight, tableRight);
    }, [headerCoords, tableCoords]);

    const getLine = (isLeft: boolean) => {
      const startX = isLeft
        ? headerCoords?.x ?? 0
        : (headerCoords?.x ?? 0) + (headerCoords?.width ?? 0);

      const startY = tableCoords?.y ?? 0;
      const endY = startY + (tableCoords?.height ?? 0);

      return [
        [startX, startY],
        [startX, endY],
      ];
    };

    const handleMergeColumn = (isLeft: boolean) => {
      const line = getLine(isLeft) as DocumentLineItemLine;
      documentStore.mergeLineItemColumns(line);
    };

    const columnOptions = useMemo(() => {
      if (!documentStore.lineItemHeaderTypeUpdate) {
        return [];
      }

      const enhancementOptions = documentStore.focusedLineItemHeaders
        ?.filter(
          (item) =>
            item.key !== documentStore.lineItemHeaderTypeUpdate &&
            item?.source === ASSET_BACKEND_TYPES.enhancement
        )
        .map((header) => ({
          label: header.name,
          key: header.key,
        }));

      const columnOptions =
        documentStore.focusedLineItemHeaders
          ?.filter(
            (item) =>
              item.key !== documentStore.lineItemHeaderTypeUpdate &&
              item?.source !== ASSET_BACKEND_TYPES.enhancement
          )
          ?.map((header) => ({
            label: header.name,
            key: header.key,
            isDisabled: false,
            divider: false,
          })) || [];

      return [...(columnOptions || []), ...(enhancementOptions || [])];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      documentStore.focusedLineItemHeaders,
      documentStore.lineItemHeaderTypeUpdate,
    ]);

    const isHeaderInUse = (fieldKey: string) => {
      return documentStore.lineItems
        .find((lineItem) => lineItem.type === documentStore.focusedFieldCanvas)
        ?.headers?.find((item) => item.key === fieldKey);
    };

    const MenuOptions: MenuOption[] = [
      {
        name: "addInUseColumn",
        icon: <DeleteIcon fontSize="small" />,
        hidden: !isHeaderIgnored,
        disabled: true,
        children: [
          ...columnOptions.map((option) => ({
            name: option.label,
            key: option.key,
            action: () => {
              documentStore.replaceLineItemColumn(
                documentStore.lineItemHeaderTypeUpdate as string,
                option.key
              );
              onClose();
            },
            hidden: isHeaderIgnored,
          })),
        ],
      },
      {
        name: "ignoreColumn",
        icon: <VisibilityOffIcon fontSize="small" />,
        action: handleIgnoreColumn,
        hidden: isHeaderIgnored,
      },
      {
        name: "modifyColumn",
        icon: <EditIcon fontSize="small" />,
        hidden: isHeaderIgnored,
        disabled: true,
        children: [
          ...columnOptions.map((option) => ({
            name: option.label,
            key: option.key,
            action: () => {
              documentStore.replaceLineItemColumn(
                documentStore.lineItemHeaderTypeUpdate as string,
                option.key
              );
              onClose();
            },
            hidden: isHeaderIgnored,
            isHeaderInUse: isHeaderInUse(option.key),
          })),
        ],
      },

      {
        name: "mergeLeftColumn",
        icon: <WestIcon fontSize="small" />,
        action: () => handleMergeColumn(true),
        hidden: isLeftmostColumn,
      },

      {
        name: "mergeRightColumn",
        icon: <EastIcon fontSize="small" />,
        action: () => handleMergeColumn(false),
        hidden: isRightmostColumn,
      },
    ];

    const getOption = (option: MenuOption) => {
      if (option.hidden) {
        return null;
      }
      if (option.children === undefined) {
        return (
          <MenuItem
            key={option.key ?? option.name}
            onClick={() => {
              option.action && option.action();
              onClose();
            }}
          >
            {option.icon}
            <span className={classes.rightClickMenuText}>{t(option.name)}</span>
          </MenuItem>
        );
      }

      return (
        <NestedMenuItem
          parentMenuOpen={open}
          onClick={() => {
            if (option.action) {
              option.action();
              onClose();
            }
          }}
          key={option.name}
          leftIcon={option.icon}
          label={t(option.name)}
        >
          {(option.children ?? []).map((child) => (
            <MenuItem
              key={child.key ?? child.name}
              className={child.isHeaderInUse ? classes.headerInUse : ""}
              onClick={() => {
                if (child.action) {
                  child.action();
                  onClose();
                }
              }}
            >
              {child.icon}
              <span className={classes.rightClickMenuText}>
                {t(child.name)}
              </span>
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
    };

    return (
      <Portal>
        <Menu
          open={open}
          onClose={onClose}
          anchorReference={anchorReference}
          anchorPosition={anchorPosition}
        >
          <MenuList>{MenuOptions.map((option) => getOption(option))}</MenuList>
        </Menu>
      </Portal>
    );
  }
);

export default RightClickMenuHeader;
