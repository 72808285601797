/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from "mobx";
import { GraphQLError } from "graphql";
import { TFunction } from "i18next";
import { v4 as uuidv4 } from "uuid";
import { Node, Edge, MarkerType, ReactFlowInstance } from "reactflow";
import { NotificationContextProps } from "../context/useNotification";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  FlowDiagram,
  FlowInterface,
  AssetType,
  FlowNode,
  FormData,
  NodeData,
  InputOptions,
  DuplicatedFlowConfig,
  SelectOptionDef,
  NodeAnchor,
  FormField,
  Variable,
  NodeErrors,
  AssetTemplateParameters,
} from "../types/interfaces";
import {
  FLOW_SETTINGS_TABS,
  NODE_ASSET_TYPE,
  DIAGRAM_NODE_DELETE_MODES,
  DIAGRAM_NODE_CATEGORIES,
  NODE_TYPES,
  NODE_CONDITIONAL_BRANCHES,
  NODES_ALLOWED_TYPES,
  FlowEnum,
  ContextFieldTypes,
} from "../types/constants";
import NodesHelper from "../components/pages/flow/flow-settings/helper/nodesHelper";
import { RootStore } from "./StoresProvider";

import {
  CREATE_FLOW_TYPE,
  CREATE_FLOW,
  UPDATE_FLOW_FROM_DRAFT_TYPE,
  UPDATE_FLOW,
  DELETE_DIAGRAM_VERSION,
  PROMOTE_TO_CURRENT,
  CREATE_DIAGRAM,
  CREATE_DIAGRAM_RESPONSE,
  DUPLICATE_DIAGRAM,
  DUPLICATE_DIAGRAM_RESPONSE,
  DUPLICATE_FLOW,
  DUPLICATE_FLOW_TYPE,
  IMPORT_FLOW,
  IMPORT_FLOW_TYPE,
  IMPORT_DIAGRAM_RESPONSE,
  IMPORT_DIAGRAM,
  APPLY_DIAGRAM_TEMPLATE_RESPONSE,
  APPLY_DIAGRAM_TEMPLATE,
} from "./mutations/flows";
import {
  CHECK_FLOW_NAME,
  CHECK_FLOW_NAME_RESPONSE,
  GET_FLOW_DIAGRAMS_TYPE,
  GET_FLOW_DIAGRAMS,
  GET_FLOW_DIAGRAM_TYPE,
  GET_FLOW_DIAGRAM,
  GET_LATEST_FLOW_DIAGRAM,
  GET_LATEST_FLOW_DIAGRAM_TYPE,
  GET_EXPORTABLE_OBJECTS,
  GET_EXPORTABLE_OBJECTS_TYPE,
  GET_CONTEXT_OBJECTS_TYPE,
  GET_CONTEXT_OBJECTS,
  GET_DIAGRAM_CONFIGS,
  GET_DIAGRAM_CONFIGS_TYPE,
} from "./queries/flows";
import FlowHelper from "../helper/flowHelper";

export class FlowSettingsStore {
  root: RootStore;
  flow: FlowInterface | undefined;
  selectedTab = FLOW_SETTINGS_TABS.designer;
  pendingTab = "";
  loadingUpdateFlow = false;
  loadingFlowSettings = true;
  refetchFlowSettings = false;
  duplicateFlowConfig: DuplicatedFlowConfig | undefined;
  nodesList: AssetType[] = [];

  //Diagram
  isDiagramEditable = false;
  nodeIdToEdit: string | null = null; // Used to open drawer in edit mode
  isAddNodeOpened = false; // Used to open drawer in create new node mode
  flowDiagram: FlowDiagram | null = null; // current nodes for flow
  nonAlteredFlowDiagram: FlowDiagram | null = null;
  contextObjects: Variable[] = [];
  nodeErrors: NodeErrors = {};
  menuPopupOpen = false;
  menuPopupAnchor: HTMLDivElement | null = null;
  selectedEdgeId?: string;
  flowDiagrams: FlowDiagram[] = [];
  currentDiagramId: string | null = null;
  diagramHasBeenUpdated = false;
  loadingDiagram = false;
  loadingCreateDiagram = false;
  parentNodeId: string | null = null;
  conditionalPlaceholderFocus: string | null = null;
  parallelNodeMode = false;
  triggerDiagramPngExport = false;
  nodeDimensions: Record<
    string,
    {
      width: number;
      height: number;
    }
  > = {};
  layoutingNeeded = false;

  isSuggestionsModalOpen = false;
  nodeInputOptions: AssetType[] = [];
  nodeCategories: SelectOptionDef[] = [];
  approvalRules: AssetType[] = [];
  loadingRules = false;
  nodeAnchor: NodeAnchor | null = null;
  generatedSchemaField: FormField | null = null;
  loadingContextObjects = false;

  forceRetriggerRendererValue = false;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  setFlow = (value: FlowInterface) => {
    this.flow = value;
  };

  setNodeAnchor = (value: NodeAnchor | null) => {
    this.nodeAnchor = value;
  };

  setDuplicateFlowConfig = (value: DuplicatedFlowConfig | undefined) => {
    this.duplicateFlowConfig = value;
  };

  setSelectedTab = (tab: string) => {
    this.selectedTab = tab;
  };

  retriggerRendererValue = (value: boolean) => {
    this.forceRetriggerRendererValue = value;
  };

  setGeneratedSchemaField = (value: FormField | null) => {
    this.generatedSchemaField = value;
  };

  setIsDiagramEditable = (isEditable: boolean) => {
    this.isDiagramEditable = isEditable;
  };

  setTriggerDiagramPngExport = (clicked: boolean) => {
    this.triggerDiagramPngExport = clicked;
  };

  setSuggestionsModalOpen = (value: boolean) => {
    this.isSuggestionsModalOpen = value;
  };

  setNodeInputOptions = (value: AssetType[]) => {
    this.nodeInputOptions = value;
  };

  setPendingTab = (tab: string) => {
    this.pendingTab = tab;
  };

  setNodeCategories = (value: SelectOptionDef[]) => {
    this.nodeCategories = value;
  };

  setFlowDiagramsList = (value: FlowDiagram[]) => {
    this.flowDiagrams = value;
  };

  setApprovalRules = (value: AssetType[]) => {
    this.approvalRules = value;
  };

  setContextObjects = (value: Variable[]) => {
    this.contextObjects = value;
  };

  setLoadingContextObjects = (value: boolean) => {
    this.loadingContextObjects = value;
  };

  setNodeDimensions = (
    key: string,
    dimension: { width: number; height: number }
  ) => {
    this.nodeDimensions[key] = dimension;
  };

  setLayoutingNeeded = (value: boolean) => {
    this.layoutingNeeded = value;
  };

  setNodeErrors = (errors: NodeErrors) => {
    this.nodeErrors = errors;
  };

  setLoadingDiagram = (value: boolean) => {
    this.loadingDiagram = value;
  };

  getNodeInputOptions = (
    diagramNodes: FlowNode[],
    diagramEdges: Edge[],
    field: FormField,
    t: TFunction
  ) => {
    try {
      const nodes = NodesHelper.attachSource(diagramNodes, diagramEdges);
      const hasParentNode = nodes.some(
        (node) =>
          node.id === this.nodeIdToEdit &&
          node.type !== DIAGRAM_NODE_CATEGORIES.placeholder &&
          node.parentId
      );
      const currentParent = nodes.find((node) => node.id === this.parentNodeId);

      const nodeAllowedTypes = NodesHelper.validateAllowedTypes(
        field?.allowedTypes
      );

      const shouldAllowedIteration = Boolean(
        (currentParent?.parentId || hasParentNode) &&
          currentParent?.key !== NODE_ASSET_TYPE.conditional
      );

      const hasAnyType = nodeAllowedTypes?.includes(NODES_ALLOWED_TYPES.any);

      const allowedTypes = shouldAllowedIteration
        ? [...nodeAllowedTypes, NODES_ALLOWED_TYPES.iteration]
        : nodeAllowedTypes;

      const nodeIdOrParentId = this.nodeIdToEdit || this.parentNodeId || "";
      const filteredContextVariables = [
        ...this.getContextVariablesAboveNode(
          this.contextObjects,
          nodes,
          nodeIdOrParentId
        ),
      ];

      let contextVariableCategoryAndOptions: InputOptions[] = [];

      const categorizedOptions =
        this.nodeInputOptions?.flatMap((option) => {
          return [
            {
              category: option.name,
              key: option.name,
              type: option.name,
              value: option.name,
            },
            ...((
              option.parameters as unknown as [{ [key: string]: string }]
            )?.map((param) => ({
              category: option.name,
              key: `${option.name}.${param.key}`,
              type: param.type,
              value: param.key,
            })) || []),
          ];
        }) || [];

      if (filteredContextVariables && filteredContextVariables?.length > 0) {
        contextVariableCategoryAndOptions = [
          ...filteredContextVariables.map((variable) => ({
            ...variable,
            category: t("contextVariables"),
            value: variable.name,
          })),
        ] as InputOptions[];
      }

      return [
        ...categorizedOptions,
        ...contextVariableCategoryAndOptions,
        ...this.getIterationOptions(nodes, filteredContextVariables),
      ]?.filter((option) => hasAnyType || allowedTypes?.includes(option.type));
    } catch {
      // In case something crushes
      return [];
    }
  };

  getIterationOptions = (nodes: FlowNode[], currentOptions: Variable[]) => {
    let options =
      [
        {
          category: "iteration",
          key: "index",
          type: "iteration",
          value: "index",
        },
        {
          category: "iteration",
          key: "value",
          type: "iteration",
          value: "value",
        },
      ] || [];

    if (this.nodeIdToEdit) {
      const nodeToEdit = nodes?.find(
        (node) => node.identifier === this.nodeIdToEdit
      );

      if (nodeToEdit && nodeToEdit?.parameters?.allowIterationIndex === false) {
        options = options?.filter((option) => option?.key !== "index");
      }

      if (
        nodeToEdit?.parentId &&
        nodeToEdit?.key !== NODE_ASSET_TYPE.conditional &&
        nodeToEdit?.key !== NODE_ASSET_TYPE.parallelIteration &&
        NodesHelper.conditionalNodeCheck(nodes, nodeToEdit?.parentId)
      ) {
        const parentIterationProps = NodesHelper.extractParentIterationProps(
          nodes,
          nodeToEdit?.parentId,
          currentOptions
        );
        return [...options, ...(parentIterationProps || [])];
      }
    }

    if (this.parentNodeId) {
      const parentNode = nodes.find((node) => node.id === this.parentNodeId);

      if (parentNode && parentNode?.parameters?.allowIterationIndex === false) {
        options = options?.filter((option) => option?.key !== "index");
      }

      if (
        parentNode?.parentId &&
        parentNode?.key !== NODE_ASSET_TYPE.conditional &&
        NodesHelper.conditionalNodeCheck(nodes, parentNode?.parentId)
      ) {
        const parentIterationProps = NodesHelper.extractParentIterationProps(
          nodes,
          parentNode?.parentId,
          currentOptions
        );
        return [...options, ...(parentIterationProps || [])];
      }
    }

    return [];
  };

  getNodeOutputOptions = (
    nodes: FlowNode[],
    t: TFunction,
    allowNested?: boolean
  ) => {
    const nodeIdOrParentId = this.nodeIdToEdit || this.parentNodeId || "";
    const filteredContextVariables = [
      ...this.getContextVariablesAboveNode(
        this.contextObjects,
        nodes,
        nodeIdOrParentId
      ),
    ];

    let contextVariableCategoryAndOptions: InputOptions[] = [];

    if (filteredContextVariables && filteredContextVariables?.length > 0) {
      contextVariableCategoryAndOptions = [
        ...filteredContextVariables.map((variable) => ({
          ...variable,
          category: t("contextVariables"),
          value: variable.name,
        })),
      ] as InputOptions[];
    }

    if (!allowNested) {
      return contextVariableCategoryAndOptions.filter(
        (option) => !option.isDynamicContent
      );
    }

    return contextVariableCategoryAndOptions;
  };

  getContextVariablesAboveNode(
    variables: Variable[],
    nodes: FlowNode[],
    nodeId: string
  ): Variable[] {
    const parentNodeIds = new Set<string>();
    parentNodeIds.add(nodeId);

    NodesHelper.collectParentNodeIds(nodeId, parentNodeIds, nodes);

    const filteredVariables = variables?.filter((variable) =>
      parentNodeIds.has(variable?.nodeIdentifier)
    );

    return filteredVariables;
  }

  resetDiagramStates = () => {
    this.isDiagramEditable = false;
    this.flowDiagram = null;
    this.nonAlteredFlowDiagram = null;
    this.menuPopupOpen = false;
    this.menuPopupAnchor = null;
    this.selectedEdgeId = undefined;
    this.parentNodeId = null;
    this.conditionalPlaceholderFocus = null;
    this.parallelNodeMode = false;
    this.diagramHasBeenUpdated = false;
    this.currentDiagramId = null;
    this.isAddNodeOpened = false;
    this.nodeIdToEdit = null;
    this.flowDiagrams = [];
    this.nodesList = [];
    this.isSuggestionsModalOpen = false;
    this.pendingTab = "";
    this.nodeCategories = [];
    this.loadingRules = false;
    this.nodeInputOptions = [];
    this.nodeAnchor = null;
    this.generatedSchemaField = null;
    this.nodeDimensions = {};
    this.selectedTab = FLOW_SETTINGS_TABS.designer;
    this.refetchFlowSettings = false;
    this.contextObjects = [];
    this.loadingContextObjects = false;
    this.loadingFlowSettings = true;
    this.nodeErrors = {};
  };

  deleteDiagramNode(
    reactFlow: ReactFlowInstance,
    identifier: string,
    mode: string,
    notification: NotificationContextProps,
    t: TFunction
  ) {
    try {
      let nodeIdentifiers = [] as string[];
      let isConditionalChildrenDelete = false;

      const targetNode = reactFlow.getNode(identifier) as Node<any>;

      if (
        mode === DIAGRAM_NODE_DELETE_MODES.groupChildren &&
        (reactFlow.getNode(targetNode?.id) as FlowNode)?.key ===
          NODE_ASSET_TYPE.conditional
      ) {
        isConditionalChildrenDelete = true;
      }

      if (
        targetNode.type === DIAGRAM_NODE_CATEGORIES.placeholder &&
        targetNode.parentId &&
        mode !== DIAGRAM_NODE_DELETE_MODES.groupChildren
      ) {
        // if we have a placeholder with a parent id, we actually want the parent id
        identifier = targetNode.parentId;
      }

      if (mode === DIAGRAM_NODE_DELETE_MODES.hierarchy) {
        nodeIdentifiers = NodesHelper.deleteNodeHierarchy(
          reactFlow,
          identifier
        );
      } else if (mode === DIAGRAM_NODE_DELETE_MODES.groupChildren) {
        nodeIdentifiers = NodesHelper.deleteGroupChildren(
          reactFlow,
          identifier
        );
      } else {
        nodeIdentifiers = NodesHelper.deleteSingleNode(reactFlow, identifier);
      }

      const newNodes = reactFlow
        .getNodes()
        .filter((node) => !nodeIdentifiers.includes(node.id));

      const newEdges = NodesHelper.deleteEdgesFixup(
        reactFlow,
        identifier,
        nodeIdentifiers
      );

      if (
        mode !== DIAGRAM_NODE_DELETE_MODES.groupChildren &&
        targetNode.parentId &&
        newNodes.find((node) => node.id === targetNode.parentId) &&
        (reactFlow.getNode(targetNode.parentId) as FlowNode).key ===
          NODE_ASSET_TYPE.conditional
      ) {
        // we have deleted the root node of a branch
        // make sure to re-add a placeholder node

        const childNodes = newNodes.filter(
          (node) => node.parentId === targetNode.parentId
        );

        const trueBranchMissing = childNodes.every(
          (node) => !(node as FlowNode).trueBranch
        );

        const falseBranchMissing = childNodes.every(
          (node) => !(node as FlowNode).falseBranch
        );

        if (trueBranchMissing || falseBranchMissing) {
          const placeholderId = uuidv4();
          const placeholderNode = {
            ...targetNode,
            id: placeholderId,
            identifier: placeholderId,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: {
              ...(targetNode.data as unknown as any),
              identifier: placeholderId,
              type: NODE_TYPES.passthrough,
            },
            type: DIAGRAM_NODE_CATEGORIES.placeholder,
          } as FlowNode;

          const newEdge = {
            ...(reactFlow
              .getEdges()
              .find((edge) => edge.target === targetNode.id) as Edge<any>),
            id: uuidv4(),
            target: placeholderId,
          };

          newNodes.push(placeholderNode as Node<any>);
          newEdges.push(newEdge);
        }
      }

      //Case for deleting conditional node children
      //Instead of deleting the first two children of the conditional node and then adding placeholders again, we need to overwrite the first two nodes with the placeholder configuration
      let conditionalPlaceHolders = [] as Node[];

      if (isConditionalChildrenDelete) {
        const conditionalNode = reactFlow
          .getNodes()
          ?.find((node) => node?.id === targetNode?.parentId) as FlowNode;

        //Nodes that are children of the conditional node and are scheduled for deletion
        const filteredNodes = reactFlow
          .getNodes()
          ?.filter(
            (node) =>
              node?.parentId === conditionalNode?.id &&
              nodeIdentifiers?.includes(node.id)
          );

        //First two children of the conditional node
        const firstTwoChildren = filteredNodes
          .filter((node) => {
            const isChildNode = node?.parentId === conditionalNode?.identifier;
            const isTrueOrFalseBranch =
              (node as FlowNode)?.trueBranch || (node as FlowNode)?.falseBranch;
            return isChildNode && isTrueOrFalseBranch;
          })
          ?.slice(0, 2);

        conditionalPlaceHolders = (firstTwoChildren || [])?.map((node) => ({
          ...({
            ...node,
            parameters: conditionalNode?.parameters,
            [NODE_CONDITIONAL_BRANCHES.trueBranch]: (node as FlowNode)
              ?.trueBranch,
            [NODE_CONDITIONAL_BRANCHES.falseBranch]: (node as FlowNode)
              ?.falseBranch,
          } as Node),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: {
            ...(conditionalNode?.data as unknown as any),
            identifier: node?.id,
            type: NODE_TYPES.passthrough,
          },
          key: conditionalNode?.key,
          name: conditionalNode?.name,
          description: conditionalNode?.description,
          type: DIAGRAM_NODE_CATEGORIES.placeholder,
          id: node?.id,
          identifier: node?.id,
        }));
      }

      if (
        (targetNode as unknown as FlowNode)?.parameters?.refreshContextObjects
      ) {
        void this.loadContextObjects({ nodes: newNodes as FlowNode[] }).catch(
          (error: Error) => {
            notification.error(t(error?.message || "contextObjectsError"));
          }
        );
      }

      if (isConditionalChildrenDelete) {
        //Exclude conditional placeholders from deletion
        const filteredNodeIdentifiers =
          nodeIdentifiers?.filter(
            (nodeId) =>
              !conditionalPlaceHolders
                ?.map((node) => node?.id)
                ?.includes(nodeId)
          ) || [];

        reactFlow.setNodes([...newNodes, ...conditionalPlaceHolders]);

        //Fix up the edges while keeping the conditional placeholders
        //We need to fix up the edges only if there are more than two nodes in the conditional node that has been deleted; otherwise, we only overwrite the first two nodes
        if (filteredNodeIdentifiers && filteredNodeIdentifiers?.length > 0) {
          const fixupEdges = NodesHelper.deleteConditionalEdgesFixup(
            reactFlow,
            filteredNodeIdentifiers
          );

          reactFlow.setEdges(fixupEdges);
        }
      } else {
        reactFlow.setNodes(newNodes);
        reactFlow.setEdges(newEdges);
      }

      this.retriggerDiagram();
    } catch (error) {
      notification.error(t("errorDeletingNode"));
    }
  }

  checkConnectionErrors = (nodes: FlowNode[]) => {
    const connectionIds =
      this.root.userStore?.connections?.map(
        (connection) => connection?.identifier
      ) || [];

    if (nodes && nodes?.length > 0) {
      nodes?.forEach((node) => {
        if (
          node?.parameters?.connectionId &&
          !connectionIds?.includes(node?.parameters?.connectionId as string)
        ) {
          const existingErrors = this.nodeErrors?.[node?.id] || [];

          if (!existingErrors?.includes(FlowEnum.connectionId)) {
            this.setNodeErrors({
              ...this.nodeErrors,
              [node?.id]: [
                ...existingErrors,
                FlowEnum.connectionId,
              ] as string[],
            });
          }
        }
      });
    }
  };

  validateNodeFields = (
    identifier: string,
    setFieldsErrors: (value: { [key: string]: string }) => void,
    fieldsErrors: { [key: string]: string },
    fields: AssetTemplateParameters[],
    form: Record<string, unknown>,
    t: TFunction
  ) => {
    let errors = { ...fieldsErrors } as { [key: string]: string };

    if (
      Object.keys(this.nodeErrors || {})?.length > 0 &&
      Object.keys(this.nodeErrors || {})?.includes(identifier || "")
    ) {
      const fieldErrors = this.nodeErrors?.[identifier || ""];

      const updatedFieldErrors = fieldErrors?.reduce((acc, key) => {
        acc[key] = t("invalidValue");
        return acc;
      }, {} as { [key: string]: string });

      errors = { ...errors, ...updatedFieldErrors };
    }

    if (fields?.some((field) => ContextFieldTypes?.includes(field?.type))) {
      const targetFields = fields
        ?.filter((field) => ContextFieldTypes?.includes(field?.type))
        ?.map((field) => field?.key);

      targetFields?.forEach((fieldKey) => {
        const fieldValue = form?.[fieldKey];

        if (
          !fieldValue ||
          (Array.isArray(fieldValue) && fieldValue?.length === 0)
        )
          return;

        const contextFields = [
          ...this.contextObjects,
          ...NodesHelper.getDefaultCtxOptions(this.nodeInputOptions),
        ];

        const isValidField = Array.isArray(fieldValue)
          ? fieldValue?.every((field) =>
              contextFields?.some((ctxField) => ctxField?.key === field)
            )
          : contextFields?.some((ctxField) => ctxField?.key === fieldValue);

        if (!isValidField) {
          errors = {
            ...errors,
            [fieldKey]: t("invalidValue"),
          };
        }
      });
    }

    setFieldsErrors(errors);

    return errors;
  };

  validateNodeConnectionField = (config: FormField, fieldValue: unknown) => {
    let isFieldValid = true;

    const connectionIds =
      this.root.userStore?.connections?.map(
        (connection) => connection?.identifier
      ) || [];

    if (
      fieldValue &&
      config?.key === FlowEnum.connectionId &&
      !connectionIds?.includes(fieldValue as string)
    ) {
      isFieldValid = false;
    }

    return isFieldValid;
  };

  deleteHierarchyFromStartNode = (
    reactFlow: ReactFlowInstance,
    identifier: string
  ) => {
    reactFlow.setNodes((nodes) =>
      nodes.filter((node) => node.id === identifier)
    );
    reactFlow.setEdges([]);

    this.contextObjects = [];

    this.retriggerDiagram();
  };

  retriggerDiagram = () => {
    this.setDiagramHasBeenUpdated(true);
    this.setLayoutingNeeded(true);
    this.setConditionalPlaceholderFocus(null);
  };

  addDiagramNode = (
    reactFlow: ReactFlowInstance,
    cNode: FlowNode,
    notification: NotificationContextProps,
    t: TFunction
  ) => {
    try {
      let newNode = cNode;
      let parentNodeId = this.parentNodeId;

      if (this.selectedEdgeId) {
        const selectedEdge = reactFlow
          .getEdges()
          .find((edge) => edge.id === this.selectedEdgeId) as Edge<any>;

        const targetNode = reactFlow.getNode(selectedEdge.target) as FlowNode;

        newNode = {
          ...newNode,
          trueBranch: targetNode.trueBranch,
          falseBranch: targetNode.falseBranch,
        };
      }

      if (this.conditionalPlaceholderFocus) {
        const nodeIdToReplace = this.conditionalPlaceholderFocus;

        const currentNode = reactFlow.getNode(
          nodeIdToReplace
        ) as unknown as NodeData;

        const sourceRef = reactFlow
          .getEdges()
          ?.find((edge) => edge.target === nodeIdToReplace)?.source;

        parentNodeId = sourceRef as string;

        const branchName = currentNode?.trueBranch
          ? NODE_CONDITIONAL_BRANCHES.trueBranch
          : NODE_CONDITIONAL_BRANCHES.falseBranch;

        newNode = {
          ...newNode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: {
            ...(newNode.data as unknown as any),
            type: NODE_TYPES.passthrough,
          },
          [branchName]: true,
          parentId: currentNode.parentId,
        };
      }

      let newNodes = [newNode] as Node[];
      let extraEdges = [] as Edge[];

      if (newNode.type === DIAGRAM_NODE_CATEGORIES.group) {
        const otherId = uuidv4();

        newNodes = [
          ...newNodes,
          {
            ...({ ...newNode, identifier: otherId } as Node),
            id: otherId,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: {
              ...(newNode.data as unknown as any),
              identifier: otherId,
              type: NODE_TYPES.input,
            },
            type: DIAGRAM_NODE_CATEGORIES.placeholder,
            parentId: newNode.id,
          },
        ];

        if (newNode.key === NODE_ASSET_TYPE.conditional) {
          const trueBranchId = uuidv4();
          const falseBranchId = uuidv4();

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { trueBranch, falseBranch, ...formattedNode } = newNode;

          newNodes = [
            ...newNodes,
            {
              ...({
                ...formattedNode,
                [NODE_CONDITIONAL_BRANCHES.trueBranch]: true,
                identifier: trueBranchId,
              } as Node),
              id: trueBranchId,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              data: {
                ...(formattedNode.data as unknown as any),
                identifier: trueBranchId,
                type: NODE_TYPES.passthrough,
              },
              type: DIAGRAM_NODE_CATEGORIES.placeholder,
              parentId: formattedNode.id,
            },
            {
              ...({
                ...formattedNode,
                [NODE_CONDITIONAL_BRANCHES.falseBranch]: true,
                identifier: falseBranchId,
              } as Node),
              id: falseBranchId,

              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              data: {
                ...(formattedNode.data as unknown as any),
                identifier: falseBranchId,
                type: NODE_TYPES.passthrough,
              },
              type: DIAGRAM_NODE_CATEGORIES.placeholder,
              parentId: formattedNode.id,
            },
          ];

          extraEdges = [
            { source: otherId, target: trueBranchId },
            { source: otherId, target: falseBranchId },
          ].map((ed) => ({
            ...ed,
            id: uuidv4(),
            type: "diagramLink",
            label: AddCircleOutlineIcon,
            markerEnd: {
              type: MarkerType.Arrow,
            },
          }));
        }
      }

      const edges = reactFlow.getEdges();

      reactFlow.setNodes((nds) => {
        let allNodes = [...(nds || [])];

        if (this.conditionalPlaceholderFocus) {
          allNodes = allNodes.filter(
            (node) =>
              node.id !== this.conditionalPlaceholderFocus ||
              (node as unknown as FlowNode).identifier !==
                this.conditionalPlaceholderFocus
          );
        }

        // Find position of the new node based on focused edge
        const selectedEgde = edges?.find(
          (item) => item.id === this.selectedEdgeId
        );
        const topNodePosition = (allNodes as FlowNode[])?.findIndex(
          (item) => item.identifier === selectedEgde?.source
        );

        // Add to position
        if (topNodePosition >= 0) {
          allNodes = [
            ...(allNodes?.slice(0, topNodePosition + 1) || []),
            ...newNodes,
            ...(allNodes?.slice(topNodePosition + 1, allNodes.length) || []),
          ];
        } else {
          // Push as last node
          allNodes = [...allNodes, ...newNodes];
        }

        if (cNode?.parameters?.refreshContextObjects) {
          void this.loadContextObjects({ nodes: allNodes as FlowNode[] }).catch(
            (error: Error) => {
              notification.error(t(error?.message || "contextObjectsError"));
            }
          );
        }

        return allNodes;
      });
      reactFlow.setEdges((eds) => {
        let list = eds;

        if (this.conditionalPlaceholderFocus) {
          list = list.filter(
            (edge) => edge.target !== this.conditionalPlaceholderFocus
          );
        }

        const newEdge = {
          id: uuidv4(),
          source: parentNodeId || DIAGRAM_NODE_CATEGORIES.start,
          target: newNode.id,
          type: "diagramLink",
          label: AddCircleOutlineIcon,
          markerEnd: {
            type: MarkerType.Arrow,
          },
          zIndex: 100,
        } as Edge;

        if (this.selectedEdgeId) {
          // we actually need to add this node between two others.

          // adjust existing edge
          list = list.map((edge) => {
            return edge.id === this.selectedEdgeId
              ? { ...edge, source: newNode.id }
              : edge;
          });

          list.push(newEdge);
        } else {
          list.push(newEdge);
        }

        if (extraEdges?.length > 0) {
          extraEdges?.forEach((ed) => {
            list.push(ed);
          });
        }

        return list;
      });

      this.retriggerDiagram();
    } catch (error) {
      notification.error(t("errorCreatingNode"));
    }
  };

  updateDiagramNode = (
    reactFlow: ReactFlowInstance,
    nodeData: FormData, // Node to update
    rootProps: FormData, // Parent node of type group - properties to update,
    notification: NotificationContextProps,
    t: TFunction
  ) => {
    try {
      reactFlow.setNodes((nodes) => {
        // Update current node
        let newNodes = nodes?.map((node) =>
          node.id === nodeData.identifier
            ? {
                ...node,
                ...rootProps,
                data: {
                  ...(node.data as { [key: string]: string }),
                  value: nodeData.name,
                },
              }
            : node
        );

        const flowNode = nodes?.find((node) => node.id === nodeData.identifier);

        // Check if node is root of group
        if (flowNode?.type === DIAGRAM_NODE_CATEGORIES.placeholder) {
          const parentNode = nodes.find(
            (node) => node.id === flowNode.parentId
          ) as FlowNode;

          // Update group root node
          if (parentNode && parentNode.type === DIAGRAM_NODE_CATEGORIES.group) {
            newNodes = newNodes.map((node) =>
              node.id === parentNode.id
                ? {
                    ...node,
                    ...(rootProps || {}),
                    data: {
                      ...(node.data as { [key: string]: string }),
                      value: nodeData.name,
                    },
                  }
                : node
            );
          }
        }

        if (
          (nodeData as unknown as FlowNode)?.parameters?.refreshContextObjects
        ) {
          void this.loadContextObjects({ nodes: newNodes as FlowNode[] }).catch(
            (error: Error) => {
              notification.error(t(error?.message || "contextObjectsError"));
            }
          );
        }

        return newNodes;
      });

      this.retriggerDiagram();
    } catch (error) {
      notification.error(t("errorUpdatingNode"));
    }
  };

  setLoadingUpdateFlow = (value: boolean) => {
    this.loadingUpdateFlow = value;
  };

  setParallelNodeMode = (value: boolean) => {
    this.parallelNodeMode = value;
  };

  setRepoConnection = (repoConnection: string) => {
    if (this.flow) this.flow.storage.repoConnection = repoConnection;
  };

  setNodeId = (value: string | null) => {
    this.nodeIdToEdit = value;
  };

  setParentNodeId = (value: string | null) => {
    this.parentNodeId = value;
  };

  setConditionalPlaceholderFocus = (identifier: string | null) => {
    this.conditionalPlaceholderFocus = identifier;
  };

  setDiagram = (value: FlowDiagram | null) => {
    this.flowDiagram = value;
  };

  resetFlowDiagram = () => {
    this.flowDiagram = { ...this.nonAlteredFlowDiagram } as FlowDiagram;
  };

  setMenuPopupOpen = (value: boolean) => {
    this.menuPopupOpen = value;
  };

  setMenuPopupAnchor = (value: HTMLDivElement | null) => {
    this.menuPopupAnchor = value;
  };

  setSelectedEdgeId = (value?: string) => {
    this.selectedEdgeId = value;
  };

  openNodeOptionsPopup = (value: HTMLDivElement | null, edgeId?: string) => {
    this.setMenuPopupAnchor(value);
    this.setSelectedEdgeId(edgeId);
    this.setMenuPopupOpen(true);
  };

  closeNodeOptionsPopup = () => {
    this.setMenuPopupOpen(false);
  };

  setDesignerModalAddMode = (value: boolean) => {
    this.isAddNodeOpened = value;
  };

  triggerRefetchFlowSettings = (value: boolean) => {
    this.refetchFlowSettings = value;
  };

  setNodesList = (value: AssetType[]) => {
    this.nodesList = value;
  };

  setCurrentDiagramId = (value: string | null) => {
    this.currentDiagramId = value;
  };

  setDiagramHasBeenUpdated = (value: boolean) => {
    this.diagramHasBeenUpdated = value;
  };

  getAllContextVariables = (nodes?: FlowNode[]) => {
    const nodeIdOrParentId = this.nodeIdToEdit || this.parentNodeId || "";

    if (nodes) {
      const filteredContextVariables = [
        ...(this.getContextVariablesAboveNode(
          this.contextObjects,
          nodes,
          nodeIdOrParentId
        ) || []),
      ];

      const parentOptions = filteredContextVariables?.filter(
        (option) => option?.schema && option?.schema?.properties
      );

      return filteredContextVariables?.map((option) => {
        if (option?.schema && option?.schema?.properties) {
          return {
            ...option,
            isParent: true,
          };
        }

        const parentOption = parentOptions?.find((parent) =>
          option?.key?.includes(parent?.key)
        );

        if (parentOption) {
          const childLabelWithoutParent = option?.key
            ?.replace(`${parentOption.key}${"."}`, "")
            ?.trim();

          return {
            ...option,
            label: childLabelWithoutParent,
            parentKey: parentOption?.key,
          };
        }

        return option;
      });
    } else {
      return this.contextObjects;
    }
  };

  checkFlowName = async (flowName: string) => {
    const {
      data: { checkFlowName },
      errors,
    } = await this.root.client.query<CHECK_FLOW_NAME_RESPONSE>({
      query: CHECK_FLOW_NAME,
      variables: { flowName },
      fetchPolicy: "network-only",
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return checkFlowName;
  };

  getFlowDiagrams = async (id: string) => {
    const {
      data: { getFlowDiagrams },
      errors,
    } = await this.root.client.query<GET_FLOW_DIAGRAMS_TYPE>({
      query: GET_FLOW_DIAGRAMS,
      variables: { flowId: id },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.flowDiagrams = getFlowDiagrams;
    });

    return getFlowDiagrams;
  };

  getFlowDiagram = async (diagramId: string) => {
    try {
      this.loadingDiagram = true;
      const {
        data: { getFlowDiagram },
        errors,
      } = await this.root.client.query<GET_FLOW_DIAGRAM_TYPE>({
        query: GET_FLOW_DIAGRAM,
        variables: { flowId: this.flow?.identifier, diagramId },
      });

      if (errors && errors?.[0]) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingDiagram = false;
        this.flowDiagram = getFlowDiagram;
        this.nonAlteredFlowDiagram = getFlowDiagram;
      });

      return getFlowDiagram;
    } catch (error) {
      runInAction(() => {
        this.loadingDiagram = false;
      });
      throw error;
    }
  };

  getLatestFlowDiagram = async (id: string) => {
    const {
      data: { getLatestFlowDiagram },
      errors,
    } = await this.root.client.query<GET_LATEST_FLOW_DIAGRAM_TYPE>({
      query: GET_LATEST_FLOW_DIAGRAM,
      variables: { flowId: id },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.flowDiagram = getLatestFlowDiagram;
      this.nonAlteredFlowDiagram = getLatestFlowDiagram;
      if (getLatestFlowDiagram?.identifier) {
        this.currentDiagramId = getLatestFlowDiagram?.identifier;
      }
    });

    return getLatestFlowDiagram;
  };

  loadFlow = async (flowId: string, forDuplicateFlow: boolean) => {
    try {
      this.loadingFlowSettings = true;
      const getFlowDetails = await this.root.flowStore.fetchFlowData(flowId);
      runInAction(() => {
        if (forDuplicateFlow) {
          this.duplicateFlowConfig = {
            name: getFlowDetails?.name,
            description: getFlowDetails?.description,
            access: getFlowDetails?.access,
          } as DuplicatedFlowConfig;
        } else {
          this.flow = getFlowDetails as unknown as FlowInterface;
          this.loadingFlowSettings = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.loadingFlowSettings = false;
      });
      throw error;
    }
  };

  updateFlow = async (updatedFlowConfig: FlowInterface) => {
    this.loadingUpdateFlow = true;

    const formattedFlow = FlowHelper.removeKeyFromObject(
      { ...this.flow },
      "identifier"
    );

    try {
      const response =
        await this.root.client.mutate<UPDATE_FLOW_FROM_DRAFT_TYPE>({
          mutation: UPDATE_FLOW,
          variables: {
            flow: {
              ...formattedFlow,
              ...updatedFlowConfig,
            },
            id: this.flow?.identifier,
          },
        });

      const {
        data: { updateFlow },
        errors,
      } = response as unknown as {
        data: { updateFlow: boolean };
        errors: GraphQLError[];
      };

      if (!updateFlow || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingUpdateFlow = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingUpdateFlow = false;
      });
      throw error;
    }
  };

  createFlow = async (flowConfig: FlowInterface) => {
    const response = await this.root.client.mutate<CREATE_FLOW_TYPE>({
      mutation: CREATE_FLOW,
      variables: flowConfig,
    });

    const {
      data: { createFlow },
      errors,
    } = response as unknown as {
      data: { createFlow: string };
      errors: GraphQLError[];
    };

    if (!createFlow || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return createFlow;
  };

  importFlow = async (flowConfig: FlowInterface) => {
    const response = await this.root.client.mutate<IMPORT_FLOW_TYPE>({
      mutation: IMPORT_FLOW,
      variables: flowConfig,
    });

    const {
      data: { importFlow },
      errors,
    } = response as unknown as {
      data: { importFlow: string };
      errors: GraphQLError[];
    };

    if (!importFlow || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return importFlow;
  };

  duplicateFlow = async (flowId: string, flowConfig: FlowInterface) => {
    const response = await this.root.client.mutate<DUPLICATE_FLOW_TYPE>({
      mutation: DUPLICATE_FLOW,
      variables: {
        flowId,
        ...flowConfig,
      },
    });

    const {
      data: { duplicateFlow },
      errors,
    } = response as unknown as {
      data: { duplicateFlow: string };
      errors: GraphQLError[];
    };

    if (!duplicateFlow || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return duplicateFlow;
  };

  deleteDiagramVersion = async (flowId: string, diagramId: string) => {
    const response = await this.root.client.mutate<DELETE_DIAGRAM_VERSION>({
      mutation: DELETE_DIAGRAM_VERSION,
      variables: { flowId, diagramId },
    });

    const {
      data: { deleteDiagramVersion },
      errors,
    } = response as unknown as {
      data: { deleteDiagramVersion: boolean };
      errors: GraphQLError[];
    };

    if (!deleteDiagramVersion || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.flowDiagrams = this.flowDiagrams.filter(
        (flowDiagram) => flowDiagram.identifier !== diagramId
      );
    });

    return deleteDiagramVersion;
  };

  promoteToCurrent = async (flowId: string) => {
    const response = await this.root.client.mutate<PROMOTE_TO_CURRENT>({
      mutation: PROMOTE_TO_CURRENT,
      variables: {
        flowId,
        selectedDiagramId: this.currentDiagramId,
      },
    });

    const {
      data: { promoteToCurrent },
      errors,
    } = response as unknown as {
      data: { promoteToCurrent: FlowDiagram };
      errors: GraphQLError[];
    };

    if (!promoteToCurrent || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.flowDiagram = promoteToCurrent;
      this.nonAlteredFlowDiagram = promoteToCurrent;
      if (promoteToCurrent?.identifier) {
        this.currentDiagramId = promoteToCurrent?.identifier;
      }
    });

    return promoteToCurrent;
  };

  duplicateFlowDiagram = async (
    flowId: string,
    duplicatedFlowId: string,
    diagramId?: string
  ) => {
    const response = await this.root.client.mutate<DUPLICATE_DIAGRAM_RESPONSE>({
      mutation: DUPLICATE_DIAGRAM,
      variables: {
        flowId,
        duplicatedFlowId,
        diagramId,
      },
    });

    const {
      data: { duplicateFlowDiagram },
      errors,
    } = response as unknown as {
      data: { duplicateFlowDiagram: boolean };
      errors: GraphQLError[];
    };

    if (!duplicateFlowDiagram || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return duplicateFlowDiagram;
  };

  applyDiagramTemplate = async (parameters?: Record<string, string>) => {
    const flowId = this.flow?.identifier as string;
    const response =
      await this.root.client.mutate<APPLY_DIAGRAM_TEMPLATE_RESPONSE>({
        mutation: APPLY_DIAGRAM_TEMPLATE,
        variables: {
          flowId,
          parameters,
        },
      });

    const {
      data: { applyDiagramTemplate },
      errors,
    } = response as unknown as {
      data: { applyDiagramTemplate: boolean };
      errors: GraphQLError[];
    };

    if (!applyDiagramTemplate || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return applyDiagramTemplate;
  };

  createFlowDiagram = async (data: {
    nodes: FlowNode[];
    edges: Edge<any>[];
  }) => {
    this.loadingCreateDiagram = true;

    const { nodes, edges } = data;
    try {
      const response = await this.root.client.mutate<CREATE_DIAGRAM_RESPONSE>({
        mutation: CREATE_DIAGRAM,
        variables: {
          flowId: this.flow?.identifier || "",
          diagram: {
            nodes: nodes.map((node) => {
              return Object.fromEntries(
                Object.entries(node).filter(
                  ([key]) =>
                    ![
                      "width",
                      "height",
                      "positionAbsolute",
                      "selected",
                    ].includes(key)
                )
              );
            }),
            edges: edges,
          },
        },
      });

      const {
        data: { createFlowDiagram },
        errors,
      } = response as unknown as {
        data: { createFlowDiagram: boolean };
        errors: GraphQLError[];
      };

      if (!createFlowDiagram || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingCreateDiagram = false;
      });

      return createFlowDiagram;
    } catch (error) {
      runInAction(() => {
        this.loadingCreateDiagram = false;
      });
      throw error;
    }
  };

  importFlowDiagram = async (flowId: string, diagramConfig: FlowDiagram) => {
    this.loadingCreateDiagram = true;

    try {
      const response = await this.root.client.mutate<IMPORT_DIAGRAM_RESPONSE>({
        mutation: IMPORT_DIAGRAM,
        variables: {
          flowId: flowId,
          diagram: diagramConfig,
        },
      });

      const {
        data: { importFlowDiagram },
        errors,
      } = response as unknown as {
        data: { importFlowDiagram: boolean };
        errors: GraphQLError[];
      };

      if (!importFlowDiagram || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingCreateDiagram = false;
      });

      return importFlowDiagram;
    } catch (error) {
      runInAction(() => {
        this.loadingCreateDiagram = false;
      });
      throw error;
    }
  };

  getExportableObjects = async (flowId: string) => {
    const {
      data: { getExportableObjects },
      errors,
    } = await this.root.client.query<GET_EXPORTABLE_OBJECTS_TYPE>({
      query: GET_EXPORTABLE_OBJECTS,
      variables: { flowId: flowId, diagramId: "latest" },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getExportableObjects;
  };

  getDiagramDataConfigs = async () => {
    try {
      this.loadingRules = true;

      const {
        data: { getDiagramDataConfigs },
        errors,
      } = await this.root.client.query<GET_DIAGRAM_CONFIGS_TYPE>({
        query: GET_DIAGRAM_CONFIGS,
      });

      if (errors && errors?.[0]) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingRules = false;
        this.approvalRules = getDiagramDataConfigs?.rules || [];
        this.setNodeCategories(
          getDiagramDataConfigs?.categories?.parameters[0]?.options || []
        );
        this.nodeInputOptions = getDiagramDataConfigs?.inputSpecs || [];
      });

      return getDiagramDataConfigs;
    } catch (error) {
      runInAction(() => {
        this.loadingRules = false;
      });

      throw error;
    }
  };

  loadDiagramData = async (
    id: string,
    notification: NotificationContextProps,
    t: TFunction
  ) => {
    try {
      this.loadingDiagram = true;

      const getLatestFlowDiagram = this.getLatestFlowDiagram(id).then(
        (data) => {
          this.loadContextObjects({
            nodes: data?.specs?.nodes || [],
            edges: data?.specs?.edges || [],
          }).catch((error: Error) => {
            notification.error(t(error?.message || "contextObjectsError"));
          });
        }
      );

      const getFlowDiagrams = this.getFlowDiagrams(id);

      const connectionsFetch = this.root.userStore.loadAllConnections();

      const nodesList = this.root.flowStore
        .getDiagramAssetList()
        .then((data) => this.setNodesList(data.getAssetDiagramList));

      const getDiagramConfigs = this.getDiagramDataConfigs();

      await Promise.all([
        getLatestFlowDiagram,
        getFlowDiagrams,
        nodesList,
        getDiagramConfigs,
        connectionsFetch,
      ]);

      runInAction(() => {
        this.loadingDiagram = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingDiagram = false;
      });
      throw error;
    }
  };

  loadContextObjects = async (specs: {
    nodes: FlowNode[];
    edges?: Edge<any>[];
  }) => {
    const edges = specs.edges || [];

    if (specs?.nodes?.some((node) => node?.parameters?.refreshContextObjects)) {
      this.loadingContextObjects = true;

      try {
        const {
          data: { getContextObjects },
          errors,
        } = await this.root.client.query<GET_CONTEXT_OBJECTS_TYPE>({
          query: GET_CONTEXT_OBJECTS,
          variables: {
            flowId: this.flow?.identifier || "",
            nodes: specs.nodes,
            edges: edges,
          },
        });

        if (errors && errors?.[0]) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        runInAction(() => {
          this.loadingContextObjects = false;
          this.contextObjects =
            NodesHelper.formatContextObjects(getContextObjects);
        });

        return getContextObjects;
      } catch (error) {
        runInAction(() => {
          this.loadingContextObjects = false;
        });

        throw error;
      }
    } else {
      runInAction(() => {
        this.contextObjects = [];
      });
    }

    return null;
  };
}
