import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { TFunction } from "i18next";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import EditorJson from "../pages/connections/components/EditorJson";
import { FormField } from "../../types/interfaces";
import { useStores } from "../../stores/StoresProvider";
import { FormFieldLanguageTypes } from "../../types/enums";
import CoreButton from "../core/CoreButton";

interface Props {
  onChange: (value: Record<string, string>) => void;
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  errorText: string | undefined;
  preventValueCheck?: boolean;
  textAtCursor?: string;
  cannotEditSchema?: boolean;
  disableDiagramField?: boolean;
}

function validateOneLevelNesting(
  input: unknown,
  shouldAllowTopLevelList = false
): boolean {
  if (Array.isArray(input)) {
    return shouldAllowTopLevelList
      ? input.every((item) => isOneLevelNested(item))
      : false;
  } else if (typeof input === "object" && input !== null) {
    return isOneLevelNested(input);
  }
  return false;
}

function isOneLevelNested(item: unknown): boolean {
  if (typeof item === "object" && item !== null) {
    for (const key in item) {
      const value = (item as Record<string, unknown>)[key];
      if (
        (typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null) ||
        (Array.isArray(value) &&
          value.length &&
          value.some((item) => typeof item === "object"))
      ) {
        return false;
      }
    }
  }
  return true;
}

const useStyles = makeStyles({
  schemaPreviewButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "16px",
  },
  notificationBox: {
    maxWidth: "540px",
    display: "flex",
    alignSelf: "flex-end",
  },
});

const JsonEditorRenderer: React.FC<Props> = observer(
  ({
    onChange,
    value,
    field,
    errorText: propsErrorText,
    translation,
    textAtCursor,
    preventValueCheck = false,
    cannotEditSchema = false,
    disableDiagramField,
  }) => {
    const { flowSettingsStore } = useStores();

    const classes = useStyles();
    // Original stringified value must be stored so for json editor to keep formatting the json
    const [stringifiedValue, setValue] = useState(
      value
        ? typeof value === "string"
          ? value
          : JSON.stringify(value, null, "\t")
        : "{}"
    );
    const [errorText, setErrorText] = useState<string | undefined>(
      propsErrorText
    );
    const [lastKnownValidValue, setLastKnownValidValue] =
      useState<string>(stringifiedValue);

    useEffect(() => {
      if (flowSettingsStore.forceRetriggerRendererValue) {
        setValue(
          value
            ? typeof value === "string"
              ? value
              : JSON.stringify(value, null, "\t")
            : "{}"
        );
        flowSettingsStore.retriggerRendererValue(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, flowSettingsStore.forceRetriggerRendererValue]);

    useEffect(() => {
      if (propsErrorText) {
        setErrorText(propsErrorText);
      } else {
        setErrorText(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsErrorText]);

    const handleChange = (updatedValue: string) => {
      if (preventValueCheck) {
        setValue(updatedValue);
        onChange({ [field.key]: updatedValue } as Record<string, string>);
      } else {
        let parsedValue = value;
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          parsedValue = JSON.parse(updatedValue);

          if (!field.allowNestedObjects) {
            if (!validateOneLevelNesting(parsedValue)) {
              setErrorText(
                translation(
                  Array.isArray(parsedValue)
                    ? "errorForbiddenListSchemaObjects"
                    : "errorNestedObjects"
                )
              );
              setValue(lastKnownValidValue);
              return;
            } else {
              if (errorText) setErrorText(undefined);
              if (updatedValue !== stringifiedValue) {
                setLastKnownValidValue(updatedValue);
              }
            }
          }
        } catch {
          // If parse is failing, this means the json is invalid and the old value should be kept
        }

        setValue(updatedValue);
        onChange({ [field.key]: parsedValue } as Record<string, string>);
      }
    };

    return (
      <>
        <EditorJson
          onChange={handleChange}
          value={stringifiedValue as string | undefined}
          errorText={errorText}
          label={translation(field.name ?? "")}
          isMandatory={field.isMandatory}
          isReadOnly={field.props?.readOnly || field?.disabled || false}
          mode={FormFieldLanguageTypes.json}
          textAtCursor={textAtCursor}
          disableDiagramField={disableDiagramField}
        />

        {field?.allowSchemaGenerator &&
          flowSettingsStore.isDiagramEditable &&
          !cannotEditSchema && (
            <Box className={classes.schemaPreviewButton}>
              <CoreButton
                onClick={() => flowSettingsStore.setGeneratedSchemaField(field)}
              >
                {translation("generateFromSample")}
              </CoreButton>
            </Box>
          )}
      </>
    );
  }
);

export default JsonEditorRenderer;
