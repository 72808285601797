//Module imports
import { TFunction } from "i18next";
import React, { FC } from "react";
import { isString } from "lodash";

//Design imports
import { makeStyles } from "@mui/styles";
import { Box, Typography, useTheme } from "@mui/material";

//Internal imports
import { CardActions } from "../../types/interfaces";
import CoreActionsFlow from "./CoreActionsFlow";

interface Props {
  listActions?: CardActions[];
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  scope?: string;
  identifier?: string[]; // Used as param for onClick functions
  t: TFunction;
  disabled?: boolean;
  addEnhancement?: boolean;
  addCardReview?: boolean;
  approvalCard?: boolean;
  isResultsDetails?: boolean;
}

const CoreCardFlow: FC<Props> = ({
  listActions,
  title,
  subtitle,
  t,
  scope,
  identifier,
  disabled,
  addEnhancement,
  addCardReview,
  approvalCard,
  isResultsDetails = false,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    card: {
      maxWidth: "800px",
      minHeight: "90px",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      padding: "1rem",
      margin: "20px 0",
      flexWrap: "wrap",
    },
    approvalCard: {
      maxWidth: "800px",
      display: "flex",
      margin: "10px 0",
      marginLeft: "34px",
      flexWrap: "wrap",
    },
    cardAddEnhancement: {
      maxWidth: "800px",
      minHeight: "90px",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      padding: "1rem",
      flexWrap: "wrap",
    },
    cardAddReview: {
      maxWidth: "600px",
      minHeight: "90px",
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      margin: "20px 0",
    },
    cardResultsDetails: {
      minHeight: "90px",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      padding: "1rem",
      flexWrap: "wrap",
      marginLeft: "10px",
      marginRight: "10px",
      marginBottom: "10px",
    },
    leftBox: {
      display: "flex",
      flexDirection: "column",
      marginRight: "5px",
      justifyContent: "center",
    },
    boxTitle: {
      fontSize: "18px",
    },
    subtitle: {
      color: theme.palette.highlight.main,
      marginTop: "5px",
      fontSize: "14px",
    },
    rightBox: {
      margin: "auto 0",
      marginLeft: "auto",
      display: "flex",
    },
    scope: {
      marginRight: "10px",
      marginTop: "auto",
    },
  });

  const classes = useStyles();

  let cardClasses = classes.card;

  if (addEnhancement) {
    cardClasses = classes.cardAddEnhancement;
  }

  if (addCardReview) {
    cardClasses = classes.cardAddReview;
  }

  if (approvalCard) {
    cardClasses = classes.approvalCard;
  }

  if (isResultsDetails) {
    cardClasses = classes.cardResultsDetails;
  }

  const getSubtitle = () => {
    if (subtitle) {
      if (isString(subtitle)) {
        return <Typography className={classes.subtitle}>{subtitle}</Typography>;
      } else {
        return subtitle;
      }
    }
    return;
  };

  const getTitle = () => {
    if (isString(title)) {
      return <Typography className={classes.boxTitle}>{title}</Typography>;
    } else {
      return title;
    }
  };

  return (
    <Box className={cardClasses}>
      <Box className={classes.leftBox}>
        {getTitle()}
        {getSubtitle()}
      </Box>

      <Box className={classes.rightBox}>
        {scope && <Typography className={classes.scope}>{scope}</Typography>}
        {listActions && (
          <CoreActionsFlow
            listActions={listActions}
            t={t}
            identifier={identifier}
            disabled={disabled}
          />
        )}
      </Box>
    </Box>
  );
};

export default CoreCardFlow;
