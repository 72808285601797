import React, { useMemo } from "react";

import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";

import CoreInput from "../../core/CoreInput";
import { FormField } from "../../../types/interfaces";

type Props = {
  field: FormField;
};

const useStyle = makeStyles({
  container: {
    marginTop: "16px",
  },
  title: {
    opacity: 0.6,
    fontSize: "14px",
  },
});

const IndexingFieldView: React.FC<Props> = ({ field }) => {
  const classes = useStyle();

  const fieldValue = useMemo(() => {
    if (field?.value && field?.options && field?.options?.length > 0) {
      const option = field?.options?.find(
        (option) => option?.key === field?.value
      );
      return option?.label || field?.value || "-";
    }
    return field?.value || "-";
  }, [field]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{field?.name || "-"}</Typography>
      <CoreInput
        readOnly={true}
        value={fieldValue}
        description={field?.props?.description || ""}
        tooltipLocation="input"
      />
    </Box>
  );
};

export default IndexingFieldView;
