import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";
import { THEME_MODES } from "../../types/constants";

export const initMuiSwitch = (theme: ThemeOptions): Components["MuiSwitch"] => {
  const palette = theme.palette as ThemePalette;
  const isLightMode = theme.palette?.mode === THEME_MODES.light;

  return {
    styleOverrides: {
      root: {
        "& .MuiSwitch-switchBase": {
          transitionDuration: "300ms",
          "&.Mui-checked": {
            color: palette.highlight.main,
            "& + .MuiSwitch-track": {
              backgroundColor: palette.highlight.main,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              backgroundColor: palette.switch.dark,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: palette.secondary.main,
            border: `6px solid ${palette.secondary.light}`,
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            backgroundColor: isLightMode
              ? palette.switch.light
              : palette.switch.dark,
          },
          "&:not(.Mui-checked)": {
            "& + .MuiSwitch-track": {
              backgroundColor: isLightMode && palette.switch.light,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              backgroundColor: palette.highlight.light,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              backgroundColor: isLightMode
                ? palette.background.default
                : palette.text.secondary,
            },
          },
        },
      },
    },
  };
};
