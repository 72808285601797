import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import CoreTabs from "../../../core/CoreTabs";
import CorePageContainer from "../../../core/CorePageContainer";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { ContextEvent } from "../../../../types/interfaces/contextEvent";
import { SummaryTab } from "../components/SummaryTab";
import { LogsTab } from "../components/LogsTab";
import { appRoutes } from "../../../../configs/routes";

const useStyles = makeStyles({
  container: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  tabContainer: {
    marginTop: "20px",
    marginLeft: "25px",
  },
});

export const ActivityLogDetailsPage: FC = observer(() => {
  const classes = useStyles();
  const notification = useNotification();
  const { t, ready } = useTranslation("activityLogs");
  const {
    activityLogStore: { getActivityLogItemData, resetStore, currentPage },
  } = useStores();
  const { id } = useParams<{ id: string }>();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState<ContextEvent>();

  useEffect(() => {
    getActivityLogItemData(id ?? "")
      .then((event) => {
        if (!event) {
          notification.error(t("noLogsFound"));
          return;
        }
        setData(event);
      })
      .catch(() => {
        notification.error(t("context_event_error_data_fetch"));
      })
      .finally(() => {
        setIsPageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivityLogItemData, id, currentPage]);

  const handleChangeTabs = useCallback((_: unknown, newIndex: number) => {
    setTabIndex(newIndex);
  }, []);

  useEffect(() => {
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TABS = useMemo(
    () => [
      {
        key: "summary",
        label: t("context_event_summary_tab"),
      },
      {
        key: "logs",
        label: t("context_event_logs_tab"),
      },
    ],
    [t]
  );

  return (
    <CorePageContainer
      canGoBack
      isPageLoading={isPageLoading || !ready}
      label={`Event #${id ?? ""}`}
      redirectPage={appRoutes.ContextEvents()}
    >
      <Box className={classes.container}>
        <CoreTabs
          t={t}
          variant="scrollable"
          value={tabIndex}
          onChange={handleChangeTabs}
          options={TABS}
        />

        <Box className={classes.tabContainer}>
          {tabIndex === 0 && <SummaryTab data={data} />}

          {tabIndex === 1 && (
            <LogsTab data={data as unknown as ContextEvent} translate={t} />
          )}
        </Box>
      </Box>
    </CorePageContainer>
  );
});
