import React, { FC, useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react";
import Konva from "konva";

import { Theme } from "@mui/material";

import ZoneDraft from "../zones/ZoneDraft";
import { ZoneLine } from "./ZoneLine";
import { ZoneTable } from "./ZoneTable";
import ZoneCell from "./ZoneCell";
import Zone from "../zones/Zone";
import {
  CANVAS_ACTIONS,
  EDITABLE_ZONE_ACTIONS,
  LINE_SPACING,
} from "../../../../../../types/constants";
import { DocumentStore } from "../../../../../../stores/DocumentStore";
import { DocumentZone } from "../../../../../../types/interfaces";
import RightClickMenuCell from "./menus/RightClickMenuCell";
import RightClickMenuHeader from "./menus/RightClickMenuHeader";

const isRowHovered = (
  cell: DocumentZone,
  tableRootX: number,
  mousePos: {
    x: number;
    y: number;
  } | null
) => {
  const mousePosY = mousePos?.y ?? 0;
  const mousePosX = mousePos?.x ?? 0;
  const tableX = tableRootX ?? 0;
  const tableXWithSpacing = tableX - LINE_SPACING;

  const isRowHovered =
    cell.box.y < mousePosY &&
    cell.box.y + cell.box.height > mousePosY &&
    tableXWithSpacing < mousePosX &&
    tableX > mousePosX;

  return isRowHovered;
};

export const LineItemsRenderer: FC<{
  documentStore: DocumentStore;
  theme: Theme;
}> = observer(({ documentStore, theme }) => {
  const [contextMenuHeader, setContextMenuHeader] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [contextMenuCell, setContextMenuCell] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [currentCell, setCurrentCell] = useState<{
    rowIndex: number;
    cellIndex: number;
    cell: DocumentZone;
    headerKey: string;
  } | null>(null);

  const [currentHeader, setCurrentHeader] = useState<string | null>(null);

  const focusedLineItem = useMemo(
    () => documentStore.focusedLineItem,
    [documentStore.focusedLineItem]
  );

  // In edit mode, need to draw only the focused table
  const lineItems = ![CANVAS_ACTIONS.drawNewZone].includes(
    documentStore.canvasAction
  )
    ? documentStore.lineItems
    : focusedLineItem
    ? [focusedLineItem]
    : [];

  const currentEditingCell = documentStore.currentEditingCell;
  const setCurrentEditingCell = documentStore.setCurrentEditingCell;

  const isEditable = useMemo(
    () =>
      documentStore.isLineItemsMode &&
      documentStore.focusedFieldCanvas &&
      !documentStore.viewMode &&
      !documentStore.manualLineItemMode &&
      [CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction),
    [
      documentStore.canvasAction,
      documentStore.focusedFieldCanvas,
      documentStore.isLineItemsMode,
      documentStore.manualLineItemMode,
      documentStore.viewMode,
    ]
  );

  const isManuallModeEditabled = useMemo(
    () =>
      documentStore.isLineItemsMode &&
      documentStore.focusedFieldCanvas &&
      !documentStore.viewMode &&
      [CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction),
    [
      documentStore.canvasAction,
      documentStore.focusedFieldCanvas,
      documentStore.isLineItemsMode,
      documentStore.viewMode,
    ]
  );

  const isFieldInUse = (fieldKey: string) => {
    return documentStore.focusedLineItemHeaders?.find(
      (item) => item.key === fieldKey
    );
  };

  //right click menu handle function for header
  const handleContextMenuHeader = (
    event: Konva.KonvaEventObject<PointerEvent>,
    headerKey: string
  ) => {
    event.evt.preventDefault();
    const { x: mouseX, y: mouseY } = event.evt;

    documentStore.toggleLineItemHeaderTypeChange(headerKey);
    setCurrentHeader(headerKey);
    setContextMenuHeader(
      contextMenuHeader === null
        ? {
            mouseX: mouseX + 10,
            mouseY: mouseY - 10,
          }
        : null
    );
  };
  // right click menu close function
  const handleCloseContextMenuHeader = () => {
    setContextMenuHeader(null);
    documentStore.toggleLineItemHeaderTypeChange(null);
  };

  //right click menu handle function for cell
  const handleContextMenuCell = (
    event: Konva.KonvaEventObject<PointerEvent>,
    rowIndex: number,
    cellIndex: number,
    cell: DocumentZone,
    headerKey: string
  ) => {
    event.evt.preventDefault();
    const { x: mouseX, y: mouseY } = event.evt;

    setCurrentCell({ rowIndex, cellIndex, cell, headerKey });
    setContextMenuCell(
      contextMenuCell === null
        ? {
            mouseX: mouseX + 10,
            mouseY: mouseY - 10,
          }
        : null
    );
  };
  // right click menu close function
  const handleCloseContextMenuCell = () => {
    setContextMenuCell(null);
  };

  const updateManuallZone = useCallback(
    (updatedZone: DocumentZone, e?: Konva.KonvaEventObject<Event>) => {
      if (EDITABLE_ZONE_ACTIONS.includes(documentStore.canvasAction)) {
        documentStore.updateManualLineItemCell(updatedZone, e);
      }
    },
    [documentStore]
  );

  return (
    <>
      {lineItems?.length > 0 &&
        lineItems.map((table, index) => {
          // Skip lineItems from other pages that the current one
          if (table.pageIdentifier !== documentStore.documentPage?.identifier) {
            return null;
          }

          const tableLines = documentStore.movingLineIndex
            ? documentStore.draftLines
            : table?.coords?.lines;
          const tableData = table?.data || [];
          const tableHeaders = table?.headers || [];

          return (
            <React.Fragment key={index}>
              {/* Table edges */}
              {contextMenuCell === null && contextMenuHeader === null && (
                <ZoneTable
                  documentStore={documentStore}
                  table={table}
                  theme={theme}
                  isReadOnly={!isEditable}
                />
              )}

              {tableHeaders?.length > 0 &&
                tableHeaders.map((header, headerCellIndex) => {
                  const isHeaderInUse = isFieldInUse(header.key as string);

                  return (
                    <ZoneCell
                      isHeader
                      key={`${index}=${headerCellIndex}`}
                      zone={header}
                      isGrey={!isHeaderInUse}
                      isReadOnly={!isEditable}
                      theme={theme}
                      cellIndex={headerCellIndex}
                      rowIndex={0}
                      currentEditingCell={currentEditingCell}
                      setCurrentEditingCell={setCurrentEditingCell}
                      handleContextMenu={(event) =>
                        handleContextMenuHeader(event, header.key as string)
                      }
                    />
                  );
                })}

              {/* Table body data text */}
              {tableData?.length > 0 &&
                documentStore.movingLineIndex === null &&
                tableData.map((row, rowIndex) => {
                  const dataCells = row?.cells || {};

                  if (dataCells && Object.keys(dataCells)?.length > 0) {
                    return Object.keys(dataCells).map((cellKey, cellIndex) => {
                      // If current cell key exists in flow config, then the column is in use
                      const isCellInUse = isFieldInUse(cellKey);
                      const cell = dataCells[cellKey];

                      const isHovered = isRowHovered(
                        cell,
                        table.coords.root.x,
                        documentStore.mousePos
                      );

                      if (row?.manuallyAdded) {
                        const isCellFocused =
                          currentEditingCell?.rowIndex === rowIndex &&
                          currentEditingCell.colKey === cellKey;

                        // Manually added cell must behave as normal canvas zone
                        return (
                          <Zone
                            key={`${index}-${rowIndex}-${cellIndex}`}
                            zone={{
                              key: cellKey,
                              category:
                                documentStore.focusedFieldCanvas || cellKey,
                              ...cell,
                            }}
                            forceDirectFocus
                            isDraggable={!!isManuallModeEditabled}
                            isReadOnly={!isManuallModeEditabled}
                            isEditable={!!isManuallModeEditabled}
                            isFocused={isCellFocused}
                            theme={theme}
                            store={documentStore}
                            onUpdate={updateManuallZone}
                            onFocus={() => {
                              setCurrentEditingCell({
                                rowIndex: rowIndex,
                                colKey: cellKey,
                              });
                              documentStore.setManualLineItemMode(true);
                            }}
                          />
                        );
                      }

                      return (
                        <ZoneCell
                          key={`${index}-${rowIndex}-${cellIndex}`}
                          zone={{
                            key: cellKey,
                            ...cell,
                          }}
                          isRowHovered={isHovered}
                          isGrey={row.isIgnored || !isCellInUse}
                          isReadOnly={!isEditable}
                          theme={theme}
                          cellIndex={cellIndex}
                          rowIndex={rowIndex}
                          handleContextMenu={(event) =>
                            handleContextMenuCell(
                              event,
                              rowIndex,
                              cellIndex,
                              cell,
                              cellKey
                            )
                          }
                          currentEditingCell={currentEditingCell}
                          setCurrentEditingCell={setCurrentEditingCell}
                        />
                      );
                    });
                  }

                  return null;
                })}

              {/* Table lines (rows and columns) */}
              {tableLines?.length > 0 &&
                tableLines.map((tableLine, lineIndex) => (
                  <ZoneLine
                    table={table}
                    line={tableLine}
                    index={lineIndex}
                    key={`${index}-${lineIndex}`}
                    theme={theme}
                    remakeColumns={documentStore.remakeColumns}
                    remakeRows={documentStore.remakeLines}
                    setMovingLineIndex={documentStore.setMovingLineIndex}
                    movingLineIndex={documentStore.movingLineIndex}
                    setLines={documentStore.setLines}
                    mousePos={documentStore.mousePos}
                    viewMode={
                      documentStore.viewMode || documentStore.manualLineItemMode
                    }
                  />
                ))}
              <RightClickMenuCell
                open={contextMenuCell !== null}
                onClose={handleCloseContextMenuCell}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenuCell !== null
                    ? {
                        top: contextMenuCell.mouseY,
                        left: contextMenuCell.mouseX,
                      }
                    : undefined
                }
                currentCell={currentCell}
                documentStore={documentStore}
                tableCoords={table.coords.root}
              />

              <RightClickMenuHeader
                open={contextMenuHeader !== null}
                onClose={handleCloseContextMenuHeader}
                anchorReference="anchorPosition"
                tableCoords={table.coords.root}
                anchorPosition={
                  contextMenuHeader !== null
                    ? {
                        top: contextMenuHeader.mouseY,
                        left: contextMenuHeader.mouseX,
                      }
                    : undefined
                }
                headerKey={currentHeader}
                documentStore={documentStore}
                theme={theme}
              />
            </React.Fragment>
          );
        })}

      {[CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction) && (
        <ZoneDraft
          store={documentStore}
          theme={theme}
          isSelectMode={documentStore.isSimpleMode}
        />
      )}
    </>
  );
});
