import React, { FC, useState, useMemo, useEffect } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import EditorJson from "../../connections/components/EditorJson";
import { useNotification } from "../../../../context/useNotification";
import {
  ContextEventLog,
  ContextEventLogStackTrace,
} from "../../../../types/interfaces/contextEvent";
import DateHelper from "../../../../helper/dateHelper";
import { useStores } from "../../../../stores/StoresProvider";
import { CoreLevelIcon } from "../../../core/CoreLevelIcon";

const useStyles = makeStyles({
  rowCollapseContent: {
    height: 0,
    padding: 0,
  },
  actionCell: {
    width: "10px",
    padding: 0,
    borderColor: "transparent",
  },
  messageCell: {
    width: "100%",
    padding: "10px",
    borderColor: "transparent",
    wordBreak: "break-all",
  },
  timeCell: {
    borderColor: "transparent",
    padding: "0",
  },
  time: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    "@media screen and (max-width:768px)": {
      width: "150px",
    },
    height: "100%",
  },
  message: {
    flex: 1,
  },
  logContainer: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  logMessageFullContent: {
    marginTop: "10px",
    marginBottom: "10px",
    opacity: 0.7,
    fontStyle: "italic",
  },
});

export const LogExpander: FC<{
  eventIdentifier: string;
  log: ContextEventLog | undefined;
  translate: TFunction;
}> = ({ eventIdentifier, log, translate }) => {
  const classes = useStyles();
  const notification = useNotification();
  const {
    activityLogStore: { getLogStackTrace },
  } = useStores();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isStackTraceLoading, setStackTraceLoading] = useState(false);
  const [stackTrace, setStackTrace] = useState<ContextEventLogStackTrace>();

  const parsedLog = useMemo(
    () => JSON.stringify(stackTrace ? stackTrace || {} : {}, null, "\t"),
    [stackTrace]
  );

  const time = useMemo(
    () => (log?.time ? DateHelper.getTimeDifference(log?.time, translate) : ""),
    [log?.time, translate]
  );

  useEffect(() => {
    if (isCollapsed && !stackTrace) {
      setStackTraceLoading(true);
      getLogStackTrace(eventIdentifier, log?.id as string)
        .then((stackTraceData: ContextEventLogStackTrace) => {
          setStackTrace(stackTraceData);
          setStackTraceLoading(false);
        })
        .catch((error: Error) => {
          notification.error(error?.message);
          setStackTraceLoading(false);
        });
    } else if (!isCollapsed && stackTrace) {
      // Clear stack if already set and expander is collapsed
      setStackTrace(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.actionCell}>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>

        <TableCell className={classes.timeCell}>
          <Box className={classes.time}>
            <CoreLevelIcon level={log?.level} />
            <div className={classes.time}>{time}</div>
          </Box>
        </TableCell>

        <TableCell className={classes.messageCell}>
          <div className={classes.message}>
            {log?.message && log?.message?.length > 300
              ? `${log?.message?.substring(0, 300)}...`
              : log?.message}
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.rowCollapseContent} colSpan={6}>
          <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
            <Box className={classes.logMessageFullContent}>
              {log?.message || ""}
            </Box>

            {isStackTraceLoading ? (
              <Box className={classes.logContainer}>
                <CircularProgress size="25px" />
              </Box>
            ) : (
              <EditorJson isReadOnly value={parsedLog} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
