import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import CheckIcon from "@mui/icons-material/Check";

import { useStores } from "../../../../../../stores/StoresProvider";
import CoreButton from "../../../../../core/CoreButton";
import { CoreCard } from "../../../../../core/CoreCard";
import { CoreStatusTag } from "../../../../../core/CoreStatusTag";
import BillingHelper from "../../../../../../helper/billingHelper";
import { ContactSalesDrawer } from "../ContactSalesDrawer";
import { CancelSubscriptionConfirmationModal } from "../CancelSubscriptionConfirmationModal";
import {
  BILLING_CONTRACT_TERMS,
  PREDEFINED_PLANS,
} from "../../../../../../types/interfaces/subscription";
import { SubscriptionPrice } from "../SubscriptionPrice";

const useStyles = makeStyles({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    margin: "0px 5px",
  },
  contactSalesContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  price: {
    textAlign: "center",
    marginTop: "15px",
  },
  description: {
    textAlign: "center",
    fontSize: "12px",
    marginTop: "15px",
    opacity: 0.6,
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  benefitContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: "5px",
  },
  benefitCheckIcon: {
    marginRight: "10px",
  },
  cancelSubscriptionContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  subscriptionPlaceholder: {
    opacity: 0.6,
    textAlign: "center",
  },
  annualInfo: {
    fontSize: "12px",
    opacity: 0.6,
    marginTop: "3px",
    textAlign: "justify",
  },
  annualAwareness: {
    fontSize: "12px",
    opacity: 0.5,
    marginTop: "3px",
    fontStyle: "italic",
    textAlign: "justify",
  },
  annualInfoBox: {
    marginTop: "20px",
  },
});

export const Component = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation("subscription");
  const { subscriptionStore } = useStores();

  const [isConfirmModalOpened, toggleConfirmCancel] = useState(false);
  const [isContactSalesOpened, toggleContactSalesModal] = useState(false);

  const selectedSubscription = subscriptionStore.selectedSubscription;

  const periodPlan = useMemo(
    () =>
      selectedSubscription?.plan
        ? subscriptionStore.subscriptionPlans?.find(
            (item) =>
              item?.plan === selectedSubscription?.plan &&
              item?.period === subscriptionStore.contractTerm
          )
        : selectedSubscription,
    [
      subscriptionStore.contractTerm,
      selectedSubscription,
      subscriptionStore.subscriptionPlans,
    ]
  );

  if (selectedSubscription?.plan === PREDEFINED_PLANS.free) {
    return (
      <CoreCard>
        <Typography className={classes.subscriptionPlaceholder} variant="body2">
          {t("subscription_upgrade_preview_placeholder")}
        </Typography>
      </CoreCard>
    );
  }

  return (
    <>
      <CoreCard>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6">
            {periodPlan?.name || "-"}
          </Typography>
          {subscriptionStore.activePlan?.id === periodPlan?.id && (
            <CoreStatusTag
              label={t("subscription_upgrade_current_plan_tag")}
              type={"done"}
            />
          )}
        </Box>

        {!periodPlan?.subscribable ? (
          <Box className={classes.contactSalesContainer}>
            <CoreButton onClick={() => toggleContactSalesModal(true)}>
              {t("contactSales")}
            </CoreButton>
          </Box>
        ) : periodPlan?.price ? (
          <SubscriptionPrice
            isHeader
            price={periodPlan?.price}
            discount={periodPlan?.discount}
            currency={periodPlan?.currency}
            contractTerm={subscriptionStore.contractTerm}
            styleProps={{ marginTop: "10px" }}
          />
        ) : (
          <></>
        )}

        {periodPlan?.description && (
          <Typography className={classes.description}>
            {periodPlan.description}
          </Typography>
        )}

        {periodPlan?.subscriptionBenefits &&
          periodPlan?.subscriptionBenefits?.length > 0 && (
            <Box>
              <Divider className={classes.divider} />

              {periodPlan?.subscriptionBenefits.map((benefit, index) => (
                <Box key={index} className={classes.benefitContainer}>
                  <CheckIcon
                    fontSize="small"
                    className={classes.benefitCheckIcon}
                  />
                  <Typography variant="body2">{benefit}</Typography>
                </Box>
              ))}
            </Box>
          )}

        {periodPlan && !!periodPlan?.priceExtra && (
          <>
            <Divider className={classes.divider} />

            <Typography variant="body2">
              {t("subscription_upgrade_preview_additional_cost_label", {
                additionalCost: BillingHelper.convertSumToCurrency(
                  periodPlan?.priceExtra,
                  periodPlan?.currency
                ),
                additionalCostUnit: periodPlan?.priceExtraUnit,
              })}
            </Typography>
          </>
        )}
      </CoreCard>

      {subscriptionStore?.activePlan?.plan !== PREDEFINED_PLANS.free &&
      subscriptionStore?.activePlan?.period === BILLING_CONTRACT_TERMS.month ? (
        <Box className={classes.cancelSubscriptionContainer}>
          <CoreButton onClick={() => toggleConfirmCancel(true)}>
            {t("subscription_cancel_action")}
          </CoreButton>
        </Box>
      ) : (
        <></>
      )}

      {subscriptionStore.contractTerm === BILLING_CONTRACT_TERMS.year && (
        <Box className={classes.annualInfoBox}>
          <Typography variant="body2" className={classes.annualInfo}>
            {t("subscription_upgrade_preview_annual_info")}
          </Typography>

          <Typography variant="body2" className={classes.annualAwareness}>
            {t("subscription_upgrade_preview_annual_info_awareness")}
          </Typography>

          <Typography variant="body2" className={classes.annualInfo}>
            {t("subscription_upgrade_preview_annual_info_agreement")}
          </Typography>
        </Box>
      )}

      <ContactSalesDrawer
        planId={periodPlan?.id || ""}
        planName={periodPlan?.name || ""}
        isOpen={isContactSalesOpened}
        onClose={() => toggleContactSalesModal(false)}
      />

      <CancelSubscriptionConfirmationModal
        isOpen={isConfirmModalOpened}
        onClose={() => toggleConfirmCancel(false)}
      />
    </>
  );
});

export const UpgradePlanPreview = memo(Component);
