import { Box, CircularProgress, Typography } from "@mui/material";
import React, { FC, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { makeStyles } from "@mui/styles";

import { useStores } from "../../../../../../stores/StoresProvider";
import CoreDrawer from "../../../../../core/CoreDrawer";
import CoreButton from "../../../../../core/CoreButton";
import { UpdateCreditCard } from "./UpdateCreditCard";

const useStyles = makeStyles({
  title: { margin: "0px 0 20px 0" },
  loadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  action: {
    margin: "10px 10px",
  },
  noDataMessageContainer: {
    margin: "10px 20px 20px 20px",
  },
  noDataMessage: {
    textAlign: "center",
  },
});

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const UpdateCreditCardDrawer: FC<Props> = ({ isOpened, onClose }) => {
  const { t } = useTranslation("subscription");
  const { subscriptionStore } = useStores();
  const classes = useStyles();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeKey, setStripeKey] = useState("");

  useEffect(() => {
    if (isOpened) {
      setIsDataLoading(true);

      subscriptionStore
        .getBillingSetupIntent()
        .then(({ publishableKey, clientSecret }) => {
          setClientSecret(clientSecret);
          setStripeKey(publishableKey);
          setIsDataLoading(false);
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    }
  }, [isOpened, subscriptionStore]);

  const stripePromise = useMemo(
    () => (stripeKey && clientSecret ? loadStripe(stripeKey) : undefined),
    [stripeKey, clientSecret]
  );

  return (
    <CoreDrawer
      isOpen={isOpened}
      onClose={onClose}
      title={t("active_subscription_update_card_drawer_title")}
    >
      {isDataLoading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress size="30px" color="inherit" />
        </Box>
      ) : (
        <>
          {stripePromise ? (
            <>
              <Typography className={classes.title}>
                {t("active_subscription_update_card_drawer_subtitle")}
              </Typography>

              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <UpdateCreditCard
                  onCancelClick={onClose}
                  clientSecret={clientSecret}
                />
              </Elements>
            </>
          ) : (
            <>
              <Box className={classes.noDataMessageContainer}>
                <Typography className={classes.noDataMessage}>
                  {t("active_subscription_update_card_drawer_no_instance")}
                </Typography>
              </Box>
            </>
          )}

          {(!stripeKey || !clientSecret) && (
            // Make cancel possible if stripe is not set
            <Box className={classes.actionsContainer}>
              <CoreButton
                variant="outlined"
                onClick={onClose}
                className={classes.action}
              >
                {t("active_subscription_update_card_drawer_cancel_btn")}
              </CoreButton>
            </Box>
          )}
        </>
      )}
    </CoreDrawer>
  );
};
