import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Box,
  Typography,
  useTheme,
  alpha,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { Flow } from "../../../../types/interfaces";
import { FLOW_DATA_TYPES_FRONTEND } from "../../../../types/constants";
import { appRoutes } from "../../../../configs/routes";
import { useStores } from "../../../../stores/StoresProvider";
import FlowHelper from "../../../../helper/flowHelper";

interface Props {
  flow: Flow;
}

const ExpandableCardFlow: FC<Props> = observer(({ flow }) => {
  const { t } = useTranslation("flow");
  const { mainStore, flowStore } = useStores();
  const history = useHistory();
  const theme = useTheme();

  const expanded = flowStore.expandedCardFlow[flow.identifier];

  const classes = makeStyles({
    accordion: {
      "& .MuiAccordionSummary-content": {
        margin: "0",
        minHeight: "0px",
        "& .Mui-expanded": {
          margin: "0",
          minHeight: "0px",
        },
      },
      "& .MuiAccordionSummary-root": {
        margin: "0",
        minHeight: "0px",
        "& .Mui-expanded": {
          minHeight: "0px",
          margin: "0",
        },
      },
      boxShadow: "none",
    },
    title: {
      display: "flex",
      wordBreak: "break-word",
    },
    accordionSummary: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    boxAccordionSummary: {
      display: "flex",
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
      },
      borderRadius: "2px",
    },
    selectedAccordionSummary: {
      display: "flex",
      "& .MuiTypography-root": {
        color: theme.palette.highlight.main,
      },
      backgroundColor: alpha(theme.palette.highlight.light, 0.08),
      borderRadius: "2px",
    },
    accordionDetails: {
      "& .MuiAccordionDetails-root": {
        padding: "0",
      },
    },
    listItemButton: {
      padding: "0 0 0 30px",
      margin: "5px 0",
      "&.Mui-selected": {
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
      "& > div > span": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  })();

  const accordionSummaryClass = mainStore.currentPage.includes(flow.identifier)
    ? classes.selectedAccordionSummary
    : classes.boxAccordionSummary;

  const subOptions = [
    {
      key: flow.identifier,
      name: t("documents"),
      route: appRoutes.FlowDetails(flow.identifier),
    },
    {
      key: FLOW_DATA_TYPES_FRONTEND.dataSources,
      name: t(FLOW_DATA_TYPES_FRONTEND.dataSources),
      route: appRoutes.FlowDataCategories(
        flow.identifier,
        FLOW_DATA_TYPES_FRONTEND.dataSources
      ),
    },
    {
      key: FLOW_DATA_TYPES_FRONTEND.dataExports,
      name: t(FLOW_DATA_TYPES_FRONTEND.dataExports),
      route: appRoutes.FlowDataCategories(
        flow.identifier,
        FLOW_DATA_TYPES_FRONTEND.dataExports
      ),
    },
  ];

  const handleClick = (e: React.MouseEvent, route: string) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(route);
  };

  useEffect(() => {
    if (mainStore.currentPage.includes(flow.identifier)) {
      FlowHelper.handleExpandCardFlow(flowStore, flow.identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion expanded={expanded || false} className={classes.accordion}>
      <Box className={accordionSummaryClass}>
        <AccordionSummary className={classes.accordionSummary}>
          <Typography className={classes.title}>{flow.name}</Typography>
        </AccordionSummary>

        <IconButton
          onClick={(e) =>
            FlowHelper.handleExpandCardFlow(flowStore, flow.identifier, true, e)
          }
        >
          {expanded === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Box className={classes.accordionDetails}>
        <AccordionDetails>
          {subOptions.map((option, index) => (
            <ListItemButton
              key={index}
              className={classes.listItemButton}
              onClick={(e) => {
                handleClick(e, option.route);
                FlowHelper.handleExpandCardFlow(
                  flowStore,
                  flow.identifier,
                  false,
                  e as unknown as React.FormEvent<HTMLButtonElement>
                );
              }}
              selected={mainStore.currentPage === option.route}
            >
              <ListItemText title={option.name}>{option.name}</ListItemText>
            </ListItemButton>
          ))}
        </AccordionDetails>
      </Box>
    </Accordion>
  );
});

export default ExpandableCardFlow;
