import { createContext, useContext } from "react";
import { ApolloClient } from "@apollo/client";

import { MainStore } from "./MainStore";
import { DocumentStore } from "./DocumentStore";
import { FlowStore } from "./FlowStore";
import { UserStore } from "./UserStore";
import { ActivityLogStore } from "./ActivityLogStore";
import { LandingStore } from "./LandingStore";
import { SubscriptionStore } from "./SubscriptionStore";
import { StorageStore } from "./StorageStore";
import { QAStore } from "./QAStore";
import { IdentityStore } from "./IdentityStore";
import { ChatGptStore } from "./ChatGptStore";
import { FlowSettingsStore } from "./FlowSettingsStore";

export const STORES = [
  "mainStore",
  "documentStore",
  "flowStore",
  "userStore",
  "activityLogStore",
  "landingStore",
  "subscriptionStore",
  "storageStore",
  "qaStore",
  "identityStore",
  "chatGptStore",
  "flowSettingsStore",
];

export class RootStore {
  client: ApolloClient<unknown>;

  root = this;
  mainStore: MainStore;
  documentStore: DocumentStore;
  flowStore: FlowStore;
  userStore: UserStore;
  activityLogStore: ActivityLogStore;
  landingStore: LandingStore;
  subscriptionStore: SubscriptionStore;
  storageStore: StorageStore;
  qaStore: QAStore;
  identityStore: IdentityStore;
  chatGptStore: ChatGptStore;
  flowSettingsStore: FlowSettingsStore;

  constructor(apolloClient: ApolloClient<unknown>) {
    this.client = apolloClient;

    this.mainStore = new MainStore(this);
    this.documentStore = new DocumentStore(this);
    this.flowStore = new FlowStore(this);
    this.userStore = new UserStore(this);
    this.activityLogStore = new ActivityLogStore(this);
    this.landingStore = new LandingStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
    this.storageStore = new StorageStore(this);
    this.qaStore = new QAStore(this);
    this.identityStore = new IdentityStore(this);
    this.chatGptStore = new ChatGptStore(this);
    this.flowSettingsStore = new FlowSettingsStore(this);
  }

  resetStores() {
    this.mainStore = new MainStore(this);
    this.documentStore = new DocumentStore(this);
    this.flowStore = new FlowStore(this);
    this.userStore = new UserStore(this);
    this.activityLogStore = new ActivityLogStore(this);
    this.landingStore = new LandingStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
    this.storageStore = new StorageStore(this);
    this.qaStore = new QAStore(this);
    this.identityStore = new IdentityStore(this);
    this.chatGptStore = new ChatGptStore(this);
    this.flowSettingsStore = new FlowSettingsStore(this);
  }

  resetSpecificStores(stores: string[]) {
    stores.forEach((store) => {
      this.resetSpecificStore(store);
    });
  }

  resetSpecificStore(store: string) {
    switch (store) {
      case "mainStore":
        this.mainStore = new MainStore(this);
        break;
      case "documentStore":
        this.documentStore = new DocumentStore(this);
        break;
      case "flowStore":
        this.flowStore = new FlowStore(this);
        break;
      case "userStore":
        this.userStore = new UserStore(this);
        break;
      case "activityLogStore":
        this.activityLogStore = new ActivityLogStore(this);
        break;
      case "landingStore":
        this.landingStore = new LandingStore(this);
        break;
      case "subscriptionStore":
        this.subscriptionStore = new SubscriptionStore(this);
        break;
      case "storageStore":
        this.storageStore = new StorageStore(this);
        break;
      case "qaStore":
        this.qaStore = new QAStore(this);
        break;
      case "identityStore":
        this.identityStore = new IdentityStore(this);
        break;
      case "chatGptStore":
        this.chatGptStore = new ChatGptStore(this);
        break;
      case "flowSettingsStore":
        this.flowSettingsStore = new FlowSettingsStore(this);
        break;
      default:
        break;
    }
  }
}

export function createStore(apolloClient: ApolloClient<unknown>): RootStore {
  return new RootStore(apolloClient);
}

export type StoreType = ReturnType<typeof createStore>;

export const StoresContext = createContext<StoreType | null>(null);

export function useStores(): RootStore {
  const store = useContext(StoresContext);
  if (!store) {
    throw new Error("Store cannot be used outside StoreProvider!");
  }

  return store;
}
