import React, { FC, useMemo } from "react";
import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface CoreTooltipProps extends TooltipProps {
  transparent?: boolean;
  arrow?: boolean;
  titleCharLimit?: number;
}

const preprocessTitle = (title?: string | React.ReactNode, limit?: number) => {
  // When title is string, limit is received and title exceeds limit accepted characters
  if (
    title &&
    typeof title === "string" &&
    limit &&
    limit > 0 &&
    title.length > limit
  ) {
    return `${title.slice(0, limit)}...`;
  }

  return title;
};

const CoreTooltip: FC<CoreTooltipProps> = ({
  transparent = false,
  arrow = true,
  children,
  titleCharLimit,
  title,
  ...otherProps
}) => {
  const theme = useTheme();
  const { mainStore } = useStores();

  const useStyles = makeStyles({
    tooltip: {
      padding: "5px",
      backgroundColor: !transparent ? theme.palette.secondary.light : undefined,
      color:
        mainStore.currentTheme === THEME_MODES.light
          ? theme.palette.common.black
          : theme.palette.common.white,
    },
    arrow: {
      color: theme.palette.secondary.light,
    },
    title: {
      whiteSpace: "pre-line",
    },
  });

  const classes = useStyles();

  const hint = useMemo(
    () => preprocessTitle(title, titleCharLimit),
    [title, titleCharLimit]
  );

  return hint ? (
    <Tooltip
      componentsProps={{
        tooltip: { className: classes.tooltip },
        arrow: { className: classes.arrow },
      }}
      arrow={arrow}
      TransitionProps={{ timeout: 0 }}
      {...otherProps}
      title={<span className={classes.title}>{hint}</span>}
    >
      {children}
    </Tooltip>
  ) : (
    (children as unknown as React.ReactElement)
  );
};

export default CoreTooltip;
