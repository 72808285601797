import React from "react";

import { makeStyles } from "@mui/styles";
import { TFunction } from "i18next";

import { Card, CardContent, Typography, Grid, useTheme } from "@mui/material";
import { useStores } from "../../../../../stores/StoresProvider";

interface SummaryProps {
  t: TFunction;
}

const SummaryCard: React.FC<SummaryProps> = ({ t }) => {
  const theme = useTheme();

  const useStyle = makeStyles({
    container: {
      border: `1px solid ${theme.palette?.primary.main}`,
      width: "668px",
      height: "auto",
      padding: "18px 24px 0px 24px",
      background: "transparent",
    },
    heading: {
      paddingBottom: 20,
    },
    grid: {
      justifyContent: "space-between",
      paddingBottom: 24,
    },
  });

  const classes = useStyle();

  const { landingStore } = useStores();
  const { firstName, lastName, email, namespace } =
    landingStore.userRegistration;

  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography variant="h5" className={classes.heading}>
          {t("reviewPlanSummaryHeading")}
        </Typography>
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item>
            <Typography variant="body1">{`${t(
              "reviewPlanNamespace"
            )}: ${namespace}`}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">{`${t(
              "reviewPlanFirstName"
            )}: ${firstName}`}</Typography>
          </Grid>
        </Grid>

        <Grid container flexDirection="row" className={classes.grid}>
          <Grid item>
            <Typography variant="body1">{`${t(
              "reviewPlanEmail"
            )}: ${email}`}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">{`${t(
              "reviewPlanLastName"
            )}: ${lastName}`}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
