import { gql } from "@apollo/client";

export const DOCUMENTS_SUBSCRIPTIONS = gql`
  subscription documentStatusChanged(
    $contextId: String!
    $flowIdentifier: String
    $documentIds: [String]
  ) {
    documentStatusChanged(
      contextId: $contextId
      flowIdentifier: $flowIdentifier
      documentIds: $documentIds
    )
  }
`;
