import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { useTheme, Typography, Box, Divider, alpha } from "@mui/material";

import { SelectOptionDef } from "../../../types/interfaces";
import EditorJson from "../connections/components/EditorJson";

import { PythonIcon } from "./PythonIcon";
import { CSharpIcon } from "./CSharpIcon";
import { JavaIcon } from "./JavaIcon";
import { FormFieldLanguageTypes } from "../../../types/enums";

const CODE_LANGUAGES = [
  {
    icon: PythonIcon,
    name: FormFieldLanguageTypes.python,
  },
  {
    icon: CSharpIcon,
    name: FormFieldLanguageTypes.charp,
  },
  {
    icon: JavaIcon,
    name: FormFieldLanguageTypes.java,
  },
];

const IntegrationContent: React.FC<{
  integrationCode: SelectOptionDef[];
}> = ({ integrationCode }) => {
  const { t } = useTranslation("apikeys");
  const theme = useTheme();

  const [activeLang, setActiveLang] = useState<FormFieldLanguageTypes>(
    FormFieldLanguageTypes.python
  );

  const classes = makeStyles({
    titleContent: {
      marginTop: "35px",
    },
    divider: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    langContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    langBox: {
      display: "flex",
      padding: "10px 15px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
      },
    },
    activeLangBox: {
      backgroundColor: alpha(theme.palette.highlight.main, 0.2),
      display: "flex",
      padding: "10px 15px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
      },
    },
    integrationCodeBox: {
      minHeight: "450px",
      marginBottom: "20px",
    },
    noIntegrationCode: {
      marginTop: "15px",
    },
  })();

  const activeIntegrationCode = useMemo(
    () =>
      integrationCode && activeLang
        ? integrationCode?.find((item) => item.key === activeLang)?.contents
        : null,
    [activeLang, integrationCode]
  );

  return (
    <>
      <Typography variant="h6" className={classes.titleContent}>
        {t("integrationTitle")}
      </Typography>

      <Divider className={classes.divider} />

      <Box className={classes.langContainer}>
        {CODE_LANGUAGES.map((type) => (
          <Box
            key={type.name}
            className={
              activeLang === type.name ? classes.activeLangBox : classes.langBox
            }
            onClick={() => setActiveLang(type.name)}
          >
            <type.icon />
          </Box>
        ))}
      </Box>

      {activeIntegrationCode ? (
        <Box className={classes.integrationCodeBox}>
          <EditorJson
            isReadOnly
            editorHeight="430px"
            value={activeIntegrationCode}
            mode={activeLang}
          />
        </Box>
      ) : (
        <Typography className={classes.noIntegrationCode}>
          {t("noIntegrationCode")}
        </Typography>
      )}
    </>
  );
};

export default IntegrationContent;
