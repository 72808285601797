import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiLinearProgress = (
  theme: ThemeOptions
): Components["MuiLinearProgress"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        backgroundColor: palette.highlight.main,
      },
    },
  };
};
