import React from "react";
import { TFunction } from "i18next";

import { Box, FormHelperText, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { EXPORT_KEY, FormData, FormField } from "../../types/interfaces";
import CoreButton from "../core/CoreButton";
import AdvancedExport from "../pages/addNewFlow/tabs/assetsCore/components/AdvancedExport";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface Props {
  translation: TFunction;
  value: unknown;
  onChange: (value: FormData) => void;
  formData: FormData;
  field: FormField;
}
const AdvancedExportRenderer: React.FC<Props> = ({
  translation,
  value,
  onChange,
  formData,
  field,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formText: {
      margin: "auto 0",
      height: "auto",
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
  });

  const handleChangeForm = (value: FormData) => {
    onChange(value);
    setOpenAdvancedExport(false);
  };

  const [openAdvancedExport, setOpenAdvancedExport] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box className={classes.container}>
          <Typography>
            {translation("fieldsExported", {
              number: (
                ((value as EXPORT_KEY[]) ?? [])?.filter(
                  (item) => item.willExport
                ) ?? []
              ).length,
            })}
            {field.isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
          {/* error text for 0 fields selected */}
          <CoreButton
            onClick={() => {
              setOpenAdvancedExport(true);
            }}
          >
            {translation("selectExportedFields")}
          </CoreButton>
        </Box>

        {field.errorText && (
          <Box className={classes.boxError}>
            <CancelOutlinedIcon className={classes.cancelIcon} />

            <FormHelperText
              error={!!field.errorText}
              className={classes.formText}
            >
              {field.errorText}
            </FormHelperText>
          </Box>
        )}
      </Box>
      <AdvancedExport
        exportType={formData.exportType as string}
        isOpen={openAdvancedExport}
        onClose={() => setOpenAdvancedExport(false)}
        t={translation}
        handleChangeForm={handleChangeForm}
        formData={formData}
      />
    </>
  );
};

export default AdvancedExportRenderer;
