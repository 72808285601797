import React, { FC, useState } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { DateRange } from "@mui/lab/DateRangePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { alpha, useTheme } from "@mui/material";

import DateHelper from "../../../helper/dateHelper";
import CoreButton from "../CoreButton";
import { useStores } from "../../../stores/StoresProvider";
import {
  MONTHS,
  MONTHS_RO,
  WEEK_DAYS,
  WEEK_DAYS_RO,
  THEME_MODES,
} from "../../../types/constants";

interface Props {
  onUpdate: (value: DateRange<Date>) => void;
  onClose: () => void;
  currentValues?: DateRange<Date>;
  t: TFunction;
}

const DateSelector: FC<Props> = ({
  currentValues = [null, null],
  onClose,
  onUpdate,
  t,
}) => {
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;
  const theme = useTheme();

  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "280px",
    },
    preset: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
      backgroundColor: alpha(theme.palette.primary.dark, 0.1),
      borderRight: `2px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
      padding: 0,
    },
    datePicker: {
      "& .react-datepicker__header": {
        background: alpha(theme.palette.primary.dark, 0.1),
        borderRadius: 0,
      },
      "& .react-datepicker": {
        background: alpha(theme.palette.primary.dark, 0.1),
        border: "none",
        borderRadius: 0,
        height: "280px",
        marginBottom: "-10px",
      },
      "& react-datepicker__month-container": {
        borderRadius: 0,
      },
      "& .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header":
        {
          color:
            currentTheme !== THEME_MODES.light
              ? theme.palette.text.primary
              : "",
        },
      "& .react-datepicker__day-name": {
        color: `${theme.palette.text.primary} !important`,
      },
      "& .react-datepicker__day": {
        color: `${theme.palette.text.primary} !important`,
      },
      "& .react-datepicker__day-names": {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    listStyle: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    listItemButtonStyle: {
      paddingBottom: "4px",
      paddingTop: "4px",
      fontSize: "14px",
    },
  });

  const classes = useStyles();
  const [value, setValue] = useState<DateRange<Date>>(currentValues);

  const TIME_OPTIONS = [
    {
      label: t("today"),
      start: DateHelper.getStartDay(0),
      end: DateHelper.getEndDay(0),
    },
    {
      label: t("yesterday"),
      start: DateHelper.getStartDay(1),
      end: DateHelper.getEndDay(1),
    },
    {
      label: t("thisWeek"),
      start: DateHelper.getThisWeek(),
      end: DateHelper.getEndDay(0),
    },
    {
      label: t("lastWeek"),
      start: DateHelper.getStartLastWeek(),
      end: DateHelper.getEndLastWeek(),
    },
    {
      label: t("thisMonth"),
      start: DateHelper.getStartThisMonth(),
      end: DateHelper.getEndThisMonth(),
    },
    {
      label: t("lastMonth"),
      start: DateHelper.getStartLastMonth(),
      end: DateHelper.getEndLastMonth(),
    },
  ];

  const days = mainStore.languageFlag === "GB" ? WEEK_DAYS : WEEK_DAYS_RO;
  const months = mainStore.languageFlag === "GB" ? MONTHS : MONTHS_RO;

  const locale = {
    localize: {
      day: (n: number) => days[n],
      month: (n: number) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  } as unknown as Locale;

  const handleApply = () => {
    if (value[0]) {
      onUpdate(value);
    }
  };

  const handleChange = (dates: DateRange<Date>) => {
    setValue(dates);
  };

  const handlePresetChange = (start: Date, end: Date) => {
    setValue([start, end]);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.preset}>
        <List className={classes.listStyle}>
          {TIME_OPTIONS.map((value) => (
            <ListItemButton
              key={value.label}
              onClick={() => handlePresetChange(value.start, value.end)}
              className={classes.listItemButtonStyle}
            >
              <ListItemText
                primary={value.label}
                primaryTypographyProps={{
                  fontSize: "14px",
                }}
              />
            </ListItemButton>
          ))}
        </List>
        <CoreButton
          onClick={() => {
            handleApply();
            onClose();
          }}
          variant="contained"
        >
          {t("apply_selectedDates")}
        </CoreButton>
      </Box>

      <Box className={classes.datePicker}>
        <DatePicker
          locale={locale}
          selected={value[0]}
          onChange={handleChange}
          startDate={value[0]}
          endDate={value[1]}
          selectsRange
          inline
        />
      </Box>
    </Box>
  );
};

export default DateSelector;
