import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";

import { Box } from "@mui/material";
import CoreInput from "../../../../core/CoreInput";

import {
  FIELD_MAX_LENGTH,
  FlowInterface,
} from "../../../../../types/interfaces";

import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import BoxInfoContent from "../BoxInfoContent";
import { AppSuspense } from "../../../../main/AppSuspense";
import FlowTabHeaderActions from "../FlowTabHeaderActions";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const useStyles = makeStyles({
  nameFormElement: {
    "& .MuiOutlinedInput-root": {
      marginRight: 0,
    },
    alignSelf: "flex-start !important",
    maxWidth: "540px",
  },
  outerBox: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  infoInnerBox: {
    maxWidth: "540px",
  },
  box: {
    marginTop: "15px",
    maxWidth: "540px",
  },
});

const OverviewTab: React.FC<Props> = observer(({ t, flowSettingsStore }) => {
  const [flowName, setFlowName] = useState("");
  const [loadingCheckName, setLoadingCheckName] = useState(false);
  const [nameError, setNameError] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const timer = 1000;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlowName(event.target.value);
  };

  useEffect(() => {
    setFlowName(flowSettingsStore.flow?.name || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.flow?.name]);

  useEffect(() => {
    if (!isEditable) return;

    setLoadingCheckName(true);

    const delay = setTimeout(() => {
      //TODO: Check here for cloned flow id
      if (flowName && flowName !== flowSettingsStore.flow?.name) {
        checkName(flowName.trim() || "");
      } else if (flowName.length < 3) {
        setLoadingCheckName(false);
        setNameError("createFlow_nameTooShort");
      } else {
        setLoadingCheckName(false);
        setNameError("");
      }
    }, timer);

    return () => {
      clearTimeout(delay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowName, isEditable]);

  const checkName = (name: string) => {
    if (name.length < 3) {
      setNameError("createFlow_nameTooShort");
      setLoadingCheckName(false);
      return;
    }

    setLoadingCheckName(true);
    setNameError("");
    flowSettingsStore
      .checkFlowName(name)
      .then((res) => {
        if (!res) {
          setNameError("createFlow_nameNotAvailable");
        }
      })
      .catch((error: Error) => {
        setNameError(error?.message || "createFlow_nameNotAvailable");
      })
      .finally(() => {
        setLoadingCheckName(false);
      });
  };

  const handleOnCancel = () => {
    setIsEditable(false);
    setFlowName(flowSettingsStore.flow?.name || "");
  };

  return (
    <CoreTabContainer
      t={t}
      title={t(flowSettingsStore.selectedTab || "")}
      extraHeaderActions={
        <FlowTabHeaderActions
          t={t}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          disableSaveButton={loadingCheckName || !flowName}
          editButtonDisabled={flowSettingsStore.loadingFlowSettings}
          onCancel={handleOnCancel}
          form={
            {
              name: flowName,
            } as FlowInterface
          }
        />
      }
    >
      {flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <Box className={classes.outerBox}>
          <CoreInput
            fullWidth
            placeholder={t("createFlow_namePlaceholder")}
            title={t("flowName")}
            value={flowName || ""}
            name="name"
            onChange={handleChange}
            disabled={!isEditable}
            isMandatory
            formControlClass={classes.nameFormElement}
            description={t("createFlow_nameDescription")}
            inputProps={{
              maxLength: FIELD_MAX_LENGTH.NAME,
            }}
          />
          {isEditable && (
            <BoxInfoContent
              t={t}
              flowName={flowName}
              loadingCheckName={loadingCheckName}
              nameError={nameError}
              isOverViewTab={true}
            />
          )}
        </Box>
      )}
    </CoreTabContainer>
  );
});

export default OverviewTab;
