import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CircularProgress,
  Link,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import EastIcon from "@mui/icons-material/East";

import { appRoutes, authRoutes } from "../../../configs/routes";
import { useStores } from "../../../stores/StoresProvider";
import { NamespaceRole } from "../../../types/interfaces";
import CoreButton from "../../core/CoreButton";
import { useNotification } from "../../../context/useNotification";
import { AppSuspense } from "../../main/AppSuspense";
import CoreFooter from "../../core/table/CoreFooter";
import CoreTooltip from "../../core/CoreTooltip";
import Logo from "../../../../src/icons/Logo";
import {
  PAGE_SIZES,
  SORT_DIRECTION,
  THEME_MODES,
} from "../../../types/constants";
import Languages from "../../main/sidebar/account/Languages";

const Namespaces: React.FC = () => {
  const {
    userStore,
    mainStore: { currentTheme },
  } = useStores();
  const theme = useTheme();
  const client = useApolloClient();
  const [namespaces, setNamespaces] = useState<{
    count: number;
    rows: NamespaceRole[];
  }>({ count: 0, rows: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [updatingNamespaceId, setUpdatingNamespaceId] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(1);
  const { t, ready } = useTranslation("landingPages");
  const notification = useNotification();
  const history = useHistory();
  const classes = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      Height: "100%",
      flex: 1,
    },
    logo: {
      width: 200,
      height: 25,
    },
    logContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "25px",
    },
    mail: {
      fontWeight: 700,
      margin: "0 5px",
    },
    logOut: {
      margin: "0 4px",
      color: theme.palette.buttons.main,
      textDecoration: "underline",
      fontSize: "14px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    cardsContainer: {
      width: "100%",
      maxWidth: "600px",
      minWidth: "400px",
      maxHeight: "600px",
      overflow: "auto",
    },
    createNSBtnContainer: {
      marginTop: "40px",
      width: "100%",
      maxWidth: "600px",
      display: "flex",
      justifyContent: "flex-end",
    },
    nsCard: {
      position: "relative",
      padding: "20px",
      display: "flex",
      alignItems: "center",
      boxShadow: "none",
      borderRadius: 0,
      backgroundImage: "none",
      "&:hover": {
        backgroundColor:
          currentTheme === THEME_MODES.dark
            ? theme.palette.secondary.dark
            : theme.palette.secondary.light,
      },
    },
    beforeDot: {
      position: "absolute",
      left: 15,
    },
    defaultDot: {
      color: theme.palette.buttons.main,
      width: "12px",
    },
    cardInnerContainer: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "20px",
    },
    nsName: {
      fontWeight: 700,
      textTransform: "capitalize",
    },
    nsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    setDefault: {
      marginLeft: "20px",
      color: theme.palette.buttons.main,
      textDecoration: "underline",
      fontSize: "14px",
    },
    underNs: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    openLink: {
      textDecoration: "none",
      position: "absolute",
      right: 15,
    },
    open: {
      width: "80px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      "&:hover": {
        "& #open": {
          display: "block",
        },
      },
    },
    openText: {
      color: theme.palette.buttons.main,
      marginRight: "10px",
      display: "none",
    },
    icon: {
      fontSize: 17,
      marginRight: "3px",
    },
    typography: {
      margin: "20px 0",
    },
    eastIcon: {
      color: theme.palette.text.primary,
    },
    inactiveNsCard: {
      opacity: 0.5,
    },
    circularProgress: {
      marginLeft: "10px",
    },
    mainBox: {},
  })();

  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  const getNamespaces = () => {
    setIsLoading(true);
    userStore
      .getNamespaces(client, rowsPerPage, rowsPerPage * (page - 1), [
        "namespace_id",
        SORT_DIRECTION.desc,
      ])
      .then((res) => {
        setNamespaces(res);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "errorGettingNamespaces"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNamespaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const numberOfPages =
    namespaces.count < rowsPerPage
      ? 1
      : Math.ceil(namespaces.count / rowsPerPage);

  // TODO: Add mumber of flows in namespace

  const handleSetDefault = (namespaceId: number) => {
    setUpdatingNamespaceId(namespaceId);
    userStore
      .updateDefaultNamespace(client, namespaceId)
      .then(() => {
        void userStore.getUserFromContext(client);
      })
      .then(() => {
        getNamespaces();
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "updateDefaultNamespaceError"));
      })
      .finally(() => {
        setUpdatingNamespaceId(null);
      });
  };

  const getIsActiveComponent = (namespace: NamespaceRole) => {
    if (
      namespace.user.default_ns !== namespace.namespace.id &&
      namespace.is_active
    ) {
      if (updatingNamespaceId === namespace.namespace.id) {
        return (
          <CircularProgress size="15px" className={classes.circularProgress} />
        );
      }

      return (
        <Link
          component="button"
          className={classes.setDefault}
          onClick={() => handleSetDefault(namespace.namespace.id)}
        >
          {t("setAsDefault")}
        </Link>
      );
    }
    return <></>;
  };

  const namespaceList = namespaces.rows.map((namespace) => {
    return (
      <Box key={namespace.namespace.id} className={classes.mainBox}>
        <Card
          key={namespace.namespace.id}
          variant="elevation"
          className={`${classes.nsCard} ${
            !namespace.is_active ? classes.inactiveNsCard : ""
          }`}
        >
          {namespace.user.default_ns === namespace.namespace.id && (
            <Box className={classes.beforeDot}>
              <FiberManualRecordIcon className={classes.defaultDot} />
            </Box>
          )}
          <Box className={classes.cardInnerContainer}>
            <Box className={classes.nsContainer}>
              <Typography className={classes.nsName}>
                {namespace.namespace.name}
              </Typography>

              {getIsActiveComponent(namespace)}
            </Box>
            <Box className={classes.underNs}>
              <PersonOutlineIcon className={classes.icon} />
              <Typography variant="body2">{namespace.scope}</Typography>
            </Box>
          </Box>
          {namespace.is_active ? (
            <Link
              className={classes.openLink}
              component="button"
              disabled={Boolean(updatingNamespaceId)}
              onClick={() => {
                userStore
                  .selectNamespace(client, Number(namespace.namespace.id))
                  .then(() => {
                    history.push(appRoutes.Home());
                  })
                  .catch((error: Error) => {
                    notification.error(
                      t(error?.message || "errorSelectingNamespace")
                    );
                  });
              }}
            >
              <Box className={classes.open}>
                <Typography
                  variant="body2"
                  className={classes.openText}
                  id="open"
                >
                  {t("open")}
                </Typography>{" "}
                <EastIcon className={classes.eastIcon} />
              </Box>
            </Link>
          ) : (
            <Box className={classes.openLink}>
              <Box className={classes.open}>
                <CoreTooltip title={t("namespaceNotActive")}>
                  <DoNotDisturbIcon className={classes.eastIcon} />
                </CoreTooltip>
              </Box>
            </Box>
          )}
        </Card>
        <Divider />
      </Box>
    );
  });

  const performLogout = async () => {
    await userStore.logoutUser(client).catch((error: Error) => {
      notification.error(t(error?.message || "logoutError"));
    });
    await client.resetStore();
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Box className={classes.container}>
        <Logo fullLogoClassName={classes.logo} />
        <Typography variant="body2" className={classes.typography}>
          {t("selectNamespaceTxt")}
        </Typography>
        <Box className={classes.logContainer}>
          <Typography variant="body2">{t("loggedInAs")}</Typography>
          <Typography variant="body2" className={classes.mail}>
            {userStore.user?.email}
          </Typography>
          (
          <Link
            component="button"
            onClick={performLogout}
            className={classes.logOut}
          >
            {t("logout")}
          </Link>
          )
        </Box>

        <Box className={classes.content}>
          <Box className={classes.cardsContainer}>
            {isLoading ? <AppSuspense /> : namespaceList}
          </Box>
          <CoreFooter
            changePage={handleChangePage}
            page={page}
            numberOfPages={numberOfPages}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={PAGE_SIZES}
            setRowsPerPage={setRowsPerPage}
            totalCount={namespaces.count}
            isFixed={false}
          />
          <Box className={classes.createNSBtnContainer}>
            <CoreButton
              variant="contained"
              onClick={() => history.push(authRoutes.SignUpJourney())}
            >
              {t("createNewNamespace")}
            </CoreButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Namespaces;
