import React, { useState } from "react";
import moment from "moment";
import { TFunction } from "i18next";

import { DateRange } from "@mui/lab/DateRangePicker";

import CoreTag from "../../../core/CoreTag";
import DatePicker from "../../../core/DatePicker";
import { DATE_FORMAT, FILTER_PROPS } from "../../../../types/constants";
import { DocumentsFilterProps } from "../../../../types/interfaces";

const formatData = (value: DateRange<Date>) => {
  if (value[0]) {
    const startDate = moment(value[0]?.toISOString()).format(DATE_FORMAT.date);
    const endDate = value[1]
      ? `- ${moment(value[1]?.toISOString()).format(DATE_FORMAT.date)}`
      : "-";

    return `${startDate} ${endDate}`;
  }

  return "";
};

export interface Props {
  filtersValues: string | DateRange<Date>;
  t: TFunction;
  isDate?: boolean;
  filters: DocumentsFilterProps;
  setFilters: (key: string, value: string | string[] | unknown) => void;
}

const FilterTag: React.FC<Props> = ({
  filtersValues,
  t,
  isDate,
  filters,
  setFilters,
}) => {
  const [isOpen, setIsOpen] = useState<(EventTarget & HTMLElement) | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isDate) {
      const anchorEl = event ? event.currentTarget : null;
      setIsOpen(anchorEl);
    }
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  if (!filtersValues || filtersValues?.length === 0) {
    return null;
  }

  if (isDate) {
    const formattedValue = formatData(filtersValues as DateRange<Date>);

    const handleChangeDate = (value?: DateRange<Date>) => {
      if (value)
        setFilters(FILTER_PROPS.date, {
          startDate: value[0],
          endDate: value[1],
        });
      else {
        setFilters(FILTER_PROPS.date, {
          startDate: null,
          endDate: null,
        });
      }
    };

    return (
      <>
        <CoreTag
          label={formattedValue}
          variant="outlined"
          showTooltip={true}
          onDelete={() => handleChangeDate()}
          onClick={handleOpen}
        />

        <DatePicker
          t={t}
          anchorEl={isOpen}
          onClose={handleClose}
          dateIntervals={filtersValues as DateRange<Date>}
          onUpdate={(value: DateRange<Date>) => handleChangeDate(value)}
        />
      </>
    );
  }

  return (
    <CoreTag
      label={filtersValues}
      variant="outlined"
      showTooltip={true}
      onDelete={() =>
        setFilters(
          FILTER_PROPS.name,
          filters.name?.filter((name) => name !== filtersValues)
        )
      }
      onClick={handleOpen}
    />
  );
};

export default FilterTag;
