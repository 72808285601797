import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useStores } from "../../../../../../stores/StoresProvider";
import { PlanSuggestionDisplay } from "./PlanSuggestionDisplay";
import { CoreCard } from "../../../../../core/CoreCard";
import CoreButton from "../../../../../core/CoreButton";
import CoreAccordion from "../../../../../core/CoreAccordion";
import { PaymentMethod } from "./PaymentMethod";
import { BillingInformation } from "./BillingInformation";
import { ReviewAndConfirm } from "./ReviewAndConfirm";
import { useNotification } from "../../../../../../context/useNotification";
import BillingHelper from "../../../../../../helper/billingHelper";
import { ContractTerm } from "./ContractTerm";
import {
  BILLING_CARD_TYPES,
  PREDEFINED_PLANS,
} from "../../../../../../types/interfaces/subscription";
import { appRoutes } from "../../../../../../configs/routes";

const useStyles = makeStyles({
  root: {
    padding: "0px !important",
    marginBottom: "15px !important",
  },
  actionContainer: {
    padding: "7px",
    display: "flex",
    flexDirection: "row-reverse",
  },
  stepSummaryContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  accordionDetails: {
    paddingTop: "0px",
  },
  accordionSummary: {
    "&.MuiButtonBase-root": {
      "&.MuiAccordionSummary-root": {
        cursor: "default",
      },
    },
  },
});

interface StepTab {
  key: string;
  description: string;
  content: React.ReactNode;
}

const STEP_KEYS = {
  plan: "plan",
  contractTerm: "contractTerm",
  price: "price",
  billing: "billing",
  review: "review",
};

const STEPS = [
  {
    key: STEP_KEYS.plan,
    description: "subscription_upgrade_plan_step",
    content: <PlanSuggestionDisplay />,
  },
  {
    key: STEP_KEYS.contractTerm,
    description: "subscription_upgrade_contract_term_step",
    content: <ContractTerm />,
  },
  {
    key: STEP_KEYS.price,
    description: "subscription_upgrade_payment_method_step",
    content: <PaymentMethod />,
  },
  {
    key: STEP_KEYS.billing,
    description: "subscription_upgrade_billing_step",
    content: <BillingInformation />,
  },
  {
    key: STEP_KEYS.review,
    description: "subscription_upgrade_review_step",
    content: <ReviewAndConfirm />,
  },
] as StepTab[];

export const UpgradePlanSteps = observer(() => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const notification = useNotification();
  const { subscriptionStore } = useStores();

  const { t } = useTranslation("subscription");
  const { t: stripeT, i18n } = useTranslation("stripe-errors");

  const elements = useElements();
  const stripe = useStripe();

  const [currentStep, setCurrentStep] = useState<string>("plan");

  const validSteps = subscriptionStore.validSteps;

  const changeStep = (nextStepIndex: number, isBack = false) => {
    const nextStepTab = STEPS[nextStepIndex];
    const currentStepTab = STEPS.find((item) => item.key === currentStep);

    if (isBack) {
      let validSteps = {
        [nextStepTab?.key]: false,
      } as { [key: string]: boolean };

      // Clear next steps
      switch (nextStepTab?.key) {
        case STEP_KEYS.plan:
          validSteps = {
            ...validSteps,
            contractTerm: false,
            price: false,
            billing: false,
            review: false,
          };
          break;
        case STEP_KEYS.contractTerm:
          validSteps = {
            ...validSteps,
            billing: false,
            review: false,
          };
          break;
        case STEP_KEYS.price:
          validSteps = {
            ...validSteps,
            billing: false,
            review: false,
          };
          break;
        case STEP_KEYS.billing:
          validSteps = {
            ...validSteps,
            review: false,
          };
          break;
        default:
      }

      subscriptionStore.setValidSteps(validSteps);
      setCurrentStep(nextStepTab.key);
      return;
    }

    // Validate step content
    if (nextStepTab && currentStepTab) {
      const setSetValid = () => {
        subscriptionStore.setValidSteps({
          [currentStepTab?.key]: true,
        });
        setCurrentStep(nextStepTab.key);
      };

      switch (currentStepTab?.key) {
        case STEP_KEYS.contractTerm:
        case STEP_KEYS.plan: {
          const originalPlan = subscriptionStore.activePlan;
          const selectedPlan = subscriptionStore.selectedSubscription;
          const isFreeTrial = selectedPlan?.plan === PREDEFINED_PLANS.free;

          const additionalConditions =
            originalPlan?.plan !== selectedPlan?.plan ||
            originalPlan?.period !== subscriptionStore.contractTerm;

          const isSelectedPlanValid =
            currentStepTab?.key === STEP_KEYS.plan
              ? !isFreeTrial
              : !isFreeTrial && additionalConditions;

          subscriptionStore.setValidSteps({
            [currentStepTab?.key]: isSelectedPlanValid,
          });

          if (isSelectedPlanValid) {
            const nextStepKey =
              subscriptionStore.selectedSubscription?.plan ===
              PREDEFINED_PLANS.free
                ? STEP_KEYS.review
                : nextStepTab.key;
            setCurrentStep(nextStepKey);
          } else {
            notification.warning(
              t(
                isFreeTrial
                  ? "active_subscription_step_plan_empty_warning"
                  : "active_subscription_step_plan_warning"
              )
            );
          }
          break;
        }
        case STEP_KEYS.price: {
          if (
            subscriptionStore.cardType === BILLING_CARD_TYPES.existing &&
            subscriptionStore.activeSubscription?.card
          ) {
            setSetValid();
            return;
          }

          // This is handled by StripeCard
          const isInError = document.getElementById("card-error");
          if (!isInError) {
            setSetValid();
          }
          break;
        }
        case STEP_KEYS.billing: {
          const formErrors = BillingHelper.validateBillingForm(
            subscriptionStore.flatBillingCustomer,
            true
          );
          const companyFormErrors = subscriptionStore.flatBillingCompany?.[
            "name"
          ]
            ? BillingHelper.validateBillingForm(
                subscriptionStore.flatBillingCompany,
                true,
                true
              )
            : null;

          if (formErrors || companyFormErrors) {
            subscriptionStore.setValidSteps({
              [currentStepTab?.key]: false,
            });

            if (formErrors) subscriptionStore.setBillingFormErrors(formErrors);
            if (companyFormErrors)
              subscriptionStore.setCompanyFormErrors(companyFormErrors);
            return;
          } else {
            subscriptionStore.setBillingFormErrors({});
            subscriptionStore.setCompanyFormErrors({});
            setSetValid();
          }

          break;
        }
        default: {
          setSetValid();
        }
      }
    }
  };

  const changeSubscription = (paymentMethodId?: string) => {
    subscriptionStore
      .changeSubscription(paymentMethodId)
      .then(() => {
        notification.success(t("active_subscription_upgrade_success"));
        history.push(appRoutes.AccountBilling());
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "subscription_upgrade_failed"));
      });
  };

  const updateSubscription = () => {
    subscriptionStore
      .upgradeToPlan()
      .then(() => {
        notification.success(t("active_subscription_upgrade_success"));
        history.push(appRoutes.AccountBilling());
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "subscription_upgrade_failed"));
      });
  };

  const handleConfirmChange = () => {
    subscriptionStore.setChangeSubscriptionInProgress(true);

    if (
      subscriptionStore.selectedSubscription?.plan === PREDEFINED_PLANS.free
    ) {
      // Rest of changes are not needed, only subscription update
      updateSubscription();
      return;
    }

    const setupPayload = {
      payment_method: {
        card: elements?.getElement(CardElement) as StripeCardElement,
      },
    };

    if (subscriptionStore.cardType === BILLING_CARD_TYPES.existing) {
      // Update only billing customer and subscription
      changeSubscription();
      return;
    }

    try {
      stripe
        ?.confirmCardSetup(
          subscriptionStore.billingSetupIntent?.clientSecret,
          setupPayload
        )
        .then((response) => {
          // Card created successfully to Stripe
          if (
            response?.setupIntent?.status === "succeeded" &&
            response?.setupIntent?.payment_method
          ) {
            // Set new card as default for customer
            changeSubscription(response?.setupIntent?.payment_method as string);
          } else {
            // Failed with error code
            const errorCode = response?.["error"]?.code || "default_error";
            const message = i18n.exists(`stripe-errors:${errorCode}`)
              ? stripeT(errorCode)
              : stripeT("default_error");

            notification.error(message);
            subscriptionStore.setChangeSubscriptionInProgress(false);
          }
        })
        .catch((error: Error) => {
          subscriptionStore.setChangeSubscriptionInProgress(false);
          notification.error(error?.message);
        });
    } catch {
      subscriptionStore.setChangeSubscriptionInProgress(false);
      notification.error(
        t("active_subscription_update_card_drawer_no_instance")
      );
    }
  };

  useEffect(() => {
    return () => {
      subscriptionStore.setBillingFormErrors({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {STEPS.map((step, index) => (
        <CoreCard key={step.key} className={classes.root}>
          <CoreAccordion
            expanded={currentStep === step.key}
            elevation={0}
            shouldRenderExpandIcon={false}
            accordionBackgroundStyle={false}
            applyBoxAccordionClassName={false}
            accordionDetailsClassName={classes.accordionDetails}
            summaryClassName={classes.accordionSummary}
            summaryChildren={
              <Box className={classes.stepSummaryContainer}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: validSteps[step.key]
                      ? theme.palette.highlight.main
                      : "auto",
                  }}
                >
                  {validSteps[step.key] ? (
                    <CheckIcon
                      fontSize="small"
                      sx={{
                        marginRight: "3px",
                        color: "inherit",
                      }}
                    />
                  ) : (
                    ` ${index + 1}. `
                  )}
                  {t(step.description)}
                </Typography>

                {currentStep !== step.key && !!validSteps[step.key] && (
                  <CoreButton
                    variant="text"
                    sx={{
                      color: theme.palette.highlight.main,
                      fontSize: "12px",
                    }}
                    disabled={subscriptionStore.changeSubscriptionInProgress}
                    onClick={() => changeStep(index, true)}
                  >
                    {t("subscription_upgrade_edit_option")}
                  </CoreButton>
                )}
              </Box>
            }
          >
            {step.content}

            <Divider
              style={{
                marginTop: "10px",
              }}
            />

            <Box className={classes.actionContainer}>
              {currentStep === "review" ? (
                <CoreButton
                  onClick={handleConfirmChange}
                  variant="contained"
                  disabled={subscriptionStore.changeSubscriptionInProgress}
                  isLoading={subscriptionStore.changeSubscriptionInProgress}
                >
                  {t("subscription_upgrade_review_confirm_action")}
                </CoreButton>
              ) : (
                <CoreButton
                  onClick={() => changeStep(index + 1)}
                  variant="contained"
                >
                  {t("subscription_upgrade_continue_action")}
                </CoreButton>
              )}
            </Box>
          </CoreAccordion>
        </CoreCard>
      ))}
    </>
  );
});
