import { makeAutoObservable, runInAction } from "mobx";
import { ApolloClient } from "@apollo/client";
import { GraphQLError } from "graphql";
import {
  MongoAbility,
  AbilityTuple,
  MongoQuery,
  PureAbility,
  ExtractSubjectType,
  SubjectRawRule,
  Subject,
} from "@casl/ability";

import {
  User,
  FormData,
  ConnectionField,
  Connection,
  Namespace,
  InvitationData,
  SelectOptionDef,
  DevSettings,
  ConnectionType,
} from "../types/interfaces";

import {
  MUTATION_CREATE_CONN_RESPONSE,
  MUTATION_DELETE_CONN_RESPONSE,
  MUTATION_UPDATE_CONN_RESPONSE,
  MUTATION_UPDATE_USER_RESPONSE,
  CREATE_CONN,
  TEST_CONN,
  DELETE_CONN,
  UPDATE_CONN,
  UPDATE_USER,
  MUTATION_LOGIN_USER_RESPONSE,
  LOGIN_USER,
  MUTATION_CREATE_USER_RESPONSE,
  CREATE_USER,
  MUTATION_DELETE_USER_RESPONSE,
  DELETE_USER,
  MUTATION_UPDATE_PASSWORD_RESPONSE,
  UPDATE_PASSWORD,
  MUTATION_LOGIN_WITH_GOOGLE_RESPONSE,
  LOGIN_WITH_GOOGLE,
  MUTATION_LOGOUT_USER_RESPONSE,
  LOGOUT_USER,
  MUTATION_SELECT_NAMESPACE_RESPONSE,
  SELECT_NAMESPACE,
  MUTATION_REMOVE_CTX_RESPONSE,
  REMOVE_CTX,
  UPDATE_DEFAULT_NAMESPACE,
  MUTATION_UPDATE_DEFAULT_NAMESPACE_RESPONSE,
  MUTATION_UPDATE_NAMESPACE_IS_ACTIVE_RESPONSE,
  UPDATE_NAMESPACE_IS_ACTIVE,
  ACTIVATING_ACCOUNT,
  MUTATION_ACTIVATING_ACCOUNT_RESPONSE,
  RESET_PASSWORD,
  MUTATION_RESET_PASSWORD_RESPONSE,
  MUTATION_REPORT_EVENT_RESPONSE,
  REPORT_EVENT,
  MUTATION_UPDATE_CONTEXT_SETTINGS,
  UPDATE_CONTEXT_SETTINGS,
} from "./mutations/users";
import {
  MUTATION_GENERATE_API_KEY_RESPONSE,
  GENERATE_APY_KEY,
  DELETE_API_KEY,
  MUTATION_DELETE_API_KEY_RESPONSE,
  MUTATION_ACTIVATE_API_KEY_RESPONSE,
  ACTIVATE_API_KEY,
  MUTATION_DEACTIVATE_API_KEY_RESPONSE,
  DEACTIVATE_API_KEY,
} from "./mutations/apiKey";

import {
  QUERY_GET_ALL_USERS_RESPONSE,
  QUERY_GET_ALL_CONNECTIONS_RESPONSE,
  QUERY_GET_CONNECTION_RESPONSE,
  QUERY_GET_ALL_ENGINE_CONNECTIONS_RESPONSE,
  QUERY_GET_ENGINE_CONNECTION_RESPONSE,
  GET_ALL_CONNECTIONS,
  GET_ALL_ENGINE_CONNECTIONS,
  GET_ALL_USERS,
  GET_CONNECTION,
  GET_ENGINE_CONNECTION,
  QUERY_CHECK_IF_USER_HAS_ACCOUNT_RESPONSE,
  CHECK_IF_USER_HAS_ACCOUNT,
  GET_USER_FROM_CONTEXT,
  QUERY_GET_USER_FROM_CONTEXT_RESPONSE,
  QUERY_GET_NAMESPACES_RESPONSE,
  QUERY_GET_NAMESPACE_FROM_CONTEXT_RESPONSE,
  GET_NAMESPACE_FROM_CONTEXT_RESPONSE,
  GET_NAMESPACES,
  QUERY_CHECK_INVITATION_LINK_EMAIL,
  QUERY_GENERATE_INVITE_LINK_RESPONSE,
  QUERY_GET_INVITATION_LINK_DATA_RESPONSE,
  QUERY_SEND_INVITATION_LINK,
  GENERATE_INVITE_LINK,
  GET_INVITATION_LINK_DATA,
  SEND_INVITATION_LINK,
  CHECK_INVITATION_LINK_EMAIL,
  VERIFY_TOKEN_RECAPTCHA,
  QUERY_VERIFY_TOKEN_RECAPTCHA_RESPONSE,
  QUERY_SEND_RESET_PASSWORD_LINK_RESPONSE,
  SEND_RESET_PASSWORD_LINK,
  QUERY_RESEND_ACTIVATION_LINK_EMAIL,
  RESEND_ACTIVATION_LINK_EMAIL,
  QUERY_GET_CONTEXT_SETTING,
  GET_CONTEXT_SETTING,
} from "./queries/users";
import {
  GET_API_KEYS,
  ApiKey,
  QUERY_GET_API_KEYS_RESPONSE,
} from "./queries/apiKey";

import {
  getAccessTokenPermissions,
  setAccessToken,
  formatPermissions,
} from "../helper/authHelper";
import { RootStore } from "./StoresProvider";
import { EXTENDED_ASSET_TYPES } from "../types/constants";

export class UserStore {
  rootStore: RootStore;
  connections: Connection[] = [];
  connection: Connection | undefined;
  connectionTypes: ConnectionType[] = [];
  connectionConfig: ConnectionField | undefined;
  loadingConnections = true;
  loadingConnection = false;
  loadingConnectionTypes = true;
  loadingConnectionConfig = false;

  currentUserPermissions?: MongoAbility<AbilityTuple, MongoQuery>;
  users: User[] = [];
  user: User | undefined;
  namespace: Namespace | undefined = undefined;
  //Used to persist email and error message when user tries to login with a different provider
  usedEmailDifferentProvider:
    | {
        loginEmail: string | undefined;
        loginError: string | undefined;
      }
    | undefined = undefined;
  usersFilters: string[] = [];
  filterSearchInput = "";
  userInputFilter = "";
  scope = "";
  totalUsers = 0;
  loadingUsers = true;
  loadingUser = false;
  isPasswordModalOpened = false;
  tokenData: InvitationData | undefined = undefined;
  isConnectionDrawerOpened = false;
  isHomeChatGptOpened = false;
  triggerRefetchRepoConnections = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get currentUserId(): string {
    return this.user?.id?.toString() ?? "48";
  }

  setAbilityForUser = (userPermissions: string) => {
    if (!userPermissions) {
      this.currentUserPermissions = new PureAbility([]);
    } else {
      try {
        const formattedPermissions = formatPermissions(
          JSON.parse(userPermissions) as string[]
        );

        this.currentUserPermissions = new PureAbility(
          formattedPermissions as SubjectRawRule<
            string,
            ExtractSubjectType<Subject>,
            MongoQuery
          >[]
        );
      } catch (error) {
        this.currentUserPermissions = new PureAbility([]);
      }
    }
  };
  // MAIN
  setFilters = (filters: string[]) => {
    this.usersFilters = filters;
  };

  setScope = (scope: string) => {
    this.scope = scope;
  };

  setFiltersInput = (input: string) => {
    this.filterSearchInput = input;
  };

  setUserInputFilter = (input: string) => {
    this.userInputFilter = input;
  };

  setNamespace = (newNamespace: Namespace | undefined) => {
    this.namespace = newNamespace;
  };

  triggerRefetchConnections = () => {
    this.triggerRefetchRepoConnections = {};
  };

  setUsedEmailDifferentProvider = (
    emailDifferentProvider:
      | {
          loginEmail: string | undefined;
          loginError: string | undefined;
        }
      | undefined
  ) => {
    this.usedEmailDifferentProvider = emailDifferentProvider;
  };

  togglePasswordModal = (value: boolean) => {
    this.isPasswordModalOpened = value;
  };

  toggleConnectionDrawer = (value: boolean) => {
    this.isConnectionDrawerOpened = value;
  };

  setIsHomeChatGptOpened = (value: boolean) => {
    this.isHomeChatGptOpened = value;
  };

  getContextSetting = async (settingKey: string) => {
    const {
      data: { getContextSetting },
      errors,
    } = await this.rootStore.client.query<QUERY_GET_CONTEXT_SETTING>({
      query: GET_CONTEXT_SETTING,
      variables: {
        setting: settingKey,
      },
    });

    if (!getContextSetting || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getContextSetting;
  };

  resetData = () => {
    this.setNamespace(undefined);
    this.rootStore.documentStore.resetDocument();
    this.rootStore.qaStore.setSessionId(undefined);
  };

  // USERS
  getAllUsers = async (limit?: number, offset?: number, orderBy?: string[]) => {
    this.loadingUsers = true;
    const fields = this.filterSearchInput === "" ? [] : this.usersFilters;

    const filter = this.filterSearchInput;

    try {
      const {
        data: { getUsers },
        errors,
      } = await this.rootStore.client.query<QUERY_GET_ALL_USERS_RESPONSE>({
        query: GET_ALL_USERS,
        variables: {
          limit,
          offset,
          filter,
          fieldsToSearch: fields,
          orderBy,
          scope: this.scope,
        },
      });

      if (!getUsers || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.users = getUsers.rows.length > 0 ? getUsers.rows : [];
        this.totalUsers = getUsers.count;
        this.loadingUsers = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingUsers = false;
      });
      throw error;
    }
  };

  generateInvitationLink = async (
    client: ApolloClient<unknown>,
    fields: FormData
  ) => {
    const result = await client.query<QUERY_GENERATE_INVITE_LINK_RESPONSE>({
      query: GENERATE_INVITE_LINK,
      variables: { tokenPayload: fields },
    });
    const {
      data: { getInvitationLink },
      errors,
    } = result as unknown as {
      data: { getInvitationLink: string };
      errors: GraphQLError[];
    };
    if (!getInvitationLink || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getInvitationLink;
  };

  getInvitationLinkData = async (
    client: ApolloClient<unknown>,
    token: string
  ) => {
    const result = await client.query<QUERY_GET_INVITATION_LINK_DATA_RESPONSE>({
      query: GET_INVITATION_LINK_DATA,
      variables: { token },
    });

    const {
      data: { getInvitationLinkData },
      errors,
    } = result as unknown as {
      data: {
        getInvitationLinkData: {
          data: InvitationData | undefined;
          redirect: string;
          successNotification: string;
        };
      };
      errors: GraphQLError[];
    };

    if (!getInvitationLinkData || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.tokenData = getInvitationLinkData.data;
    });

    return getInvitationLinkData;
  };

  sendInvitationLink = async (
    client: ApolloClient<unknown>,
    fields: FormData
  ) => {
    const {
      data: { sendInvitationLink },
      errors,
    } = await client.query<QUERY_SEND_INVITATION_LINK>({
      query: SEND_INVITATION_LINK,
      variables: fields,
    });

    if (!sendInvitationLink || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return sendInvitationLink;
  };

  checkInvitationLinkEmail = async (
    client: ApolloClient<unknown>,
    email: string
  ) => {
    const {
      data: { checkInvitationLinkEmail },
      errors,
    } = await client.query<QUERY_CHECK_INVITATION_LINK_EMAIL>({
      query: CHECK_INVITATION_LINK_EMAIL,
      variables: { email },
    });

    if (!checkInvitationLinkEmail || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return checkInvitationLinkEmail;
  };

  resendActivationLink = async (
    client: ApolloClient<unknown>,
    email: string
  ) => {
    const {
      data: { resendActivationLink },
      errors,
    } = await client.query<QUERY_RESEND_ACTIVATION_LINK_EMAIL>({
      query: RESEND_ACTIVATION_LINK_EMAIL,
      variables: { email },
    });

    if (!resendActivationLink || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return resendActivationLink;
  };

  getUserFromContext = async (
    client: ApolloClient<unknown>,
    disableLoadingSet = false
  ) => {
    try {
      const {
        data: { getUserFromContext },
        errors,
      } = await client.query<QUERY_GET_USER_FROM_CONTEXT_RESPONSE>({
        query: GET_USER_FROM_CONTEXT,
      });

      if (!getUserFromContext || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.user = getUserFromContext;
        if (!disableLoadingSet) {
          this.loadingUser = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.loadingUser = false;
      });
      throw error;
    }
  };

  getNamespaces = async (
    client: ApolloClient<unknown>,
    limit?: number,
    offset?: number,
    orderBy?: string[]
  ) => {
    const {
      data: { getNamespaces },
      errors,
    } = await client.query<QUERY_GET_NAMESPACES_RESPONSE>({
      query: GET_NAMESPACES,
      variables: {
        limit,
        offset,
        orderBy,
      },
    });
    if (!getNamespaces || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getNamespaces;
  };

  selectNamespace = async (
    client: ApolloClient<unknown>,
    namespaceId: number
  ) => {
    const response = await client.mutate<MUTATION_SELECT_NAMESPACE_RESPONSE>({
      mutation: SELECT_NAMESPACE,
      variables: {
        namespaceId,
      },
    });

    const {
      data: { selectNamespace },
      errors,
    } = response as {
      data: { selectNamespace: Record<string, string> };
      errors: GraphQLError[];
    };

    if (!selectNamespace || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    this.rootStore.mainStore.setAuthState(new Date());
    const { newAccessToken, devSettings } = selectNamespace;
    setAccessToken(newAccessToken);
    this.rootStore.chatGptStore.setDevSettings(
      JSON.parse(devSettings) as unknown as DevSettings
    );
    this.setAbilityForUser(getAccessTokenPermissions(newAccessToken));
  };

  removeCTX = async (client: ApolloClient<unknown>) => {
    const response = await client.mutate<MUTATION_REMOVE_CTX_RESPONSE>({
      mutation: REMOVE_CTX,
    });
    const {
      data: { removeCTX },
      errors,
    } = response as {
      data: { removeCTX: string };
      errors: GraphQLError[];
    };

    if (!removeCTX || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    this.rootStore.mainStore.setAuthState(new Date());
    setAccessToken(removeCTX);
    this.setAbilityForUser(getAccessTokenPermissions(removeCTX));
  };

  getNamespaceFromContext = async (
    client: ApolloClient<unknown>,
    disableLoadingSet = false
  ) => {
    if (!disableLoadingSet) {
      this.loadingUser = true;
    }
    const {
      data: { getNamespaceFromContext },
      errors,
    } = await client.query<QUERY_GET_NAMESPACE_FROM_CONTEXT_RESPONSE>({
      query: GET_NAMESPACE_FROM_CONTEXT_RESPONSE,
    });

    if (!getNamespaceFromContext || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    const user = {
      ...(this.user || getNamespaceFromContext.user),
      scope: getNamespaceFromContext.scope,
    };

    runInAction(() => {
      this.namespace = getNamespaceFromContext.namespace;
      this.user = user;
      this.loadingUser = false;
    });
    return getNamespaceFromContext;
  };

  logoutUser = async (client: ApolloClient<unknown>) => {
    const response = await client.mutate<MUTATION_LOGOUT_USER_RESPONSE>({
      mutation: LOGOUT_USER,
    });

    const {
      data: { logoutUser },
      errors,
    } = response as {
      data: { logoutUser: boolean };
      errors: GraphQLError[];
    };

    if (!logoutUser || (errors && errors?.[0])) {
      throw new Error();
    }

    runInAction(() => {
      this.user = undefined;
      this.rootStore.mainStore.setAuthState(new Date());
      this.setAbilityForUser(getAccessTokenPermissions(""));
      setAccessToken("");
    });

    return true;
  };

  deleteUser = async (client: ApolloClient<unknown>, id: number | null) => {
    const response = await client.mutate<MUTATION_DELETE_USER_RESPONSE>({
      mutation: DELETE_USER,
      variables: { id },
    });
    const {
      data: { deleteUser },
      errors,
    } = response as {
      data: { deleteUser: boolean };
      errors: GraphQLError[];
    };
    if (!deleteUser || (errors && errors?.[0])) {
      throw new Error();
    }
    return true;
  };

  updateUser = async (client: ApolloClient<unknown>, userData: User) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { __typename, ...rest } = userData;
    const response = await client.mutate<MUTATION_UPDATE_USER_RESPONSE>({
      mutation: UPDATE_USER,
      variables: { userData: rest, id: rest.id },
    });

    const {
      data: { updateUser },
      errors,
    } = response as unknown as {
      data: { updateUser: boolean };
      errors: GraphQLError[];
    };

    if (!updateUser || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.users = this.users.map((user) =>
        user.id === userData.id
          ? {
              ...rest,
              is_active: Boolean(userData.is_active),
            }
          : user
      );
    });
  };

  updateNamespaceIsActive = async (userId: number, isActive: boolean) => {
    const response =
      await this.rootStore.client.mutate<MUTATION_UPDATE_NAMESPACE_IS_ACTIVE_RESPONSE>(
        {
          mutation: UPDATE_NAMESPACE_IS_ACTIVE,
          variables: { userId, isActive },
        }
      );
    const {
      data: { updateNamespaceIsActive },
      errors,
    } = response as unknown as {
      data: { updateNamespaceIsActive: boolean };
      errors: GraphQLError[];
    };

    if (!updateNamespaceIsActive || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
  };

  updatePassword = async (
    client: ApolloClient<unknown>,
    password: string,
    id: number
  ) => {
    const response = await client.mutate<MUTATION_UPDATE_PASSWORD_RESPONSE>({
      mutation: UPDATE_PASSWORD,
      variables: { password, id },
    });
    const {
      data: { updatePassword },
      errors,
    } = response as unknown as {
      data: { updatePassword: boolean };
      errors: GraphQLError[];
    };

    if (!updatePassword || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
  };

  // AUTHENTICATION

  loginUser = async (
    client: ApolloClient<unknown>,
    email: string,
    password: string
  ) => {
    const response = await client.mutate<MUTATION_LOGIN_USER_RESPONSE>({
      mutation: LOGIN_USER,
      variables: { email, password },
    });

    const {
      data: { loginUser },
      errors,
    } = response as {
      data: { loginUser: Record<string, string> };
      errors: GraphQLError[];
    };

    if (!loginUser || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    const { accessToken, devSettings } = loginUser;

    this.rootStore.mainStore.setAuthState(new Date());
    setAccessToken(accessToken);
    this.rootStore.chatGptStore.setDevSettings(
      JSON.parse(devSettings) as unknown as DevSettings
    );
    this.setAbilityForUser(getAccessTokenPermissions(accessToken));
  };

  createUser = async (client: ApolloClient<unknown>, fields: FormData) => {
    const { email, firstName, lastName, password } = fields;
    const response = await client.mutate<MUTATION_CREATE_USER_RESPONSE>({
      mutation: CREATE_USER,
      variables: {
        email,
        firstName,
        lastName,
        password,
        token: this.tokenData?.token,
      },
    });

    const {
      data: { createUser },
      errors,
    } = response as unknown as {
      data: { createUser: { created: boolean; fromLink: boolean } };
      errors: GraphQLError[];
    };
    if (!createUser || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return createUser;
  };

  loginWithGoogle = async (client: ApolloClient<unknown>, code: string) => {
    const response = await client.mutate<MUTATION_LOGIN_WITH_GOOGLE_RESPONSE>({
      mutation: LOGIN_WITH_GOOGLE,
      variables: {
        code,
        token: this.tokenData?.token,
      },
    });
    const {
      data: { loginWithGoogle },
      errors,
    } = response as unknown as {
      data: {
        loginWithGoogle: {
          newAccessToken?: string;
          newUser?: User;
          loginError?: string;
          loginEmail?: string;
          devSettings?: string;
        };
      };
      errors: GraphQLError[];
    };

    const { newAccessToken, newUser, loginError, loginEmail, devSettings } =
      loginWithGoogle || {};

    if ((errors && errors?.[0]) || (!newAccessToken && !newUser)) {
      this.usedEmailDifferentProvider = { loginEmail, loginError };

      if (!loginError || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
    }

    if (newAccessToken) {
      setAccessToken(newAccessToken);
      if (devSettings)
        this.rootStore.chatGptStore.setDevSettings(
          JSON.parse(devSettings) as unknown as DevSettings
        );
      this.setAbilityForUser(getAccessTokenPermissions(newAccessToken));
    }

    this.rootStore.mainStore.setAuthState(new Date());

    if (newUser) {
      const lastName = newUser.last_name ?? "";
      runInAction(() => {
        this.rootStore.landingStore.setUserRegistration({
          ...newUser,
          firstName: newUser.first_name,
          lastName: lastName,
          isGoogleLogin: true,
        });
      });
    }

    return { newUser: !!newUser };
  };

  checkIfEmailIsUsed = async (client: ApolloClient<unknown>, email: string) => {
    const response =
      await client.query<QUERY_CHECK_IF_USER_HAS_ACCOUNT_RESPONSE>({
        query: CHECK_IF_USER_HAS_ACCOUNT,
        variables: { email },
      });

    const {
      data: { checkIfEmailIsUsed },
      errors,
    } = response as unknown as {
      data: { checkIfEmailIsUsed: boolean };
      errors: GraphQLError[];
    };

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return checkIfEmailIsUsed;
  };

  forgotPassword = async (client: ApolloClient<unknown>, email: string) => {
    const response =
      await client.query<QUERY_SEND_RESET_PASSWORD_LINK_RESPONSE>({
        query: SEND_RESET_PASSWORD_LINK,
        variables: { email },
      });

    const {
      data: { sendResetPasswordLink },
      errors,
    } = response as unknown as {
      data: { sendResetPasswordLink: boolean };
      errors: GraphQLError[];
    };

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return sendResetPasswordLink;
  };

  resetPassword = async (
    client: ApolloClient<unknown>,
    password: string,
    resetToken: string
  ) => {
    const response = await client.mutate<MUTATION_RESET_PASSWORD_RESPONSE>({
      mutation: RESET_PASSWORD,
      variables: { password, resetToken },
    });

    const {
      data: { resetPassword },
      errors,
    } = response as unknown as {
      data: { resetPassword: boolean };
      errors: GraphQLError[];
    };

    if (!resetPassword || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return resetPassword;
  };

  activatingAccount = async (client: ApolloClient<unknown>, token: string) => {
    const response = await client.mutate<MUTATION_ACTIVATING_ACCOUNT_RESPONSE>({
      mutation: ACTIVATING_ACCOUNT,
      variables: { token },
    });
    const {
      data: { activatingAccount },
      errors,
    } = response as unknown as {
      data: { activatingAccount: boolean };
      errors: GraphQLError[];
    };
    if (!activatingAccount || (errors && errors?.[0])) {
      throw new Error();
    }

    return activatingAccount;
  };

  updateDefaultNamespace = async (
    client: ApolloClient<unknown>,
    namespaceId: number
  ) => {
    const response =
      await client.mutate<MUTATION_UPDATE_DEFAULT_NAMESPACE_RESPONSE>({
        mutation: UPDATE_DEFAULT_NAMESPACE,
        variables: { namespaceId },
      });
    const {
      data: { updateDefaultNamespace },
      errors,
    } = response as unknown as {
      data: { updateDefaultNamespace: boolean };
      errors: GraphQLError[];
    };

    if (!updateDefaultNamespace || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
  };

  verifyTokenRecaptcha = async (
    client: ApolloClient<unknown>,
    token: string
  ) => {
    const response = await client.query<QUERY_VERIFY_TOKEN_RECAPTCHA_RESPONSE>({
      query: VERIFY_TOKEN_RECAPTCHA,
      variables: { token },
    });

    const {
      data: { verifyTokenRecaptcha },
      errors,
    } = response as unknown as {
      data: { verifyTokenRecaptcha: boolean };
      errors: GraphQLError[];
    };

    if (!verifyTokenRecaptcha || (errors && errors?.[0])) {
      throw new Error();
    }

    return verifyTokenRecaptcha;
  };

  // CONNECTIONS
  loadAllConnections = async (onlyStorage?: boolean) => {
    if (!this.loadingConnections) {
      this.loadingConnections = true;
    }
    try {
      const {
        data: { getConnections },
        errors,
      } = await this.rootStore.client.query<QUERY_GET_ALL_CONNECTIONS_RESPONSE>(
        {
          query: GET_ALL_CONNECTIONS,
          variables: { onlyStorage },
        }
      );

      if (!getConnections || (errors && errors?.[0])) {
        throw new Error();
      }
      // if onlyStorage is true, return the connections to use in components
      if (onlyStorage) {
        return getConnections.connections;
      }

      runInAction(() => {
        this.connections = getConnections.connections;
        this.loadingConnections = false;
      });
      return [];
    } catch (error) {
      runInAction(() => {
        this.loadingConnections = false;
      });
      throw error;
    }
  };

  getConnection = async (client: ApolloClient<unknown>, id: string) => {
    try {
      this.loadingConnection = true;
      const {
        data: { getConnection },
        errors,
      } = await client.query<QUERY_GET_CONNECTION_RESPONSE>({
        query: GET_CONNECTION,
        variables: { id },
      });

      if (!getConnection || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.connection = getConnection?.connection;
        this.loadingConnection = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingConnection = false;
      });
      throw error;
    }
  };

  deleteConnection = async (client: ApolloClient<unknown>, id: string) => {
    const response = await client.mutate<MUTATION_DELETE_CONN_RESPONSE>({
      mutation: DELETE_CONN,
      variables: { id },
    });

    const {
      data: { deleteConnection },
      errors,
    } = response as {
      data: { deleteConnection: boolean };
      errors: GraphQLError[];
    };

    if (!deleteConnection || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      void this.loadAllConnections();
    });
  };

  createConnection = async (
    client: ApolloClient<unknown>,
    config: Connection
  ) => {
    try {
      this.loadingConnection = true;
      const response = await client.mutate<MUTATION_CREATE_CONN_RESPONSE>({
        mutation: CREATE_CONN,
        variables: { config },
      });

      const {
        data: { createConnection },
        errors,
      } = response as {
        data: { createConnection: Record<string, string> };
        errors: GraphQLError[];
      };

      if (!createConnection || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingConnection = false;
        void this.getConnectionTypes(client);
      });

      return createConnection.identifier;
    } catch (err) {
      runInAction(() => {
        this.loadingConnection = false;
      });
      throw err;
    }
  };

  testConnection = async (
    client: ApolloClient<unknown>,
    config: Connection
  ) => {
    const response = await client.mutate<{ testConnection: boolean }>({
      mutation: TEST_CONN,
      variables: { config },
    });

    const {
      data: { testConnection },
      errors,
    } = response as {
      data: { testConnection: boolean };
      errors: GraphQLError[];
    };

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return testConnection;
  };

  updateContextSettings = async (
    settingsKey: string,
    config: Record<string, unknown>
  ) => {
    const response =
      await this.rootStore.client.mutate<MUTATION_UPDATE_CONTEXT_SETTINGS>({
        mutation: UPDATE_CONTEXT_SETTINGS,
        variables: { settingsKey, config },
      });

    const {
      data: { updateContextSettings },
      errors,
    } = response as {
      data: { updateContextSettings: boolean };
      errors: GraphQLError[];
    };

    if (!updateContextSettings || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return updateContextSettings;
  };

  updateConnection = async (
    client: ApolloClient<unknown>,
    id: string,
    config: Connection
  ) => {
    const response = await client.mutate<MUTATION_UPDATE_CONN_RESPONSE>({
      mutation: UPDATE_CONN,
      variables: { id, config },
    });

    const {
      data: { updateConnection },
      errors,
    } = response as {
      data: { updateConnection: boolean };
      errors: GraphQLError[];
    };

    if (!updateConnection || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      void this.getConnectionTypes(client);
    });
  };

  // ENGINE CONNECTIONS
  getConnectionTypes = async (client: ApolloClient<unknown>) => {
    try {
      this.loadingConnectionTypes = true;
      const {
        data: { getConnectionTypes },
        errors,
      } = await client.query<QUERY_GET_ALL_ENGINE_CONNECTIONS_RESPONSE>({
        query: GET_ALL_ENGINE_CONNECTIONS,
      });

      if (!getConnectionTypes || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.connectionTypes = getConnectionTypes.conn_types || [];
        this.loadingConnectionTypes = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingConnectionTypes = false;
      });
      throw error;
    }
  };

  getConnectionConfig = async (client: ApolloClient<unknown>, id: string) => {
    try {
      this.loadingConnectionConfig = true;
      const {
        data: { getConnectionConfig },
        errors,
      } = await client.query<QUERY_GET_ENGINE_CONNECTION_RESPONSE>({
        query: GET_ENGINE_CONNECTION,
        variables: { id },
      });

      if (!getConnectionConfig || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.connectionConfig = getConnectionConfig?.config;
        this.loadingConnectionConfig = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingConnectionConfig = false;
      });
      throw error;
    }
  };

  // API KEYS
  loadApiKeys = async (): Promise<ApiKey[]> => {
    const {
      data: { listKeys },
      errors,
    } = await this.rootStore.client.query<QUERY_GET_API_KEYS_RESPONSE>({
      query: GET_API_KEYS,
    });

    if (errors) {
      throw new Error();
    }

    return listKeys;
  };

  getIntegrationsCode = async (): Promise<SelectOptionDef[]> => {
    try {
      const code = await this.rootStore.flowStore.getAssetConfiguration(
        EXTENDED_ASSET_TYPES.apiSamples
      );

      return code?.getAssetConfig?.parameters?.[0]?.options || [];
    } catch {
      return [] as unknown as SelectOptionDef[];
    }
  };

  generateApiKey = async (active?: boolean): Promise<string> => {
    const response =
      await this.rootStore.client.mutate<MUTATION_GENERATE_API_KEY_RESPONSE>({
        mutation: GENERATE_APY_KEY,
        variables: {
          active,
        },
      });

    const {
      data: { generateApiKey },
      errors,
    } = response as {
      data: { generateApiKey: string };
      errors: GraphQLError[];
    };

    if (errors || !generateApiKey) {
      throw new Error();
    }

    return generateApiKey;
  };

  deleteApiKey = async (identifier: string): Promise<string> => {
    const response =
      await this.rootStore.client.mutate<MUTATION_DELETE_API_KEY_RESPONSE>({
        mutation: DELETE_API_KEY,
        variables: {
          identifier,
        },
      });

    const {
      data: { deleteApiKey },
      errors,
    } = response as {
      data: { deleteApiKey: string };
      errors: GraphQLError[];
    };

    if (errors) {
      throw new Error();
    }

    return deleteApiKey;
  };

  activateApiKey = async (identifier: string): Promise<string> => {
    const response =
      await this.rootStore.client.mutate<MUTATION_ACTIVATE_API_KEY_RESPONSE>({
        mutation: ACTIVATE_API_KEY,
        variables: {
          identifier,
        },
      });

    const {
      data: { activateApiKey },
      errors,
    } = response as {
      data: { activateApiKey: string };
      errors: GraphQLError[];
    };

    if (errors) {
      throw new Error();
    }

    return activateApiKey;
  };

  deactivateApiKey = async (identifier: string): Promise<string> => {
    const response =
      await this.rootStore.client.mutate<MUTATION_DEACTIVATE_API_KEY_RESPONSE>({
        mutation: DEACTIVATE_API_KEY,
        variables: {
          identifier,
        },
      });

    const {
      data: { deactivateApiKey },
      errors,
    } = response as {
      data: { deactivateApiKey: string };
      errors: GraphQLError[];
    };

    if (errors) {
      throw new Error();
    }

    return deactivateApiKey;
  };

  reportEvent = async (client: ApolloClient<unknown>, message: string) => {
    const response = await client.mutate<MUTATION_REPORT_EVENT_RESPONSE>({
      mutation: REPORT_EVENT,
      variables: {
        message,
      },
    });

    const {
      data: { reportEvent },
      errors,
    } = response as {
      data: { reportEvent: boolean };
      errors: GraphQLError[];
    };

    if (!reportEvent || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return reportEvent;
  };
}
