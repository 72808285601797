import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useStores } from "../../../../../../../stores/StoresProvider";
import CoreButton from "../../../../../../core/CoreButton";
import { CoreCard } from "../../../../../../core/CoreCard";
import { CoreStatusTag } from "../../../../../../core/CoreStatusTag";
import { DocumentStages } from "../../../../../../../types/enums";
import { SubscriptionPlanDrawer } from "../SubscriptionPlanDrawer";
import BillingHelper from "../../../../../../../helper/billingHelper";
import { PREDEFINED_PLANS } from "../../../../../../../types/interfaces/subscription";

const useStyles = makeStyles({
  description: {
    opacity: 0.6,
    fontSize: "12px",
    marginTop: "10px",
  },
  actionsContainer: {
    margin: "auto",
  },
  planHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export const CurrentPackageCard = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation("subscription");
  const { subscriptionStore, userStore } = useStores();

  const [isModalOpened, toggleModal] = useState(false);

  const currentPlanData = subscriptionStore.activePlan;
  const activeSubscription = subscriptionStore.activeSubscription;
  const expireDays = BillingHelper.getActiveSubExpireDays(activeSubscription);

  const isFreeSubscription = currentPlanData?.plan === PREDEFINED_PLANS.free;

  if (!activeSubscription || !currentPlanData) {
    return <></>;
  }

  return (
    <>
      <CoreCard>
        <Grid container>
          <Grid item flex="1">
            <Box className={classes.planHeader}>
              <Typography>{currentPlanData?.name || "-"}</Typography>

              {!isFreeSubscription && (
                <CoreStatusTag
                  styleProps={{ marginLeft: "10px" }}
                  label={
                    expireDays === null || typeof expireDays === "undefined"
                      ? t("active_subscription_expired")
                      : t("active_subscription_expire_dates", { expireDays })
                  }
                  type={DocumentStages.REQUIRES_USER_INPUT}
                />
              )}
            </Box>

            <Typography className={classes.description}>
              {currentPlanData?.description || "-"}
            </Typography>
          </Grid>
          {userStore.currentUserPermissions?.can("update", "subscription") && (
            <Grid item className={classes.actionsContainer}>
              <CoreButton variant="outlined" onClick={() => toggleModal(true)}>
                {t("active_subscription_learn_more")}
              </CoreButton>
            </Grid>
          )}
        </Grid>
      </CoreCard>

      <SubscriptionPlanDrawer
        isActive
        isOpen={isModalOpened}
        onClose={() => toggleModal(false)}
        subscriptionPlan={currentPlanData}
      />
    </>
  );
});
