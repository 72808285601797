import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiInputLabel = (
  theme: ThemeOptions
): Components["MuiInputLabel"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        opacity: 0.4,
        "&.MuiInputLabel-root": {
          color: palette.text.primary,
        },
      },
    },
  };
};
