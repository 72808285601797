import React, { FC } from "react";
import { observer } from "mobx-react";

import { createStyles, makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { DocumentStore } from "../../../../stores/DocumentStore";
import { appRoutes } from "../../../../configs/routes";
import CoreTextLink from "../../../core/CoreTextLink";

interface Props {
  id: string;
  name: string;
  documentStore: DocumentStore;
  flowId: string;
  onClick?: () => void;
}

export const NameView: FC<Props> = observer(
  ({ id, name, documentStore, flowId, onClick }) => {
    const useStyles = makeStyles(() =>
      createStyles({
        cellContainer: {
          display: "flex",
          alignItems: "center",
        },
      })
    );
    const classes = useStyles();

    const togglePreview = (previewValue: string | null) => {
      documentStore.setPreview(previewValue);
    };

    return (
      <Box
        className={classes.cellContainer}
        onMouseEnter={() => togglePreview(id)}
        onMouseLeave={() => togglePreview(null)}
      >
        <CoreTextLink
          onClick={onClick}
          to={appRoutes.DocumentView(flowId, id)}
          label={name}
        />
      </Box>
    );
  }
);
