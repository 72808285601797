import React, { FC, useMemo } from "react";

import { TFunction } from "i18next";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChatGptHelper from "../../../../helper/chatGptHelper";
import { ChatGptMessageFilters } from "../../../../types/interfaces";

export const ChatTextRenderer: FC<{
  t: TFunction;
  text: string;
  filters?: ChatGptMessageFilters;
  onFilterApply?: () => void;
}> = ({ t, filters, text, onFilterApply }) => {
  const message = useMemo(() => ChatGptHelper.preProcessText(text), [text]);
  const theme = useTheme();

  const classes = makeStyles({
    link: {
      cursor: "pointer",
      color: theme.palette.highlight.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    message: {
      fontSize: "14px",
      textWrap: "wrap",
    },
  })();

  const handleLinkClick = () => {
    if (onFilterApply) {
      onFilterApply();
    }
  };

  // When no link provided, return simple box
  if (typeof text === "string" && !text?.includes("${link}")) {
    return <Box className={classes.message}>{message}</Box>;
  }

  return (
    <Box
      style={{
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {typeof text === "string"
        ? text?.split(" ").map((chunk, index) =>
            chunk === "${link}" ? (
              <span
                key={index}
                className={classes.link}
                onClick={handleLinkClick}
              >
                {!filters ||
                (typeof filters === "object" &&
                  Object.keys(filters).length === 0)
                  ? t("nothingFound")
                  : t("clickHere")}
              </span>
            ) : (
              <span key={index} style={{ marginRight: "4px" }}>
                {chunk}
              </span>
            )
          )
        : text}
    </Box>
  );
};
