import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { FlowDocuments } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";
import CoreTooltip from "../../../core/CoreTooltip";
import CoreCheckbox from "../../../core/CoreCheckbox";

interface BoxSelectProps {
  t: TFunction;
  documents: FlowDocuments;
  handleMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  filterClass: string;
}

export const DocumentBoxSelect: FC<BoxSelectProps> = observer(
  ({ documents, t, handleMenu, filterClass }) => {
    const { documentStore } = useStores();

    const isChecked = useMemo(
      () =>
        !!documents.documents.length &&
        documents.documents.every((doc) =>
          documentStore.selectedDocuments.some(
            (selectedDoc) => selectedDoc.identifier === doc.identifier
          )
        ),
      [documentStore.selectedDocuments, documents.documents]
    );

    const isIndeterminate = useMemo(
      () =>
        (documents.documents.some((doc) =>
          documentStore.selectedDocuments.some(
            (selectedDoc) => selectedDoc.identifier === doc.identifier
          )
        ) ||
          documentStore.selectedDocuments.length !== 0) &&
        !isChecked,
      [documentStore.selectedDocuments, documents.documents, isChecked]
    );

    return (
      <Box sx={{ display: "flex" }}>
        <CoreCheckbox
          sx={{ marginLeft: "10px" }}
          indeterminate={isIndeterminate}
          checked={isChecked}
          onClick={() => documentStore.checkAllCurrentFlowDocuments(isChecked)}
        />
        <CoreTooltip title={t("column_filter")}>
          <IconButton className={filterClass} onClick={handleMenu}>
            <FilterAltIcon />
          </IconButton>
        </CoreTooltip>
      </Box>
    );
  }
);
