import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { AppSuspense } from "../../main/AppSuspense";
import { useStores } from "../../../stores/StoresProvider";
import { EditPassword } from "../team/EditPassword";
import CorePageContainer from "../../core/CorePageContainer";
import AccountCard from "./AccountCard";
import CoreConfirmModal from "../../core/CoreConfirmModal";
import { USER_SCOPES } from "../../../types/constants";
import { useNotification } from "../../../context/useNotification";

const useStyle = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    padding: "25px 10px 10px 30px",
    "@media screen and (max-width:768px)": {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
});

const UserAccount: React.FC = observer(() => {
  const { ready, t } = useTranslation("userAccount");
  const { userStore } = useStores();
  const client = useApolloClient();
  const notification = useNotification();
  const [onDeleteAccount, setOnDeleteAccount] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyle();

  const isGoogleAccount = useMemo(() => {
    return userStore.user?.oauth_id;
  }, [userStore.user?.oauth_id]);

  const cards = [
    {
      t: t,
      title: "accountDetails",
      hasFields: true,
    },
    !isGoogleAccount
      ? {
          t: t,
          hasDivider: true,
          isChangePassword: true,
          openDrawer: setIsDrawerOpen,
          title: "security",
          cardTitle: "changePasswordTitle",
          cardInfo: "infoPassword",
          buttonTitle: "changePasswordButton",
        }
      : {},
    {
      t: t,
      hasDivider: true,
      isDeleteAccount: true,
      titleDividerOn: isGoogleAccount ? true : false,
      title: isGoogleAccount ? "security" : undefined,
      handleDeleteModal: setOnDeleteAccount,
      buttonTitle: "deleteAccountButton",
      cardTitle: "deleteAccountTitle",
      warning:
        userStore.user?.scope === USER_SCOPES.owner
          ? "deleteWarningOwner"
          : "deleteWarningUser",
      cardInfo: "deleteInfo",
    },
  ];

  const handleDelete = () => {
    setIsLoading(true);
    const userToDeleteId = userStore.user?.id ?? null;
    userStore
      .deleteUser(client, userToDeleteId)
      .then(() => {
        notification.success(t("deleteUserSuccess"));
        void userStore.logoutUser(client).catch((error: Error) => {
          notification.error(t(error?.message || "logoutError"));
        });
      })
      .catch(() => {
        notification.error(t("deleteUserError"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <>
      <CorePageContainer label={t("personalAccount")} isNotFlowPage={true}>
        <Box className={classes.mainContainer}>
          <Box className={classes.cardBox}>
            {cards.map((cardProps, index) =>
              cardProps.isChangePassword
                ? !isGoogleAccount && <AccountCard key={index} {...cardProps} />
                : (cardProps.title || cardProps.cardTitle) && (
                    <AccountCard key={index} {...cardProps} />
                  )
            )}
          </Box>
        </Box>
      </CorePageContainer>

      <EditPassword
        t={t}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        userId={userStore.user?.id ?? null}
      />

      <CoreConfirmModal
        open={onDeleteAccount}
        onClose={() => setOnDeleteAccount(false)}
        title={t("deleteAccountModalTitle")}
        subtitle={t("deleteAccountModalSubtitle")}
        cancelButtonLabel={t("cancelButton")}
        confirmButtonLabel={t("confirmButton")}
        onCancel={() => setOnDeleteAccount(false)}
        onConfirm={handleDelete}
        isDisable={isLoading}
      />
    </>
  );
});

export default UserAccount;
