import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Divider, useTheme } from "@mui/material";

import { FormData, FormField } from "../../../types/interfaces";
import { FIELD_TYPE } from "../../../types/constants";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { AppSuspense } from "../../main/AppSuspense";
import CoreButton from "../../core/CoreButton";
import { useStores } from "../../../stores/StoresProvider";
import { emailRegex, passwordRegex } from "../../../types/regex";
import { authRoutes } from "../../../configs/routes";
import { useNotification } from "../../../context/useNotification";
import Logo from "../../../icons/Logo";
import Languages from "../../main/sidebar/account/Languages";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "@mui/icons-material/Google";

interface Errors {
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
}

const Register: FC = observer(() => {
  const { userStore } = useStores();
  const client = useApolloClient();

  const [fields, setFields] = useState<FormData>({
    email: userStore.tokenData?.email || "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [registerWithGoogleError, setRegisterWithGoogleError] = useState("");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogleRegister, setIsLoadingGoogleRegister] = useState(false);
  //Used to show content if user is created from an invitation link
  const [showContent, setShowContent] = useState(false);

  const { t, ready } = useTranslation("register");
  const theme = useTheme();
  const notification = useNotification();
  const history = useHistory();
  const showConfirm = createSuccess;

  const useStyles = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    pageContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
    },
    registerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "300px",
      overflow: "auto",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      cursor: "pointer",
    },
    divider: { margin: "20px 0px" },
    groupButtons: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    button: {
      textTransform: "none",
      height: "40px",
      margin: "10px 0px",
      display: "flex",
      alignItems: "center",
    },
    confirmBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "80%",
      gap: "15px",
      margin: "auto",
      background: "transparent",
      maxWidth: "800px",
      maxHeight: "800px",
      minWidth: "300px",
      minHeight: "450px",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      width: "50%",
      margin: "0 auto",
    },
    text: {
      textAlign: "center",
      fontSize: "20px",
    },
    verificationButtons: {
      width: "200px",
      margin: "0 auto",
    },
    googleIcon: { fontSize: "25px", marginRight: "5px" },
    errorText: {
      color: theme.palette.error.main,
    },
    error: {
      justifyContent: "center",
      display: "flex",
    },
  });

  const classes = useStyles();

  const handleInput = (value: FormData, changedKey: string) => {
    setFields({ ...fields, ...value });
    setErrors({
      ...errors,
      [changedKey as keyof Errors]: "",
    });
  };

  const validateEmail = async (email: string) => {
    if (!email || email.trim() === "") {
      return t("email_error");
    }
    if (!emailRegex.test(email?.trim())) {
      return t("email_invalidForm");
    }

    const isEmailUsed = await userStore
      .checkIfEmailIsUsed(client, email?.trim())
      .then((res) => res);

    if (isEmailUsed) {
      return t("email_alreadyUsed");
    }
    return "";
  };

  const handleGoogleRegister = useGoogleLogin({
    onSuccess: (response) => {
      setIsLoadingGoogleRegister(true);

      // eslint-disable-next-line @typescript-eslint/naming-convention
      userStore
        .loginWithGoogle(client, response.code)
        .then(() => {
          history.push(authRoutes.Login());
        })
        .catch((error: Error) => {
          setRegisterWithGoogleError(
            t(error?.message || "internalServerError")
          );
        })
        .finally(() => {
          setIsLoadingGoogleRegister(false);
        });
    },
    flow: "auth-code",
  });

  const handleRegister = async () => {
    setIsLoading(true);
    const emailError = await validateEmail(fields.email as string);

    const passwordError =
      !fields || !fields?.password || (fields?.password as string).trim() === ""
        ? t("password_error")
        : !passwordRegex.test(fields?.password as string)
        ? t("passwordValidation_error")
        : "";

    const confirmPasswordError =
      !fields ||
      !fields?.confirmPassword ||
      (fields?.confirmPassword as string).trim() === ""
        ? t("password_error")
        : !passwordRegex.test(fields?.confirmPassword as string)
        ? t("passwordValidation_error")
        : fields?.confirmPassword !== fields?.password
        ? t("confirmPassword_error")
        : "";

    const firstNameError =
      !fields ||
      !fields?.firstName ||
      (fields?.firstName as string).trim() === ""
        ? t("firstName_error")
        : "";

    const lastNameError =
      !fields || !fields?.lastName || (fields?.lastName as string).trim() === ""
        ? t("lastName_error")
        : "";

    setErrors({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      firstName: firstNameError,
      lastName: lastNameError,
    });

    if (
      !emailError &&
      !passwordError &&
      !confirmPasswordError &&
      !firstNameError &&
      !lastNameError &&
      fields
    ) {
      const registerFields = {
        email: (fields?.email as string)?.trim(),
        firstName: fields.firstName,
        lastName: fields.lastName,
        password: fields.password,
      };

      userStore
        .createUser(client, registerFields)
        .then((createUser) => {
          if (createUser?.fromLink === true) {
            setShowContent(true);
          }
          setCreateSuccess(true);
          notification.success(t("register_success"));
        })
        .catch((error: Error) => {
          setCreateSuccess(false);
          notification.error(t(error?.message || "register_error"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleRegister();
  };

  const handleRedirect = () => {
    history.push(authRoutes.Login());
  };

  const registerFields: FormField[] = [
    {
      translationKey: "firstNameInput_placeholder",
      key: "firstName",
      type: FIELD_TYPE.input,
      props: {
        title: t("firstNameInput_title"),
      },
      errorText: errors?.firstName as string,
    },
    {
      translationKey: "lastNameInput_placeholder",
      key: "lastName",
      type: FIELD_TYPE.input,
      props: {
        title: t("lastNameInput_title"),
      },
      errorText: errors?.lastName as string,
    },
    {
      translationKey: "emailInput_placeholder",
      key: "email",
      type: FIELD_TYPE.input,
      props: {
        title: t("emailInput_title"),
        readOnly: !!userStore.tokenData?.email,
      },
      errorText: errors?.email as string,
    },
    {
      translationKey: "passwordInput_placeholder",
      key: "password",
      type: FIELD_TYPE.password,
      tooltipLocation: "title",
      props: {
        title: t("passwordInput_title"),
        description: t("passwordValidation_error"),
      },
      showStrengthBar: true,
      showRules: false,
      errorText: errors?.password,
    },
    {
      translationKey: "confirmPasswordInput_placeholder",
      key: "confirmPassword",
      type: FIELD_TYPE.password,
      props: {
        title: t("confirmPasswordInput_title"),
      },
      errorText: errors?.confirmPassword,
    },
  ];

  useEffect(() => {
    setFields({ ...fields, email: userStore.tokenData?.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>

      {!showConfirm ? (
        <Container maxWidth="xs" className={classes.pageContainer}>
          <Box className={classes.registerContainer}>
            <Typography variant="h4" fontWeight="600" mb="10px">
              {t("title")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <CoreFormRenderer
                fields={registerFields}
                translation={t}
                data={fields}
                onChange={handleInput}
              />
              <Box className={classes.groupButtons}>
                <CoreButton
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading || isLoadingGoogleRegister}
                >
                  {t("register_button")}
                </CoreButton>
              </Box>
            </form>
            <CoreButton
              onClick={handleGoogleRegister}
              disabled={isLoadingGoogleRegister || isLoading}
              variant="outlined"
              isFullWidth
              className={classes.button}
              isLoading={isLoadingGoogleRegister}
            >
              <GoogleIcon className={classes.googleIcon} />
              {t("registerWithGoogle")}
            </CoreButton>
            {registerWithGoogleError && (
              <Box className={classes.error}>
                <Typography className={classes.errorText}>
                  {registerWithGoogleError}
                </Typography>
              </Box>
            )}
            <Divider className={classes.divider} />
            <Box className={classes.footer}>
              <Link
                color={theme.palette.text.primary}
                underline="none"
                onClick={() => history.push(authRoutes.Login())}
              >
                {t("have_account")} {t("login_button")}
              </Link>
            </Box>
          </Box>
        </Container>
      ) : (
        <Box className={classes.confirmBox}>
          <Box className={classes.logoContainer}>
            <Logo />
          </Box>
          <Typography className={classes.text} fontWeight="800">
            {t("verification_title")}
          </Typography>

          {showContent && (
            <>
              <Typography className={classes.text}>
                {t("verification_email", { email: fields.email })}
              </Typography>

              <Typography className={classes.text}>
                {t("verification_checkSpam")}
              </Typography>
            </>
          )}

          <CoreButton
            onClick={handleRedirect}
            variant="contained"
            className={classes.verificationButtons}
          >
            {`${t("goTo")} ${t("login_button")}`}
          </CoreButton>

          {showContent && (
            <CoreButton
              variant="outlined"
              className={classes.verificationButtons}
              onClick={() => history.push(authRoutes.ResendActivation())}
            >
              {t("verification_resend")}
            </CoreButton>
          )}
        </Box>
      )}
    </Box>
  );
});

export default Register;
