import { ContextEventData } from "../types/interfaces/contextEvent";

export default class ActivityLogsHelper {
  static initiatedByFormat = (data: unknown): string => {
    const eventData = data as ContextEventData;
    if (
      !eventData ||
      eventData?.userId === null ||
      eventData?.userId === undefined
    ) {
      return "-";
    }

    if (eventData?.userId === 0) {
      return "Claritext";
    }

    if (
      !eventData.user ||
      !eventData?.user?.first_name ||
      !eventData?.user?.last_name
    ) {
      return "Claritext user";
    }

    return `[${eventData?.user?.first_name} ${eventData?.user?.last_name}]`;
  };
}
