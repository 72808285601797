import { gql } from "@apollo/client";

export const APPLY_QA = gql`
  mutation applyQA(
    $users: [String]
    $sessionId: String
    $assignedBy: String!
    $excludeErrors: Boolean
  ) {
    applyQA(
      users: $users
      sessionId: $sessionId
      assignedBy: $assignedBy
      excludeErrors: $excludeErrors
    )
  }
`;

export interface MUTATION_APPLY_QA_RESPONSE {
  applyQA: boolean;
}
