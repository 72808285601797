// Module exports
import React, { FC, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { isBoolean } from "lodash";
import { v4 as uuidv4 } from "uuid";

// Design imports
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  AssetConfiguration,
  AssetTemplateConfiguration,
  FormField,
  FormData,
  AssetTemplateParameters,
  AssetCanDeleteCanEdit,
  Flow,
  EXPORTED_VARIABLE,
  Variable,
} from "../../../../types/interfaces";
import {
  FIELD_TYPE,
  FLOW_DATA_TYPES_BACKEND,
  FLOW_DATA_TYPES_FRONTEND,
  FlowEnum,
} from "../../../../types/constants";
import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { useStores } from "../../../../stores/StoresProvider";
import {
  useNotification,
  NotificationTypes,
} from "../../../../context/useNotification";
import PreviewDialog from "../../addNewFlow/tabs/assetsCore/components/PreviewDialog";
import FileBrowser from "../../addNewFlow/tabs/assetsCore/components/FileBrowser";
import { AppSuspense } from "../../../main/AppSuspense";
import { CoreFormRenderer } from "../../../core/CoreFormRenderer";
import CoreButton from "../../../core/CoreButton";
import FlowHelper from "../../../../helper/flowHelper";
import ConfigHelper from "../../../../helper/configHelper";
import CoreNotificationBox from "../../../core/CoreNotificationBox";

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
});

const getFieldsConfig = (
  t: TFunction,
  config: AssetTemplateConfiguration,
  fieldsErrors: {
    [key: string]: string;
  },
  formData: FormData,
  flow: Flow,
  flows: Flow[],
  onClickConnection: () => void,
  onClickRootPath: () => void,
  setFormData: (data: FormData) => void,
  canCreateConnection: boolean,
  canEdit?: AssetCanDeleteCanEdit
) => {
  const fields = config?.parameters ?? [];

  const getExtraActions = (field: AssetTemplateParameters) => {
    if (field.allowAddConnection && canCreateConnection)
      return {
        extraActions: (
          <IconButton onClick={onClickConnection}>
            <AddIcon />
          </IconButton>
        ),
      };

    if (field.key === FlowEnum.rootPath)
      return {
        extraActions: (
          <IconButton
            onClick={onClickRootPath}
            disabled={!formData.connectionId}
          >
            <AddIcon />
          </IconButton>
        ),
      };

    return {};
  };

  return [
    ...ConfigHelper.getFieldsConfig(t, fieldsErrors),
    ...fields.map((field) => {
      const parentKeys = Object.keys(field.parents ?? {});
      let render = true;
      parentKeys.forEach((key) => {
        if (!field.parents?.[key].includes(formData[key])) {
          render = false;
        }
      });

      if (!render) return {} as FormField;

      if (formData[field.key] === undefined) {
        setFormData({ ...formData, [field.key]: field.value });
      }

      return {
        ...field,
        translationKey: field.name,
        key: field.key,
        type:
          (field.type as unknown as string) === FlowEnum.flow
            ? FIELD_TYPE.select
            : field.type,
        errorText: fieldsErrors?.[field.key],
        isMandatory: field.isMandatory || false,
        tooltipLocation: "input",
        canContainSpacesOnly: field.canContainSpacesOnly,
        ...getExtraActions(field),
        props: {
          title: field.name,
          readOnly:
            field.key === FlowEnum.rootPath ||
            (config.nonEditableFields.includes(field.key) &&
              canEdit?.enabled === false),
          description: field.description,
        },
        ...ConfigHelper.getOptions(field, flow, flows, formData),
      };
    }),
  ] as FormField[];
};

interface Props {
  t: TFunction;
  type: string;
  onClose: (refresh?: boolean) => void;
  categoryKey: string;
  setOpenConn: (open: boolean) => void;
  openFileBrowser: boolean;
  setOpenFileBrowser: (open: boolean) => void;
  setData: (data: FlowDataCategoryItem) => void;
  itemToEdit?: FlowDataCategoryItem;
}

const DataCategoryConfig: FC<Props> = observer(
  ({
    t,
    onClose,
    categoryKey,
    type,
    setOpenConn,
    openFileBrowser,
    setOpenFileBrowser,
    setData,
    itemToEdit,
  }) => {
    const client = useApolloClient();
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const notification = useNotification();
    const { flowStore, userStore, flowSettingsStore } = useStores();

    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [config, setConfig] = useState<AssetTemplateConfiguration>();
    const [canEdit, setCanEdit] = useState<AssetCanDeleteCanEdit>();
    const [formData, setFormData] = useState<FormData>({
      key: categoryKey,
    });
    const [openDialog, setOpenDialog] = useState<
      AssetConfiguration | undefined
    >(undefined);
    const [fieldsErrors, setFieldsErrors] = useState<{
      [key: string]: string;
    }>({});
    const [variables, setVariables] = useState<Variable[]>([]);

    const formattedType = (
      FLOW_DATA_TYPES_BACKEND as unknown as { [key: string]: unknown }
    )[type] as string;

    const onClickConnection = () => setOpenConn(true);
    const onClickRootPath = () => setOpenFileBrowser(true);

    useEffect(() => {
      if (itemToEdit) {
        setFormData(itemToEdit as unknown as FormData);
      }
    }, [itemToEdit]);

    useEffect(() => {
      setLoading(true);

      const assetConfig = flowStore.getAssetConfiguration(
        itemToEdit !== undefined ? itemToEdit.key : categoryKey
      );

      const variables = flowSettingsStore
        .getExportableObjects(id)
        .catch(() => null);

      Promise.all([variables, assetConfig])
        .then(([variablesResponse, assetConfigResponse]) => {
          const config = assetConfigResponse.getAssetConfig;
          setConfig(config);
          if (variablesResponse) setVariables(variablesResponse);

          if (itemToEdit === undefined) {
            setFormData({
              ...config.parameters.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.key]: field.value,
                  ...(field.type === FIELD_TYPE.exportFields && {
                    exportFields:
                      (variablesResponse &&
                        FlowHelper.getExportVariables(
                          variablesResponse || []
                        )) ||
                      [],
                  }),
                }),
                {}
              ),
              name: config.name,
              description: config.description,
              ...formData,
            });
          } else {
            const {
              key,
              identifier,
              name,
              description,
              canEdit,
              parameters: { exportFields, ...others },
            } = itemToEdit as unknown as AssetConfiguration;
            setCanEdit(canEdit);

            setFormData({
              key,
              identifier,
              name,
              description,
              ...(exportFields && {
                exportFields:
                  (variablesResponse &&
                    FlowHelper.getExportVariables(
                      variablesResponse || [],
                      exportFields as unknown as EXPORTED_VARIABLE
                    )) ||
                  [],
              }),
              ...others,
            });
          }
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "configError"));
        })
        .finally(() => {
          setLoading(false);
        });
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedData = (): AssetConfiguration | undefined => {
      const { name, description, key, identifier, ...parameters } = formData;

      const configuration = {
        name,
        identifier: identifier || uuidv4(),
        description,
        key: key || categoryKey,
        type: formattedType,
        parameters: {
          ...parameters,
          ...(parameters.exportFields
            ? {
                exportFields: FlowHelper.buildExportVariables(parameters),
              }
            : null),
        },
      } as unknown as AssetConfiguration;

      const currentErrors = {} as { [key: string]: string };

      //Check if mandatory field is empty or has undefined value

      if (formattedFields) {
        formattedFields.forEach((config) => {
          if (
            config.isMandatory &&
            !isBoolean(formData[config.key]) &&
            FlowHelper.checkIfValueIsEmpty(
              formData[config.key],
              config.canContainSpacesOnly
            )
          ) {
            currentErrors[config.key] = t("validationFieldsError");
          }
        });
      }

      if (Object.keys(currentErrors).length > 0) {
        setFieldsErrors(currentErrors);
        return;
      } else {
        setFieldsErrors({});
      }

      return configuration;
    };

    const canCreateConnection =
      userStore.currentUserPermissions?.can("create", "connections") ?? false;

    const formattedFields = useMemo(
      () =>
        getFieldsConfig(
          t,
          config as AssetTemplateConfiguration,
          fieldsErrors,
          formData,
          flowStore.flow as Flow,
          flowStore.flows,
          onClickConnection,
          onClickRootPath,
          setFormData,
          canCreateConnection,
          canEdit
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [t, config?.parameters, fieldsErrors, formData, setOpenConn]
    );

    const onFormChange = (value: FormData, key: string) => {
      if (key === FlowEnum.category) {
        setFormData({
          ...formData,
          ...value,
          labels: undefined,
        });
        return;
      }

      setFieldsErrors({
        ...fieldsErrors,
        [key]: "",
      });

      setFormData({ ...formData, ...value });
    };

    const handleConfirm = () => {
      const configurationData = formattedData();

      if (!configurationData) return;

      setData(configurationData as unknown as FlowDataCategoryItem);
      onClose(true);
    };

    const handlePreviewDataSource = () => {
      const configurationData = formattedData();

      if (configurationData) {
        setOpenDialog(configurationData);
      }
    };

    const handleTest = () => {
      const configurationData = formattedData();

      if (configurationData) {
        setLoadingButton(true);
        flowStore
          .dataExportTest(client, configurationData)
          .then(() => {
            notification.success(t("data_export_test_success"));
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "data_export_test_error"));
          })
          .finally(() => setLoadingButton(false));
      }
    };

    const handleTestAndPreview = () => {
      switch (type) {
        case FLOW_DATA_TYPES_FRONTEND.dataSources:
          handlePreviewDataSource();
          break;
        case FLOW_DATA_TYPES_FRONTEND.dataExports:
          handleTest();
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      if (openDialog) {
        setLoadingButton(true);
      } else {
        setLoadingButton(false);
      }
    }, [openDialog]);

    return (
      <>
        {!openFileBrowser ? (
          <>
            {loading ? (
              <AppSuspense />
            ) : (
              <>
                {config ? (
                  <Box>
                    <CoreFormRenderer
                      fields={formattedFields as unknown as FormField[]}
                      translation={t}
                      onChange={onFormChange}
                      data={formData}
                      groups={config?.groups ?? []}
                      variables={variables}
                    />
                  </Box>
                ) : (
                  <CoreNotificationBox
                    type={NotificationTypes.error}
                    description={t("failToFetchFields")}
                  />
                )}

                <Box className={classes.footer}>
                  <CoreButton
                    variant="contained"
                    onClick={handleConfirm}
                    disabled={loadingButton || !config}
                  >
                    {itemToEdit !== undefined ? t("edit") : t("add")}
                  </CoreButton>

                  <CoreButton
                    variant="outlined"
                    onClick={handleTestAndPreview}
                    isLoading={loadingButton}
                    disabled={loadingButton || !config}
                  >
                    {t(`${formattedType}_btn`)}
                  </CoreButton>
                </Box>
              </>
            )}
          </>
        ) : (
          <FileBrowser
            onClose={() => {
              setOpenFileBrowser(false);
            }}
            connectionId={formData.connectionId as string}
            onFormChange={onFormChange}
            formData={formData}
          />
        )}

        <PreviewDialog open={openDialog} setOpenDialog={setOpenDialog} />
      </>
    );
  }
);

export default DataCategoryConfig;
