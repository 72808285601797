// TODO: Remove
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Rect, Transformer } from "react-konva";
import Konva from "konva";
import { Box } from "konva/lib/shapes/Transformer";
import { Node } from "konva/lib/Node";

import { Theme, alpha } from "@mui/material";
import { observer } from "mobx-react";

import { DocumentZone } from "../../../../../../types/interfaces";
import { DocumentStore } from "../../../../../../stores/DocumentStore";
import ZoneHelper from "../../../../../../helper/canvas/zoneHelper";
import { CANVAS_ACTIONS } from "../../../../../../types/constants";

interface Props {
  theme: Theme;
  zone: DocumentZone;
  isFocused: boolean;
  isReadOnly?: boolean | null;
  isDraggable?: boolean; // In PAN mode, disable
  isEditable?: boolean; // Advanced mode -> transform zone
  isSelectMode?: boolean; // Used in simple validation mode to select zones and not draw new one (border style will be changed)
  forceDirectFocus?: boolean; // Used for manuall lineItem row mode
  disableRotate?: boolean;
  onFocus?: (identifier: string) => void;
  onUpdate?: (
    zone: DocumentZone,
    event?: Konva.KonvaEventObject<Event>
  ) => void;
  store: DocumentStore;
  noBackground?: boolean; // Background of cell is not set
  searchQuery?: string;
}

/**
 * Returns style props based on three zone states
 * 1 -> zone is hovered but not linked with a field
 * 2 -> zone is linked with a field but not hovered/focused
 * 3 -> zone is linked with a field and hovered/focused
 * @param theme
 * @param zone
 * @param isFocused
 * @param isHovered
 * @returns
 */
const getZoneStyle = (
  theme: Theme,
  zone: DocumentZone,
  isFocused: boolean,
  isHovered: boolean,
  isSelectMode: boolean | undefined,
  documentStore: DocumentStore,
  noBackground?: boolean,
  searchQuery?: string
) => {
  let zoneStyleProps = {};

  // Style for hovered zone but not linked
  if (
    (isHovered &&
      !isFocused &&
      !documentStore.viewMode &&
      documentStore.canvasAction === CANVAS_ACTIONS.drawNewZone &&
      !documentStore.temporaryAction) ||
    documentStore.isOcrVisible
  ) {
    zoneStyleProps = {
      dash: [7],
      strokeWidth: 2,
      stroke: theme.palette.neutral.dark,
    };
  }

  // Style used for multiple select and searched zones
  if (
    isFocused ||
    (searchQuery && zone.text.toLowerCase().includes(searchQuery.toLowerCase()))
  ) {
    zoneStyleProps = {
      ...zoneStyleProps,
      strokeWidth: 1,
      stroke: theme.palette.canvas.dark,
      ...(isSelectMode ? { dash: [7] } : {}),
    };
  }

  // Style for linked zone with field
  if (zone.category || zone.type) {
    zoneStyleProps = {
      ...zoneStyleProps,
      strokeWidth: 2,
      stroke: theme.palette.highlight.main,
    };

    if (!noBackground) {
      zoneStyleProps = {
        ...zoneStyleProps,
        fill: isFocused
          ? alpha(theme.palette.canvas.light, 0.3)
          : alpha(theme.palette.canvas.light, 0.1),
      };
    }
  }
  return zoneStyleProps;
};

const Zone: FC<Props> = observer(
  ({
    theme,
    zone,
    isReadOnly,
    isFocused,
    isDraggable,
    isEditable,
    isSelectMode,
    forceDirectFocus,
    disableRotate,
    onUpdate,
    onFocus,
    store,
    noBackground,
    searchQuery,
  }) => {
    const zoneRef = React.useRef();
    const transformerRef = useRef();
    const [isHovered, toggleHover] = useState(false);

    const rectStyle = useMemo(
      () =>
        getZoneStyle(
          theme,
          zone,
          isFocused,
          isHovered,
          isSelectMode,
          store,
          noBackground,
          searchQuery
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        zone,
        isHovered,
        isFocused,
        isSelectMode,
        store.isOcrVisible,
        noBackground,
        searchQuery,
      ]
    );

    const isTransformerVisible = useMemo(
      () =>
        isFocused && isEditable && zone.box && Object.keys(zone.box).length > 1,
      [isFocused, isEditable, zone.box]
    );

    useEffect(() => {
      // Init transformer
      if (isFocused) {
        try {
          (transformerRef?.current as any)?.nodes([zoneRef.current]);
          (transformerRef?.current as any)?.getLayer().batchDraw();
        } catch {
          /** */
        }
      }
    }, [isFocused, isEditable]);

    const getTransformerBoundBox = useCallback(
      (oldBox: Box, newBox: Box): Box =>
        ZoneHelper.getTransformerBoundBox(oldBox, newBox),
      []
    );

    // Zone coords are changed
    const handleZoneTransform = useCallback(
      (event: Konva.KonvaEventObject<Event>) => {
        if (!onUpdate) {
          return;
        }

        const node = zoneRef.current as unknown as Node;

        const updatedZone = {
          ...zone,
          box: {
            x: node.x(),
            y: node.y(),
            // Minimal value
            width: Math.max(node.width()),
            height: Math.max(node.height()),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
            rotation: node.rotation(),
          },
        };

        node.scale({
          x: 1,
          y: 1,
        });

        onUpdate(updatedZone, event);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [zone, zoneRef]
    );

    const handleDragEnd = useCallback(
      (e: Konva.KonvaEventObject<DragEvent>) => {
        if (!onUpdate) {
          return;
        }

        onUpdate(
          {
            ...zone,
            box: {
              ...zone.box,
              x: e.target.x(),
              y: e.target.y(),
              scaleX: e.target.scaleX(),
              scaleY: e.target.scaleY(),
            },
          },
          e
        );

        if (
          !store.manualLineItemMode &&
          (zone.category || zone.type) !== store.focusedFieldCanvas
        ) {
          store.setFocusFieldCanvas(zone.category || zone.type || "");
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [zone]
    );

    const handleDblClick = () => {
      // For manually added cell (lineItem), no need for below actions
      if (forceDirectFocus && onFocus) {
        onFocus(zone.identifier);
        store.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
        return;
      }

      if (store.canvasAction === CANVAS_ACTIONS.pan) {
        store.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
        handleFocus(zone.identifier);
      }
    };

    const handleFocus = useCallback((identifier: string) => {
      if (!onFocus) {
        return;
      }

      // For manually added cell (lineItem), no need for below actions
      if (forceDirectFocus) {
        onFocus(zone.identifier);
        store.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
        return;
      }

      const category = zone.type || zone.category;

      if (
        category &&
        category !== store.focusedFieldCanvas &&
        !store.multiSelectMode &&
        store.canvasAction === CANVAS_ACTIONS.drawNewZone
      ) {
        store.handleCancelFocusedFieldCanvas(false);
      }

      if (category && category !== store.focusedFieldCanvas) {
        store.setFocusFieldCanvas(category);
      }

      onFocus(identifier);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Manual lineItem cells are first initialized with empty box coords
    if (
      zone?.box?.x === 0 &&
      zone?.box?.y === 0 &&
      zone?.box?.width === 0 &&
      zone?.box?.height === 0
    ) {
      return <></>;
    }

    return (
      <>
        <Rect
          ref={zoneRef as React.LegacyRef<any> | undefined}
          draggable={isDraggable}
          x={zone.box.x}
          y={zone.box.y}
          rotation={zone.box.rotation || 0}
          width={zone.box.width}
          height={zone.box.height}
          onDragEnd={handleDragEnd}
          {...(isReadOnly
            ? {}
            : {
                onClick: () => handleFocus(zone.identifier),
                onTap: () => handleFocus(zone.identifier),
                onMouseEnter: () => toggleHover(true),
                onMouseLeave: () => toggleHover(false),
                onDblClick: () => handleDblClick(),
              })}
          onTransformEnd={handleZoneTransform}
          {...rectStyle}
          strokeScaleEnabled={false}
        />

        {isTransformerVisible && (
          <Transformer
            rotateEnabled={!disableRotate}
            ref={transformerRef as React.LegacyRef<any> | undefined}
            boundBoxFunc={getTransformerBoundBox}
            ignoreStroke={true}
            onTransformStart={() => store.setZoneTransforming(true)}
            onTransformEnd={() => store.setZoneTransforming(false)}
          />
        )}
      </>
    );
  }
);
export default memo(Zone);
