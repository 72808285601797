import React, { FC, useMemo, useState } from "react";
import { Group, Line, Rect } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { observer } from "mobx-react";

import { Theme } from "@mui/material";

import {
  DocumentLineItem,
  DocumentLineItemLine,
} from "../../../../../../types/interfaces";
import {
  DELETE_LINE_RANGE,
  LINE_SPACING,
} from "../../../../../../types/constants";
import { DocumentStore } from "../../../../../../stores/DocumentStore";
import { KonvaImageRenderer } from "./RowLineDraft";
import CanvasHelper from "../../../../../../helper/canvas/canvasHelper";
import polygonClipping, { Polygon } from "polygon-clipping";

interface Props {
  table: DocumentLineItem;
  documentStore: DocumentStore;
  theme: Theme;
}

// TODO: remove comments after split icon size on zoom in/out is done
export const ColLineDraft: FC<Props> = observer(
  ({ table, documentStore, theme }) => {
    const [isHovered, toggleHover] = useState(false);
    const [xPosition, setXPosition] = useState(0);
    const [lineDeleteMode, setLineDeleteMode] = useState(false);

    const focusedLineItem = documentStore.focusedLineItem;

    const tableEdges = useMemo(
      () => ({
        box: table?.coords?.root,
        type: table.type,
      }),
      [table?.coords?.root, table.type]
    );

    // const yPoint =
    //   (documentStore.canvasScaleSize * 10000) / tableEdges?.box?.y - 25;

    const calculatedXPosition = useMemo(
      // The xPosition is calculated strictly within the drawn boundary
      () => xPosition - tableEdges?.box?.x,
      [tableEdges?.box?.x, xPosition]
    );

    const isDeleteMode = (lineX: number) => {
      const lineLeft = lineX - DELETE_LINE_RANGE;
      const lineRight = lineX + DELETE_LINE_RANGE;

      const deleteMode = focusedLineItem?.coords?.lines?.some(
        (line) => line[1][0] >= lineLeft && line[1][0] <= lineRight
      );

      return !!deleteMode;
    };

    const onNewColLineDraw = (event: KonvaEventObject<MouseEvent>) => {
      // Draw line only when Alt key is being pressed
      if (event.evt.ctrlKey) {
        const { x = 0 } =
          event?.target?.getStage()?.getRelativePointerPosition() || {};

        const lineInit = [
          [x, tableEdges?.box?.y],
          [x, tableEdges?.box?.y + tableEdges?.box?.height],
        ] as DocumentLineItemLine;

        if (lineDeleteMode) {
          documentStore.mergeLineItemColumns(lineInit);
        } else {
          // This will open the column config drawer
          // Column split will be done after confirming new column config
          documentStore.setNewLineDraft(lineInit);

          // line poly with 5 px buffer on x axis
          // and on y axis spanning infinity
          const linePoly = [
            [
              [lineInit[0][0] - 5, -Infinity],
              [lineInit[0][0] - 5, Infinity],
              [lineInit[1][0] + 5, Infinity],
              [lineInit[1][0] + 5, -Infinity],
            ],
          ] as Polygon;

          const focusedLineItems = documentStore.lineItems.find(
            (lineItem) => lineItem.type === documentStore.focusedFieldCanvas
          );

          if (focusedLineItems) {
            const intersectedHeader = focusedLineItems.headers.find(
              (header) => {
                const boxToPoly = [
                  CanvasHelper.initRowPoints(header.box),
                ] as Polygon;

                return polygonClipping.intersection(boxToPoly, linePoly)[0];
              }
            );

            if (intersectedHeader) {
              documentStore.splitColumnLine(lineInit, intersectedHeader);
              documentStore.setNewLineDraft(null);
            }
          }
        }
      }
    };

    const handleMouseEnter = (event: KonvaEventObject<MouseEvent>) => {
      if (event.evt.ctrlKey) {
        const { x = 0 } =
          event?.target?.getStage()?.getRelativePointerPosition() || {};

        setXPosition(x);
        toggleHover(true);

        const stage = event.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = "pointer";
        }
      }
    };

    // Triggered when mouse moves on the line
    const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
      if (event.evt.ctrlKey) {
        const { x = 0 } =
          event?.target?.getStage()?.getRelativePointerPosition() || {};

        setLineDeleteMode(isDeleteMode(x));
        setXPosition(x);

        if (!isHovered) {
          toggleHover(true);
        }
      } else {
        if (isHovered) {
          toggleHover(false);
        }
      }
    };

    // Triggered when mouse moves leaves line
    const handleMouseLeave = (event: KonvaEventObject<MouseEvent>) => {
      // Reset position
      setXPosition(0);
      toggleHover(false);

      const stage = event.target.getStage();
      if (stage) {
        const container = stage.container();
        container.style.cursor = "default";
      }
    };

    // const konvaImageRendererX =
    //   calculatedXPosition -
    //   5 / documentStore.canvasScaleSize -
    //   documentStore.canvasScaleSize * 2;
    // const imageDimension =
    //   10 / documentStore.canvasScaleSize + documentStore.canvasScaleSize * 5;

    return (
      <>
        {/* Column line draw */}
        <Group
          x={tableEdges?.box?.x}
          y={tableEdges?.box?.y - LINE_SPACING}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onClick={onNewColLineDraw}
        >
          <Rect width={tableEdges?.box?.width} height={LINE_SPACING} />

          {isHovered && (
            <>
              <KonvaImageRenderer
                x={calculatedXPosition - 5}
                y={0}
                // x={konvaImageRendererX}
                // y={yPoint}
                // width={imageDimension}
                // height={imageDimension}
                deleteMode={lineDeleteMode}
              />

              <Line
                points={[
                  calculatedXPosition,
                  LINE_SPACING,
                  calculatedXPosition,
                  tableEdges?.box?.height + LINE_SPACING,
                ]}
                closed
                stroke={
                  lineDeleteMode
                    ? theme.palette.error.main
                    : theme.palette.canvas.main
                }
              />
            </>
          )}

          <Line
            // points={[0, yPoint, tableEdges?.box?.width, yPoint]}
            points={[0, 0, tableEdges?.box?.width, 0]}
            closed
            stroke={theme.palette.canvas.main}
          />
        </Group>
      </>
    );
  }
);
