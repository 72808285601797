import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import Paper from "@mui/material/Paper";
import { alpha, useTheme, IconButton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GridClearIcon } from "@mui/x-data-grid";

import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useStores } from "../../../../../stores/StoresProvider";
import { CANVAS_ACTIONS, APP_IDS } from "../../../../../types/constants";
import CoreTooltip from "../../../../core/CoreTooltip";
import CanvasShortcutsContent from "./CanvasShortcutsContent";
import ToolbarMenuList from "./ToolbarMenuList";
import CoreInput from "../../../../core/CoreInput";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

interface Props {
  activeAction: CANVAS_ACTIONS;
  fitAction: CANVAS_ACTIONS | undefined;
  changeAction: (action: CANVAS_ACTIONS) => void;
}

const Toolbar: FC<Props> = observer(
  ({ activeAction, fitAction, changeAction }) => {
    const theme = useTheme();
    const { ready, t } = useTranslation("document-validate");
    const { documentStore } = useStores();
    const useStyles = makeStyles({
      menuContainer: {
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "none",
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        borderRadius: "0",
        backgroundImage: "unset",
        overflowX: "auto",
        whiteSpaces: "nowrap",
      },
      shortcutsInfo: { marginRight: "20px", cursor: "pointer" },
      showOcrButton: {
        minWidth: "270px",
      },
      infoIcon: {
        marginLeft: "10px",
      },
      input: {
        width: "250px",
      },
      box: {
        display: "flex",
        marginLeft: "auto",
        gap: "5px",
      },
    });
    const classes = useStyles();

    const infoTooltipContent = <CanvasShortcutsContent />;

    if (!ready) {
      return <></>;
    }

    return (
      <Paper className={classes.menuContainer} id={APP_IDS.canvasToolbar}>
        <ToolbarMenuList
          activeAction={activeAction}
          fitAction={fitAction}
          changeAction={changeAction}
          t={t}
        />

        {documentStore.documentHasZones && (
          <Box className={classes.box}>
            <CoreTooltip
              title={t(documentStore.isOcrVisible ? "hide_ocr" : "show_ocr")}
            >
              <span>
                <IconButton onClick={documentStore.toggleOcr}>
                  {documentStore.isOcrVisible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </span>
            </CoreTooltip>

            <CoreInput
              className={classes.input}
              placeholder={t("search_tooltip")}
              value={documentStore.ocrSearchQuery}
              onChange={(e) => documentStore.setOcrSearchQuery(e.target.value)}
              endAdornment={
                documentStore.ocrSearchQuery && (
                  <IconButton
                    onClick={() => documentStore.setOcrSearchQuery("")}
                  >
                    <GridClearIcon />
                  </IconButton>
                )
              }
            />
          </Box>
        )}

        <CoreTooltip className={classes.infoIcon} title={infoTooltipContent}>
          <InfoIcon className={classes.shortcutsInfo} />
        </CoreTooltip>
      </Paper>
    );
  }
);

export default memo(Toolbar);
