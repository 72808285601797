import {
  Components,
  ThemeOptions,
  alpha,
  OutlinedInputProps,
} from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiInput = (
  theme: ThemeOptions
): Components["MuiOutlinedInput"] => {
  const palette = theme.palette as ThemePalette;
  return {
    styleOverrides: {
      root: {
        "&.MuiOutlinedInput-root": {
          backgroundColor: alpha(palette.surface.dark, 0.8),
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.neutral.main,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.neutral.dark,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.neutral.dark,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.error.dark,
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: palette.text.disabled,
          },
        },
      },
      input: {
        "&:-webkit-autofill": {
          boxShadow: ` 0 0 0px 1000px ${palette.secondary.main} inset`,
          font: "inherit",
          borderRadius: "initial",
        },
        color: palette.surface.contrastText,
      },
    },
    variants: [
      // Currently used in canvas field editors
      {
        props: { variant: "focused" } as Partial<OutlinedInputProps>,
        style: {
          "&.MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.highlight.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.highlight.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.highlight.main,
            },
          },
        },
      },
      {
        props: { variant: "error" } as Partial<OutlinedInputProps>,
        style: {
          "&.MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.error.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.error.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.error.main,
            },
          },
        },
      },
    ],
  };
};
