import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { MenuList } from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

import Portal from "./Portal";
import {
  DocumentLineItemLine,
  DocumentZone,
  DocumentZoneBox,
} from "../../../../../../../types/interfaces";
import { DocumentStore } from "../../../../../../../stores/DocumentStore";
import CanvasHelper from "../../../../../../../helper/canvas/canvasHelper";

interface Props {
  open: boolean;
  onClose: () => void;
  documentStore: DocumentStore;
  anchorReference: "anchorPosition" | "anchorEl";
  tableCoords: DocumentZoneBox | null;
  currentCell: {
    rowIndex: number;
    cellIndex: number;
    cell: DocumentZone;
    headerKey: string;
  } | null;
  anchorPosition:
    | {
        top: number;
        left: number;
      }
    | undefined;
}

const useStyles = makeStyles({
  rightClickMenuText: {
    marginLeft: 10,
  },
});

const RightClickMenuCell: FC<Props> = observer(
  ({
    onClose,
    open,
    anchorReference,
    anchorPosition,
    documentStore,
    currentCell,
    tableCoords,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation("documents");

    const headerCoords = useMemo(() => {
      const header = documentStore?.usedLineItemHeaders?.find(
        (header) => header.key === currentCell?.headerKey
      );
      return header?.box ?? null;
    }, [currentCell?.headerKey, documentStore?.usedLineItemHeaders]);

    const isRowIgnored = documentStore.isRowIgnored(
      (currentCell?.cell?.box?.y ?? 0) + 10
    );

    const isHeaderIgnored = useMemo(() => {
      const unusedHeaders = documentStore?.unusedLineItemHeaders?.map(
        (header) => header.key
      );

      if (unusedHeaders?.includes(currentCell?.headerKey ?? "")) {
        return true;
      }
      return false;
    }, [documentStore?.unusedLineItemHeaders, currentCell?.headerKey]);

    const isBottomRow = useMemo(() => {
      const cell = currentCell?.cell;

      return CanvasHelper.isDifferenceLessThan2px(
        (cell?.box?.y ?? 0) + (cell?.box?.height ?? 0),
        (tableCoords?.y ?? 0) + (tableCoords?.height ?? 0)
      );
    }, [currentCell?.cell, tableCoords?.y, tableCoords?.height]);

    const isTopRow = useMemo(() => {
      const cell = currentCell?.cell;

      return CanvasHelper.isDifferenceLessThan2px(
        cell?.box?.y ?? 0,
        tableCoords?.y ?? 0
      );
    }, [currentCell?.cell, tableCoords?.y]);

    const isLeftmostColumn = useMemo(() => {
      return CanvasHelper.isDifferenceLessThan2px(
        headerCoords?.x ?? 0,
        tableCoords?.x ?? 0
      );
    }, [headerCoords?.x, tableCoords?.x]);

    const isRightmostColumn = useMemo(() => {
      const headerRight = (headerCoords?.x ?? 0) + (headerCoords?.width ?? 0);
      const tableRight = (tableCoords?.x ?? 0) + (tableCoords?.width ?? 0);
      return CanvasHelper.isDifferenceLessThan2px(headerRight, tableRight);
    }, [headerCoords, tableCoords]);

    const getLineColumn = (isLeft: boolean) => {
      const startX = isLeft
        ? headerCoords?.x ?? 0
        : (headerCoords?.x ?? 0) + (headerCoords?.width ?? 0);

      const startY = tableCoords?.y ?? 0;
      const endY = startY + (tableCoords?.height ?? 0);

      return [
        [startX, startY],
        [startX, endY],
      ];
    };

    const getLineRow = (isUpper: boolean) => {
      const { box } = currentCell?.cell ?? {
        box: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
      };
      const { y, height } = box;

      const x = tableCoords?.x ?? 0;
      const width = tableCoords?.width ?? 0;

      if (isUpper)
        return [
          [x, y],
          [x + width, y],
        ] as DocumentLineItemLine;

      return [
        [x, y + height],
        [x + width, y + height],
      ] as DocumentLineItemLine;
    };

    const handleIgnoreCurrentRow = () => {
      documentStore.ignoreLineItemRow((currentCell?.cell.box.y ?? 0) + 10);
    };

    const handleIgnoreCurrentColumn = () => {
      if (currentCell?.headerKey) {
        documentStore.replaceLineItemColumn(
          currentCell?.headerKey ?? "",
          uuidv4()
        );
      }
    };

    const handleMergeRowTop = () => {
      documentStore.initLineItemRowLine(
        documentStore.focusedLineItem?.type ?? "",
        getLineRow(true) as DocumentLineItemLine,
        true
      );
    };

    const handleMergeRowBottom = () => {
      documentStore.initLineItemRowLine(
        documentStore.focusedLineItem?.type ?? "",
        getLineRow(false) as DocumentLineItemLine,
        true
      );
    };

    const handleMergeColumn = (isLeft: boolean) => {
      const line = getLineColumn(isLeft) as DocumentLineItemLine;
      documentStore.mergeLineItemColumns(line);
    };

    const handleCopyCellValue = () => {
      void navigator.clipboard.writeText(currentCell?.cell.text ?? "");
    };

    const MenuOptions = [
      {
        name: "ignoreCurrentRow",
        icon: <VisibilityOffIcon fontSize="small" />,
        action: handleIgnoreCurrentRow,
        disabled: isRowIgnored,
      },
      {
        name: "enableCurrentRow",
        icon: <VisibilityIcon fontSize="small" />,
        action: handleIgnoreCurrentRow,
        disabled: !isRowIgnored,
      },
      {
        name: "ignoreCurrentColumn",
        icon: <VisibilityOffIcon fontSize="small" />,
        action: handleIgnoreCurrentColumn,
        disabled: isHeaderIgnored,
      },
      {
        name: "mergeTopRow",
        icon: <NorthIcon fontSize="small" />,
        action: handleMergeRowTop,
        disabled: isTopRow,
      },
      {
        name: "mergeBottomRow",
        icon: <SouthIcon fontSize="small" />,
        action: handleMergeRowBottom,
        disabled: isBottomRow,
      },
      {
        name: "mergeLeftColumn",
        icon: <WestIcon fontSize="small" />,
        action: () => handleMergeColumn(true),
        disabled: isLeftmostColumn,
      },
      {
        name: "mergeRightColumn",
        icon: <EastIcon fontSize="small" />,
        action: () => handleMergeColumn(false),
        disabled: isRightmostColumn,
      },
      {
        name: "copyCellValue",
        icon: <CopyAllIcon fontSize="small" />,
        action: handleCopyCellValue,
      },
    ];

    return (
      <Portal>
        <Menu
          open={open}
          onClose={onClose}
          anchorReference={anchorReference}
          anchorPosition={anchorPosition}
        >
          <MenuList>
            {MenuOptions.map((option) =>
              option.disabled ? null : (
                <MenuItem
                  onClick={() => {
                    option.action();
                    onClose();
                  }}
                  key={option.name}
                >
                  {option.icon}
                  <span className={classes.rightClickMenuText}>
                    {t(option.name)}
                  </span>
                </MenuItem>
              )
            )}
          </MenuList>
        </Menu>
      </Portal>
    );
  }
);

export default RightClickMenuCell;
