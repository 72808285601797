import React, { FC } from "react";

import { createStyles, makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { appRoutes } from "../../../../configs/routes";
import CoreTextLink from "../../../core/CoreTextLink";

interface Props {
  flowName: string;
  flowId: string;
}

export const FlowNameView: FC<Props> = ({ flowName, flowId }) => {
  const useStyles = makeStyles(() =>
    createStyles({
      cellContainer: {
        display: "flex",
        alignItems: "center",
      },
    })
  );
  const classes = useStyles();

  return (
    <Box className={classes.cellContainer}>
      <CoreTextLink to={appRoutes.FlowDetails(flowId)} label={flowName} />
    </Box>
  );
};
