import { TFunction } from "i18next";

import { FIELD_TYPE } from "../../../types/constants";
import { ConnectionField } from "../../../types/interfaces";

export const infoConfig = (t: TFunction): ConnectionField[] => {
  return [
    {
      name: t("connection_name"),
      ftype: FIELD_TYPE.input,
      fields: [],
      key: "name",
      value: "",
      translationKey: "",
      parentValue: "",
      description: "connection_name_tooltip",
      tooltipLocation: "input",
      isMandatory: true,
    },
    {
      name: t("connection_description"),
      ftype: FIELD_TYPE.input,
      fields: [],
      key: "description",
      value: "",
      translationKey: "",
      parentValue: "",
      description: "connection_description_tooltip",
      tooltipLocation: "input",
      isMandatory: false,
    },
    {
      name: t("connection_type"),
      ftype: FIELD_TYPE.select,
      fields: [],
      key: "type",
      value: "",
      translationKey: "",
      parentValue: "",
      description: "connection_type_tooltip",
      tooltipLocation: "input",
      isMandatory: true,
      options: [],
    },
  ];
};
