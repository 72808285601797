import { gql } from "@apollo/client";

export interface ApiKey {
  identifier: string;
  key: string;
  name: string;
  description: string;
  active: boolean;
  metadata: unknown;
}

export interface QUERY_GET_API_KEYS_RESPONSE {
  listKeys: ApiKey[];
}

export const GET_API_KEYS = gql`
  query listKeys {
    listKeys {
      identifier
      key
      name
      description
      active
      metadata
    }
  }
`;
