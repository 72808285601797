import React from "react";
import { Popover } from "@mui/material";

import { DateRange } from "@mui/lab/DateRangePicker";
import DateSelector from "./date/DateSelector";
import { TFunction } from "i18next";

export interface Props {
  anchorEl: (EventTarget & HTMLElement) | null;
  onClose: () => void;
  dateIntervals?: DateRange<Date>;
  t: TFunction;
  onUpdate: (value: DateRange<Date>) => void;
}

const DatePicker: React.FC<Props> = ({
  anchorEl,
  dateIntervals,
  onClose,
  onUpdate,
  t,
}) => {
  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <DateSelector
        t={t}
        onClose={onClose}
        currentValues={dateIntervals as DateRange<Date>}
        onUpdate={onUpdate}
      />
    </Popover>
  );
};

export default DatePicker;
