// Modules imports
import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

// Design imports
import { SelectChangeEvent } from "@mui/material";

// Internal imports
import { NotificationTypes } from "../../../../../context/useNotification";
import CoreNotificationBox from "../../../../core/CoreNotificationBox";
import RepositorySettings from "./RepositorySettings";
// import DataSettings from "./DataSettings";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import { UserStore } from "../../../../../stores/UserStore";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import {
  FlowInterface,
  StorageOptions,
  Connection,
} from "../../../../../types/interfaces";
import { GlobalEventType } from "../../../../../types/types";
import { FlowEnum, FIELD_TYPE, ICONS } from "../../../../../types/constants";
import { AppSuspense } from "../../../../main/AppSuspense";
import FlowTabHeaderActions from "../../../flow/flow-settings/FlowTabHeaderActions";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
  userStore: UserStore;
}

const Storage: React.FC<Props> = observer(
  ({ t, flowSettingsStore, userStore }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [flowStorage, setFlowStorage] = useState<StorageOptions | undefined>(
      undefined
    );
    const [loadingConnections, setLoadingConnections] = useState(false);
    const [connections, setConnections] = useState<Connection[]>([]);

    useEffect(() => {
      if (
        userStore.currentUserPermissions?.can("read", "connections")
        //TODO: Check if here should be customRepo check for existing flow
        // && flowStorage?.customRepo
      ) {
        setLoadingConnections(true);
        userStore
          .loadAllConnections(true)
          .then((res) => {
            setConnections(res);
          })
          .catch(() => {
            //TODO: discuss about error
          })
          .finally(() => {
            setLoadingConnections(false);
          });
      }
      setFlowStorage({
        ...flowSettingsStore.flow?.storage,
        ...((flowStorage || {}) as StorageOptions),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      flowSettingsStore.flow?.storage?.customRepo,
      userStore.triggerRefetchRepoConnections,
    ]);

    const formattedOptions = useMemo(
      () =>
        connections?.length > 0
          ? connections.map((con) => {
              return {
                key: con.identifier,
                label: con.name,
                icon: ICONS[con.type],
              };
            })
          : [],
      [connections]
    );

    const handleRepositoryInput = (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | SelectChangeEvent<unknown>
    ) => {
      if (!isEditable) {
        return;
      }

      const {
        target: { name, value, type, checked: targetChecked },
      } = event as GlobalEventType;

      const checked = type === FIELD_TYPE.checkbox ? targetChecked : undefined;
      const val = checked !== undefined ? checked : value;
      const repoChecked =
        name === FlowEnum.customRepo ? checked : flowStorage?.customRepo;

      setFlowStorage(
        (prevFlowStorage) =>
          ({
            ...prevFlowStorage,
            ...(repoChecked
              ? { [name]: val }
              : {
                  customRepo: checked as boolean,
                  repoConnection: "",
                }),
          } as StorageOptions)
      );
    };

    //TODO: Keep this for later use
    // const handleDataSettingsInput = (
    //   event: React.ChangeEvent<HTMLInputElement>,
    //   checked?: boolean
    // ) => {
    //   const { name, value } = event.target;

    //   const val = checked !== undefined ? checked : value;

    //   const dataChecked =
    //     name === FlowEnum.dataRetention ? checked : flowStorage?.dataRetention;

    //   setFlowStorage(
    //     (prevFlowStorage) =>
    //       ({
    //         ...prevFlowStorage,
    //         ...(dataChecked
    //           ? { [name]: val }
    //           : {
    //               dataRetention: checked as boolean,
    //               deleteDoc: false,
    //               deleteLocalFile: false,
    //               timeToKeep: 1,
    //             }),
    //       } as StorageOptions)
    //   );
    // };

    const onCancel = () => {
      setFlowStorage(flowSettingsStore.flow?.storage);
      setIsEditable(false);
    };

    const disableSaveButton = useMemo(() => {
      return (
        flowStorage?.customRepo === true &&
        (!flowStorage?.repoConnection || flowStorage?.repoConnection === "")
      );
    }, [flowStorage]);

    return (
      <CoreTabContainer
        t={t}
        title={t(flowSettingsStore.selectedTab || "")}
        extraHeaderActions={
          <FlowTabHeaderActions
            t={t}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            onCancel={onCancel}
            disableSaveButton={disableSaveButton}
            editButtonDisabled={
              loadingConnections || flowSettingsStore.loadingFlowSettings
            }
            form={
              {
                storage: flowStorage,
              } as FlowInterface
            }
          />
        }
      >
        {loadingConnections || flowSettingsStore.loadingFlowSettings ? (
          <AppSuspense />
        ) : (
          <>
            <CoreNotificationBox
              description={t("storage_description")}
              type={NotificationTypes.info}
            />

            {userStore.currentUserPermissions?.can("read", "connections") && (
              <RepositorySettings
                t={t}
                isEditable={isEditable}
                handleInput={handleRepositoryInput}
                flowStorage={flowStorage}
                setFlowStorage={setFlowStorage}
                options={formattedOptions}
                loadingConnections={loadingConnections}
                connections={connections}
              />
            )}

            {/* TODO: Keep this for later use */}
            {/* <DataSettings
              flowSettingsStore={flowSettingsStore}
              t={t}
              isEditable={isEditable}
              handleInput={handleDataSettingsInput}
              flowStorage={flowStorage}
            /> */}
          </>
        )}
      </CoreTabContainer>
    );
  }
);

export default Storage;
