import React, { FC } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { DateRange } from "@mui/lab";

import { DocumentsFilterProps } from "../../../../types/interfaces";
import FilterTag from "./FilterTag";
import FilterManuallyUpdatedTag from "./FilterManuallyUpdatedTag";

interface Props {
  t: TFunction;
  filters: DocumentsFilterProps;
  setFilters: (key: string, value: string | string[] | unknown) => void;
}

const FiltersDisplay: FC<Props> = observer(({ t, filters, setFilters }) => {
  if (
    !filters.name?.length &&
    !filters.date?.startDate &&
    filters?.manuallyUpdated === undefined
  )
    return <></>;

  return (
    <>
      {filters?.manuallyUpdated !== undefined ? (
        <FilterManuallyUpdatedTag
          t={t}
          manuallyUpdated={filters?.manuallyUpdated}
          setFilters={setFilters}
        />
      ) : (
        <></>
      )}
      {filters.date?.startDate && (
        <FilterTag
          t={t}
          filters={filters}
          filtersValues={
            [
              filters.date?.startDate,
              filters.date?.endDate || null,
            ] as unknown as DateRange<Date>
          }
          isDate
          setFilters={setFilters}
        />
      )}

      {filters.name?.map((filter, index) => {
        return (
          <FilterTag
            t={t}
            key={`${filter}-${index}`}
            filtersValues={filter}
            filters={filters}
            setFilters={setFilters}
          />
        );
      })}
    </>
  );
});

export default FiltersDisplay;
