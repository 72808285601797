import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import CoreButton from "../../../../../core/CoreButton";
import { StripeCard } from "../StripeCard";
import { useNotification } from "../../../../../../context/useNotification";
import { useStores } from "../../../../../../stores/StoresProvider";

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "30px",
  },
});

interface Props {
  clientSecret: string;
  onCancelClick: () => void;
}

export const UpdateCreditCard: FC<Props> = ({
  onCancelClick,
  clientSecret,
}) => {
  const { t } = useTranslation("subscription");
  const { t: stripeT, i18n } = useTranslation("stripe-errors");
  const notifier = useNotification();
  const classes = useStyles();
  const { subscriptionStore, userStore } = useStores();

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const [isOpInProgress, setIsOpInProgress] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);

  const handleChange = (event: StripeCardElementChangeEvent) => {
    setIsCardValid(event.complete && !event.empty && !event.error);
  };

  const canReadFlows =
    userStore.currentUserPermissions?.can("read", "flows") ?? false;

  const setCardAsDefault = (paymentMethodId: string) => {
    subscriptionStore
      .updateCustomerDefaultCard(paymentMethodId)
      .then(() => {
        notifier.success(
          t("active_subscription_update_card_drawer_add_success")
        );
        setIsOpInProgress(false);
        onCancelClick();
        void subscriptionStore.loadSubscriptionData(canReadFlows);
      })
      .catch((error: Error) => {
        notifier.error(
          t(
            error?.message ||
              "active_subscription_update_card_drawer_default_card_set_filed"
          )
        );
        setIsOpInProgress(false);
      });
  };

  const handleUpdateCard = () => {
    setIsOpInProgress(true);

    const setupPayload = {
      payment_method: {
        card: elements?.getElement(CardElement) as StripeCardElement,
      },
    };

    try {
      stripe
        ?.confirmCardSetup(clientSecret, setupPayload)
        .then((response) => {
          // Card created successfully to Stripe
          if (
            response?.setupIntent?.status === "succeeded" &&
            response?.setupIntent?.payment_method
          ) {
            // Set new card as default for customer
            setCardAsDefault(response?.setupIntent?.payment_method as string);
          } else {
            // Failed with error code
            const errorCode = response?.["error"]?.code || "default_error";
            const message = i18n.exists(`stripe-errors:${errorCode}`)
              ? stripeT(errorCode)
              : stripeT("default_error");

            notifier.error(message);
            setIsOpInProgress(false);
          }
        })
        .catch((error: Error) => {
          setIsOpInProgress(false);
          notifier.error(error?.message);
        });
    } catch {
      setIsOpInProgress(false);
      notifier.error(
        stripeT("active_subscription_update_card_drawer_no_instance")
      );
    }
  };

  return (
    <>
      <StripeCard
        emptyMessage={t(
          "active_subscription_update_card_drawer_card_mandatory"
        )}
        onChange={handleChange}
        setValidState={(state: boolean) => setIsCardValid(state)}
      />

      <Box className={classes.footer}>
        <CoreButton
          variant="outlined"
          onClick={onCancelClick}
          disabled={isOpInProgress}
        >
          {t("active_subscription_update_card_drawer_cancel_btn")}
        </CoreButton>

        <CoreButton
          variant="contained"
          onClick={handleUpdateCard}
          disabled={!isCardValid || isOpInProgress}
          isLoading={isOpInProgress}
        >
          {t("active_subscription_update_card_drawer_confirm_btn")}
        </CoreButton>
      </Box>
    </>
  );
};
