import React, { FC, memo, useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import DateRangeIcon from "@mui/icons-material/DateRange";

import { useStores } from "../../../../../../../stores/StoresProvider";
import { CoreCard } from "../../../../../../core/CoreCard";
import { SubscriptionUsage } from "../../../../../../../types/interfaces/subscription";
import {
  Divider,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import CoreSelect from "../../../../../../core/CoreSelect";
import { SelectOptionDef } from "../../../../../../../types/interfaces";

const useStyles = makeStyles({
  progressContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  container: {
    margin: "30px 60px",
  },
  currentUsageTitle: {
    marginBottom: "20px",
  },
  noData: {
    opacity: 0.6,
    fontSize: "12px",
  },
  cardFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  timeInterval: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: { marginRight: "9px" },
  selectLabel: { marginRight: "9px", marginLeft: "12px" },
  divider: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  barContainer: {
    height: "200px",
  },
  selectBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "5px",
  },
  paragraph: {
    margin: 0,
    padding: 0,
  },
});

const CURRENT_USAGE_PROPS = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label: (context: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const label = (context.dataset.label as string) || "";
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const value = context.parsed.y as number;
          return value === 0 && label.includes("Estimate")
            ? ""
            : `${label}: ${value}`;
        },
      },
    },
  },
};

const SELECT_TYPES = {
  NAMESPACES: "overview_current_usage_namespaces",
  FLOWS: "overview_current_usage_flows",
};

const Component: FC<{ translate: TFunction }> = ({ translate }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { subscriptionStore, userStore } = useStores();
  const { t } = useTranslation("subscription");

  // Extract select options from Subscription store
  const { namespaces, flows } = subscriptionStore;

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState<SubscriptionUsage>();
  const currentDate = new Date();
  const [start] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).valueOf() /
      1000
  );
  const [end] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).valueOf() / 1000
  );

  // Select states
  const [statisticType, setStatisticType] = useState("cumulative");
  const [selectedNamespaces, setSelectedNamespaces] = useState<string[]>([]);
  const [selectedFlows, setSelectedFlows] = useState<string[]>([]);

  const OPTIONS = useMemo(
    () =>
      [
        {
          key: "cumulative",
          value: "cumulative",
          label: t("overview_current_usage_cumulative_type_label"),
        },
        {
          key: "periodic",
          value: "periodic",
          label: t("overview_current_usage_periodic_type_label"),
        },
      ] as SelectOptionDef[],
    [t]
  );

  // Map select options based on incoming store data
  const NAMESPACES_OPTIONS = useMemo(
    () =>
      namespaces?.map((namespaceItem) => ({
        key: String(namespaceItem?.namespace?.id),
        label: namespaceItem?.namespace?.name,
        value: String(namespaceItem?.namespace?.id),
      })) as SelectOptionDef[],
    [namespaces]
  );

  const FLOWS_OPTIONS = useMemo(
    () =>
      flows?.map((flowItem) => ({
        key: flowItem?.identifier,
        label: flowItem?.name,
        value: flowItem?.identifier,
      })) as SelectOptionDef[],
    [flows]
  );

  const currentUsageProps = useMemo(
    () => ({
      ...CURRENT_USAGE_PROPS,
      scales: {
        y: {
          ticks: { color: theme.palette.text.primary },
          stacked: true,
        },
        x: {
          ticks: { color: theme.palette.text.primary },
          stacked: true,
        },
      },
    }),
    [theme]
  );

  const timeInterval = useMemo(() => {
    const labels = data?.currentUsage?.labels as string[];
    return `${labels?.[0] || ""} - ${labels?.pop() || ""}`;
  }, [data]);

  const formattedData = useMemo(
    () => ({
      ...(data || {}),
      currentUsage: {
        ...(data?.currentUsage || {}),
        datasets:
          data?.currentUsage?.datasets?.map((dataset) => ({
            ...(dataset || {}),
            label: t(dataset?.label) || "-",
          })) || [],
      },
    }),
    [data, t]
  );

  const handleRenderSelect = (
    selected: unknown,
    placeholder: string,
    translationKey: string
  ): React.ReactNode => {
    const selectedItems = selected as string[];

    if (selectedItems.length === 0) {
      return (
        <Typography className={classes.paragraph}>{t(placeholder)}</Typography>
      );
    }

    return `${selectedItems?.length} ${t(translationKey)}`;
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriptionStore,
    start,
    end,
    statisticType,
    selectedNamespaces,
    selectedFlows,
  ]);

  const loadData = () => {
    setIsDataLoading(true);

    subscriptionStore
      .getOverviewData(
        start,
        end,
        statisticType,
        selectedNamespaces,
        selectedFlows
      )
      .then((usageData) => {
        setData(usageData);
        setIsDataLoading(false);
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  };

  return (
    <CoreCard className={classes.container}>
      <Typography className={classes.currentUsageTitle}>
        {translate("overview_current_usage_title")}
      </Typography>

      <Box className={classes.barContainer} style={{ position: "relative" }}>
        {data?.currentUsage && (
          <Bar
            options={currentUsageProps}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
            data={formattedData?.currentUsage as unknown as any}
          />
        )}
        {isDataLoading && (
          <Box className={classes.progressContainer}>
            <CircularProgress />
          </Box>
        )}
        {!isDataLoading && (!data?.currentUsage || !data) && (
          <Typography className={classes.noData}>
            {translate("overview_current_usage_no_data")}
          </Typography>
        )}
      </Box>

      <Divider className={classes.divider} />

      <Box className={classes.cardFooter}>
        <Box className={classes.timeInterval}>
          <DateRangeIcon className={classes.label} />
          <Typography variant="body2">{timeInterval}</Typography>
        </Box>

        <Box className={classes.selectBox}>
          <Typography variant="body2" className={classes.selectLabel}>
            {t("overview_current_usage_type")}
          </Typography>
          <CoreSelect
            value={statisticType}
            options={OPTIONS}
            onChange={(event: SelectChangeEvent<unknown>) =>
              setStatisticType(event.target.value as string)
            }
            style={{ minWidth: "300px" }}
          />
        </Box>
        <Box className={classes.selectBox}>
          <Typography variant="body2" className={classes.selectLabel}>
            {t("overview_current_usage_namespace")}
          </Typography>
          <CoreSelect
            value={selectedNamespaces}
            options={NAMESPACES_OPTIONS}
            onChange={(event: SelectChangeEvent<unknown>) => {
              setSelectedNamespaces(event.target.value as string[]);
            }}
            isMultipleSelect
            placeholder={t("namespacesPlaceholder")}
            hasCheckbox
            style={{ minWidth: "300px" }}
            renderValue={(selected) =>
              handleRenderSelect(
                selected,
                SELECT_TYPES.NAMESPACES,
                "selectedNamespaces"
              )
            }
          />
        </Box>
        {userStore.currentUserPermissions?.can("read", "flows") && (
          <Box className={classes.selectBox}>
            <Typography variant="body2" className={classes.selectLabel}>
              {t("overview_current_usage_flows")}
            </Typography>
            <CoreSelect
              value={selectedFlows}
              options={FLOWS_OPTIONS}
              onChange={(event: SelectChangeEvent<unknown>) =>
                setSelectedFlows(event.target.value as string[])
              }
              isMultipleSelect
              placeholder={t("flowsPlaceholder")}
              hasCheckbox
              renderValue={(selected) =>
                handleRenderSelect(
                  selected,
                  SELECT_TYPES.FLOWS,
                  "selectedFlows"
                )
              }
              style={{ minWidth: "300px" }}
            />
          </Box>
        )}
      </Box>
    </CoreCard>
  );
};

ChartJS.register(...registerables);

export const SubscriptionOverview = memo(Component);
