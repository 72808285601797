import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"; // Changed import
import { useTheme, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import SummaryCard from "./components/SummaryCard";
import CoreButton from "../../../core/CoreButton";
import { AppSuspense } from "../../../main/AppSuspense";
import { appRoutes, authRoutes } from "../../../../configs/routes";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import Languages from "../../../main/sidebar/account/Languages";

const ReviewPlan = () => {
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const history = useHistory(); // Changed from useHistory
  const { t, ready } = useTranslation("landingPages");
  const { landingStore } = useStores();
  const { userStore } = useStores();

  const notification = useNotification();

  const useStyles = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    container: {
      backgroundColor: theme.palette?.background.paper,
      width: "100vw",
      height: "100vh",
    },
    spacing: {
      paddingBottom: 32,
    },
    buttonStyle: {
      borderColor: theme.palette?.buttons.main,
    },
    goBack: {
      borderColor: theme.palette?.buttons.main,
      marginRight: 20,
    },
  });

  const classes = useStyles();

  const handleRedirect = () => {
    setIsLoading(true);

    landingStore
      .createUserAndContext()
      .then(() => {
        setIsLoading(false);
        if (landingStore.userRegistration.isGoogleLogin) {
          history.push(appRoutes.Home()); // Use navigate function
          notification.success(t("userCreatedGoogle"));
          landingStore.resetFields();
        } else if (userStore.user?.id) {
          history.push(authRoutes.Namespaces()); // Use navigate function
          notification.success(t("namespaceCreated"));
          landingStore.resetFields();
        } else {
          landingStore.setUserRegistration({
            isCreateSuccessfully: true,
          });
          history.push(authRoutes.SuccessfullyRegister()); // Use navigate function
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "errorCreatingUser"));
        setIsLoading(false);
      });
  };

  const handleGoBack = () => {
    landingStore.goBack();
  };

  if (!ready || isLoading) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Grid
        container
        className={classes.container}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.spacing}>
          <SummaryCard t={t} />
        </Grid>

        <Grid item>
          <CoreButton className={classes.goBack} onClick={handleGoBack}>
            {t("goBack")}
          </CoreButton>

          <CoreButton className={classes.buttonStyle} onClick={handleRedirect}>
            {t("reviewPlanCreateEnvironment")}
          </CoreButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewPlan;
