import React from "react";

import Box from "@mui/material/Box";

import { createStyles, makeStyles } from "@mui/styles";
import { TFunction } from "i18next";

import { CheckboxRenderer } from "../../formFields/CheckboxRenderer";
import { InputRenderer } from "../../formFields/InputRenderer";
import { SelectRenderer } from "../../formFields/SelectRenderer";
import { SwitchRenderer } from "../../formFields/SwitchRenderer";
import { GoogleDriveRenderer } from "../../formFields/GoogleDriveRenderer";
import { OneDriveRenderer } from "../../formFields/OneDriveRenderer";

import { FIELD_TYPE, OTHER_ENUMS } from "../../../types/constants";

import { ConnectionField, FormData } from "../../../types/interfaces";
import JsonEditorRenderer from "../../formFields/JsonEditorRenderer";
import { DropBoxRenderer } from "../../formFields/DropBoxRenderer";
import { AutocompleteRenderer } from "../../formFields/AutocompleteRenderer";
import { FileUploadRenderer } from "../../formFields/FileUploadRenderer";
import AdvancedExportRenderer from "../../formFields/AdvancedExportRenderer";
import FileNameRenderer from "../../formFields/FileNameRenderer";
import DynamicPathRenderer from "../../formFields/DynamicPathRenderer";
import { AutocompleteNoAddRenderer } from "../../formFields/AutocompleteNoAddRenderer";
import { FieldSelectRenderer } from "../../formFields/FieldSelect";
import { TextareaRenderer } from "../../formFields/TextareaRenderer";
import NodeInputRenderer from "../../formFields/NodeInputRenderer";
import NodeOutputRenderer from "../../formFields/NodeOutputRenderer";
import NodeListRenderer from "../../formFields/NodeListRenderer";
import ExecutionSchemaRenderer from "../../formFields/ExecutionSchemaRenderer";
import CodeRenderer from "../../formFields/CodeRenderer";
import ConditionalNodeRenderer from "../../formFields/conditional/ConditionalNodeRenderer";

interface Props {
  config: ConnectionField[];
  translation: TFunction;
  data: FormData;
  onChange: (value: FormData) => void;
  fieldSpacing?: string;
  errors?: FormData;
  id?: string;
  disableForm?: boolean;
  forConnections?: boolean;
}

const COMPONENTS = {
  [FIELD_TYPE.input]: InputRenderer,
  [FIELD_TYPE.number]: InputRenderer,
  [FIELD_TYPE.password]: InputRenderer,
  [FIELD_TYPE.textarea]: TextareaRenderer,
  [FIELD_TYPE.select]: SelectRenderer,
  [FIELD_TYPE.checkbox]: CheckboxRenderer,
  [FIELD_TYPE.switch]: SwitchRenderer,
  [FIELD_TYPE.json]: JsonEditorRenderer,
  [FIELD_TYPE.googleDriveConnection]: GoogleDriveRenderer,
  [FIELD_TYPE.oneDriveConnection]: OneDriveRenderer,
  [FIELD_TYPE.dropboxConnection]: DropBoxRenderer,
  [FIELD_TYPE.autocomplete]: AutocompleteRenderer,
  [FIELD_TYPE.autocompleteNoAdd]: AutocompleteNoAddRenderer,
  [FIELD_TYPE.fileUpload]: FileUploadRenderer,
  [FIELD_TYPE.exportFields]: AdvancedExportRenderer,
  [FIELD_TYPE.fileName]: FileNameRenderer,
  [FIELD_TYPE.filePath]: DynamicPathRenderer,
  [FIELD_TYPE.fields]: FieldSelectRenderer,
  [FIELD_TYPE.code]: CodeRenderer,
  [FIELD_TYPE.nodeInput]: NodeInputRenderer,
  [FIELD_TYPE.nodeOutput]: NodeOutputRenderer,
  [FIELD_TYPE.list]: NodeListRenderer,
  [FIELD_TYPE.inputList]: ExecutionSchemaRenderer,
  [FIELD_TYPE.conditional]: ConditionalNodeRenderer,
};

const validateFieldValue = (
  field: ConnectionField,
  dataValue: boolean | number | string | undefined | unknown
): string | boolean | number => {
  switch (field.ftype) {
    case FIELD_TYPE.checkbox:
      if (typeof dataValue !== undefined && dataValue !== null) {
        return dataValue as boolean;
      }

      if (typeof field.value !== undefined && field.value !== null) {
        return field.value;
      }

      return false;
    default:
      return (dataValue || "") as string | number;
  }
};

export const ConnectionConfigRenderer: React.FC<Props> = ({
  config,
  translation,
  data,
  onChange,
  fieldSpacing = "1rem",
  errors = {},
  id,
  disableForm,
  forConnections,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      formContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
      },
      box: {
        marginTop: fieldSpacing,
      },
    })
  );

  const classes = useStyles();

  // Display only children linked with parentValue
  const filterChildren = (parent: ConnectionField) => {
    const parentValue = data[parent.key] ?? parent.value;

    return parent.fields.filter((item) => {
      if (!item.parentValue) return true; // Return all fields with no parentValue link

      return parentValue === item.parentValue; // Filter by parentValue
    });
  };

  return (
    <Box className={classes.formContainer}>
      {config.map((field, index) => {
        const FieldRenderer = COMPONENTS[field.ftype];
        if (!FieldRenderer) return null;

        const fieldValue = validateFieldValue(field, data[field.key]);

        return (
          <Box key={`${field.key}-${index}`} className={classes.box}>
            <FieldRenderer
              formData={data}
              field={{ ...field, type: field.ftype }}
              value={fieldValue}
              translation={translation}
              onChange={onChange}
              errorText={errors[field.key] as string}
              type={field.ftype as string}
              description={field.description}
              tooltipLocation={field.tooltipLocation}
              otherProps={{
                disabled:
                  !!(id && field.key === OTHER_ENUMS.type) || disableForm,
              }}
              forConnections={forConnections}
            />

            {field.fields && field.fields.length > 0 && (
              <ConnectionConfigRenderer
                config={filterChildren(field)}
                translation={translation}
                data={data}
                onChange={onChange}
                errors={errors}
                disableForm={disableForm}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
