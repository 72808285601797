import React, { useState, useEffect } from "react";
import { TFunction } from "i18next";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import CoreInput from "../../core/CoreInput";
import CoreDrawer from "../../core/CoreDrawer";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import {
  FIELD_TYPE,
  LINK_LIFETIME_OPTIONS_GB,
  LINK_LIFETIME_OPTIONS_RO,
} from "../../../types/constants";
import CoreButton from "../../core/CoreButton";
import { CopyToClipboardAction } from "./CopyToClipboardAction";

import { Ability, FormData, FormField } from "../../../types/interfaces";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import NamespacePermissions from "./NamespacePermissions";

interface GenerateInviteLinkProps {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "12px",
  },
});

const GenerateInviteLink: React.FC<GenerateInviteLinkProps> = ({
  isOpen,
  t,
  onClose,
}) => {
  const notification = useNotification();
  const [fields, setFields] = useState<FormData>({});
  const [link, setLink] = useState("");
  const [generatingProgress, setGeneratingProgress] = useState(false);
  const [permissions, setPermissions] = useState<Ability[]>([]);

  const { userStore, mainStore } = useStores();
  const apolloClient = useApolloClient();
  const classes = useStyles();

  //Clear fields on after closing drawer
  useEffect(() => {
    if (!isOpen) {
      setFields({});
      setPermissions([]);
      setLink("");
      setGeneratingProgress(false);
    }
  }, [isOpen]);

  const generateLink = () => {
    if (permissions.length === 0) {
      notification.error(t("onePermissionRequired"));
      return;
    }

    setGeneratingProgress(true);
    userStore
      .generateInvitationLink(apolloClient, {
        ...fields,
        perms: permissions,
      })
      .then((link) => {
        setGeneratingProgress(false);
        setLink(link);
      })
      .catch((error: Error) => {
        setGeneratingProgress(false);
        notification.error(t(error?.message));
      });
  };

  const handleInputChange = (value: FormData) =>
    setFields({ ...fields, ...value });

  const formFields: FormField[] = [
    {
      key: "lifetime",
      type: FIELD_TYPE.select,
      isMandatory: true,
      props: {
        title: t("linkLifetime"),
        value: fields?.lifetime ?? "",
        disabled: generatingProgress,
      },
      options:
        mainStore.languageFlag === "GB"
          ? LINK_LIFETIME_OPTIONS_GB
          : LINK_LIFETIME_OPTIONS_RO,
      translationKey: "linkLifetime",
    },
  ];

  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t("generateInviteLink")}
    >
      <CoreFormRenderer
        fields={formFields}
        data={fields}
        onChange={handleInputChange}
        translation={t}
      />

      <Box className={classes.optionsContainer}>
        <NamespacePermissions
          t={t}
          permissions={permissions}
          setPermissions={setPermissions}
          checkboxDisabled={generatingProgress}
        />
      </Box>

      <CoreButton
        onClick={generateLink}
        style={{ marginTop: 15, marginBottom: 15 }}
        disabled={
          generatingProgress ||
          Object.keys(permissions).length === 0 ||
          !fields?.lifetime
        }
        isLoading={generatingProgress}
      >
        <span>{t("generateInviteLink")}</span>
      </CoreButton>

      <CoreInput
        readOnly
        endAdornment={
          <CopyToClipboardAction t={t} text={link} isDisabled={link === ""} />
        }
        value={link}
      />
    </CoreDrawer>
  );
};

export default GenerateInviteLink;
