import React from "react";
import { TFunction } from "i18next";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import { makeStyles } from "@mui/styles";
import { useTheme, Box, Typography, Paper, alpha } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";

import CoreButton from "../../../core/CoreButton";

interface Props {
  t: TFunction;
  getRootProps: () => DropzoneRootProps;
  getInputProps: () => DropzoneInputProps;
  fileName: string | null;
  isOnDrop: boolean;
  open: () => void;
  isJsonValid: boolean;
}

const useStyles = makeStyles({
  uploadButton: {
    marginTop: "-20px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  uploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50%",
    margin: "0 20px 0 20px",
    backgroundColor: "transparent",
  },
  dragAndDropBox: {
    display: "flex",
    alignItems: "center",
    border: "3px dashed",
    justifyContent: "center",
    flexDirection: "column",
    opacity: "0.4",
    width: "100%",
    height: "100%",
    padding: "20px",
    margin: "20px",
  },
  dropIcon: {
    fontSize: "8rem",
  },

  boxSupportedFiles: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    opacity: "0.4",
    marginTop: "20px",
  },
  supportedFiles: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  boxDnDText: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btnUpload: {
    marginTop: "auto",
    alignSelf: "flex-end",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
  },
  infoBox: {
    display: "flex",
    padding: "10px 15px 0 15px",
  },
  jsonIcon: {
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconAndFileName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "10px",
  },
});

const ImportFlowDragAndDropZone: React.FC<Props> = ({
  t,
  getInputProps,
  getRootProps,
  fileName,
  isOnDrop,
  open,
  isJsonValid,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const themeClasses = makeStyles({
    paper: {
      height: "250px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
      border: "1px solid",
      borderColor: alpha(theme.palette.divider, 0.2),
    },
  })();

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />

      <Paper className={themeClasses.paper} elevation={0}>
        {!isJsonValid ? (
          <Box className={classes.contentContainer}>
            {isOnDrop ? (
              <Box className={classes.dragAndDropBox}>
                <CloudUploadOutlinedIcon className={classes.dropIcon} />
                <Typography variant="caption">{t("dropDocMessage")}</Typography>
              </Box>
            ) : (
              <Box className={classes.uploadBox}>
                <Box className={classes.boxDnDText}>
                  <Typography variant="h6">{t("dragAndDropFile")}</Typography>
                  <Typography variant="h6">{t("or")}</Typography>
                  <CoreButton
                    className={classes.btnUpload}
                    onClick={open}
                    variant="outlined"
                  >
                    {t("browseFile")}
                  </CoreButton>
                </Box>
                <Box className={classes.boxSupportedFiles}>
                  <Typography
                    variant="caption"
                    className={classes.supportedFiles}
                  >
                    {t("supportedFile")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box className={classes.uploadBox}>
            <Box className={classes.boxDnDText}>
              <Box className={classes.iconAndFileName}>
                <Box className={classes.jsonIcon}>
                  <DataObjectIcon />
                </Box>
                <Typography>{fileName || ""}</Typography>
              </Box>

              <CoreButton
                className={classes.btnUpload}
                onClick={open}
                variant="outlined"
              >
                {t("changeFile")}
              </CoreButton>
            </Box>
            <Box className={classes.boxSupportedFiles}>
              <Typography variant="caption" className={classes.supportedFiles}>
                {t("supportedFile")}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ImportFlowDragAndDropZone;
