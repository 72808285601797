import React from "react";
import CoreInput from "../core/CoreInput";
import { Box, Typography, useTheme } from "@mui/material";
import { TFunction } from "i18next";
import { makeStyles } from "@mui/styles";

interface Props {
  translation: TFunction;
  errorText?: string;
}

export const CorePlaceholderProvider: React.FC<Props> = ({
  translation,
  errorText,
}) => {
  const theme = useTheme();

  const classes = makeStyles({
    mainContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginRight: "5px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
  })();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Typography>
          {translation("directory")}
          <span className={classes.asterisk}>*</span>
        </Typography>
        <CoreInput
          value={translation("providerNotConfiguredProperly")}
          readOnly
          fullWidth
          error={!!errorText}
          errorText={errorText}
        />
      </Box>
    </Box>
  );
};
