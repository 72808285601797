import React from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { useStores } from "../../../../stores/StoresProvider";
import CoreButton from "../../../core/CoreButton";

interface Props {
  translation: TFunction;
  buttonsClass: string;
  handleCancel: () => void;
  handleSave: () => void;
  handleReject: () => void;
  handleSkip: () => void;
}

const EditModeButtons: React.FC<Props> = observer(
  ({
    translation,
    buttonsClass,
    handleCancel,
    handleSave,
    handleReject,
    handleSkip,
  }) => {
    const { documentStore } = useStores();

    return (
      <>
        <CoreButton
          isLoading={documentStore.updatingPage}
          disabled={documentStore.updatingPage}
          onClick={handleSave}
          variant="contained"
          containerClass={buttonsClass}
        >
          {translation("saveDocument_btn")}
        </CoreButton>

        <CoreButton
          disabled={documentStore.updatingPage}
          variant="outlined"
          containerClass={buttonsClass}
          onClick={handleReject}
        >
          {translation("rejectDocument_btn")}
        </CoreButton>

        <CoreButton
          disabled={documentStore.updatingPage}
          variant="outlined"
          containerClass={buttonsClass}
          onClick={handleCancel}
        >
          {translation("cancelValidation_btn")}
        </CoreButton>
        <CoreButton
          disabled={documentStore.updatingPage}
          onClick={handleSkip}
          variant="outlined"
          containerClass={buttonsClass}
        >
          {translation("skip_btn")}
        </CoreButton>
      </>
    );
  }
);

export default EditModeButtons;
