import Box from "@mui/material/Box";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { AppSuspense } from "../../main/AppSuspense";
import CoreButton from "../../core/CoreButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  codeError: {
    fontSize: "50px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "20px",
  },
  backButton: {
    marginTop: "20px",
  },
});

// TODO: Redesign component

interface Props {
  page?: string;
}

const AccessDenied: FC<Props> = ({ page }) => {
  const { t, ready } = useTranslation("core");
  const classes = useStyles();
  const history = useHistory();
  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.codeError}>
        {t("notAuthorizedStatusCode")}
      </Typography>
      <Typography className={classes.title}>
        {t("accessDeniedTitle")}
      </Typography>
      <Typography className={classes.subtitle}>
        {t("accessDeniedSubtitle")}
      </Typography>
      {page && <Typography className={classes.subtitle}>{page}</Typography>}
      <CoreButton
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
        className={classes.backButton}
      >
        {t("accessDeniedGoBackButton")}
      </CoreButton>
    </Box>
  );
};

export default AccessDenied;
