import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { MenuList } from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Portal from "./Portal";
import {
  DocumentLineItemLine,
  DocumentLineItemRow,
  DocumentZoneBox,
} from "../../../../../../../types/interfaces";
import { DocumentStore } from "../../../../../../../stores/DocumentStore";
import CanvasHelper from "../../../../../../../helper/canvas/canvasHelper";

interface Props {
  open: boolean;
  onClose: () => void;
  currentLine: DocumentLineItemRow | null;
  documentStore: DocumentStore;
  tableCoords: DocumentZoneBox | null;
  anchorReference: "anchorPosition" | "anchorEl";
  anchorPosition:
    | {
        top: number;
        left: number;
      }
    | undefined;
}

const useStyles = makeStyles({
  rightClickMenuText: {
    marginLeft: 10,
  },
});

const RightClickMenuRow: FC<Props> = observer(
  ({
    onClose,
    open,
    anchorReference,
    anchorPosition,
    currentLine,
    documentStore,
    tableCoords,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation("documents");
    const rowY = currentLine?.box.y ?? 0;
    const rowHeight = currentLine?.box.height ?? 0;
    const getLine = (isUpper: boolean) => {
      const { box } = currentLine ?? {
        box: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
      };
      const { x, y, width, height } = box;

      if (isUpper)
        return [
          [x, y],
          [x + width, y],
        ] as DocumentLineItemLine;

      return [
        [x, y + height],
        [x + width, y + height],
      ] as DocumentLineItemLine;
    };

    const handleRemoveUpperLine = () => {
      documentStore.initLineItemRowLine(
        documentStore.focusedLineItem?.type ?? "",
        getLine(true),
        true
      );
    };

    const handleRemoveBottomLine = () => {
      documentStore.initLineItemRowLine(
        documentStore.focusedLineItem?.type ?? "",
        getLine(false),
        true
      );
    };

    const handleIgnoreRow = () => {
      const rowY = currentLine?.box.y ?? 0;
      documentStore.ignoreLineItemRow(rowY + 10);
    };

    const isRowIgnored = documentStore.isRowIgnored(rowY + 10);

    const isBottomRow = useMemo(() => {
      return CanvasHelper.isDifferenceLessThan2px(
        rowY + rowHeight,
        (tableCoords?.y ?? 0) + (tableCoords?.height ?? 0)
      );
    }, [rowY, rowHeight, tableCoords?.y, tableCoords?.height]);

    const isTopRow = useMemo(() => {
      return CanvasHelper.isDifferenceLessThan2px(rowY, tableCoords?.y ?? 0);
    }, [rowY, tableCoords?.y]);

    const MenuOptions = [
      {
        name: "mergeTopRow",
        icon: <NorthIcon fontSize="small" />,
        action: handleRemoveUpperLine,
        disabled: isTopRow,
      },
      {
        name: "mergeBottomRow",
        icon: <SouthIcon fontSize="small" />,
        action: handleRemoveBottomLine,
        disabled: isBottomRow,
      },

      {
        name: "ignoreCurrentRow",
        icon: <VisibilityOffIcon fontSize="small" />,
        action: handleIgnoreRow,
        disabled: isRowIgnored,
      },
      {
        name: "enableCurrentRow",
        icon: <VisibilityIcon fontSize="small" />,
        action: handleIgnoreRow,
        disabled: !isRowIgnored,
      },
    ];

    return (
      <Portal>
        <Menu
          open={open}
          onClose={onClose}
          anchorReference={anchorReference}
          anchorPosition={anchorPosition}
        >
          <MenuList>
            {MenuOptions.map((option) =>
              option.disabled ? null : (
                <MenuItem
                  onClick={() => {
                    option.action();
                    onClose();
                  }}
                  key={option.name}
                >
                  {option.icon}
                  <span className={classes.rightClickMenuText}>
                    {t(option.name)}
                  </span>
                </MenuItem>
              )
            )}
          </MenuList>
        </Menu>
      </Portal>
    );
  }
);

export default RightClickMenuRow;
