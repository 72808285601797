import { TFunction } from "i18next";
import React from "react";

import CoreButton from "../../../core/CoreButton";
import { useStores } from "../../../../stores/StoresProvider";
import { DOCUMENT_STATUSES } from "../../../../types/constants";

interface Props {
  handleCancel: () => void;
  buttonsClass: string;
  translation: TFunction;
  handleReject: () => void;
}

const ViewModeButtons: React.FC<Props> = ({
  buttonsClass,
  handleCancel,
  translation,
  handleReject,
}) => {
  const { documentStore } = useStores();

  return (
    <>
      {documentStore.document?.status?.type === DOCUMENT_STATUSES.error && (
        <CoreButton
          disabled={documentStore.updatingPage}
          variant="outlined"
          containerClass={buttonsClass}
          onClick={handleReject}
        >
          {translation("rejectDocument_btn")}
        </CoreButton>
      )}
      <CoreButton
        onClick={handleCancel}
        variant="outlined"
        containerClass={buttonsClass}
      >
        {translation("close_btn")}
      </CoreButton>
    </>
  );
};

export default ViewModeButtons;
