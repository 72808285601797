import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import CoreDrawer from "../../../../core/CoreDrawer";
import ApprovalRulesDisplay from "./ApprovalRulesDisplay";
import RuleConfig from "../../../../formFields/conditional/RuleConfig";
import { AssetConfiguration } from "../../../../../types/interfaces";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  onAdd: (value: { [key: string]: unknown }) => void;
  onEdit: (value: { [key: string]: unknown }) => void;
  list: AssetConfiguration[];
  selectedItemPosition: number | undefined;
  setSelectedItemPosition: (value: number | undefined) => void;
  isOnConfig: boolean;
  setIsOnConfig: (isConfig: boolean) => void;
}

const RulesDrawer: React.FC<Props> = observer(
  ({
    t,
    isOpen,
    onClose,
    onAdd,
    onEdit,
    list,
    selectedItemPosition,
    setSelectedItemPosition,
    isOnConfig,
    setIsOnConfig,
  }) => {
    const [ruleCategoryKey, setRuleCategoryKey] = useState<string>("");

    const onSelectRule = (key: string) => {
      setRuleCategoryKey(key);
      setIsOnConfig(true);
    };

    const handleOnBack = () => {
      if (isOnConfig) {
        if (selectedItemPosition !== undefined) {
          onClose();
        } else {
          setIsOnConfig(false);
        }
      } else {
        onClose();
      }

      setRuleCategoryKey("");
    };

    useEffect(() => {
      if (selectedItemPosition !== undefined && selectedItemPosition > -1) {
        setRuleCategoryKey(
          (list as unknown as AssetConfiguration[])?.[selectedItemPosition]?.key
        );
      }
    }, [selectedItemPosition, list]);

    const onCloseConfig = () => {
      setRuleCategoryKey("");
      if (selectedItemPosition !== undefined) {
        setSelectedItemPosition(undefined);
      }
      onClose();
    };

    useEffect(() => {
      if (!isOpen) {
        if (ruleCategoryKey) setRuleCategoryKey("");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleDrawerTitle = useMemo(() => {
      return isOnConfig
        ? selectedItemPosition !== undefined
          ? t("editRuleTitle")
          : t("addRuleTitle")
        : t("rulesList");
    }, [t, selectedItemPosition, isOnConfig]);

    return (
      <CoreDrawer
        title={handleDrawerTitle}
        isOpen={isOpen}
        onClose={onClose}
        hasBackButton
        onBack={handleOnBack}
      >
        {!isOnConfig ? (
          <ApprovalRulesDisplay t={t} onSelect={onSelectRule} />
        ) : (
          <RuleConfig
            t={t}
            assetCategoryKey={ruleCategoryKey}
            closeDrawer={onCloseConfig}
            onAdd={onAdd}
            onEdit={onEdit}
            list={list}
            selectedItemPosition={selectedItemPosition}
          />
        )}
      </CoreDrawer>
    );
  }
);
export default RulesDrawer;
