import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";
import { TFunction } from "i18next";
import { CardActions } from "../../types/interfaces";
import CoreTooltip from "./CoreTooltip";

interface Props {
  listActions: CardActions[];
  t: TFunction;
  identifier?: string[] | string;
  disabled?: boolean;
}

const CoreActionsFlow: FC<Props> = ({
  listActions,
  t,
  identifier,
  disabled,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    actionsBox: {
      marginLeft: "10px",
    },
    label: {
      textAlign: "center",
      color: disabled
        ? theme.palette.text.disabled
        : theme.palette.text.primary,
    },
    tooltipWithLabel: {
      marginBottom: "0px",
    },
    tooltipWithoutLabel: {
      marginBottom: "20px",
    },
  });

  const classes = useStyles();

  const handleOnClick = (func: (args: string[] | string) => void) => {
    func(identifier ?? []);
  };
  return (
    <>
      {listActions.map((item, index) => (
        <Box key={index} className={classes.actionsBox}>
          <CoreTooltip
            title={(item.tooltip && t(item.tooltip)) || ""}
            className={
              item.label
                ? classes.tooltipWithLabel
                : classes.tooltipWithoutLabel
            }
          >
            <span>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(item.onClick);
                }}
                disabled={disabled}
              >
                {item.icon as unknown as React.ReactElement}
              </IconButton>
            </span>
          </CoreTooltip>

          <Typography fontSize={"small"} className={classes.label}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default CoreActionsFlow;
