// Library imports
import React from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";

// Sidebar icons
import DebuggingIcon from "@mui/icons-material/BugReport";
import FlowsIcon from "@mui/icons-material/AutoAwesomeMotion";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
//FIXME: Keep for later use
// import QAIcon from "@mui/icons-material/AssignmentTurnedIn";
// import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";

// Material components
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Material helpers
import { alpha, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Internal imports
import { useStores } from "../../../stores/StoresProvider";
import { appRoutes } from "../../../configs/routes";
import CoreTooltip from "../../core/CoreTooltip";
import { THEME_MODES } from "../../../types/constants";
import FlowHelper from "../../../helper/flowHelper";

interface Props {
  t: TFunction;
}

interface MenuItemProps {
  label: string;
  target: string;
  icon: JSX.Element;
  badgeContent?: string;
  hasChildren?: boolean;
  lastItem?: boolean;
  isExact?: boolean;
  abilityKey?: string;
  acceptedTargets?: string[];
}

const MenuItem: React.FC<MenuItemProps> = observer(
  ({
    label,
    icon,
    target,
    badgeContent,
    hasChildren = false,
    lastItem = false,
    isExact,
    abilityKey,
    acceptedTargets,
  }) => {
    const { mainStore, userStore, flowStore } = useStores();
    const { pathname } = useLocation();

    const theme = useTheme();

    const { expandedSidebar, currentTheme } = mainStore;

    const classes = makeStyles({
      listItemIcon: {
        minWidth: 0,
        marginRight: expandedSidebar ? "10px" : "auto",
        justifyContent: "center",
        "&.MuiListItemIcon-root": {
          color: alpha(theme.palette.highlight.light, 0.5),
        },
      },
      listItemButton: {
        height: "32px",
        justifyContent: expandedSidebar ? "initial" : "center",
        margin: "0 10px",
        "&.Mui-selected": {
          color: theme.palette.highlight.main,
          "& .MuiListItemIcon-root": {
            color: theme.palette.highlight.main,
          },
          "& .MuiTypography-root": {
            color: theme.palette.highlight.main,
          },
        },
        "&:hover": {
          backgroundColor:
            currentTheme === THEME_MODES.dark
              ? theme.palette.canvas.light
              : alpha(theme.palette.surface.dark, 0.4),
        },
      },
      listItemText: {
        display: expandedSidebar ? undefined : "none",
      },
      listItem: {
        padding: 0,
        display: "block",
        marginBottom: "10px",
      },
      link: { textDecoration: "none", color: "inherit" },
      lastListItem: {
        padding: 0,
        display: "block",
        marginTop: "auto",
        marginBottom: "20px",
      },
      badge: {
        backgroundColor: theme.palette.highlight.main,
        color: theme.palette.highlight.contrastText,
        margin: "0px 8px",
        display: expandedSidebar ? undefined : "none",
      },
    })();

    const itemClassName = lastItem ? classes.lastListItem : classes.listItem;

    const match = useRouteMatch(target);

    const isActive =
      !(
        (pathname === appRoutes.Home() &&
          userStore.isHomeChatGptOpened === true) ||
        !match ||
        (isExact && !match.isExact)
      ) ||
      !!(
        acceptedTargets && acceptedTargets?.some((item) => item === pathname)
      ) ||
      (userStore.isHomeChatGptOpened === true &&
        target === appRoutes.ChatGpt());

    if (abilityKey) {
      if (abilityKey === "qa") {
        if (
          userStore.currentUserPermissions?.cannot("validate", abilityKey) &&
          userStore.currentUserPermissions?.cannot("assign", abilityKey)
        ) {
          return null;
        }
      } else if (userStore.currentUserPermissions?.cannot("read", abilityKey)) {
        return null;
      }
    }

    return (
      <ListItem className={itemClassName}>
        <NavLink className={classes.link} to={target}>
          <CoreTooltip title={expandedSidebar ? "" : label} placement="right">
            <ListItemButton
              onClick={() => {
                if (target === appRoutes.Flows())
                  FlowHelper.handleExpandCardFlow(flowStore, "", false);
                if (target === appRoutes.Home()) {
                  userStore.setIsHomeChatGptOpened(false);
                }
              }}
              className={`${classes.listItemButton}`}
              selected={isActive}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {icon}
              </ListItemIcon>
              <ListItemText primary={label} className={classes.listItemText} />
              {badgeContent && (
                <Box>
                  <Badge
                    badgeContent={badgeContent}
                    classes={{ badge: classes.badge }}
                  />
                </Box>
              )}
              {hasChildren && expandedSidebar && <ChevronRightIcon />}
            </ListItemButton>
          </CoreTooltip>
        </NavLink>
      </ListItem>
    );
  }
);

const SidebarButtons: React.FC<Props> = observer(({ t }) => {
  //FIXME: Keep for later use
  // const refreshQaCount = () => {
  //   if (userStore.currentUserPermissions?.cannot("validate", "qa")) return;
  //   qaStore.loadQADocumentsCount().catch(() => {
  //     notification.error(t("loadQADocumentsCountError"));
  //   });
  // };

  // useEffect(() => {
  //   refreshQaCount();

  //   history.listen(() => {
  //     refreshQaCount();
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const menuItems = [
    {
      target: appRoutes.Home(),
      label: t("home"),
      icon: <HomeIcon />,
      isExact: true,
    },
    {
      target: appRoutes.ChatGpt(),
      label: t("chat"),
      icon: <ChatIcon />,
      isExact: true,
    },
    {
      target: appRoutes.Flows(),
      label: t("flows"),
      icon: <FlowsIcon />,
      hasChildren: true,
      abilityKey: "flows",
    },
    {
      target: appRoutes.ContextEvents(),
      label: t("activityLog"),
      icon: <DebuggingIcon />,
      abilityKey: "activityLogs",
    },
    //FIXME: Keep for later use
    // {
    //   target: userStore.currentUserPermissions?.can("validate", "qa")
    //     ? appRoutes.QaDocuments()
    //     : appRoutes.QA(),
    //   acceptedTargets: [appRoutes.QaDocuments(), appRoutes.QA()],
    //   label: t("qa"),
    //   icon: <QAIcon />,
    //   badgeContent: (currentQACount || 0).toString(),
    //   hasChildren: true,
    //   abilityKey: "qa",
    // },
    // {
    //   target: appRoutes.Statistics(),
    //   label: t("statistics"),
    //   icon: <LeaderboardIcon />,
    //   hasChildren: true,
    //   abilityKey: "statistics",
    // },
    {
      target: appRoutes.UserAccount(),
      acceptedTargets: [
        appRoutes.UserAccount(),
        appRoutes.Team(),
        appRoutes.AccountBilling(),
        appRoutes.SubscriptionPlanUpgrade(),
        appRoutes.Invoices(),
        appRoutes.Rejections(),
        appRoutes.Connections(),
        appRoutes.AccountApiKeys(),
      ],
      label: t("settings"),
      icon: <SettingsIcon />,
      hasChildren: true,
      lastItem: true,
    },
  ];

  return (
    <>
      {menuItems.map(({ ...menuItemProps }, idx) => (
        <MenuItem key={idx} {...menuItemProps} />
      ))}
    </>
  );
});

export default SidebarButtons;
