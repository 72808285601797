import React, { CSSProperties } from "react";
import { useImage } from "react-image";
import { ErrorBoundary } from "../../ErrorBoundary";

import DamagedFile from "./table/miscellaneous/DamagedFile.jpg";

interface ImageProps {
  path: string | string[];
  useSuspense?: boolean;
  className?: string;
  customStyle?: CSSProperties;
}

const ImageWrapper: React.FC<ImageProps> = ({
  path,
  useSuspense,
  className,
  customStyle,
}) => {
  const image = useImage({
    srcList: path,
    useSuspense,
  });

  if (image.error) {
    return <img src={DamagedFile} className={className} />;
  }

  return <img src={image.src} className={className} style={customStyle} />;
};

interface DamagedImageProps {
  className?: string;
}

const DamagedImage: React.FC<DamagedImageProps> = ({ className = "" }) => {
  return <img src={DamagedFile} className={className} />;
};

export const CoreImage: React.FC<ImageProps> = ({
  path,
  useSuspense = true,
  className = "",
  customStyle = {},
}) => {
  return (
    <>
      {!path ? (
        <img src={DamagedFile} className={className} />
      ) : (
        <ErrorBoundary
          customFallback={() => {
            return <DamagedImage className={className} />;
          }}
        >
          <ImageWrapper
            path={path}
            useSuspense={useSuspense}
            className={className}
            customStyle={customStyle}
          />
        </ErrorBoundary>
      )}
    </>
  );
};
