import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { Delete, Edit } from "@mui/icons-material";
import { useStores } from "../../../../../../../stores/StoresProvider";
import CoreCardFlow from "../../../../../../core/CoreCardFlow";
import { CardActions } from "../../../../../../../types/interfaces";

interface Props {
  t: TFunction;
  handleClickEdit: (args: string[]) => void;
  handleClickDelete: (args: string[]) => void;
}

const ReasonsTable: FC<Props> = observer(
  ({ t, handleClickEdit, handleClickDelete }) => {
    const { flowSettingsStore } = useStores();

    const listActions = useMemo(
      () =>
        [
          {
            icon: <Edit />,
            tooltip: t("editReason_tooltip"),
            onClick: handleClickEdit,
            label: "Edit",
          },
          {
            icon: <Delete />,
            tooltip: t("deleteReason_tooltip"),
            onClick: handleClickDelete,
            label: "Delete",
          },
        ] as unknown as CardActions[],
      [handleClickDelete, handleClickEdit, t]
    );

    return (
      <>
        {(flowSettingsStore.flow?.rejectReasons ?? []).map((item, index) => {
          return (
            <CoreCardFlow
              key={index}
              title={item.message}
              subtitle={item.description}
              t={t}
              listActions={listActions}
              identifier={[item.code]}
            />
          );
        })}
      </>
    );
  }
);

export default ReasonsTable;
