import jwtDecode from "jwt-decode";
import { backendRoutes } from "../configs/backendRoutes";
import { AppSettings } from "../types/constants";
import { AuthTokenResponse, IToken, Ability } from "../types/interfaces";

interface RefreshTokenResponse {
  ok?: boolean;
  accessToken?: string;
}

let accessToken = "";

export const setAccessToken = (s: string | void): void => {
  accessToken = s ?? "";
};

export const getAccessToken = (): string => {
  return accessToken;
};

export const getAccessTokenPermissions = (token: string): string => {
  if (!token) {
    return [].toString();
  }

  try {
    const { permissions } = jwtDecode<IToken>(token);

    return permissions;
  } catch (err) {
    console.error({ err });
    return [].toString();
  }
};

export const isTokenValidOrUndefined = (): boolean => {
  return validateAccessToken().isUserAuthenticated;
};

export const validateAccessToken = (): AuthTokenResponse => {
  const token = getAccessToken();
  if (!token) {
    return { isUserAuthenticated: false, isNamespace: false };
  }

  try {
    const { exp, contextId } = jwtDecode<IToken>(token);

    if (!exp || Date.now() >= exp * 1000) {
      return { isUserAuthenticated: false, isNamespace: false };
    }

    if (contextId) {
      return { isUserAuthenticated: true, isNamespace: false };
    }
    return { isUserAuthenticated: false, isNamespace: true };
  } catch (err) {
    console.error({ err });
    return { isUserAuthenticated: false, isNamespace: false };
  }
};

export const fetchAccessToken = (): Promise<Response> => {
  return fetch(backendRoutes.RefreshTokenUrl(), {
    method: "POST",
    credentials: "include",
  });
};

export const retrieveAccessToken = async (): Promise<string | void> => {
  try {
    const response = await fetchAccessToken();
    const parsedResponse = (await response.json()) as RefreshTokenResponse;
    return parsedResponse.accessToken;
  } catch {
    console.error("Failed to retrieve access token! Please login!");
  }
};

export const getSettings = async (): Promise<AppSettings | void> => {
  try {
    const response = await fetch(backendRoutes.GetSettings(), {
      method: "POST",
    });
    const parsedResponse = (await response.json()) as AppSettings;
    return parsedResponse;
  } catch {
    console.error("Failed to retrieve settings! Please try again!");
  }
};

export const formatPermissions = (perms: string[]): Ability[] => {
  return (perms || [])?.map((item) => {
    const [action, subject] = item.split(":");
    return { action, subject };
  });
};
