import React, { useMemo } from "react";
import _startCase from "lodash/startCase";

import { Chip, Theme, useTheme } from "@mui/material";
import { CSSProperties, makeStyles } from "@mui/styles";

import { DocumentHistoryStages, DocumentStages } from "../../types/enums";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface StatusProps {
  type: string;
  label: string;
  styleProps?: CSSProperties;
  disableCasing?: boolean; // Disable casing for displaying symbols and avoid bad conversion
}

export const CoreStatusTag: React.FC<StatusProps> = ({
  type,
  label,
  styleProps = {},
  disableCasing = false,
}) => {
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const getVariantStyle = (theme: Theme, variant: string): CSSProperties => {
    const isLightModeOn = currentTheme === THEME_MODES.light;
    switch (variant) {
      case DocumentStages.CREATED:
        return {
          color: theme.palette.success.dark,
          backgroundColor: theme.palette.success.light,
        };
      case DocumentStages.REQUIRES_USER_INPUT:
        return {
          color: isLightModeOn
            ? theme.palette.warning.dark
            : theme.palette.warning.light,
        };
      case DocumentStages.RUNNING:
        return {
          color: theme.palette.highlight.main,
        };
      case DocumentStages.WAITING:
        return {
          color: theme.palette.warning.main,
        };
      case DocumentHistoryStages.SUCCESS:
        return {
          color: theme.palette.success.dark,
        };
      case DocumentStages.DONE:
        return {
          color: isLightModeOn
            ? theme.palette.success.dark
            : theme.palette.success.main,
        };
      case DocumentStages.ERROR:
        return {
          cursor: "default",
          color: isLightModeOn
            ? theme.palette.error.dark
            : theme.palette.error.light,
        };
      case DocumentStages.REJECTED:
        return {
          color: isLightModeOn
            ? theme.palette.error.dark
            : theme.palette.error.light,
        };
      case DocumentHistoryStages.FAILED:
        return { color: theme.palette.error.main };
      default:
        return {
          color: theme.palette.warning.main,
        };
    }
  };

  const chipStyleVariant = useMemo(
    () => getVariantStyle(theme, type),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, theme]
  );

  const classes = makeStyles({
    chipStyle: {
      height: "22px",
      borderRadius: "5px",
      fontSize: "12px",
      ...chipStyleVariant,
      ...styleProps,
    },
  })();

  return (
    <Chip
      size="small"
      label={disableCasing ? label : _startCase(label)}
      className={classes.chipStyle}
    />
  );
};
