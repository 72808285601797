import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Paper, CssBaseline } from "@mui/material";

// Internal imports
import AppSidebar from "./AppSidebar";
import AppRouter from "./AppRouter";
import { AppSuspense } from "./AppSuspense";
import AppTopbar from "./AppTopbar";

import {
  validateAccessToken,
  retrieveAccessToken,
  setAccessToken,
  getAccessTokenPermissions,
} from "../../helper/authHelper";

import "../../App.css";
import { useStores } from "../../stores/StoresProvider";
import { useNotification } from "../../context/useNotification";

const AppBody: React.FC = observer(() => {
  const { userStore, mainStore, chatGptStore, identityStore } = useStores();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const notification = useNotification();
  const { t, ready } = useTranslation(["sidebar", "flows"]);

  const [authProps, setAuthProps] = useState<{
    isUserAuthenticated: boolean;
    isNamespace: boolean;
  } | null>(null);

  const handleDevSettings = (namespaceId: number) => {
    chatGptStore.setLoadDevSettings(true);
    const appId = `namespace/${namespaceId}/`;
    identityStore
      .getIdentity(client, appId)
      .then((res) => {
        if (res?.devSettings && Object.keys(res.devSettings).length > 0) {
          chatGptStore.setDevSettings(res.devSettings);
        }
      })
      .catch(() => null)
      .finally(() => {
        chatGptStore.setLoadDevSettings(false);
      });
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);

      retrieveAccessToken()
        .then((accessToken) => {
          userStore.setAbilityForUser(
            getAccessTokenPermissions(accessToken ?? "")
          );

          setAccessToken(accessToken);
          const authProps = validateAccessToken();
          setAuthProps(authProps);

          if (authProps.isUserAuthenticated) {
            Promise.all([
              userStore.getUserFromContext(client, true),
              userStore
                .getNamespaceFromContext(client, true)
                .then((response) => {
                  handleDevSettings(response.namespace?.id ?? "");
                }),
              chatGptStore.getChatLanguages().catch(() => null),
            ]).catch((error: Error) => {
              void userStore.logoutUser(client).catch((error: Error) => {
                notification.error(t(error?.message || "logoutError"));
              });
              notification.error(t(error?.message || "errorGettingUser"));
            });
          }

          if (authProps.isNamespace) {
            userStore.getUserFromContext(client).catch((error: Error) => {
              void userStore.logoutUser(client).catch((error: Error) => {
                notification.error(t(error?.message || "logoutError"));
              });
              notification.error(t(error?.message || "errorGettingUser"));
            });
          }
        })
        .catch(() => {
          userStore.setAbilityForUser("[]");
          setAuthProps({ isUserAuthenticated: false, isNamespace: false });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore.resetAuth]);

  useEffect(() => {
    mainStore.loadSettings().catch(() => {
      notification.error(t("errorGettingSettings"));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore]);

  if (
    !ready ||
    loading ||
    mainStore.loadingSettings ||
    userStore.loadingUser ||
    !authProps ||
    (userStore.user && !userStore.currentUserPermissions)
  ) {
    return <AppSuspense />;
  }

  return (
    <BrowserRouter>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          height: "100%",
          backgroundImage: "none",
        }}
      >
        <CssBaseline />

        {authProps.isUserAuthenticated && (
          <>
            <AppTopbar t={t} />
            <AppSidebar t={t} />
          </>
        )}

        <AppRouter authProps={authProps} isLoading={loading} />
      </Paper>
    </BrowserRouter>
  );
});

export default AppBody;
