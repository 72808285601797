import { alpha, Components, ThemeOptions } from "@mui/material";

export const initMuiTableCell = (
  theme: ThemeOptions
): Components["MuiTableCell"] => {
  return {
    styleOverrides: {
      root: {
        borderBottom:
          theme.palette?.divider &&
          `1px solid  ${alpha(theme.palette?.divider, 0.2)}`,
        width: "100px",
        height: "40px",
      },
    },
  };
};
