import { makeAutoObservable, runInAction } from "mobx";

import { RootStore } from "./StoresProvider";
import {
  THEME_MODES,
  LOCAL_STORAGE_KEYS,
  AppSettings,
} from "../types/constants";
import LocalStorageHelper from "../helper/localStorageHelper";
import { getSettings } from "../helper/authHelper";

export class MainStore {
  rootStore: RootStore;
  hideExpandSidebar = false;
  currentTheme = THEME_MODES.mono;
  currentPage = "";
  expandedSidebar = false;
  _expandedSidebar = false;
  resetAuth = new Date();
  settings: AppSettings | null = null;
  loadingSettings = false;
  languageFlag = localStorage.getItem("languageFlag") || "GB";

  constructor(rootStore: RootStore) {
    this._expandedSidebar = this.expandedSidebar = LocalStorageHelper.getValue(
      LOCAL_STORAGE_KEYS.expandedSidebar,
      this.expandedSidebar
    );

    this.currentTheme = LocalStorageHelper.getValue(
      LOCAL_STORAGE_KEYS.selectedTheme,
      this.currentTheme
    );

    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setLanguageFlag = (flag: string) => {
    this.languageFlag = flag;
    localStorage.setItem("languageFlag", flag);
  };

  setCurrentPage = (name: string) => {
    this.currentPage = name;
  };

  toggleSidebar = () => {
    this.setSidebar(!this.expandedSidebar);
  };

  setSidebar = (expanded: boolean, saveInStorage = true) => {
    this.expandedSidebar = expanded;

    // Save in storage only when action from user
    if (saveInStorage) {
      this._expandedSidebar = expanded;
      LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.expandedSidebar, expanded);
    }
  };

  collapseSidebar = (hideButton = false) => {
    this.setSidebar(false, false);
    this.hideExpandSidebar = hideButton;
  };

  resetSidebar = () => {
    this.expandedSidebar = this._expandedSidebar;
    this.hideExpandSidebar = false;
  };

  setThemeMode = (mode: THEME_MODES) => {
    this.currentTheme = mode;

    LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.selectedTheme, mode);
  };

  setAuthState = (reset: Date) => {
    this.resetAuth = reset;
  };

  loadSettings = async () => {
    try {
      this.loadingSettings = true;
      const settings = await getSettings();
      if (!settings) {
        throw new Error();
      }

      runInAction(() => {
        this.settings = settings;
        this.loadingSettings = false;
      });
    } catch (err) {
      runInAction(() => {
        this.loadingSettings = false;
      });
    }
  };
}
