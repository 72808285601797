import React, { FC, useMemo } from "react";

import { useTheme, alpha } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CheckIcon from "@mui/icons-material/Check";

import { MESSAGE_LEVELS } from "../../../../../../types/interfaces";

const MESSAGE_LEVEL_ICONS = {
  [MESSAGE_LEVELS.error]: ErrorOutlineIcon,
  [MESSAGE_LEVELS.warning]: WarningAmberIcon,
  [MESSAGE_LEVELS.info]: InfoOutlinedIcon,
  [MESSAGE_LEVELS.header]: null,
  [MESSAGE_LEVELS.subheader]: KeyboardDoubleArrowDownIcon,
  [MESSAGE_LEVELS.success]: CheckIcon,
};

export const MessageLevelIcon: FC<{ level: MESSAGE_LEVELS | undefined }> = ({
  level,
}) => {
  const theme = useTheme();

  const IconComponent = useMemo(
    () => MESSAGE_LEVEL_ICONS[level as MESSAGE_LEVELS],
    [level]
  );

  const styleProps = useMemo(() => {
    switch (level) {
      case MESSAGE_LEVELS.info:
      case MESSAGE_LEVELS.success:
        return {
          color: theme.palette.highlight.main,
        };
      case MESSAGE_LEVELS.warning:
        return {
          color: theme.palette.warning.main,
        };
      case MESSAGE_LEVELS.error:
        return {
          color: theme.palette.error.dark,
        };
      case MESSAGE_LEVELS.header:
      case MESSAGE_LEVELS.subheader:
        return {
          color: alpha(theme.palette.text.primary, 0.5),
        };
      default:
        return {};
    }
  }, [level, theme]);

  if (IconComponent) {
    return (
      <IconComponent
        sx={{
          marginRight: "5px",
          fontSize: "20px",
          ...styleProps,
        }}
      />
    );
  }

  return <></>;
};
