import React, { useState, useMemo } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, List, ListItemButton, styled, alpha } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import CoreInput from "../../core/CoreInput";
import { InputOptions } from "../../../types/interfaces";
import CoreButton from "../../core/CoreButton";
import ConfigHelper from "../../../helper/configHelper";

const useStyles = makeStyles({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
    height: "100%",
    width: "100%",
  },
  list: {
    marginTop: "30px",
    marginBottom: "30px",
    maxHeight: "200px",
    overflowY: "auto",
    height: "100%",
  },
  button: {
    maxWidth: "100px",
  },
  noDataBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface Props {
  t: TFunction;
  options: InputOptions[];
  setValue: (value: string) => void;
  value: string;
  setTextInQuill: (value: string) => void;
  disableDiagramField?: boolean;
}

const GroupHeader = styled("div")(({ theme }) => ({
  padding: "4px 10px",
  color: alpha(theme.palette.text.disabled, 0.7),
  cursor: "default",
}));

const DynamicContentTab: React.FC<Props> = ({
  t,
  options,
  setValue,
  value,
  setTextInQuill,
  disableDiagramField = false,
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
  };

  const handleClickAdd = () => {
    setTextInQuill(value);
    setValue("");
  };

  const handleClickOption = (key: string) => {
    if (value === key) {
      setValue("");
      return;
    }
    setValue(key);
  };

  const filteredOptions = useMemo(() => {
    return ConfigHelper.filterOptionsByTerm(options, searchFilter);
  }, [options, searchFilter]);

  const groupOptionsByCategory = useMemo(
    () =>
      filteredOptions?.reduce<Record<string, InputOptions[]>>((acc, option) => {
        const category = option?.category || t("noCategory");
        if (!acc?.[category]) {
          acc[category] = [];
        }
        acc?.[category]?.push(option);
        return acc;
      }, {}),
    [filteredOptions, t]
  );

  return (
    <Box className={classes.mainBox}>
      <CoreInput
        placeholder={t("searchForSuggestions")}
        onChange={handleChange}
        size="small"
        value={searchFilter}
        startAdornment={
          <SearchIcon sx={{ opacity: 0.4, marginRight: "10px" }} />
        }
      />

      <Box className={classes.list}>
        {groupOptionsByCategory &&
        Object.keys(groupOptionsByCategory)?.length > 0 ? (
          <List style={{ width: "100%", height: "100%" }}>
            {Object.entries(groupOptionsByCategory || [])?.map(
              ([category, options]) => (
                <React.Fragment key={category}>
                  <GroupHeader>{category}</GroupHeader>
                  {options?.map((option) => (
                    <ListItemButton
                      key={option?.key}
                      onClick={() => handleClickOption(option?.key)}
                      selected={value === option?.key}
                    >
                      {option?.value}
                    </ListItemButton>
                  ))}
                </React.Fragment>
              )
            )}
          </List>
        ) : (
          <Box className={classes.noDataBox}>{t("noDataToDisplay")}</Box>
        )}
      </Box>

      <CoreButton
        variant="contained"
        className={classes.button}
        onClick={handleClickAdd}
        disabled={!value || disableDiagramField}
      >
        {t("add")}
      </CoreButton>
    </Box>
  );
};

export default DynamicContentTab;
