import { gql } from "@apollo/client";
import {
  ChatGptSuggestions,
  ChatGptMessage,
  ChatLanguages,
} from "../../types/interfaces";

export interface QUERY_GET_CHAT_GPT_RESPONSE {
  getChatGptResponse: ChatGptMessage;
}

export interface QUERY_GET_CHAT_GPT_SUGGESTIONS {
  getChatGptSuggestions: ChatGptSuggestions[];
}

export interface DevSettingsParameters {
  key: string;
  options: [{ key: string; label: string }];
  value: string;
  type: string;
  name: string;
  description: string;
}

export interface DevSettings {
  name: string;
  description: string;
  parameters: DevSettingsParameters[];
}

export interface QUERY_GET_DEV_SETTINGS_RESPONSE {
  getDevSettings: DevSettings;
}

export interface QUERY_GET_CHAT_LANGUAGES {
  getChatLanguages: ChatLanguages[];
}

export const GET_CHAT_GPT_RESPONSE = gql`
  query getChatGptResponse(
    $question: JSON
    $config: JSON
    $flowIdentifier: String
    $language: String
  ) {
    getChatGptResponse(
      question: $question
      config: $config
      flowIdentifier: $flowIdentifier
      language: $language
    ) {
      question
      answer
      filter
    }
  }
`;

export const GET_CHAT_GPT_SUGGESTIONS = gql`
  query getChatGptSuggestions {
    getChatGptSuggestions {
      question
    }
  }
`;

export const GET_DEV_SETTINGS = gql`
  query getDevSettings {
    getDevSettings {
      name
      description
      parameters {
        key
        options
        value
        type
        name
        description
      }
    }
  }
`;

export const GET_CHAT_LANGUAGES = gql`
  query getChatLanguages {
    getChatLanguages {
      key
      name
    }
  }
`;
