import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";

import { useReactFlow } from "reactflow";

import { useStores } from "../../../../../stores/StoresProvider";
import { NODE_HANDLE_TYPES } from "../../../../../types/constants";
import { NodeData } from "../../../../../types/interfaces";
import DiagramNodeHandle from "./DiagramNodeHandle";
import NodesHelper from "../helper/nodesHelper";

interface NodeProps {
  data: NodeData;
}

const DiagramNodeGroup: FC<NodeProps> = observer(({ data }) => {
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();

  const { onHandleClick, identifier } = data;

  const outgoing = useMemo(
    () => NodesHelper.findOutgoing(reactFlow, identifier),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowSettingsStore.layoutingNeeded]
  );

  const nodeTypeLabel = useMemo(() => {
    const currentNodeKey =
      (reactFlow.getNode(identifier) as unknown as NodeData)?.key || "";

    const nodeName = flowSettingsStore.nodesList?.find(
      (node) => node?.key === currentNodeKey
    )?.name;

    return nodeName || "";
  }, [reactFlow, identifier, flowSettingsStore.nodesList]);

  return (
    <>
      <DiagramNodeHandle
        type={NODE_HANDLE_TYPES.target}
        hidden={true}
        identifier={identifier}
        groupFlag={nodeTypeLabel}
      />

      <DiagramNodeHandle
        type={NODE_HANDLE_TYPES.source}
        onHandleClick={() => onHandleClick && onHandleClick(identifier)}
        hidden={outgoing.length > 0 || !flowSettingsStore.isDiagramEditable}
        identifier={identifier}
      />
    </>
  );
});

export default DiagramNodeGroup;
