/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/material";

import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Edge,
  Connection,
  MiniMap,
  Controls,
  Node,
} from "reactflow";
import "reactflow/dist/style.css";

import { DiagramNodeHandlerDrawer } from "../diagramBuilder/DiagramNodeHandlerDrawer";
import { DiagramLink } from "../diagramBuilder/DiagramLink";
import DiagramNode from "../diagramBuilder/DiagramNode";
import { useStores } from "../../../../../stores/StoresProvider";
import { useNotification } from "../../../../../context/useNotification";
import {
  NODE_ASSET_TYPE,
  THEME_MODES,
  START_NODE_STRUCTURE,
  DIAGRAM_NODE_CATEGORIES,
} from "../../../../../types/constants";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import { FlowNode, FormData } from "../../../../../types/interfaces";
import NodesHelper from "../helper/nodesHelper";
import { DiagramStartNode } from "../diagramBuilder/DiagramStartNode";
import DesignerTabHeaderActions from "../DesignerTabHeaderActions";
import ExportAsPngButton from "./ExportAsPngButton";
import { AppSuspense } from "../../../../main/AppSuspense";
import CoreConfirmModal from "../../../../core/CoreConfirmModal";
import DiagramNodeGroup from "../diagramBuilder/DiagramNodeGroup";
import DiagramDrawer from "../../../addNewFlow/tabs/assetsCore/DiagramDrawer";
import { ElkNodeParent } from "../../../../../types/types";
import DiagramPlaceholder from "../diagramBuilder/DiagramPlaceholder";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const NODE_TYPES = {
  [DIAGRAM_NODE_CATEGORIES.start]: DiagramStartNode,
  [DIAGRAM_NODE_CATEGORIES.diagramNode]: DiagramNode,
  [DIAGRAM_NODE_CATEGORIES.node]: DiagramNode,
  [DIAGRAM_NODE_CATEGORIES.placeholder]: DiagramPlaceholder,
  [DIAGRAM_NODE_CATEGORIES.group]: DiagramNodeGroup,
};

const EDGE_TYPES = {
  diagramLink: DiagramLink,
};

const initialNodes = [START_NODE_STRUCTURE];

const DesignerTab: React.FC<Props> = observer(({ t, flowSettingsStore }) => {
  const { id } = useParams<{ id: string }>();
  const {
    mainStore: { currentTheme },
    userStore,
  } = useStores();
  const notification = useNotification();
  const reactflowRef = useRef(null);
  const theme = useTheme();

  const [loadingNodes, setLoadingNodes] = useState(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [selectedItemIndex, setSelectedItemIndex] = useState<
    number | undefined
  >();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOnConfig, setIsOnConfig] = useState<boolean | null>(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[]);

  const classes = makeStyles({
    outerBox: {
      maxWidth: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& .react-flow__node-group": {
        borderColor: theme.palette.neutral.dark,
      },
      "& .react-flow__node": {
        cursor: "pointer !important",
      },
      "& .react-flow__handle.connectionindicator": {
        cursor: "grab",
      },
      "& .react-flow__edge": {
        cursor: "grab",
      },
    },
  })();

  const onConnect = useCallback(
    (params: Edge<any> | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onHandleClick = (identifier: string) => {
    flowSettingsStore.setParentNodeId(identifier);
  };

  const loadNodes = () => {
    const newNodes = [
      ...(flowSettingsStore?.flowDiagram &&
      (flowSettingsStore.flowDiagram?.specs?.nodes?.length === 0 ||
        (flowSettingsStore.flowDiagram?.specs?.nodes || [])?.find(
          (item) => item?.identifier === NODE_ASSET_TYPE.startNode
        ) === undefined)
        ? [
            {
              ...START_NODE_STRUCTURE,
              data: {
                ...START_NODE_STRUCTURE.data,
                onHandleClick: onHandleClick,
              },
            },
          ]
        : []),

      ...(flowSettingsStore?.flowDiagram &&
      flowSettingsStore.flowDiagram.specs &&
      flowSettingsStore.flowDiagram.specs.nodes
        ? flowSettingsStore.flowDiagram.specs.nodes.map((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return {
              ...item,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              data: {
                ...item.data,
                onHandleClick: onHandleClick,
                key: item.key,
              },
            };
          })
        : []),
    ] as FlowNode[];

    const loadedEdges = [
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ...(flowSettingsStore?.flowDiagram &&
      flowSettingsStore.flowDiagram.specs &&
      flowSettingsStore.flowDiagram.specs.edges
        ? flowSettingsStore.flowDiagram.specs.edges
        : []),
    ];

    setNodes((newNodes as Node[]) || initialNodes);
    setEdges(loadedEdges);
  };

  useEffect(() => {
    //Used to hide the ResizeObserver error
    window.addEventListener("error", NodesHelper.errorHandler);

    flowSettingsStore
      .loadDiagramData(id, notification, t)
      .catch((error: Error) => {
        notification.error(t(error?.message || "getFlowDiagramError"));
      })
      .finally(() => {
        setLoadingNodes(false);
      });

    return () => {
      //flowSettingsStore.resetDiagramStates();
      window.removeEventListener("error", NodesHelper.errorHandler);

      if (flowSettingsStore.isDiagramEditable) {
        flowSettingsStore.setIsDiagramEditable(false);
      }

      if (Object.keys(flowSettingsStore.nodeErrors || {})?.length > 0) {
        flowSettingsStore.setNodeErrors({});
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flowSettingsStore.flowDiagram) {
      loadNodes();
    }

    flowSettingsStore.setLayoutingNeeded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.flowDiagram]);

  //TODO: Check this
  useEffect(() => {
    if (nodes && userStore?.connections && userStore?.connections?.length > 0) {
      flowSettingsStore.checkConnectionErrors(nodes as FlowNode[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  const toggleDrawer = (value: boolean) => {
    setIsDrawerOpen(value);
    if (!value) setSelectedItemIndex(undefined);
    if (selectedItemIndex === undefined) setIsOnConfig(false);
  };

  const handleModalClose = () => {
    flowSettingsStore.setPendingTab("");
  };

  const handleOnModalConfirm = () => {
    flowSettingsStore.setSelectedTab(flowSettingsStore.pendingTab);
    flowSettingsStore.setPendingTab("");
    flowSettingsStore.setDiagramHasBeenUpdated(false);
  };

  const onEdgeClick = (
    _: React.MouseEvent<Element, MouseEvent>,
    edge: Edge<any>
  ) => {
    flowSettingsStore.setParentNodeId(edge.source);
    flowSettingsStore.setSelectedEdgeId(edge.id);
  };

  const onDiagramTemplateConfirm = (formData: FormData) => {
    flowSettingsStore
      .applyDiagramTemplate(formData as Record<string, string>)
      .then(() => {
        flowSettingsStore.setLoadingDiagram(true);

        flowSettingsStore
          .getLatestFlowDiagram(id)
          .then((diagram) => {
            flowSettingsStore
              .loadContextObjects({
                nodes: diagram?.specs?.nodes || [],
                edges: diagram?.specs?.edges || [],
              })
              .catch((error: Error) => {
                notification.error(t(error?.message || "contextObjectsError"));
              });
            toggleDrawer(false);
          })
          .catch((error: Error) => {
            notification.error(error.message);
          })
          .finally(() => {
            flowSettingsStore.setLoadingDiagram(false);
          });
      })
      .catch((error: Error) => {
        notification.error(error.message);
      });
  };

  useEffect(() => {
    if (flowSettingsStore.layoutingNeeded) {
      NodesHelper.getLayoutedElements(nodes, edges)
        .then((layoutedGraph) => {
          const flattened = NodesHelper.flattenElkGraph(layoutedGraph);

          setNodes(
            nodes.map((node) => {
              const nodePos = flattened.find(
                (el) => el.id === node.id
              ) as ElkNodeParent;
              return {
                ...node,
                position: { x: nodePos.x as number, y: nodePos.y as number },
                style: { width: nodePos.width, height: nodePos.height },
              };
            })
          );

          flowSettingsStore.setLayoutingNeeded(false);
        })
        .catch((reason) => {
          console.log(reason, "error layouting");

          flowSettingsStore.setLayoutingNeeded(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.layoutingNeeded]);

  return (
    <>
      <CoreTabContainer
        t={t}
        title={t(flowSettingsStore.selectedTab || "")}
        extraHeaderActions={
          <DesignerTabHeaderActions
            t={t}
            toggleDiagramDrawer={() => toggleDrawer(!isDrawerOpen)}
            setLoadingNodes={setLoadingNodes}
          />
        }
        addPadding={false}
      >
        {flowSettingsStore.loadingDiagram ||
        flowSettingsStore.loadingFlowSettings ? (
          <AppSuspense />
        ) : (
          <Box className={classes.outerBox}>
            <div style={{ width: "100%", height: "100%" }} ref={reactflowRef}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={NODE_TYPES}
                edgeTypes={EDGE_TYPES}
                nodesDraggable={false}
                nodesConnectable={false}
                onEdgeClick={onEdgeClick}
                fitView
                attributionPosition="top-right"
                proOptions={{ hideAttribution: true }}
              >
                <ExportAsPngButton t={t} />
                <Controls />
                <MiniMap
                  nodeColor={theme.palette.canvas.dark}
                  nodeStrokeColor={theme.palette.switch.main}
                  nodeStrokeWidth={3}
                  maskColor={
                    currentTheme === THEME_MODES.dark
                      ? theme.palette.secondary.dark
                      : theme.palette.primary.light
                  }
                  style={{
                    backgroundColor:
                      currentTheme === THEME_MODES.dark
                        ? theme.palette.secondary.dark
                        : currentTheme === THEME_MODES.light
                        ? theme.palette.neutral.light
                        : theme.palette.primary.light,
                  }}
                />
              </ReactFlow>
            </div>
          </Box>
        )}
      </CoreTabContainer>

      <DiagramNodeHandlerDrawer loading={loadingNodes} />

      <CoreConfirmModal
        open={!!flowSettingsStore.pendingTab}
        onClose={handleModalClose}
        title={t("versionChangedTitle")}
        subtitle={t("versionChangedSubtitle")}
        cancelButtonLabel={t("cancel")}
        confirmButtonLabel={t("discard")}
        onCancel={handleModalClose}
        onConfirm={handleOnModalConfirm}
        isDisable={flowSettingsStore.loadingDiagram}
      />

      <DiagramDrawer
        t={t}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={() => toggleDrawer(!isDrawerOpen)}
        isOnConfig={isOnConfig}
        setIsOnConfig={setIsOnConfig}
        onConfirm={onDiagramTemplateConfirm}
      />
    </>
  );
});

export default DesignerTab;
