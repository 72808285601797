import React, { useState } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import {
  Drawer,
  IconButton,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  useTheme,
  DialogContent,
  Typography,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import LocalStorageHelper from "../../../helper/localStorageHelper";
import {
  LOCAL_STORAGE_KEYS,
  SuggestionsModalTabs,
  THEME_MODES,
} from "../../../types/constants";
import { useStores } from "../../../stores/StoresProvider";
import DynamicContentTab from "./DynamicContentTab";
// import FunctionTab from "./FunctionTab";
import { InputOptions } from "../../../types/interfaces";

interface Props {
  t: TFunction;
  onClose: () => void;
  setModalTab: (tab: string) => void;
  selectedModalTab: string;
  suggestions: InputOptions[];
  setTextInQuill: (value: string) => void;
  disableDiagramField?: boolean;
}

const extractDrawerWidth = () => {
  const storageWidth = LocalStorageHelper.getValue<DrawerWidth>(
    LOCAL_STORAGE_KEYS.appSettings,
    { drawerWidth: 0 }
  );

  // If property is not set, return default
  if (!storageWidth || !storageWidth?.drawerWidth) {
    return window.innerWidth * 0.5 - 50;
  }

  // If property exceeds maximum accepted size, return max size
  if (storageWidth?.drawerWidth > window.innerWidth - 50) {
    return window.innerWidth - 50;
  }

  return storageWidth?.drawerWidth;
};

type DrawerWidth = {
  drawerWidth: number;
};

const SuggestionsModal: React.FC<Props> = observer(
  ({
    t,
    onClose,
    setModalTab,
    selectedModalTab,
    suggestions,
    setTextInQuill,
    disableDiagramField,
  }) => {
    const [selectedSuggestionValue, setSelectedSuggestionValue] =
      useState<string>("");
    const { mainStore, flowSettingsStore } = useStores();
    const theme = useTheme();

    const useStyles = makeStyles({
      paper: {
        minHeight: "50%",
        width: "70%",
        maxWidth: "150px",
        display: "block",
      },
      drawerPaper: {
        "& .MuiDrawer-paper": {
          padding: 0,
          height: "50vh",
          width: "400px",
          position: "fixed",
          marginTop: "150px",
          marginRight: extractDrawerWidth(),
        },
      },
      header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "5px 10px",
      },
      actionsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 0,
      },
      tabs: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
      tabText: {
        fontSize: "14px",
        color: "inherit",
      },
      tab: {
        textTransform: "none",
        width: "100%",
        backgroundColor: "inherit",
      },
      selectedTab: {
        backgroundColor: alpha(theme.palette.highlight.dark, 0.4),
        color: theme.palette.highlight.main,
        "&:hover": {
          backgroundColor:
            mainStore.currentTheme === THEME_MODES.light
              ? theme.palette.canvas.contrastText
              : "",
        },
      },
      dialogContent: {
        width: "100%",
        height: "100%",
      },
    });

    const classes = useStyles();

    const tabs = [
      // {
      //   label: t(SuggestionsModalTabs.function),
      //   onClick: () => {
      //     if (selectedSuggestionValue) {
      //       setSelectedSuggestionValue(null);
      //     }
      //     setModalTab(SuggestionsModalTabs.function);
      //   },
      //   value: SuggestionsModalTabs.function,
      // },
      {
        label: t(SuggestionsModalTabs.dynamicContent),
        onClick: () => {
          // if (selectedSuggestionValue) {
          //   setSelectedSuggestionValue("");
          // }
          setModalTab(SuggestionsModalTabs.dynamicContent);
        },
        value: SuggestionsModalTabs.dynamicContent,
      },
    ];

    const handleClose = () => {
      setSelectedSuggestionValue("");
      onClose();
    };

    return (
      <Drawer
        open={flowSettingsStore.isSuggestionsModalOpen}
        onClose={handleClose}
        style={{ position: "initial" }}
        anchor="right"
        className={classes.drawerPaper}
        transitionDuration={0}
        variant={"persistent"}
      >
        <DialogTitle className={classes.header}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogActions className={classes.actionsContainer}>
          <Tabs variant="fullWidth" className={classes.tabs} value={false}>
            {tabs.map((tab, index) => {
              const isSelected = selectedModalTab === tab.value;
              return (
                <Tab
                  key={index}
                  label={
                    <Typography className={classes.tabText}>
                      {t(tab.label)}
                    </Typography>
                  }
                  onClick={tab.onClick}
                  className={`${classes.tab} ${
                    isSelected ? classes.selectedTab : ""
                  }`}
                />
              );
            })}
          </Tabs>
        </DialogActions>

        <DialogContent className={classes.dialogContent}>
          {/* {selectedModalTab === SuggestionsModalTabs.function && (
            <FunctionTab t={t} handleAddClick={handleAdd} />
          )} */}
          {selectedModalTab === SuggestionsModalTabs.dynamicContent && (
            <DynamicContentTab
              t={t}
              options={suggestions}
              setValue={setSelectedSuggestionValue}
              value={selectedSuggestionValue}
              setTextInQuill={setTextInQuill}
              disableDiagramField={disableDiagramField}
            />
          )}
        </DialogContent>
      </Drawer>
    );
  }
);

export default SuggestionsModal;
