import React, { memo, FC } from "react";
import { TFunction } from "react-i18next";
import { observer } from "mobx-react";

import makeStyles from "@mui/styles/makeStyles";
import { Delete } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import { DocumentLineItemRow } from "../../../../../types/interfaces";
import { DocumentStore } from "../../../../../stores/DocumentStore";

interface Props {
  t: TFunction;
  row: DocumentLineItemRow & { index: number };
  documentStore: DocumentStore;
}

const useStyles = makeStyles({
  box: {
    display: "flex",
    flexDirection: "row",
  },
});

const Component: FC<Props> = observer(({ t, row, documentStore }) => {
  const classes = useStyles();
  const onDelete = () => {
    documentStore.deleteLineItemRow(row?.index);
    documentStore.setManualLineItemMode(false);
  };

  const onCheck = () => {
    documentStore.setManualLineItemMode(false);
    documentStore.setCurrentEditingCell(null);
  };

  return (
    <Box className={classes.box}>
      <Tooltip
        placement="left"
        title={
          !row?.["manuallyAdded"]
            ? t("lineItemDeleteDisabledTooltip")
            : t("lineItemDeleteTooltip")
        }
      >
        <div>
          <IconButton
            color="error"
            size="small"
            // Only custom rows can be deleted
            disabled={
              !row?.["manuallyAdded"] ||
              (documentStore.viewMode as unknown as boolean)
            }
            onClick={onDelete}
          >
            <Delete />
          </IconButton>
        </div>
      </Tooltip>

      {row?.["manuallyAdded"] &&
        documentStore.manualLineItemMode &&
        documentStore.currentEditingCell?.rowIndex === row?.index && (
          <Tooltip placement="left" title={t("lineItemCheckTooltip")}>
            <div>
              <IconButton
                size="small"
                disabled={documentStore.viewMode as unknown as boolean}
                onClick={onCheck}
              >
                <CheckIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
    </Box>
  );
});

export const TableLineActionsColumn = memo(Component);
