import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, Grid, Radio, Typography, useTheme } from "@mui/material";

import { useStores } from "../../../../../../stores/StoresProvider";
import { BILLING_CONTRACT_TERMS } from "../../../../../../types/interfaces/subscription";
import { SubscriptionPrice } from "../SubscriptionPrice";

const useStyles = makeStyles({
  contractLabelContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contractLabel: {
    marginRight: "5px",
  },
  contractDescription: {
    opacity: 0.6,
    fontSize: "12px",
    marginBottom: "12px",
  },
  checkboxRadioContainer: {
    width: "45px",
  },
  detailsContent: {
    flex: 1,
  },
});

const CONTRACT_TERM_LABEL = {
  [BILLING_CONTRACT_TERMS.month]:
    "subscription_upgrade_contract_term_monthly_title",
  [BILLING_CONTRACT_TERMS.year]:
    "subscription_upgrade_contract_term_yearly_title",
};

const CONTRACT_TERM_DESCRIPTION = {
  [BILLING_CONTRACT_TERMS.month]:
    "subscription_upgrade_contract_term_monthly_description",
  [BILLING_CONTRACT_TERMS.year]:
    "subscription_upgrade_contract_term_yearly_description",
};

export const ContractTerm: FC = observer(() => {
  const { t } = useTranslation("subscription");
  const theme = useTheme();
  const classes = useStyles();
  const { subscriptionStore } = useStores();

  const selectedPlan = subscriptionStore.selectedSubscription;
  const isAnnualPlanActive =
    subscriptionStore.activePlan?.period === BILLING_CONTRACT_TERMS.year;

  const periodPlans = selectedPlan?.plan
    ? subscriptionStore.subscriptionPlans?.filter(
        (item) => !!item?.plan && item?.plan === selectedPlan?.plan
      )
    : [];

  const periods =
    periodPlans?.length > 1
      ? periodPlans?.map((item) => item?.period)
      : [BILLING_CONTRACT_TERMS.month];

  return (
    <>
      {Object.keys(BILLING_CONTRACT_TERMS).map((contractType) => {
        // When an annual plan is active, restrict monthly plans to
        if (
          isAnnualPlanActive &&
          contractType === BILLING_CONTRACT_TERMS.month
        ) {
          return null;
        }

        if (periods?.length > 0 && !periods.includes(contractType)) {
          return <React.Fragment key={contractType}></React.Fragment>;
        }

        const isSelected = subscriptionStore.contractTerm === contractType;
        const periodPlan = periodPlans?.find(
          (item) => item.period === contractType
        );

        return (
          <React.Fragment key={contractType}>
            <Grid
              onClick={() =>
                subscriptionStore.setContractTermType(contractType)
              }
              container
              direction="row"
              sx={{
                cursor: "pointer",
                padding: "15px 10px",
                background: isSelected ? theme.palette.primary.dark : "auto",
              }}
            >
              <Grid item className={classes.checkboxRadioContainer}>
                <Radio checked={isSelected} />
              </Grid>

              <Grid item className={classes.detailsContent}>
                <Box className={classes.contractLabelContent}>
                  <Typography className={classes.contractLabel}>
                    {t(CONTRACT_TERM_LABEL[contractType])}
                  </Typography>
                </Box>

                <Typography className={classes.contractDescription}>
                  {t(CONTRACT_TERM_DESCRIPTION[contractType])}
                </Typography>
              </Grid>

              <Grid item>
                {periodPlan?.price ? (
                  <SubscriptionPrice
                    price={periodPlan?.price}
                    discount={periodPlan?.discount}
                    currency={periodPlan.currency}
                    styleProps={{ color: theme.palette.highlight.main }}
                  />
                ) : (
                  "-"
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
});
