import React from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { appRoutes } from "../../configs/routes";

interface Props {
  t: TFunction;
  title: string;
  children: React.ReactNode;
  extraHeaderActions?: React.ReactNode;
  addPadding?: boolean; // TODO: Remove property and handle this case in more generic way
}

const CoreTabContainer: React.FC<Props> = observer(
  ({ t, title, children, extraHeaderActions, addPadding = true }) => {
    const { id: flowId } = useParams<{ id: string }>();

    const history = useHistory();
    const classes = makeStyles({
      titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "64px",
      },
      content: {
        paddingRight: "4px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        marginTop: "64px",
      },
      titleStyle: {
        fontSize: "20px",
        paddingLeft: "10px",
      },
      componentContentStyle: {
        padding: addPadding ? "20px" : "0",
        height: "100%",
      },
      extraActionsContainer: {
        display: "flex",
        flexDirection: "row",
      },
      extraHeaderActions: {
        textAlign: "right",
        marginTop: 5,
        display: "block",
      },
      boxTitle: {
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
      },
    })();

    return (
      <Box className={classes.content}>
        <Box className={classes.titleContainer}>
          <Box className={classes.boxTitle}>
            <IconButton
              onClick={() => history.push(appRoutes.FlowDetails(flowId))}
            >
              <ArrowBackIosNew />
            </IconButton>

            <Typography className={classes.titleStyle}>{t(title)}</Typography>
          </Box>
          <Box className={classes.extraActionsContainer}>
            {extraHeaderActions}
          </Box>
        </Box>

        <Divider />

        <Box className={classes.componentContentStyle}>{children}</Box>
      </Box>
    );
  }
);

export default CoreTabContainer;
