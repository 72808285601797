import React, { FC } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import { CoreStatusTag } from "../../../../core/CoreStatusTag";
import { DocumentStages } from "../../../../../types/enums";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gridGap: "10px",
  },
});

interface Props {
  t: TFunction;
  version: number;
  isCurrent: boolean;
}

export const VersionDisplayCell: FC<Props> = ({ t, version, isCurrent }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="body2">{version}</Typography>

      {isCurrent ? (
        <CoreStatusTag
          type={DocumentStages.RUNNING}
          label={t("versions_is_current_label")}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
