export const SERVER_URL = process.env.REACT_APP_BACKEND_URL ?? "/api/";

const url = (route: string): string => `${SERVER_URL}${route}`;

export const getEndpoint: (path: string) => string = (path = "") => {
  return `${getProtocol(
    "http"
  )}://${getHost()}${getPort()}${getPath()}/${path}`;
};

export const getWebSocketUrl: (path?: string) => string = (path = "") => {
  return `${getProtocol("ws")}://${getHost()}${getPort(
    "REACT_APP_WEBSOCKET_PORT"
  )}${path}`;
};

export const forceHTTPS: () => void = () => {
  if (window.process.env && window.location.protocol !== "https:") {
    window.location.href = window.location.href.replace("http://", "https://");
  }
};

const getProtocol = (type: string) => {
  const sslDisabled = process.env.REACT_APP_SSL_DISABLED ?? false;
  return `${type}${sslDisabled ? "" : "s"}`;
};

const getHost = () => {
  return window.location.hostname;
};

const getPort = (type?: string) => {
  return type && process.env && process.env?.[type]
    ? `:${process.env?.[type] as string}`
    : "/api/";
};

const getPath = () => {
  return process.env.REACT_APP_SERVER_PATH
    ? `${process.env.REACT_APP_SERVER_PATH}`
    : "";
};

export const backendRoutes = {
  GetImagePreview: (
    path: string,
    documentIdentifier: string,
    size?: string,
    quality?: string
  ): string => {
    if (!path) return "";

    const searchParams = new URLSearchParams({
      path,
      documentIdentifier,
    });

    if (size) searchParams.append("size", size);
    if (quality) searchParams.append("quality", quality);

    return url(`document-page/preview?${searchParams.toString()}`);
  },
  GraphQLUrl: (): string => url("graphql"),
  RefreshTokenUrl: (): string => url("refresh_token"),

  UploadDocument: (id: string): string => `/flow/${id}/upload`,
  GetSettings: (): string => url("settings"),
  OneDriveAuthorization: (): string => url("onedrive/authorize"),
  DropboxAuthorization: (): string => url("authorize-dropbox"),
  DetectLineItemsFromImage: (): string => "processing/table/detect",
  ProcessImage: (): string => url("processing/file/getImage"),

  ExportFlow: (): string => `flow/export`,
  DownloadDocument: (id: string) => `download/document?identifier=${id}`,

  // Billing
  DownloadReceipt: (receiptId: string, billingClientId: string): string =>
    url(`receipt/download?receipt=${receiptId}&client=${billingClientId}`),
  DownloadUsageHistory: (billingClientId: string, date: string): string =>
    url(`usage/download?client=${billingClientId}&start=${date}&end=${date}`),
};
