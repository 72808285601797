import React, { FC, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  alpha,
  Box,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CoreNotificationBox from "../../../../../core/CoreNotificationBox";

import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CoreButton from "../../../../../core/CoreButton";
import { useStores } from "../../../../../../stores/StoresProvider";
import {
  useNotification,
  NotificationTypes,
} from "../../../../../../context/useNotification";
import { DOCUMENT_STATUSES } from "../../../../../../types/constants";
interface Props {
  closeRightClickMenu: () => void;
  open: boolean;
  handleClose: () => void;
  selectedPages: string[];
  documentPages: string[];
}

const ExtractDocumentPages: FC<Props> = observer(
  ({
    closeRightClickMenu,
    open,
    handleClose,
    selectedPages,
    documentPages,
  }) => {
    const { documentStore, flowStore } = useStores();
    const client = useApolloClient();
    const notification = useNotification();
    const theme = useTheme();
    const { t } = useTranslation("documents");
    const [buttonDisbled, setButtonDisabled] = useState(false);
    const [targetFlow, setTargetFlow] = useState<{
      id: string;
      label: string;
    } | null>(null);
    const classes = makeStyles({
      dialog: {
        backgroundImage: "unset",
        backgroundColor: theme.palette?.background.paper,
        maxWidth: "640px",
      },
      deletePagesModalHeader: {
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
        marginBottom: "20px",
      },
      deletePageModalActions: {
        padding: "20px",
      },
      flowSelect: {
        marginTop: "20px",
        width: "100%",
      },
      extractLastPageNotification: {
        backgroundColor: alpha(theme.palette.warning.main, 0.4),
        boxShadow: "none",
        marginTop: "20px",
        width: "100%",
      },
      iconButton: {
        position: "absolute",
        right: 8,
        top: 8,
      },
    })();

    const getFlows = flowStore.flows.map((flow) => ({
      id: flow.identifier,
      label: flow.name,
    }));

    const handleFlow = (value: unknown) => {
      const option = value as { id: string; label: string };
      setTargetFlow(option);
    };

    const handleExtractPages = () => {
      setButtonDisabled(true);
      if (
        documentStore.documentStatus === DOCUMENT_STATUSES.requiresUserInput &&
        selectedPages.length === documentPages.length
      ) {
        notification.error(t("extractingLastPageInfo"));
        return;
      }
      documentStore
        .extractDocumentPages(client, selectedPages, targetFlow?.id as string)
        .then(() => {
          handleClose();
          closeRightClickMenu();
          setTargetFlow(null);
        })
        .then(() => {
          notification.success(t("extractPagesSuccess"));
        })
        .then(() => {
          documentStore
            .loadDocument(
              client,
              documentStore.document?.identifier || "",
              false
            )
            .catch((error: Error) => {
              notification.error(t(error?.message || "loadDocumentPageError"));
            })
            .finally(() => {
              setButtonDisabled(false);
            });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "extractPagesError"));
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    };

    return (
      <Dialog
        open={open}
        fullWidth
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.deletePagesModalHeader}>
          {t("extractPages")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {documentStore.documentStatus !== DOCUMENT_STATUSES.requiresUserInput ||
        documentPages.length > selectedPages.length ? (
          <DialogContent>
            {`${t("pagesExtractionMessage")}`}
            <Autocomplete
              size="small"
              className={classes.flowSelect}
              getOptionLabel={(flow) => flow.label || ""}
              options={getFlows}
              autoHighlight
              value={targetFlow as { id: string; label: string }}
              onChange={(_, value) => {
                handleFlow(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, flow) => (
                <Box component="li" {...props} key={flow.id}>
                  {flow.label}
                </Box>
              )}
              renderInput={(params) => {
                return <TextField {...params} label={t("selectFlow")} />;
              }}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <CoreNotificationBox
              type={NotificationTypes.warning}
              className={classes.extractLastPageNotification}
            >
              {t("extractingLastPageInfo")}
            </CoreNotificationBox>
          </DialogContent>
        )}

        <DialogActions className={classes.deletePageModalActions}>
          <CoreButton
            onClick={handleClose}
            variant="outlined"
            disabled={documentStore.updatingPage}
          >
            {t("cancel")}
          </CoreButton>
          {(documentStore.documentStatus !==
            DOCUMENT_STATUSES.requiresUserInput ||
            documentPages.length > selectedPages.length) && (
            <CoreButton
              autoFocus
              onClick={handleExtractPages}
              variant="contained"
              isLoading={documentStore.updatingPage || buttonDisbled}
              disabled={
                documentStore.updatingPage || !targetFlow || buttonDisbled
              }
            >
              {t("confirm")}
            </CoreButton>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default ExtractDocumentPages;
