import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { CoreCard } from "../../../../../../core/CoreCard";
import { useStores } from "../../../../../../../stores/StoresProvider";
import {
  BillingCountry,
  BillingCustomer,
} from "../../../../../../../types/interfaces/subscription";
import CoreButton from "../../../../../../core/CoreButton";
import { BillingCardRender } from "../../BillingCardRender";
import { useNotification } from "../../../../../../../context/useNotification";
import BillingHelper, {
  EMPTY_COMPANY_DATA,
} from "../../../../../../../helper/billingHelper";
import {
  getBillingInformationFields,
  getCompanyInformationFields,
  MAIN_FIELDS,
} from "../../resources/billingFields";

const useStyles = makeStyles({
  tableContainer: {
    margin: "30px 60px",
  },
  updateActionContainer: {
    padding: "10px !important",
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "none !important",
    borderRadius: "0px 0px 2px 2px !important",
  },
  billingAddressContainer: { marginBottom: "0px", padding: "0px !important" },
});

const formatPayload = (
  form: { [key: string]: string },
  billingForm: { [key: string]: string },
  companyForm: { [key: string]: string },
  countries: BillingCountry[]
): BillingCustomer => {
  const { postalCode, city, line1, line2, country, ...billingInformation } =
    billingForm;
  const countryObj = countries?.find((item) => item.code === country);

  let payload = {
    ...form,
    companyInformation: EMPTY_COMPANY_DATA,
    billingInformation: {
      ...billingInformation,
      address: { postalCode, city, line1, line2 },
      country: countryObj,
    },
  } as unknown as BillingCustomer;

  if (companyForm?.name?.trim() !== "") {
    const { postalCode, city, line1, line2, country, ...companyInformation } =
      companyForm;
    const countryObj = countries?.find((item) => item.code === country);

    payload = {
      ...payload,
      companyInformation: {
        ...companyInformation,
        address: { postalCode, city, line1, line2 },
        country: countryObj,
      },
    } as unknown as BillingCustomer;
  }

  return payload;
};

const Component: FC<{ translate: TFunction }> = observer(({ translate }) => {
  const classes = useStyles();
  const notifier = useNotification();
  const { subscriptionStore, userStore } = useStores();

  const [isSaveInProgress, toggleSaveProgress] = useState(false);
  const [isSaveActionEnabled, toggleSaveEnabled] = useState(false);
  const [form, setForm] = useState<{ [key: string]: string }>(
    (subscriptionStore?.billingCustomer || {}) as unknown as {
      [key: string]: string;
    }
  );
  const [mainDetailsErrors, setMainDetailsErrors] = useState<{
    [key: string]: string;
  }>({});
  const [billingErrors, setBillingErrors] = useState<{
    [key: string]: string;
  }>({});
  const [companyErrors, setCompanyErrors] = useState<{
    [key: string]: string;
  }>({});
  const [billingForm, setBillingForm] = useState<{ [key: string]: string }>(
    BillingHelper.formatBillingInformationData(
      subscriptionStore?.billingCustomer?.billingInformation
    )
  );
  const [companyForm, setCompanyForm] = useState<{ [key: string]: string }>(
    BillingHelper.formatBillingInformationData(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      subscriptionStore?.billingCustomer?.companyInformation as any
    )
  );

  const billingFieldsConfig = useMemo(
    () => getBillingInformationFields(subscriptionStore.countries),
    [subscriptionStore.countries]
  );

  const companyFieldsConfig = useMemo(
    () =>
      getCompanyInformationFields(
        subscriptionStore.countries,
        !!companyForm?.name && companyForm?.name?.trim() !== ""
      ),
    [subscriptionStore.countries, companyForm?.name]
  );

  const updateMainForm = useCallback((formData: { [key: string]: string }) => {
    setForm(formData);
    toggleSaveEnabled(true);
  }, []);

  const updateBillingForm = useCallback(
    (formData: { [key: string]: string }) => {
      setBillingForm(formData);
      toggleSaveEnabled(true);
    },
    []
  );

  const updateCompanyForm = useCallback(
    (formData: { [key: string]: string }) => {
      setCompanyForm(formData);
      toggleSaveEnabled(true);
    },
    []
  );

  const validateForm = (): boolean => {
    const formErrors = BillingHelper.validateBillingForm(
      form,
      false,
      false,
      true
    );
    const billingErrors = BillingHelper.validateBillingForm(
      billingForm,
      false,
      false,
      false
    );

    let companyErrors: { [key: string]: string } | null = null;
    let needCompanyValidation = false;

    // Validate company email if company name is provided
    if (companyForm?.name && companyForm?.name?.trim() !== "") {
      needCompanyValidation = true;
      companyErrors = BillingHelper.validateBillingForm(
        companyForm,
        false,
        true,
        false
      );
    }

    let hasErrors = false;

    if (formErrors) {
      setMainDetailsErrors(formErrors);
      hasErrors = true;
    } else {
      setMainDetailsErrors({});
    }

    if (billingErrors) {
      setBillingErrors(billingErrors);
      hasErrors = true;
    } else {
      setBillingErrors({});
    }

    if (companyErrors && needCompanyValidation) {
      setCompanyErrors(companyErrors);
      hasErrors = true;
    } else {
      setCompanyErrors({});
    }

    return hasErrors;
  };

  const handleUpdate = () => {
    if (isSaveActionEnabled) {
      const hasErrors = validateForm();
      if (hasErrors) return;

      const payload = formatPayload(
        form,
        billingForm,
        companyForm,
        subscriptionStore.countries
      );

      setMainDetailsErrors({});
      setBillingErrors({});
      setCompanyErrors({});
      toggleSaveProgress(true);

      subscriptionStore
        .updateBilling(payload)
        .then(() => {
          notifier.success(translate("billing_update_successful"));
          toggleSaveProgress(false);
        })
        .catch((error: Error) => {
          notifier.error(error?.message || translate("billing_update_failed"));
          toggleSaveProgress(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setMainDetailsErrors({});
      setBillingErrors({});
      setCompanyErrors({});
    };
  }, []);

  const canUpdate =
    userStore.currentUserPermissions?.can("update", "billing") ?? false;

  return (
    <Box className={classes.tableContainer}>
      <BillingCardRender
        translate={translate}
        disabled={isSaveInProgress || !canUpdate}
        title="billing_main_details_title"
        form={form}
        errors={mainDetailsErrors}
        fields={MAIN_FIELDS}
        onFormUpdate={updateMainForm}
      />

      <BillingCardRender
        translate={translate}
        disabled={isSaveInProgress || !canUpdate}
        title="billing_address_details_title"
        form={billingForm}
        errors={billingErrors}
        fields={billingFieldsConfig}
        onFormUpdate={updateBillingForm}
      />

      <BillingCardRender
        translate={translate}
        disabled={isSaveInProgress || !canUpdate}
        title="billing_company_details_title"
        form={companyForm}
        errors={companyErrors}
        fields={companyFieldsConfig}
        containerClassName={classes.billingAddressContainer}
        onFormUpdate={updateCompanyForm}
      />

      {canUpdate && (
        <CoreCard className={classes.updateActionContainer}>
          <CoreButton
            disabled={!isSaveActionEnabled || isSaveInProgress}
            isLoading={isSaveInProgress}
            onClick={handleUpdate}
          >
            {translate("billing_update_action_label")}
          </CoreButton>
        </CoreCard>
      )}
    </Box>
  );
});

export const BillingDetails = memo(Component);
