import React, { useMemo, useState, useEffect } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { Schema } from "json-schema-faker";
import _, { isBoolean } from "lodash";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import {
  AssetConfiguration,
  AssetTemplateConfiguration,
  FormData,
  FormField,
  FlowInterface,
  FlowNode,
} from "../../../types/interfaces";
import {
  FlowEnum,
  ContextVariableEnum,
  JSON_EDITOR_TYPES,
  FIELD_TYPE,
  NODE_ASSET_TYPE,
  NODES_PARAMETERS_KEYS,
} from "../../../types/constants";
import ConfigHelper from "../../../helper/configHelper";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { useStores } from "../../../stores/StoresProvider";
import CoreButton from "../../core/CoreButton";
import FlowHelper from "../../../helper/flowHelper";
import { alphanumericRegex } from "../../../types/regex";
import JsonSchemaConverter from "../../../helper/jsonSchemaConverter";
import GenerateSchemaModal from "./GenerateSchemaModal";
import CustomOptions from "./CustomOptions";

interface Props {
  config: AssetTemplateConfiguration;
  t: TFunction;
  onAdd: (value: { [key: string]: unknown }) => void;
  closeDrawer: () => void;
  list: FormData[];
  variableKeyToEdit: string;
  onEdit: (value: { [key: string]: unknown }) => void;
}

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
});

const ContextVariableConfig: React.FC<Props> = observer(
  ({ config, t, onAdd, closeDrawer, list, variableKeyToEdit, onEdit }) => {
    const { flowSettingsStore, flowStore } = useStores();
    const reactFlow = useReactFlow();

    const [formData, setFormData] = useState<FormData>({});
    const [fieldsErrors, setFieldsErrors] = useState<{
      [key: string]: string;
    }>({});
    const [keyManuallyChanged, setKeyManuallyChanged] = useState(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [generatedSchemaValue, setGeneratedSchemaValue] =
      useState<string>("");

    const classes = useStyles();

    const nodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const checkVariableUsage = (fieldKey: string, formKey?: string) => {
      if (!flowSettingsStore.isDiagramEditable) {
        return true;
      }

      if (fieldKey === ContextVariableEnum.key) {
        return nodes?.some((node) => {
          const selectedItem = list?.find(
            (item) => item.key === variableKeyToEdit
          );

          const pathIncludesKey = [node?.inputPath, node?.outputPath]?.some(
            (path) =>
              path?.includes(formKey ? formKey : (selectedItem?.key as string))
          );

          const specialCaseConditions =
            [
              NODE_ASSET_TYPE.claritextOcr,
              NODE_ASSET_TYPE.dataEncoding,
              NODE_ASSET_TYPE.exportVariables,
            ]?.includes(node?.key as NODE_ASSET_TYPE) &&
            [
              NODES_PARAMETERS_KEYS.executionSchema,
              NODES_PARAMETERS_KEYS.inputVariables,
            ]?.some((param) => {
              const paramValues = node?.parameters?.[param];
              if (Array.isArray(paramValues)) {
                return paramValues?.some((value: string) =>
                  value?.includes(formData?.key as string)
                );
              }
              return false;
            });

          return pathIncludesKey || specialCaseConditions;
        });
      }

      return false;
    };

    const keyShouldBeDisabled = useMemo(() => {
      if (variableKeyToEdit) {
        return checkVariableUsage(ContextVariableEnum.key);
      }

      return false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableKeyToEdit, formData?.key]);

    const restrictKeyChange = useMemo(() => {
      return checkVariableUsage(
        ContextVariableEnum.key,
        formData?.key as string
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.key]);

    const getFieldsConfiguration = (form: FormData) => {
      return ConfigHelper.getFieldsConfiguration(
        t,
        config?.parameters,
        fieldsErrors,
        form,
        flowSettingsStore.flow as FlowInterface,
        flowStore.flows,
        setFormData,
        t("variableName"),
        true,
        flowSettingsStore.isDiagramEditable,
        keyShouldBeDisabled
      );
    };

    const formattedFields = useMemo(
      () => getFieldsConfiguration(formData),
      //  eslint-disable-next-line react-hooks/exhaustive-deps
      [
        t,
        config,
        fieldsErrors,
        formData,
        flowSettingsStore.isDiagramEditable,
        keyShouldBeDisabled,
      ]
    );

    const onFormChange = (updatedForm: FormData, key: string) => {
      if (key === ContextVariableEnum.name && !keyManuallyChanged) {
        setFieldsErrors({
          ...fieldsErrors,
          [ContextVariableEnum.key]: "",
          [ContextVariableEnum.name]: "",
        });
      } else {
        setFieldsErrors({
          ...fieldsErrors,
          [key]: "",
        });
      }

      if (key === ContextVariableEnum.key) {
        if (alphanumericRegex.test(updatedForm.key as string)) return;
        setKeyManuallyChanged(true);
      }

      setFieldsErrors({
        ...fieldsErrors,
        [key]: "",
      });

      let shouldCheckKey = false;
      let shouldRegenerateKey = true;

      if (
        key === ContextVariableEnum.name &&
        variableKeyToEdit &&
        restrictKeyChange
      ) {
        shouldCheckKey = restrictKeyChange;
      }

      if (keyManuallyChanged) {
        if (updatedForm?.[ContextVariableEnum.key]) {
          shouldRegenerateKey = false;
        } else {
          setKeyManuallyChanged(false);
        }
      } else if (updatedForm?.[ContextVariableEnum.key]) {
        shouldRegenerateKey = true;
      }

      const updatedFormData = {
        ...formData,
        ...updatedForm,
        ...(key === ContextVariableEnum.name &&
        shouldRegenerateKey &&
        !shouldCheckKey
          ? { key: _.camelCase(updatedForm.name as string) }
          : {}),
      };

      setFormData(
        ConfigHelper.filterParams(
          getFieldsConfiguration(updatedFormData),
          updatedFormData
        )
      );
    };

    useEffect(() => {
      if (variableKeyToEdit) {
        const selectedItem = list?.find(
          (item) => item.key === variableKeyToEdit
        );

        if (selectedItem) {
          const { parameters, ...rest } = selectedItem;
          setFormData({
            ...rest,
            ...(parameters || {}),
          } as FormData);
        }
      } else {
        setFormData({});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableKeyToEdit]);

    const formattedData = (): AssetConfiguration | undefined => {
      const { name, key, ...parameters } = ConfigHelper.filterParams(
        formattedFields,
        formData
      ) as unknown as FormData;

      const currentErrors = {} as { [key: string]: string };

      //Check if mandatory field is empty or has undefined value
      if (formattedFields) {
        formattedFields.forEach((config) => {
          if (
            config.isMandatory &&
            !isBoolean(formData[config.key]) &&
            FlowHelper.checkIfValueIsEmpty(
              formData[config.key],
              config.canContainSpacesOnly
            )
          ) {
            currentErrors[config.key] = t("validationFieldsError");
          }

          if (config?.type === FIELD_TYPE.json && formData?.[config?.key]) {
            const validationResult = JsonSchemaConverter.validateSchema(
              formData?.[config.key] as Schema,
              JSON_EDITOR_TYPES.object,
              t
            );

            if (!validationResult.isValid) {
              currentErrors[config?.key] = validationResult.errorMessage || "";
            }
          }
        });
      }

      if (Object.keys(currentErrors)?.length > 0) {
        setFieldsErrors(currentErrors);
        return;
      } else {
        setFieldsErrors({});
      }

      const assetConfiguration = {
        name,
        key: key,
        parameters,
      } as unknown as AssetConfiguration;

      return assetConfiguration;
    };

    const handleConfirm = () => {
      if (!flowSettingsStore.isDiagramEditable) {
        closeDrawer();
        return;
      }

      const currentErrors = {} as { [key: string]: string };

      //Merge context variables or fields from existing nodes with variables/fields from the current node being created to check if the key is unique
      const mergedVariablesList = [
        ...flowSettingsStore.getAllContextVariables(),
        ...list,
      ];

      if (
        mergedVariablesList?.some(
          (item) =>
            item.key === formData.key && formData.key !== variableKeyToEdit
        )
      ) {
        currentErrors[FlowEnum.key] = t("keyNotUnique");
      }

      const assetConfigurationData = formattedData();

      if (!assetConfigurationData) return;

      if (Object.keys(currentErrors)?.length > 0) {
        setFieldsErrors(currentErrors);
        return;
      }

      if (variableKeyToEdit) {
        onEdit(assetConfigurationData as unknown as { [key: string]: unknown });
      } else {
        onAdd(assetConfigurationData as unknown as { [key: string]: unknown });
      }

      closeDrawer();
    };

    const getButtonLabel = useMemo(() => {
      return !flowSettingsStore.isDiagramEditable
        ? t("close")
        : variableKeyToEdit !== ""
        ? t("edit")
        : t("add");
    }, [t, variableKeyToEdit, flowSettingsStore.isDiagramEditable]);

    const setSchemaValue = (value: Schema) => {
      setFormData({
        ...formData,
        [flowSettingsStore?.generatedSchemaField?.key as string]: value,
      });

      flowSettingsStore.retriggerRendererValue(true);
      flowSettingsStore.setGeneratedSchemaField(null);
      setGeneratedSchemaValue("");
    };

    const onCloseSchemaModal = () => {
      flowSettingsStore.setGeneratedSchemaField(null);
      setGeneratedSchemaValue("");

      if (showError) {
        setShowError(false);
      }
    };

    const renderAddOptionButton = useMemo(() => {
      return formattedFields?.some((field) =>
        field?.options?.some(
          (option) => option?.key === formData?.type && option?.canAddOptions
        )
      );
    }, [formattedFields, formData?.type]);

    const optionAssetCategoryKey = useMemo(() => {
      const fieldWithOption = formattedFields?.find((field) =>
        field?.options?.some((option) => option?.key === formData?.type)
      );

      return (
        fieldWithOption?.options?.find(
          (option) => option?.key === formData?.type
        )?.optionTypeFormat || ""
      );
    }, [formattedFields, formData?.type]);

    return (
      <>
        <CoreFormRenderer
          fields={formattedFields as unknown as FormField[]}
          data={formData}
          translation={t}
          onChange={onFormChange}
          isViewMode={!flowSettingsStore.isDiagramEditable}
        />

        <GenerateSchemaModal
          t={t}
          isOpen={!!flowSettingsStore.generatedSchemaField}
          onClose={onCloseSchemaModal}
          type={formData?.type as string}
          editorValue={generatedSchemaValue}
          setEditorValue={setGeneratedSchemaValue}
          setSchemaValue={setSchemaValue}
          showError={showError}
          setShowError={setShowError}
        />

        {renderAddOptionButton && (
          <CustomOptions
            t={t}
            formData={formData}
            setFormData={setFormData}
            optionAssetCategoryKey={optionAssetCategoryKey}
          />
        )}

        <Box className={classes.footer}>
          <CoreButton
            variant={
              !flowSettingsStore.isDiagramEditable ? "outlined" : "contained"
            }
            onClick={handleConfirm}
          >
            {getButtonLabel}
          </CoreButton>
        </Box>
      </>
    );
  }
);

export default ContextVariableConfig;
