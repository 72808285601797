import React, { useRef, useEffect, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import { useStores } from "../../../../stores/StoresProvider";
import SearchingResponse from "./SearchingResponse";
import MessageRenderer from "./MessageRenderer";
import ChatGptHelper from "../../../../helper/chatGptHelper";
import ChatGptLanguageSelector from "./ChatGptLanguageSelector";

interface Props {
  t: TFunction;
}

const ClariChatTab: React.FC<Props> = observer(({ t }) => {
  const {
    chatGptStore: {
      messages,
      isResponseLoading,
      setIsResponseGenerating,
      setIsResponseLoading,
      isDataLoading,
      clearMessages,
      isResponseGenerating,
      setIsOnSuggestions,
      isScrollDisabled,
    },
  } = useStores();
  const [triggerClearChat, setTriggerClearChat] = useState({});
  const theme = useTheme();

  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    scrollingBox: {
      overflow: "auto",
    },
    contentLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      flex: "1",
    },
    link: {
      fontSize: "14px",
      whiteSpace: "nowrap",
      textAlign: "right",
      overflow: "hidden",
      padding: "10px 15px",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
        color: theme.palette.highlight.main,
      },
      minWidth: "100px",
      alignSelf: "flex-end",
    },
    downloadAndClear: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    language: {
      padding: "0 5px 0 5px",
    },
  });

  const classes = useStyles();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messages?.length > 0 && messagesEndRef?.current && !isScrollDisabled)
      messagesEndRef?.current?.scrollIntoView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isResponseGenerating]);

  const handleClearChat = () => {
    setIsResponseGenerating(false);
    setIsResponseLoading(false);
    setIsOnSuggestions(false);
    clearMessages();
    setTriggerClearChat({});
  };

  useEffect(() => {
    return () => {
      setIsResponseGenerating(false);
      setIsResponseLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerClearChat]);

  if (isDataLoading) {
    return (
      <Box className={classes.contentLoading}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.downloadAndClear}>
        <Typography className={classes.link} onClick={handleClearChat}>
          {t("clearChatButton")}
        </Typography>

        {messages?.length > 0 && (
          <Typography
            className={classes.link}
            onClick={() => ChatGptHelper.handleDownloadConversation(messages)}
          >
            {t("downloadChat")}
          </Typography>
        )}
        <Box className={classes.language}>
          <ChatGptLanguageSelector />
        </Box>
      </Box>

      <Box id="clariChatBox" className={classes.scrollingBox}>
        <MessageRenderer t={t} messages={messages} isHomeChat={false} />

        {isResponseLoading && <SearchingResponse t={t} />}

        <div ref={messagesEndRef} id="clariChatBox" />
      </Box>
    </Box>
  );
});

export default ClariChatTab;
