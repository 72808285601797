import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import FlowsSidebar from "./components/FlowsSidebar";
import { useStores } from "../../../stores/StoresProvider";
import FlowDetails from "../flow/FlowDetails";
import FlowDataCategories from "../flow/sub-categories/FlowDataCategories";
import { appRoutes } from "../../../configs/routes";
//TODO: To be deleted
// import AddFlow from "../addNewFlow/AddFlow";
import { useNotification } from "../../../context/useNotification";
import { AppSuspense } from "../../main/AppSuspense";
import AccessDenied from "../errorPages/AccessDenied";

const FlowsList: React.FC = observer(() => {
  const { t, ready } = useTranslation("flows");
  const [reload, setReload] = useState(false);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const notification = useNotification();
  const useStyles = makeStyles({
    sidebarContainer: {
      marginTop: "64px",
    },
  });
  const { mainStore, flowStore, userStore } = useStores();

  const client = useApolloClient();
  const FLOWS_ROUTES = [
    {
      path: appRoutes.Flows(),
      component: FlowDetails,
      exact: true,
      abilityKey: "flows",
      abilityAction: "read",
      permissions: userStore.currentUserPermissions,
    },
    {
      path: appRoutes.FlowDetails(),
      component: FlowDetails,
      exact: true,
      abilityKey: "flows",
      abilityAction: "read",
      permissions: userStore.currentUserPermissions,
    },
    {
      path: appRoutes.FlowDataCategories(),
      component: FlowDataCategories,
      exact: true,
      abilityKey: "flows",
      abilityAction: "read",
      permissions: userStore.currentUserPermissions,
    },
    //TODO: To be deleted
    // {
    //   path: appRoutes.FlowSettings(),
    //   component: FlowSettingsPage,
    //   exact: true,
    //   abilityKey: "flows",
    //   abilityAction: "read",
    //   permissions: userStore.currentUserPermissions,
    // },
    // {
    //   path: appRoutes.AddFlow(),
    //   component: AddFlow,
    //   exact: true,
    //   abilityKey: "flows",
    //   abilityAction: "create",
    //   permissions: userStore.currentUserPermissions,
    // },
  ];

  const classes = useStyles();

  useEffect(() => {
    setIsRefreshDisabled(true);
    flowStore
      .getAllFlows(true, false)
      .catch((error: Error) => {
        notification.error(t(error?.message || "errorGettingFlows"));
      })
      .finally(() => setIsRefreshDisabled(false));
    mainStore.collapseSidebar();

    return () => {
      mainStore.resetSidebar();
      setReload(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, flowStore, mainStore, reload, t]);

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <>
      <Box className={classes.sidebarContainer}>
        <FlowsSidebar
          setReload={setReload}
          reload={reload}
          isRefreshDisabled={isRefreshDisabled}
        />
      </Box>
      <Switch>
        {FLOWS_ROUTES.map((item) => {
          const {
            abilityAction,
            abilityKey,
            component,
            exact,
            path,
            permissions,
          } = item;
          if (abilityKey) {
            if (permissions?.cannot(abilityAction, abilityKey)) {
              return <AccessDenied key={path} page={path ?? ""} />;
            }
          }

          if (!component) {
            return null;
          }

          const Component = component;

          return (
            <Route key={path} exact={exact} path={path}>
              <Component />
            </Route>
          );
        })}
      </Switch>
    </>
  );
});

export default FlowsList;
