export const ROUTE_PAGES = {
  flow_id: "flow_id",
  flow: "flow",
  management: "management",
  system: "system",
  news: "news",
  documentation: "documentation",
  account_apiKeys: "account_apiKeys",
  account_connections: "account_connections",
  account_createConnection: "account_createConnection",
  account_editConnection: "account_editConnection",
  account_billing: "account_billing",
  account_team: "account_team",
  documents: "documents",
  flow_settings: "flow_settings",
  login: "login",
  register: "register",
  successfully_register: "successfully_register",
  forgot_password: "forgot_password",
  reset_password: "reset_password",
  activating_account: "activating_account",
  personal_information: "personal_information",
  editConnection: "editConnection",
  documentValidate: "documentValidate",
  home: "home",
  addFlow: "add_flow",
  contextEvents: "activityLog",
  contextEvent: "activityLogItem",
  human_statistics: "human_statistics",
  machine_statistics: "machine_statistics",
  signup_journey: "signup_journey",
  qa: "qa",
  qaDocuments: "qaDocuments",
  qa_document_view: "qa_document_view",
  settings: "settings",
  subscription: "subscription",
  payment_details: "payment_details",
  upgrade_plan: "upgrade_plan",
  statistics: "statistics",
  flows: "flows",
  document_view: "document_view",
  retry_next_document: "retry_next_document",
  invitation: "invitation",
  namespaces: "namespaces",
  onedriveRedirect: "onedriveRedirect",
  resend_activation: "resend_activation",
  chatGpt: "chatGpt",
  flowSettings: "flowSettings",
  flow_data_categories: "flow_data_categories",
};
