import React, { useEffect, useMemo, useRef, useCallback, memo } from "react";
import { v4 } from "uuid";
import { observer } from "mobx-react";
import { TFunction } from "react-i18next";

import {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_TableInstance,
  MRT_Row,
  MRT_Column,
} from "material-react-table";

import { VAL_TYPES } from "../../../../../types/constants";
import {
  DocumentLineItemRow,
  DocumentZone,
} from "../../../../../types/interfaces";
import TableCellRenderer from "./TableCellRenderer";

import DataGridLineItem from "./DataGridLineItem";
import { DocumentStore } from "../../../../../stores/DocumentStore";
import { TableLineActionsColumn } from "./TableLineActionsColumn";

const createNewBox = () => {
  const newBox = {
    box: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      rotation: 0,
    },
    points: [],
  };
  return newBox;
};

interface Props {
  documentStore: DocumentStore;
  width: number;
  t: TFunction;
}

const TableLineItem: React.FC<Props> = observer(
  ({ documentStore, width, t }) => {
    const currentLineItemData = useMemo(
      () =>
        // Exclude ignored table rows
        documentStore.focusedLineItem?.data,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [documentStore.focusedLineItem, documentStore.focusedLineItem?.data]
    );

    const currentLineItemType = useMemo(
      () => documentStore.focusedFieldCanvas,
      [documentStore.focusedFieldCanvas]
    );

    const fieldHeaders = useMemo(
      () => documentStore.focusedDocumentLineItemHeaders,
      [documentStore.focusedDocumentLineItemHeaders]
    );

    const rows = useMemo(() => {
      return (currentLineItemData ?? []).map((row, index) => {
        let formattedRow = { unique: v4() };
        const headerKeys = fieldHeaders.map((header) => header.key ?? "");
        headerKeys.forEach((cell) => {
          formattedRow = {
            ...formattedRow,
            [cell]:
              (row.cells as unknown as Record<string, DocumentZone>)[cell]
                ?.text ?? "",
          };
        });
        return {
          ...row,
          ...formattedRow,
          index,
          isIgnored: row.isIgnored,
          manuallyAdded: row.manuallyAdded,
        };
      });
      // currentLineItemData needed to rerender row construct when split line is done
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      currentLineItemData,
      currentLineItemData?.length,
      documentStore.lineItems,
      documentStore.document?.status?.details?.lineItems,
    ]);

    const errors = useMemo(
      () =>
        documentStore.document?.status?.details?.lineItems?.[
          currentLineItemType ?? ""
        ] ?? {},
      [documentStore.document?.status.details.lineItems, currentLineItemType]
    );

    const isViewMode = documentStore.document?.isViewMode ?? false;

    const updatedData = (
      data: DocumentLineItemRow[],
      cell: MRT_Cell,
      value: unknown
    ) => {
      const rowIndex = rows.find((row) => row.unique === cell.row.id)?.index;

      return data.map((row, index) => {
        const condition = rowIndex === index;
        return condition
          ? {
              ...row,
              cells: {
                ...row.cells,
                [cell.column.id]: {
                  ...(row.cells[cell.column.id]
                    ? row.cells[cell.column.id]
                    : createNewBox()),
                  manuallyAdded: true,
                  text: value as string,
                },
              },
            }
          : row;
      }) as DocumentLineItemRow[];
    };

    const handleSaveCell = useCallback(
      (cell: MRT_Cell, value: unknown, disableClearEditingCell = false) => {
        documentStore.updateCurrentLineItemData(
          updatedData(currentLineItemData ?? [], cell, value)
        );

        if (!disableClearEditingCell) {
          documentStore.setCurrentEditingCell(null);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentLineItemData]
    );

    // Used to render "Action" column
    // Action column will be visible only if a custom row was created
    const hasManualRows = useMemo(() => {
      return rows?.some((row) => row?.manuallyAdded);
    }, [rows]);

    const handleHeaderType = (type: string) => {
      switch (type) {
        case VAL_TYPES.string:
          return VAL_TYPES.text;
        case VAL_TYPES.integer:
          return VAL_TYPES.number;
        default:
          return VAL_TYPES.text;
      }
    };

    const headers = useMemo(() => {
      const rowActions = hasManualRows
        ? [
            {
              accessorKey: "actions",
              header: t("lineItemActionsHeader"),
              isMandatory: false,
              editable: false,
              enableColumnOrdering: false,
              enableEditing: false,
              enableSorting: false,
              size: 70,
              minSize: 70,
              Cell: (props: {
                row: MRT_Row<DocumentLineItemRow & { index: number }>;
              }) => (
                <TableLineActionsColumn
                  t={t}
                  row={props?.row?.original}
                  documentStore={documentStore}
                />
              ),
            },
          ]
        : [];

      return [
        ...(fieldHeaders ?? []).map((header) => {
          const errorsPerHeader = errors?.[header.key];
          return {
            accessorKey: header.key,
            header:
              //TODO: Fix this
              // header.isMandatory
              //   ? ((
              //       <>
              //         {header.name || header.key}
              //         <span
              //           style={{
              //             color: theme.palette.error.main,
              //             padding: "2px",
              //           }}
              //         >
              //           *
              //         </span>
              //       </>
              //     ) as unknown as string)
              //   :
              header.key,
            // isMandatory: header.isMandatory,
            // isVisible: header.isVisible,
            editable: !isViewMode,
            size: width / [...fieldHeaders, ...rowActions].length - 10,
            minSize: 50,
            muiTableBodyCellEditTextFieldProps: {
              type: handleHeaderType(header?.type),
            },
            Cell: (props: {
              cell: MRT_Cell;
              renderedCellValue: number | string | React.ReactNode;
              column: MRT_Column;
              row: MRT_Row;
              table: MRT_TableInstance;
            }) => (
              <div id={`${props.row.index}`}>
                <TableCellRenderer
                  params={props}
                  errors={errorsPerHeader}
                  translation={t}
                />
              </div>
            ),
          };
        }),
        ...rowActions,
      ];
    }, [
      hasManualRows,
      t,
      fieldHeaders,
      documentStore,
      errors,
      isViewMode,
      width,
      // theme.palette.error.main,
    ]) as MRT_ColumnDef[];

    const gridApi = useRef<MRT_TableInstance>(
      null
    ) as React.MutableRefObject<MRT_TableInstance>;

    useEffect(() => {
      try {
        if (documentStore.currentEditingCell && headers.length) {
          const { rowIndex, colKey } = documentStore.currentEditingCell;
          if (
            rowIndex === undefined ||
            colKey === undefined ||
            rows[rowIndex] === undefined
          )
            return;

          if (!rows[rowIndex].isIgnored) {
            const cell = gridApi.current
              ?.getRow(rows[rowIndex].unique)
              ?.getAllCells()
              ?.find((cell) => cell.column.id === colKey);

            if (cell) gridApi?.current?.setEditingCell(cell as MRT_Cell);
          } else {
            gridApi?.current?.setEditingCell({} as MRT_Cell);
          }
        }
      } catch {
        // No action needed
      }
    }, [documentStore.currentEditingCell, gridApi, headers, rows]);

    useEffect(() => {
      return () => {
        documentStore.setCurrentEditingCell(null);
      };
    }, [documentStore]);

    return (
      <DataGridLineItem
        rows={
          rows?.filter((item) => !item?.isIgnored) as unknown as Record<
            string,
            string | number
          >[]
        }
        handleSaveCell={handleSaveCell}
        headers={headers}
        gridApi={gridApi}
        t={t}
      />
    );
  }
);

export default memo(TableLineItem);
