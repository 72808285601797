import { ActivityLogsFilters } from "../../../../types/constants";

export class ActivityLogHelper {
  static formatQueryFilters(filters: ActivityLogsFilters | null): {
    [key: string]: string | string[];
  } {
    if (!filters) {
      return {};
    }

    const {
      flowsFilter,
      statusFilter,
      eventTypeFilter,
      dateFilter,
      inputSearchFilter,
    } = filters;

    let filterResult = {};

    if (flowsFilter && flowsFilter?.length > 0) {
      filterResult = {
        flows: flowsFilter,
      };
    }

    if (statusFilter && statusFilter?.length > 0) {
      filterResult = {
        ...filterResult,
        status: statusFilter,
      };
    }

    if (eventTypeFilter && eventTypeFilter?.length > 0) {
      filterResult = {
        ...filterResult,
        eventTypes: eventTypeFilter,
      };
    }

    if (dateFilter && dateFilter?.length > 0) {
      filterResult = {
        ...filterResult,
        date: {
          startDate: dateFilter[0] || "",
          ...(dateFilter?.[1] ? { endDate: dateFilter[1] } : {}),
        },
      };
    }

    if (inputSearchFilter && inputSearchFilter?.length > 0) {
      filterResult = {
        ...filterResult,
        documentIdentifiers: inputSearchFilter,
      };
    }

    return filterResult;
  }
}
