/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from "react";
import { TFunction } from "i18next";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { FormData, FormField } from "../../types/interfaces";
import CoreInput from "../core/CoreInput";
import { backendRoutes } from "../../configs/backendRoutes";
import { useStores } from "../../stores/StoresProvider";
import { useNotification } from "../../context/useNotification";

interface Props {
  cancel?: () => void;
  linkType?: "preview" | "direct";
  multiselect?: boolean;
  extensions?: string[];
  disabled?: boolean;
  onChange: (value: FormData) => void;
  field: FormField;
  translation: TFunction;
  value: unknown;
  errorText?: string;
}

interface ChooserFile {
  id: string;
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string;
  isDir: boolean;
}

interface ChooserOptions {
  success: (files: ReadonlyArray<ChooserFile>) => void;
  cancel?: () => void;
  linkType?: "preview" | "direct";
  multiselect?: boolean;
  extensions?: string[];
  folderselect?: boolean;
  sizeLimit?: number;
}

interface DropboxInterface {
  choose(options: ChooserOptions): void;
}

export const DropBoxRenderer: React.FC<Props> = ({
  onChange,
  field,
  value,
  translation,
  errorText,
}) => {
  const readOnly = field.props?.readOnly;
  const theme = useTheme();
  const { mainStore } = useStores();
  const notification = useNotification();

  const switchAccount = () => {
    onChange({ [field.key]: undefined });
    login();
  };

  const login = () => {
    window.open(
      backendRoutes.DropboxAuthorization(),
      "authorization",
      "width=800,height=600"
    );
  };

  const eventListener = (event: MessageEvent) => {
    const { data } = event as {
      data?: {
        accessToken?: string;
        refreshToken?: string;
        account?: string;
        source?: string;
        error?: string;
      };
    };

    if (!data || data.error) return;
    if (data.source !== "claritext") return;
    if (data.refreshToken && data.account) {
      try {
        openDropBoxPopup(data.refreshToken, data.account);
      } catch {
        notification.error(translation("errorOpenDropBoxPopup"));
      }
    }
  };

  const openDropBoxPopup = (refreshToken: string, data: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((window as any).Dropbox as DropboxInterface).choose({
      success: (files: readonly ChooserFile[]) => {
        success(files, refreshToken, data);
      },
      folderselect: true,
      linkType: "preview",
      multiselect: false,
    });
  };

  const success = (
    files: readonly ChooserFile[],
    refreshToken: string,
    data: string
  ) => {
    const file = files[0];
    const { id, name } = file;
    const {
      email,
      name: { display_name: displayName },
    } = JSON.parse(data) as {
      email: string;
      name: { display_name: string };
    };

    onChange({
      [field.key]: {
        credentials: {
          refreshToken,
        },
        directory: {
          id,
          name,
        },
        user: {
          email,
          name: displayName,
        },
      },
    });
  };

  const classes = makeStyles({
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginRight: "5px",
    },
    userContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    auto: { margin: "auto" },
    typography: { marginRight: "10px" },
  })();

  useEffect(() => {
    window.addEventListener("message", eventListener);

    const script = document.getElementById("dropboxjs") as HTMLScriptElement;
    if (!script) {
      const dropboxAppKey = mainStore.settings?.dropboxAppKey;

      if (!dropboxAppKey) return;

      const script = document.createElement("script");

      script.setAttribute("type", "text/javascript");
      script.setAttribute(
        "src",
        "https://www.dropbox.com/static/api/2/dropins.js"
      );
      script.setAttribute("data-app-key", dropboxAppKey);
      script.setAttribute("id", "dropboxjs");

      document.head.appendChild(script);
    }

    return () => {
      window.removeEventListener("message", eventListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (value) {
    const { directory, user } = value as {
      directory: {
        id: string;
        name: string;
      };
      user: {
        email: string;
        name: string;
      };
    };

    const handleClear = () => {
      onChange({ [field.key]: undefined });
    };

    const clearInput = (
      <IconButton onClick={handleClear} disabled={readOnly}>
        <HighlightOffIcon />
      </IconButton>
    );

    return (
      <Box>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <CoreInput value={directory.name} readOnly endAdornment={clearInput} />

        <Box className={classes.userContainer}>
          <Box>
            <Typography variant="body2" className={classes.auto}>
              {user.name}
            </Typography>
            <Typography variant="body2" className={classes.auto}>
              {user.email}
            </Typography>
          </Box>

          <Button onClick={switchAccount} disabled={readOnly}>
            <Typography className={classes.typography}>
              {translation("switchUser")}
            </Typography>
            <ChangeCircleIcon />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <Box>
          <CoreInput
            value={translation("noDropboxFolder")}
            readOnly
            fullWidth
            error={!!field.errorText || !!errorText}
            errorText={field.errorText || errorText}
            icon={CreateNewFolderIcon}
            iconOnClick={login}
          />
        </Box>
      </Box>
    </Box>
  );
};
