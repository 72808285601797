import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, SelectChangeEvent } from "@mui/material";

import CoreSelect from "../../../../core/CoreSelect";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreConfirmModal from "../../../../core/CoreConfirmModal";
import { SelectOptionDef } from "../../../../../types/interfaces";
import { CoreStatusTag } from "../../../../core/CoreStatusTag";
import { DocumentStages } from "../../../../../types/enums";
import { useNotification } from "../../../../../context/useNotification";

const useStyles = makeStyles({
  select: {
    minWidth: "150px",
  },
  box: {
    paddingRight: "20px",
  },
});

interface Props {
  t: TFunction;
}

const DiagramVersionSelector: React.FC<Props> = observer(({ t }) => {
  const { flowSettingsStore } = useStores();
  const notification = useNotification();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedDiagramId, setSelectedDiagramId] = useState<string | null>(
    null
  );

  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newDiagramId = event.target.value as string;

    if (
      //TODO: diagramHasBeenUpdated is not fully handled yet
      flowSettingsStore.diagramHasBeenUpdated &&
      newDiagramId !== flowSettingsStore.currentDiagramId
    ) {
      setSelectedDiagramId(newDiagramId);
      setIsModalOpened(true);
    } else {
      refetchDiagram(newDiagramId, false);
    }
  };

  const refetchDiagram = (diagramId: string, modalRefetch?: boolean) => {
    flowSettingsStore
      .getFlowDiagram(diagramId)
      .then((res) => {
        flowSettingsStore.setCurrentDiagramId(res?.identifier);
        if (modalRefetch) {
          setIsModalOpened(false);
          setSelectedDiagramId(null);
        }
        flowSettingsStore.setDiagramHasBeenUpdated(false);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "getFlowDiagramError"));
      });
  };

  const handleOnModalConfirm = () => {
    if (selectedDiagramId) {
      refetchDiagram(selectedDiagramId, true);
    }
  };

  const handleModalFalse = () => {
    setSelectedDiagramId(null);
    setIsModalOpened(false);
  };

  const options = useMemo(() => {
    return (
      flowSettingsStore.flowDiagrams?.map((diagram) => ({
        key: diagram.identifier,
        value: diagram.identifier,
        label: `v${diagram.version?.toString()}`,
        element: diagram.current ? (
          <CoreStatusTag
            label={t("versions_is_current_label")}
            type={DocumentStages.RUNNING}
          />
        ) : (
          <></>
        ),
      })) as SelectOptionDef[]
    )?.sort((a, b) => {
      const numA = parseInt(a.label.replace("v", ""), 10);
      const numB = parseInt(b.label.replace("v", ""), 10);
      return numB - numA;
    });
  }, [flowSettingsStore.flowDiagrams, t]);

  const value = useMemo(() => {
    const selectedDiagram = flowSettingsStore.flowDiagrams?.find(
      (diagram) => diagram.identifier === flowSettingsStore.currentDiagramId
    );

    return selectedDiagram?.identifier;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.flowDiagrams, flowSettingsStore.currentDiagramId]);

  if (
    !flowSettingsStore.flowDiagrams ||
    flowSettingsStore.flowDiagrams?.length === 0
  ) {
    return <></>;
  }

  return (
    <>
      {flowSettingsStore.flowDiagrams &&
      flowSettingsStore.flowDiagrams?.length > 0 ? (
        <Box className={classes.box}>
          <CoreSelect
            value={value}
            options={options}
            onChange={handleChange}
            selectInputStyle={classes.select}
          />
        </Box>
      ) : (
        <></>
      )}

      <CoreConfirmModal
        open={isModalOpened}
        onClose={handleModalFalse}
        title={t("versionChangedTitle")}
        subtitle={t("versionChangedSubtitle")}
        cancelButtonLabel={t("cancel")}
        confirmButtonLabel={t("discard")}
        onCancel={handleModalFalse}
        onConfirm={handleOnModalConfirm}
        isDisable={flowSettingsStore.loadingDiagram}
      />
    </>
  );
});

export default DiagramVersionSelector;
