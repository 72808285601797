import React, { FC, useEffect, useMemo } from "react";
import { TFunction } from "i18next";

import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import {
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Tabs,
  Tab,
  Drawer,
  alpha,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MessageIcon from "@mui/icons-material/Message";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import ChatBoxTitleContainer from "./ChatBoxTitleContainer";
import { ChatGptTabs } from "../../../../types/constants";
import ChatHomeTab from "./ChatHomeTab";
import ClariChatTab from "./ClariChatTab";
import { useStores } from "../../../../stores/StoresProvider";
import ChatGptInput from "./ChatGptInput";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
}

const ChatBoxPopup: FC<Props> = observer(({ t, isOpen, onClose }) => {
  const {
    chatGptStore: {
      viewTab,
      setChatGptViewTab,
      tabToReturn,
      setTabToReturn,
      isOnSuggestions,
      clearMessages,
      setIsOnSuggestions,
      setIsResponseGenerating,
      setIsResponseLoading,
    },
  } = useStores();

  const isClariChatTab = useMemo(() => {
    return viewTab === ChatGptTabs.clariChat;
  }, [viewTab]);

  const theme = useTheme();
  const useStyles = makeStyles({
    title: {
      "&.MuiDialogTitle-root": {
        padding: 0,
        minHeight: "50px",
      },
    },
    content: {
      "&.MuiDialogContent-root": {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        overflow: isClariChatTab ? "hidden" : "auto",
      },
    },
    drawerPaper: {
      "& .MuiDrawer-paper": {
        padding: 0,
        height: "80vh",
        width: "450px",
        position: "fixed",
        top: "auto",
        bottom: "5px",
        right: "5px",
        borderRadius: "15px",
        margin: "0 10px 20px 0",
      },
    },
    dialogActionsTabs: {
      "&.MuiDialogActions-root": {
        padding: 0,
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 0,
      cursor: "pointer",
      maxHeight: "75x",
      border: "none",
    },
    iconStyle: {
      fontSize: "20px",
    },
    tabText: {
      fontSize: "14px",
      color: "inherit",
    },
    selectedTab: {
      color: theme.palette.highlight.main,
    },
    tab: {
      textTransform: "none",
      width: "100%",
      minHeight: "75px",
      "&:hover": {
        backgroundColor: alpha(theme.palette.neutral.main, 0.5),
      },
    },
    tabs: {
      maxHeight: "75px",
      width: "100%",
    },
    dialogActions: {
      padding: 0,
    },
  });

  const classes = useStyles();

  const tabs = [
    {
      label: "homeTitle",
      onClick: () => setChatGptViewTab(ChatGptTabs.home),
      icon: HomeIcon,
      value: ChatGptTabs.home,
    },
    {
      label: "newChatTitle",
      onClick: () => setChatGptViewTab(ChatGptTabs.clariChat),
      icon: MessageIcon,
      value: ChatGptTabs.clariChat,
    },
  ];

  const handleOnBack = () => {
    setChatGptViewTab(tabToReturn || ChatGptTabs.home);
    if (isOnSuggestions) {
      setIsResponseGenerating(false);
      setIsResponseLoading(false);
      clearMessages();
      setIsOnSuggestions(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      setTabToReturn("");
      setChatGptViewTab(ChatGptTabs.home);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      className={classes.drawerPaper}
      style={{ position: "initial" }}
      SlideProps={{
        exit: true,
        timeout: 550,
      }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <ChatBoxTitleContainer
          t={t}
          title={"clariChatTitle"}
          onClose={handleClose}
          hasBackButton={isClariChatTab}
          onBack={handleOnBack}
          isClariChatTab={isClariChatTab}
        />
        {isClariChatTab && <Divider />}
      </DialogTitle>

      <DialogContent className={classes.content}>
        {viewTab === ChatGptTabs.home && <ChatHomeTab t={t} />}
        {viewTab === ChatGptTabs.clariChat && <ClariChatTab t={t} />}
      </DialogContent>

      <Divider />

      {!isClariChatTab ? (
        <DialogActions className={classes.dialogActionsTabs}>
          <Tabs variant="fullWidth" className={classes.tabs} value={false}>
            {tabs.map((tab, index) => {
              const Icon = tab.icon;
              const isSelected = viewTab === tab.value;

              return (
                <Tab
                  key={index}
                  icon={Icon && <Icon className={classes.iconStyle} />}
                  label={
                    <Typography className={classes.tabText}>
                      {t(tab.label)}
                    </Typography>
                  }
                  onClick={tab.onClick}
                  value={tab.value}
                  className={`${classes.tab} ${
                    isSelected ? classes.selectedTab : ""
                  }`}
                />
              );
            })}
          </Tabs>
        </DialogActions>
      ) : (
        <DialogActions className={classes.dialogActions}>
          <ChatGptInput
            placeholder="askSomething"
            isForHome={false}
            inputIcon={<PlayArrowIcon />}
          />
        </DialogActions>
      )}
    </Drawer>
  );
});

export default ChatBoxPopup;
