import React, { FC, useMemo } from "react";
import { useReactFlow } from "reactflow";

import {
  DIAGRAM_NODE_CATEGORIES,
  NODE_ASSET_TYPE,
  NODE_CONDITIONAL_BRANCHES,
  NODE_TYPES,
} from "../../../../../types/constants";
import { FlowNode, NodeData } from "../../../../../types/interfaces";
import { useStores } from "../../../../../stores/StoresProvider";
import { ConditionalTag } from "./ConditionalTag";

export const ConditionalTabHandler: FC<{ nodeIdentifier: string }> = ({
  nodeIdentifier,
}) => {
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();

  const branch = useMemo(() => {
    const currentNode = reactFlow.getNode(
      nodeIdentifier
    ) as unknown as NodeData;

    if (currentNode.parentId) {
      const parentNode = reactFlow.getNode(currentNode.parentId) as FlowNode;

      if (parentNode.key !== NODE_ASSET_TYPE.conditional) {
        return null;
      }

      // Check current node source node
      const nodeEdge = reactFlow
        .getEdges()
        ?.find((edge) => edge.target === nodeIdentifier);

      const sourceNode = nodeEdge?.source
        ? (reactFlow.getNode(nodeEdge?.source) as unknown as NodeData)
        : undefined;

      if (
        sourceNode &&
        sourceNode.type === DIAGRAM_NODE_CATEGORIES.placeholder
      ) {
        return currentNode?.trueBranch
          ? NODE_CONDITIONAL_BRANCHES.trueBranch
          : NODE_CONDITIONAL_BRANCHES.falseBranch;
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeIdentifier, flowSettingsStore.layoutingNeeded]);

  const hasGroupTag = useMemo(() => {
    const nodes = reactFlow.getNodes();
    const targetNode = nodes?.find(
      (node) => node?.id === nodeIdentifier
    ) as FlowNode;

    if (
      !targetNode ||
      targetNode?.type !== NODE_TYPES.group ||
      (!targetNode?.trueBranch && !targetNode?.falseBranch)
    ) {
      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeIdentifier, flowSettingsStore.layoutingNeeded]);

  if (!branch) {
    return <></>;
  }

  return (
    <ConditionalTag
      isTrue={branch === NODE_CONDITIONAL_BRANCHES.trueBranch}
      hasGroupTag={hasGroupTag}
    />
  );
};
