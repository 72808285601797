import React, { FC, useEffect, useState, useMemo } from "react";
import { TFunction } from "i18next";
import { useParams } from "react-router-dom";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useStores } from "../../../../stores/StoresProvider";
import CoreButton from "../../../core/CoreButton";
import CoreNotificationBox from "../../../core/CoreNotificationBox";
import { NotificationTypes } from "../../../../context/useNotification";

interface Props {
  t: TFunction;
  open: boolean;
  handleClose: () => void;
  handleMove: (flowId: string) => void;
  loading: boolean;
  isForCanvas?: boolean;
}

const MoveDocumentsDialog: FC<Props> = ({
  t,
  open,
  handleClose,
  handleMove,
  loading,
  isForCanvas = false,
}) => {
  const { id } = useParams<{ id: string }>();
  const { flowId } = useParams<{ flowId: string }>();
  const paramId = isForCanvas ? flowId : id;

  const [selectedItem, setSelectedItem] = useState("");
  const { flowStore } = useStores();
  const theme = useTheme();

  const classes = makeStyles({
    dialog: {
      backgroundImage: "unset",
      backgroundColor: theme.palette?.background.paper,
    },
    displayRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    spacing: {
      marginBottom: "8px",
    },
    widthElement: {
      width: "100%",
    },
    divider: {
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    infoBox: {
      padding: "0 20px 0 20px",
    },
  })();

  useEffect(() => {
    setSelectedItem("");
  }, [open]);

  const filteredFlows = useMemo(
    () =>
      (flowStore.flows ?? [])?.filter((flow) => flow.identifier !== paramId),
    [flowStore.flows, paramId]
  );

  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.dialog }}>
      <DialogTitle>{t("move_documents")}</DialogTitle>

      <Divider className={classes.divider} />

      <DialogContent>
        <Box className={classes.spacing}>
          {filteredFlows?.length > 0 ? (
            <>
              <Typography>{t("select_flow_to_move")}</Typography>
              <Box className={classes.displayRow}>
                <List className={classes.widthElement}>
                  {filteredFlows?.map((flow, index) => {
                    return (
                      <ListItemButton
                        key={index}
                        onClick={() => {
                          setSelectedItem(flow.identifier);
                        }}
                      >
                        <Radio
                          checked={flow.identifier === selectedItem}
                          value={flow.identifier}
                        />
                        <Typography>{t(flow.name)}</Typography>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
            </>
          ) : (
            <Box className={classes.infoBox}>
              <CoreNotificationBox
                type={NotificationTypes.info}
                description={
                  <>
                    {t("noFlowsToMove")}
                    <br />
                    {t("createFlowToMoveMessage")}
                  </>
                }
              />
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <CoreButton onClick={handleClose} variant="neutral">
          {t("cancel")}
        </CoreButton>
        <CoreButton
          disabled={!selectedItem || loading}
          autoFocus
          isLoading={loading}
          onClick={() => handleMove(selectedItem)}
          variant="contained"
        >
          {t("confirm")}
        </CoreButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveDocumentsDialog;
