import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { GraphQLError } from "graphql";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import FlowSettingsMenu from "./flow-settings/FlowSettingsMenu";
import RejectReasonsTab from "./flow-settings/flowSettingsTabs/RejectReasonsTab";
import DataSourceTab from "./flow-settings/flowSettingsTabs/DataSourceTab";
import DataExportTab from "./flow-settings/flowSettingsTabs/DataExportTab";
import Storage from "../addNewFlow/tabs/storage/Storage";
import OverviewTab from "./flow-settings/flowSettingsTabs/OverviewTab";
import RestrictTab from "./flow-settings/flowSettingsTabs/RestrictTab";
import DesignerTab from "./flow-settings/flowSettingsTabs/DesignerTab";
import APIkeysTab from "./flow-settings/flowSettingsTabs/APIkeysTab";
import { VersionsTab } from "./flow-settings/flowSettingsTabs/VersionsTab";
import { FlowLogsTab } from "./flow-settings/flowSettingsTabs/FlowLogsTab";
import { FlowSettingsStore } from "../../../stores/FlowSettingsStore";
import { UserStore } from "../../../stores/UserStore";
import { AppSuspense } from "../../main/AppSuspense";
import { useNotification } from "../../../context/useNotification";
import { useStores } from "../../../stores/StoresProvider";
import NotFound from "../errorPages/NotFound";
import AccessDenied from "../errorPages/AccessDenied";

const useStyle = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    overflowX: "auto",
  },
  content: {
    paddingLeft: "40px",
    paddingRight: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "64px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "64px",
  },
});

interface TabProps {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
  userStore: UserStore;
}

const TAB_COMPONENTS: { [key: string]: React.ComponentType<TabProps> } = {
  overview: OverviewTab,
  accessControl: RestrictTab,
  designer: DesignerTab,
  versions: VersionsTab,
  dataSource: DataSourceTab,
  dataExport: DataExportTab,
  repository: Storage,
  rejectReasons: RejectReasonsTab,
  accessKeys: APIkeysTab,
  alerts: () => <Box>Alerts</Box>,
  logs: FlowLogsTab,
  metrics: () => <Box>Metrics</Box>,
};

const FlowSettingsPage: FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyle();
  const notification = useNotification();
  const { mainStore, flowSettingsStore, userStore, flowStore } = useStores();
  const { t, ready } = useTranslation(["addFlow", "apikeys", "activityLogs"]);

  const [loadFlowError, setLoadFlowError] = useState<
    Error | GraphQLError | null
  >(null);

  const loadFlowDetails = (tabRefetch = false) => {
    flowSettingsStore
      .loadFlow(id, false)
      .catch((error: Error) => {
        setLoadFlowError(error);
        notification.error(t(error?.message || "error_loadFlow"));
      })
      .finally(() => {
        if (tabRefetch) {
          flowSettingsStore.triggerRefetchFlowSettings(false);
        }
      });
  };

  useEffect(() => {
    flowStore.getAllFlows(true).catch((error: Error) => {
      notification.error(t(error?.message || "fetchFlowsError"));
    });

    userStore
      .getAllUsers()
      .catch((error: Error) =>
        notification.error(t(error?.message ?? "get_users_error"))
      );

    loadFlowDetails();

    mainStore.collapseSidebar();

    return () => {
      flowSettingsStore.resetDiagramStates();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flowSettingsStore.refetchFlowSettings === true) {
      loadFlowDetails(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.refetchFlowSettings]);

  const TabComponent = useMemo(() => {
    return (
      TAB_COMPONENTS[flowSettingsStore.selectedTab] || TAB_COMPONENTS.overview
    );
  }, [flowSettingsStore.selectedTab]);

  if (!ready || flowSettingsStore.loadingFlowSettings) {
    return <AppSuspense />;
  }

  if (loadFlowError) {
    if ((loadFlowError as GraphQLError)?.extensions?.statusCode === 404) {
      return <AccessDenied />;
    }

    return (
      <NotFound
        title={t("flowNotFoundTitle")}
        subtitle={t("flowNotFoundSubtitle")}
      />
    );
  }

  return (
    <Box className={classes.container}>
      <FlowSettingsMenu />
      <TabComponent
        t={t}
        flowSettingsStore={flowSettingsStore}
        userStore={userStore}
      />
    </Box>
  );
});

export default FlowSettingsPage;
