import React, { FC } from "react";

import { Box, Chip, useTheme } from "@mui/material";

import { THEME_MODES } from "../../../../../types/constants";
import { useStores } from "../../../../../stores/StoresProvider";

export const GroupTypeFlag: FC<{
  label: string;
  hasBranchAndGroupFlag?: boolean;
}> = ({ label, hasBranchAndGroupFlag = false }) => {
  const theme = useTheme();
  const {
    mainStore: { currentTheme },
  } = useStores();

  return (
    <Box
      sx={{
        height: "15px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        top: hasBranchAndGroupFlag ? "-29px" : "-13px",
        left: "5px",
      }}
    >
      <Chip
        size="small"
        label={label}
        sx={{
          "& .MuiChip-label": {
            fontSize: "8px",
            fontWeight: "bold",
            color:
              currentTheme === THEME_MODES.light
                ? theme.palette.neutral.light
                : theme.palette.text.primary,
          },
          borderRadius: "5px",
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.highlight.main,
        }}
      />
    </Box>
  );
};
