import React, { FC, memo, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { TFunction } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import {
  Menu,
  MenuItem,
  useTheme,
  MenuList,
  Typography,
  IconButton,
  ListItemIcon,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";

import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useNotification } from "../../../../context/useNotification";
import { appRoutes } from "../../../../configs/routes";
import HistoryDrawer from "../history/HistoryDrawer";
import { DocumentStore } from "../../../../stores/DocumentStore";
import { UserStore } from "../../../../stores/UserStore";
import { FlowStore } from "../../../../stores/FlowStore";
import { DocumentsFilterProps } from "../../../../types/interfaces";
import {
  DOCUMENT_STATUS,
  RERUN_STATUSES_LIMIT,
} from "../../../../types/constants";
import RerunDocumentsAction from "../../flow/table/RerunDocumentsAction";
import MoveDocumentsDialog from "../../flow/table/MoveDocumentsDialog";
import CoreConfirmModal from "../../../core/CoreConfirmModal";

interface Props {
  t: TFunction;
  documentStore: DocumentStore;
  userStore: UserStore;
  download: () => void;
  flowStore: FlowStore;
  isDownloadInProgress: boolean;
}

const Component: FC<Props> = observer(
  ({
    t,
    documentStore,
    userStore,
    flowStore,
    download,
    isDownloadInProgress,
  }) => {
    const notification = useNotification();
    const theme = useTheme();
    const history = useHistory();
    const apolloClient = useApolloClient();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isHistoryDrawerOpened, toggleHistoryDrawer] = useState(false);
    const [isRerunDialogOpen, setIsRerunDialogOpen] = useState(false);
    const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false);
    const [isMovingInProgress, setIsMovingInProgress] = useState(false);
    const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);

    const checked = documentStore.isGetNextDocumentChecked;
    const currentDoc = documentStore.document;

    const { flowId, documentId } = useParams<{
      documentId: string;
      flowId: string;
    }>();

    const [formattedFlowId, setFormattedFlowId] = useState<string>(flowId);

    const classes = makeStyles({
      paper: {
        padding: "10px",
        background: "none",
        backgroundColor: theme.palette.secondary.light,
      },
      list: {
        width: "275px",
        padding: "0px",
      },
      loadingSpinner: {
        marginLeft: "6px",
        color: theme.palette.buttons.main,
      },
      spacingBox: {
        display: "flex",
        flexDirection: "column",
      },
    })();

    useEffect(() => {
      if (
        history.location.pathname !==
        appRoutes.DocumentView(formattedFlowId, currentDoc?.identifier)
      )
        history.replace(
          appRoutes.DocumentView(formattedFlowId, currentDoc?.identifier)
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedFlowId]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setIsRerunDialogOpen(false);
    };

    const goToNextDoc = () => {
      documentStore
        .loadDocument(
          apolloClient,
          undefined,
          undefined,
          formattedFlowId,
          documentStore.document?.identifier ?? ""
        )
        .then((hasNextDoc) => {
          if (hasNextDoc)
            history.replace(
              appRoutes.DocumentView(formattedFlowId, currentDoc?.identifier)
            );
          else {
            history.push(appRoutes.FlowDetails(formattedFlowId));
            notification.info(t("noMoreDocs"));
          }
        })
        .catch((error: Error) => {
          history.push(appRoutes.RetryNextDocument(formattedFlowId));
          notification.error(t(error?.message || "errorGettingNextDoc"));
        });
    };

    const handleRetryDocument = () => {
      documentStore
        .retryDocument(apolloClient, currentDoc?.identifier ?? "")
        .then(() => {
          notification.success(t("retryDocumentSuccess"));
          setIsRetryModalOpen(false);
          goToNextDoc();
        })
        .catch((error: Error) =>
          notification.error(t(error?.message || "retryDocumentError"))
        );
    };

    const viewSimilarDocuments = () => {
      const values = documentStore.document?.metadata.template
        ? (
            documentStore.document?.metadata.template as unknown as {
              [key: string]: string;
            }
          ).id
        : documentStore.document?.identifier;

      const customMetadata = {
        field: "template.id",
        operator: "IN",
        values: [values],
      };

      flowStore.setFiltersValues(
        {
          ...flowStore?.filters?.flowFilters,
          metadata: [
            {
              ...customMetadata,
              type: "metadata",
            },
          ] as unknown as DocumentsFilterProps[],
        },
        formattedFlowId
      );
      history.push(appRoutes.ListDocuments(formattedFlowId));

      handleClose();
    };

    const canRetryDocument = userStore.currentUserPermissions?.can(
      "updateDocuments",
      "flows"
    );

    const canViewHistory = userStore.currentUserPermissions?.can(
      "read",
      "activityLogs"
    );

    const canViewSimilarDocs = userStore.currentUserPermissions?.can(
      "read",
      "documents"
    );

    const downloadDocument = () => {
      download();
    };

    const moveDocument = (flowIdentifier: string) => {
      setIsMovingInProgress(true);

      documentStore
        .moveSelectedDocuments(apolloClient, flowIdentifier)
        .then(() => {
          notification.success(t("document_moved"));
          setFormattedFlowId(flowIdentifier);
          setIsMoveDialogOpen(false);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "document_moved_error"));
        })
        .finally(() => {
          setIsMovingInProgress(false);
          setAnchorEl(null);
        });
    };

    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            list: classes.list,
          }}
        >
          <MenuList dense>
            {!currentDoc?.isViewMode && (
              <MenuItem
                onClick={() => {
                  documentStore.setGetNextDocumentChecked(!checked);
                }}
              >
                <ListItemIcon>{checked ? <Check /> : <Close />}</ListItemIcon>
                <Typography style={{ whiteSpace: "normal" }}>
                  {t("getNextDocAfterSave")}
                </Typography>
              </MenuItem>
            )}
            {canRetryDocument && (
              <Box className={classes.spacingBox}>
                {currentDoc?.status?.type === "error" && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setIsRetryModalOpen(true);
                    }}
                  >
                    <Typography>{t("retryDocument")}</Typography>
                  </MenuItem>
                )}
                {!RERUN_STATUSES_LIMIT.includes(
                  currentDoc?.status?.type as unknown as DOCUMENT_STATUS
                ) && (
                  <MenuItem onClick={() => setIsRerunDialogOpen(true)}>
                    <Typography>{t("rerunDocument")}</Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={() => setIsMoveDialogOpen(true)}>
                  <Typography>{t("move_documents")}</Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={downloadDocument}>
                  <Typography>{t("downloadDocument")}</Typography>
                  {isDownloadInProgress && (
                    <CircularProgress
                      className={classes.loadingSpinner}
                      size={"20px"}
                    />
                  )}
                </MenuItem>

                <Divider />
              </Box>
            )}
            {canViewHistory && (
              <Box className={classes.spacingBox}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    toggleHistoryDrawer(true);
                  }}
                >
                  <Typography>{t("header_view_history_label")}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push(appRoutes.ContextEvent(documentId))
                  }
                >
                  <Typography>{t("header_view_logs_label")}</Typography>
                </MenuItem>
              </Box>
            )}
            {canViewSimilarDocs && (
              <MenuItem onClick={viewSimilarDocuments}>
                <Typography>{t("header_view_similar_docs_label")}</Typography>
              </MenuItem>
            )}
          </MenuList>
        </Menu>

        <HistoryDrawer
          translation={t}
          isOpened={isHistoryDrawerOpened}
          onClose={() => {
            toggleHistoryDrawer(false);
          }}
        />

        <RerunDocumentsAction
          t={t}
          documents={[currentDoc?.identifier ?? ""]}
          isRerunDialogOpen={isRerunDialogOpen}
          setIsRerunDialogOpen={setIsRerunDialogOpen}
          successfullyRerun={() => {
            if (documentStore.isGetNextDocumentChecked) goToNextDoc();
            setAnchorEl(null);
          }}
          finallyRerun={() => setAnchorEl(null)}
          refreshData={() => goToNextDoc()}
        />

        <MoveDocumentsDialog
          open={isMoveDialogOpen}
          t={t}
          handleClose={() => setIsMoveDialogOpen(false)}
          handleMove={moveDocument}
          loading={isMovingInProgress}
          isForCanvas={true}
        />

        <CoreConfirmModal
          open={isRetryModalOpen}
          onClose={() => {
            setIsRetryModalOpen(false);
          }}
          title={t("retryDocument")}
          subtitle={t("retryDocumentSubtitle")}
          cancelButtonLabel={t("cancel")}
          confirmButtonLabel={t("confirm")}
          onCancel={() => setIsRetryModalOpen(false)}
          onConfirm={handleRetryDocument}
          isDisable={documentStore.updatingPage}
        />
      </>
    );
  }
);

export const DocumentValidateActions = memo(Component);
