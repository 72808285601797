import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Box, Typography, useTheme, Checkbox, SvgIcon } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectProps } from "@mui/material/Select";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";

import CoreOption from "./select/CoreOption";
import { CategoryResults, SelectOptionDef } from "../../types/interfaces";
import CoreTooltip from "./CoreTooltip";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface Props extends SelectProps {
  options?: SelectOptionDef[];
  errorText?: string;
  isMultipleSelect?: boolean;
  placeholder?: string;
  title?: string;
  titleIcon?: React.ReactNode;
  size?: "medium" | "small";
  selectContainerStyle?: string;
  titleClassname?: string;
  disabled?: boolean;
  description?: string;
  formControlClass?: string;
  selectInputStyle?: string;
  isMandatory?: boolean;
  extraActions?: React.ReactNode;
  hasCheckbox?: boolean;
  checked?: boolean;
  optionStyle?: string;
  inputStyle?: string;
  disableCheckBoxRipple?: boolean;
  placeholderStyle?: string;
  isForSchema?: boolean;
  forConnections?: boolean;
}

const CoreSelect: FC<Props> = ({
  options,
  errorText,
  isMultipleSelect,
  placeholder,
  title,
  titleIcon,
  size = "small",
  selectContainerStyle,
  selectInputStyle,
  titleClassname,
  disabled,
  description,
  formControlClass,
  isMandatory = false,
  extraActions,
  hasCheckbox = false,
  value,
  optionStyle,
  inputStyle,
  disableCheckBoxRipple = false,
  placeholderStyle,
  isForSchema = false,
  forConnections,
  ...extended
}) => {
  const { t } = useTranslation("core");
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const useStyles = makeStyles({
    formText: {
      height: "35px",
      margin: "auto 0",
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
    formControl: {
      width: "100%",
    },
    select: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      borderRadius: "2px",
    },
    icon: {
      alignSelf: "center",
      marginLeft: "15px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    box: {
      display: "flex",
      width: "100%",
    },
    iconContainer: {
      marginRight: "12px",
      maxHeight: forConnections ? "24px" : undefined,
    },
    extraActions: {
      alignSelf: "center",
      marginLeft: "5px",
      marginRight: "-10px",
    },
    input: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    checkBox: {
      padding: 0,
      marginRight: "10px",
      ...(disableCheckBoxRipple && {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      }),
    },
    score: {
      fontWeight: "bold",
      "&.MuiButtonBase-root": {
        "&.Mui-selected": {
          backgroundColor: theme.palette.secondary.dark,
        },
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    option: {
      display: "flex",
      flexDirection: "row",
      width: isForSchema ? "100%" : undefined,
      margin: isForSchema ? "6px 16px 6px 16px" : undefined,
      height: isForSchema ? "100%" : undefined,
      justifyContent: forConnections ? "center" : undefined,
      alignItems: forConnections ? "center" : undefined,
    },
    title: {
      display: "flex",
      alignItems: "flex-start",
    },
    elementStyle: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
    },
    childOptionsStyle: {
      marginLeft: "30px",
    },
  });

  const classes = useStyles();

  const titleClass = `${titleClassname as unknown as string} ${classes.title}`;

  const formattedOptions =
    options && options.length > 0
      ? options
      : [{ label: t("noOptions"), isDisabled: true, key: "" }];

  const formControlClasses = classNames(classes.formControl, formControlClass);

  const handleOnClick = (option: SelectOptionDef) => {
    if (option.onClick) {
      option.onClick();
    }
  };

  const getOption = (option: SelectOptionDef) => {
    const OptionIcon = option.icon as React.ElementType | undefined;

    if (option?.isSvg) {
      return (
        <div className={classes.option}>
          <div className={classes.iconContainer}>
            <SvgIcon
              key={option?.key}
              width="24px"
              height="24px"
              fill={theme.palette?.text.primary}
            >
              <g
                dangerouslySetInnerHTML={{
                  __html: (option?.icon as string) || "",
                }}
              />
            </SvgIcon>
          </div>
          <div className={optionStyle || ""}>{option.label}</div>
        </div>
      );
    }

    if (OptionIcon) {
      return (
        <div className={classes.option}>
          <div className={classes.iconContainer}>
            <OptionIcon
              width="24px"
              height="24px"
              fill={theme.palette?.text.primary}
            />
          </div>
          <div className={optionStyle || ""}>{option.label}</div>
        </div>
      );
    }

    if (option.element) {
      return (
        <div className={classes.elementStyle}>
          <div>{option.label}</div>
          <div>{option.element}</div>
        </div>
      );
    }

    if (isMultipleSelect && hasCheckbox) {
      const isChecked =
        typeof option?.isChecked !== "undefined"
          ? option?.isChecked
          : ((value as string[]) || [])?.includes(option?.key);

      return (
        <div
          className={classes.option}
          onClick={
            option?.isParent && isForSchema
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOnClick(option);
                }
              : undefined
          }
        >
          {options && options?.length > 0 && (
            <Checkbox
              disableRipple={disableCheckBoxRipple}
              checked={isChecked}
              className={classNames(
                classes.checkBox,
                option?.parentKey && classes.childOptionsStyle
              )}
              indeterminate={option?.isIndeterminate}
            />
          )}
          <div className={optionStyle || ""}>{option.label}</div>
        </div>
      );
    }

    if ((option as unknown as CategoryResults).score) {
      const score = (option as unknown as CategoryResults).score * 100;
      if (score === 100) {
        return `${option.label} (${score.toFixed(0)}%)`;
      }
      return `${option.label} (${score.toFixed(4)}%)`;
    }

    return option.label;
  };

  return (
    <>
      {title && (
        <Typography
          variant="subtitle2"
          className={titleClass}
          color={
            errorText
              ? currentTheme === THEME_MODES.light
                ? theme.palette.error.main
                : theme.palette.error.light
              : theme.palette.text.primary
          }
        >
          {title} {isMandatory && <span className={classes.asterisk}>*</span>}
          {titleIcon}
        </Typography>
      )}

      <FormControl
        className={selectContainerStyle || formControlClasses}
        size={size}
      >
        <Box className={classes.box}>
          <Select
            multiple={isMultipleSelect}
            value={value || (isMultipleSelect ? [] : "")}
            error={!!errorText}
            disabled={disabled}
            className={selectInputStyle || classes.select}
            inputProps={{
              className: inputStyle ? inputStyle : classes.input,
              ...(extended.inputProps || {}),
            }}
            displayEmpty
            {...extended}
          >
            {!!placeholder && (
              <CoreOption
                value=""
                disabled
                selected
                className={placeholderStyle || ""}
              >
                <Typography className={optionStyle || ""}>
                  {placeholder}
                </Typography>
              </CoreOption>
            )}
            {formattedOptions.map((option, index) => {
              return (
                <CoreOption
                  key={`${option.key}-${index}`}
                  value={option.key}
                  disabled={option?.isDisabled}
                  className={
                    (option as unknown as CategoryResults).score
                      ? classes.score
                      : optionStyle || ""
                  }
                  divider={option.divider}
                >
                  <div className={optionStyle || ""}>{getOption(option)}</div>
                </CoreOption>
              );
            })}
          </Select>
          {extraActions && (
            <Box className={classes.extraActions}>{extraActions}</Box>
          )}

          {description && (
            <CoreTooltip title={description}>
              <InfoOutlinedIcon className={classes.icon} />
            </CoreTooltip>
          )}
        </Box>
        {errorText && (
          <Box className={classes.boxError}>
            <CancelOutlinedIcon className={classes.cancelIcon} />

            <FormHelperText error={!!errorText} className={classes.formText}>
              {errorText}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    </>
  );
};

export default CoreSelect;
