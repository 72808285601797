import { v4 as uuidv4 } from "uuid";
import {
  DocumentLineItem,
  DocumentLineItemCell,
  DocumentLineItemLine,
  DocumentLineItemRow,
  DocumentZone,
  FlowField,
} from "../../types/interfaces";
import CanvasHelper from "./canvasHelper";

export default class LineItemHeaderHelper {
  static isHeaderBeingSplit(
    lineItem: DocumentLineItem,
    newLine: DocumentLineItemLine
  ): boolean {
    try {
      const headerCells = lineItem.headers || [];

      if (headerCells?.length === 0) {
        return false;
      }

      const headerLineCoords = {
        x: Math.min(...(headerCells ?? []).map((cell) => cell.box?.x)),
        y: headerCells?.[0]?.box?.y,
        height: headerCells?.[0]?.box?.height,
        width: Math.abs(
          headerCells[headerCells.length - 1]?.box?.x - headerCells[0]?.box?.x
        ),
      };

      const intersectLine = newLine[0][1];

      return (
        headerLineCoords.y <= intersectLine &&
        headerLineCoords.y + headerLineCoords.height >= intersectLine
      );
    } catch {
      return false;
    }
  }

  static splitHeadersWithNoData(
    lineItem: DocumentLineItem,
    newLine: DocumentLineItemLine,
    canvasZones: DocumentZone[]
  ): DocumentLineItem {
    try {
      const lineY = newLine[0][1];

      let formattedHeaders = [] as DocumentZone[];
      let formattedDataCells = {} as { [key: string]: DocumentLineItemCell };

      lineItem.headers.forEach((lineItemHeader) => {
        const [header, dataCell] = CanvasHelper.splitTableCell(
          lineItemHeader,
          lineY,
          canvasZones
        );

        formattedHeaders = [...formattedHeaders, header];
        formattedDataCells = {
          ...formattedDataCells,
          [header.key as string]: dataCell,
        };
      });

      const cells = Object.values(formattedDataCells);

      const rowBox = {
        x: Math.min(...(cells ?? []).map((cell) => cell.box?.x)),
        y: cells?.[0]?.box?.y,
        height: cells?.[0]?.box?.height,
        width: Math.abs(cells[cells.length - 1]?.box?.x - cells[0]?.box?.x),
        rotation: cells?.[0]?.box?.rotation || 0,
      };

      // Construct first row coords
      return {
        ...lineItem,
        headers: formattedHeaders,
        data: [
          {
            box: rowBox,
            cells: formattedDataCells,
            points: CanvasHelper.initRowPoints(rowBox),
          },
          ...(lineItem.data || []),
        ] as DocumentLineItemRow[],
      };
    } catch {
      return lineItem;
    }
  }

  static splitHeaderColumnWithNoData(
    lineItem: DocumentLineItem, // LineItem object configuration
    newHeaderColumn: FlowField, // New defined header
    originalColumns: FlowField[], // Original headers (from flow config)
    newLine: DocumentLineItemLine, // New drawn line
    canvasZones: DocumentZone[],
    // By default, the new column will be added as second to original
    // If this property comes as true, then the new column will be added as first, then oeiginal as second column
    positionSwitched = false
  ): {
    updatedLineItem: DocumentLineItem;
    headers: FlowField[];
    splitSuccessful: boolean;
  } {
    try {
      let formattedOriginalColumns = originalColumns;

      // Used for adding the new line
      // If false, then no need to create a new line (will not be linked to any cell)
      let splitSuccessful = true;

      // Cell formatter
      const topPoint = { x: newLine[0][0], y: newLine[0][1] };

      // Manual configuration
      let formattedHeaders = [] as DocumentZone[];

      lineItem?.headers?.forEach((header) => {
        if (
          header.box.x <= topPoint.x &&
          header.box.x + header.box.width >= topPoint.x
        ) {
          // Column header to split
          splitSuccessful = true;
          const originalHeader = CanvasHelper.updateCellText(
            {
              ...header,
              box: {
                ...header.box,
                width: topPoint.x - header.box.x,
              },
            },
            canvasZones
          );

          // New header
          const newHeader = CanvasHelper.updateCellText(
            {
              ...header,
              ...newHeaderColumn,
              identifier: uuidv4(),
              box: {
                ...header.box,
                x: topPoint.x,
                y: header.box.y,
                width: header.box.width + header.box.x - topPoint.x,
              },
            },
            canvasZones
          );

          formattedHeaders = [
            ...formattedHeaders,
            ...(positionSwitched
              ? [newHeader, originalHeader]
              : [originalHeader, newHeader]),
          ];

          if (
            !originalColumns.some((col) => col.key === newHeaderColumn?.key)
          ) {
            // New column created
            let sortedConfigColumns = [] as FlowField[];

            originalColumns.forEach((origColumn) => {
              if (origColumn.key === header.key) {
                sortedConfigColumns = [
                  ...sortedConfigColumns,
                  ...(positionSwitched
                    ? [newHeaderColumn, origColumn]
                    : [origColumn, newHeaderColumn]),
                ];
              } else {
                sortedConfigColumns = [...sortedConfigColumns, origColumn];
              }
            });
            formattedOriginalColumns = sortedConfigColumns;
          }
        } else {
          formattedHeaders = [...formattedHeaders, header];
        }
      });

      return {
        updatedLineItem: {
          ...lineItem,
          headers: formattedHeaders,
        } as unknown as DocumentLineItem,
        headers: [...formattedOriginalColumns],
        splitSuccessful,
      };
    } catch {
      return {
        updatedLineItem: lineItem,
        splitSuccessful: false,
        headers: originalColumns,
      };
    }
  }

  static replaceHeaders(
    originalHeaders: DocumentZone[],
    originalColumn: string,
    newColumn: string
  ): DocumentZone[] {
    return originalHeaders.map((header) => {
      // Original column will become new column
      if (header.key === originalColumn) {
        return { ...header, key: newColumn, originalKey: header.key };
      }

      // new column in config will become ignored
      if (header.key === newColumn) {
        return {
          ...header,
          key: uuidv4(),
          originalKey: header.key,
        };
      }
      return header;
    });
  }
}
