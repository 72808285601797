// Library imports
import React, { Suspense } from "react";
import { TFunction } from "i18next";

// Design imports
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { alpha, useTheme } from "@mui/material";

// Internal imports
import { backendRoutes } from "../../../../configs/backendRoutes";
import { AppSuspense } from "../../../main/AppSuspense";
import { CoreImage } from "../../CoreImage";
import { StatusDef } from "../../../../types/interfaces";
import { IMAGE_SIZE } from "../../../../types/constants";
import { DocumentStore } from "../../../../stores/DocumentStore";
import { CoreStatusTag } from "../../CoreStatusTag";

interface Props {
  imageUri: string;
  name: string;
  pages: number;
  status: StatusDef;
  t: TFunction;
  documentStore: DocumentStore;
}

export const ImagePreview: React.FC<Props> = ({
  imageUri,
  name,
  pages = 1,
  t,
  status,
  documentStore,
}) => {
  const theme = useTheme();

  //TODO: Add transition & center content
  const useStyles = makeStyles({
    container: {
      height: "100%",
      width: "25%",
      backgroundColor: alpha(theme.palette.secondary.dark, 0.9),
      position: "absolute",
      zIndex: 5000,
      top: 0,
      right: 0,
      display: "flex",
      padding: "15px",
      justifyContent: "center",
    },
    content: {
      height: "50%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "30px",
    },
    chipContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "20px",
    },
    dataChip: {
      maxWidth: "80%",
      marginBottom: 0,
      marginTop: "20px",
      backgroundColor: theme.palette.neutral.dark,
      color: theme.palette.neutral.light,
      borderRadius: "5px",
    },
    image: {
      maxHeight: "100%",
      objectFit: "scale-down",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.chipContainer}>
          <Chip label={name} className={classes.dataChip} />
        </Box>
        <Suspense fallback={<AppSuspense />}>
          <CoreImage
            path={backendRoutes.GetImagePreview(
              imageUri,
              documentStore.hoveredDocument?.identifier ?? "",
              IMAGE_SIZE.preview
            )}
            className={classes.image}
          />
        </Suspense>

        <Box className={classes.chipContainer}>
          <Chip
            label={`${t("totalPages")} ${pages} `}
            className={classes.dataChip}
          />
          <CoreStatusTag
            type={status.type}
            label={t(status.type)}
            styleProps={{ height: "30px", marginTop: "20px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
