import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Typography, Box, Slide, Divider } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import DebuggingIcon from "@mui/icons-material/BugReport";
import GroupsIcon from "@mui/icons-material/Groups";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import Logo from "../../../icons/Logo";
import CoreButton from "../../core/CoreButton";
import { AppSuspense } from "../../main/AppSuspense";
import { useStores } from "../../../stores/StoresProvider";
import { appRoutes } from "../../../configs/routes";
import CoreInput from "../../core/CoreInput";
import HomeChatGpt from "../flow/chat-gpt/HomeChatGpt";
import { ENTER_KEYS } from "../../../types/constants";
import { useNotification } from "../../../context/useNotification";

const useStyles = makeStyles({
  chatGptContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "80px",
    width: "100%",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  logoDimension: {
    width: "25px",
    height: "30px",
  },
  homeInitContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  homeMainContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    width: "100%",
    alignItems: "center",
    gap: "17px",
  },
  homeSearchInput: {
    borderRadius: "50px",
  },
  homeQuickAccessLabel: {
    opacity: 0.6,
    marginTop: "10px",
  },
  divider: {
    width: "100%",
  },
  homeQuickAccessActionsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    width: "100%",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
});

const Home: React.FC = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const notification = useNotification();
  const { t, ready } = useTranslation("home");
  const { userStore, chatGptStore } = useStores();

  const canRead = (key: string) =>
    userStore.currentUserPermissions?.can("read", key) ?? false;

  const quickAccessButtons = [
    {
      subject: "subscription",
      onClick: () => history.push(appRoutes.AccountBilling()),
      title: "billingTitle",
      icon: <CreditCardIcon style={{ fontSize: "22px" }} />,
    },
    {
      subject: "activityLogs",
      onClick: () => history.push(appRoutes.ContextEvents()),
      title: "activityLogsTitle",
      icon: <DebuggingIcon style={{ fontSize: "22px" }} />,
    },
    {
      subject: "users",
      onClick: () => history.push(appRoutes.Team()),
      title: "namespaceTeamTitle",
      icon: <GroupsIcon style={{ fontSize: "22px" }} />,
    },
  ];

  useEffect(() => {
    return () => {
      chatGptStore.setUserInput("");
      chatGptStore.clearHomeMessages();
      userStore.setIsHomeChatGptOpened(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserQuestion = (event: React.ChangeEvent<HTMLInputElement>) => {
    chatGptStore.setUserInput(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      ENTER_KEYS.includes(event.code) &&
      chatGptStore.userInput?.trim()?.length > 4
    ) {
      userStore.setIsHomeChatGptOpened(true);

      chatGptStore.addHomeMessage({
        answer: "",
        createdAt: "",
        question: chatGptStore.userInput,
        type: "user",
      });

      void chatGptStore
        .getChatGptResponseHelper(chatGptStore.userInput, true)
        .catch((error: Error) => {
          notification.error(t(error?.message || "getResponseError"));
        });

      chatGptStore.setUserInput("");
    }
  };

  if (!ready || !userStore.namespace?.name) {
    return <AppSuspense />;
  }

  return (
    <>
      {!userStore.isHomeChatGptOpened ? (
        <>
          <Box className={classes.homeInitContainer}>
            <Slide direction="down" in={!userStore.isHomeChatGptOpened}>
              <Box className={classes.homeMainContent}>
                <Logo
                  threeStripes={true}
                  threeStripesClassName={classes.logoDimension}
                />

                <Typography variant="h4">
                  {`${t("welcome")} ${userStore.user?.first_name || ""}`}
                </Typography>

                <CoreInput
                  placeholder={t("chat_gpt_ask_something")}
                  value={chatGptStore.userInput}
                  onChange={handleUserQuestion}
                  onKeyPress={handleKeyPress}
                  startAdornment={<SearchIcon className={classes.icon} />}
                  size="medium"
                  className={classes.homeSearchInput}
                />

                <Typography
                  variant="body1"
                  className={classes.homeQuickAccessLabel}
                >
                  {t("quickAccess")}
                </Typography>

                <Divider className={classes.divider} />

                <Box className={classes.homeQuickAccessActionsContainer}>
                  {quickAccessButtons.map((item, index) =>
                    item.subject === "users" || canRead(item.subject) ? (
                      <CoreButton
                        key={index}
                        variant="text"
                        size="large"
                        startIcon={item.icon}
                        onClick={item.onClick}
                      >
                        {t(item.title)}
                      </CoreButton>
                    ) : (
                      <React.Fragment key={index}></React.Fragment>
                    )
                  )}
                </Box>
              </Box>
            </Slide>
          </Box>
        </>
      ) : (
        <Box className={classes.chatGptContainer}>
          <HomeChatGpt
            onClose={() => {
              userStore.setIsHomeChatGptOpened(false);
              chatGptStore.clearHomeMessages();
              chatGptStore.setUserInput("");
            }}
          />
        </Box>
      )}
    </>
  );
});

export default Home;
