import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useApolloClient } from "@apollo/client";

import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import GoogleIcon from "@mui/icons-material/Google";
import { makeStyles } from "@mui/styles";

import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import CoreButton from "../../core/CoreButton";
import { AppSuspense } from "../../main/AppSuspense";
import { FormData, FormField } from "../../../types/interfaces";
import { FIELD_TYPE } from "../../../types/constants";
import { useStores } from "../../../stores/StoresProvider";
import { emailRegex } from "../../../types/regex";
import { appRoutes, authRoutes } from "../../../configs/routes";
import Languages from "../../main/sidebar/account/Languages";

export interface InputData {
  emailInput: string;
  passwordInput: string;
}

interface Errors {
  emailInput?: string;
  passwordInput?: string;
}

const Login: React.FC = observer(() => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const theme = useTheme();
  const { userStore, mainStore } = useStores();
  const { t, ready } = useTranslation("login");
  const [loginForm, setLoginForm] = useState<FormData>({
    emailInput: "",
    passwordInput: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const [showResend, setShowResend] = useState(false);

  const useStyles = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    pageContainer: {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    loginContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "300px",
      width: "100%",
      paddingBottom: "30px",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    groupButtons: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    button: {
      textTransform: "none",
      height: "40px",
      margin: "10px 0px",
      display: "flex",
      alignItems: "center",
    },
    googleIcon: { fontSize: "25px", marginRight: "5px" },
    loginFooter: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
    },
    divider: { margin: "20px 0px" },
    errorText: {
      color: theme.palette.error.main,
    },
    error: {
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  const handleInput = (value: FormData, changedKey: string) => {
    setLoginForm(value);
    setErrors({
      ...errors,
      [changedKey as keyof Errors]: "",
    });
  };

  const handleLogin = () => {
    if (!apolloClient) {
      return setLoginErrors(t("login_error"));
    }

    const emailError =
      !loginForm ||
      !loginForm.emailInput ||
      (loginForm.emailInput as string).trim() === ""
        ? t("email_errorMessage")
        : !emailRegex.test((loginForm?.emailInput as string)?.trim())
        ? t("email_invalidForm")
        : "";

    const passwordError =
      !loginForm ||
      !loginForm?.passwordInput ||
      (loginForm.passwordInput as string).trim() === ""
        ? t("password_errorMessage")
        : "";

    setErrors({ emailInput: emailError, passwordInput: passwordError });

    if (!emailError && !passwordError && loginForm) {
      setIsLoginInProgress(true);

      userStore
        .loginUser(
          apolloClient,
          (loginForm?.emailInput as string)?.trim(),
          loginForm.passwordInput as string
        )
        .then(() => {
          userStore.getUserFromContext(apolloClient).catch(() => {
            setIsLoginInProgress(false);
          });
          userStore.setUsedEmailDifferentProvider(undefined);
        })
        .then(() => {
          history.push(appRoutes.Home());
        })
        .catch((e: Error) => {
          setLoginErrors(t(e?.message));

          if (e?.message === "userNotActive") {
            setShowResend(true);
          }
        })
        .finally(() => {
          setIsLoginInProgress(false);
        });
    }
  };

  const handleLoginGoogle = mainStore.settings?.googleClientId
    ? useGoogleLogin({
        onSuccess: (response) => {
          setIsLoginInProgress(true);

          // eslint-disable-next-line @typescript-eslint/naming-convention
          userStore
            .loginWithGoogle(apolloClient, response.code)
            .then(({ newUser }) => {
              //If the user is first time on the platform, redirect him to create his first namespace
              //Otherwise, redirect him to the Home page
              newUser
                ? history.push(authRoutes.SignUpJourney())
                : history.push(appRoutes.Home());
            })
            .catch((error: Error) => {
              setLoginErrors(t(error?.message || "internalServerError"));
            })
            .finally(() => {
              setIsLoginInProgress(false);
            });
        },
        flow: "auth-code",
      })
    : () => {
        return;
      };

  const handleSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  useEffect(() => {
    setLoginErrors(t(userStore.usedEmailDifferentProvider?.loginError || ""));
    setLoginForm({
      ...loginForm,
      emailInput:
        userStore.tokenData?.email ??
        userStore.usedEmailDifferentProvider?.loginEmail,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return <AppSuspense />;
  }

  const fields: FormField[] = [
    {
      translationKey: "login_emailField",
      key: "emailInput",
      type: FIELD_TYPE.input,
      props: {
        title: t("email_inputTitle"),
        disabled: isLoginInProgress,
        required: true,
      },
      errorText: errors?.emailInput as string,
    },
    {
      translationKey: "login_passwordField",
      key: "passwordInput",
      type: FIELD_TYPE.password,
      props: {
        title: t("password_inputTitle"),
        disabled: isLoginInProgress,
      },
      errorText: errors?.passwordInput as string,
    },
    // TODO: remove comments after "Remember me" is implemented
    // {
    //   translationKey: "keep_loggedIn",
    //   key: "keepLoggedIn",
    //   type: FIELD_TYPE.checkbox,
    //   props: {
    //     disabled: isLoginInProgress,
    //   },
    // },
  ];

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>

      <Container maxWidth="xs" className={classes.pageContainer}>
        <Box className={classes.loginContainer}>
          <Typography variant="h3" fontWeight="600" mb="10px">
            {t("title")}
          </Typography>
          <Typography mb="25px">{t("login_subtitle")}</Typography>
          <form onSubmit={handleSubmit}>
            <CoreFormRenderer
              fields={fields}
              translation={t}
              data={loginForm}
              onChange={handleInput}
            />
            <Box className={classes.groupButtons}>
              <Box className={classes.button}>
                <CoreButton
                  onClick={handleLogin}
                  disabled={isLoginInProgress}
                  isLoading={isLoginInProgress}
                  variant="contained"
                  isFullWidth
                  type="submit"
                >
                  {t("login_button")}
                </CoreButton>
              </Box>
              {mainStore.settings?.googleClientId && (
                <CoreButton
                  onClick={handleLoginGoogle}
                  disabled={isLoginInProgress}
                  variant="outlined"
                  isFullWidth
                  className={classes.button}
                >
                  <GoogleIcon className={classes.googleIcon} />
                  {t("login_withGoogle")}
                </CoreButton>
              )}
            </Box>
          </form>

          {loginErrors && (
            <Box className={classes.error}>
              <Typography className={classes.errorText}>
                {loginErrors}
              </Typography>
              {showResend && (
                <Link
                  href={authRoutes.ResendActivation()}
                  color="inherit"
                  underline="none"
                >
                  {t("resendActivationLink_title")}
                </Link>
              )}
            </Box>
          )}
          <Divider className={classes.divider} />

          <Box className={classes.loginFooter}>
            {process.env.REACT_APP_ENABLE_REGISTER === "true" ? (
              <Link
                href={authRoutes.SignUpJourney()}
                color="inherit"
                underline="none"
              >
                {t("newUser_message")}
              </Link>
            ) : (
              <></>
            )}

            <Link
              href={authRoutes.ForgotPassword()}
              color="inherit"
              underline="none"
            >
              {t("forgot_password")}
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
});

export default Login;
