/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node, Edge, NodeChange, ReactFlowInstance } from "reactflow";

import ELK, { ElkNode } from "elkjs/lib/elk.bundled.js";

import {
  FlowDiagram,
  FlowNode,
  NodeData,
  Schema,
  NestedPropertyWithType,
  NodeFilters,
  Variable,
  InputOptions,
  AssetType,
} from "../../../../../types/interfaces";
import {
  CONTEXT_LABEL,
  DIAGRAM_NODE_CATEGORIES,
  NODE_ASSET_TYPE,
  NodeInputAllowedTypes,
  NODES_ALLOWED_TYPES,
  VAL_TYPES,
} from "../../../../../types/constants";
import { ElkNodeParent } from "../../../../../types/types";

export default class NodesHelper {
  static buildElkGraph = (nodes: Node[], edges: Edge<any>[]) => {
    const graph: ElkNode = {
      id: "root",
      layoutOptions: {
        "elk.algorithm": "layered",
        "elk.direction": "DOWN",
        "spacing.nodeNodeBetweenLayers": "80",
        "elk.hierarchyHandling": "INCLUDE_CHILDREN",
      },
      children: [],
      edges: [],
    };

    const elkNodes = nodes.map((node) => {
      return {
        id: node.id,
        parentId: node.parentId,
        width: node.width || 150,
        height: node.height || 40,
        layoutOptions: {
          "elk.algorithm": "layered",
          "elk.direction": "DOWN",
          "spacing.nodeNodeBetweenLayers": "80",
          "elk.hierarchyHandling": "INCLUDE_CHILDREN",
          "elk.padding": "[top=25.0,left=25.0,bottom=25.0,right=25.0]",
        },
      } as ElkNode & { parentId?: string };
    });

    const nodeMap = new Map<
      string,
      ElkNode & { parentId?: string; children: ElkNode[] }
    >();
    const rootNodes = [] as any[];

    // Step 1: Create a map of nodes and initialize each node's children array
    elkNodes.forEach((node) => {
      nodeMap.set(node.id, { ...node, children: [] as ElkNode[] });
    });

    // Step 2: Loop through the nodes and assign each to its parent or root list
    elkNodes.forEach((node) => {
      const currentNode = nodeMap.get(node.id) as ElkNode & {
        parentId?: string;
        children: ElkNode[];
      };

      if (node.parentId) {
        // If the node has a parentId, find the parent and add the current node as a child
        const parentNode = nodeMap.get(node.parentId);
        if (parentNode) {
          parentNode.children.push(currentNode);
        }
      } else {
        // If the node doesn't have a parentId, it's a root node
        rootNodes.push(currentNode);
      }
    });

    graph.children = rootNodes;

    graph.edges = edges.map((edge) => {
      return {
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      };
    });

    return graph;
  };

  static flattenElkGraph = (graph: ElkNode) => {
    const flatList = [] as ElkNodeParent[];

    const traverse = (node: ElkNodeParent, parentId?: string) => {
      // Add the current node to the flat list, including its parentId (if it has one)
      const { id, children, ...rest } = node; // Extract id and children, and keep other properties
      flatList.push({
        id,
        parentId,
        ...rest,
      } as unknown as ElkNodeParent);

      // Traverse the children (if any), passing the current node's id as their parentId
      if (children && children.length) {
        children.forEach((child) => traverse(child as ElkNodeParent, id));
      }
    };

    // Start traversing from the root nodes
    (graph.children as ElkNode[]).forEach((node) =>
      traverse(node as ElkNodeParent, undefined)
    );

    return flatList;
  };

  static getLayoutedElements = (nodes: Node[], edges: Edge<any>[]) => {
    const elk = new ELK();

    return elk.layout(this.buildElkGraph(nodes, edges));
  };

  static handleNodesChange(
    changes: NodeChange[],
    onNodesChange: (changes: NodeChange[]) => void
  ) {
    const nextChanges = changes.reduce((acc, change) => {
      // if this change is supposed to remove a node we want to validate it first
      if (change.type === "remove") {
        // if the node can be removed, keep the change, otherwise we skip the change and keep the node
        if (change.id !== NODE_ASSET_TYPE.startNode) {
          return [...acc, change];
        }

        // change is skipped, node is kept
        return acc;
      }

      // all other change types are just put into the next changes arr
      return [...acc, change];
    }, [] as NodeChange[]);

    // apply the changes we kept
    onNodesChange(nextChanges);
  }

  static readonly validateDiagram = (json: FlowDiagram) => {
    if (!json || Object.keys(json).length === 0) {
      return false;
    }

    // check if the json has the required keys
    if (!json.specs || Object.keys(json.specs).length === 0) {
      return false;
    }

    if (!json.specs.nodes || json.specs.nodes.length === 0) {
      return false;
    }

    if (!json.specs.edges || json.specs.edges.length === 0) {
      return false;
    }

    return true;
  };

  static findOutgoing = (reactFlow: ReactFlowInstance, identifier: string) => {
    return reactFlow
      .getEdges()
      .filter((edge) => edge.source === identifier)
      .map((edge) => edge.target);
  };

  //Used to determine if there are other nodes under a group
  static findGroupOutgoing = (
    reactFlow: ReactFlowInstance,
    identifier: string
  ) => {
    const placeHolderParentId = reactFlow
      .getNodes()
      ?.find((node) => node?.id === identifier)?.parentId;

    return reactFlow
      .getEdges()
      ?.filter((edge) => edge?.source === placeHolderParentId)
      ?.map((edge) => edge?.target);
  };

  //Used to hide the ResizeObserver error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static errorHandler = (e: any) => {
    if (
      //eslint-disable-next-line
      e.message.includes(
        "ResizeObserver loop completed with undelivered notifications"
      ) ||
      //eslint-disable-next-line
      e.message.includes("ResizeObserver loop limit exceeded")
    ) {
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (resizeObserverErr) {
        resizeObserverErr.style.display = "none";
      }
    }
  };

  static getNestedProperties = (
    schema: Schema,
    parentKey = "",
    result: Variable[] = [],
    filters: NodeFilters = {}
  ): Variable[] => {
    // If there are no properties, return the current result
    if (!schema?.properties) return result;

    // Iterate over each property in the schema
    for (const key in schema?.properties) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const property = schema?.properties[key];

      const option = { ...(property || {}), key: currentKey } as Variable;

      // Check if the property is an object and has properties to explore
      if (property?.type?.includes("object")) {
        result.push(option);

        // Recursively call for nested object properties
        if (property?.properties) {
          this.getNestedProperties(property, currentKey, result);
        }
      }
      // If the property is an array, skip its nested properties
      else if (
        property?.type?.includes("array") ||
        property?.type?.includes("list")
      ) {
        // If the filter is set to include array nested properties, explore them
        if (filters?.includeArrayNestedProperties) {
          if (property?.items?.type === "object") {
            result.push(option);
            if (property?.items?.properties) {
              this.getNestedProperties(
                property.items,
                currentKey,
                result,
                filters
              );
            }
          }
        } else {
          result.push(option); // Add only the array key
        }
      }
      // For any other property type, add the current key to the result
      else {
        result.push(option);
      }
    }

    return result; // Return the accumulated result
  };

  static getNestedPropertiesWithType = (
    schema: Schema,
    parentKey = "",
    result: NestedPropertyWithType[] = []
  ): NestedPropertyWithType[] => {
    // If there are no properties, return the current result
    if (!schema?.properties) return result;

    // Iterate over each property in the schema
    for (const key in schema?.properties) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const property = schema?.properties[key];

      // Check if the property is an object and has properties to explore
      if (property?.type === "object") {
        result.push({ key: currentKey, type: "object" });

        if (property.properties) {
          this.getNestedPropertiesWithType(property, currentKey, result);
        }
      }
      // If the property is an array, skip its nested properties
      else if (property?.type === "array") {
        result.push({ key: currentKey, type: NodeInputAllowedTypes.list }); // Add only the array key
      }
      // For any other property type, add the current key to the result
      else {
        result.push({ key: currentKey, type: property?.type });
      }
    }

    return result; // Return the accumulated result
  };

  static validateAllowedTypes = (
    allowedTypes: string[] | null | undefined
  ): string[] => {
    let types = allowedTypes || [];

    // Default to "any" in case allowedTypes is not received
    if (types?.length === 0) {
      types = [NODES_ALLOWED_TYPES.any];
    }

    return types;
  };

  static findReachableNodes = (
    edges: Edge<any>[],
    nodeId: string,
    nodesToRemove: Set<string>
  ) => {
    // perform a DFS and mark all reachable nodes
    if (nodesToRemove.has(nodeId)) {
      return;
    }
    nodesToRemove.add(nodeId);

    // find all edges where the source is the current node
    edges.forEach((edge) => {
      if (edge.source === nodeId) {
        this.findReachableNodes(edges, edge.target, nodesToRemove);
      }
    });
  };

  static findReachableNodesForGroup = (
    edges: Edge<any>[],
    nodeId: string,
    nodesToRemove: Set<string>
  ) => {
    edges?.forEach((edge) => {
      if (edge?.source === nodeId) {
        const targetNodeId = edge?.target;
        if (!nodesToRemove.has(targetNodeId)) {
          nodesToRemove.add(targetNodeId);
          this.findReachableNodesForGroup(edges, targetNodeId, nodesToRemove);
        }
      }
    });
  };

  static deleteSingleNode(reactFlow: ReactFlowInstance, identifier: string) {
    const targetNode = reactFlow.getNode(identifier) as Node;
    if (targetNode.type === DIAGRAM_NODE_CATEGORIES.group) {
      const identifiers = [identifier];
      const foundParents = [identifier];

      while (foundParents.length > 0) {
        const currentParent = foundParents.pop();

        reactFlow
          .getNodes()
          .filter((node) => node.parentId === currentParent)
          .forEach((node) => {
            if (node.type === DIAGRAM_NODE_CATEGORIES.group) {
              foundParents.push(node.id);
            }
            identifiers.push(node.id);
          });
      }
      return identifiers;
    } else {
      return [identifier];
    }
  }

  static deleteNodeHierarchy(reactFlow: ReactFlowInstance, identifier: string) {
    const reachableNodes = new Set<string>();
    const targetNodes = [] as string[];
    this.findReachableNodes(reactFlow.getEdges(), identifier, reachableNodes);

    Array.from(reachableNodes).forEach((nodeId) => {
      targetNodes.push(...this.deleteSingleNode(reactFlow, nodeId));
    });

    return targetNodes;
  }

  static deleteGroupChildren(reactFlow: ReactFlowInstance, identifier: string) {
    const reachableNodes = new Set<string>();
    const targetNodes = [] as string[];

    this.findReachableNodesForGroup(
      reactFlow.getEdges(),
      identifier,
      reachableNodes
    );

    Array.from(reachableNodes)?.forEach((nodeId) => {
      targetNodes.push(...this.deleteSingleNode(reactFlow, nodeId));
    });

    return targetNodes;
  }

  static deleteConditionalEdgesFixup(
    reactFlow: ReactFlowInstance,
    nodeIdentifiers: string[]
  ) {
    const allEdges = reactFlow.getEdges();

    const fixupEdges = allEdges?.filter(
      (edge) =>
        nodeIdentifiers?.includes(edge?.source) ||
        nodeIdentifiers?.includes(edge?.target)
    );

    if (fixupEdges?.length === 1) {
      const targetEdge = fixupEdges?.[0];

      return allEdges?.filter((edge) => edge?.id !== targetEdge?.id);
    } else {
      return allEdges?.filter(
        (edge) =>
          !nodeIdentifiers?.includes(edge?.source) &&
          !nodeIdentifiers?.includes(edge?.target)
      );
    }
  }

  static deleteEdgesFixup(
    reactFlow: ReactFlowInstance,
    nodeIdentifier: string,
    nodeIdentifiers: string[]
  ) {
    const fixupEdges = reactFlow
      .getEdges()
      .filter(
        (edge) =>
          nodeIdentifiers.includes(edge.source) !==
          nodeIdentifiers.includes(edge.target)
      );

    let newEdges = reactFlow
      .getEdges()
      .filter(
        (edge) =>
          !(
            nodeIdentifiers.includes(edge.source) &&
            nodeIdentifiers.includes(edge.target)
          )
      );

    // handle edges surrounding our target node
    if (fixupEdges.length === 1) {
      // we just remove the dangling edge
      const targetEdge = fixupEdges[0];
      newEdges = newEdges.filter((edge) => edge.id !== targetEdge.id);
    } else {
      // we have two dangling edges here, one to remove and one to fixup
      // we will remove the edge which has our target node as it's source
      const edgeToRemove = fixupEdges.find(
        (edge) => edge.source === nodeIdentifier
      ) as Edge<any>;
      const edgeToFixup = fixupEdges.find(
        (edge) => edge.id !== edgeToRemove.id
      ) as Edge<any>;

      newEdges = newEdges
        .filter((edge) => edge.id !== edgeToRemove.id)
        .map((edge) => {
          if (edge.id === edgeToFixup.id) {
            // repair edge connection
            edge.target = edgeToRemove.target;
          }
          return edge;
        });
    }
    return newEdges;
  }

  static attachSource = (nodes: FlowNode[], edges: Edge[]) => {
    return nodes.map((node) => {
      if (node.type === DIAGRAM_NODE_CATEGORIES.placeholder && node.parentId) {
        return {
          ...node,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: { ...(node.data || {}), source: [node.parentId] },
        };
      }

      const edge = edges.find((edge) => edge.target === node.identifier);
      if (edge?.source) {
        return {
          ...node,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: { ...(node.data || {}), source: [edge.source] },
        };
      }

      return node;
    });
  };

  static formatContextObjects = (variables: Variable[]): Variable[] => {
    if (variables?.length > 0) {
      return variables
        ?.map((option) => {
          const schemaOptions = this.getNestedProperties(option?.schema);
          const rootProps = Object.keys(option.schema);

          return [
            {
              ...option,
              label: option.name,
              value: `${CONTEXT_LABEL}.${option?.key}`,
              key: `${CONTEXT_LABEL}.${option?.key}`,
            },
            ...(schemaOptions?.map((schemaOption) => {
              const schemaKey = schemaOption.key;
              const iterationKey = `${CONTEXT_LABEL}.${option?.key || ""}.${
                schemaKey || ""
              }`;
              const iterationName = `${option?.name}.${schemaKey}`;
              const iterationType = schemaOption?.type;

              return {
                ...(schemaOption || {}),
                type:
                  iterationType === VAL_TYPES.array
                    ? NodeInputAllowedTypes.list
                    : iterationType,
                key: iterationKey,
                label: iterationName,
                name: iterationName,
                value: iterationKey,
                nodeIdentifier: option.nodeIdentifier,
                isDynamicContent: !rootProps.includes(schemaKey),
              };
            }) || []),
          ];
        })
        .flat() as unknown as Variable[];
    }

    return [];
  };

  static collectParentNodeIds = (
    currentNodeId: string,
    collectedIds: Set<string>,
    nodes: FlowNode[]
  ) => {
    const currentNode = nodes?.find(
      (node) => node?.identifier === currentNodeId
    );
    const nodeDataSource = (currentNode?.data as NodeData)?.source;
    if (currentNode && currentNode?.data && nodeDataSource) {
      for (const parentId of nodeDataSource) {
        if (!collectedIds.has(parentId)) {
          collectedIds.add(parentId);
          this.collectParentNodeIds(parentId, collectedIds, nodes);
        }
      }
    }
  };

  //Check if parent node for a given node is a conditional node
  static conditionalNodeCheck = (nodes: FlowNode[], key: string) => {
    return (
      nodes?.find((node) => node.identifier === key)?.key !==
      NODE_ASSET_TYPE.conditional
    );
  };

  static extractParentIterationProps = (
    nodes: FlowNode[],
    parentId: string,
    options: Variable[]
  ) => {
    // Find parent node (ex: Paralel iteration, While)
    const node = nodes.find((node) => node.id === parentId);
    if (!node) {
      return [];
    }

    // Extract selected input of parent node
    const selectedInput = node?.parameters?.["nodeInput"];
    const inputOption = options?.find((op) => op.key === selectedInput);

    // Extract properties of a single item from list
    if (
      inputOption &&
      inputOption.type === NodeInputAllowedTypes.list &&
      inputOption?.items?.properties
    ) {
      const optionsProperties = inputOption?.items?.properties;

      let inputOptions = [] as InputOptions[];

      Object.keys(optionsProperties).forEach((propertyName) => {
        inputOptions = [
          ...inputOptions,
          {
            ...(optionsProperties[propertyName] || ""),
            category: "iteration",
            value: `value.${propertyName}`,
            key: `value.${propertyName}`,
          },
        ];
      });

      return inputOptions;
    }

    return [];
  };

  static getDefaultCtxOptions = (nodeInputOptions: AssetType[]) => {
    return (
      nodeInputOptions?.flatMap((option) => {
        return [
          {
            key: option?.name,
          },
          ...((
            option?.parameters as unknown as [{ [key: string]: string }]
          )?.map((param) => ({
            key: `${option?.name}.${param?.key}`,
          })) || []),
        ];
      }) || []
    );
  };
}
