import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { FIELD_TYPE } from "../../../types/constants";
import { authRoutes } from "../../../configs/routes";
import { useNotification } from "../../../context/useNotification";
import { useStores } from "../../../stores/StoresProvider";
import { passwordRegex } from "../../../types/regex";
import CoreButton from "../../core/CoreButton";
import { AppSuspense } from "../../main/AppSuspense";
import { FormData } from "../../../types/interfaces";
import Languages from "../../main/sidebar/account/Languages";

interface Errors {
  password: string;
  confirmPassword: string;
}

const useStyle = makeStyles({
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  resetContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    minWidth: "300px",
  },
  languageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginRight: "20px",
  },
  form: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
});

const ResetPassword: React.FC = () => {
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const [errors, setErrors] = useState<Errors>();
  const [formData, setFormData] = useState<FormData>({
    password: "",
    confirmPassword: "",
  });

  const client = useApolloClient();
  const notification = useNotification();
  const classes = useStyle();
  const { userStore } = useStores();
  const { t, ready } = useTranslation("login");
  const history = useHistory();
  const fields = [
    {
      translationKey: "passwordInput_placeholder",
      key: "password",
      type: FIELD_TYPE.password,
      props: {
        disabled: isOperationInProgress,
      },
      errorText: errors?.password,
    },
    {
      translationKey: "confirmPasswordInput_placeholder",
      key: "confirmPassword",
      type: FIELD_TYPE.password,
      props: {
        disabled: isOperationInProgress,
      },
      errorText: errors?.confirmPassword,
    },
  ];
  const resetToken = new URLSearchParams(window.location.search).get(
    "resetToken"
  );

  const handleReset = () => {
    const passwordError =
      !formData ||
      !formData?.password ||
      (formData?.password as string).trim() === ""
        ? t("validationFieldsError")
        : !passwordRegex.test(formData?.password as string)
        ? t("password_tooltip")
        : "";

    const confirmPasswordError =
      !formData ||
      !formData?.confirmPassword ||
      (formData?.confirmPassword as string).trim() === ""
        ? t("validationFieldsError")
        : formData?.confirmPassword !== formData?.password
        ? t("confirmPassword_tooltip")
        : "";

    if (passwordError || confirmPasswordError) {
      setErrors({
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });

      return;
    }

    if (!resetToken) {
      notification.error(t("passwordReset_error"));
      return;
    }

    if (formData && resetToken) {
      setIsOperationInProgress(true);
      userStore
        .resetPassword(client, formData.password as string, resetToken)
        .then(() => {
          notification.success(t("passwordReset_success"));
          history.push(authRoutes.Login());
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "passwordReset_error"));
        })
        .finally(() => {
          setIsOperationInProgress(false);
        });
    }
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Container maxWidth="xs" className={classes.pageContainer}>
        <Box className={classes.resetContainer}>
          <Typography variant="h5" fontWeight="600" mb="25px">
            {t("resetPassword_title")}
          </Typography>

          <Box className={classes.form}>
            <CoreFormRenderer
              fields={fields}
              translation={t}
              data={formData}
              onChange={setFormData}
            />
            <CoreButton
              onClick={handleReset}
              isLoading={isOperationInProgress}
              disabled={isOperationInProgress}
              variant="contained"
            >
              {t("resetPasswordButton_label")}
            </CoreButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPassword;
