import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

import { appRoutes } from "../../../../../configs/routes";
import CorePageContainer from "../../../../core/CorePageContainer";
import { CorePageMessage } from "../../../../core/CorePageMessage";
import { useStores } from "../../../../../stores/StoresProvider";
import { DetailCards } from "./main/cards/DetailCards";
import { TabsContent } from "./main/TabsContent";
import { HeaderActions } from "../../../../../types/interfaces";
import NoSubscriptionCard from "./main/cards/NoSubscriptionCard";

const useStyles = makeStyles({
  container: {
    margin: "20px",
  },
});

export const Subscription: React.FC = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslation("subscription");
  const { subscriptionStore, userStore } = useStores();

  const canReadFlows =
    userStore.currentUserPermissions?.can("read", "flows") ?? false;

  useEffect(() => {
    void subscriptionStore.loadSubscriptionData(canReadFlows);

    return () => {
      subscriptionStore.resetStore();
    };
  }, [subscriptionStore, canReadFlows]);

  const handleRedirect = () => {
    history.push(appRoutes.SubscriptionPlanUpgrade());
  };

  const headerActions: HeaderActions[] = [
    {
      label: t("header_action_manage_subscription"),
      tooltip: t("header_action_manage_subscription"),
      onClick: () => {
        handleRedirect();
      },
      startIcon: <CardMembershipIcon />,
      disabled: !subscriptionStore.billingCustomer,
      ability: [
        {
          action: "update",
          subject: "subscription",
        },
      ],
    },
  ];

  return (
    <CorePageContainer
      label={t("title")}
      isPageLoading={!ready || subscriptionStore.isDataLoading}
      extraHeaderActions={headerActions}
      isNotFlowPage={true}
      showMoreVertIcon={
        userStore.currentUserPermissions?.can("update", "subscription") ?? false
      }
    >
      {!subscriptionStore.billingCustomer ? (
        <CorePageMessage
          title={t("no_data_title")}
          subtitle={t("no_data_subtitle")}
        />
      ) : (
        <Box className={classes.container}>
          {!subscriptionStore.activeSubscription && (
            <NoSubscriptionCard t={t} />
          )}

          <DetailCards />

          <TabsContent translate={t} />
        </Box>
      )}
    </CorePageContainer>
  );
});
