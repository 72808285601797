import React from "react";

import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import logo from "./ThreeStripesLogo.png";
import { ReactComponent as FullLogo } from "./../claritextLogo.svg";

interface Props {
  threeStripes?: boolean;
  threeStripesClassName?: string | undefined;
  fullLogoClassName?: string | undefined;
  fullLogoOnClick?: () => void;
}

const Logo: React.FC<Props> = ({
  threeStripes = false,
  threeStripesClassName,
  fullLogoClassName,
  fullLogoOnClick,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    fullLogo: {
      fill: theme.palette.text.primary,
    },
  });

  const classes = useStyles();

  const fullLogoClass = `${classes.fullLogo} ${fullLogoClassName || ""}`;

  if (threeStripes) {
    return (
      <img src={logo} alt="Logo" className={threeStripesClassName || ""} />
    );
  } else {
    return <FullLogo className={fullLogoClass} onClick={fullLogoOnClick} />;
  }
};

export default Logo;
