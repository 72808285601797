import { gql } from "@apollo/client";
import {
  Document,
  DocumentData,
  DocumentHistory,
  DocumentPage,
} from "../../types/interfaces";

export interface QUERY_GET_FLOW_DOCUMENTS_RESULT {
  getFlowDocuments: {
    documents: Document[];
    totalDocuments: number;
    amountOfPages: number;
    currentPage: number;
  };
}

export interface QUERY_GET_DOCUMENT_PAGE_RESULT {
  getDocumentPage: DocumentPage;
}

export interface QUERY_GET_DOCUMENT_RESULT {
  getFlowDocument: Document;
}

export interface QUERY_GET_DOCUMENT_DATA {
  getFlowDocumentData: DocumentData;
}

export interface QUERY_DOCUMENT_HISTORY_RESPONSE {
  getDocumentHistory: DocumentHistory[];
}

export interface QUERY_DOCUMENT_HISTORY_EVENT_RESPONSE {
  getDocumentHistoryEvent: DocumentHistory;
}

export const GET_DOCUMENT = gql`
  query getFlowDocument(
    $flowId: String
    $documentId: String
    $state: String
    $filters: JSON
    $documentIdentifier: String
  ) {
    getFlowDocument(
      flowId: $flowId
      documentId: $documentId
      state: $state
      filters: $filters
      documentIdentifier: $documentIdentifier
    ) {
      name
      pagesUrls
      identifier
      pages
      fields
      lineItems
      categories
      status
      flowIdentifier
      isViewMode
      createdAt
      finishedAt
      metadata
    }
  }
`;

export const GET_DOCUMENT_DATA = gql`
  query getFlowDocumentData($documentId: String!) {
    getFlowDocumentData(documentId: $documentId) {
      data
    }
  }
`;

export const GET_FLOW_DOCUMENTS = gql`
  query getFlowDocuments(
    $flowId: String!
    $page: Int!
    $pageSize: Int!
    $filters: DocumentsFiltersInput!
  ) {
    getFlowDocuments(
      id: $flowId
      page: $page
      pageSize: $pageSize
      filters: $filters
    ) {
      documents {
        name
        pageCount
        identifier
        flowIdentifier
        createdAt
        finishedAt
        metadata
        lineItems
        updatedAt
        status
        pageUri
        values
      }
      totalDocuments
      amountOfPages
      currentPage
    }
  }
`;

export const GET_DOCUMENT_PAGE = gql`
  query getDocumentPage($documentId: String!, $pageIdentifier: String!) {
    getDocumentPage(documentId: $documentId, pageIdentifier: $pageIdentifier) {
      identifier
      documentIdentifier
      lineItems
      uri
      pageNumber
      ocrData
      pageInformation
      rotation
    }
  }
`;

export const GET_DOCUMENT_HISTORY = gql`
  query getDocumentHistory($documentId: String!) {
    getDocumentHistory(documentId: $documentId) {
      eventIdentifier
      time
      actionCode
      actionStatus
      userId
      user {
        first_name
        last_name
      }
      statusDetails
      data
    }
  }
`;

export const GET_DOCUMENT_HISTORY_EVENT = gql`
  query getDocumentHistoryEvent(
    $documentId: String!
    $eventIdentifier: String!
  ) {
    getDocumentHistoryEvent(
      documentId: $documentId
      eventIdentifier: $eventIdentifier
    ) {
      data
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query getDocuments(
    $page: Int!
    $pageSize: Int!
    $payload: DocumentsFiltersInput
  ) {
    getDocuments(page: $page, pageSize: $pageSize, payload: $payload) {
      documents {
        identifier
        name
        createdAt
        updatedAt
        status
        flowName
        flowIdentifier
        pageCount
        pageUri
      }
      totalDocuments
      amountOfPages
      currentPage
    }
  }
`;
