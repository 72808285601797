import React, { FC, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Group, Line, Rect, Image } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import useImage from "use-image";

import { Theme } from "@mui/material";

import {
  DocumentLineItem,
  DocumentLineItemRow,
} from "../../../../../../types/interfaces";
import { DocumentStore } from "../../../../../../stores/DocumentStore";
import PlusIcon from "../../../../../../icons/PlusIcon.svg";
import MinusIcon from "../../../../../../icons/MinusIcon.svg";
import {
  DELETE_LINE_RANGE,
  LINE_SPACING,
} from "../../../../../../types/constants";
import RightClickMenuRow from "./menus/RightClickMenuRow";

interface Props {
  table: DocumentLineItem;
  documentStore: DocumentStore;
  theme: Theme;
}

// TODO: remove comments after split icon size on zoom in/out is done
// Konva requires images to be built with their Image component based on incoming URL
export const KonvaImageRenderer: FC<{
  x: number;
  y: number;
  // width: number;
  // height: number;
  deleteMode: boolean;
  // }> = ({ x, y, width, height, deleteMode }) => {
}> = ({ x, y, deleteMode }) => {
  const [image] = useImage(deleteMode ? MinusIcon : PlusIcon);

  // return <Image image={image} width={width} height={height} x={x} y={y} />;
  return <Image image={image} width={10} height={10} x={x} y={y} />;
};

export const RowLineDraft: FC<Props> = observer(
  ({ table, documentStore, theme }) => {
    const [contextMenu, setContextMenu] = useState<{
      mouseX: number;
      mouseY: number;
    } | null>(null);

    const [currentLine, setCurrentLine] = useState<DocumentLineItemRow | null>(
      null
    );

    const [isHovered, toggleHover] = useState(false);

    // Since the cursor will only draw on Y axis, x position will remain the same in the icon coordinates
    const [yPosition, setYPosition] = useState(0);
    const [lineDeleteMode, setLineDeleteMode] = useState(false);

    const focusedLineItem = documentStore.focusedLineItem;

    const tableEdges = useMemo(
      () => ({
        box: table?.coords?.root,
        type: table.type,
      }),
      [table?.coords?.root, table.type]
    );

    // const xPoint =
    //   (documentStore.canvasScaleSize * 10000) / tableEdges?.box?.height - 25;

    const calculatedYPosition = useMemo(
      // The yPosition is calculated strictly within the drawn boundary
      () => yPosition - tableEdges?.box?.y,
      [tableEdges?.box?.y, yPosition]
    );

    const isDeleteMode = (lineY: number) => {
      const lineTop = lineY - DELETE_LINE_RANGE;
      const lineBottom = lineY + DELETE_LINE_RANGE;

      const deleteMode = focusedLineItem?.coords?.lines?.some(
        (line) => line[0][1] >= lineTop && line[1][1] <= lineBottom
      );

      return !!deleteMode;
    };

    const onNewLineDraw = (event: KonvaEventObject<MouseEvent>) => {
      const { y = 0 } =
        event?.target?.getStage()?.getRelativePointerPosition() ?? {};

      documentStore.initLineItemRowLine(
        tableEdges?.type,
        [
          [tableEdges?.box?.x, y],
          [tableEdges?.box?.x + tableEdges?.box?.width, y],
        ],
        lineDeleteMode
      );
    };

    // Triggered when mouse enters line
    const handleMouseEnter = (event: KonvaEventObject<MouseEvent>) => {
      if (event.evt.ctrlKey) {
        const { y = 0 } =
          event?.target?.getStage()?.getRelativePointerPosition() || {};

        setYPosition(y);
        toggleHover(true);
      }

      const stage = event.target.getStage();
      if (stage) {
        const container = stage.container();
        container.style.cursor = "pointer";
      }
    };

    // Triggered when mouse moves on the line
    const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
      if (event.evt.ctrlKey) {
        const { y = 0 } =
          event?.target?.getStage()?.getRelativePointerPosition() || {};

        setLineDeleteMode(isDeleteMode(y));
        setYPosition(y);

        if (!isHovered) {
          toggleHover(true);
        }
      } else {
        if (isHovered) {
          toggleHover(false);
        }
      }
    };

    // Triggered when mouse moves leaves line
    const handleMouseLeave = (event: KonvaEventObject<MouseEvent>) => {
      // Reset position
      setYPosition(0);
      toggleHover(false);

      const stage = event.target.getStage();
      if (stage) {
        const container = stage.container();
        container.style.cursor = "default";
      }
    };

    const toggleIgnoreRow = (event: KonvaEventObject<MouseEvent>) => {
      // Draw line only when Alt key is being pressed
      if (event.evt.ctrlKey) {
        onNewLineDraw(event);
      }
    };

    //right click menu handle function
    const handleContextMenu = (event: KonvaEventObject<PointerEvent>) => {
      event.evt.preventDefault();
      const { x: mouseX, y: mouseY } = event.evt;
      const { y } = event.target.getStage()?.getRelativePointerPosition() ?? {
        x: 0,
        y: 0,
      };

      setCurrentLine(documentStore.getRow(y));
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: mouseX + 10,
              mouseY: mouseY - 10,
            }
          : null
      );
    };
    // right click menu close function
    const handleCloseContextMenu = () => {
      setContextMenu(null);
    };

    // const konvaImageRendererY =
    //   calculatedYPosition -
    //   5 / documentStore.canvasScaleSize -
    //   documentStore.canvasScaleSize * 2;
    // const imageDimension =
    //   10 / documentStore.canvasScaleSize + documentStore.canvasScaleSize * 5;

    return (
      <>
        {/* Row line draw */}
        <Group
          x={tableEdges?.box?.x - LINE_SPACING}
          y={tableEdges?.box?.y}
          onContextMenu={handleContextMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onClick={toggleIgnoreRow}
        >
          <Rect width={LINE_SPACING} height={tableEdges?.box?.height} />

          {isHovered && (
            <>
              <Group>
                <KonvaImageRenderer
                  x={0}
                  y={calculatedYPosition - 5}
                  // x={xPoint}
                  // y={konvaImageRendererY}
                  // width={imageDimension}
                  // height={imageDimension}
                  deleteMode={lineDeleteMode}
                />
              </Group>

              <Line
                points={[
                  LINE_SPACING,
                  calculatedYPosition,
                  tableEdges?.box?.width + LINE_SPACING,
                  calculatedYPosition,
                ]}
                closed
                stroke={
                  lineDeleteMode
                    ? theme.palette.error.main
                    : theme.palette.canvas.main
                }
              />
            </>
          )}

          <Line
            // points={[xPoint, 0, xPoint, tableEdges?.box?.height]}
            points={[0, 0, 0, tableEdges?.box?.height]}
            closed
            stroke={theme.palette.canvas.main}
          />
          <RightClickMenuRow
            tableCoords={tableEdges?.box}
            open={contextMenu !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? {
                    top: contextMenu.mouseY,
                    left: contextMenu.mouseX,
                  }
                : undefined
            }
            currentLine={currentLine}
            documentStore={documentStore}
          />
        </Group>
      </>
    );
  }
);
