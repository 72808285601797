import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { SxProps, Theme } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  useTheme,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import CoreTooltip from "./CoreTooltip";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface CoreAccordionProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  expanded?: boolean;
  onChange?: () => void;
  onClick?: () => void;
  defaultExpanded?: boolean;
  summaryChildren?: React.ReactNode;
  titleClassName?: string;
  shouldRenderExpandIcon?: boolean;
  summaryClassName?: string;
  accordionDetailsClassName?: string;
  elevation?: number;
  iconSx?: SxProps<Theme>;
  accordionMaxWidth?: string;
  showAccordionDetails?: boolean;
  customExpandIcon?: React.ReactNode;
  expandIconPosition?: "left" | "right";
  accordionBackgroundStyle?: boolean;
  hasDivider?: boolean;
  customBox?: boolean;
  applyBoxAccordionClassName?: boolean;
  width?: string;
  warning?: string;
  customAccordionBoxStyle?: string;
  showSettingsIcon?: boolean;
  settingsIcon?: React.ReactNode;
  settingsIconClassName?: string;
  priorityIcon?: React.ReactNode;
  priorityIconClassName?: string;
  showPriorityIcon?: boolean;
  accordionCustomBackground?: boolean;
}

const CoreAccordion: React.FC<CoreAccordionProps> = ({
  title,
  description,
  children,
  expanded,
  onChange,
  onClick,
  defaultExpanded,
  summaryChildren,
  titleClassName,
  shouldRenderExpandIcon = true,
  summaryClassName,
  accordionDetailsClassName,
  elevation,
  iconSx,
  accordionMaxWidth,
  showAccordionDetails = true,
  customExpandIcon,
  expandIconPosition = "right",
  accordionBackgroundStyle = true,
  hasDivider = true,
  customBox = false,
  applyBoxAccordionClassName = true,
  width,
  warning = false,
  customAccordionBoxStyle,
  showSettingsIcon = false,
  settingsIcon,
  settingsIconClassName,
  priorityIcon,
  priorityIconClassName,
  showPriorityIcon = false,
  accordionCustomBackground = false,
}) => {
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;
  const theme = useTheme();
  const useStyles = makeStyles(() =>
    createStyles({
      accordion: {
        boxShadow: "none",
        borderRadius: "0px !important",
        width: `${width || "100%"}`,
        maxWidth: `${accordionMaxWidth || ""}`,
        padding: "0",
        "& .MuiAccordionSummary-content": {
          margin: "7px 0",
          cursor: "default",
        },
        backgroundColor: accordionBackgroundStyle
          ? currentTheme === THEME_MODES.light
            ? accordionCustomBackground
              ? theme.palette.secondary.dark
              : theme.palette.secondary.light
            : theme.palette.background.paper
          : "",
      },
      boxAccordion: {
        display: "flex",
        marginTop: "1rem",
      },
      icon: {
        alignSelf: "center",
        marginLeft: "15px",
      },
      expandIconLeft: {
        "& .MuiAccordionSummary-expandIconWrapper": {
          order: -1,
          marginLeft: "auto",
          marginRight: 0,
        },
      },
      customBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      settingsAndPriority: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "10px",
        marginLeft: "auto",
        marginRight: "15px",
      },
      details: {
        marginTop: "8px",
      },
    })
  );
  const classes = useStyles();

  const getBoxClassName = () => {
    if (customAccordionBoxStyle) {
      return customAccordionBoxStyle;
    } else if (applyBoxAccordionClassName) {
      return classes.boxAccordion;
    }
    return "";
  };

  return (
    <Box className={getBoxClassName()}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={onChange}
        onClick={onClick}
        defaultExpanded={defaultExpanded}
        elevation={elevation}
        disableGutters
      >
        <Box className={customBox ? classes.customBox : ""}>
          <AccordionSummary
            expandIcon={
              shouldRenderExpandIcon
                ? customExpandIcon || <ExpandMoreIcon sx={iconSx} />
                : null
            }
            className={`${summaryClassName || ""} ${
              expandIconPosition === "left" ? classes.expandIconLeft : ""
            }`}
          >
            {summaryChildren}
            <Box className={titleClassName || ""}>{title}</Box>

            <Box className={classes.settingsAndPriority}>
              {showPriorityIcon && (
                <Box className={priorityIconClassName || ""}>
                  {priorityIcon}
                </Box>
              )}
              {showSettingsIcon && (
                <Box className={settingsIconClassName || ""}>
                  {settingsIcon}
                </Box>
              )}
            </Box>
          </AccordionSummary>
        </Box>

        {showAccordionDetails && (
          <AccordionDetails className={accordionDetailsClassName || ""}>
            {hasDivider && <Divider />}
            <Box className={classes.details}>{children}</Box>
          </AccordionDetails>
        )}
      </Accordion>

      {description && (
        <CoreTooltip title={description}>
          <InfoOutlinedIcon className={classes.icon} />
        </CoreTooltip>
      )}

      {warning && (
        <CoreTooltip title={warning}>
          <WarningIcon color="warning" className={classes.icon} />
        </CoreTooltip>
      )}
    </Box>
  );
};

export default CoreAccordion;
