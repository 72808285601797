//Library imports
import React, { FC, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

//Design imports
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

//Internal imports
import CoreInput from "../../../../../core/CoreInput";
import { User, FlowInterface } from "../../../../../../types/interfaces";
import { useStores } from "../../../../../../stores/StoresProvider";
import UserSetup from "./UserSetup";
import CoreButton from "../../../../../core/CoreButton";
import { useNotification } from "../../../../../../context/useNotification";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  titleContainer: {
    display: "flex",
    marginBottom: "10px",
  },
  loadingContainer: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
    alignItems: "center",
    marginTop: "20px",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
  input: {
    width: "100%",
  },
  confirmButton: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    padding: "15px 30px",
  },
  cardListStyle: {
    maxHeight: "665px",
    overflowY: "auto",
    marginTop: "10px",
  },
});

interface Props {
  onClose: () => void;
  t: TFunction;
}

const RestrictDrawer: FC<Props> = observer(({ onClose, t }) => {
  const notification = useNotification();
  const { userStore, flowSettingsStore } = useStores();
  const [userFilter, setUserFilter] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const classes = useStyles();

  const usersWithoutRestrictions = useMemo(() => {
    return userStore.users.filter(
      (user) => flowSettingsStore.flow?.access?.[user.id] !== "restricted"
    );
  }, [userStore.users, flowSettingsStore.flow?.access]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserFilter(event.target.value);
  };

  const filteredUsers = useMemo(
    () =>
      usersWithoutRestrictions.filter((user) => {
        if (user.scope === "owner" || user.scope === "Owner") return;
        if (user.id === userStore.user?.id) return;
        if (userFilter) {
          const filteredByEmail = user.email.toLowerCase();
          const filteredByFirstName = user.first_name.toLowerCase();
          const filteredByLastName = user.last_name.toLowerCase();

          if (
            filteredByEmail.includes(userFilter.toLowerCase()) ||
            filteredByFirstName.includes(userFilter.toLowerCase()) ||
            filteredByLastName.includes(userFilter.toLowerCase())
          ) {
            return user;
          }
          return;
        }
        return user;
      }),
    [userFilter, userStore.user?.id, usersWithoutRestrictions]
  );

  const handleClick = (user: User) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleConfirm = () => {
    const updatedAccess = { ...flowSettingsStore.flow?.access };

    selectedUsers.forEach((user) => {
      updatedAccess[user.id] = "restricted";
    });

    flowSettingsStore
      .updateFlow({
        access: updatedAccess,
      } as FlowInterface)
      .then(() => {
        onClose();
        notification.success(t("updateFlowSuccess"));
        flowSettingsStore.triggerRefetchFlowSettings(true);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "updateFlowError"));
      });
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography>{t("restrict_subtitle")}</Typography>
        </Box>

        <CoreInput
          value={userFilter}
          onChange={handleInput}
          placeholder={t("searchInput_users")}
          startAdornment={<SearchIcon className={classes.icon} />}
          fullWidth
          size="small"
          className={classes.input}
        />

        {!userStore.loadingUsers && filteredUsers?.length === 0 && (
          <Box className={classes.loadingContainer}>
            <Typography>{t("noUsers")}</Typography>
          </Box>
        )}

        <Box className={classes.cardListStyle}>
          {!userStore.loadingUsers ? (
            filteredUsers.map((user, index) => {
              return (
                <UserSetup
                  key={index}
                  user={user}
                  users={selectedUsers}
                  handleClick={handleClick}
                  isLoading={false}
                />
              );
            })
          ) : (
            <Box className={classes.loadingContainer}>
              <CircularProgress size="30px" color="inherit" />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.confirmButton}>
        <CoreButton
          variant="contained"
          onClick={handleConfirm}
          disabled={
            filteredUsers?.length === 0 ||
            flowSettingsStore.loadingUpdateFlow ||
            selectedUsers?.length === 0
          }
          isLoading={flowSettingsStore.loadingUpdateFlow}
        >
          {t("confirm")}
        </CoreButton>
      </Box>
    </>
  );
});

export default RestrictDrawer;
