import React, { FC, useMemo, useState, useEffect } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import _isEqual from "lodash/isEqual";
import _ from "lodash";

import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { DateRange } from "@mui/lab/DateRangePicker";
import { Typography, useTheme } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import CoreTabs from "../../../core/CoreTabs";
import SearchFilter from "./SearchFilter";
import DatePicker from "../../../core/DatePicker";
import CoreTooltip from "../../../core/CoreTooltip";
import {
  FILTER_PROPS,
  FILTER_STATUS,
  FILTER_TYPE_KEYS,
  THEME_MODES,
} from "../../../../types/constants";
import { FlowTypes } from "../../../../types/enums";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import {
  AdvancedFilter,
  DocumentsFilterProps,
  FlowCategory,
  FlowField,
  FlowFilterKeys,
  SelectOptionDef,
} from "../../../../types/interfaces";
import CoreSelect from "../../../core/CoreSelect";
import { AdvancedFiltersDrawer } from "./AdvancedFiltersDrawer";

interface Props {
  t: TFunction;
  filters: DocumentsFilterProps;
  setFilters: (key: string, value: string | string[] | unknown) => void;
  resetFilters: (filters: DocumentsFilterProps) => void;
  focusedFilterMetadata?: DocumentsFilterProps;
  page: number;
  id: string;
  defaultFilter: DocumentsFilterProps;
  flowFieldOptions: FlowField[] | FlowCategory[];
}

const TABS_OPTIONS = [
  {
    key: FILTER_TYPE_KEYS.filter_all,
    label: FILTER_TYPE_KEYS.filter_all,
    icon: VisibilityIcon,
    tooltipText: "view_allDocuments",
  },
  {
    key: FILTER_TYPE_KEYS.filter_waiting,
    label: FILTER_TYPE_KEYS.filter_waiting,
    icon: HourglassEmptyIcon,
    tooltipText: "view_waitingDocuments",
  },
  {
    key: FILTER_TYPE_KEYS.filter_review,
    label: FILTER_TYPE_KEYS.filter_review,
    icon: VisibilityIcon,
    tooltipText: "view_reviewDocuments",
  },
  {
    key: FILTER_TYPE_KEYS.filter_error,
    label: FILTER_TYPE_KEYS.filter_error,
    icon: WarningIcon,
    tooltipText: "view_errorDocuments",
  },
  {
    key: FILTER_TYPE_KEYS.filter_rejected,
    label: FILTER_TYPE_KEYS.filter_rejected,
    icon: EventBusyIcon,
    tooltipText: "view_rejectedDocuments",
  },
  {
    key: FILTER_TYPE_KEYS.filter_completed,
    label: FILTER_TYPE_KEYS.filter_completed,
    icon: DoneIcon,
    tooltipText: "view_completedDocuments",
  },
];

const FilterBar: FC<Props> = observer(
  ({
    t,
    filters,
    setFilters,
    resetFilters,
    id,
    defaultFilter,
    flowFieldOptions,
  }) => {
    const { flowStore } = useStores();
    const { mainStore } = useStores();
    const { currentTheme } = mainStore;
    const theme = useTheme();
    const notification = useNotification();

    const [index, setIndex] = useState(0);
    const [isFlowsOpen, setIsFlowsOpen] = useState(false);
    const [isAdvancedDrawerOpened, toggleAdvancedDrawer] = useState(false);
    const [anchor, setAnchor] = useState<(EventTarget & HTMLElement) | null>(
      null
    );
    const [flowFilterKeys, setFlowFilterKeys] = useState<FlowFilterKeys>();
    const [isDataLoading, setIsDataLoading] = useState(false);

    const useStyles = makeStyles({
      searchAndDateContainer: {
        margin: "15px 0 0 15px",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        "@media only screen and (max-width: 1160px)": {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
      tabsContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: "row",
        gap: "1rem",
        marginLeft: "10px",
        alignItems: "center",
        "@media only screen and (max-width: 1100px)": {
          flexDirection: "column",
          alignItems: "flex-start",
        },
        "& .MuiTabs-root": {
          overflow: "visible",
          "@media only screen and (max-width: 970px)": {
            maxWidth: 480,
          },
          "@media only screen and (max-width: 830px)": {
            maxWidth: 410,
          },
        },
      },
      boxDate: {
        display: "flex",
        "@media screen and (min-width:1161px)": {
          margin: "0 15px",
        },
        cursor: "pointer",
        "&:hover": {
          color:
            currentTheme === THEME_MODES.light
              ? theme.palette.neutral.dark
              : theme.palette.text.secondary,
        },
      },
      textDate: {
        margin: "auto 16px auto 0",
        color: "inherit",
      },
      select: {
        height: "35px",
        marginLeft: "5px",
        width: "250px",
        "@media only screen and (max-width: 1160px)": {
          width: "294px !important",
          marginTop: 13,
        },
      },
      formControl: {
        width: "250px !important",
        "@media only screen and (max-width: 1160px)": {
          alignSelf: "flex-start",
        },
        "@media screen and (min-width:1161px)": {
          marginLeft: "15px",
        },
      },
      paragraph: {
        margin: 0,
        padding: 0,
      },
      clearButton: {
        "@media screen and (min-width:1161px)": {
          marginLeft: "-15px",
        },
      },
      optionStyle: {
        whiteSpace: "normal",
        wordBreak: "normal",
        hyphens: "auto",
      },
    });

    const classes = useStyles();

    const flowType = useMemo(() => {
      const flowTypes = _.uniq(flowStore.flows.map((flow) => flow.flowType));
      const allFlowsType = flowTypes.length === 1 ? flowTypes[0] : "";
      return flowStore?.flowSummary?.flowType ?? allFlowsType ?? "";
    }, [flowStore?.flowSummary?.flowType, flowStore.flows]);

    const formattedAllFlowsFilterOptions = useMemo(() => {
      let result: (SelectOptionDef | string)[] = [];

      result = [
        ...result,
        ...(flowFieldOptions?.map((field) => ({
          key: field.key,
          value: field.key,
          label: field.name,
          type: field.type,
        })) as SelectOptionDef[]),
      ];

      return result;
    }, [flowFieldOptions]);

    const formattedFilterOptions = useMemo(() => {
      let result: (SelectOptionDef | string)[] = [];
      Object.keys(flowFilterKeys || {})?.map((filterKey) => {
        if (
          Object.values(
            (flowFilterKeys as unknown as FlowFilterKeys)[
              filterKey as "fields" | "metadata" | "categories"
            ]
          ).length > 0
        ) {
          result = [
            ...result,
            {
              key: filterKey,
              value: filterKey.charAt(0).toUpperCase() + filterKey.slice(1),
              label: filterKey.charAt(0).toUpperCase() + filterKey.slice(1),
              type: filterKey,
              isDisabled: true,
            },
            ...(flowFilterKeys?.[
              filterKey as "fields" | "metadata" | "categories"
            ]
              ?.filter((field) =>
                filterKey === "fields" ? field.source === "flowConfig" : field
              )
              ?.map((field) => {
                return {
                  key: field.key,
                  value: field.name || field.key,
                  label: field.name || field.key,
                  type: filterKey,
                  source: field.source,
                };
              }) as unknown as string[]),
          ];
        }
      });

      const computedFields =
        flowFilterKeys?.fields?.filter(
          (field) => field.source === "enhancement"
        ) || [];

      if (computedFields.length > 0)
        result = [
          ...result,
          {
            key: t("computedFields"),
            value: t("computedFields"),
            label: t("computedFields"),
            type: "fields",
            isDisabled: true,
          },
          ...(computedFields?.map((field) => {
            return {
              key: field.key,
              value: field.name || field.key,
              label: field.name || field.key,
              type: "fields",
              source: field.source,
            };
          }) as unknown as string[]),
        ];

      return result;
    }, [flowFilterKeys, t]);

    const flowOptions = useMemo(
      () =>
        flowStore.flows.map((flow) => ({
          key: flow.identifier,
          label: flow.name,
        })),
      [flowStore.flows]
    );

    const formatFilter = (filter: DocumentsFilterProps) => {
      const pairs = Object.entries(filter).filter(
        ([key]) => key !== "sortBy" && key !== "sortDirection"
      );
      const result = Object.fromEntries(pairs);

      return result;
    };

    const newFilters = formatFilter(filters);
    const newDefaultFilter = formatFilter(defaultFilter);

    const clearButtonVisible = useMemo(
      () => !_isEqual(newFilters, newDefaultFilter),
      [newDefaultFilter, newFilters]
    );

    useEffect(() => {
      const statusIndex = TABS_OPTIONS.findIndex((status) =>
        _isEqual(FILTER_STATUS[status.key], filters.status)
      );

      if (statusIndex > 0) setIndex(statusIndex);
      else setIndex(0);
    }, [filters.status]);

    const toggleDatePicker = (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchor(event.currentTarget);
    };

    const updateDate = (value: DateRange<Date>) => {
      setFilters(FILTER_PROPS.date, { startDate: value[0], endDate: value[1] });
    };

    const handleChangeTabs = (
      event: React.SyntheticEvent,
      newValue: number
    ) => {
      setIndex(newValue);
      setFilters(
        FILTER_PROPS.status,
        FILTER_STATUS[TABS_OPTIONS[newValue].key]
      );
    };

    const handleClear = () => {
      resetFilters(defaultFilter);
      setIndex(0);
    };

    const handleAddFilterMetadata = (newFilter: DocumentsFilterProps) => {
      const type = newFilter.type;

      let filterKey;
      let filterType;
      let formattedFilter: AdvancedFilter = {};
      let finalFilters;

      const fieldsList =
        (newFilter.type === "fields" ? filters.fields : filters.metadata) || [];

      const categoriesList =
        (newFilter.type === "categories"
          ? filters.categories
          : filters.metadata) || [];

      const formattedFieldsFilter = fieldsList?.some(
        (filter) =>
          filter.field === newFilter.field &&
          filter.operator === newFilter.operator
      )
        ? fieldsList?.map((item) =>
            item.field === newFilter.field &&
            item.operator === newFilter.operator
              ? {
                  ...item,
                  values: [
                    ...(item.values as string[]),
                    ...(newFilter.values ?? []),
                  ],
                }
              : null
          )
        : {
            field: newFilter.field,
            operator: newFilter.operator,
            values: newFilter.values,
            type: newFilter.type,
            source: newFilter.source,
          };

      const formattedCategoriesFilter = categoriesList?.some(
        (filter) =>
          filter.category === newFilter.category &&
          filter.operator === newFilter.operator
      )
        ? categoriesList?.map((item) =>
            item.category === newFilter.category &&
            item.operator === newFilter.operator
              ? {
                  ...item,
                  values: [
                    ...(item.values as string[]),
                    ...(newFilter.values ?? []),
                  ],
                }
              : null
          )
        : {
            category: newFilter.category,
            operator: newFilter.operator,
            values: newFilter.values,
            type: newFilter.type,
          };

      const fieldsFilter = (Array.isArray(formattedFieldsFilter)
        ? formattedFieldsFilter.filter((item) => item !== null)[0]
        : formattedFieldsFilter) as unknown as AdvancedFilter;

      const categoriesFilter = (Array.isArray(formattedCategoriesFilter)
        ? formattedCategoriesFilter.filter((item) => item !== null)[0]
        : formattedCategoriesFilter) as unknown as AdvancedFilter;

      switch (type) {
        case "fields":
          filterKey = FILTER_PROPS.fields;
          filterType = filters?.fields ?? [];
          formattedFilter = fieldsFilter;

          finalFilters = filterType?.some(
            (filter) =>
              filter.field === formattedFilter.field &&
              filter.operator === formattedFilter.operator
          )
            ? filterType.map((filter) =>
                filter.field === formattedFilter.field &&
                filter.operator === formattedFilter.operator
                  ? {
                      ...filter,
                      values: formattedFilter.values,
                    }
                  : filter
              )
            : [...(filterType || []), formattedFilter];

          break;

        case "categories":
          filterKey = FILTER_PROPS.categories;
          filterType = filters?.categories ?? [];
          formattedFilter = categoriesFilter;

          finalFilters = filterType?.some(
            (filter) =>
              filter.category === formattedFilter.category &&
              filter.operator === formattedFilter.operator
          )
            ? filterType.map((filter) =>
                filter.category === formattedFilter.category &&
                filter.operator === formattedFilter.operator
                  ? {
                      ...filter,
                      values: formattedFilter.values,
                    }
                  : filter
              )
            : [...(filterType || []), formattedFilter];

          break;

        case "metadata":
          filterKey = FILTER_PROPS.metadata;
          filterType = filters?.metadata ?? [];
          formattedFilter =
            flowType === FlowTypes.classification
              ? categoriesFilter
              : fieldsFilter;

          finalFilters = filterType?.some(
            (filter) =>
              filter.field === formattedFilter.field &&
              filter.operator === formattedFilter.operator
          )
            ? filterType.map((filter) =>
                filter.field === formattedFilter.field &&
                filter.operator === formattedFilter.operator
                  ? {
                      ...filter,
                      values: formattedFilter.values,
                    }
                  : filter
              )
            : [...(filterType || []), formattedFilter];

          break;

        default:
          break;
      }

      setFilters(filterKey as string, finalFilters);

      toggleAdvancedDrawer(false);
    };

    const handleRenderSelectValue = (selected: unknown): React.ReactNode => {
      const allFlows = selected as string[];

      if (allFlows.length === 0) {
        return (
          <Typography className={classes.paragraph}>
            {t("flowsPlaceholder")}
          </Typography>
        );
      }

      return `${allFlows?.length} ${t("flowSelected")}`;
    };

    const handleClick = () => {
      toggleAdvancedDrawer(true);
      if (id) {
        setIsDataLoading(true);
        flowStore
          .loadFlowFilterKeys(id)
          .then((response) => {
            const fields = response.fields.map((res) => ({
              key: res.key,
              name: res.name,
              type: res.type,
              source: flowStore?.flowSummary?.fields?.find(
                (field) => field.key === res.key
              )?.source,
            }));
            setFlowFilterKeys({
              fields: fields,
              metadata: response.metadata,
              categories: response.categories,
            });
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "errorFilterKeys"));
          })
          .finally(() => setIsDataLoading(false));
      }
    };

    return (
      <>
        <Box className={classes.tabsContainer}>
          <CoreTabs
            value={index}
            onChange={handleChangeTabs}
            options={TABS_OPTIONS}
            t={t}
            showTooltip={true}
            scrollButtons={true}
            variant="scrollable"
          />
        </Box>

        <DatePicker
          t={t}
          anchorEl={anchor}
          onClose={() => setAnchor(null)}
          onUpdate={updateDate}
        />

        <Box className={classes.searchAndDateContainer}>
          <SearchFilter
            name={filters?.name || []}
            setFilters={(value) => setFilters(FILTER_PROPS.name, value)}
            toggleDrawer={handleClick}
          />

          {!id && (
            <CoreSelect
              isMultipleSelect
              displayEmpty
              hasCheckbox
              selectInputStyle={classes.select}
              formControlClass={classes.formControl}
              open={isFlowsOpen}
              onClose={() => setIsFlowsOpen(false)}
              onOpen={() => setIsFlowsOpen(true)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "30%",
                    maxWidth: "10%",
                    overflowX: "hidden",
                  },
                },
              }}
              options={flowOptions}
              value={filters.flows ?? []}
              onChange={(event) => {
                setFilters(FILTER_PROPS.flows, event.target.value as string[]);
              }}
              renderValue={handleRenderSelectValue}
              optionStyle={classes.optionStyle}
              disableCheckBoxRipple={true}
            />
          )}

          <Box className={classes.boxDate} onClick={toggleDatePicker}>
            <IconButton style={{ color: "inherit" }}>
              <DateRangeIcon />
            </IconButton>
            <Typography className={classes.textDate}>
              {t("dateFilter")}
            </Typography>
          </Box>

          {clearButtonVisible && (
            <CoreTooltip title={t("remove_filters")}>
              <IconButton onClick={handleClear} className={classes.clearButton}>
                <RemoveCircleOutlinedIcon />
              </IconButton>
            </CoreTooltip>
          )}
        </Box>

        <AdvancedFiltersDrawer
          translation={t}
          isOpened={isAdvancedDrawerOpened}
          fieldOptions={
            flowStore.flowSummary?.identifier
              ? (formattedFilterOptions as unknown as SelectOptionDef[])
              : (formattedAllFlowsFilterOptions as unknown as SelectOptionDef[])
          }
          onClose={() => {
            toggleAdvancedDrawer(false);
            setFlowFilterKeys(undefined);
          }}
          onConfirm={handleAddFilterMetadata}
          showAddFilterButton={true}
          flowType={flowType}
          isLoading={isDataLoading}
          showManuallyUpdatedFilter
          manuallyUpdated={flowStore.filters?.flowFilters?.manuallyUpdated}
          updateManuallyUpdatedFilter={(value: boolean | undefined) =>
            setFilters(FILTER_PROPS.manuallyUpdated, value)
          }
        />
      </>
    );
  }
);

export default FilterBar;
