import React, { useState, useEffect } from "react";
import { TFunction } from "i18next";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ExtendedFile } from "../../../../types/interfaces";
import CoreTooltip from "../../../core/CoreTooltip";
import { DOCUMENT_UPLOAD_STATUS } from "../../../../types/constants";

interface FileItemProps {
  t: TFunction;
  file: ExtendedFile;
  isDisabled: boolean;
  index: number;
  removeItem: (index: number) => void;
}

interface DetailProps {
  status: DOCUMENT_UPLOAD_STATUS;
  index: number;
}

const FileItem: React.FC<FileItemProps> = ({
  t,
  file,
  isDisabled,
  index,
  removeItem,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    buttons: {
      justifyContent: "center",
      display: "flex",
      minWidth: "80px",
    },
    cell: {
      padding: "5px 0",
    },
    uploadIcon: {
      paddingLeft: "12px",
    },
    loadingColor: {
      color: theme.palette.highlight.main,
    },
  });

  const [fileStatus, setFileStatus] = useState<DOCUMENT_UPLOAD_STATUS>(
    DOCUMENT_UPLOAD_STATUS.draft
  );

  const classes = useStyles();

  useEffect(() => {
    const uploadListener = (e: CustomEvent) => {
      setFileStatus((e.detail as DetailProps).status);
    };

    window.addEventListener(`upload-${index}`, uploadListener as EventListener);

    return () => {
      window.removeEventListener(
        `upload-status`,
        uploadListener as EventListener
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Box className={classes.uploadIcon}>
          {fileStatus === DOCUMENT_UPLOAD_STATUS.draft && <FileUploadIcon />}
          {fileStatus === DOCUMENT_UPLOAD_STATUS.upload_running && (
            <CircularProgress size={24} className={classes.loadingColor} />
          )}
          {fileStatus === DOCUMENT_UPLOAD_STATUS.upload_success && (
            <CheckCircleOutlineOutlinedIcon />
          )}
          {fileStatus === DOCUMENT_UPLOAD_STATUS.upload_failed && <ErrorIcon />}
        </Box>
      </TableCell>
      <TableCell className={classes.cell}>{file.name}</TableCell>
      <TableCell className={classes.cell}>
        <Box className={classes.buttons}>
          <CoreTooltip title={t("viewDocument")}>
            <div>
              <IconButton onClick={() => window.open(file.preview)}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </div>
          </CoreTooltip>

          <CoreTooltip title={t("deleteDocument")}>
            <div>
              {fileStatus !== DOCUMENT_UPLOAD_STATUS.upload_running && (
                <IconButton
                  onClick={() => removeItem(index)}
                  disabled={isDisabled}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              )}
            </div>
          </CoreTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default FileItem;
