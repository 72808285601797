import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import { Box } from "@mui/material";

import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { AppSuspense } from "../../../main/AppSuspense";
import CorePageContainer from "../../../core/CorePageContainer";
import {
  FLOW_DATA_TYPES_BACKEND,
  FLOW_DATA_TYPES_FRONTEND,
} from "../../../../types/constants";
import { DataCategoryContent } from "./DataCategoryContent";
import NotFound from "../../errorPages/NotFound";
import AddDataContentAction from "./AddDataContentAction";

const useStyles = makeStyles({
  addDataButton: {
    display: "flex",
    alignItems: "center",
    marginRight: "4px",
    margin: "3px",
    marginLeft: "auto",
  },
  mainContainer: {
    width: "100%",
    padding: "0 10px",
  },
});

const FlowDataCategories: FC = () => {
  const { t } = useTranslation("flow");
  const { flowStore } = useStores();
  const notification = useNotification();
  const { id, type } = useParams<{ id: string; type: string }>();

  const [flowIdInvalid, setFlowIdInvalid] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const classes = useStyles();

  const typeInvalid =
    type !== FLOW_DATA_TYPES_FRONTEND.dataSources &&
    type !== FLOW_DATA_TYPES_FRONTEND.dataExports;

  useEffect(() => {
    setIsPageLoading(true);

    flowStore
      .getFlowSummary(id)
      .then((getFlowSummary) => {
        flowStore.setFlowSummary(getFlowSummary);
      })
      .catch((error: Error) => {
        setFlowIdInvalid(true);
        notification.error(t(error?.message || "flowNotFound"));
      })
      .finally(() => setIsPageLoading(false));

    return () => {
      flowStore.setFlowSummary(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isPageLoading) {
    return <AppSuspense />;
  }

  if (flowIdInvalid) {
    return (
      <NotFound
        title={t("flowNotFoundTitle")}
        subtitle={t("flowNotFoundSubtitle")}
      />
    );
  }

  if (typeInvalid) {
    return (
      <NotFound
        title={t("typeNotFoundTitle")}
        subtitle={t("typeNotFoundSubtitle")}
      />
    );
  }

  return (
    <CorePageContainer
      label={`${(flowStore.flowSummary?.name as string) || ""} - ${t(type)}`}
      isPageLoading={!!id && !!type && flowStore.isFlowSummaryLoading}
      subtitle={flowStore.flowSummary?.description || ""}
      extraHeaderContent={
        <AddDataContentAction type={type} setRefreshData={setRefreshData} />
      }
      headerContentClass={classes.addDataButton}
    >
      <Box className={classes.mainContainer}>
        <DataCategoryContent
          t={t}
          flowId={id}
          type={FLOW_DATA_TYPES_BACKEND[type] || ""}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      </Box>
    </CorePageContainer>
  );
};

export default FlowDataCategories;
