import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

import { useStores } from "../../../../stores/StoresProvider";

import CoreInput from "../../../core/CoreInput";
import {
  FIELD_MAX_LENGTH,
  User,
  FlowInterface,
} from "../../../../types/interfaces";
import UserSetup from "../../addNewFlow/tabs/access/drawer/UserSetup";
import { useNotification } from "../../../../context/useNotification";
import BoxInfoContent from "../flow-settings/BoxInfoContent";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  loadingContainer: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
    alignItems: "center",
    marginTop: "20px",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
  input: {
    width: "100%",
  },
  cardListStyle: {
    maxHeight: "665px",
    overflowY: "auto",
    marginTop: "10px",
    paddingTop: "20px",
  },
  searchBox: {
    paddingTop: "20px",
  },
});

interface Props {
  flowConfig: FlowInterface | null;
  selectedUsers: User[];
  setSelectedUsers: (users: User[]) => void;
  flowName: string;
  setFlowName: (name: string) => void;
}

const ImportFlowCustomConfiguration: React.FC<Props> = observer(
  ({ flowConfig, selectedUsers, setSelectedUsers, flowName, setFlowName }) => {
    const { userStore, flowSettingsStore } = useStores();
    const notification = useNotification();
    const { t } = useTranslation("addFlow");
    const [userFilter, setUserFilter] = useState("");
    const [loadingCheckName, setLoadingCheckName] = useState(false);
    const [nameError, setNameError] = useState("");
    const timer = 1000;

    const classes = useStyles();

    const users = useMemo(() => userStore.users, [userStore.users]);

    const handleChangeFlowName = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFlowName(event.target.value);
    };

    const filteredUsers = useMemo(
      () =>
        users.filter((user) => {
          if (user.scope === "owner" || user.scope === "Owner") return;
          if (user.id === userStore.user?.id) return;
          if (userFilter) {
            const filteredByEmail = user.email.toLowerCase();
            const filteredByFirstName = user.first_name.toLowerCase();
            const filteredByLastName = user.last_name.toLowerCase();

            if (
              filteredByEmail.includes(userFilter.toLowerCase()) ||
              filteredByFirstName.includes(userFilter.toLowerCase()) ||
              filteredByLastName.includes(userFilter.toLowerCase())
            ) {
              return user;
            }
            return;
          }
          return user;
        }),
      [userFilter, userStore.user?.id, users]
    );

    const handleClick = (user: User) => {
      if (selectedUsers.includes(user)) {
        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    };

    const handleRestrictInput = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setUserFilter(event.target.value);
    };

    useEffect(() => {
      userStore
        .getAllUsers()
        .catch((error: Error) =>
          notification.error(t(error?.message ?? "get_users_error"))
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!flowConfig) return;
      setLoadingCheckName(true);

      const delay = setTimeout(() => {
        if (flowName) {
          checkName(flowName?.trim() || "");
        } else if (flowName?.length < 3) {
          setLoadingCheckName(false);
          setNameError("createFlow_nameTooShort");
        } else {
          setLoadingCheckName(false);
          setNameError("");
        }
      }, timer);

      return () => {
        clearTimeout(delay);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowName, flowConfig]);

    const checkName = (name: string) => {
      if (name.length < 3) {
        setNameError("createFlow_nameTooShort");
        setLoadingCheckName(false);
        return;
      }

      setLoadingCheckName(true);
      setNameError("");
      flowSettingsStore
        .checkFlowName(name)
        .then((res) => {
          if (!res) {
            setNameError("createFlow_nameNotAvailable");
          }
        })
        .catch((error: Error) => {
          setNameError(error?.message || "createFlow_nameNotAvailable");
        })
        .finally(() => {
          setLoadingCheckName(false);
        });
    };

    useEffect(() => {
      if (flowConfig) {
        if (flowConfig?.name) {
          setFlowName(flowConfig?.name);
        }

        if (flowConfig?.access) {
          const accessKeys = Object.keys(flowConfig?.access ?? {});

          setSelectedUsers(
            userStore.users?.filter((user) =>
              accessKeys?.includes(user?.id?.toString())
            ) || []
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowConfig]);

    return (
      <Box className={classes.container}>
        <CoreInput
          fullWidth
          title={t("flowNameTitle")}
          placeholder={t("createFlow_namePlaceholder")}
          value={flowName || ""}
          name="name"
          onChange={handleChangeFlowName}
          isMandatory
          description={t("createFlow_nameDescription")}
          inputProps={{
            maxLength: FIELD_MAX_LENGTH.NAME,
          }}
        />

        <BoxInfoContent
          t={t}
          flowName={flowName}
          loadingCheckName={loadingCheckName}
          nameError={nameError}
        />

        <Box className={classes.searchBox}>
          <CoreInput
            value={userFilter}
            onChange={handleRestrictInput}
            title={t("restrictUsersDescription")}
            placeholder={t("searchInput_users")}
            startAdornment={<SearchIcon className={classes.icon} />}
            fullWidth
            size="small"
            className={classes.input}
          />
        </Box>

        {!userStore.loadingUsers && filteredUsers?.length === 0 && (
          <Box className={classes.loadingContainer}>
            <Typography>{t("noUsers")}</Typography>
          </Box>
        )}

        <Box className={classes.cardListStyle}>
          {!userStore.loadingUsers ? (
            filteredUsers.map((user, index) => {
              return (
                <UserSetup
                  key={index}
                  user={user}
                  users={selectedUsers}
                  handleClick={handleClick}
                  isLoading={false} // check this
                />
              );
            })
          ) : (
            <Box className={classes.loadingContainer}>
              <CircularProgress size="30px" color="inherit" />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default ImportFlowCustomConfiguration;
