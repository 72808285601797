import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// Internal imports
import DocumentValidate from "../pages/documents/DocumentValidate";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import SuccessfullyRegister from "../pages/register/SuccessfullyRegister";
import ForgotPassword from "../pages/login/ForgotPassword";
import ActivatingAccount from "../pages/activation/ActivatingAccount";
import NotFound from "../pages/errorPages/NotFound";
import Home from "../pages/home/Home";
import Documents from "../pages/allDocuments/Documents";
import SignUpJourney from "../pages/steps/SignupJourney";
import SettingsPage from "../pages/settingsPage/SettingsPage";
import FlowsList from "../pages/flows/FlowsList";
import Namespaces from "../pages/namespaces/Namespaces";
import ActivityLogs from "../pages/activityLogs/ActivityLogs";
import RetryNextDocument from "../pages/documents/RetryNextDocuments";
import InvitationLandingPage from "../pages/invitation/InvitationLandingPage";
import ResetPassword from "../pages/login/ResetPassword";
import AccessDenied from "../pages/errorPages/AccessDenied";
import ResendActivation from "../pages/login/ResendActivation";
import HomeChatGpt from "../pages/flow/chat-gpt/HomeChatGpt";
import FlowSettingsPage from "../pages/flow/FlowSettingsPage";

// Configs
import { appRoutes, authRoutes } from "../../configs/routes";
import { ROUTE_PAGES } from "../../configs/resources";
import { HistoryProvider } from "../../context/useHistoryContext";
import { AuthTokenResponse } from "../../types/interfaces";
import { useStores } from "../../stores/StoresProvider";
import { UserStore } from "../../stores/UserStore";

interface Props {
  authProps: AuthTokenResponse;
  isLoading: boolean;
}

interface RouteProps {
  route: string;
  exact: boolean;
  abilityKey?: string;
}

const PAGES = {
  [ROUTE_PAGES.document_view]: DocumentValidate,
  [ROUTE_PAGES.login]: Login,
  [ROUTE_PAGES.register]: Register,
  [ROUTE_PAGES.successfully_register]: SuccessfullyRegister,
  [ROUTE_PAGES.forgot_password]: ForgotPassword,
  [ROUTE_PAGES.activating_account]: ActivatingAccount,
  [ROUTE_PAGES.home]: Home,
  [ROUTE_PAGES.documents]: Documents,
  [ROUTE_PAGES.contextEvents]: ActivityLogs,
  [ROUTE_PAGES.signup_journey]: SignUpJourney,
  [ROUTE_PAGES.settings]: SettingsPage,
  [ROUTE_PAGES.flows]: FlowsList,
  [ROUTE_PAGES.retry_next_document]: RetryNextDocument,
  [ROUTE_PAGES.invitation]: InvitationLandingPage,
  [ROUTE_PAGES.namespaces]: Namespaces,
  // FIXME: Keep for later use
  // [ROUTE_PAGES.statistics]: Statistics,
  // [ROUTE_PAGES.qaDocuments]: QAMainPage,
  // [ROUTE_PAGES.qa]: QAMainPage,
  // [ROUTE_PAGES.qa_document_view]: DocumentValidate,
  [ROUTE_PAGES.reset_password]: ResetPassword,
  [ROUTE_PAGES.resend_activation]: ResendActivation,
  [ROUTE_PAGES.chatGpt]: HomeChatGpt,
  [ROUTE_PAGES.flowSettings]: FlowSettingsPage,
  [ROUTE_PAGES.flow_data_categories]: FlowsList,
  [ROUTE_PAGES.documents]: FlowsList,
};

const MainPageRoutes = {
  [ROUTE_PAGES.home]: {
    route: appRoutes.Home(),
  },
  [ROUTE_PAGES.chatGpt]: {
    route: appRoutes.ChatGpt(),
  },
  [ROUTE_PAGES.document_view]: {
    route: appRoutes.DocumentView(),
    abilityKey: "flows",
  },
  [ROUTE_PAGES.documents]: {
    route: appRoutes.ListDocuments(),
    abilityKey: "flows",
    exact: true,
  },

  // FIXME: Keep for later use
  // [ROUTE_PAGES.qa_document_view]: {
  //   route: appRoutes.QaDocumentView(),
  //   abilityKey: "qa",
  // },
  // [ROUTE_PAGES.qa]: { route: appRoutes.QA(), abilityKey: "qa" },
  // [ROUTE_PAGES.qaDocuments]: {
  //   route: appRoutes.QaDocuments(),
  //   abilityKey: "qa",
  // },
  // [ROUTE_PAGES.statistics]: {
  //   route: appRoutes.Statistics(),
  //   exact: false,
  //   abilityKey: "statistics",
  // },
  [ROUTE_PAGES.flow_data_categories]: {
    route: appRoutes.FlowDataCategories(),
    abilityKey: "flows",
    exact: true,
  },
  [ROUTE_PAGES.settings]: {
    route: appRoutes.Settings(),
    exact: false,
  },
  [ROUTE_PAGES.contextEvents]: {
    route: appRoutes.ContextEvents(),
    exact: false,
    abilityKey: "activityLogs",
  },
  [ROUTE_PAGES.flows]: {
    route: appRoutes.Flows(),
    exact: true,
    abilityKey: "flows",
  },
  [ROUTE_PAGES.flowSettings]: { route: appRoutes.FlowSettings() },
  [ROUTE_PAGES.retry_next_document]: {
    route: appRoutes.RetryNextDocument(),
    abilityKey: "flows",
  },
  [ROUTE_PAGES.invitation]: { route: authRoutes.Invitation() },
};

const AuthRoutes = {
  [ROUTE_PAGES.login]: { route: authRoutes.Login() },
  [ROUTE_PAGES.register]: { route: authRoutes.Register() },
  [ROUTE_PAGES.successfully_register]: {
    route: authRoutes.SuccessfullyRegister(),
  },
  [ROUTE_PAGES.forgot_password]: { route: authRoutes.ForgotPassword() },
  [ROUTE_PAGES.reset_password]: { route: authRoutes.ResetPassword() },
  [ROUTE_PAGES.activating_account]: { route: authRoutes.ActivatingAccount() },
  [ROUTE_PAGES.resend_activation]: { route: authRoutes.ResendActivation() },
  [ROUTE_PAGES.signup_journey]: { route: authRoutes.SignUpJourney() },
  [ROUTE_PAGES.invitation]: { route: authRoutes.Invitation() },
};

const NamespaceRoutes = {
  [ROUTE_PAGES.namespaces]: { route: authRoutes.Namespaces() },
  [ROUTE_PAGES.signup_journey]: { route: authRoutes.SignUpJourney() },
  [ROUTE_PAGES.invitation]: { route: authRoutes.Invitation() },
};

const getRoutes = (isUserAuthenticated: boolean, isNamespace: boolean) => {
  if (isUserAuthenticated) return MainPageRoutes;
  if (isNamespace) return NamespaceRoutes;
  return AuthRoutes;
};

const canReadQa = (userStore: UserStore) => {
  return (
    userStore.currentUserPermissions?.can("validate", "qa") ||
    userStore.currentUserPermissions?.can("assign", "qa")
  );
};

const AppRouter: React.FC<Props> = ({
  authProps: { isUserAuthenticated, isNamespace },
  isLoading,
}) => {
  const routes = getRoutes(isUserAuthenticated, isNamespace);
  const { userStore } = useStores();
  const { pathname } = useLocation();

  return (
    <HistoryProvider>
      <Switch>
        {Object.keys(routes).map((routeKey: string) => {
          const {
            route,
            exact = true,
            abilityKey,
          } = routes[routeKey] as Partial<RouteProps>;
          const Page = PAGES[routeKey];

          if (abilityKey) {
            if (abilityKey === "qa") {
              if (!canReadQa(userStore))
                return (
                  <Route key={routeKey} exact={exact} path={route}>
                    <AccessDenied page={route ?? ""} />
                  </Route>
                );
            } else if (
              !userStore.currentUserPermissions?.can("read", abilityKey)
            ) {
              return (
                <Route key={routeKey} exact={exact} path={route}>
                  <AccessDenied page={route ?? ""} />
                </Route>
              );
            }
          }

          if (!Page) {
            return null;
          }

          return (
            <Route key={routeKey} exact={exact} path={route}>
              <Page />
            </Route>
          );
        })}

        {isUserAuthenticated && pathname === authRoutes.Login() && (
          <Redirect to={appRoutes.Home()} />
        )}

        {isUserAuthenticated && !isLoading && (
          <Route>
            <NotFound />
          </Route>
        )}

        {!isUserAuthenticated && !isNamespace && !isLoading && (
          <Redirect to={authRoutes.Login()} />
        )}

        {!isUserAuthenticated && isNamespace && !isLoading && (
          <Redirect to={authRoutes.Namespaces()} />
        )}
      </Switch>
    </HistoryProvider>
  );
};

export default AppRouter;
