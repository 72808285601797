import {
  DocumentLineItem,
  DocumentLineItemCell,
  DocumentLineItemRow,
  DocumentMessageType,
  DocumentZone,
  MESSAGE_LEVELS,
} from "../../types/interfaces";

export default class LineItemsHelper {
  // Format lineItems list before sending it to update
  static lineItemToApiLineItem(lineItems: DocumentLineItem[]) {
    if (lineItems?.length > 0) {
      return lineItems.map((lineItem) => ({
        ...lineItem,
        headers: lineItem.headers?.map((header) => {
          // Remove custom properties which may break update call
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { originalKey, ...headerProps } = header;
          return headerProps;
        }),
        data: lineItem?.data?.map((row) => {
          let formattedRow = { ...row };
          Object.keys(formattedRow?.cells)?.forEach((colKey) => {
            const column = formattedRow?.cells[colKey];
            formattedRow = {
              ...formattedRow,
              cells: {
                ...formattedRow?.cells,
                [colKey]: {
                  ...column,
                  text: column?.text?.toString(),
                  box: {
                    height: column?.box?.height || 0,
                    width: column?.box?.width || 0,
                    x: column?.box?.x || 0,
                    y: column?.box?.y || 0,
                    rotation: column?.box?.rotation || 0,
                  },
                },
              },
            };
          });
          return formattedRow;
        }),
      }));
    }

    return [];
  }

  static isRowIgnored(lineItem: DocumentLineItem, yCoord: number) {
    try {
      const row = lineItem.data?.find((row) => {
        return row.box.y < yCoord && row.box.y + row.box.height > yCoord;
      });

      return row?.isIgnored ?? false;
    } catch {
      return false;
    }
  }

  static ignoreOrEnableRow(
    lineItem: DocumentLineItem,
    yCoord: number
  ): DocumentLineItem {
    try {
      const data = lineItem.data?.map((row) => {
        if (row.box.y < yCoord && row.box.y + row.box.height > yCoord) {
          return { ...row, isIgnored: !row.isIgnored };
        }

        return row;
      });

      return {
        ...lineItem,
        data,
      };
    } catch {
      return lineItem;
    }
  }

  static getRow(lineItem: DocumentLineItem, yCoord: number) {
    try {
      return (
        lineItem.data.find((row) => {
          return row.box.y < yCoord && row.box.y + row.box.height > yCoord;
        }) ?? null
      );
    } catch {
      return null;
    }
  }

  static initEmptyRow(lineItemHeaders: DocumentZone[]): DocumentLineItemRow {
    const emptyRow = {
      box: { height: 0, width: 0, x: 0, y: 0, rotation: 0 },
      points: [],
      cells: {},
      isIgnored: false,
      manuallyAdded: true,
    } as unknown as DocumentLineItemRow;

    let emptyDataCells = {} as Record<string, DocumentLineItemCell>;

    lineItemHeaders?.forEach((header) => {
      emptyDataCells = {
        ...emptyDataCells,
        [header.key as string]: {
          box: {
            height: 0,
            width: 0,
            x: 0,
            y: 0,
            rotation: 0,
          },
          manuallyAdded: true,
          text: "",
          points: [],
        },
      } as unknown as Record<string, DocumentLineItemCell>;
    });

    return {
      ...emptyRow,
      cells: emptyDataCells,
    };
  }

  // This function extracts errors from cell level and adds them to lineItem error scope
  static injectCellErrors(lineItems: DocumentLineItem[]): DocumentLineItem[] {
    if (lineItems?.length > 0) {
      return lineItems.map((lineItem) => {
        let cellErrorDetails = [] as DocumentMessageType[];

        const lineItemData = lineItem.data;

        if (lineItemData?.length > 0) {
          lineItemData.forEach((row, rowIndex) => {
            const rowCells = row.cells || {};

            if (Object.keys(rowCells)?.length > 0) {
              let cellErrors = [] as DocumentMessageType[];

              Object.keys(rowCells).forEach((cell) => {
                const cellItem = rowCells[cell];

                if (cellItem?.details && cellItem?.details?.length > 0) {
                  cellErrors = [
                    ...cellErrors,
                    {
                      level: MESSAGE_LEVELS.subheader,
                      message: cellItem?.name || cell,
                    },
                    ...cellItem.details.map((item) => ({
                      ...item,
                      isSubError: true,
                    })),
                  ];
                }
              });

              if (cellErrors?.length > 0) {
                cellErrorDetails = [
                  ...cellErrorDetails,
                  {
                    level: MESSAGE_LEVELS.header,
                    message: `Row ${rowIndex + 1}`,
                  },
                  ...cellErrors,
                ];
              }
            }
          });
        }

        return {
          ...lineItem,
          details: [...(lineItem.details || []), ...cellErrorDetails],
        };
      });
    }

    return lineItems;
  }
}
