import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";

import { useStores } from "../../../stores/StoresProvider";
import UrlHelper from "../../../helper/urlHelper";
import { AppSuspense } from "../../main/AppSuspense";
import { authRoutes } from "../../../configs/routes";
import { useApolloClient } from "@apollo/client";
import CoreButton from "../../core/CoreButton";
import { useHistory } from "react-router-dom";
import Logo from "../../../icons/Logo";
import Languages from "../../main/sidebar/account/Languages";

const ActivatingAccount: React.FC = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const useStyle = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "0 10px",
    },
    logo: {
      width: "210px",
      height: "30px",
    },
    icon: {
      fontSize: "45px",
      color: theme.palette.highlight.main,
    },
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      marginBottom: "7rem",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
    },
    errorIcon: {
      marginTop: "15px",
      fontSize: "45px",
      color: theme.palette.error.main,
    },
    loadingIcon: {
      marginTop: "15px",
    },
    button: {
      marginTop: "20px",
    },
  });

  const client = useApolloClient();
  const { t, ready } = useTranslation("activating-account");
  const { userStore } = useStores();
  const classes = useStyle();

  const [isActivatingInProgress, setIsActivatingInProgress] = useState(true);
  const [isInError, setIsInError] = useState(false);

  const token = UrlHelper.getUrlQueryParams();
  useEffect(() => {
    if (token) {
      setIsActivatingInProgress(true);

      userStore
        .activatingAccount(client, token)
        .then(() => {
          setIsActivatingInProgress(false);
        })
        .catch(() => {
          setIsInError(true);
          setIsActivatingInProgress(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleClick = () => {
    history.push(authRoutes.Login());
  };

  if (!ready) {
    return <AppSuspense />;
  }

  if (!token) {
    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.languageContainer}>
          <Languages t={t} />
        </Box>
        <Grid container className={classes.container}>
          <Grid item className={classes.gridItem}>
            <ErrorOutlineIcon className={classes.errorIcon} />
            <Typography variant="body1">{t("noToken_error")}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (isActivatingInProgress) {
    return (
      <Grid container className={classes.container}>
        <Box className={classes.gridItem}>
          <Logo fullLogoClassName={classes.logo} />
          <CircularProgress className={classes.loadingIcon} />
          <Typography variant="body1">{t("loadingMessage")}</Typography>
        </Box>
      </Grid>
    );
  }

  if (isInError) {
    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.languageContainer}>
          <Languages t={t} />
        </Box>
        <Grid container className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Logo fullLogoClassName={classes.logo} />
            <ErrorOutlineIcon className={classes.errorIcon} />
            <Typography variant="body1">{t("invalidMessage")}</Typography>
            <Typography variant="body1">{t("tryAgain")}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Grid container className={classes.grid}>
        <Grid item>
          <Box className={classes.box}>
            <Logo fullLogoClassName={classes.logo} />
            <DoneIcon className={classes.icon} />
            <Typography variant="h5">{t("congratulations")}</Typography>
            <Typography variant="body1">{t("activatingMessage")}</Typography>
            <CoreButton
              onClick={handleClick}
              variant="outlined"
              className={classes.button}
            >
              {t("login")}
            </CoreButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ActivatingAccount;
