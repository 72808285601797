import React, { FC, memo, useState, useMemo } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import CoreTag from "../../../core/CoreTag";
import { HISTORY_EVENT_TYPES } from "../../../../types/constants";

const useStyles = makeStyles({
  filterBoxContainer: {
    padding: "0px 0 20px 0",
  },
  filtersDisplayBox: {
    marginTop: "10px",
  },
  filterItem: {
    margin: "3px",
  },
});

interface Props {
  translation: TFunction;
  selectedFilterEvents: { id: string; label: string }[];
  setSelectedFilterEvents: (newList: { id: string; label: string }[]) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const EVENT_TYPE_LABElS = {
  [HISTORY_EVENT_TYPES.UPDATE_METADATA]: "history_event_update_metadata_label",
  [HISTORY_EVENT_TYPES.STATUS_UPDATE]: "history_event_status_update_label",
  [HISTORY_EVENT_TYPES.IMPORT]: "history_event_import_label",
  [HISTORY_EVENT_TYPES.DISCOVER]: "history_event_discover_label",
  [HISTORY_EVENT_TYPES.UPDATE_OCR]: "history_event_update_ocr_label",
  [HISTORY_EVENT_TYPES.EXTRACT_PAGES]: "history_event_extract_pages_label",
  [HISTORY_EVENT_TYPES.DELETE_PAGES]: "history_event_delete_pages_label",
  [HISTORY_EVENT_TYPES.EXPORT_DOCUMENT]: "history_event_export_document_label",
  [HISTORY_EVENT_TYPES.MOVE_DOCUMENT]: "history_event_move_document_label",
  [HISTORY_EVENT_TYPES.LOCK_UNLOCK]: "history_event_lock_unlock_label",
  [HISTORY_EVENT_TYPES.ASSIGNMENT]: "history_event_assignment_label",
  [HISTORY_EVENT_TYPES.REORDER_PAGES]: "history_event_reorder_pages_label",
};

const HistoryEventFilterBox: FC<Props> = ({
  translation,
  selectedFilterEvents,
  setSelectedFilterEvents,
}) => {
  const classes = useStyles();

  const [filterInputSearch, setFilterSearchValue] = useState("");

  const eventTypeOptions = useMemo(() => {
    const options = Object.keys(EVENT_TYPE_LABElS).map((eventType) => ({
      id: eventType,
      label: translation(EVENT_TYPE_LABElS[eventType]),
    }));

    if (selectedFilterEvents?.length > 0) {
      const eventKeys = selectedFilterEvents.map((item) => item.id);
      return options?.filter((op) => !eventKeys.includes(op.id));
    }

    return options;
  }, [translation, selectedFilterEvents]);

  const onFilterChange = (_: unknown, value: unknown) => {
    const selection = value as { id: string; label: string };

    if (
      selection &&
      !selectedFilterEvents?.some((item) => item.id === selection.id)
    ) {
      setSelectedFilterEvents([...selectedFilterEvents, selection]);
      setFilterSearchValue("");
    }
  };

  const handleRemoveFilter = (filterIndex: number) => {
    setSelectedFilterEvents(
      selectedFilterEvents?.filter((_, index) => index !== filterIndex)
    );
  };

  const handleInput = (event: unknown, value: string, reason: string) => {
    setFilterSearchValue(reason === "reset" ? "" : value);
  };

  return (
    <>
      <Box className={classes.filterBoxContainer}>
        <Autocomplete
          size="small"
          autoHighlight
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(item) => item.label || ""}
          options={eventTypeOptions}
          onInputChange={handleInput}
          inputValue={filterInputSearch}
          value={null}
          onChange={onFilterChange}
          renderOption={(props, item) => (
            <Box component="li" {...props} key={item.id}>
              {item.label}
            </Box>
          )}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={translation("history_event_type_filter_label")}
              />
            );
          }}
        />

        {selectedFilterEvents && selectedFilterEvents.length > 0 && (
          <Box className={classes.filtersDisplayBox}>
            {selectedFilterEvents.map((item, index) => (
              <CoreTag
                key={`${item.id}-${index}`}
                label={item.label}
                onDelete={() => handleRemoveFilter(index)}
                className={classes.filterItem}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(HistoryEventFilterBox);
