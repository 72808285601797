import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Typography,
  useTheme,
  Theme,
} from "@mui/material";

import CoreInput from "../../../../../core/CoreInput";
import CoreTable from "../../../../../core/CoreTable";
import CoreCheckbox from "../../../../../core/CoreCheckbox";
import { EXPORT_KEY } from "../../../../../../types/interfaces";
import {
  EXPORT_HEADERS,
  FIELD_TYPE,
  THEME_MODES,
} from "../../../../../../types/constants";
import { GlobalEventType } from "../../../../../../types/types";

interface Props {
  exportFields: EXPORT_KEY[];
  handleChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    exportKeyField: string
  ) => void;
  setExportFields: React.Dispatch<React.SetStateAction<EXPORT_KEY[]>>;
  isDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableBox: { padding: "0 35px 0 9px" },
  title: {
    display: "flex",
    marginTop: "10px",
    "@media screen and (min-width:551px) and (max-width:700px)": {
      paddingLeft: "9%",
    },
    "@media screen and (min-width:701px)  and (max-width:850px)": {
      paddingLeft: "10%",
    },
    "@media screen and (min-width:851px)": {
      paddingLeft: "10.5%",
    },
    wordBreak: "break-word",
  },
  coreInput: {
    display: "flex",
    paddingTop: "10px",
    width: "40%",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
  },
  accordion: {
    backgroundColor:
      theme.palette.mode === THEME_MODES.light
        ? theme.palette.secondary.light
        : theme.palette.background.paper,
    paddingTop: "5px",
  },
  boxAccordionSummary: {
    display: "flex",
  },
  icon: {
    maxWidth: "40px",
    maxHeight: "40px",
    marginTop: "10px",
    marginRight: "10px",
  },
}));

const AdvancedExportTable: React.FC<Props> = ({
  exportFields,
  handleChange,
  setExportFields,
  isDisabled = false,
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = useState<{
    [key: string]: boolean;
  }>({});

  const handleExpand = (
    e: React.FormEvent<HTMLButtonElement>,
    exportKey: string
  ) => {
    const config = {} as { [key: string]: boolean };
    e.preventDefault();
    e.stopPropagation();
    config[exportKey] = !expanded[exportKey];
    setExpanded({ ...expanded, ...config });
  };

  const handleChangeHeaderCheckbox = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    tableKey: string
  ) => {
    const {
      target: { checked },
    } = e as GlobalEventType;

    // if checked is false, then uncheck all the headers
    const newTable = exportFields.map((exportField) =>
      exportField.key === tableKey
        ? {
            ...exportField,
            willExport: checked,
            headers: exportField.headers
              ? exportField.headers.map((header) => ({
                  ...header,
                  willExport: checked,
                }))
              : null,
          }
        : exportField
    );

    setExportFields(newTable);
  };

  const handleChangeTable = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    tableKey: string
  ) => {
    const {
      target: { name, value, type, checked },
    } = e as GlobalEventType;

    const newTable = exportFields.map((exportField) =>
      exportField.key === tableKey
        ? {
            ...exportField,
            headers: exportField.headers
              ? exportField?.headers?.map((header, i) =>
                  i === index
                    ? {
                        ...header,
                        [name]: type === FIELD_TYPE.checkbox ? checked : value,
                      }
                    : header
                )
              : null,
          }
        : exportField
    );

    //if the field has headers, and all the headers are checked, then check the table
    //if the field has headers, and all the headers are unchecked, then uncheck the table

    const newNewTable = newTable.map((exportField) => {
      const hasHeaders = exportField.headers
        ? exportField.headers?.length > 0
        : false;

      if (hasHeaders) {
        const atLeastOneHeaderChecked = exportField.headers?.some(
          (header) => header.willExport
        );
        const allHeadersUnchecked = exportField.headers?.every(
          (header) => !header.willExport
        );

        if (atLeastOneHeaderChecked) {
          return {
            ...exportField,
            willExport: true,
          };
        } else if (allHeadersUnchecked) {
          return {
            ...exportField,
            willExport: false,
          };
        } else {
          return exportField;
        }
      } else {
        return exportField;
      }
    });

    setExportFields(newNewTable);
  };

  const getFormattedTableHeader = (exportKey: EXPORT_KEY) =>
    exportKey?.headers?.map((header, index) => ({
      willExport: (
        <CoreCheckbox
          checked={header?.willExport}
          onChange={(e) => handleChangeTable(e, index, exportKey.key)}
          name="willExport"
          disabled={isDisabled}
        />
      ),
      name: <Typography>{`${header.name} (${header.key})`}</Typography>,
      exportName: (
        <CoreInput
          value={header.exportKey}
          onChange={(e) => handleChangeTable(e, index, exportKey.key)}
          name="exportKey"
          disabled={isDisabled}
        />
      ),
    }));

  return (
    <>
      {exportFields
        ?.map((exportKey) => {
          if (exportKey.headers) {
            const data = getFormattedTableHeader(exportKey);
            return (
              <Box key={exportKey.key} className={classes.accordion}>
                <Accordion expanded={expanded[exportKey.key] || false}>
                  <Box className={classes.boxAccordionSummary}>
                    <AccordionSummary className={classes.accordionSummary}>
                      <Checkbox
                        checked={
                          exportKey.willExport &&
                          exportKey.headers.some((header) => header.willExport)
                        }
                        indeterminate={
                          !exportKey.headers.every(
                            (header) => header.willExport
                          ) &&
                          exportKey.headers.some((header) => header.willExport)
                        }
                        onChange={(e) =>
                          handleChangeHeaderCheckbox(e, exportKey.key)
                        }
                        disabled={isDisabled}
                      />
                      <Typography
                        className={classes.title}
                      >{`${exportKey.name} (${exportKey.key})`}</Typography>
                    </AccordionSummary>
                    <Box className={classes.coreInput}>
                      <CoreInput
                        value={exportKey.exportKey}
                        name="exportKey"
                        onChange={(e) => handleChange(e, exportKey.key)}
                        disabled={isDisabled}
                      />
                    </Box>
                    <IconButton
                      onClick={(e) => handleExpand(e, exportKey.key)}
                      className={classes.icon}
                    >
                      {expanded[exportKey.key] === true ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Box>
                  <AccordionDetails>
                    <Box className={classes.tableBox}>
                      <CoreTable
                        globalCellStyle={{
                          borderBottomColor: "transparent",
                          wordBreak: "break-word",
                        }}
                        isLoading={false}
                        hideHeaders={true}
                        headers={EXPORT_HEADERS}
                        data={data || []}
                        columnWidth={{
                          willExport: "10%",
                          exportName: "37%",
                        }}
                        isPaginated={false}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          }
          return null;
        })
        .filter((field) => field !== null)}
    </>
  );
};

export default AdvancedExportTable;
