import React, { useEffect } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useApolloClient } from "@apollo/client";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import { CreateNewFolder } from "@mui/icons-material";

import { FormData, FormField } from "../../../../../../types/interfaces";
import { useStores } from "../../../../../../stores/StoresProvider";
import { getGooglePickerOptions, Providers } from "./helper";
import { useNotification } from "../../../../../../context/useNotification";

const useStyles = makeStyles({
  iconButton: {
    marginLeft: "5px",
    marginRight: "-11px",
  },
});

const GooglePicker: React.FC<{
  onChange: (formData: FormData) => void;
  field: FormField;
  translation: TFunction;
  code: string;
  setCode: (code: string) => void;
  iconOnClick?: () => void;
}> = ({ onChange, field, translation, code, setCode, iconOnClick }) => {
  const { flowStore, mainStore } = useStores();
  const client = useApolloClient();
  const [openPicker] = useDrivePicker();
  const notification = useNotification();
  const classes = useStyles();

  const getAccessAndRefreshToken = async (code: string) => {
    if (!code) return;
    const data = (await flowStore.getAccessAndRefreshTokens(
      client,
      code,
      Providers.google
    )) as {
      accessToken: string;
      refreshToken: string;
      idToken: string;
    };
    return data;
  };

  useEffect(() => {
    if (code) {
      getAccessAndRefreshToken(code)
        .then((tokens) => {
          if (tokens)
            openPicker(
              getGooglePickerOptions(
                mainStore,
                tokens,
                onChange,
                field.customScopes || [],
                setCode
              )
            );
        })
        .catch(() => {
          notification.error(translation("loginFailed"));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <Box>
      <IconButton onClick={iconOnClick} className={classes.iconButton}>
        <CreateNewFolder />
      </IconButton>
    </Box>
  );
};

export default GooglePicker;
