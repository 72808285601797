import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/material";

import DiagramNodeIcon from "./DiagramNodeIcon";
import { useStores } from "../../../../../stores/StoresProvider";

interface Props {
  nodeKey: string;
  value: string;
  nodeTitleStyle: string;
  nodeIdentifier: string;
}

const useStyles = makeStyles({
  textBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "5px",
  },
  iconTextBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "transparent",
    padding: 0,
  },
  iconStyle: {
    fontSize: "14px",
  },
  svgIconStyle: {
    width: "15px",
  },
});

const DiagramNodeLabel: React.FC<Props> = observer(
  ({ nodeKey, value, nodeTitleStyle, nodeIdentifier }) => {
    const { flowSettingsStore } = useStores();
    const classes = useStyles();
    const theme = useTheme();

    const nodeHasErrors = useMemo(() => {
      return Object.keys(flowSettingsStore?.nodeErrors || {})?.includes(
        nodeIdentifier
      );
    }, [flowSettingsStore.nodeErrors, nodeIdentifier]);

    return (
      <Box className={classes.textBox}>
        <DiagramNodeIcon
          nodeKey={nodeKey}
          textBoxStyle={classes.iconTextBox}
          iconStyle={classes.iconStyle}
          svgIconStyle={classes.svgIconStyle}
          forDiagram={true}
          nodeHasErrors={nodeHasErrors}
        />

        <span className="nodrag" style={{ display: "flex" }}>
          <span
            className={nodeTitleStyle}
            style={{
              color: nodeHasErrors ? theme.palette.warning.main : "inherit",
            }}
          >
            {value}
          </span>
        </span>
      </Box>
    );
  }
);

export default DiagramNodeLabel;
