import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Typography, useTheme, alpha } from "@mui/material";

import DocumentInformationsPopup from "./DocumentInformationsPopup";

const Component: React.FC = () => {
  const [isDocInfoOpen, setIsDocInfoOpen] = useState(false);
  const { t } = useTranslation("document-validate");
  const theme = useTheme();

  const useStyles = makeStyles({
    link: {
      fontSize: "14px",
      color: alpha(theme.palette.highlight.main, 0.8),
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    docInfoButton: {
      marginRight: "5px",
    },
  });

  const classes = useStyles();

  return (
    <span className={classes.docInfoButton}>
      <Typography
        className={classes.link}
        onClick={() => setIsDocInfoOpen(true)}
      >
        {t("docInfo")}
      </Typography>
      <DocumentInformationsPopup
        isOpen={isDocInfoOpen}
        setIsOpen={setIsDocInfoOpen}
      />
    </span>
  );
};

export const DocInfoButton = memo(Component);
