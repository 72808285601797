/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from "i18next";

import {
  AssetTemplateParameters,
  FormData,
  FormField,
  FIELD_MAX_LENGTH,
  Flow,
  FlowInterface,
  InputOptions,
} from "../types/interfaces";
import { FIELD_TYPE, FlowEnum, ContextVariableEnum } from "../types/constants";

export default class ConfigHelper {
  static getOptions(
    field: AssetTemplateParameters,
    flow: Flow,
    flows: Flow[],
    formData: FormData
  ) {
    if ((field.type as unknown as string) === FlowEnum.flow)
      return {
        options: (flows ?? [])?.map((flow) => ({
          key: flow.identifier,
          label: flow.name,
        })),
      };

    if (field.key === FlowEnum.category)
      return {
        options:
          flow?.categories?.map((item) => ({
            key: item.key,
            label: item.name,
          })) ?? [],
      };

    if (field.key === FlowEnum.labels)
      return {
        options: (formData as unknown as { [key: string]: unknown })[
          FlowEnum.category
        ]
          ? flow?.categories
              ?.find(
                (item) =>
                  item.key ===
                  (formData as unknown as { [key: string]: unknown })[
                    FlowEnum.category
                  ]
              )
              ?.labels?.map((item) => ({
                key: item.key,
                label: item.label ?? item.name,
              }))
          : [],
      };

    if (field.type === FIELD_TYPE.select)
      return {
        options: field.options ?? [],
      };

    return [];
  }

  static getFieldsConfig(
    t: TFunction,
    fieldsErrors: {
      [key: string]: string;
    },
    customName?: string,
    shouldAssignValue?: boolean
  ) {
    return [
      {
        translationKey: customName || "name",
        key: "name",
        type: FIELD_TYPE.input,
        errorText: fieldsErrors?.["name"],
        isMandatory: true,
        tooltipLocation: "input",
        props: {
          title: customName || t("name"),
          description: customName || t("name"),
          inputProps: {
            maxLength: FIELD_MAX_LENGTH.NAME,
          },
        },
        ...(shouldAssignValue ? { value: null } : {}),
      },
    ] as unknown as FormField[];
  }

  static getFieldsConfiguration = (
    t: TFunction,
    assetParameters: AssetTemplateParameters[],
    fieldsErrors: {
      [key: string]: string;
    },
    formData: FormData,
    flow: FlowInterface,
    flows: Flow[],
    setFormData: (data: FormData) => void,
    fieldName: string,
    forDiagram?: boolean,
    isEditable?: boolean,
    checkVariableUsage?: boolean
  ) => {
    const fields = assetParameters ?? [];

    const getValue = (key: string) => {
      return formData[key] ?? "";
    };

    const notForDiagramConfig = [
      ...ConfigHelper.getFieldsConfig(t, fieldsErrors),
      {
        translationKey: "description",
        key: "description",
        type: FIELD_TYPE.input,
        errorText: fieldsErrors?.["description"],
        isMandatory: false,
        tooltipLocation: "input",
        props: {
          title: t("description"),
          description: t("description"),
          inputProps: {
            maxLength: FIELD_MAX_LENGTH.DESCRIPTION,
          },
        },
      },
    ];

    return [
      ...(!forDiagram
        ? notForDiagramConfig
        : ConfigHelper.getFieldsConfig(t, fieldsErrors, fieldName, true)),
      ...fields.map((field) => {
        const parentKeys = Object.keys(field.parents ?? {});
        let render = true;
        parentKeys.forEach((key) => {
          if (!field.parents?.[key].includes(formData[key])) {
            render = false;
          }
        });

        if (!render) return {} as FormField;
        if (formData[field.key] === undefined) {
          setFormData({ ...formData, [field.key]: field.value });
        }

        return {
          ...field,
          translationKey: field.name,
          key: field.key,
          type:
            (field.type as unknown as string) === FlowEnum.flow
              ? FIELD_TYPE.select
              : field.type,
          errorText: fieldsErrors?.[field.key],
          isMandatory: field.isMandatory || false,
          tooltipLocation: "input",
          preventValueCheck: true,
          canContainSpacesOnly: field.canContainSpacesOnly,
          cannotEditSchema: checkVariableUsage || false,
          props: {
            title: field.name,
            readOnly:
              checkVariableUsage !== undefined &&
              (field.key === ContextVariableEnum.key ||
                field.key === ContextVariableEnum.type ||
                field.type === FIELD_TYPE.json)
                ? checkVariableUsage
                : !isEditable,
            description:
              (field.key === ContextVariableEnum.key ||
                field.key === ContextVariableEnum.type) &&
              !field.description &&
              checkVariableUsage !== undefined &&
              checkVariableUsage
                ? t("cannotEditField")
                : field.description,
            value: getValue(field.key),
          },
          ...ConfigHelper.getOptions(field, flow, flows, formData),
        };
      }),
    ] as FormField[];
  };

  static filterOptionsByTerm = (
    options: InputOptions[],
    inputValue: string
  ) => {
    const allCategories = [
      ...new Set([
        ...(options || []).map((option) => option?.category?.toLowerCase()),
      ]),
    ];
    const input = inputValue?.trim()?.toLowerCase();

    const index = input?.indexOf(".");

    // If there's no dot, return the input as the first element and an empty string as the second

    if (index === -1) {
      if (input) {
        return options?.filter(
          (option) =>
            option?.category?.toLowerCase()?.includes(input) ||
            option?.key?.toLowerCase()?.includes(input)
        );
      }

      return options;
    }
    // Split the string at the first dot
    const categoryTerm = input?.substring(0, index);
    const nameTerm = input?.substring(index + 1) || "";

    if (allCategories?.includes(categoryTerm?.toLowerCase())) {
      return options?.filter(
        (option) =>
          option?.category?.toLowerCase()?.includes(categoryTerm) &&
          option?.key?.toLowerCase()?.includes(nameTerm?.trim())
      );
    } else {
      return options?.filter(
        (option) =>
          option?.key?.toLowerCase()?.includes(input) ||
          option?.value?.toLowerCase()?.includes(input)
      );
    }
  };

  static filterParams = (fields: FormField[], formData: FormData) => {
    let newFormData = {} as FormData;

    fields?.forEach((field) => {
      if (field.key) {
        newFormData = {
          ...newFormData,
          [field.key]: formData?.[field?.key] || field?.value,
        };
      }
    });

    if (formData?.options as FormData[]) {
      newFormData.options = formData?.options;
    }

    return newFormData;
  };
}
