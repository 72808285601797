import Konva from "konva";
import { Box } from "konva/lib/shapes/Transformer";
import { v4 as uuidv4 } from "uuid";
import polygonClipping, { Polygon } from "polygon-clipping";

import { DocumentZone, DocumentZoneBox, Point } from "../../types/interfaces";
import {
  CANVAS_COORDS_RULE,
  CANVAS_LINE_ITEMS_COORDS_RULE,
} from "../../types/constants";

export default class ZoneHelper {
  static getTransformerBoundBox(oldBox: Box, newBox: Box): Box {
    // limit resize
    if (newBox.width < 5 || newBox.height < 5) {
      return oldBox;
    }
    return newBox;
  }

  static normalizeRect = (rect: DocumentZoneBox): DocumentZoneBox => {
    if (rect.width >= 0 && rect.height >= 0) {
      return rect;
    } else {
      const x = rect.width < 0 ? rect.width + rect.x : rect.x;
      const y = rect.height < 0 ? rect.height + rect.y : rect.y;
      return {
        x: x,
        y: y,
        width: Math.abs(rect.width * (rect.scaleX || 1)),
        height: Math.abs(rect.height * (rect.scaleY || 1)),
        scaleX: 1,
        scaleY: 1,
        rotation: rect.rotation,
      };
    }
  };

  static intersectRect(
    rect1: DocumentZoneBox,
    rect2: DocumentZoneBox,
    ratio = 0.5
  ): boolean {
    const r1 = this.normalizeRect(rect1);
    const r2 = this.normalizeRect(rect2);
    return this.detectPolygonCollision(r1, r2, ratio);
  }
  /**
   * Extracts mouse event coords and constructs the zone based on new values
   * Returns updated zone
   * @param event - Konva event type
   * @param drawingZone - Initial zone
   * @param store
   * @returns
   */
  static preprocessZone(
    event: Konva.KonvaEventObject<MouseEvent | TouchEvent | Event>,
    drawingZone: DocumentZone,
    // store: DocumentStore
    scaleCoords: { x: number; y: number },
    canvasScaleSize: number,
    edit?: boolean
  ): DocumentZone {
    const sx = drawingZone.box.x;
    const sy = drawingZone.box.y;

    const { x = sx, y = sy } = event?.target
      ?.getStage()
      ?.getRelativePointerPosition() ?? { x: sx, y: sy };

    return {
      ...(drawingZone || {}),
      box: {
        x: sx,
        y: sy,
        width: edit
          ? drawingZone.box.width * (drawingZone.box.scaleX || 1)
          : x - sx,
        height: edit
          ? drawingZone.box.height * (drawingZone.box.scaleY || 1)
          : y - sy,
        scaleX: 1,
        scaleY: 1,
        rotation: drawingZone.box.rotation,
      },
    };
  }

  /**
   * Merges a list of zones by extracting new coords based on received list
   * @param zones - list of zones to merge
   * @param fieldType - selected field type (ex: invoiceIdentifier)
   * @returns - new zone
   */
  static mergeZones(
    zones: DocumentZone[],
    fieldType: string,
    pageIdentifier: string
  ): DocumentZone {
    // Extract min x coord
    const x = Math.min(...zones.map((zone) => zone.box.x));

    // Extract min y coord
    const y = Math.min(...zones.map((zone) => zone.box.y));

    // Extract new width calculated based on x and width coords
    const width = Math.max(...zones.map((zone) => zone.box.x + zone.box.width));

    // Extract max height calculated based on y and height coords
    const height = Math.max(
      ...zones.map((zone) => zone.box.y + zone.box.height)
    );

    // Text concatenation must be done in OCR zone order and not based on the selection
    const sortedZones = zones?.sort((a, b) => a.index - b.index);

    // Text of the merged zone should be the text concatenation of all received zone
    const text = sortedZones?.map((zone) => zone.text).join(" ");

    return {
      identifier: uuidv4(),
      text,
      score: 1,
      textScore: 1,
      segmentationScore: 1,
      type: fieldType,
      category: fieldType,
      manuallyAdded: true,
      default: false,
      box: {
        x,
        y,
        width: width - x,
        height: height - y,
      },
      pageIdentifier,
    } as DocumentZone;
  }

  /**
   * Check if the width and height of the zone meets the canvas zone coords limitation
   * Negative width and height may be received because of the drawing direction (inverted zone)
   * @param zone
   * @returns
   */
  static isZoneDimensionValid = (
    zone: DocumentZone,
    isLineItemsMode?: boolean
  ): boolean => {
    if (!zone) {
      return false;
    }

    const minWidth = isLineItemsMode
      ? CANVAS_LINE_ITEMS_COORDS_RULE.width
      : CANVAS_COORDS_RULE.width;
    const minHeight = isLineItemsMode
      ? CANVAS_LINE_ITEMS_COORDS_RULE.height
      : CANVAS_COORDS_RULE.height;

    // Convert negative dimensions
    const zoneWidth = zone.box.width < 0 ? zone.box.width * -1 : zone.box.width;
    const zoneHeight =
      zone.box.height < 0 ? zone.box.height * -1 : zone.box.height;

    return zoneWidth > minWidth && zoneHeight > minHeight;
  };

  static isZoneLinked = (zone: DocumentZone, fields: DocumentZone[] | null) => {
    if (!fields || fields.length === 0) {
      return false;
    }

    return fields.some((field) => this.intersectRect(zone.box, field.box));
  };

  static normalizeZoneCoords(
    zone: DocumentZone,
    width: number,
    height: number
  ): DocumentZone {
    let formattedZone = { ...zone };

    //from right to left
    if (zone.box.width < 0) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          x: zone.box.x + zone.box.width,
          width: Math.abs(zone.box.width),
        },
      };
    }
    //from bottom to top
    if (zone.box.height < 0) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          y: zone.box.y + zone.box.height,
          height: Math.abs(zone.box.height),
        },
      };
    }

    //from left to right
    if (formattedZone.box.x < 0) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          width: formattedZone.box.width + formattedZone.box.x,
          x: 0,
        },
      };
    }

    //from top to bottom
    if (formattedZone.box.y < 0) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          height: formattedZone.box.height + formattedZone.box.y,
          y: 0,
        },
      };
    }

    if (formattedZone.box.x + formattedZone.box.width > width) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          width: width - formattedZone.box.x,
        },
      };
    }

    if (formattedZone.box.y + formattedZone.box.height > height) {
      formattedZone = {
        ...formattedZone,
        box: {
          ...formattedZone.box,
          height: height - formattedZone.box.y,
        },
      };
    }

    const { box } = formattedZone;
    if (
      box.width <= 0 ||
      box.height <= 0 ||
      box.x >= width ||
      box.y >= height
    ) {
      return { ...formattedZone, box: { x: 0, y: 0, width: 0, height: 0 } };
    }
    return formattedZone;
  }

  /**
   * @param x - x coord of the point to check
   * @param y - y coord of the point to check
   * @param rad - rotation angle in radians
   * @param Ox - x coord of the rotation origin of the rectangle
   * @param Oy - y coord of the rotation origin of the rectangle
   * @returns - new x and y coords of the point after rotation
   */
  static rotatePoint = (
    x: number,
    y: number,
    rad: number,
    Ox?: number,
    Oy?: number
  ) => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);

    Ox = Ox || 0;
    Oy = Oy || 0;
    return {
      x: Ox + (x - Ox) * rcos - (y - Oy) * rsin,
      y: Ox + (y - Oy) * rcos + (x - Ox) * rsin,
    };
  };

  /**
   * @param drawZone - focused zone to check intersection
   * @param OcrZone - ocr zone to check if it intersects with the drawZone
   * @returns - true if the drawZone intersects with the OcrZone
   */
  static detectPolygonCollision(
    drawZone: DocumentZoneBox,
    OcrZone: DocumentZoneBox,
    ratio = 0.5
  ): boolean {
    const rect1 = this.getRotatedRectangleAroundTopLeftCoords(
      drawZone.x,
      drawZone.y,
      drawZone.width,
      drawZone.height,
      drawZone.rotation
    );

    const rect2 = this.getRotatedRectangleAroundTopLeftCoords(
      OcrZone.x,
      OcrZone.y,
      OcrZone.width,
      OcrZone.height,
      OcrZone.rotation
    );

    // array with the 4 corners of the intersection rectangle
    const arr = polygonClipping.intersection(rect1, rect2);

    if (arr.length > 0) {
      const length = arr[0][0].length;
      const X = [];
      const Y = [];
      for (let i = 0; i < length; i++) {
        X.push(polygonClipping.intersection(rect1, rect2)[0][0][i][0]);
        Y.push(polygonClipping.intersection(rect1, rect2)[0][0][i][1]);
      }
      const ocrArea = OcrZone.width * OcrZone.height;

      //If more than ratio (default 50%) of the ocr zone is inside the draw zone then it is considered as intersecting
      if (this.getArea(X, Y) > ocrArea * ratio) {
        return true;
      }
    }

    return false;
  }

  /**
   * @param X - array of x coords of the polygon
   * @param Y - array of y coords of the polygon
   * @returns - area of the polygon
   * @description - the area is calculated using the shoelace formula
   */

  static getArea = (X: number[], Y: number[]) => {
    let area = 0;
    const length = X.length;
    let j = length - 1;
    for (let i = 0; i < length; i++) {
      area += (X[j] + X[i]) * (Y[j] - Y[i]);
      j = i;
    }
    return Math.abs(area / 2);
  };

  /**
   * @param topLeftX - x coord of the top left corner of the rectangle
   * @param topLeftY - y coord of the top left corner of the rectangle
   * @param width - width of the rectangle
   * @param height - height of the rectangle
   * @param rotation - rotation angle in radians
   * @returns - array with the 4 corners of the rectangle after rotation
   */
  static getRotatedRectangleAroundTopLeftCoords(
    topLeftX: number,
    topLeftY: number,
    width: number,
    height: number,
    rotation?: number
  ): Polygon {
    const rectCoords = [
      { x: topLeftX, y: topLeftY },
      { x: topLeftX + width, y: topLeftY },
      { x: topLeftX + width, y: topLeftY + height },
      { x: topLeftX, y: topLeftY + height },
    ];

    const newRotation = rotation || 0;

    const rotatedRectCoords = rectCoords.map((coord) => {
      return this.rotatePointAroundOrigin(
        coord.x,
        coord.y,
        topLeftX,
        topLeftY,
        newRotation
      );
    });

    return [
      [
        [rotatedRectCoords[0].x, rotatedRectCoords[0].y],
        [rotatedRectCoords[1].x, rotatedRectCoords[1].y],
        [rotatedRectCoords[2].x, rotatedRectCoords[2].y],
        [rotatedRectCoords[3].x, rotatedRectCoords[3].y],
      ],
    ] as Polygon;
  }

  /**
   * @param topLeftX - x coord of the top left corner of the rectangle
   * @param topLeftY - y coord of the top left corner of the rectangle
   * @param width - width of the rectangle
   * @param height - height of the rectangle
   * @param rotation - rotation angle in radians
   * @returns - array with the 4 corners of the rectangle after rotation
   */
  static getRotatedRectangleAroundTopLeftCoordsOrigin(
    topLeftX: number,
    topLeftY: number,
    width: number,
    height: number,
    originX: number,
    originY: number,
    rotation?: number
  ): Polygon {
    const rectCoords = [
      { x: topLeftX, y: topLeftY },
      { x: topLeftX + width, y: topLeftY },
      { x: topLeftX + width, y: topLeftY + height },
      { x: topLeftX, y: topLeftY + height },
    ];

    const newRotation = rotation || 0;

    const rotatedRectCoords = rectCoords.map((coord) => {
      return this.rotatePointAroundOrigin(
        coord.x,
        coord.y,
        originX,
        originY,
        newRotation
      );
    });

    return [
      [
        [rotatedRectCoords[0].x, rotatedRectCoords[0].y],
        [rotatedRectCoords[1].x, rotatedRectCoords[1].y],
        [rotatedRectCoords[2].x, rotatedRectCoords[2].y],
        [rotatedRectCoords[3].x, rotatedRectCoords[3].y],
      ],
    ] as Polygon;
  }

  /**
   * @param x - x coord of the point to check
   * @param y - y coord of the point to check
   * @param originX - x coord of the rotation origin of the rectangle
   * @param originY - y coord of the rotation origin of the rectangle
   * @param rad - rotation angle in radians
   * @returns - new x and y coords of the point after rotation
   * @description - the rotation is done around the origin of the rectangle
   */
  static rotatePointAroundOrigin(
    x: number,
    y: number,
    originX: number,
    originY: number,
    rotation: number
  ): Point {
    const radians = (rotation * Math.PI) / 180;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - originX) - sin * (y - originY) + originX;
    const ny = sin * (x - originX) + cos * (y - originY) + originY;
    return { x: nx, y: ny };
  }
}
