import React, { useState, useMemo } from "react";
import { TFunction } from "i18next";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconButton from "@mui/material/IconButton";

import { v4 as uuidv4 } from "uuid";
import {
  AssetConfiguration,
  FormData,
  TableContentDef,
} from "../../../types/interfaces";
import CoreDrawer from "../../core/CoreDrawer";
import CustomOptionConfig from "./CustomOptionConfig";
import CoreButton from "../../core/CoreButton";
import CoreTable from "../../core/CoreTable";
import CoreTooltip from "../../core/CoreTooltip";
import { useStores } from "../../../stores/StoresProvider";

interface Props {
  t: TFunction;
  formData: FormData;
  setFormData: (data: (prevFormData: FormData) => FormData) => void;
  optionAssetCategoryKey: string;
}

const useStyles = makeStyles({
  addOptionButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "16px",
  },
  tableKey: {
    display: "flex",
    flex: "wrap",
    fontSize: "14px",
    wordBreak: "break-all",
  },
  tableAction: {
    fontSize: "20px",
  },
});

const COL_WIDTH: TableContentDef = {
  key: "35%",
  isMandatory: "15%",
  actions: "15%",
};

const getTableHeaders = (translate: TFunction) => {
  return [
    {
      accessor: "name",
      label: translate("customOptionHeader_name"),
    },
    {
      accessor: "key",
      label: translate("customOptionHeader_key"),
    },
    {
      accessor: "actions",
      label: "",
    },
  ];
};

const CustomOptions: React.FC<Props> = ({
  t,
  formData,
  setFormData,
  optionAssetCategoryKey,
}) => {
  const { flowSettingsStore } = useStores();
  const [isOptionsDrawerOpen, setIsOptionsDrawerOpen] =
    useState<boolean>(false);
  const [selectedOptionKey, setSelectedOptionKey] = useState<string>("");

  const classes = useStyles();

  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);

  const addOptionsToFormData = (config: FormData) => {
    setFormData((prevFormData) => {
      const optionsArray =
        prevFormData?.options && Array.isArray(prevFormData?.options)
          ? prevFormData?.options
          : [];
      return {
        ...prevFormData,
        options: [...(optionsArray as FormData[]), config],
      };
    });
  };

  const editOptionInFormData = (config: FormData) => {
    setFormData((prevFormData) => {
      const optionsArray =
        prevFormData?.options && Array.isArray(prevFormData?.options)
          ? prevFormData?.options
          : [];

      return {
        ...prevFormData,
        options: (optionsArray as FormData[])?.map((option) =>
          option?.key === config.key ? { ...option, ...config } : option
        ),
      } as FormData;
    });
  };

  const deleteOptionFromFormData = (key: string) => {
    setFormData((prevFormData) => {
      const optionsArray =
        prevFormData?.options && Array.isArray(prevFormData?.options)
          ? prevFormData?.options
          : [];

      const updatedOptions = (optionsArray as FormData[])?.filter(
        (option) => option?.key !== key
      );

      return {
        ...prevFormData,
        options: updatedOptions,
      };
    });
  };

  const tableData = useMemo(() => {
    if (
      formData?.options &&
      Array.isArray(formData?.options) &&
      formData?.options?.length > 0
    ) {
      return (formData?.options as FormData[])?.map((option) => ({
        id:
          //TODO: Delete comment
          // (option?.key as string) ||
          uuidv4(),
        key: <div className={classes.tableKey}>{option?.key as string}</div>,
        name: <div className={classes.tableKey}>{option?.name as string}</div>,
        actions: (
          <Box>
            <CoreTooltip
              title={
                flowSettingsStore.isDiagramEditable
                  ? t("edit_tooltip")
                  : t("view_tooltip")
              }
            >
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    setIsOptionsDrawerOpen(true);
                    setSelectedOptionKey(option?.key as string);
                  }}
                >
                  <EditIcon className={classes.tableAction} />
                </IconButton>
              </span>
            </CoreTooltip>
            <CoreTooltip title={t("delete_tooltip") || ""}>
              <span>
                <IconButton
                  size="small"
                  onClick={() =>
                    deleteOptionFromFormData(option?.key as string)
                  }
                  disabled={!flowSettingsStore.isDiagramEditable}
                >
                  <DeleteOutlineOutlinedIcon className={classes.tableAction} />
                </IconButton>
              </span>
            </CoreTooltip>
          </Box>
        ),
      }));
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.options, t]);

  const closeOptionDrawer = () => {
    setIsOptionsDrawerOpen(false);
    if (selectedOptionKey) setSelectedOptionKey("");
  };

  const getDrawerTitle = useMemo(() => {
    if (!flowSettingsStore.isDiagramEditable) {
      return t("viewOptionTitle");
    }

    if (selectedOptionKey) {
      return t("editOptionTitle");
    }

    return t("addOption");
  }, [selectedOptionKey, t, flowSettingsStore.isDiagramEditable]);

  return (
    <>
      <Box className={classes.addOptionButton}>
        <CoreButton
          onClick={() => setIsOptionsDrawerOpen(true)}
          disabled={!flowSettingsStore.isDiagramEditable}
        >
          {t("addOption")}
        </CoreButton>
      </Box>

      {formData?.options &&
        Array.isArray(formData?.options) &&
        formData?.options?.length > 0 && (
          <>
            <CoreTable
              isPaginated={false}
              headers={tableHeaders}
              data={tableData as TableContentDef[]}
              columnWidth={COL_WIDTH}
            />
          </>
        )}

      <CoreDrawer
        isOpen={isOptionsDrawerOpen}
        onClose={closeOptionDrawer}
        title={getDrawerTitle}
        BackdropProps={{ invisible: true }}
        hasBackButton={true}
        onBack={closeOptionDrawer}
      >
        <CustomOptionConfig
          t={t}
          onAdd={addOptionsToFormData}
          closeDrawer={closeOptionDrawer}
          list={(formData?.options as AssetConfiguration[]) || []}
          onEdit={editOptionInFormData}
          assetCategoryKey={optionAssetCategoryKey}
          selectedOptionKey={selectedOptionKey}
        />
      </CoreDrawer>
    </>
  );
};

export default CustomOptions;
