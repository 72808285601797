import React, { useCallback } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { Box, IconButton, useTheme } from "@mui/material";

import { CANVAS_ACTIONS } from "../../../../../types/constants";
import { DocumentZone, DocumentField } from "../../../../../types/interfaces";
import CoreButton from "../../../../core/CoreButton";
import CoreTooltip from "../../../../core/CoreTooltip";
import { DocumentStore } from "../../../../../stores/DocumentStore";

interface Props {
  field: DocumentField;
  // docField: DocumentZone | null; //TODO: Fix this, send doc field
  translation: TFunction;
  handleFocusedFields: (
    field: DocumentField | null,
    zone?: DocumentZone
  ) => void;
  isDisabled: boolean;
  documentStore: DocumentStore;
}

const CanvasFieldActions: React.FC<Props> = observer(
  ({ translation, handleFocusedFields, documentStore, isDisabled }) => {
    const theme = useTheme();
    const classes = makeStyles({
      boxActions: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        padding: "2.5px",
        width: "100%",
      },
      iconButton: {
        color: theme.palette.success.dark,
      },
      box: { display: "flex" },
    })();

    const handleCancel = useCallback(() => {
      documentStore.handleCancelFocusedFieldCanvas(true);
    }, [documentStore]);

    const handleClear = useCallback(() => {
      documentStore.removeFieldZones();
    }, [documentStore]);

    const handleCheck = useCallback(() => {
      //TODO: Fix this, handle zone
      // documentStore.updateZone({
      //   ...(field || docField || {}),
      //   skipValidation: true,
      // } as unknown as DocumentZone);
      handleFocusedFields(null);

      documentStore.setFocusZone(null);
      documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);
    }, [handleFocusedFields, documentStore]);

    return (
      <Box className={classes.box}>
        <Box>
          <CoreButton variant="neutral" onClick={handleCancel} tabIndex={-1}>
            {translation("cancel")}
          </CoreButton>
        </Box>
        <Box className={classes.boxActions}>
          <CoreTooltip
            title={`${translation("canvasFieldActions_clearTooltip")}`}
          >
            <span>
              <IconButton
                onClick={handleClear}
                disabled={isDisabled}
                size="small"
                tabIndex={-1}
              >
                <ClearIcon />
              </IconButton>
            </span>
          </CoreTooltip>

          <CoreTooltip
            title={`${translation("canvasFieldActions_closeTooltip")}`}
          >
            <span>
              <IconButton
                onClick={handleCheck}
                disabled={isDisabled}
                size="small"
                className={classes.iconButton}
                tabIndex={-1}
              >
                <CheckIcon />
              </IconButton>
            </span>
          </CoreTooltip>
        </Box>
      </Box>
    );
  }
);

export default CanvasFieldActions;
