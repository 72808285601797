import React, { useMemo } from "react";
import moment from "moment";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import { dataTypes } from "../../../../../types/constants";
import CoreTooltip from "../../../../core/CoreTooltip";
import {
  MRT_Cell,
  MRT_TableInstance,
  MRT_Row,
  MRT_Column,
} from "material-react-table";
import { MessageTooltipBox } from "../canvas/messagePanel/MessageTooltipBox";
import {
  DocumentMessageType,
  MESSAGE_LEVELS,
} from "../../../../../types/interfaces";

interface Params {
  cell: MRT_Cell;
  renderedCellValue: number | string | React.ReactNode;
  column: MRT_Column;
  row: MRT_Row;
  table: MRT_TableInstance;
}

interface Props {
  params: Params;
  errors?: {
    [rowIndex: string]: string;
  };
  translation: TFunction;
}

const valueGetter = (params: Params) => {
  let val;
  let hasProblems = false;
  const { column, renderedCellValue } = params;

  switch (column.columnDef.columnDefType) {
    case dataTypes.dateDataType:
      if (moment.isDate(renderedCellValue))
        val = renderedCellValue.toLocaleDateString();
      else {
        val = renderedCellValue;
        hasProblems = true;
      }
      break;
    case dataTypes.numberDataType:
      val = renderedCellValue;
      if (isNaN(renderedCellValue as number)) hasProblems = true;
      break;
    default:
      val = renderedCellValue;
      break;
  }

  return { val, hasProblems };
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  box: {
    marginBottom: "auto",
  },
  warningIcon: {
    fontSize: "20px",
    marginRight: "5px",
  },
});

const TableCellRenderer: React.FC<Props> = ({ params, translation }) => {
  const classes = useStyles();
  const { val } = valueGetter(params);
  const columnId = params?.column?.id;

  // TODO: Change to propert interface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const cellMessage = (params?.row?.original as any)?.["cells"]?.[columnId]?.[
    "message"
  ] as DocumentMessageType;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const cellMessageDetails = (params?.row?.original as any)?.["cells"]?.[
    columnId
  ]?.["details"] as DocumentMessageType[];

  // Message panel must display cell value in cell view mode
  const valueMessage = useMemo(
    () =>
      ({
        level: MESSAGE_LEVELS.success,
        message: val,
      } as DocumentMessageType),
    [val]
  );

  const messagesList = useMemo(() => {
    let messages = [valueMessage] as DocumentMessageType[];

    // Attach details
    if (cellMessage || cellMessageDetails?.length > 0) {
      messages = [
        ...messages,
        {
          level: MESSAGE_LEVELS.subheader,
          message: translation("messagesPanel_messagesHeader"),
        },
        // Add list as suboptions
        ...(cellMessageDetails?.map((item) => ({
          ...item,
          isSubError: true,
        })) || []),
      ];
    }

    return messages;
  }, [cellMessage, cellMessageDetails, translation, valueMessage]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.box}>
        <MessageTooltipBox
          translation={translation}
          message={
            cellMessage || { level: MESSAGE_LEVELS.success, message: "" }
          }
          details={messagesList}
        />
      </Box>

      <CoreTooltip title={val as string} placement="top-start">
        <Typography>{val}</Typography>
      </CoreTooltip>
    </Box>
  );
};

export default TableCellRenderer;
