import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography, Checkbox, useTheme } from "@mui/material";

import { FlowHelper } from "../filters/helpers/FlowHelper";
import CoreTable from "../../../core/CoreTable";
import {
  TableHeaderDef,
  TableContentDef,
  DocumentsFilterProps,
} from "../../../../types/interfaces";
import {
  FILTER_PROPS,
  HOVER_COLUMNS,
  LOCAL_STORAGE_KEYS,
  PAGE_SIZES,
  SORT_DIRECTION,
} from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";
import { NameView } from "./NameView";
import { FlowNameView } from "./FlowNameView";
import { useNotification } from "../../../../context/useNotification";
import StatusBox from "./StatusBox";
import DocumentsActions from "./DocumentsActions";
import LocalStorageHelper from "../../../../helper/localStorageHelper";
import DateHelper from "../../../../helper/dateHelper";
import { Order } from "../../../../types/types";
import { DocumentsMenuAction } from "./DocumentsMenuAction";
import { DocumentBoxSelect } from "./DocumentBoxSelect";

interface Props {
  t: TFunction;
  flowId: string;
  page: number;
  setCurrentPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setFilters: (
    updatedFilters: DocumentsFilterProps,
    resetPage?: boolean
  ) => void;
  refreshData: () => void;
}

const useStyles = makeStyles({
  tableHeaderBox: { width: "125px" },
  container: {
    marginTop: "50px",
    width: "100%",
    minWidth: "600px",
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 900px)": {
      display: "block",
    },
  },
  filterIcon: {
    height: "40px",
    width: "40px",
    "@media only screen and (max-width: 900px)": {
      marginLeft: "-5px",
    },
  },
  numberOfPages: {
    marginLeft: "50px",
  },
});

const COL_WIDTH: TableContentDef = {
  select: "5%",
};

export const DocumentsTable: FC<Props> = observer(
  ({
    t,
    flowId,
    setCurrentPage,
    page,
    rowsPerPage,
    setRowsPerPage,
    setFilters,
    refreshData,
  }) => {
    const { id } = useParams<{ id: string }>();
    const client = useApolloClient();
    const { documentStore, flowStore, userStore } = useStores();
    const theme = useTheme();
    const classes = useStyles();
    const notification = useNotification();

    const [menuOptionsAnchorEl, setMenuOptionsAnchor] =
      useState<null | HTMLElement>(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false);
    const [isMovingDocs, setIsMovingDocs] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const selectedDocuments = documentStore.selectedDocuments;
    const documents = documentStore.currentFlowDocuments;
    const documentIds = documents?.documents?.map((doc) => doc?.identifier);
    const contextId = userStore.namespace?.id.toString();

    // Enable subscriptions
    useEffect(() => {
      if (!contextId) return;
      const subscriptionObserver = documentStore.documentsSubscription(
        client,
        flowId,
        documentIds,
        contextId
      );

      return () => {
        subscriptionObserver.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(documentIds), contextId]);

    const handleDelete = () => {
      setIsDeleteLoading(true);
      documentStore
        .deleteSelectedDocuments(client)
        .then(() => {
          notification.success(t("documents_deleted"));
          setIsDeleteDialogOpen(false);
          setCurrentPage(1);
        })
        .catch((error: Error) => {
          notification.error(
            t(error?.message || "documents_deleted_error") as unknown as string
          );
        })
        .finally(() => setIsDeleteLoading(false));
    };

    const handleMove = (flowId: string) => {
      setIsMovingDocs(true);

      documentStore
        .moveSelectedDocuments(client, flowId)
        .then(() => {
          notification.success(t("documents_moved"));
          setIsMoveDialogOpen(false);
          setCurrentPage(1);
        })
        .catch((error: Error) => {
          notification.error(
            t(error?.message || "documents_moved_error") as unknown as string
          );
        })
        .finally(() => setIsMovingDocs(false));
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
      setCurrentPage(newPage);
    };

    const handleSort = (cell: TableHeaderDef) => {
      const newDirection =
        cell.options?.sortDirection === SORT_DIRECTION.asc
          ? SORT_DIRECTION.desc
          : SORT_DIRECTION.asc;

      setFilters({
        ...flowStore?.filters?.flowFilters,
        [FILTER_PROPS.sortBy]: cell.accessor,
        [FILTER_PROPS.sortDirection]: newDirection,
      });

      documentStore.setTableFilters(
        documentStore.tableFilters,
        cell.accessor,
        newDirection,
        true
      );
    };

    const checkPermissions = useMemo(() => {
      const movePermission =
        userStore.currentUserPermissions?.can("importDocuments", "flows") &&
        userStore.currentUserPermissions?.can("deleteDocuments", "flows");

      const hasAtLeastOnePermission =
        movePermission ||
        userStore.currentUserPermissions?.can("updateDocuments", "flows") ||
        userStore.currentUserPermissions?.can("deleteDocuments", "flows");

      return hasAtLeastOnePermission;
    }, [userStore.currentUserPermissions]);

    const tableHeaders = useMemo(() => {
      let tableHeaderFields: TableHeaderDef[] = [];

      if (flowStore.flowSummary) {
        const dataRef =
          flowStore?.flowSummary?.flowType === "extraction"
            ? flowStore?.flowSummary?.fields
            : flowStore?.flowSummary?.categories;

        if (dataRef && dataRef?.length > 0) {
          tableHeaderFields = dataRef.map((item) => ({
            accessor: item.key,
            translationKey: item.name,
            options: {
              isSortable: false,
            },
          }));
        }
      }

      return (
        [...FlowHelper.generateTableHead(flowId ?? ""), ...tableHeaderFields]
          // Set box component
          ?.map((item) => ({
            ...item,
            label: item.translationKey ? (
              <Box className={classes.tableHeaderBox}>
                {t(item.translationKey)}
              </Box>
            ) : (
              item.label
            ),
            ...(item.accessor === "select" && (checkPermissions || id)
              ? {
                  label: (
                    <DocumentBoxSelect
                      t={t}
                      documents={documents}
                      filterClass={classes.filterIcon}
                      handleMenu={(e) => setMenuOptionsAnchor(e.currentTarget)}
                    />
                  ),
                }
              : {}),
          }))
          // Handle sorting
          ?.map((item) =>
            (documentStore.sortBy ??
              flowStore?.filters?.flowFilters?.sortBy) === item.accessor
              ? {
                  ...item,
                  options: {
                    ...item.options,
                    sortDirection:
                      (documentStore.sortDirection as Order) ??
                      (flowStore?.filters?.flowFilters?.sortDirection as Order),
                    isSortActive: true,
                  },
                }
              : {
                  ...item,
                  options: { ...item.options, isSortActive: false },
                }
          )
          // Return needed data
          ?.filter(
            (item) =>
              documentStore.tableFilters.includes(item.accessor) ||
              item.accessor === "select"
          )
      );
    }, [
      id,
      checkPermissions,
      flowStore.flowSummary,
      flowStore?.filters?.flowFilters?.sortBy,
      flowStore?.filters?.flowFilters?.sortDirection,
      flowId,
      classes.tableHeaderBox,
      classes.filterIcon,
      t,
      documents,
      documentStore.sortBy,
      documentStore.sortDirection,
      documentStore.tableFilters,
    ]);

    const setCurrentPageAndCheckedDocuments = useCallback(() => {
      const storageKey = flowId ? `current-${flowId}` : "current-All flows";

      if (!flowId) {
        // Page set for all flows to document validate and back issue
        // TODO: Find a better fix
        LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.prevPageRoot, "/flows");
      }

      LocalStorageHelper.setValue(storageKey, {
        currentPage: page,
        selectedDocuments: [],
      });
    }, [flowId, page]);

    const formattedData = useMemo(
      () =>
        documents.documents?.length > 0
          ? (documents.documents?.map((item) => {
              const conditionallyCheckBox =
                id || checkPermissions ? (
                  <Checkbox
                    onClick={() => documentStore.checkDocument(item)}
                    checked={selectedDocuments.some(
                      (selectedDoc) =>
                        item.identifier === selectedDoc.identifier
                    )}
                  />
                ) : (
                  <div></div>
                );

              return {
                ...item,
                select: conditionallyCheckBox,
                ...(item.updatedAt && {
                  updatedAt: DateHelper.timeStringFormat(item.updatedAt),
                }),
                ...(item.createdAt && {
                  createdAt: DateHelper.timeStringFormat(item.createdAt),
                }),
                [HOVER_COLUMNS.flowName]: (
                  <FlowNameView
                    flowName={item?.flowName || ""}
                    flowId={item.flowIdentifier}
                  />
                ),
                [HOVER_COLUMNS.name]: (
                  <NameView
                    id={item.identifier}
                    name={item.name}
                    documentStore={documentStore}
                    flowId={item.flowIdentifier}
                    onClick={() => setCurrentPageAndCheckedDocuments()}
                  />
                ),
                numberOfPages: (
                  <Typography className={classes.numberOfPages}>
                    {item.pageCount}
                  </Typography>
                ),
                status: (
                  <StatusBox
                    status={item.status}
                    page={page}
                    identifier={item.identifier}
                    applyFilters={setCurrentPage}
                    documentStore={documentStore}
                  />
                ),
                ...item.values,
              };
            }) as unknown as TableContentDef[])
          : [],
      [
        id,
        checkPermissions,
        documents.documents,
        selectedDocuments,
        documentStore,
        classes.numberOfPages,
        page,
        setCurrentPage,
        setCurrentPageAndCheckedDocuments,
      ]
    );

    const indexOfLastSeen = useMemo(() => {
      if (documentStore.lastSeenDocument) {
        return (formattedData as unknown as TableContentDef[]).findIndex(
          (item) => item.identifier === documentStore.lastSeenDocument
        );
      }
      return -1;
    }, [documentStore.lastSeenDocument, formattedData]);

    return (
      <>
        <Box className={classes.container}>
          <CoreTable
            columnWidth={COL_WIDTH}
            headers={tableHeaders}
            isLoading={documentStore.loadingDocuments}
            data={(formattedData as unknown as TableContentDef[]) || []}
            numberOfPages={documents.amountOfPages}
            rowsPerPage={rowsPerPage}
            totalCount={documents.totalDocuments}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={PAGE_SIZES}
            noVisibleCells={3}
            currentPage={page}
            changePage={handleChangePage}
            handleSorting={handleSort}
            customRowStyles={{
              [indexOfLastSeen]: {
                backgroundColor: theme.palette.surface.dark,
              },
            }}
          />
        </Box>

        <DocumentsMenuAction
          anchor={menuOptionsAnchorEl}
          onClose={() => setMenuOptionsAnchor(null)}
          flowId={flowId}
          t={t}
        />

        <DocumentsActions
          t={t}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          isMoveDialogOpen={isMoveDialogOpen}
          setIsMoveDialogOpen={setIsMoveDialogOpen}
          handleMove={handleMove}
          handleDelete={handleDelete}
          isMovingDocs={isMovingDocs}
          isDeleteLoading={isDeleteLoading}
          setFilters={setFilters}
          refreshData={refreshData}
        />
      </>
    );
  }
);
