import React, { useState } from "react";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { useTheme, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import Logo from "../../../../icons/Logo";
import CoreInput from "../../../core/CoreInput";
import CoreButton from "../../../core/CoreButton";
import { AppSuspense } from "../../../main/AppSuspense";
import { useStores } from "../../../../stores/StoresProvider";
import { authRoutes } from "../../../../configs/routes";
import CoreTooltip from "../../../core/CoreTooltip";
import Languages from "../../../main/sidebar/account/Languages";

const EnvironmentCreation = observer(() => {
  const theme = useTheme();
  const { t, ready } = useTranslation("landingPages");
  const client = useApolloClient();
  const history = useHistory(); // Using useHistory

  const useStyle = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    container: {
      backgroundColor: theme.palette?.background.paper,
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    content: {
      width: 350,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "46px",
    },
    typography: {
      color: theme.palette?.highlight.light,
      margin: "40px 0",
    },
    buttonStyle: {
      borderColor: theme.palette?.buttons.main,
      textDecoration: "none",
    },
    input: {
      width: "100%",
    },
    logo: {
      width: 200,
      height: 25,
    },
    inputContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    buttonsContainer: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    infoIcon: {
      marginLeft: 1,
    },
  });

  const classes = useStyle();

  const { landingStore, userStore } = useStores();

  const { userRegistration, setUserRegistration, goBack, goNext } =
    landingStore;

  const [errorMessage, setErrorMessage] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    const newValue = event.target.value;
    setUserRegistration({
      ...userRegistration,
      [event.target.name]: newValue,
    });
  };

  const handleLink = () => {
    const regex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    if (regex.exec(userRegistration.namespace)) {
      goNext();
    }
    setErrorMessage(true);
  };

  const isButtonDisabled = userRegistration.namespace.trim() === "";

  const handleGoBack = () => {
    goBack();
  };

  const handleGoBackGoogleLogin = async () => {
    history.push(authRoutes.Login()); // Using navigate
    landingStore.resetFields();

    userStore.setUsedEmailDifferentProvider(undefined);

    await client.resetStore();
  };

  if (!ready) {
    return <AppSuspense />;
  }

  const tooltipMessage = (
    <>
      <div>{t("charactersLimit")}</div> <div>{t("tooltipMessage")}</div>
    </>
  );

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Logo fullLogoClassName={classes.logo} />
          <Typography variant="body2" className={classes.typography}>
            {t("environmentTitle")}
          </Typography>
          <Box className={classes.inputContainer}>
            <CoreInput
              placeholder={t("environmentNamespaceName")}
              className={classes.input}
              size="small"
              onChange={handleChange}
              value={userRegistration.namespace}
              name="namespace"
              errorText={errorMessage ? t("invalidNamespaceName") : ""}
            />
            <CoreTooltip title={tooltipMessage} placement="right" arrow>
              <InfoIcon fontSize="small" className={classes.infoIcon} />
            </CoreTooltip>
          </Box>
          <Box className={classes.buttonsContainer}>
            {userStore.user ? (
              <CoreButton
                onClick={() => {
                  history.push(authRoutes.Namespaces());
                  setUserRegistration({
                    ...userRegistration,
                    namespace: "",
                  });
                }}
              >
                {t("goToNamespaces")}
              </CoreButton>
            ) : landingStore.userRegistration.isGoogleLogin ? (
              <CoreButton onClick={handleGoBackGoogleLogin}>
                {t("goBack")}
              </CoreButton>
            ) : (
              <CoreButton onClick={handleGoBack}>{t("goBack")}</CoreButton>
            )}
            <CoreButton
              className={classes.buttonStyle}
              variant="contained"
              disabled={isButtonDisabled}
              onClick={handleLink}
            >
              {t("nextStep")}
            </CoreButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default EnvironmentCreation;
