import { gql } from "@apollo/client";
import {
  ConnectionField,
  Connection,
  User,
  NamespaceRole,
  ContextSetting,
  ConnectionType,
} from "../../types/interfaces";

export interface QUERY_GET_ALL_USERS_RESPONSE {
  getUsers: { count: number; rows: User[] };
}

export interface QUERY_GET_USER_FROM_CONTEXT_RESPONSE {
  getUserFromContext: User;
}

export interface QUERY_GET_ALL_CONNECTIONS_RESPONSE {
  getConnections: {
    connections: Connection[];
  };
}

export interface QUERY_GET_CONNECTION_RESPONSE {
  getConnection: {
    connection: Connection;
  };
}

export interface QUERY_GET_ALL_ENGINE_CONNECTIONS_RESPONSE {
  getConnectionTypes: { conn_types: ConnectionType[] };
}

export interface QUERY_GET_ENGINE_CONNECTION_RESPONSE {
  getConnectionConfig: {
    config: ConnectionField;
  };
}

export interface QUERY_CHECK_IF_USER_HAS_ACCOUNT_RESPONSE {
  checkIfEmailIsUsed: boolean;
}

export interface QUERY_SEND_RESET_PASSWORD_LINK_RESPONSE {
  sendResetPasswordLink: boolean;
}

export interface QUERY_GET_NAMESPACES_RESPONSE {
  getNamespaces: {
    count: number;
    rows: NamespaceRole[];
  };
}

export interface QUERY_GET_NAMESPACE_FROM_CONTEXT_RESPONSE {
  getNamespaceFromContext: NamespaceRole;
}

export interface QUERY_GENERATE_INVITE_LINK_RESPONSE {
  getInvitationLink: string;
}

export interface QUERY_GET_INVITATION_LINK_DATA_RESPONSE {
  getInvitationLinkData: { redirect: string; data: JSON };
}

export interface QUERY_SEND_INVITATION_LINK {
  sendInvitationLink: boolean;
}

export interface QUERY_CHECK_INVITATION_LINK_EMAIL {
  checkInvitationLinkEmail: boolean;
}

export interface QUERY_RESEND_ACTIVATION_LINK_EMAIL {
  resendActivationLink: boolean;
}

export interface QUERY_VERIFY_TOKEN_RECAPTCHA_RESPONSE {
  verifyTokenRecaptcha: boolean;
}

export interface QUERY_GET_CONTEXT_SETTING {
  getContextSetting: ContextSetting;
}

export const CHECK_IF_USER_HAS_ACCOUNT = gql`
  query checkIfEmailIsUsed($email: String!) {
    checkIfEmailIsUsed(email: $email)
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  query sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(email: $email)
  }
`;

export const GET_ALL_USERS = gql`
  query getUsers(
    $orderBy: [String]
    $limit: Int
    $offset: Int
    $filter: String
    $scope: String
    $fieldsToSearch: [String]
  ) {
    getUsers(
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      filter: $filter
      scope: $scope
      fieldsToSearch: $fieldsToSearch
    ) {
      count
      rows {
        first_name
        last_name
        id
        email
        scope
        is_active
      }
    }
  }
`;

export const GET_ALL_CONNECTIONS = gql`
  query getConnections($onlyStorage: Boolean) {
    getConnections(onlyStorage: $onlyStorage) {
      connections {
        identifier
        name
        type
        description
      }
    }
  }
`;

export const GET_ALL_ENGINE_CONNECTIONS = gql`
  query getConnectionTypes {
    getConnectionTypes {
      conn_types {
        type
        name
        icon
      }
    }
  }
`;

export const GET_CONNECTION = gql`
  query getConnection($id: String) {
    getConnection(id: $id) {
      connection
    }
  }
`;

export const GET_ENGINE_CONNECTION = gql`
  query getConnectionConfig($id: String) {
    getConnectionConfig(id: $id) {
      config
    }
  }
`;

export const GET_USER_FROM_CONTEXT = gql`
  query getUserFromContext {
    getUserFromContext {
      id
      email
      first_name
      last_name
      default_ns
      oauth_id
    }
  }
`;

export const GET_NAMESPACES = gql`
  query getNamespaces($orderBy: [String], $limit: Int, $offset: Int) {
    getNamespaces(orderBy: $orderBy, limit: $limit, offset: $offset) {
      count
      rows {
        namespace {
          id
          name
        }
        user {
          id
          default_ns
        }
        is_active
        is_deleted
      }
    }
  }
`;

export const GET_NAMESPACE_FROM_CONTEXT_RESPONSE = gql`
  query getNamespaceFromContext {
    getNamespaceFromContext {
      scope
      namespace {
        id
        name
      }
      user {
        id
      }
    }
  }
`;

export const GENERATE_INVITE_LINK = gql`
  query getInvitationLink($tokenPayload: JSON!) {
    getInvitationLink(tokenPayload: $tokenPayload)
  }
`;

export const GET_INVITATION_LINK_DATA = gql`
  query getInvitationLinkData($token: String!) {
    getInvitationLinkData(token: $token)
  }
`;

export const SEND_INVITATION_LINK = gql`
  query sendInvitationLink($email: String!, $perms: JSON!) {
    sendInvitationLink(email: $email, perms: $perms)
  }
`;

export const CHECK_INVITATION_LINK_EMAIL = gql`
  query checkInvitationLinkEmail($email: String!) {
    checkInvitationLinkEmail(email: $email)
  }
`;

export const RESEND_ACTIVATION_LINK_EMAIL = gql`
  query resendActivationLink($email: String!) {
    resendActivationLink(email: $email)
  }
`;

export const VERIFY_TOKEN_RECAPTCHA = gql`
  query verifyTokenRecaptcha($token: String!) {
    verifyTokenRecaptcha(token: $token)
  }
`;

export const GET_CONTEXT_SETTING = gql`
  query getContextSetting($setting: String!) {
    getContextSetting(setting: $setting)
  }
`;
