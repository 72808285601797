// Modules imports
import React, { useMemo } from "react";
import { observer } from "mobx-react";

import {
  ApolloLink,
  ApolloProvider,
  ApolloClient,
  Observable,
  NormalizedCacheObject,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createUploadLink } from "apollo-upload-client";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { PaletteMode, ThemeProvider } from "@mui/material";

// Internal imports
import AppBody from "./components/main/AppBody";
import { ErrorBoundary } from "./ErrorBoundary";
import { getCurrentTheme } from "./theming/initTheme";
import { NotificationProvider } from "./context/useNotification";
import { StoresContext, createStore } from "./stores/StoresProvider";

import {
  fetchAccessToken,
  getAccessToken,
  setAccessToken,
  isTokenValidOrUndefined,
} from "./helper/authHelper";

import { backendRoutes, getWebSocketUrl } from "./configs/backendRoutes";
import { ConditionalWrapper } from "./components/core/CoreConditionalWrapper";

import "./i18n";
import "./App.css";
import { ReactFlowProvider } from "reactflow";

interface Subscription {
  closed: boolean;
  unsubscribe(): void;
}

const ACCESS_TOKEN_FIELD = "accessToken";

// Create token-request link
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: Subscription;

      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: ACCESS_TOKEN_FIELD, // Move to constants
  isTokenValidOrUndefined: async () =>
    Promise.resolve(isTokenValidOrUndefined()),
  fetchAccessToken: fetchAccessToken,
  handleFetch: setAccessToken,
});

const terminatingLink = createUploadLink({
  uri: backendRoutes.GraphQLUrl(),
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWebSocketUrl(),
    retryAttempts: Infinity,
    lazy: true,
    shouldRetry: () => true,
  })
);

const links = ApolloLink.from([tokenRefreshLink, requestLink, terminatingLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  links
);

// Init apollo client
const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: splitLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  cache: new InMemoryCache({ addTypename: false }),
});

const rootStore = createStore(apolloClient);

const App: React.FC = observer(() => {
  const { mainStore } = rootStore;

  const currentTheme = useMemo(
    () => getCurrentTheme(mainStore.currentTheme as PaletteMode),
    [mainStore.currentTheme]
  );

  return (
    <ConditionalWrapper
      condition={mainStore.settings?.googleClientId !== undefined}
      wrapper={(children: React.ReactElement) => (
        <GoogleOAuthProvider
          clientId={mainStore.settings?.googleClientId as string}
        >
          {children}
        </GoogleOAuthProvider>
      )}
    >
      <StoresContext.Provider value={rootStore}>
        <ThemeProvider theme={currentTheme}>
          <NotificationProvider>
            <ApolloProvider client={apolloClient}>
              <ErrorBoundary>
                <ReactFlowProvider>
                  <AppBody />
                </ReactFlowProvider>
              </ErrorBoundary>
            </ApolloProvider>
          </NotificationProvider>
        </ThemeProvider>
      </StoresContext.Provider>
    </ConditionalWrapper>
  );
});

export default App;
