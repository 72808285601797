import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  alpha,
} from "@mui/material";

import { useStores } from "../../../../../stores/StoresProvider";
import DateHelper from "../../../../../helper/dateHelper";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const DocumentInformationsPopup: FC<Props> = observer(
  ({ isOpen, setIsOpen }) => {
    const theme = useTheme();
    const classes = makeStyles({
      title: {
        fontWeight: "bolder",
        fontSize: "18px",
      },
      content: {
        "&.MuiDialogContent-root": {
          alignSelf: "stretch",
        },
        padding: "0 40px 20px 40px",
      },
      dialogPaper: {
        "& .MuiDialog-paper": {
          padding: 0,
        },
      },
      divider: {
        marginRight: "1rem",
        marginLeft: "1rem",
      },
      iconButton: {
        position: "absolute",
        right: 8,
        top: 8,
      },
      firstCell: {
        borderBottom: "none",
        textAlign: "right",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "0 20px 10px 0",
        fontSize: "0.8rem",
        opacity: "0.7",
      },
      secondCell: {
        borderBottom: "none",
        padding: "0 0 10px 0",
        width: "100%",
        wordBreak: "break-all",
      },
      firstMetadataCell: {
        borderBottom: "none",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "0 15px 0 0",
        fontSize: "0.8rem",
        opacity: "0.7",
        height: "25px",
      },
      secondMetadataCell: {
        borderBottom: "none",
        padding: "0",
        width: "100%",
        fontSize: "0.8rem",
        height: "25px",
      },
      tableCell: {
        borderBottom: "none",
        fontSize: "20px",
        minWidth: "130px",
        opacity: "0.7",
      },
      headBorder: {
        borderBottom: `0.5px solid ${alpha(theme.palette.divider, 0.1)}`,
      },
      message: {
        fontWeight: "bold",
        wordBreak: "break-all",
      },
    })();

    const { documentStore } = useStores();
    const { t } = useTranslation("document-validate");

    const mainInfoContent = [
      {
        key: t("identifier"),
        value: documentStore.document?.identifier,
      },
      {
        key: t("name"),
        value: documentStore.document?.name,
      },
      {
        key: t("status"),
        value: `${t(documentStore.document?.status.type as string)}`,
      },
      {
        key: t("message"),
        value: documentStore.document?.status.details.message,
      },
      {
        key: t("createdAt"),
        value: DateHelper.timeStringFormat(documentStore.document?.createdAt),
      },
      {
        key: t("finishedAt"),
        value: documentStore.document?.finishedAt
          ? DateHelper.timeStringFormat(documentStore.document?.finishedAt)
          : "-",
      },
    ];

    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={classes.dialogPaper}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{t("docInfoTitle")}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpen(false)}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider className={classes.divider} />

        <DialogContent className={classes.content}>
          <Table>
            <TableHead className={classes.headBorder}>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Typography>{t("mainInfo")}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {mainInfoContent.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.firstCell}>
                      {item.key}
                    </TableCell>
                    <TableCell className={classes.secondCell}>
                      {item.key === t("message") && item.value ? (
                        <Typography className={classes.message}>
                          {item.value}
                        </Typography>
                      ) : (
                        item.value ?? "-"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {Object.keys(documentStore.document?.metadata || {}).length > 0 && (
            <Table>
              <TableHead className={classes.headBorder}>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography>{t("metadata")}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(documentStore.document?.metadata || {}).map(
                  (key, index) => {
                    const value = documentStore?.document?.metadata?.[key];
                    const formattedValue =
                      typeof value === "object" ? (
                        <Table>
                          <TableBody>
                            {Object.keys(value).map((item, i) => {
                              const tableValue = value[item] as
                                | string
                                | boolean
                                | number;
                              return (
                                <TableRow key={i}>
                                  <TableCell
                                    className={classes.firstMetadataCell}
                                  >
                                    {item}
                                  </TableCell>
                                  <TableCell
                                    className={classes.secondMetadataCell}
                                  >
                                    {tableValue ? tableValue.toString() : "-"}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        value?.toString()
                      );

                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.firstCell}>
                          {key}
                        </TableCell>
                        <TableCell className={classes.secondCell}>
                          {formattedValue ? formattedValue : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    );
  }
);

export default DocumentInformationsPopup;
