import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { TFunction } from "i18next";

// Material ui
import {
  styled,
  Theme,
  CSSObject,
  alpha,
  useTheme,
} from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { useStores } from "../../stores/StoresProvider";
import {
  APP_IDS,
  APP_SIDEBAR_WIDTH,
  DRAWER_STATE,
} from "../../types/constants";
import SidebarButtons from "./sidebar/SidebarButtons";

interface Props {
  t: TFunction;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: APP_SIDEBAR_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== DRAWER_STATE.open,
})(({ theme, open }) => ({
  width: APP_SIDEBAR_WIDTH,
  flexShrink: 0,
  height: "100%",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppSidebar: FC<Props> = observer(({ t }) => {
  const { mainStore } = useStores();
  const theme = useTheme();

  // Update current path in store for selection
  const { pathname } = useLocation();
  useEffect(() => {
    mainStore.setCurrentPage(pathname);
  }, [mainStore, pathname]);

  const classes = makeStyles({
    paper: {
      top: "65px",
      borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    list: {
      marginTop: "5px",
      height: "calc(100% - 65px)",
      display: "flex",
      flexDirection: "column",
    },
  })();

  return (
    <Box>
      <Drawer
        variant="permanent"
        open={mainStore.expandedSidebar}
        classes={{ paper: classes.paper }}
        id={APP_IDS.sidebar}
      >
        <List className={classes.list}>
          <SidebarButtons t={t} />
        </List>
      </Drawer>
    </Box>
  );
});
export default AppSidebar;
