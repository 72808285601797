import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography, useTheme, alpha } from "@mui/material";
import OutlinedFlagOutlinedIcon from "@mui/icons-material/OutlinedFlagOutlined";
import BugReportIcon from "@mui/icons-material/BugReport";

import ReportIssuePopup from "./ReportIssuePopup";

interface Props {
  isTopBar: boolean;
  documentId?: string;
}

const ReportIssueButton: React.FC<Props> = ({ documentId, isTopBar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("flow");
  const theme = useTheme();

  const useStyles = makeStyles({
    link: {
      fontSize: "14px",
      color: alpha(theme.palette.highlight.main, 0.8),
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    topBarReport: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textStyle: {
      fontSize: "14px",
      color: alpha(theme.palette.highlight.main, 0.8),
    },
  });

  const classes = useStyles();

  return (
    <>
      {isTopBar ? (
        <Box className={classes.topBarReport} onClick={() => setIsOpen(true)}>
          <BugReportIcon />
        </Box>
      ) : (
        <Box className={classes.link} onClick={() => setIsOpen(true)}>
          <OutlinedFlagOutlinedIcon
            style={{ color: alpha(theme.palette.highlight.main, 0.8) }}
          />
          <Typography className={classes.textStyle}>
            {t("reportIssueButton")}
          </Typography>
        </Box>
      )}

      <ReportIssuePopup
        t={t}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isTopBar={isTopBar}
        documentId={documentId}
        theme={theme}
      />
    </>
  );
};

export default ReportIssueButton;
