import React, { FC, useState } from "react";
import { TFunction } from "i18next";

import { Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CoreCard } from "../../../../../../core/CoreCard";
import { ContactSalesDrawer } from "../../ContactSalesDrawer";
import CoreButton from "../../../../../../core/CoreButton";

const useStyles = makeStyles({
  description: {
    opacity: 0.6,
    fontSize: "12px",
    marginTop: "10px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionsContainer: {
    margin: "auto",
  },
});

const NoSubscriptionCard: FC<{
  t: TFunction;
}> = ({ t }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <CoreCard>
        <Grid container>
          <Grid item flex="1">
            <Box className={classes.title}>
              <Typography>{t("noActiveSubscription")}</Typography>
            </Box>

            <Typography className={classes.description}>
              {t("contactOrActivate")}
            </Typography>
          </Grid>

          <Grid item className={classes.actionsContainer}>
            <CoreButton variant="outlined" onClick={() => setDrawerOpen(true)}>
              {t("contact_sales_action_label")}
            </CoreButton>
          </Grid>
        </Grid>
      </CoreCard>

      <ContactSalesDrawer
        isOpen={isDrawerOpen}
        planId=""
        planName={t("noActiveSubscriptionTitle")}
        onClose={() => {
          setDrawerOpen(false);
        }}
      />
    </>
  );
};

export default NoSubscriptionCard;
