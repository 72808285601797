import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Typography, Box, Divider } from "@mui/material";

import CoreTable from "../../core/CoreTable";
import { CoreStatusTag } from "../../core/CoreStatusTag";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import { TableContentDef, TableHeaderDef } from "../../../types/interfaces";
import { ApiKey } from "../../../stores/queries/apiKey";
import { DocumentStages } from "../../../types/enums";
import { ApiKeyTableActions } from "./ApiKeyTableActions";
import CoreConfirmModal from "../../core/CoreConfirmModal";

const useStyles = makeStyles({
  divider: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  noData: {
    opacity: 0.6,
    fontStyle: "italic",
    fontSize: "15px",
    marginBottom: "20px",
  },
  dataTable: {
    marginBottom: "20px",
    marginTop: "20px",
  },
});

const ApiKeyContent: React.FC<{
  apiKeys: ApiKey[];
  isTableLoading: boolean;
  refreshData: () => void;
}> = ({ apiKeys, isTableLoading, refreshData }) => {
  const classes = useStyles();
  const { t } = useTranslation("apikeys");
  const { userStore } = useStores();
  const notification = useNotification();

  // Key operations state management
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState({
    open: false,
    activate: false,
  });

  const [currentSelectedKey, setCurrentSelectedKey] = useState("");
  const [isOperationLoading, setIsOperationLoading] = useState(false);

  const hasApiKeys = apiKeys && apiKeys?.length > 0;

  const formattedHeaders = useMemo(
    () =>
      [
        { accessor: "identifier", label: t("tableKeyHeader") },
        { accessor: "active", label: t("tableIsActiveHeader") },
        { accessor: "actions", label: t("tableIsActionsHeader") },
      ] as TableHeaderDef[],
    [t]
  );

  const handleDeleteIconClick = (apiKey: string) => {
    setCurrentSelectedKey(apiKey);
    setDeleteModalOpen(true);
  };

  const handleActivateIconClick = (apiKey: string, isActive: boolean) => {
    setCurrentSelectedKey(apiKey);
    setActivateModalOpen({
      open: true,
      activate: !isActive,
    });
  };

  const handleDeleteAction = async () => {
    setIsOperationLoading(true);

    await userStore
      .deleteApiKey(currentSelectedKey)
      .then(() => {
        setDeleteModalOpen(false);
        notification.success(t("deleteApiKeySuccess"));
        refreshData();
      })
      .catch(() => {
        notification.error(t("deleteApiKeyFail"));
      })
      .finally(() => {
        setIsOperationLoading(false);
      });
  };

  const handleActivateAction = async () => {
    setIsOperationLoading(true);
    const isActivatingKey = activateModalOpen.activate;

    const currentOperation = isActivatingKey
      ? userStore.activateApiKey(currentSelectedKey)
      : userStore.deactivateApiKey(currentSelectedKey);

    await currentOperation
      .then(() => {
        setActivateModalOpen({ ...activateModalOpen, open: false });
        notification.success(
          isActivatingKey
            ? t("activateModalApiKeySuccess")
            : t("deactivateModalApiKeySuccess")
        );
        refreshData();
      })
      .catch(() => {
        notification.error(
          isActivatingKey
            ? t("activateModalApiKeyFail")
            : t("deactivateModalApiKeyFail")
        );
      })
      .finally(() => {
        setIsOperationLoading(false);
      });
  };

  const formattedData = useMemo(
    () =>
      (apiKeys && apiKeys?.length > 0
        ? [...apiKeys].reverse().map((apiKey) => ({
            ...apiKey,
            active: (
              <CoreStatusTag
                label={t(
                  apiKey.active ? "tableIsActiveFlag" : "tableIsInactiveFlag"
                )}
                type={apiKey.active ? DocumentStages.DONE : ""}
              />
            ),
            actions: (
              <ApiKeyTableActions
                t={t}
                identifier={apiKey.identifier}
                isActive={apiKey.active}
                onDeleteToggle={(apiKey: string) =>
                  handleDeleteIconClick(apiKey)
                }
                onActivateToggle={(identifier: string) =>
                  handleActivateIconClick(identifier, apiKey.active)
                }
              />
            ),
          }))
        : []) as unknown as ApiKey[],
    [apiKeys, t]
  );

  return (
    <>
      <Typography variant="h6">{t("apiKeyTitle")}</Typography>

      <Divider className={classes.divider} />

      {!hasApiKeys ? (
        <Typography className={classes.noData}>{t("noApiKeyFound")}</Typography>
      ) : (
        <>
          <Typography>{t("keyLabel")}</Typography>
          <Box className={classes.dataTable}>
            <CoreTable
              isPaginated={false}
              headers={formattedHeaders}
              isLoading={isTableLoading}
              data={formattedData as unknown as TableContentDef[]}
            />
          </Box>
        </>
      )}

      <CoreConfirmModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={t("deleteModal_title")}
        subtitle={t("deleteModal_subtitle")}
        cancelButtonLabel={t("deleteModal_cancelButton")}
        confirmButtonLabel={t("deleteModal_confirmButton")}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteAction}
        isDisable={isOperationLoading}
      />

      <CoreConfirmModal
        open={activateModalOpen.open}
        onClose={() =>
          setActivateModalOpen({ ...activateModalOpen, open: false })
        }
        title={
          activateModalOpen.activate
            ? t("activateModal_title")
            : t("deactivateModal_title")
        }
        subtitle={
          activateModalOpen.activate
            ? t("activateModal_subtitle")
            : t("deactivateModal_subtitle")
        }
        cancelButtonLabel={t("deleteModal_cancelButton")}
        confirmButtonLabel={
          activateModalOpen.activate
            ? t("activateModal_confirmButton")
            : t("deactivateModal_confirmButton")
        }
        onCancel={() =>
          setActivateModalOpen({ ...activateModalOpen, open: false })
        }
        onConfirm={handleActivateAction}
        isDisable={isOperationLoading}
      />
    </>
  );
};

export default ApiKeyContent;
