/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeAutoObservable, runInAction } from "mobx";
import { AxiosInstance } from "axios";
import { ApolloClient } from "@apollo/client";
import { GraphQLError, GraphQLErrorExtensions } from "graphql";
import _get from "lodash/get";
import { uniqBy } from "lodash";
import {
  AbilityBuilder,
  AbilityTuple,
  ExtractSubjectType,
  MongoAbility,
  MongoQuery,
  PureAbility,
  Subject,
  SubjectRawRule,
} from "@casl/ability";

import {
  Flow,
  FlowField,
  RejectionInterface,
  FlowTemplate,
  AssetConfiguration,
  FormData as FormDataInterface,
  ConnectionField,
  StorageOptions,
  FlowFilters,
  DocumentsFilterProps,
  FlowSummary,
  Ability,
  AssetType,
  BackendAlgorithmsOptions,
} from "../types/interfaces";

import {
  GET_FLOW,
  GET_FLOW_SUMMARY,
  GET_ALL_FLOWS,
  GET_FLOW_TEMPLATES,
  GET_ALL_REJECTIONS,
  GET_FLOWS_TYPE,
  GET_REJECTIONS_TYPE,
  GET_FLOW_TYPE,
  GET_ASSET_LIST,
  GET_ASSET_LIST_TYPE,
  GET_ASSET_CONFIG,
  GET_ASSET_CONFIG_TYPE,
  GET_TEST_DATA_EXPORT,
  DATA_EXPORT_TEST,
  GET_TOKENS_ASSETS,
  GET_TOKENS,
  GET_CONNECTION_FILES,
  GET_CONNECTION_FILES_QUERY,
  GET_FLOW_SUMMARY_TYPE,
  GET_FLOW_FILTER_KEYS_TYPE,
  GET_FLOW_FILTER_KEYS,
  GET_FLOW_CATEGORY_DATA_QUERY,
  GET_FLOW_CATEGORY_DATA,
  GET_DIAGRAM_ASSET_LIST_TYPE,
  GET_DIAGRAM_ASSET_LIST,
} from "./queries/flows";
import {
  DELETE_FLOW,
  GET_FIELDS_TYPE,
  DELETE_FLOW_TYPE,
  ADD_REJECTION_TYPE,
  ADD_REJECTION,
  EXECUTE_FLOW_DATA_TYPE_ACTION,
  EXECUTE_FLOW_DATA_TYPE_ACTION_MUTATION,
  ADD_FLOW_CATEGORY_DATA,
  ADD_FLOW_CATEGORY_DATA_MUTATION,
  EDIT_FLOW_CATEGORY_DATA,
  EDIT_FLOW_CATEGORY_DATA_MUTATION,
  REPORT_ISSUE_MUTATION,
  REPORT_ISSUE_RESPONSE,
} from "./mutations/flows";
import FlowHelper from "../helper/flowHelper";
import { backendRoutes } from "../configs/backendRoutes";
import { RootStore } from "./StoresProvider";
import {
  ASSET_TYPES,
  ASSET_PROPERTIES,
  DEFAULT_FILTER_FLOW,
  DEFAULT_FILTER_ALL_FLOWS,
  ASSET_BACKEND_TYPES,
} from "../types/constants";
import { FlowDataCategoryItem } from "../types/interfaces/flow";

export class FlowStore {
  root: RootStore;
  flows: Flow[] = [];
  flowTemplates: FlowTemplate[] = [];
  flow: Flow | undefined;
  flowSummary: FlowSummary | null = null;
  flowFields: FlowField[] = [];
  rejections: RejectionInterface[] = [];
  dataAlgorithmGroups: AssetType[] = [];
  expandedCardFlow: {
    [key: string]: boolean;
  } = {};
  // Loaders
  isDocumentUploading = false;
  isFlowDetailsLoading = false;
  isFlowSummaryLoading = false;
  loadingFields = true;
  loadingFlows = true;
  loadingFlowCreateUpdate = false;
  loadingRejections = true;
  refetchForChatGptFilters = {};
  filters: FlowFilters | undefined;
  //Access Users
  flowRestrictedUsers: Record<string, string> | null = null;
  currentFlowPermissions?: MongoAbility<AbilityTuple>;

  setFlowRestrictedUsers = (users: Record<string, string>): void => {
    this.flowRestrictedUsers = users;
  };

  // Popups controls
  isRejectionModalOnEdit: string | null = null;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  toggleRejectionModal = (value: string | null) => {
    this.isRejectionModalOnEdit = value;
  };

  setFlow = (value: Flow) => {
    this.flow = value;
  };

  setRefetchForChatGptFilters = () => {
    this.refetchForChatGptFilters = {};
  };

  setFiltersValues = (value: DocumentsFilterProps | null, flowId?: string) => {
    this.filters = {
      flowId: flowId
        ? flowId
        : this.filters?.flowId
        ? this.filters?.flowId
        : undefined,
      flowFilters: value ? value : undefined,
    };
  };

  setExpandedCardFlow = (config: { [key: string]: boolean }) => {
    this.expandedCardFlow = config;
  };

  setDataAlgorithmGroups = (value: AssetType[]) => {
    this.dataAlgorithmGroups = value;
  };

  setFlowSummary = (value: FlowSummary | null) => {
    this.flowSummary = value;
  };

  resetCurrentFlow = () => {
    // Reset previous setting
    this.flow = undefined;
    this.flowFields = [];
    this.isFlowDetailsLoading = false;
    this.flowSummary = null;
    this.flowRestrictedUsers = null;
  };

  setFlowTemplate = (selectedTemplate: FlowTemplate | undefined) => {
    if (!selectedTemplate) {
      return;
    }

    const { dataExport, dataSource, fields, ...templateProps } =
      selectedTemplate;

    const newFlow = {
      ...(this.flow || {}),
      fields: FlowHelper.getUniqueFields(this.flow?.fields, fields),
      template: templateProps,
      dataSource: {
        sources:
          [
            ...FlowHelper.setTemplateAssetDefaults(dataSource?.sources || []),
            ...FlowHelper.filterDefaultAssets(
              this.flow?.dataSource.sources || []
            ),
          ] || [],
        fields: {
          ...(dataSource?.fields || {}),
        },
      },
      dataExport: {
        dataExports: [
          ...FlowHelper.setTemplateAssetDefaults(dataExport?.dataExports || []),
          ...FlowHelper.filterDefaultAssets(
            this.flow?.dataExport.dataExports || []
          ),
        ],
        fields: {
          ...(dataExport?.fields || {}),
        },
      },
    } as Flow;

    this.setFlow(newFlow);
  };

  loadFlowTemplates = async (client: ApolloClient<unknown>) => {
    this.loadingFields = true;

    try {
      const response = await client.query<GET_FIELDS_TYPE>({
        query: GET_FLOW_TEMPLATES,
      });

      const {
        data: { getFlowTemplates },
        errors,
      } = response as unknown as {
        data: { getFlowTemplates: FlowTemplate[] };
        errors: GraphQLError[];
      };

      if (!getFlowTemplates || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.flowTemplates = getFlowTemplates || [];
        this.loadingFields = false;

        // Used for create flow
      });
    } catch (error) {
      runInAction(() => {
        this.loadingFields = false;
      });
      throw error;
    }
  };

  getAllFlows = async (
    silent = false,
    resetFilters = true,
    loadDocuments = false
  ) => {
    if (!silent) this.loadingFlows = true;

    try {
      // If filters are set for another flow, reset them
      if (
        resetFilters &&
        (!this.filters || (this.filters && !!this.filters?.flowId))
      )
        this.filters = {
          flowId: null,
          flowFilters: DEFAULT_FILTER_ALL_FLOWS,
        };

      const {
        data: { getFlows },
        errors,
      } = await this.root.client.query<GET_FLOWS_TYPE>({
        query: GET_ALL_FLOWS,
      });

      if (!getFlows || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.flows = getFlows;
        this.loadingFlows = false;
      });

      if (loadDocuments) {
        this.root.documentStore.getAllDocuments().catch((err) => {
          throw err;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loadingFlows = false;
      });

      throw error;
    }
  };

  getAllRejections = async (client: ApolloClient<unknown>) => {
    try {
      this.loadingRejections = true;
      const {
        data: { getAllRejections },
        errors,
      } = await client.query<GET_REJECTIONS_TYPE>({
        query: GET_ALL_REJECTIONS,
        variables: {
          identifier: this.flow?.identifier,
        },
      });

      if (!getAllRejections || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.rejections = [...getAllRejections];
        this.loadingRejections = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingRejections = false;
      });
      throw error;
    }
  };

  sendDocuments = async (
    file: File,
    client: AxiosInstance | undefined,
    id: string
  ) => {
    this.isDocumentUploading = true;

    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await client?.post(
        backendRoutes.UploadDocument(id),
        formData
      );

      if (!response) {
        throw new Error();
      }

      runInAction(() => {
        this.isDocumentUploading = false;
      });
      return true;
    } catch (error) {
      runInAction(() => {
        this.isDocumentUploading = false;
      });
      throw error;
    }
  };

  initFlowDraft = (props: Partial<Flow> = {}) => {
    this.flow = {
      identifier: "",
      name: "",
      description: "",
      flowType: "extraction",
      manualValidate: false,
      storage: {
        customRepo: false,
        repoConnection: "",
        // dataRetention: false,
        // deleteLocalFile: false,
        // deleteDoc: false,
        // timeToKeep: 1,
        repoParameters: {},
      },
      dataExport: {
        fields: [],
        dataExports: [],
      },
      dataAlgorithms: {
        algorithms: [],
      },
      dataSource: {
        fields: [],
        sources: [],
      },
      enhancements: [],
      approvals: [],
      categories: [],
      rejectReasons: [],
      access: {},
      ...props,
    };
  };

  reportIssue = async (
    client: ApolloClient<unknown>,
    documentId: string | null,
    message: string
  ) => {
    const { data: reportIssue, errors } =
      await client.mutate<REPORT_ISSUE_RESPONSE>({
        mutation: REPORT_ISSUE_MUTATION,
        variables: {
          documentId,
          message,
        },
      });

    if (!reportIssue || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return reportIssue;
  };

  loadFlowConnectionFields = (
    client: ApolloClient<unknown>,
    storage: StorageOptions
  ) => {
    const connection = this.root.userStore.connections?.find(
      (connection) => connection.identifier === storage.repoConnection
    );

    if (connection) {
      void this.root.storageStore
        .getConnectionFields(connection.type)
        .then((connectionFields) => {
          // Metadata to be used for Review tab
          const metadata: FormDataInterface = {};

          // Initial values for adding fields
          const initialValues: FormDataInterface = {};

          const updatedFields = connectionFields.map((field) => {
            metadata[field.key] = {
              name: field.name,
              isMandatory: field.isMandatory,
            };
            initialValues[field.key] = "";

            if (storage.repoParameters[field.key]) {
              return {
                ...field,
                value: storage.repoParameters[field.key],
              };
            }

            return field;
          });

          this.root.storageStore.setRepositoryFields({
            loadedFieldsId: storage.repoConnection as string,
            fields: updatedFields as ConnectionField[],
            metadata,
          });
        });
    }
  };

  fetchFlowData = async (id: string) => {
    const {
      data: { getFlow },
      errors,
    } = await this.root.client.query<GET_FLOW_TYPE>({
      query: GET_FLOW,
      variables: { id },
    });

    if (!getFlow && errors) {
      if (errors?.[0]) {
        const statusCode = (
          errors?.[0]?.extensions?.exception as GraphQLErrorExtensions
        )?.statusCode;

        if (statusCode === 404) {
          throw new GraphQLError(errors?.[0]?.message, {
            extensions: {
              statusCode: 404,
            },
          });
        } else {
          throw new Error(errors?.[0]?.message);
        }
      } else {
        throw new Error();
      }
    }

    return getFlow;
  };

  loadFlow = async (
    client: ApolloClient<unknown>,
    id: string,
    loadDocuments = false,
    clearAll = false,
    setSingleTemplate = false, // Used when flow is updated (loading all templates is not required)
    isFlowCloned = false, // Used when flow is cloned (flow id is not required)
    resetFilters = true
  ) => {
    try {
      if (clearAll) {
        this.root.documentStore.clearStore();
      }

      this.isFlowDetailsLoading = true;

      // If filters are set for another flow, reset them
      if (
        (!this.filters ||
          !this.filters?.flowId ||
          id !== this.filters?.flowId) &&
        resetFilters
      )
        this.filters = {
          flowId: id,
          flowFilters: DEFAULT_FILTER_FLOW,
        };

      const getFlow = await this.fetchFlowData(id);

      const newAlgorithms = FlowHelper.formatAlgorithmsForLoadFlow(
        getFlow?.dataAlgorithms as unknown as BackendAlgorithmsOptions
      );

      const newDataAlgorithmsGroup =
        FlowHelper.overWriteParametersForDataAlgorithmsGroup(
          this.dataAlgorithmGroups,
          getFlow?.dataAlgorithms as unknown as BackendAlgorithmsOptions
        );

      this.setDataAlgorithmGroups(newDataAlgorithmsGroup);

      runInAction(() => {
        // Do not store large fields object
        this.initFlowDraft({
          identifier: isFlowCloned ? "" : id,
          // If the flow is cloned, remove the connection from the assets
          // This is done for security reasons
          dataSource: {
            ...getFlow.dataSource,
            sources: FlowHelper.formatAssets(
              getFlow.dataSource.sources,
              isFlowCloned
            ),
          },
          dataExport: {
            ...getFlow.dataExport,
            dataExports: FlowHelper.formatAssets(
              getFlow.dataExport.dataExports,
              isFlowCloned
            ),
          },
          dataAlgorithms: newAlgorithms
            ? {
                algorithms: newAlgorithms,
              }
            : undefined,
          approvals: getFlow.approvals ?? [],
          enhancements: getFlow.enhancements ?? [],
          categories: getFlow.categories ?? [],
          fields: getFlow.fields ?? [],
          description: getFlow.description ?? "",
          name: getFlow.name ?? "",
          flowType: getFlow.flowType ?? "extraction",
          storage: getFlow.storage ?? {},
          rejectReasons: getFlow.rejectReasons ?? [],
          template: getFlow.template ?? undefined,
          manualValidate: getFlow.manualValidate ?? false,
        });

        this.flowRestrictedUsers = getFlow.access;

        // Canvas
        //TODO: Remove this
        // this.flowFields = getFlow.fields || [];
        this.isFlowDetailsLoading = false;
        this.loadingFields = false;

        if (setSingleTemplate) {
          this.flowTemplates = getFlow.template ? [getFlow.template] : [];
          this.loadingFields = false;
        }

        if (
          getFlow.storage?.repoParameters &&
          Object.keys(getFlow.storage?.repoParameters)?.length > 0
        ) {
          // Load connection fields
          this.loadFlowConnectionFields(client, getFlow.storage);
        }
      });

      // Load flow documents
      if (loadDocuments) {
        this.root.documentStore.loadFlowDocuments(id, 1).catch((err) => {
          throw err;
        });
      }
    } catch (error) {
      runInAction(() => {
        // Reset previous setting
        this.resetCurrentFlow();
      });
      throw error;
    }
  };

  createAbility = (permissions: Record<string, boolean>) => {
    const { can, build } = new AbilityBuilder(PureAbility);
    Object.keys(permissions).forEach((perm) => can(perm));

    return build();
  };

  getFlowSummary = async (id: string) => {
    const {
      data: { getFlowSummary },
      errors,
    } = await this.root.client.query<GET_FLOW_SUMMARY_TYPE>({
      query: GET_FLOW_SUMMARY,
      variables: { id },
    });

    if (!getFlowSummary && errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getFlowSummary;
  };

  loadFlowSummary = async (id: string) => {
    try {
      this.isFlowSummaryLoading = true;

      // If filters are set for another flow, reset them
      if (
        !this.filters ||
        !this.filters?.flowId ||
        id !== this.filters?.flowId
      ) {
        this.filters = {
          flowId: id,
          flowFilters: DEFAULT_FILTER_FLOW,
        };
      }

      const getFlowSummary = await this.getFlowSummary(id);

      const { currentUserPermissions } = getFlowSummary;

      runInAction(() => {
        this.setAbilityForUser(
          currentUserPermissions ? JSON.stringify(currentUserPermissions) : ""
        );
        this.flowSummary = getFlowSummary;
        this.isFlowSummaryLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        // Reset previous setting
        this.flowSummary = null;
        this.isFlowSummaryLoading = false;
      });
      throw error;
    }
  };

  setAbilityForUser = (userPermissions: string) => {
    if (!userPermissions) {
      this.currentFlowPermissions = new PureAbility([]);
    } else
      this.currentFlowPermissions = new PureAbility(
        JSON.parse(userPermissions) as SubjectRawRule<
          string,
          ExtractSubjectType<Subject>,
          MongoQuery
        >[]
      );
  };

  loadFlowFilterKeys = async (id: string) => {
    const {
      data: { getFlowFilterKeys },
      errors,
    } = await this.root.client.query<GET_FLOW_FILTER_KEYS_TYPE>({
      query: GET_FLOW_FILTER_KEYS,
      variables: { id },
    });

    if (!getFlowFilterKeys && errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getFlowFilterKeys;
  };

  //TODO: To be deleted
  deleteAsset = (type: ASSET_TYPES, itemPosition: number) => {
    const listItems = _get(
      this.flow,
      `${type}.${ASSET_PROPERTIES[type]}`
    ) as unknown as AssetConfiguration[];

    if (this.flow)
      this.setFlow({
        ...(this.flow || {}),
        [type]: {
          ...(this.flow[type] || {}),
          [ASSET_PROPERTIES[type]]: listItems.filter(
            (_, index) => index !== itemPosition
          ),
        },
      });
  };

  exportFlow = async (
    client: AxiosInstance | undefined,
    exportParameters: Record<string, unknown>
  ) => {
    const flowId = this.flowSummary?.identifier ?? "";
    const filters = this.filters?.flowFilters ?? {};
    await client
      ?.post(
        backendRoutes.ExportFlow(),
        {
          flowId,
          filters,
          exportParameters,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        if (response?.status !== 200) {
          throw new Error("exportFlowDocumentsError");
        }

        const extension = exportParameters.exportType as string;
        const url = window.URL.createObjectURL(response.data as Blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = `Flow report - ${flowId}.${extension}`;
        a.click();
      })
      .catch((error: string) => {
        throw new Error(error);
      });
  };

  exportFlowConfig = async (client: ApolloClient<unknown>, flowId: string) => {
    const {
      data: { getFlow },
      errors,
    } = await client.query<GET_FLOW_TYPE>({
      query: GET_FLOW,
      variables: { id: flowId },
    });

    if (!getFlow && errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    const flow = getFlow;
    const flowDiagram = await this.root.flowSettingsStore.getLatestFlowDiagram(
      flowId
    );

    const flowWithoutIdentifier = FlowHelper.removeIdentifiersFromPayload(
      { ...flow },
      "identifier"
    );

    const formattedFlow = {
      ...flowWithoutIdentifier,
      diagram: flowDiagram ?? null,
      //TODO: Check this
      manualValidate: flow.manualValidate ?? false,
    };

    const jsonPayload = JSON.stringify(formattedFlow, null, 2);

    if (jsonPayload) {
      const blob = new Blob([jsonPayload], { type: "application/json" });

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Exported flow - ${flowId}.json`;

      a.click();
      return true;
    }

    return false;
  };

  deleteFlow = async (client: ApolloClient<unknown>, flowId: string) => {
    const response = await client.mutate<DELETE_FLOW_TYPE>({
      mutation: DELETE_FLOW,
      variables: { flowId },
    });

    const {
      data: { deleteFlow },
      errors,
    } = response as unknown as {
      data: { deleteFlow: boolean };
      errors: GraphQLError[];
    };

    if (!deleteFlow || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    const filteredFlows = this.flows
      .filter((flow) => flow.name.toLowerCase())
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

    const inMemoryFlowIndex = filteredFlows.findIndex(
      (flow) => flow.identifier === flowId
    );

    let inMemoryFlowIdentifier = "";
    if (inMemoryFlowIndex > 0) {
      inMemoryFlowIdentifier = filteredFlows[inMemoryFlowIndex - 1]?.identifier;
    }

    runInAction(() => {
      this.root.mainStore.currentPage = inMemoryFlowIdentifier;
      this.flows = this.flows.filter((flow) => flow.identifier !== flowId);
    });

    return inMemoryFlowIdentifier;
  };

  //Draft flow operations

  updateFlowDraft = (props: Partial<Flow>) => {
    this.flow = { ...(this.flow ?? {}), ...props } as Flow;
  };

  //Fields Setup

  addDraftField = (field: FlowField | FlowField[]) => {
    const fields = this.flow?.fields || [];
    const fieldsToAdd = Array.isArray(field) ? field : [field];
    const allUniqueFields = uniqBy([...fields, ...fieldsToAdd], "key");
    this.updateFlowDraft({ fields: allUniqueFields });
  };

  updateLineItemHeaders = (lineItemHeaders: FlowField[]) => {
    const formattedFields = [...(this.flow?.fields || [])]?.map((mainField) => {
      if (mainField.key === this.root.documentStore.focusedFieldCanvas) {
        return {
          ...mainField,
          dataType: {
            ...(mainField?.dataType || {}),
            parameters: {
              ...(mainField?.dataType?.parameters || {}),
              headers: lineItemHeaders,
            },
          },
        };
      }

      return mainField;
    });

    this.flow = {
      ...(this.flow || {}),
      fields: formattedFields,
    } as Flow;
  };

  updateFields = (
    oldFields: FlowField[],
    dataKey: string | undefined,
    formData: FormDataInterface
  ) => {
    const fields = oldFields.map((field) => {
      if (field.key === dataKey) return formData;
      return field;
    }) as FlowField[];

    this.updateFlowDraft({ fields });
  };

  deleteDraftField = (field: FlowField) => {
    const fields = this.flow?.fields || [];

    const filteredFields = fields.filter((f) => f.key !== field.key);

    this.updateFlowDraft({ fields: filteredFields });
  };

  //Asset types

  getAssetList = async (assetType: string, showParams = false) => {
    let asset = assetType;
    if (assetType === ASSET_TYPES.approvals) {
      asset = ASSET_BACKEND_TYPES.approval;
    }
    if (assetType === ASSET_TYPES.enhancements) {
      asset = ASSET_BACKEND_TYPES.enhancement;
    }
    const { data, errors } = await this.root.client.query<GET_ASSET_LIST_TYPE>({
      query: GET_ASSET_LIST,
      variables: { assetType: asset, showParams: showParams },
    });

    if (!data || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return data;
  };

  getDiagramAssetList = async () => {
    const { data, errors } =
      await this.root.client.query<GET_DIAGRAM_ASSET_LIST_TYPE>({
        query: GET_DIAGRAM_ASSET_LIST,
      });

    if (!data || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return data;
  };

  getAssetConfiguration = async (key: string) => {
    const { data, errors } =
      await this.root.client.query<GET_ASSET_CONFIG_TYPE>({
        query: GET_ASSET_CONFIG,
        variables: { key },
      });

    if (!data || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return data;
  };

  dataExportTest = async (
    client: ApolloClient<unknown>,
    config: AssetConfiguration
  ) => {
    const { data, errors } = await client.query<GET_TEST_DATA_EXPORT>({
      query: DATA_EXPORT_TEST,
      variables: { config },
    });

    if (!data || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return true;
  };

  // Tokens

  getAccessAndRefreshTokens = async (
    client: ApolloClient<unknown>,
    code: string,
    type: string
  ) => {
    const {
      data: { getProviderTokens },
      errors,
    } = await client.query<GET_TOKENS_ASSETS>({
      query: GET_TOKENS,
      variables: { type, code },
    });

    if (!getProviderTokens || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getProviderTokens;
  };

  addRejection = async (
    client: ApolloClient<unknown>,
    formData: RejectionInterface
  ) => {
    try {
      const { data, errors } = await client.mutate<ADD_REJECTION_TYPE>({
        mutation: ADD_REJECTION,
        variables: {
          flowId: this.flow?.identifier,
          data: [
            {
              message: formData.message,
              code: formData.code,
              description: formData.description,
            },
          ],
        },
      });
      if (!data || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingRejections = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingRejections = false;
      });

      throw error;
    }
  };

  getConnectionFiles = async (
    client: ApolloClient<unknown>,
    connectionId: string
  ) => {
    const {
      data: { getConnectionFiles },
      errors,
    } = await client.query<GET_CONNECTION_FILES>({
      query: GET_CONNECTION_FILES_QUERY,
      variables: { connectionId },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getConnectionFiles;
  };

  verifyAbility = (permToCheck: Ability) => {
    return FlowHelper.verifyAbility(
      [this.currentFlowPermissions, this.root.userStore.currentUserPermissions],
      permToCheck
    );
  };

  // FLOW CATEGORIES
  getFlowCategoryData = async (type: string, flowId: string) => {
    const {
      data: { getFlowCategoryData },
      errors,
    } = await this.root.client.query<GET_FLOW_CATEGORY_DATA>({
      query: GET_FLOW_CATEGORY_DATA_QUERY,
      variables: { type, flowId },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getFlowCategoryData || [];
  };

  executeFlowDataTypeAction = async (
    flowId: string,
    id: string,
    type: string,
    action: string
  ) => {
    const response =
      await this.root.client.mutate<EXECUTE_FLOW_DATA_TYPE_ACTION>({
        mutation: EXECUTE_FLOW_DATA_TYPE_ACTION_MUTATION,
        variables: {
          flowId: flowId || this.flow?.identifier || "",
          id,
          type,
          action,
        },
      });

    const {
      data: { executeFlowDataTypeAction },
      errors,
    } = response as unknown as {
      data: { executeFlowDataTypeAction: string };
      errors: GraphQLError[];
    };

    if (!executeFlowDataTypeAction || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return true;
  };

  addFlowCategoryData = async (
    flowId: string,
    type: string,
    dataCategory: FlowDataCategoryItem
  ) => {
    const response = await this.root.client.mutate<ADD_FLOW_CATEGORY_DATA>({
      mutation: ADD_FLOW_CATEGORY_DATA_MUTATION,
      variables: {
        flowId: flowId || this.flow?.identifier || "",
        type,
        dataCategory,
      },
    });

    const {
      data: { addFlowCategoryData },
      errors,
    } = response as unknown as {
      data: { addFlowCategoryData: string };
      errors: GraphQLError[];
    };

    if (!addFlowCategoryData || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return true;
  };

  editFlowCategoryData = async (
    flowId: string,
    type: string,
    id: string,
    dataCategory: FlowDataCategoryItem
  ) => {
    const response = await this.root.client.mutate<EDIT_FLOW_CATEGORY_DATA>({
      mutation: EDIT_FLOW_CATEGORY_DATA_MUTATION,
      variables: {
        flowId: flowId || this.flow?.identifier || "",
        type,
        id,
        dataCategory,
      },
    });

    const {
      data: { editFlowCategoryData },
      errors,
    } = response as unknown as {
      data: { editFlowCategoryData: string };
      errors: GraphQLError[];
    };

    if (!editFlowCategoryData || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return true;
  };
}
