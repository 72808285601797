import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import CoreButton from "../core/CoreButton";
import {
  AssetTemplateConfiguration,
  AssetTemplateParameters,
  FormData,
  FormField,
} from "../../types/interfaces";
import CoreDrawer from "../core/CoreDrawer";
import CoreInput from "../core/CoreInput";
import ContextVariableConfig from "./nodeList/ContextVariableConfig";
import CoreTooltip from "../core/CoreTooltip";
import { useStores } from "../../stores/StoresProvider";
import DeleteVariableAction from "./nodeList/DeleteVariableAction";

interface Props {
  translation: TFunction;
  formData: FormData;
  field: FormField;
  value: string | boolean | number | unknown;
  onChange: (value: FormData) => void;
  onDelete?: (key: string) => void;
}

const useStyles = makeStyles({
  mainBox: { display: "flex", flexDirection: "column" },
  buttonBox: {
    paddingTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  boxInput: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
  },
  mainInputBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  iconStyle: {
    marginLeft: "5px",
    marginRight: "-11px",
  },
});

const NodeListRenderer: React.FC<Props> = observer(
  ({ translation, formData, field, value, onChange, onDelete }) => {
    const classes = useStyles();
    const { flowSettingsStore } = useStores();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [variableKeyToEdit, setVariableKeyToEdit] = useState<string>("");

    const handleAddNewVariable = (newValue: { [key: string]: unknown }) => {
      onChange({
        [field.key]: [
          ...((formData[field.key] as AssetTemplateParameters[]) || []),
          newValue,
        ],
      });
    };

    const handleEditVariable = (newValue: { [key: string]: unknown }) => {
      const updatedList = (
        (formData[field.key] as AssetTemplateParameters[]) || []
      )?.map((item) => {
        if (item.key === variableKeyToEdit) {
          return newValue;
        }
        return item;
      });

      onChange({
        [field.key]: updatedList,
      });
    };

    const handleCloseDrawer = () => {
      setIsOpen(false);
      if (variableKeyToEdit) setVariableKeyToEdit("");
    };

    const getDrawerTitle = useMemo(() => {
      if (!flowSettingsStore.isDiagramEditable) {
        return translation("viewContextVariable");
      }

      if (variableKeyToEdit) {
        return translation("editContextVariable");
      }

      return translation("addContextVariable");
    }, [variableKeyToEdit, translation, flowSettingsStore.isDiagramEditable]);

    const getListValues = useMemo(() => {
      return (formData?.variables as FormData[]) || [];
    }, [formData]);

    return (
      <Box className={classes.mainBox}>
        <Box className={classes.buttonBox}>
          <CoreButton
            onClick={() => {
              setIsOpen(true);
              setVariableKeyToEdit("");
            }}
            disabled={!flowSettingsStore.isDiagramEditable}
          >
            {translation("addContextVariable")}
          </CoreButton>
        </Box>

        <Box className={classes.mainInputBox}>
          {((value as FormData[]) || [])?.map(
            (variable: FormData, index: number) => (
              <Box key={index} className={classes.boxInput}>
                <CoreInput key={index} value={variable.name} readOnly={true} />

                <DeleteVariableAction
                  onDelete={onDelete}
                  itemKey={variable?.key as string}
                  fieldKey={field?.key}
                  t={translation}
                />

                <CoreTooltip
                  title={
                    flowSettingsStore.isDiagramEditable
                      ? translation("clickToEditVariableTooltip")
                      : translation("viewContextVariable")
                  }
                >
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() => {
                      setIsOpen(true);
                      setVariableKeyToEdit(variable.key as string);
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </CoreTooltip>
              </Box>
            )
          )}
        </Box>

        <CoreDrawer
          isOpen={isOpen}
          onClose={handleCloseDrawer}
          title={getDrawerTitle}
          hasBackButton={true}
          onBack={handleCloseDrawer}
        >
          <ContextVariableConfig
            config={field as unknown as AssetTemplateConfiguration}
            t={translation}
            onAdd={handleAddNewVariable}
            closeDrawer={handleCloseDrawer}
            list={getListValues}
            variableKeyToEdit={variableKeyToEdit}
            onEdit={handleEditVariable}
          />
        </CoreDrawer>
      </Box>
    );
  }
);

export default NodeListRenderer;
