import React from "react";
import { TFunction } from "i18next";

import { createStyles, makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { CheckboxRenderer } from "../formFields/CheckboxRenderer";
import { InputRenderer } from "../formFields/InputRenderer";
import { SelectRenderer } from "../formFields/SelectRenderer";
import { SwitchRenderer } from "../formFields/SwitchRenderer";
import JsonEditorRenderer from "../formFields/JsonEditorRenderer";
import { GoogleDriveRenderer } from "../formFields/GoogleDriveRenderer";
import { OneDriveRenderer } from "../formFields/OneDriveRenderer";
import { DropBoxRenderer } from "../formFields/DropBoxRenderer";
import { AutocompleteRenderer } from "../formFields/AutocompleteRenderer";
import { FileUploadRenderer } from "../formFields/FileUploadRenderer";
import { FIELD_TYPE } from "../../types/constants";
import { FormField, FormData, Variable } from "../../types/interfaces";
import { AppSuspense } from "../main/AppSuspense";
import { CorePlaceholderProvider } from "./CorePlaceholderProviders";
import { useStores } from "../../stores/StoresProvider";
import AdvancedExportRenderer from "../formFields/AdvancedExportRenderer";
import FileNameRenderer from "../formFields/FileNameRenderer";
import DynamicPathRenderer from "../formFields/DynamicPathRenderer";
import { AutocompleteNoAddRenderer } from "../formFields/AutocompleteNoAddRenderer";
import { FieldSelectRenderer } from "../formFields/FieldSelect";
import CoreAccordion from "./CoreAccordion";
import { TextareaRenderer } from "../formFields/TextareaRenderer";
import NodeInputRenderer from "../formFields/NodeInputRenderer";
import NodeOutputRenderer from "../formFields/NodeOutputRenderer";
import NodeListRenderer from "../formFields/NodeListRenderer";
import ExecutionSchemaRenderer from "../formFields/ExecutionSchemaRenderer";
import CodeRenderer from "../formFields/CodeRenderer";
import ConditionalNodeRenderer from "../formFields/conditional/ConditionalNodeRenderer";

const useStyles = makeStyles(() =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    inputFields: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    switch: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    box: {
      width: "100%",
    },
    boxAppSuspense: {
      marginTop: "30px",
    },
  })
);

interface Props {
  fields: FormField[];
  translation: TFunction;
  data: FormData;
  onChange: (value: FormData, key: string) => void;
  variables?: Variable[];
  fieldSpacing?: string;
  formPreProcess?: (formData: FormData) => FormData;
  isLoading?: boolean;
  isViewMode?: boolean;
  groups?: {
    key: string;
    name: string;
    description?: string;
    warning?: string;
  }[];
  onDelete?: (key: string) => void;
  disableDiagramField?: boolean;
}

const COMPONENTS = {
  [FIELD_TYPE.input]: InputRenderer,
  [FIELD_TYPE.number]: InputRenderer,
  [FIELD_TYPE.password]: InputRenderer,
  [FIELD_TYPE.textarea]: TextareaRenderer,
  [FIELD_TYPE.nodeInput]: NodeInputRenderer,
  [FIELD_TYPE.nodeOutput]: NodeOutputRenderer,
  [FIELD_TYPE.select]: SelectRenderer,
  [FIELD_TYPE.checkbox]: CheckboxRenderer,
  [FIELD_TYPE.switch]: SwitchRenderer,
  [FIELD_TYPE.json]: JsonEditorRenderer,
  [FIELD_TYPE.googleDriveConnection]: GoogleDriveRenderer,
  [FIELD_TYPE.oneDriveConnection]: OneDriveRenderer,
  [FIELD_TYPE.dropboxConnection]: DropBoxRenderer,
  [FIELD_TYPE.autocomplete]: AutocompleteRenderer,
  [FIELD_TYPE.autocompleteNoAdd]: AutocompleteNoAddRenderer,
  [FIELD_TYPE.fileUpload]: FileUploadRenderer,
  [FIELD_TYPE.exportFields]: AdvancedExportRenderer,
  [FIELD_TYPE.fileName]: FileNameRenderer,
  [FIELD_TYPE.filePath]: DynamicPathRenderer,
  [FIELD_TYPE.fields]: FieldSelectRenderer,
  [FIELD_TYPE.code]: CodeRenderer,
  [FIELD_TYPE.list]: NodeListRenderer,
  [FIELD_TYPE.inputList]: ExecutionSchemaRenderer,
  [FIELD_TYPE.conditional]: ConditionalNodeRenderer,
};

export const CoreFormRenderer: React.FC<Props> = ({
  fields,
  translation,
  data,
  onChange,
  variables,
  fieldSpacing = "1rem",
  formPreProcess,
  isLoading = false,
  isViewMode = false,
  groups,
  onDelete,
  disableDiagramField,
}) => {
  const { mainStore } = useStores();
  const classes = useStyles();

  const getConditionProviderField = (field: FormField) => {
    // If the field is a provider field, and provider is not configured, show placeholder
    switch (field.type) {
      case FIELD_TYPE.googleDriveConnection:
        return !mainStore.settings?.googleClientId;
      case FIELD_TYPE.oneDriveConnection:
        return !mainStore.settings?.onedriveClientId;
      case FIELD_TYPE.dropboxConnection:
        return !mainStore.settings?.dropboxAppKey;
      default:
        return false;
    }
  };

  const handleChange = (value: FormData) => {
    let newData = { ...data, ...value };

    if (formPreProcess) {
      newData = formPreProcess(newData);
    }

    onChange(newData, Object.keys(value)[0]);
  };

  const getClassName = (field: FormField) => {
    switch (field.type) {
      case FIELD_TYPE.input:
      case FIELD_TYPE.textarea:
      case FIELD_TYPE.select:
      case FIELD_TYPE.autocomplete:
        return classes.inputFields;
      case FIELD_TYPE.switch:
        return classes.switch;
      default:
        return classes.inputFields;
    }
  };

  const renderField = (field: FormField) => {
    const FieldRenderer = COMPONENTS[field.type];
    if (!FieldRenderer) return null;

    let isDisabled = false;
    if (field.validateField) isDisabled = field.validateField(data);

    if (getConditionProviderField(field)) {
      return (
        <Box
          key={field.key}
          className={getClassName(field)}
          sx={{ marginTop: fieldSpacing }}
        >
          <CorePlaceholderProvider
            translation={translation}
            key={field.key}
            errorText={field.errorText}
          />
        </Box>
      );
    }

    return (
      <Box
        key={field.key}
        className={getClassName(field)}
        sx={{ marginTop: fieldSpacing }}
      >
        <Box className={classes.box}>
          <FieldRenderer
            formData={data}
            field={field}
            groupBy={field.groupBy}
            value={data[field.key]}
            translation={translation}
            onChange={handleChange}
            otherProps={{
              disabled: isDisabled || isViewMode,
              ...field.props,
            }}
            tooltipLocation={field.tooltipLocation}
            extraActions={field.extraActions}
            type={field.type}
            errorText={field.errorText}
            description={field.props?.description as string}
            disabled={isViewMode}
            preventValueCheck={field?.preventValueCheck as boolean}
            variables={variables}
            onDelete={onDelete}
            cannotEditSchema={field?.cannotEditSchema}
            disableDiagramField={disableDiagramField}
          />
        </Box>
      </Box>
    );
  };

  const groupsWithAtLeastOneField = groups?.filter((group) => {
    return fields.some((field) => field.group === group.key);
  });

  const fieldsWithoutGroups = fields.filter(
    (field) =>
      !field.group ||
      !groupsWithAtLeastOneField?.some((group) => group.key === field.group)
  );

  return (
    <Box className={classes.formContainer}>
      {fieldsWithoutGroups.map((field) => renderField(field))}

      {groupsWithAtLeastOneField?.map((group) => {
        return (
          <CoreAccordion
            key={group.key}
            title={group.name}
            description={group.description}
            warning={group.warning}
          >
            {fields
              .filter((field) => field.group === group.key)
              .map((field) => renderField(field))}
          </CoreAccordion>
        );
      })}

      {isLoading && (
        <Box className={classes.boxAppSuspense}>
          <AppSuspense />
        </Box>
      )}
    </Box>
  );
};
