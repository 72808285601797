import React, { FC, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";

import { FlowNode, NodeData } from "../../../../../types/interfaces";
import { GroupTypeFlag } from "../diagramBuilder/GroupTypeFlag";
import { useStores } from "../../../../../stores/StoresProvider";
import {
  NODE_ASSET_TYPE,
  DIAGRAM_NODE_CATEGORIES,
  NODE_TYPES,
} from "../../../../../types/constants";

export const GroupFlagHandler: FC<{
  groupFlag?: string;
  nodeIdentifier: string;
}> = observer(({ nodeIdentifier, groupFlag }) => {
  const reactFlow = useReactFlow();
  const { flowSettingsStore } = useStores();

  const hasBranchAndGroupFlag = useMemo(() => {
    //Check if first node is a group and is a child for another group
    const nodes = reactFlow.getNodes();
    const targetNode = nodes?.find(
      (node) => node?.id === nodeIdentifier
    ) as FlowNode;

    if (
      !targetNode ||
      targetNode?.type !== NODE_TYPES.group ||
      (!targetNode?.trueBranch && !targetNode?.falseBranch)
    ) {
      return false;
    }

    const groupNode = nodes?.find((node) => node?.id === targetNode?.parentId);

    if (!groupNode) return false;

    const childNodesExist = nodes?.some(
      (node) =>
        node?.parentId === groupNode?.id &&
        ((node as FlowNode)?.trueBranch || (node as FlowNode)?.falseBranch)
    );

    //Check if group node has a branch flag
    const currentNode = reactFlow.getNode(
      nodeIdentifier
    ) as unknown as NodeData;

    if (!currentNode?.parentId) return false;

    const parentNode = reactFlow.getNode(currentNode?.parentId) as FlowNode;

    if (parentNode?.key !== NODE_ASSET_TYPE.conditional) return false;

    const nodeEdge = reactFlow
      .getEdges()
      ?.find((edge) => edge?.target === nodeIdentifier);

    const sourceNode = nodeEdge?.source
      ? (reactFlow.getNode(nodeEdge?.source) as unknown as NodeData)
      : undefined;

    const isBranchValid =
      sourceNode?.type === DIAGRAM_NODE_CATEGORIES.placeholder &&
      (currentNode?.trueBranch || currentNode?.falseBranch);

    //If both conditions are true, we need to move the group flag above branch flag
    return childNodesExist && isBranchValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeIdentifier, flowSettingsStore.layoutingNeeded]);

  return (
    <GroupTypeFlag
      label={groupFlag || ""}
      hasBranchAndGroupFlag={hasBranchAndGroupFlag}
    />
  );
});
