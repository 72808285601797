import React, { memo, useEffect, useRef } from "react";
import { TFunction } from "react-i18next";
import { observer } from "mobx-react";

import {
  MaterialReactTable,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_DensityState,
  MRT_TableInstance,
} from "material-react-table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { useStores } from "../../../../../stores/StoresProvider";
import CoreButton from "../../../../core/CoreButton";
import CoreTooltip from "../../../../core/CoreTooltip";

interface CustomColumnDef extends MRT_ColumnDef {
  isMandatory?: boolean;
  isVisible?: boolean;
}

interface Props {
  headers: CustomColumnDef[];
  rows: Record<string, string | number>[];
  gridApi: React.MutableRefObject<MRT_TableInstance>;
  handleSaveCell: (
    cell: MRT_Cell,
    value: string,
    disableClearEditingCell?: boolean
  ) => void;
  t: TFunction;
}

const DataGridLineItem: React.FC<Props> = observer(
  ({ headers, rows, gridApi, handleSaveCell, t }) => {
    const initialColumnsOrder =
      headers.map((header) => header.accessorKey ?? "") ?? [];

    const [columnOrder, setColumnOrder] = React.useState(initialColumnsOrder);
    const [density, setDensity] =
      React.useState<MRT_DensityState>("comfortable");
    const { documentStore } = useStores();
    const scrollToBottom = useRef<null | HTMLDivElement>(null);

    const useStyles = makeStyles({
      tableActions: {
        justifyContent: documentStore.viewMode ? "flex-end" : "space-between",
      },
      mainContainer: {
        height: "100%",
        overflow: "auto",
      },
      button: {
        minWidth: "45px",
      },
    });

    const classes = useStyles();

    const columnOrderKey = `columnOrder-${
      documentStore.document?.flowIdentifier ?? ""
    }-${documentStore.focusedLineItem?.type ?? ""}`;

    const onColumnOrderChange = (newOrder: string[]) => {
      setColumnOrder(newOrder);
      localStorage.setItem(columnOrderKey, JSON.stringify(newOrder));
    };

    useEffect(() => {
      const density = localStorage.getItem("density");

      const columnOrderLocalStorage = JSON.parse(
        localStorage.getItem(columnOrderKey) ?? "[]"
      ) as string[];

      if (density) setDensity(density as MRT_DensityState);
      if (
        columnOrderLocalStorage.length === 0 ||
        columnOrderLocalStorage.length !== headers.length
      ) {
        localStorage.setItem(
          columnOrderKey,
          JSON.stringify(initialColumnsOrder)
        );
      } else setColumnOrder(columnOrderLocalStorage);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addNewRow = () => {
      documentStore.initEmptyLineItemRow(
        headers[0].accessorKey as unknown as string
      );

      setTimeout(() => scrollToBottom?.current?.scrollIntoView(false), 100);
    };

    return (
      <Box className={classes.mainContainer} id="resizable">
        <div id="scrollToBottom" ref={scrollToBottom}>
          <MaterialReactTable
            muiTableContainerProps={{
              sx: {
                maxHeight: "100%",
              },
            }}
            muiTableBodyCellProps={() => ({
              sx:
                density === "compact"
                  ? {
                      "& .MuiTypography-root": {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }
                  : {
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    },
            })}
            tableInstanceRef={gridApi}
            columns={headers}
            data={rows}
            editingMode="cell"
            enableEditing={!documentStore.viewMode}
            state={{
              columnOrder,
              density,
              pagination: {
                pageSize: 5000,
                pageIndex: 0,
              },
            }}
            renderTopToolbarCustomActions={() => {
              return (
                <div className={classes.tableActions}>
                  {!(documentStore.viewMode as unknown as boolean) && (
                    <CoreTooltip title={t("addNewRowTooltip")}>
                      <div>
                        <CoreButton
                          onClick={addNewRow}
                          variant="outlined"
                          className={classes.button}
                        >
                          <AddOutlinedIcon />
                        </CoreButton>
                      </div>
                    </CoreTooltip>
                  )}
                </div>
              );
            }}
            muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
              onBlur: (e) => {
                handleSaveCell(
                  cell,
                  e.target.value,
                  documentStore.manualLineItemMode
                );
              },
              onFocus: () => {
                documentStore.setCurrentEditingCell({
                  rowIndex: row.original.index as number,
                  colKey: cell.column.id,
                });
                // Is next focused row is not a custom row, then disable lineItem mode
                documentStore.setManualLineItemMode(
                  !!row?.original?.manuallyAdded
                );
              },
            })}
            getRowId={(row) => {
              const { unique } = row;
              return unique as string;
            }}
            enableColumnOrdering={!documentStore.viewMode}
            onColumnOrderChange={(newVal) =>
              onColumnOrderChange(newVal as unknown as string[])
            }
            enableStickyHeader
            onDensityChange={(density) => {
              setDensity(density);
              localStorage.setItem("density", density.toString());
            }}
            enableFullScreenToggle={false}
            columnResizeMode="onEnd"
            enableColumnActions={false}
            enableBottomToolbar={false}
            enableColumnFilters={false}
            enableColumnFilterModes={false}
            enableSorting={false}
            enableColumnResizing
            localization={{
              // Add translation for toolbar
              noRecordsToDisplay: t("noRecordsToDisplay"),
              showHideColumns: t("showHideColumns"),
              showHideSearch: t("showHideSearch"),
              toggleDensity: t("toggleDensity"),
            }}
          />
        </div>
      </Box>
    );
  }
);

export default memo(DataGridLineItem);
