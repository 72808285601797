import React, { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import { makeStyles } from "@mui/styles";
import { useTheme, alpha, Box } from "@mui/material";
import { FileDownloadOutlined, Replay } from "@mui/icons-material";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import { useStores } from "../../../stores/StoresProvider";
import CorePageContainer from "../../core/CorePageContainer";
import { useNotification } from "../../../context/useNotification";
import { DOCUMENT_STATUS, FILTER_STATUS } from "../../../types/constants";
import { HeaderActions } from "../../../types/interfaces";
import { UploadDocuments } from "./footer/UploadDocuments";
import { appRoutes } from "../../../configs/routes";
import CoreConfirmModal from "../../core/CoreConfirmModal";
import ExportFlow from "./ExportFlow";
import CoreButton from "../../core/CoreButton";
import CoreTooltip from "../../core/CoreTooltip";
import FlowSettingsDrawer from "./flow-settings/FlowSettingsDrawer";

interface Props {
  children: React.ReactNode;
  refreshData: () => void;
}

// This component is in charge of flow actions
// States must remain separated from rest of the page to prevent rendering issues
const FlowDetailsWrapper: FC<Props> = observer(({ children, refreshData }) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const client = useApolloClient();
  const notification = useNotification();
  const { flowStore, documentStore, userStore, flowSettingsStore } =
    useStores();
  const theme = useTheme();

  const { t, ready } = useTranslation("flow");
  const { t: addFlowT, ready: addFlowReady } = useTranslation("addFlow");

  const [exportOpen, setExportOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportFlowConfigLoading, setExportFlowConfigLoading] = useState(false);
  const [openRerunAllModal, setOpenRerunAll] = useState(false);
  const [openRetryAllModal, setOpenRetryAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openFlowSettingsDrawer, setOpenFlowSettingsDrawer] = useState(false);

  const useStyles = makeStyles({
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
  });

  const classes = useStyles();

  const deleteFlow = () => {
    if (client && id) {
      setIsLoading(true);

      flowStore
        .deleteFlow(client, id)
        .then((flowIdentifier: string) => {
          notification.success(t("success_deleteMessage"));

          const redirectUrl = flowIdentifier
            ? appRoutes.FlowDetails(flowIdentifier)
            : appRoutes.Flows();
          history.push({ pathname: redirectUrl });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "error_deleteMessage"));
        })
        .finally(() => {
          setIsLoading(false);
          setIsModalOpen(false);
        });
    }
  };

  const cloneFlow = () => {
    setOpenFlowSettingsDrawer(true);
    flowSettingsStore.loadFlow(id, true).catch((error: Error) => {
      notification.error(t(error?.message || "error_loadFlow"));
    });
  };

  const handleCloseUploadModal = (refresh?: boolean) => {
    documentStore.toggleUploadModal(false);
    if (refresh) {
      refreshData();
    }
  };

  const rerunDocuments = () => {
    setIsLoading(true);
    documentStore
      .rerunDocuments(client, flowStore.filters?.flowFilters?.status || [], [])
      .then(() => {
        notification.success(t("rerunAllSuccess"));
        refreshData();
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "rerunAllError"));
      })
      .finally(() => {
        setIsLoading(false);
        setOpenRerunAll(false);
      });
  };

  const retryDocuments = () => {
    setIsLoading(true);
    documentStore
      .retryDocuments(client, DOCUMENT_STATUS.error, undefined, id ?? null)
      .then(() => {
        refreshData();
        notification.success(t("retryAllSuccess"));
      })
      .catch((error: Error) =>
        notification.error(t(error?.message || "retryAllError"))
      )
      .finally(() => {
        setIsLoading(false);
        setOpenRetryAll(false);
      });
  };

  const exportFlow = () => {
    setExportFlowConfigLoading(true);
    flowStore
      .exportFlowConfig(client, id ?? "")
      .then(() => {
        notification.success(t("exportFlowSuccess"));
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "exportFlowError"));
      })
      .finally(() => setExportFlowConfigLoading(false));
  };

  const statusesForRerun = useMemo(
    () =>
      [
        FILTER_STATUS.filter_error,
        FILTER_STATUS.filter_completed,
        FILTER_STATUS.filter_rejected,
        FILTER_STATUS.filter_review,
      ].some((status) =>
        _.isEqual(flowStore.filters?.flowFilters?.status, status)
      ) &&
      documentStore.checkIfCategoryHasDocuments(
        flowStore.filters?.flowFilters?.status?.[0] || ""
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowStore.filters?.flowFilters?.status, rerunDocuments]
  );

  const canRetryAll = useMemo(
    () =>
      _.isEqual(
        flowStore.filters?.flowFilters?.status,
        FILTER_STATUS.filter_error
      ) &&
      documentStore.checkIfCategoryHasDocuments(
        flowStore.filters?.flowFilters?.status?.[0] || ""
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowStore.filters?.flowFilters?.status, retryDocuments]
  );

  const headerActions: HeaderActions[] = [
    ...(id
      ? [
          {
            label: t("documents_UploadDocumentsButton"),
            tooltip: t("documents_UploadDocumentsTooltip"),
            onClick: () => documentStore.toggleUploadModal(true),
            startIcon: <CloudUploadIcon />,
            disabled: documentStore.loadingDocuments,
            ability: [
              {
                action: "importDocuments",
                subject: "flows",
              },
            ],
          },
          {
            label: t("exportDataButton"),
            tooltip: t("exportDataTooltip"),
            onClick: () => setExportOpen(true),
            startIcon: <DriveFileMoveIcon />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            ability: [
              {
                action: "read",
                subject: "flows",
              },
            ],
          },
          {
            label: t("exportFlowButton"),
            tooltip: t("exportFlowButton"),
            onClick: () => exportFlow(),
            startIcon: <FileDownloadOutlined />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            divider: true,
            ability: [
              {
                action: "exportDocuments",
                subject: "flows",
              },
            ],
          },
          {
            label: t("viewLogsButton"),
            tooltip: t("viewLogsButtonTooltip"),
            onClick: () => {
              history.push(`/activity-log?flow=${id}`);
            },
            startIcon: <DataUsageIcon />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            ability: [
              {
                action: "read",
                subject: "activityLogs",
              },
            ],
          },
          {
            label: t("duplicateButton"),
            tooltip: t("duplicateTooltip"),
            onClick: () => cloneFlow(),
            startIcon: <FileCopyIcon />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            divider: true,
            ability: [
              {
                action: "create",
                subject: "flows",
              },
              { action: "read", subject: "flows" },
            ],
          },
          {
            label: t("settings"),
            tooltip: t("display_flowSettings"),
            onClick: () => {
              history.push(appRoutes.FlowSettings(id));
            },
            startIcon: <SettingsIcon />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            ability: [
              {
                action: "update",
                subject: "flows",
              },
            ],
          },
          {
            label: t("delete"),
            tooltip: t("deleteFlow"),
            onClick: () => setIsModalOpen(true),
            startIcon: <DeleteForeverOutlinedIcon />,
            disabled: flowStore.isFlowDetailsLoading,
            initiallyHidden: true,
            ability: [
              {
                action: "delete",
                subject: "flows",
              },
            ],
          },
        ]
      : [
          {
            label: t("refreshButton"),
            tooltip: t("refreshButton"),
            onClick: () => refreshData(),
            startIcon: <CachedOutlinedIcon />,
            disabled: documentStore.loadingDocuments,
            ability: [
              {
                action: "read",
                subject: "flows",
              },
            ],
          },
        ]),
    ...(statusesForRerun
      ? [
          {
            label: t("rerunAll"),
            tooltip: t("rerunAll"),
            onClick: () => setOpenRerunAll(true),
            startIcon: <Replay />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            ability: [
              {
                action: "updateDocuments",
                subject: "flows",
              },
            ],
          },
        ]
      : []),
    ...(canRetryAll
      ? [
          {
            label: t("retryAll"),
            tooltip: t("retryAll"),
            onClick: () => setOpenRetryAll(true),
            startIcon: <Replay />,
            disabled: documentStore.loadingDocuments,
            initiallyHidden: true,
            ability: [
              {
                action: "updateDocuments",
                subject: "flows",
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <CorePageContainer
      label={id ? flowStore.flowSummary?.name || "" : t("allFlowsTitle")}
      isPageLoading={
        !ready || !addFlowReady || (!!id && flowStore.isFlowSummaryLoading)
      }
      subtitle={
        id ? flowStore.flowSummary?.description || "" : t("allFlowsSubtitle")
      }
      extraHeaderActions={headerActions}
      extraHeaderMenuException={true}
      extraHeaderActionsButtonDisabled={exportFlowConfigLoading}
      fixedExtraHeaderActions={
        id &&
        userStore.currentUserPermissions?.can("read", "flows") && (
          <CoreTooltip title={t("refreshButton")}>
            <Box>
              <CoreButton
                variant="neutral"
                size="large"
                onClick={() => refreshData()}
                startIcon={<CachedOutlinedIcon />}
                className={classes.button}
                disabled={documentStore.loadingDocuments}
              >
                {t("refreshButton")}
              </CoreButton>
            </Box>
          </CoreTooltip>
        )
      }
    >
      {children}

      <UploadDocuments
        active={documentStore.isUploadModalOpen}
        handleClose={handleCloseUploadModal}
        t={t}
      />

      <ExportFlow
        isOpen={exportOpen}
        onClose={() => setExportOpen(false)}
        t={addFlowT}
      />

      <FlowSettingsDrawer
        isOpen={openFlowSettingsDrawer}
        onClose={() => {
          flowSettingsStore.setDuplicateFlowConfig(undefined);
          setOpenFlowSettingsDrawer(false);
        }}
        forDuplicateFlow={true}
      />

      <CoreConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t("deleteModal_title")}
        subtitle={t("deleteModal_subtitle")}
        cancelButtonLabel={t("deleteModal_cancelButton")}
        confirmButtonLabel={t("deleteModal_confirmButton")}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={deleteFlow}
        isDisable={isLoading}
      />

      <CoreConfirmModal
        open={openRerunAllModal}
        onClose={() => setOpenRerunAll(false)}
        title={t("rerunAllModalTitle")}
        subtitle={t("rerunAllDocumentsSubtitle")}
        cancelButtonLabel={t("cancelModalButton")}
        confirmButtonLabel={t("confirmModalButton")}
        onCancel={() => setOpenRerunAll(false)}
        onConfirm={rerunDocuments}
        isDisable={isLoading}
      />

      <CoreConfirmModal
        open={openRetryAllModal}
        onClose={() => setOpenRetryAll(false)}
        title={t("retryAllModalTitle")}
        subtitle={t("retryAllDocumentsSubtitle")}
        cancelButtonLabel={t("cancelModalButton")}
        confirmButtonLabel={t("confirmModalButton")}
        onCancel={() => setOpenRetryAll(false)}
        onConfirm={retryDocuments}
        isDisable={isLoading}
      />
    </CorePageContainer>
  );
});

export default FlowDetailsWrapper;
