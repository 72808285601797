import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  ASSET_TYPES,
  FLOW_DATA_TYPES_BACKEND,
} from "../../../../types/constants";
import CoreDrawer from "../../../core/CoreDrawer";
import DataCategoryConfig from "./DataCategoryConfig";
import CreateConnection from "../../connections/CreateConnection";
import AssetListDisplay from "../../addNewFlow/tabs/assetsCore/AssetListDisplay";
import { useStores } from "../../../../stores/StoresProvider";
import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { useNotification } from "../../../../context/useNotification";

interface Props {
  isOpen: boolean;
  type: string;
  onClose: (refresh?: boolean) => void;
  itemToEdit?: FlowDataCategoryItem;
}

const FlowDataCategoryCrudDrawer: FC<Props> = ({
  isOpen,
  type,
  onClose,
  itemToEdit,
}) => {
  const { id } = useParams<{ id: string }>();
  const { flowStore } = useStores();
  const notification = useNotification();
  const { t } = useTranslation("addFlow");

  const [openConn, setOpenConn] = useState(false);
  const [openFileBrowser, setOpenFileBrowser] = useState(false);
  const [categoryKey, setCategoryKey] = useState("");

  const formattedType = (
    FLOW_DATA_TYPES_BACKEND as unknown as { [key: string]: unknown }
  )[type] as string;

  const handleOnBack = () => {
    if (openFileBrowser) setOpenFileBrowser(false);
    setCategoryKey("");
  };

  const handleClose = (refresh?: boolean) => {
    if (openFileBrowser) setOpenFileBrowser(false);
    setCategoryKey("");
    onClose(refresh);
  };

  const onConnClose = (goBack = false) => {
    if (!goBack) {
      handleClose();
    }
    setOpenConn(false);
  };

  const addDataCategory = (dataCategory: FlowDataCategoryItem) => {
    flowStore
      .addFlowCategoryData(id, formattedType, dataCategory)
      .then(() => onClose(true))
      .catch((error: Error) => {
        notification.error(t(error?.message || "flowNotFound"));
      });
  };

  const editDataCategory = (dataCategory: FlowDataCategoryItem) => {
    flowStore
      .editFlowCategoryData(
        id,
        formattedType,
        itemToEdit?.identifier as string,
        dataCategory
      )
      .then(() => onClose(true))
      .catch((error: Error) => {
        notification.error(t(error?.message || "flowNotFound"));
      });
  };

  useEffect(() => {
    if (itemToEdit) setCategoryKey(itemToEdit.key);
  }, [itemToEdit]);

  return (
    <>
      <CoreDrawer
        isOpen={isOpen}
        onClose={() => handleClose(false)}
        title={
          itemToEdit ? t(`${formattedType}_edit`) : t(`${formattedType}_add`)
        }
        hasBackButton={categoryKey !== "" && itemToEdit === undefined}
        onBack={handleOnBack}
      >
        {categoryKey === "" ? (
          <AssetListDisplay
            t={t}
            type={formattedType as unknown as ASSET_TYPES}
            onSelect={(key: string) => {
              setCategoryKey(key);
            }}
            subtitle={t("drawer_title", {
              title: t(`${formattedType}_title`),
            })}
          />
        ) : (
          <DataCategoryConfig
            t={t}
            type={type}
            onClose={(refresh?: boolean) => handleClose(refresh)}
            categoryKey={categoryKey}
            setOpenConn={setOpenConn}
            openFileBrowser={openFileBrowser}
            setOpenFileBrowser={setOpenFileBrowser}
            setData={(dataCategory: FlowDataCategoryItem) => {
              itemToEdit
                ? editDataCategory(dataCategory)
                : addDataCategory(dataCategory);
            }}
            itemToEdit={itemToEdit}
          />
        )}
      </CoreDrawer>

      <CreateConnection
        isOpen={openConn}
        onClose={onConnClose}
        hasBackButton
        onBack={onConnClose}
      />
    </>
  );
};

export default FlowDataCategoryCrudDrawer;
