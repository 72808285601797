import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiRadio = (theme: ThemeOptions): Components["MuiRadio"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          color: palette.neutral.main,
        },
      },
    },
  };
};
