import { gql } from "@apollo/client";
import { FlowDiagram, FlowTemplate } from "../../types/interfaces";

export interface MUTATION_FLOW_INPUT {
  name: string;
  description: string;
  fields: MUTATION_FLOW_FIELD_INPUT[];
}

export interface MUTATION_FLOW_FIELD_INPUT {
  name?: string;
  key?: string;
  isCustom?: boolean;
  category?: string;
  description?: string;
  example?: string;
  dataType?: JSON;
}

export interface CREATE_FLOW_TYPE {
  data: { createFlow: { identifier: string } };
}

export interface IMPORT_FLOW_TYPE {
  data: { importFlow: { identifier: string } };
}

export interface DUPLICATE_FLOW_TYPE {
  data: {
    duplicateFlow: {
      flowId: string;
      name: string;
      description: string;
      access: Record<string, string>;
    };
  };
}

export interface MUTATION_FLOW_CREATE_RESPONSE {
  identifier: string;
}

export interface GET_FIELDS_TYPE {
  data: { getFields: FlowTemplate[] };
}

export interface ADD_REJECTION_TYPE {
  addRejection: boolean;
}

export interface UPDATE_FLOW_FROM_DRAFT_TYPE {
  data: {
    updateFlow: {
      flow: CREATE_FLOW_TYPE;
    };
  };
}

export interface DELETE_FLOW_TYPE {
  data: { deleteFlow: { flowId: string } };
}

export interface EXECUTE_FLOW_DATA_TYPE_ACTION {
  executeFlowDataTypeAction: boolean;
}

export interface ADD_FLOW_CATEGORY_DATA {
  addFlowCategoryData: boolean;
}

export interface EDIT_FLOW_CATEGORY_DATA {
  editFlowCategoryData: boolean;
}

export interface REPORT_ISSUE_RESPONSE {
  reportIssue: boolean;
}

export interface DELETE_DIAGRAM_VERSION {
  data: { deleteDiagramVersion: { flowId: string; diagramId: string } };
}

export interface PROMOTE_TO_CURRENT {
  data: { promoteToCurrent: FlowDiagram };
}

export interface CREATE_DIAGRAM_RESPONSE {
  createFlowDiagram: boolean;
}

export interface IMPORT_DIAGRAM_RESPONSE {
  importFlowDiagram: boolean;
}

export interface DUPLICATE_DIAGRAM_RESPONSE {
  duplicateFlowDiagram: boolean;
}

export interface APPLY_DIAGRAM_TEMPLATE_RESPONSE {
  applyDiagramTemplate: boolean;
}

export const CREATE_FLOW = gql`
  mutation createFlow($name: String!, $access: JSON) {
    createFlow(name: $name, access: $access)
  }
`;

export const IMPORT_FLOW = gql`
  mutation importFlow(
    $name: String!
    $access: JSON
    $dataSource: JSON
    $dataExport: JSON
    $storage: JSON
    $rejectReasons: [RejectionInput]
  ) {
    importFlow(
      name: $name
      access: $access
      storage: $storage
      dataSource: $dataSource
      dataExport: $dataExport
      rejectReasons: $rejectReasons
    )
  }
`;

export const UPDATE_FLOW = gql`
  mutation updateFlow($flow: FlowInput, $id: String) {
    updateFlow(flow: $flow, id: $id)
  }
`;

export const DELETE_FLOW = gql`
  mutation deleteFlow($flowId: String!) {
    deleteFlow(flowId: $flowId)
  }
`;

export const DUPLICATE_FLOW = gql`
  mutation duplicateFlow(
    $flowId: String
    $name: String
    $description: String
    $access: JSON
  ) {
    duplicateFlow(
      flowId: $flowId
      name: $name
      description: $description
      access: $access
    )
  }
`;

export const ADD_REJECTION = gql`
  mutation addRejection($flowId: String!, $data: [RejectionInput!]!) {
    addRejection(flowId: $flowId, data: $data)
  }
`;

export const EXECUTE_FLOW_DATA_TYPE_ACTION_MUTATION = gql`
  mutation executeFlowDataTypeAction(
    $flowId: String!
    $id: String!
    $type: String!
    $action: String!
  ) {
    executeFlowDataTypeAction(
      flowId: $flowId
      id: $id
      type: $type
      action: $action
    )
  }
`;

export const ADD_FLOW_CATEGORY_DATA_MUTATION = gql`
  mutation addFlowCategoryData(
    $flowId: String!
    $type: String!
    $dataCategory: JSON!
  ) {
    addFlowCategoryData(
      flowId: $flowId
      type: $type
      dataCategory: $dataCategory
    )
  }
`;

export const EDIT_FLOW_CATEGORY_DATA_MUTATION = gql`
  mutation editFlowCategoryData(
    $flowId: String!
    $type: String!
    $id: String!
    $dataCategory: JSON!
  ) {
    editFlowCategoryData(
      flowId: $flowId
      type: $type
      id: $id
      dataCategory: $dataCategory
    )
  }
`;

export const REPORT_ISSUE_MUTATION = gql`
  mutation reportIssue($documentId: String!, $message: String!) {
    reportIssue(documentId: $documentId, message: $message)
  }
`;

export const DELETE_DIAGRAM_VERSION = gql`
  mutation deleteDiagramVersion($flowId: String!, $diagramId: String!) {
    deleteDiagramVersion(flowId: $flowId, diagramId: $diagramId)
  }
`;

export const PROMOTE_TO_CURRENT = gql`
  mutation promoteToCurrent($flowId: String!, $selectedDiagramId: String!) {
    promoteToCurrent(flowId: $flowId, selectedDiagramId: $selectedDiagramId) {
      identifier
      version
      createdAt
      updatedAt
      createdBy
      current
      specs
    }
  }
`;

export const CREATE_DIAGRAM = gql`
  mutation createFlowDiagram($flowId: String!, $diagram: CreateFlowDiagram!) {
    createFlowDiagram(flowId: $flowId, diagram: $diagram)
  }
`;

export const IMPORT_DIAGRAM = gql`
  mutation importFlowDiagram($flowId: String!, $diagram: CreateFlowDiagram!) {
    importFlowDiagram(flowId: $flowId, diagram: $diagram)
  }
`;

export const DUPLICATE_DIAGRAM = gql`
  mutation duplicateFlowDiagram(
    $flowId: String!
    $duplicatedFlowId: String!
    $diagramId: String
  ) {
    duplicateFlowDiagram(
      flowId: $flowId
      duplicatedFlowId: $duplicatedFlowId
      diagramId: $diagramId
    )
  }
`;

export const APPLY_DIAGRAM_TEMPLATE = gql`
  mutation applyDiagramTemplate($flowId: String!, $parameters: JSON) {
    applyDiagramTemplate(flowId: $flowId, parameters: $parameters)
  }
`;
