import React, { FC, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";

import { appRoutes } from "../../../configs/routes";
import SettingsMenu from "./components/SettingsMenu";
import { SubscriptionPackageUpgrade } from "./components/subscription/SubscriptionPackageUpgrade";
import { useStores } from "../../../stores/StoresProvider";
import { AppSuspense } from "../../main/AppSuspense";
import { VALID_SETTINGS_URLS } from "../../../types/constants";
import NotFound from "../errorPages/NotFound";
import { getSettingsMenuConfig } from "./menuConfig";

const useStyle = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    overflowX: "auto",
  },
  content: {
    paddingLeft: "20px",
    paddingRight: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "64px",
  },
});

const SettingsPage: FC = observer(() => {
  const { ready } = useTranslation(["team", "settings"]);
  const classes = useStyle();
  const { userStore, mainStore } = useStores();

  const menuConfig = useMemo(
    () => getSettingsMenuConfig(userStore.currentUserPermissions),
    [userStore.currentUserPermissions]
  );

  const SETTINGS_ROUTE = [
    ...menuConfig.map((item) => {
      return {
        path: item.path,
        component: item.component,
        abilityKey: item.abilityKey,
        abilityAction: item.abilityAction,
      };
    }),
    {
      path: appRoutes.SubscriptionPlanUpgrade(),
      component: SubscriptionPackageUpgrade,
      abilityKey: "subscription",
      abilityAction: "update",
    },
  ];

  const validUrl = VALID_SETTINGS_URLS.includes(mainStore.currentPage);

  if (!validUrl) {
    return <NotFound />;
  }

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <div className={classes.container}>
      <SettingsMenu />

      <Box className={classes.content}>
        <Switch>
          {SETTINGS_ROUTE.map((item) => {
            const { component, path } = item;

            const Component = component;

            return (
              <Route key={path} exact={true} path={path}>
                <Component />
              </Route>
            );
          })}
        </Switch>
      </Box>
    </div>
  );
});

export default SettingsPage;
