import React, { FC, memo, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _startCase from "lodash/startCase";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { DateRange } from "@mui/lab/DateRangePicker";

import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";

import CoreTag from "../../../core/CoreTag";
import CoreTooltip from "../../../core/CoreTooltip";
import DatePicker from "../../../core/DatePicker";
import { useStores } from "../../../../stores/StoresProvider";
import {
  ActivityLogsFilterTypes,
  ContextEventStatuses,
  ContextEventTypes,
} from "../../../../types/enums";
import DateHelper from "../../../../helper/dateHelper";
import SearchFilter from "../../flow/filters/SearchFilter";
import CoreAutocomplete from "../../../core/CoreAutocomplete";
import { AdvancedFiltersDrawer } from "../../flow/filters/AdvancedFiltersDrawer";
import {
  DocumentsFilterProps,
  SelectOptionDef,
} from "../../../../types/interfaces";
import { FILTER_PROPS, THEME_MODES } from "../../../../types/constants";

interface Props {
  unsupportedFilters?: string[];
}

const formatDateFilter = (date: DateRange<Date>): string => {
  const start = DateHelper.timeStringFormat(date?.[0]?.toISOString(), false);
  const end = date?.[1]
    ? DateHelper.timeStringFormat(date?.[1]?.toISOString(), false)
    : "";

  return `${start} ${end ? `- ${end}` : ""}`;
};

const FiltersContent: FC<Props> = observer(({ unsupportedFilters = [] }) => {
  const { t } = useTranslation("activityLogs");
  const { search } = useLocation<{ search: string }>();
  const history = useHistory();
  const [isAdvancedDrawerOpened, toggleAdvancedDrawer] = useState(false);
  const [anchor, setAnchor] = useState<(EventTarget & HTMLElement) | null>(
    null
  );
  const {
    flowStore: { flows },
    activityLogStore: {
      resetFilters,
      filters: {
        inputSearchFilter,
        flowsFilter,
        statusFilter,
        dateFilter,
        eventTypeFilter,
      },
      setFilters,
    },
    mainStore,
    userStore,
  } = useStores();
  const theme = useTheme();
  const { currentTheme } = mainStore;

  const classes = makeStyles({
    container: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "0px",
      maxWidth: "1500px",
      paddingRight: "16px",
    },
    valueDisplayContainer: {
      margin: "15px",
    },
    valueTag: {
      marginRight: "5px",
      marginLeft: "5px",
    },
    dateIconButton: {
      marginRight: "10px",
      display: "flex",
      alignItems: "start",
      height: "100%",
    },
    timeLabel: {
      margin: "auto 16px auto 0",
      color: "inherit",
    },
    searchFilterOverwrite: {
      height: "40px",
      marginLeft: "0",
    },
    dateFilterStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        color:
          currentTheme === THEME_MODES.light
            ? theme.palette.neutral.dark
            : theme.palette.text.secondary,
      },
    },
  })();

  const isFlowFilterSupported = useMemo(
    () => !unsupportedFilters?.includes(ActivityLogsFilterTypes.flowsFilter),
    [unsupportedFilters]
  );

  const ADVANCED_FILTERS = useMemo(() => {
    const filters = [
      ...(userStore.currentUserPermissions?.can("read", "flow") &&
      isFlowFilterSupported
        ? [
            {
              key: ActivityLogsFilterTypes.flowsFilter,
              value: ActivityLogsFilterTypes.flowsFilter,
              label: "advanced_filters_drawer_flow_filter_label",
            },
          ]
        : []),
      {
        key: ActivityLogsFilterTypes.eventTypeFilter,
        value: ActivityLogsFilterTypes.eventTypeFilter,
        label: "advanced_filters_drawer_event_type_filter_label",
        suggestions: Object.values(ContextEventTypes).map(
          (eventType: string) => ({
            key: eventType,
            value: eventType,
            label: _startCase(eventType),
          })
        ),
      },
    ];

    return filters;
  }, [userStore.currentUserPermissions, isFlowFilterSupported]);

  // Inject suggestions for advanced filter drawer
  const requiredExtraFilters = useMemo(
    () =>
      flows?.length > 0
        ? ADVANCED_FILTERS.map((filter) => {
            const formattedFilter = {
              ...filter,
              label: t(filter.label),
            };

            if (filter.key === "flowsFilter") {
              return {
                ...formattedFilter,
                suggestions: flows.map((flow) => ({
                  key: flow.identifier,
                  value: flow.identifier,
                  label: flow.name,
                })),
              };
            }
            return formattedFilter;
          })
        : ADVANCED_FILTERS.map((filter) => {
            const formattedFilter = {
              ...filter,
              label: t(filter.label),
            };
            return formattedFilter;
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flows, t, userStore.currentUserPermissions]
  );

  const currentStatuses = useMemo(
    () =>
      Object.values(ContextEventStatuses).map((status: string) => ({
        key: status,
        value: status,
        label: _startCase(status),
      })),
    []
  );

  const isFilterApplied = useMemo(
    () =>
      [
        ...(inputSearchFilter || []),
        ...(isFlowFilterSupported ? flowsFilter || [] : []),
        ...(statusFilter || []),
        ...(dateFilter || []),
        ...(eventTypeFilter || []),
      ]?.length > 0,
    [
      dateFilter,
      eventTypeFilter,
      flowsFilter,
      inputSearchFilter,
      statusFilter,
      isFlowFilterSupported,
    ]
  );

  const toggleDatePicker = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleStatusSelect = (updatedList: string[]) => {
    setFilters("statusFilter", updatedList);
  };

  const handleChangeDate = (value: DateRange<Date> | null) => {
    setFilters("dateFilter", value);
  };

  const handleSearchChange = (list: string[]) => {
    setFilters("inputSearchFilter", list);
  };

  const removeListItem = (valueIndex: number, listName: string) => {
    let filter = null;
    switch (listName) {
      case "statusFilter": {
        filter = statusFilter;
        break;
      }
      case "flowsFilter": {
        filter = flowsFilter;
        break;
      }
      case "inputSearchFilter": {
        filter = inputSearchFilter;
        break;
      }
      case "eventTypeFilter": {
        filter = eventTypeFilter;
        break;
      }
    }

    setFilters(
      listName,
      (filter as string[]).filter((_, index) => index !== valueIndex)
    );
  };

  const clearFilters = () => {
    // Clear url query params
    if (search) {
      history.push("/activity-log");
    }
    resetFilters(unsupportedFilters);
  };

  const handleAddFilter = (newFilters: DocumentsFilterProps) => {
    const { field, values } = newFilters;
    if (!field) return;
    setFilters(field, values as string[]);

    toggleAdvancedDrawer(false);
  };

  const appliedFilterRender = (
    list: string[],
    listName: string,
    optionName?: string,
    formatValue?: boolean
  ) => {
    if (!list || list.length === 0) {
      return null;
    }

    return list.map((value, index) => {
      const valueRef = !optionName
        ? undefined
        : requiredExtraFilters
            ?.find((option) => option.key === optionName)
            ?.suggestions?.find((op) => op.value === value);
      const iterationValue =
        (valueRef ? valueRef?.label || value : value) || "-";

      return (
        <Box key={value} className={classes.valueTag}>
          <CoreTag
            label={formatValue ? _startCase(iterationValue) : iterationValue}
            onDelete={() => removeListItem(index, listName)}
          />
        </Box>
      );
    });
  };

  return (
    <>
      <Grid container direction="row" className={classes.container} spacing={2}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <SearchFilter
            fullWidth
            name={inputSearchFilter}
            setFilters={handleSearchChange}
            toggleDrawer={() => toggleAdvancedDrawer(true)}
            extendedClassName={classes.searchFilterOverwrite}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <CoreAutocomplete
            label={t("status")}
            options={currentStatuses}
            selections={statusFilter}
            onUpdate={handleStatusSelect}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Box className={classes.dateIconButton}>
            <Box className={classes.dateFilterStyle} onClick={toggleDatePicker}>
              <IconButton style={{ color: "inherit" }}>
                <DateRangeIcon />
              </IconButton>

              <Typography className={classes.timeLabel}>
                {t("time_filter")}
              </Typography>
            </Box>
            {isFilterApplied && (
              <CoreTooltip title={t("context_event_clear_all_filters_tooltip")}>
                <span>
                  <IconButton onClick={clearFilters}>
                    <RemoveCircleOutlinedIcon />
                  </IconButton>
                </span>
              </CoreTooltip>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" className={classes.valueDisplayContainer}>
        {appliedFilterRender(inputSearchFilter, "inputSearchFilter")}

        {isFlowFilterSupported ? (
          appliedFilterRender(flowsFilter, "flowsFilter", "flowsFilter")
        ) : (
          <></>
        )}

        {appliedFilterRender(statusFilter, "statusFilter", undefined, true)}

        {appliedFilterRender(
          eventTypeFilter,
          "eventTypeFilter",
          "eventTypeFilter"
        )}

        {dateFilter?.[0] && (
          <Box className={classes.valueTag}>
            <CoreTag
              label={formatDateFilter(dateFilter)}
              onDelete={() => setFilters(FILTER_PROPS.dateFilter, null)}
            />
          </Box>
        )}
      </Box>

      <DatePicker
        t={t}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        onUpdate={handleChangeDate}
      />

      <AdvancedFiltersDrawer
        disableOperator
        translation={t}
        isOpened={isAdvancedDrawerOpened}
        fieldOptions={requiredExtraFilters as SelectOptionDef[]}
        onClose={() => toggleAdvancedDrawer(false)}
        onConfirm={handleAddFilter}
      />
    </>
  );
});

export default memo(FiltersContent);
