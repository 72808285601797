import React from "react";

import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Button, { ButtonProps } from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";

interface CoreButtonProps extends ButtonProps {
  errorText?: string;
  isLoading?: boolean;
  loadingSize?: number;
  isFullWidth?: boolean;
  containerClass?: string;
}

const CoreButton: React.FC<CoreButtonProps> = ({
  errorText,
  isLoading,
  loadingSize,
  isFullWidth,
  children,
  containerClass,
  ...additionalProps
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    formContainer: {
      width: isFullWidth ? "100%" : undefined,
    },
    buttonError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    // TODO: Find another way to hide text when button is loading
    loading: {
      "&.Mui-disabled": { color: "transparent" },
    },
    loadingIcon: {
      margin: "0 auto",
      position: "absolute",
      color: theme.palette.highlight.main,
    },
  });

  const classes = useStyles();

  const { className, ...rest } = additionalProps;

  let buttonClass;
  if (errorText) buttonClass = classes.buttonError;
  if (isLoading) buttonClass = classNames(classes.loading, className || "");

  return (
    <FormControl
      error={!!errorText}
      className={containerClass || classes.formContainer}
    >
      <Button className={buttonClass ?? (className || "")} {...rest}>
        {isLoading && (
          <CircularProgress
            size={20 || loadingSize}
            className={classes.loadingIcon}
          />
        )}
        {children}
      </Button>
      {errorText && (
        <FormHelperText error={!!errorText}>{`*${errorText}`}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CoreButton;
