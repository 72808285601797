import { InputProps } from "@mui/material";
import { SelectOptionDef } from "../interfaces";

export interface BillingIntentAPI {
  publishableKey: string;
  clientSecret: string;
  provider: string;
}

export interface PaginatedSubscriptionUsageHistory {
  items: SubscriptionUsageHistory[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface SubscriptionUsageHistory {
  date: string;
  included: {
    total: number;
    used: number;
  };
  extra: {
    total: number;
    used: number;
  };
  group: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
}

export interface BillingCustomer {
  id: string;
  name: string;
  email: string;
  phone?: string;
  billingInformation?: BillingCustomerInformation;
  companyInformation?: BillingCustomerCompany;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
  // Used for UI
  country?: string;
}

export interface BillingCustomerInformation {
  email: string;
  name: string;
  country: {
    name: string;
    code: string;
  };
  address: {
    city: string;
    line1: string;
    line2: string;
    postalCode: string;
  };
  taxId: string;
  registrationNumber: string;
}

export interface BillingCustomerCompany {
  email: string | null;
  name: string | null;
  country: {
    name: string | null;
    code: string | null;
  };
  address: {
    city: string | null;
    line1: string | null;
    line2: string | null;
    postalCode: string | null;
  };
  taxId: string | null;
  registrationNumber: string | null;
}

export interface SubscriptionPlan {
  id: string;
  plan: string;
  productId: string;
  priceId: string;
  price: number;
  priceExtra: number;
  priceExtraUnit: string;
  period: string;
  name: string;
  description: string;
  currency: string;
  billable: boolean;
  discount: number;
  subscribable: boolean;
  subscriptionProperties: {
    "price-detail": string;
    "usage-limit": string;
    color: string;
    "is-discount": string;
    limit: string;
    order: string;
    "ui-name": string;
    visible: string;
  };
  subscriptionBenefits: string[];
  subscriptionAdditionalPricing: string[];
  subscriptionModelType: string;
  subscriptionModelSpecs: {
    allowOverflow: true;
    usageLimit: 5;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  createdAt: number;
  expiresAt: number;
  card: string;
  status: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
}

export interface SubscriptionReceipt {
  date: string;
  amount: number;
  status: string;
  id: string;
  card: string;
  currency: string;
}

export interface SubscriptionUsage {
  currentUsage: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  };
  // transaction: any; // Not used yet
}

export interface BillingCountry {
  code: string;
  name: string;
}

export enum SUBSCRIPTION_TAB_KEYS {
  overview = "overview",
  usageHistory = "usageHistory",
  receipts = "receipts",
  billingDetails = "billingDetails",
}

export const SUBSCRIPTION_TAB_INDEXES = {
  [SUBSCRIPTION_TAB_KEYS.overview]: 0,
  [SUBSCRIPTION_TAB_KEYS.usageHistory]: 1,
  [SUBSCRIPTION_TAB_KEYS.receipts]: 2,
  [SUBSCRIPTION_TAB_KEYS.billingDetails]: 3,
};

export interface BILLING_FIELD_CONFIG {
  name: string;
  title: string;
  description?: string;
  isMandatory?: boolean;
  placeholder?: string;
  type: "select" | "input" | "country_postal"; // country_postal is a custom type for combining the two fields
  props: InputProps | { options: SelectOptionDef[] };
}

export const BILLING_CONTRACT_TERMS = {
  month: "month",
  year: "year",
};

export const BILLING_CARD_TYPES = {
  existing: "existing", // Use existing card for payment
  new: "new", // Create new card for payment
};

export const PAYMENT_PROVIDERS = {
  stripe: "stripe", // Currently supported
};

export const PREDEFINED_PLANS = {
  free: "free",
};
