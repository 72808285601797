import { RenderTree } from "../../../../../../types/interfaces";

export class FileBrowserHelper {
  static orderRenderTree = (node: RenderTree) => {
    if (node.children && node.children.length > 0) {
      node.children.sort((a, b) => {
        const aIsFolder = a.children && a.children.length > 0;
        const bIsFolder = b.children && b.children.length > 0;

        if (aIsFolder && !bIsFolder) {
          return -1;
        }
        if (!aIsFolder && bIsFolder) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });

      node.children.forEach(this.orderRenderTree);
    }
  };

  static transformDataToNodes = (
    data: { path: string; isFolder: boolean }[]
  ): RenderTree => {
    const rootNode: RenderTree = {
      id: "/",
      name: "root",
      children: [],
    };
    const nodeMap: { [key: string]: RenderTree } = { "": rootNode };

    for (const item of data) {
      const { path, isFolder } = item;
      const parts = path.split("/").filter((part) => part !== ""); // Split the path and remove empty parts

      let parent: RenderTree = rootNode;
      let fullPath = "";

      for (const element of parts) {
        fullPath += `${element}/`;

        if (!Object.prototype.hasOwnProperty.call(nodeMap, fullPath)) {
          const node: RenderTree = {
            id: fullPath,
            name: element,
          };

          nodeMap[fullPath] = node;
          parent.children = parent.children || [];
          parent.children.push(node);
        }

        parent = nodeMap[fullPath];
      }

      if (!isFolder && parts.length === 1 && parent !== rootNode) {
        const fileName = parts[parts.length - 1];
        const fileFullPath = parts[0] + "/";
        const existingFileNode = nodeMap[fileFullPath];

        if (existingFileNode) {
          existingFileNode.name = fileName;
        } else {
          const node: RenderTree = {
            id: fullPath,
            name: fileName,
          };
          parent.children = parent.children || [];
          parent.children.push(node);
          nodeMap[path] = node;
        }
      }
    }
    this.orderRenderTree(rootNode);

    return rootNode;
  };

  static getChildrenFromId = (id: string, tree: RenderTree): RenderTree[] => {
    if (tree.id === id) {
      return tree.children || [];
    }
    if (tree.children) {
      for (const element of tree.children) {
        const result = this.getChildrenFromId(id, element);
        if (result.length > 0) {
          return result;
        }
      }
    }
    return [];
  };

  static getAllIdsFromTree = (tree: RenderTree): string[] => {
    const ids = [tree.id];
    if (tree.children) {
      tree.children.forEach((child) => {
        ids.push(...this.getAllIdsFromTree(child));
      });
    }
    return ids;
  };

  static searchChildrenByName = (
    name: string,
    tree: RenderTree
  ): RenderTree[] => {
    const result: RenderTree[] = [];
    if (tree.name.toLowerCase().includes(name.toLowerCase())) {
      result.push(tree);
    }
    if (tree.children) {
      for (const element of tree.children) {
        result.push(...this.searchChildrenByName(name, element));
      }
    }

    //sort result by showing folders first and alphabetically
    result.sort((a, b) => {
      if (a.children && !b.children) {
        return -1;
      }
      if (!a.children && b.children) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return result;
  };

  static getParentPaths = (id: string): string[] => {
    const parts = id.split("/").filter((part) => part !== "");
    const parentPaths: string[] = [];

    let currentPath = "";
    for (let i = 0; i < parts.length - 1; i++) {
      currentPath += parts[i] + "/";
      parentPaths.push(currentPath);
    }

    return parentPaths;
  };
}
