import React from "react";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, ListItemButton, useTheme, alpha } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { makeStyles } from "@mui/styles";

import { STORES, useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { MultiToggle } from "../../../core/ThemeSwitch";
import { appRoutes, authRoutes } from "../../../../configs/routes";

interface Props {
  t: TFunction;
  handleCloseMenu: () => void;
}

const AccountMenu: React.FC<Props> = ({ t, handleCloseMenu }) => {
  const client = useApolloClient();
  const { userStore, root } = useStores();
  const history = useHistory();
  const theme = useTheme();
  const notification = useNotification();

  const useStyles = makeStyles({
    box: {
      display: "flex",
      margin: "15px 0",
    },
    boxIcon: {
      marginTop: "auto",
      width: "40px",
      height: "40px",
    },
    boxUser: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "8px",
    },
    emailText: {
      fontSize: "0.75rem",
      color: alpha(theme.palette.text.primary, 0.5),
    },
    scopeText: {
      fontSize: "0.75rem",
    },
    divider: {
      margin: "5px 0",
    },
    listItemButton: {
      padding: "8px",
      "&.Mui-selected": {
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
    },
    optionText: {
      fontSize: "15px",
    },
    container: {
      paddingTop: "10px",
    },
  });

  const classes = useStyles();

  const handleClickMyAccount = () => {
    history.push(appRoutes.UserAccount());
    handleCloseMenu();
  };

  const performLogout = async () => {
    await userStore
      .logoutUser(client)
      .then(() => {
        root.resetStores();
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "logoutError"));
      });
    await client.resetStore();
  };

  const removeCtxID = () => {
    userStore
      .removeCTX(client)
      .then(async () => {
        await client.resetStore();
        history.push(authRoutes.Namespaces());
        userStore.resetData();
        root.resetSpecificStores(
          STORES.filter((store) => store !== "userStore")
        );
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "logoutError"));
      });
  };

  return (
    <Box>
      <Box className={classes.box}>
        <AccountBoxIcon className={classes.boxIcon} />
        <Box className={classes.boxUser}>
          <Typography>
            {`${userStore.user?.first_name as string} ${
              userStore.user?.last_name as string
            }`}
          </Typography>
          <Typography className={classes.emailText}>
            {userStore.user?.email}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.boxUser}>
        <Typography>
          {t("namespace", {
            namespace: userStore.namespace?.name,
          })}
        </Typography>
        <Typography className={classes.scopeText}>
          {userStore.user?.scope}
        </Typography>
      </Box>
      <MultiToggle label={t("change_theme")} />
      <Box className={classes.container}>
        <Box>
          <ListItemButton
            className={classes.listItemButton}
            onClick={handleClickMyAccount}
          >
            <Typography className={classes.optionText}>
              {t("myAccount")}
            </Typography>
          </ListItemButton>
          <Divider className={classes.divider} />
        </Box>
        <Box>
          <ListItemButton
            className={classes.listItemButton}
            onClick={removeCtxID}
          >
            <Typography className={classes.optionText}>
              {t("changeNamespace")}
            </Typography>
          </ListItemButton>
        </Box>
        <Box>
          <Divider className={classes.divider} />
          <ListItemButton
            className={classes.listItemButton}
            onClick={performLogout}
          >
            <Typography className={classes.optionText}>
              {t("logout")}
            </Typography>
          </ListItemButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountMenu;
