import React, { FC, useState } from "react";
import { observer } from "mobx-react";

import { ThemeToggle } from "../../types/interfaces";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES, THEME_MODES_RO } from "../../types/constants";

import { makeStyles } from "@mui/styles";
import { alpha, Box, Typography, useTheme } from "@mui/material";

interface Props {
  label?: string;
}

const getOptions = (languageFlag: string) => {
  if (languageFlag === "GB") {
    return [
      {
        displayName: THEME_MODES.light,
        value: THEME_MODES.light,
      },
      {
        displayName: THEME_MODES.mono,
        value: THEME_MODES.mono,
      },
      {
        displayName: THEME_MODES.dark,
        value: THEME_MODES.dark,
      },
    ];
  } else {
    return [
      {
        displayName: THEME_MODES_RO.light,
        value: THEME_MODES.light,
      },
      {
        displayName: THEME_MODES_RO.mono,
        value: THEME_MODES.mono,
      },
      {
        displayName: THEME_MODES_RO.dark,
        value: THEME_MODES.dark,
      },
    ];
  }
};

export const MultiToggle: FC<Props> = observer(({ label }) => {
  const { mainStore } = useStores();
  const theme = useTheme();
  const options = getOptions(mainStore.languageFlag);

  const numOptions = options.length;

  const columnWidth = numOptions ? 100 / numOptions : numOptions;

  const [selectedOption, setSelectedOption] = useState<string>(
    mainStore.currentTheme
  );

  const isSelectedOption = (option: string) => option === selectedOption;

  const getSelectedIndex = () => {
    const indexFound = options.findIndex((option) =>
      isSelectedOption(option.value)
    );
    return indexFound > -1 ? indexFound : 0;
  };

  const createToggleOption = (item: ThemeToggle) => {
    const { value, displayName } = item;

    const selectOption = (value: string) => {
      setSelectedOption(value);
      mainStore.setThemeMode(value as THEME_MODES);
    };

    return (
      <Box
        key={value}
        className={`${classes.toggleOption} ${
          isSelectedOption(value) ? classes.selected : ""
        } `}
        onClick={() => selectOption(value)}
      >
        {displayName || value}
      </Box>
    );
  };

  const useStyles = makeStyles({
    toggleWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      padding: "0.3em",
      fontSize: "15px",
      margin: "0 auto",
    },
    toggleContainer: {
      width: "100%",
      margin: "0 auto",
      position: "relative",
      background: "transparent",
      border: "1px solid",
      borderRadius: "2px",
    },
    toggleOption: {
      display: "inline-block",
      position: "relative",
      zIndex: 1,
      textAlign: "center",
      height: "30px",
      lineHeight: "30px",
      cursor: "pointer",
      color: theme.palette.text.primary,
      fontSize: "1em",
      width: `${columnWidth}%`,
      "&:hover": { backgroundColor: theme.palette?.action?.hover },
    },
    selected: {
      color: alpha(theme.palette.text.primary, 0.5),
      cursor: "initial",
      "&:hover": { backgroundColor: "transparent" },
    },
    toggle: {
      position: "absolute",
      height: "100%",
      bottom: "0",
      left: "0",
      transition: "all 0.4s ease-in-out",
      borderRadius: "2px",
      background: alpha(theme.palette.highlight.main, 0.75),
      width: `${columnWidth}%`,
      transform: `translateX(${100 * getSelectedIndex()}%)`,
      WebkitTransform: `translateX(${100 * getSelectedIndex()}%)`,
      MozTransform: `translateX(${100 * getSelectedIndex()}%)`,
      msTransform: `translateX(${100 * getSelectedIndex()}%)`,
    },
  });
  const classes = useStyles();

  return (
    <Box className={classes.toggleWrapper}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Box className={classes.toggleContainer}>
        {options.map((item) => createToggleOption(item))}
        <Box className={classes.toggle} />
      </Box>
    </Box>
  );
});
