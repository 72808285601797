//Library imports
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

//Internal imports
import RestrictDrawer from "./RestrictDrawer";
import CoreDrawer from "../../../../../core/CoreDrawer";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MainDrawer: FC<Props> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation("addFlow");

  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t("drawerRestrict_title")}
    >
      <RestrictDrawer onClose={onClose} t={t} />
    </CoreDrawer>
  );
});

export default MainDrawer;
