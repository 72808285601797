import { gql } from "@apollo/client";

export interface ADD_USER_MUTATION {
  createUserAndContext: {
    newAccessToken: string;
  };
}

export const SIGNUP_JOURNEY = gql`
  mutation createUserAndContext(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $namespace: String!
    $userId: Int
    $oauth_id: String
  ) {
    createUserAndContext(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      namespace: $namespace
      userId: $userId
      oauth_id: $oauth_id
    ) {
      newAccessToken
    }
  }
`;
