import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useStores } from "../../../../../stores/StoresProvider";
import CorePageContainer from "../../../../core/CorePageContainer";
import { UpgradePlanPreview } from "./upgrade/UpgradePlanPreview";
import { UpgradePlanSteps } from "./upgrade/UpgradePlanSteps";
import { appRoutes } from "../../../../../configs/routes";

const useStyles = makeStyles({
  plansContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: "20px",
  },
  noData: {
    marginTop: "30px",
  },
});

export const SubscriptionPackageUpgrade: React.FC = observer(() => {
  const { t, ready } = useTranslation("subscription");
  const { subscriptionStore } = useStores();
  const classes = useStyles();

  const hasSubscriptionPlans = subscriptionStore?.subscriptionPlans?.length > 0;
  const selectedSubscription = subscriptionStore.selectedSubscription;

  useEffect(() => {
    void subscriptionStore.loadPackageUpgradeData();

    return () => {
      subscriptionStore.clearPackages();
    };
  }, [subscriptionStore]);

  const stripePromise = useMemo(
    () =>
      subscriptionStore.billingSetupIntent?.publishableKey
        ? loadStripe(subscriptionStore.billingSetupIntent?.publishableKey)
        : undefined,
    [subscriptionStore.billingSetupIntent?.publishableKey]
  );

  return (
    <CorePageContainer
      canGoBack
      redirectPage={appRoutes.AccountBilling()}
      label={t("subscription_upgrade_title")}
      isPageLoading={!ready || subscriptionStore.isDataLoading}
      isNotFlowPage={true}
    >
      <Box className={classes.plansContainer}>
        {hasSubscriptionPlans && stripePromise ? (
          <Elements stripe={stripePromise}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <UpgradePlanSteps />
              </Grid>

              {!!selectedSubscription && (
                <Grid item xs={12} sm={12} md={4}>
                  <UpgradePlanPreview />
                </Grid>
              )}
            </Grid>
          </Elements>
        ) : (
          <Typography variant="h5" className={classes.noData}>
            {t("subscription_upgrade_no_plans")}
          </Typography>
        )}
      </Box>
    </CorePageContainer>
  );
});
