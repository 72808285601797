import React from "react";
import { Link, TypographyVariant, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

interface CoreLinkProps {
  label: string;
  to: string;
  underline?: "none" | "hover" | "always";
  variant?: TypographyVariant;
  color?: string;
  onClick?: () => void;
}

const CoreTextLink: React.FC<CoreLinkProps> = ({
  label,
  to,
  variant,
  underline = "hover",
  color,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Link
      onClick={onClick}
      to={to}
      underline={underline}
      variant={variant}
      color={color || theme.palette.highlight.main}
      component={NavLink}
    >
      {label}
    </Link>
  );
};

export default CoreTextLink;
