/* eslint-disable @typescript-eslint/no-explicit-any */
import uniqBy from "lodash/uniqBy";
import { isArray, isString, omit } from "lodash";
import { TFunction } from "i18next";

import {
  Ability,
  ApprovalInterface,
  AssetConfiguration,
  AssetType,
  BackendAlgorithmsOptions,
  Connection,
  Document,
  EXPORT_FIELDS,
  EXPORT_KEY,
  EnhancementConfiguration,
  Flow,
  FlowCategory,
  FlowField,
  FlowSummary,
  QADocument,
  FlowDiagram,
  FlowInterface,
  Variable,
  EXPORTED_VARIABLE,
} from "../types/interfaces";
import { FlowEnum, PRIVATE_ASSETS, VAL_TYPES } from "../types/constants";
import { FIELD_DATA_TYPES } from "../types/enums";
import DateHelper from "./dateHelper";
import { DocumentStore } from "../stores/DocumentStore";
import { FlowStore } from "../stores/FlowStore";
import { NotificationContextProps } from "../context/useNotification";
import { AbilityTuple, MongoAbility, MongoQuery } from "@casl/ability";
import NodesHelper from "../components/pages/flow/flow-settings/helper/nodesHelper";

export default class FlowHelper {
  static getUniqueFields(
    flowFields: FlowField[] | undefined = [],
    templateFields: FlowField[] | undefined = []
  ): FlowField[] {
    const fields = [...flowFields, ...templateFields];

    if (!fields || fields?.length === 0) {
      return [];
    }

    const uniqFields = uniqBy(fields, "key");

    return uniqFields;
  }

  static formatFlow(flowDraft: Flow, dataAlgorithmGroups: AssetType[]): Flow {
    const {
      identifier,
      name,
      description,
      manualValidate,
      template,
      dataSource,
      dataExport,
      dataAlgorithms,
      storage,
      fields,
      flowType,
      categories,
      enhancements,
      approvals,
      rejectReasons,
    } = flowDraft;

    //check for not getting defaults algorithms
    const safeDataAlgorithms = dataAlgorithms || {
      algorithms: [],
    };

    const newDataAlgorithms = FlowHelper.formatDataAlgorithms(
      dataAlgorithmGroups,
      safeDataAlgorithms.algorithms ?? [],
      flowType
    );

    return {
      identifier,
      name,
      description,
      flowType,
      manualValidate,
      categories: categories?.map((item) => ({
        ...item,
        labels: item.labels.map((label) => ({
          name: label.key,
          key: label.key,
        })),
      })),
      template: {
        identifier: template?.identifier,
        name: template?.name,
        description: template?.description,
      },
      fields: [
        ...(fields?.map((field) =>
          omit(field, [
            "dataValidation",
            "required",
            "fieldType",
            "fieldFormat",
            "inputKey",
            "originalKey",
          ])
        ) ?? []),
      ],
      dataSource,
      dataExport,
      dataAlgorithms: newDataAlgorithms,
      storage,
      enhancements,
      approvals,
      rejectReasons,
    } as unknown as Flow;
  }

  static setTemplateAssetDefaults(
    assets: AssetConfiguration[]
  ): AssetConfiguration[] {
    if (assets.length > 0)
      return assets.map((asset) => {
        return {
          ...asset,
          isDefault: true,
        };
      });
    return [];
  }

  static filterDefaultAssets(
    assets: AssetConfiguration[]
  ): AssetConfiguration[] {
    return assets.filter((asset) => !asset.isDefault);
  }

  static extractCommonFields(flows: Flow[] | undefined): FlowField[] {
    if (!flows || flows?.length === 0) {
      return [];
    }

    const focusedFlowFields = flows?.map((flow) => flow.fields);
    let commonFields = [] as FlowField[];

    focusedFlowFields.forEach((list) => {
      if (list && list?.length > 0) {
        list.forEach((item) => {
          // Check if the field is found in every flow fields list
          if (
            focusedFlowFields?.every((fields) =>
              fields?.some((field) => field.key === item.key)
            ) &&
            !commonFields?.some((addedItem) => addedItem.key === item.key)
          ) {
            commonFields = [
              ...commonFields,
              {
                ...item,
                type: "fields",
              },
            ];
          }
        });
      }
    });

    return commonFields;
  }

  static extractCommonCategories(flows: Flow[] | undefined): FlowCategory[] {
    if (!flows || flows?.length === 0) {
      return [];
    }

    const focusedFlowCategories = flows?.map((flow) => flow.categories);
    let commonCategories = [] as FlowCategory[];

    focusedFlowCategories.forEach((list) => {
      if (list && list?.length > 0) {
        list.forEach((item) => {
          // Check if the category is found in every flow categories list
          if (
            focusedFlowCategories?.every((categories) =>
              categories?.some((category) => category.key === item.key)
            ) &&
            !commonCategories?.some((addedItem) => addedItem.key === item.key)
          ) {
            commonCategories = [
              ...commonCategories,
              {
                ...item,
                type: "categories",
              },
            ];
          }
        });
      }
    });

    return commonCategories;
  }

  static areAssetsValid(assets: AssetConfiguration[]): boolean {
    if (assets.length === 0) return true;

    return assets.every((asset) => {
      const privateAssetKey = PRIVATE_ASSETS.find(
        (key) => key in asset.parameters
      );

      if (privateAssetKey) return asset.parameters[privateAssetKey] !== null;
      return true;
    });
  }

  static isFlowValid(flow: Flow, connections: Connection[]): boolean {
    const isBasicSettingsValid = flow.name !== "";

    const isStorageValid = () => {
      if (flow.storage.customRepo && flow.storage.repoConnection) {
        const connection = connections.filter(
          (conn) => conn.identifier === flow.storage.repoConnection
        )[0];

        if (
          connection?.type === "s3" ||
          connection?.type === "azure" ||
          connection?.type === "google"
        ) {
          return (
            Object.keys(flow.storage.repoParameters).length > 0 &&
            Object.keys(flow.storage.repoParameters).every(
              (key) => flow.storage.repoParameters[key] !== ""
            )
          );
        } else {
          return true;
        }
      } else {
        return !flow?.storage.customRepo;
      }
    };

    const isCategoriesValid = !!(
      flow.categories && flow.categories?.length > 0
    );

    const isLabelsValid = !!(flow.fields && flow.fields?.length > 0);

    const isFieldSetupValid =
      flow.flowType === "extraction" ? isLabelsValid : isCategoriesValid;
    // Check if assets have oneDriveConnection, googleDriveConnection, or dropboxConnection and then make it null
    // We make it null for security reasons because we don't get the refreshToken from backend

    return (
      isBasicSettingsValid &&
      isStorageValid() &&
      isFieldSetupValid &&
      this.areAssetsValid(flow.dataSource.sources) &&
      this.areAssetsValid(flow.dataExport.dataExports)
    );
  }

  static formatAssets(
    assets: AssetConfiguration[],
    isFlowCloned: boolean
  ): AssetConfiguration[] {
    if (!isFlowCloned) return assets;

    // Check if assets have oneDriveConnection, googleDriveConnection, or dropboxConnection and then make it null
    // We make it null for security reasons because we don't get the refreshToken from backend

    return assets.map((asset) => {
      const privateAssetKey = PRIVATE_ASSETS.find(
        (key) => key in asset.parameters
      );

      if (privateAssetKey) {
        return {
          ...asset,
          parameters: {
            ...asset.parameters,
            [privateAssetKey]: null,
          },
        } as unknown as AssetConfiguration;
      }
      return asset;
    });
  }

  static checkIfValueIsEmpty(
    value: unknown,
    canContainSpacesOnly?: boolean
  ): boolean {
    if (isString(value)) {
      if (canContainSpacesOnly) return value.length === 0;
      return value.trim().length === 0;
    }

    if (isArray(value)) {
      return value.length === 0;
    }

    return !value;
  }

  static buildExportFields(parameters: { [key: string]: unknown }): unknown {
    let exportFields = {};
    if (parameters.exportFields) {
      (parameters.exportFields as EXPORT_KEY[])
        .filter((field) => field?.willExport)
        .forEach((field) => {
          exportFields = {
            ...exportFields,
            ...{
              ...(field.headers
                ? {
                    [field.key]: {
                      exportValue: field.exportKey,
                      headers: {
                        ...field.headers
                          ?.filter((header) => header.willExport)
                          ?.reduce(
                            (acc, header) => ({
                              ...acc,
                              [header.key]: { exportValue: header.exportKey },
                            }),
                            {}
                          ),
                      },
                    },
                  }
                : {
                    [field.key]: { exportValue: field.exportKey },
                  }),
            },
          };
        });
    }

    return exportFields;
  }

  static buildExportVariables(parameters: { [key: string]: unknown }): unknown {
    let exportFields = {};
    if (parameters.exportFields) {
      (parameters.exportFields as EXPORT_KEY[])
        .filter((field) => field?.willExport)
        .forEach((field) => {
          exportFields = {
            ...exportFields,
            ...{
              [field.key]: { exportValue: field.exportKey },
            },
          };
        });
    }

    return exportFields;
  }

  static getExportFields(
    exportFields?: EXPORT_FIELDS,
    flow?: Flow | FlowSummary
  ): EXPORT_KEY[] {
    if (exportFields) {
      if (flow?.flowType === FlowEnum.extraction) {
        return (flow?.fields ?? [])?.map((field) => {
          const exportField = Object.keys(exportFields)?.find(
            (item) => item === field.key
          );

          const getExportedHeaders = () => {
            const hasHeaders = field.dataType?.parameters?.headers;
            if (!hasHeaders) return null;

            const headers = (
              field.dataType?.parameters?.headers as FlowField[]
            )?.map((header) => header.key);

            return (
              headers?.map((header) => {
                return {
                  willExport: exportField
                    ? !!exportFields[exportField]?.headers?.[header]
                    : false,
                  key: header,
                  name: header,
                  exportKey: exportField
                    ? exportFields[exportField].headers?.[header]
                        ?.exportValue ?? ""
                    : header,
                };
              }) || []
            );
          };

          return {
            willExport: !!exportField,
            key: field.key,
            name: field.name,
            exportKey: exportField
              ? exportFields[exportField].exportValue
              : field.key,
            headers:
              field.dataType?.key !== FIELD_DATA_TYPES.tableDataType
                ? null
                : getExportedHeaders(),
          };
        });
      } else {
        return (flow?.categories ?? [])?.map((category) => {
          const exportCategory = Object.keys(exportFields)?.find(
            (item) => item === category.key
          );

          return {
            willExport: !!exportCategory,
            key: category.key,
            name: category.name,
            exportKey: exportCategory ?? category.key,
            headers: null,
          };
        });
      }
    }

    if (flow?.flowType === FlowEnum.extraction) {
      return (flow?.fields ?? [])?.map((field) => ({
        willExport: true,
        key: field.key,
        name: field.name,
        exportKey: field.key,
        headers:
          field.dataType?.key === FIELD_DATA_TYPES.tableDataType
            ? (field.dataType?.parameters?.headers as FlowField[])?.map(
                (header) => ({
                  key: header.key,
                  name: header.name,
                  exportKey: header.key,
                  willExport: true,
                })
              ) ?? []
            : null,
      }));
    } else {
      return (flow?.categories ?? [])?.map((category) => ({
        willExport: true,
        key: category.key,
        name: category.name,
        exportKey: category.key,
        headers: null,
      }));
    }
  }

  static getExportVariables(
    variables: Variable[] = [],
    exportFields?: EXPORTED_VARIABLE
  ): EXPORT_KEY[] | [] {
    if (exportFields) {
      return (variables ?? []).map((variable) => {
        const exportVariable = Object.keys(exportFields)?.find(
          (item) => item === variable.key
        );

        return {
          willExport: !!exportVariable,
          key: variable.key,
          name: variable.name,
          exportKey: exportVariable
            ? exportFields[exportVariable].exportValue
            : variable.key,
          headers: null,
        };
      });
    }

    return (variables ?? []).map((field) => ({
      willExport: true,
      key: field.key,
      name: field.name,
      exportKey: field.key,
      headers: null,
    }));
  }

  static formatDocument(
    document: Document | QADocument
  ): Document | QADocument {
    return {
      ...document,
      ...(document.createdAt && {
        createdAt: DateHelper.timeStringFormat(document.createdAt),
      }),
      ...(document.updatedAt && {
        updatedAt: DateHelper.timeStringFormat(document.updatedAt),
      }),
    };
  }

  static replaceHeader(
    store: DocumentStore,
    originalColumn: string,
    newColumn: string
  ): FlowField[] {
    const flowHeaders = store.focusedLineItemHeaders;
    const detectionHeaders = store.unusedLineItemHeaders;

    // If new column is already configured, just exclude it from config
    if (flowHeaders?.some((col) => col.key === newColumn)) {
      return store.focusedLineItemHeaders?.filter(
        (col) => col.key !== originalColumn
      );
    }

    // Selected column is within detection columns
    if (detectionHeaders?.some((col) => col.key === newColumn)) {
      const column = detectionHeaders.find((col) => col.key === newColumn);
      const newColumnDef = {
        ...(column || {}),
        name: column?.text || newColumn,
        description: "",
        type: FIELD_DATA_TYPES.textDataType,
        isMandatory: false,
        isVisible: true,
      } as FlowField;

      return store.focusedLineItemHeaders?.map((column) =>
        column.key === originalColumn ? newColumnDef : column
      );
    }

    return store.focusedLineItemHeaders;
  }

  static onFileUpload = (
    event: React.FormEvent<HTMLInputElement>,
    onFormChange: (value: string) => void,
    notification: NotificationContextProps,
    t: TFunction
  ) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target?.files && target?.files.length > 0) {
      const file = target.files[0];
      file
        .text()
        .then((contents) => {
          const fileData = contents;
          onFormChange(fileData);
        })
        .catch(() => {
          notification.error(t("uploadJSONError"));
        });
    }
  };

  static validateFields = (inputFields: FlowField[]) => {
    const fields = (inputFields || []).filter(
      (inputField) =>
        inputField.source === "flowConfig" &&
        inputField.category !== FlowEnum.lineItems
    );

    const validFields =
      fields.filter(
        (field) =>
          field.name &&
          typeof field.name === VAL_TYPES.string &&
          field.key &&
          typeof field.key === VAL_TYPES.string &&
          field.category &&
          typeof field.category === VAL_TYPES.string &&
          field.dataType?.key &&
          typeof field.dataType?.key === VAL_TYPES.string &&
          field.dataType?.parameters?.fieldFormat &&
          typeof field.dataType?.parameters?.fieldFormat === VAL_TYPES.string
      ) ?? [];

    const validFieldsKeys = validFields.map((field) => field.key) ?? [];

    return { validFields, validFieldsKeys };
  };

  static validateCategories = (inputCategories: FlowCategory[]) => {
    const validCategories =
      (inputCategories || []).filter(
        (category) =>
          category.name &&
          typeof category.name === VAL_TYPES.string &&
          category.key &&
          typeof category.key === VAL_TYPES.string &&
          category.labels &&
          Array.isArray(category.labels) &&
          category.labels.length > 0 &&
          category.labels.filter(
            (label) =>
              label.name &&
              typeof label.name === VAL_TYPES.string &&
              label.key &&
              typeof label.key === VAL_TYPES.string
          )
      ) ?? [];

    const validCategoriesKeys =
      validCategories.map((category) => category.key) ?? [];

    return { validCategories, validCategoriesKeys };
  };

  static validateLineItemFields = (inputFields: FlowField[]) => {
    const fields = (inputFields || []).filter(
      (inputField) =>
        inputField.source === "flowConfig" &&
        inputField.category === FlowEnum.lineItems
    );

    const lineItemFields =
      fields.filter(
        (field) =>
          field.name &&
          typeof field.name === VAL_TYPES.string &&
          field.key &&
          typeof field.key === VAL_TYPES.string &&
          field.category === FlowEnum.lineItems &&
          field.dataType?.key === FIELD_DATA_TYPES.tableDataType &&
          field.dataType?.parameters?.headers &&
          (field.dataType?.parameters?.headers as FlowField[]).filter(
            (header) =>
              header.name &&
              typeof header.name === VAL_TYPES.string &&
              header.key &&
              typeof header.key === VAL_TYPES.string &&
              header.dataType?.key &&
              typeof header.dataType?.key === VAL_TYPES.string &&
              header.dataType?.parameters?.fieldFormat &&
              typeof header.dataType?.parameters?.fieldFormat ===
                VAL_TYPES.string
          )
      ) ?? [];

    const headersKeys =
      lineItemFields
        .map((lineItem) =>
          (lineItem?.dataType?.parameters?.headers as FlowField[]).map(
            (header) => header.key
          )
        )
        .flat() ?? [];

    return { lineItemFields, headersKeys };
  };

  static validateEnhancements = (
    enhancements: EnhancementConfiguration[],
    validLabelsKeys: string[]
  ) => {
    let validEnhancements: EnhancementConfiguration[] = [];

    for (let i = (enhancements || []).length - 1; i >= 0; i--) {
      const currentInputFields = (
        enhancements[i].parameters as unknown as { inputFields: string[] }
      ).inputFields;

      const outputFieldsBefore = enhancements
        .slice(0, i)
        .map(
          (enhancement) =>
            (enhancement.parameters as unknown as { outputFields: string[] })
              .outputFields
        )
        .flat();

      const validEnhancement = currentInputFields.every(
        (element) =>
          outputFieldsBefore.includes(element) ||
          validLabelsKeys.includes(element)
      );

      if (validEnhancement) {
        validEnhancements = [enhancements[i], ...validEnhancements];
      }
    }

    const validEnhancementsKeys = validEnhancements
      .map((enh) => [
        ...(enh.parameters as unknown as { inputFields: string[] }).inputFields,
        ...(enh.parameters as unknown as { outputFields: string[] })
          .outputFields,
      ])
      .flat();

    return { validEnhancements, validEnhancementsKeys };
  };

  static validateExtractionApprovals = (
    approvals: ApprovalInterface[],
    checkApprovalsKeys: string[]
  ) => {
    const validApprovals = (approvals || [])?.filter(
      (approval) =>
        approval.name &&
        typeof approval.name === VAL_TYPES.string &&
        approval.key &&
        typeof approval.key === VAL_TYPES.string &&
        (approval.operator === "or" || approval.operator === "and") &&
        approval.rules &&
        approval.rules.filter(
          (rule) =>
            rule.parameters.field &&
            rule.parameters.condition &&
            (rule.parameters.documentReference === true
              ? rule.parameters.referenceField !== ""
              : rule.parameters.referenceValue !== "")
        )
    );

    const validApprovalsAndRules = validApprovals?.filter((approval) =>
      approval.rules.map((rule) =>
        rule.parameters.documentReference === true &&
        rule.parameters.referenceField !== ""
          ? checkApprovalsKeys.includes(rule.parameters.field as string) &&
            checkApprovalsKeys.includes(
              rule.parameters.referenceField as string
            )
          : checkApprovalsKeys.includes(rule.parameters.field as string)
      )
    );

    return validApprovalsAndRules;
  };

  static validateClassificationApprovals = (
    approvals: ApprovalInterface[],
    checkApprovalsKeys: string[]
  ) => {
    const validApprovals = (approvals || [])?.filter(
      (approval) =>
        approval.name &&
        typeof approval.name === VAL_TYPES.string &&
        approval.key &&
        typeof approval.key === VAL_TYPES.string &&
        (approval.operator === "or" || approval.operator === "and") &&
        approval.rules &&
        approval.rules.filter(
          (rule) =>
            (rule.parameters.category &&
              rule.parameters.condition &&
              rule.parameters.labels &&
              rule.parameters.referenceCategory &&
              rule.parameters.referenceCondition &&
              rule.parameters.referenceLabels) ||
            (rule.parameters.category &&
              rule.parameters.condition &&
              rule.parameters.labels &&
              rule.parameters.referenceValue)
        )
    );

    const validApprovalsAndRules =
      validApprovals?.filter((approval) =>
        approval.rules.map(
          (rule) =>
            ((rule.parameters.labels || []) as string[]).every((label) =>
              checkApprovalsKeys.includes(label)
            ) &&
            (((rule?.parameters?.referenceLabels as string[]) || []).length > 0
              ? ((rule?.parameters?.referenceLabels as string[]) || []).every(
                  (label) => checkApprovalsKeys.includes(label)
                )
              : [])
        )
      ) || [];

    return validApprovalsAndRules;
  };

  static removeIdentifiersFromPayload = (json: any, keyToRemove: string) => {
    for (const prop in json) {
      if (Object.prototype.hasOwnProperty.call(json, prop)) {
        if (
          prop === keyToRemove &&
          (json as unknown as { [key: string]: string })[prop]
        ) {
          delete (json as unknown as { [key: string]: string })[prop];
        } else if (
          typeof (json as unknown as { [key: string]: string })[prop] ===
          "object"
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          this.removeIdentifiersFromPayload(json[prop], keyToRemove);
        }
      }
    }
    return json as unknown as Flow;
  };

  static removeKeyFromObject(json: any, keyToRemove: string) {
    const result = { ...json } as FlowInterface;

    if (keyToRemove in result) {
      delete (result as unknown as { [key: string]: string })[keyToRemove];
    }

    return result;
  }

  //TODO: To be removed
  static validateFlowConfigPayload = (inputJson: Flow) => {
    let validFlowData = {};

    const json = this.removeIdentifiersFromPayload(inputJson, "identifier");

    if (json.name && typeof json.name === VAL_TYPES.string)
      validFlowData = { ...validFlowData, name: json.name };

    if (json.description && typeof json.description === VAL_TYPES.string)
      validFlowData = { ...validFlowData, description: json.description };

    if (json.dataSource) {
      validFlowData = {
        ...validFlowData,
        dataSource: json.dataSource,
      };
    }

    if (json.dataExport) {
      validFlowData = {
        ...validFlowData,
        dataExport: json.dataExport,
      };
    }

    if (
      json.rejectReasons &&
      Array.isArray(json.rejectReasons) &&
      json.rejectReasons.length > 0
    ) {
      const validRejectReasons = json.rejectReasons.filter(
        (rejectReason) =>
          rejectReason.message && rejectReason.description && rejectReason.code
      );

      validFlowData = {
        ...validFlowData,
        rejectReasons: validRejectReasons,
      };
    }

    return validFlowData;
  };

  static getJsonText = async (file: File) => {
    const text = await new Response(file).text();
    return text;
  };

  static preprocessFlowImportFile = async (
    file: File,
    notifyError: () => void
  ) => {
    const text = await this.getJsonText(file);
    const json = JSON.parse(text) as { [key: string]: unknown };

    let flowDiagram = null;

    const { diagram, ...flowConfig } = json;

    const flow = this.removeIdentifiersFromPayload(flowConfig, "identifier");

    if (diagram) {
      if (NodesHelper.validateDiagram(diagram as unknown as FlowDiagram)) {
        flowDiagram = diagram as FlowDiagram;
      } else {
        notifyError();
      }
    }

    return { flow, flowDiagram };
  };

  static getIndexofNthObjectInGroup = (
    arr: AssetConfiguration[],
    groupName: string,
    n: number
  ) => {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].type === groupName) {
        count++;
        if (count === n) {
          return i;
        }
      }
    }
    return -1;
  };

  static formatDataAlgorithms = (
    algorithmTypes: AssetType[],
    algorithms: AssetConfiguration[],
    flowType: string
  ) => {
    const algTypes = [...new Set(algorithmTypes.map((algType) => algType.key))];

    return algTypes.reduce(
      (acc, field) => ({
        ...acc,
        [field]: {
          ...algorithmTypes.find((item) => item.key === field),
          type: "algorithm",
          flowType,
          algorithms: algorithms.filter((item) => item.type === field),
        },
      }),
      {}
    );
  };

  static formatAlgorithmsForLoadFlow(algorithms: BackendAlgorithmsOptions) {
    const newAlg: AssetConfiguration[] = [];
    if (!algorithms) return null;
    Object.keys(algorithms).forEach((key) =>
      newAlg.push(...algorithms[key].algorithms)
    );
    return newAlg;
  }

  static overWriteParametersForDataAlgorithmsGroup = (
    dataAlgorithmsGroup: AssetType[],
    backendDataAlgorithms: BackendAlgorithmsOptions
  ): AssetType[] => {
    return dataAlgorithmsGroup.map((asset) => {
      const getFlowDataAlgorithm = backendDataAlgorithms[asset.key];

      if (getFlowDataAlgorithm && asset.hasParams) {
        return {
          ...asset,
          parameters: {
            ...(asset.parameters || {}),
            ...getFlowDataAlgorithm.parameters,
          },
          name: getFlowDataAlgorithm.name || asset.name,
          description: getFlowDataAlgorithm.description || "",
        };
      }

      return asset;
    });
  };

  static verifyAbility(
    permission: (MongoAbility<AbilityTuple, MongoQuery> | undefined)[],
    permToCheck: Ability
  ) {
    const { action, subject } = permToCheck;
    return permission.some((perm) => perm?.can(action, subject));
  }

  static extractNonEditableOutputFieldsKeys = (
    array: string[],
    fieldKey: string
  ) => {
    return array
      .filter((element) => element.startsWith("outputFields."))
      .map((element) => element.split(".")[1])
      .includes(fieldKey);
  };

  static handleExpandCardFlow = (
    flowStore: FlowStore,
    exportKey: string,
    clickedExpandButton?: boolean,
    e?: React.FormEvent<HTMLButtonElement>
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (clickedExpandButton) {
      const config = {} as { [key: string]: boolean };
      config[exportKey] = !flowStore.expandedCardFlow[exportKey];
      flowStore.setExpandedCardFlow({
        ...flowStore.expandedCardFlow,
        ...config,
      });
    } else {
      const config = Object.keys(flowStore.expandedCardFlow).reduce(
        (item, key) => {
          (item as unknown as { [key: string]: boolean })[key] = false;
          return item;
        },
        {}
      ) as unknown as { [key: string]: boolean };

      config[exportKey] = true;
      flowStore.setExpandedCardFlow(config);
    }
  };
}
