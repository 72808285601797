import { TFunction } from "i18next";
import moment from "moment";

import { DATE_FORMAT } from "../types/constants";

export default class DateHelper {
  static getStartDay(days: number): Date {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - days);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  static getEndDay(days: number): Date {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - days);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }

  static getThisWeek(): Date {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - ((newDate.getDay() + 6) % 7));
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  static getStartLastWeek(): Date {
    const startWeek = new Date();
    startWeek.setDate(this.getThisWeek().getDate() - 7);
    startWeek.setHours(0, 0, 0, 0);

    return startWeek;
  }

  static getEndLastWeek(): Date {
    const endWeek = new Date();
    endWeek.setDate(this.getThisWeek().getDate() - 1);
    endWeek.setHours(23, 59, 59, 999);

    return endWeek;
  }

  static getStartThisMonth(): Date {
    const startMonth = new Date();
    startMonth.setDate(1);
    startMonth.setHours(0, 0, 0, 0);

    return startMonth;
  }

  static getEndThisMonth(): Date {
    const endMonth = new Date();
    endMonth.setMonth(this.getEndDay(0).getMonth() + 1);
    endMonth.setDate(0);
    endMonth.setHours(23, 59, 59, 999);

    return endMonth;
  }

  static getStartLastMonth(): Date {
    const startMonth = new Date();
    startMonth.setDate(1);
    startMonth.setMonth(this.getStartDay(0).getMonth() - 1);
    startMonth.setHours(0, 0, 0, 0);

    return startMonth;
  }

  static getEndLastMonth(): Date {
    const endMonth = new Date();
    endMonth.setMonth(this.getEndDay(0).getMonth());
    endMonth.setDate(0);
    endMonth.setHours(23, 59, 59, 999);

    return endMonth;
  }

  static timeStringFormat = (
    time: string | undefined,
    hours = true,
    format = true // format date
  ) => {
    //If hours is true, then it will return the time with hours and minutes
    try {
      if (!time) {
        return "-";
      }

      if (Date.parse(time)) {
        const timeDate = time?.includes("Z")
          ? new Date(time)
          : new Date(time + "Z");

        const utcDate = moment(timeDate.toISOString()).utc().local();

        if (format) {
          return utcDate.format(
            hours ? DATE_FORMAT.dateTime : DATE_FORMAT.date
          );
        }

        return utcDate.toString();
      }
      return "-";
    } catch {
      return "-";
    }
  };

  // Obtain format such as: 1 hour ago, 30 seconds ago, 40 minutes ago...
  static getTimeDifference = (
    time: string | null | undefined,
    translate: TFunction
  ) => {
    try {
      if (!time) {
        return "";
      }

      const date: Date = new Date(
        this.timeStringFormat(time, undefined, false)
      );
      const currentDate = new Date();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const diffTime = Math.abs((date as any) - (currentDate as any));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 1) {
        // Few  days ago
        if (diffDays <= 6) {
          return `${diffDays} ${translate("table_col_time_n_days_ago")}`;
        }

        // One week
        if (diffDays === 7) {
          return translate("table_col_time_one_week_ago");
        }

        // N weeks ago
        if (diffDays > 7 && diffDays < 30) {
          return `${Math.ceil(diffDays / 7)} ${translate(
            "table_col_time_n_weeks_ago"
          )}`;
        }

        // One month ago
        if (diffDays > 26 && diffDays <= 30) {
          return translate("table_col_time_one_month_ago");
        }

        // N months ago
        if (diffDays > 30 && diffDays <= 363 && Math.ceil(diffDays / 30)) {
          return `${Math.ceil(diffDays / 30)} ${translate(
            "table_col_time_n_months_ago"
          )}`;
        }

        // One year
        if (diffDays === 364 || diffDays === 365) {
          return translate("table_col_time_one_year_ago");
        }

        // Years ago
        return `${Math.ceil(diffDays / 364)} ${translate(
          "table_col_time_n_years_ago"
        )}`;
      }

      const seconds = Math.floor((diffTime / 1000) % 60);
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60);
      const hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);

      let timeValue = "";
      if (hours > 0) {
        timeValue = `${hours} ${
          hours > 1
            ? translate("table_col_time_hours")
            : translate("table_col_time_hour")
        } `;
      }

      if (minutes > 0 && hours < 1) {
        if (timeValue) {
          timeValue += ", ";
        }
        timeValue += `${minutes} ${
          minutes > 1
            ? translate("table_col_time_minutes")
            : translate("table_col_time_minute")
        } `;
      }

      if (seconds && minutes < 1) {
        if (timeValue) {
          timeValue += translate("table_col_time_and");
        }
        timeValue += `${seconds} ${
          seconds > 1
            ? translate("table_col_time_seconds")
            : translate("table_col_time_second")
        } `;
      }

      if (timeValue) {
        timeValue += translate("table_col_time_ago");
      }

      return timeValue;
    } catch {
      return "-";
    }
  };
}
