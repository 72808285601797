import React, { useEffect, useState, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import _isBoolean from "lodash/isBoolean";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import CoreButton from "../../core/CoreButton";
import { AppSuspense } from "../../main/AppSuspense";
import {
  Connection,
  ConnectionField,
  FormData,
  ConnectionType,
} from "../../../types/interfaces";
import { ConnectionForm } from "./ConnectionForm";
import { infoConfig } from "./defaultConfig";
import CoreDrawer from "../../core/CoreDrawer";
import { ConnectionsHelper } from "./ConnectionsHelper";
import TestConnectionButton from "./components/TestConnectionButton";

const useStyle = makeStyles({
  emptyBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    minHeight: "250px",
    backgroundColor: "transparent",
  },
  typography: {
    alignSelf: "center",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    marginTop: "auto",
    paddingTop: "30px",
  },
  testButton: {
    paddingTop: "30px",
  },
  labelClasses: {
    fontSize: "14px",
  },
  boxButtons: {
    gap: "15px",
    display: "flex",
    width: "100%",
  },
  cancelButton: {
    marginRight: "15px",
  },
});

export interface ConnectionConf {
  name: string;
  fields: ConnectionField[];
}

interface Props {
  isOpen: boolean;
  onClose: (goBack?: boolean) => void;
  id?: string;
  useConn?: boolean;
  hasBackButton?: boolean;
  onBack?: () => void;
  isRepoConn?: boolean;
  setShouldRefetchAsset?: (value: boolean) => void;
}

const CreateConnection: React.FC<Props> = observer(
  ({
    isOpen,
    onClose,
    id,
    useConn = false,
    hasBackButton,
    onBack,
    isRepoConn,
    setShouldRefetchAsset = () => null,
  }) => {
    const { t, ready } = useTranslation("connections");
    const apolloClient = useApolloClient();
    const notification = useNotification();
    const { userStore, flowSettingsStore } = useStores();

    const [formData, setFormData] = useState<FormData>({});
    const [formConfig, setFormConfig] = useState<ConnectionConf>();
    const [availableConnections, setAvailableConnections] = useState<
      ConnectionType[]
    >([]);

    const [isTesting, setIsTesting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConnectionValid, setIsConnectionValid] = useState<
      boolean | undefined
    >(undefined);
    const [selectedId, setSelectedId] = useState<string>();
    const [mandatoryFields, setMandatoryFields] = useState<string[]>([]);
    const [errors, setErrors] = useState<FormData>({});

    const classes = useStyle();

    const clearError = (key: string) => {
      setErrors((current) => {
        delete current[key];

        return current;
      });
    };

    const getFieldsConfig = useMemo(() => {
      const fields = formConfig?.fields ?? [];

      return [
        ...fields.map((field) => {
          const parentKeys = Object.keys(field?.parents ?? {});
          let render = true;
          parentKeys?.forEach((key) => {
            if (!field?.parents?.[key]?.includes(formData?.[key])) {
              render = false;
            }
          });

          if (!render) return {} as ConnectionField;

          return {
            ...field,
          };
        }),
      ] as ConnectionField[];
    }, [formConfig, formData]);

    const isFieldValid = (field: string) =>
      field in formData &&
      ![null, undefined, ""].includes(formData[field] as string);

    useEffect(() => {
      if (isOpen) {
        if (id) {
          userStore
            .getConnectionTypes(apolloClient)
            .then(() => {
              setAvailableConnections(userStore.connectionTypes);
            })
            .then(() => {
              userStore
                .getConnection(apolloClient, id)
                .then(() => {
                  setSelectedId(userStore.connection?.type as string);
                  setFormData({
                    ...userStore.connection,
                  });
                })
                .catch((error: Error) =>
                  notification.error(t(error?.message || "getConnectionError"))
                );
            })
            .catch((error: Error) =>
              notification.error(t(error?.message || "getConnectionError"))
            );
        } else {
          userStore
            .getConnectionTypes(apolloClient)
            .then(() => {
              setAvailableConnections(
                userStore.connectionTypes as unknown as ConnectionType[]
              );
            })
            .catch((error: Error) =>
              notification.error(t(error?.message || "getConnectionTypesError"))
            );
        }

        ConnectionsHelper.populateFields(
          infoConfig(t),
          setMandatoryFields,
          mandatoryFields
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, id]);

    useEffect(() => {
      if (selectedId) {
        userStore
          .getConnectionConfig(apolloClient, selectedId)
          .then(() => {
            const connectionConfig =
              userStore.connectionConfig as unknown as ConnectionConf;

            // Add new fields to form
            if (!id && connectionConfig?.fields?.length > 0) {
              let formattedFormData = { ...formData };

              connectionConfig?.fields?.forEach((newField) => {
                formattedFormData = {
                  ...formattedFormData,
                  [newField?.key]: newField?.value,
                };
              });
              setFormData(formattedFormData);
            }

            setFormConfig(connectionConfig);
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "fetchConnectionError"));
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    useEffect(() => {
      formConfig &&
        ConnectionsHelper.populateFields(
          formConfig.fields,
          setMandatoryFields,
          mandatoryFields
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formConfig]);

    useEffect(() => {
      if (!isOpen) {
        setFormData({});
        setFormConfig(undefined);
        setAvailableConnections([]);
        setIsTesting(false);
        setIsConnectionValid(undefined);
        setSelectedId(undefined);
        setMandatoryFields([]);
        setErrors({});
      }
    }, [isOpen]);

    useEffect(() => {
      ConnectionsHelper.populateFields(
        infoConfig(t),
        setMandatoryFields,
        mandatoryFields,
        true
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    const resetTestConnection = () => {
      if (_isBoolean(isConnectionValid)) {
        setIsConnectionValid(undefined);
      }
    };

    const onFormChange = useCallback(
      (value: FormData) => {
        if (Object.keys(value)[0] === "type") setErrors({});

        if (errors[Object.keys(value)[0]]) {
          clearError(Object.keys(value)[0]);
        }

        resetTestConnection();
        setFormData((prev) => ({ ...prev, ...value }));
        setSelectedId("type" in value ? (value.type as string) : selectedId);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formData, errors, selectedId]
    );

    const formattedInfoConfig = useMemo(() => {
      const infoConfigArray = [...infoConfig(t)];
      const index =
        infoConfigArray.findIndex((item) => item.key === "type") || -1;

      if (index !== -1) {
        infoConfigArray[index].options = (
          isRepoConn
            ? availableConnections.filter((item) => item?.type !== "email")
            : availableConnections
        ).map((item) => ({
          key: item?.type,
          label: item?.name,
          isSvg: item?.icon ? true : false,
          icon: item?.icon,
        }));
      }

      return infoConfigArray;
    }, [t, isRepoConn, availableConnections]);

    const testConnection = (formData: FormData) => {
      setIsTesting(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { identifier, ...config } = formData;

      const updatedConfig = {
        ...config,
        connectionId: identifier,
      };

      userStore
        .testConnection(apolloClient, updatedConfig as unknown as Connection)
        .then((isValid) => {
          setIsConnectionValid(isValid);
          if (isValid) {
            notification.success(t("connection_valid_tooltip"));
          } else {
            notification.error(t("connection_invalid_tooltip"));
          }
        })
        .catch((error: Error) => {
          setIsConnectionValid(false);
          notification.error(t(error?.message || "test_connection_error"));
        })
        .finally(() => setIsTesting(false));
    };

    const createAndUpdateConnection = (
      formData: FormData,
      useConn: boolean
    ) => {
      if (!id) {
        setIsLoading(true);
        userStore
          .createConnection(apolloClient, formData as unknown as Connection)
          .then((connectionId) => {
            userStore.triggerRefetchConnections();
            userStore
              .loadAllConnections()
              .then(() => {
                if (useConn) flowSettingsStore.setRepoConnection(connectionId);
              })
              .catch((err) => {
                throw err;
              });
            onClose(true);
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "create_connection_error"));
          })
          .finally(() => {
            setIsLoading(false);
            setShouldRefetchAsset(true);
          });
      } else {
        setIsLoading(true);
        userStore
          .updateConnection(apolloClient, id, formData as unknown as Connection)
          .then(() => {
            userStore
              .loadAllConnections()
              .then(() => {
                if (useConn) flowSettingsStore.setRepoConnection(id);
              })
              .catch((err) => {
                throw err;
              });
            onClose(true);
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "update_connection_error"));
          })
          .finally(() => {
            setIsLoading(false);
            setShouldRefetchAsset(true);
          });
      }
    };

    const handleSave = (isTest = false) => {
      const canSubmit = mandatoryFields.every((item) => isFieldValid(item));

      if (!canSubmit) {
        let collectedErrors = {};

        mandatoryFields.forEach((item) => {
          if (!isFieldValid(item)) {
            collectedErrors = {
              ...collectedErrors,
              [item]: t("fieldValidation_message"),
            };
          }
        });

        setErrors(collectedErrors);
        return;
      }

      if (isTest) {
        testConnection(formData);
        return;
      }

      createAndUpdateConnection(formData, useConn);
    };

    return (
      <CoreDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={id ? t("edit_connection") : t("add_connection")}
        onBack={onBack}
        hasBackButton={hasBackButton}
      >
        {!isOpen ? (
          <></>
        ) : (id && userStore.loadingConnection) ||
          userStore.loadingConnectionTypes ||
          !ready ? (
          <AppSuspense />
        ) : (
          <>
            <Box>
              <Box>
                <Typography className={classes.labelClasses}>
                  {t("basicInformation")}
                </Typography>

                <ConnectionForm
                  config={formattedInfoConfig}
                  translation={t}
                  data={formData}
                  onChange={onFormChange}
                  errors={errors}
                  id={id}
                  disableForm={isLoading}
                />
              </Box>

              <Box>
                {/* FIXME: Keep for later use <UploadConfig t={t} setFormData={setFormData} /> */}
                {userStore.loadingConnectionConfig ? (
                  <Box className={classes.emptyBox}>
                    <AppSuspense />
                  </Box>
                ) : formConfig ? (
                  <ConnectionForm
                    config={getFieldsConfig}
                    translation={t}
                    data={formData}
                    onChange={onFormChange}
                    errors={errors}
                    disableForm={isLoading}
                  />
                ) : (
                  !id && (
                    <Box className={classes.emptyBox}>
                      <Typography className={classes.typography}>
                        {t("noSettingsToDisplay")}
                      </Typography>
                      <Typography className={classes.typography}>
                        {t("selectConnectionType")}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            </Box>

            <Box className={classes.buttonsContainer}>
              <Box className={classes.testButton} flexDirection="row">
                <TestConnectionButton
                  isConnectionValid={isConnectionValid}
                  isTesting={isTesting}
                  handleSave={handleSave}
                />
              </Box>

              <Box className={classes.buttonsContainer}>
                <Box className={classes.boxButtons}>
                  <CoreButton
                    className={classes.cancelButton}
                    onClick={() => onClose(true)}
                  >
                    {t("cancel")}
                  </CoreButton>

                  <CoreButton
                    onClick={() => handleSave()}
                    disabled={!isConnectionValid || isLoading}
                    isLoading={isLoading}
                    variant="contained"
                  >
                    {t("save")}
                  </CoreButton>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </CoreDrawer>
    );
  }
);

export default CreateConnection;
