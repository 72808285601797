import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { useStores } from "../../../../../../stores/StoresProvider";
import {
  BILLING_CONTRACT_TERMS,
  PREDEFINED_PLANS,
} from "../../../../../../types/interfaces/subscription";
import BillingHelper from "../../../../../../helper/billingHelper";
import { SubscriptionPrice } from "../SubscriptionPrice";

const useStyles = makeStyles({
  container: {
    padding: "0px 20px",
  },
  instruction: {
    marginTop: "5px",
    marginBottom: "30px",
    fontSize: "12px",
    opacity: 0.6,
  },
  divider: {
    marginTop: "7px",
    marginBottom: "7px",
  },
});

const DATE_FORMAT = "MMM D, YYYY";

export const ReviewAndConfirm = observer(() => {
  const { subscriptionStore } = useStores();
  const { t } = useTranslation("subscription");
  const classes = useStyles();

  const selectedSubscription = subscriptionStore.selectedSubscription;

  const periodPlan = useMemo(
    () =>
      selectedSubscription?.plan
        ? subscriptionStore.subscriptionPlans?.find(
            (item) =>
              item?.plan === selectedSubscription?.plan &&
              item?.period === subscriptionStore.contractTerm
          )
        : selectedSubscription,
    [
      subscriptionStore.contractTerm,
      selectedSubscription,
      subscriptionStore.subscriptionPlans,
    ]
  );

  const periodDate = useMemo(() => {
    const contractTerm = (
      subscriptionStore.contractTerm === BILLING_CONTRACT_TERMS.year ? "Y" : "M"
    ) as moment.unitOfTime.DurationConstructor;

    const billingStartDate = moment(new Date()).format(DATE_FORMAT);
    const billingEndDate = moment(new Date())
      .add(1, contractTerm)
      .format(DATE_FORMAT);

    return `${billingStartDate} - ${billingEndDate}`;
  }, [subscriptionStore.contractTerm]);

  return (
    <Box className={classes.container}>
      <Typography>{t("subscription_upgrade_review_title")}</Typography>
      <Typography className={classes.instruction}>
        {t("subscription_upgrade_review_subtitle")}
      </Typography>

      <Typography variant="body2">
        {t("subscription_upgrade_review_subscribe_to", {
          plan: periodPlan?.name || "-",
        })}
      </Typography>

      <Divider className={classes.divider} />

      {/** Need a simple table */}
      <table style={{ width: "100%", marginBottom: "7px" }}>
        <tbody>
          {selectedSubscription?.plan !== PREDEFINED_PLANS.free && (
            <>
              <tr>
                <td>
                  <Typography variant="body2">
                    {t("subscription_upgrade_review_period", { periodDate })}
                  </Typography>
                </td>
                <td style={{ textAlign: "right" }}>
                  <SubscriptionPrice
                    price={periodPlan?.price}
                    currency={periodPlan?.currency as string}
                    styleProps={{ fontSize: "14px" }}
                    containerProps={{ justifyContent: "flex-end" }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Divider className={classes.divider} />
                </td>
              </tr>
            </>
          )}

          {periodPlan && !!periodPlan?.priceExtra && (
            <>
              <tr>
                <td>
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    {t("subscription_upgrade_review_additional_pricing")}
                  </Typography>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    {t("subscription_upgrade_preview_additional_cost_value", {
                      additionalCost: BillingHelper.convertSumToCurrency(
                        periodPlan?.priceExtra,
                        periodPlan?.currency
                      ),
                      additionalCostUnit: periodPlan?.priceExtraUnit,
                    })}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Divider className={classes.divider} />
                </td>
              </tr>
            </>
          )}

          {periodPlan && !!periodPlan?.discount && (
            <>
              <tr>
                <td>
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    {t("subscription_upgrade_review_discount")}
                  </Typography>
                </td>
                <td style={{ textAlign: "right" }}>
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    -{" "}
                    {BillingHelper.convertSumToCurrency(
                      periodPlan?.discount,
                      periodPlan?.currency
                    )}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Divider className={classes.divider} />
                </td>
              </tr>
            </>
          )}

          <tr>
            <td>
              <Typography variant="body1">
                {t("subscription_upgrade_review_total_charge")}
              </Typography>
            </td>
            <td style={{ textAlign: "right" }}>
              <SubscriptionPrice
                price={periodPlan?.price}
                currency={periodPlan?.currency as string}
                containerProps={{ justifyContent: "flex-end" }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
});
