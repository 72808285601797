import React, { useMemo, useState, useEffect } from "react";
import { TFunction } from "i18next";
import _, { isBoolean } from "lodash";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import {
  AssetConfiguration,
  FormData,
  FormField,
  FlowInterface,
  AssetTemplateConfiguration,
} from "../../../types/interfaces";
import { FlowEnum } from "../../../types/constants";
import { alphanumericRegex } from "../../../types/regex";
import ConfigHelper from "../../../helper/configHelper";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { useStores } from "../../../stores/StoresProvider";
import CoreButton from "../../core/CoreButton";
import FlowHelper from "../../../helper/flowHelper";
import {
  NotificationTypes,
  useNotification,
} from "../../../context/useNotification";
import CoreNotificationBox from "../../core/CoreNotificationBox";
import { AppSuspense } from "../../main/AppSuspense";

interface Props {
  t: TFunction;
  onAdd: (value: { [key: string]: unknown }) => void;
  closeDrawer: () => void;
  list: AssetConfiguration[];
  onEdit: (value: { [key: string]: unknown }) => void;
  assetCategoryKey: string;
  selectedOptionKey: string;
}

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
  schemaPreviewButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "16px",
  },
  notificationBox: {
    maxWidth: "540px",
    display: "flex",
    alignSelf: "flex-end",
  },
});

const CustomOptionConfig: React.FC<Props> = ({
  t,
  onAdd,
  closeDrawer,
  list,
  onEdit,
  assetCategoryKey,
  selectedOptionKey,
}) => {
  const { flowSettingsStore, flowStore } = useStores();
  const notification = useNotification();

  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const [assetConfig, setAssetConfig] = useState<AssetTemplateConfiguration>();
  const [fieldsErrors, setFieldsErrors] = useState<{
    [key: string]: string;
  }>({});

  const classes = useStyles();

  const formattedFields = useMemo(
    () =>
      ConfigHelper.getFieldsConfiguration(
        t,
        assetConfig?.parameters || [],
        fieldsErrors,
        formData,
        flowSettingsStore.flow as FlowInterface,
        flowStore.flows,
        setFormData,
        t("fieldNameTitle"),
        true,
        flowSettingsStore.isDiagramEditable
      ),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      assetConfig?.parameters,
      fieldsErrors,
      formData,
      flowSettingsStore.isDiagramEditable,
    ]
  );

  const onFormChange = (updatedForm: FormData, changedKey: string) => {
    if (changedKey === FlowEnum.name) {
      setFieldsErrors({
        ...fieldsErrors,
        [FlowEnum.key]: "",
        [FlowEnum.name]: "",
      });
    } else if (changedKey === FlowEnum.key) {
      if (alphanumericRegex.test(updatedForm.key as string)) return;
      setFieldsErrors({
        ...fieldsErrors,
        [changedKey]: "",
      });
    } else {
      setFieldsErrors({
        ...fieldsErrors,
        [changedKey]: "",
      });
    }

    let newKey = updatedForm?.key;
    if (changedKey === FlowEnum.name) {
      newKey = _.camelCase(updatedForm?.name as string);
    }

    setFormData({
      ...updatedForm,
      key: newKey,
    });
  };

  useEffect(() => {
    setLoading(true);

    flowStore
      .getAssetConfiguration(assetCategoryKey)
      .then((response) => {
        setAssetConfig(response?.getAssetConfig);

        if (selectedOptionKey) {
          const selectedItem = list?.find(
            (item) => item?.key === selectedOptionKey
          );

          if (selectedItem) {
            const { key, identifier, name, description, parameters } =
              selectedItem;
            setFormData({
              key,
              identifier,
              name,
              description,
              ...parameters,
            });
          } else {
            setFormData({
              ...response?.getAssetConfig?.parameters?.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.key]: field.value,
                }),
                {}
              ),
              name: response?.getAssetConfig?.name,
              description: response?.getAssetConfig?.description,
              ...formData,
            });
          }
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "configError"));
      })
      .finally(() => setLoading(false));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedData = (): AssetConfiguration | undefined => {
    const { name, description, key, identifier, ...parameters } = formData;

    const assetConfiguration = {
      name,
      identifier,
      description,
      key: key,
      parameters: {
        ...parameters,
      },
    } as unknown as AssetConfiguration;

    const currentErrors = {} as { [key: string]: string };

    //Check if mandatory field is empty or has undefined value
    if (formattedFields) {
      formattedFields.forEach((config) => {
        if (
          config.isMandatory &&
          !isBoolean(formData[config.key]) &&
          FlowHelper.checkIfValueIsEmpty(
            formData[config.key],
            config.canContainSpacesOnly
          )
        ) {
          currentErrors[config.key] = t("validationFieldsError");
        }
      });
    }

    if (Object.keys(currentErrors).length > 0) {
      setFieldsErrors(currentErrors);
      return;
    } else {
      setFieldsErrors({});
    }

    return assetConfiguration;
  };

  const handleConfirm = () => {
    if (!flowSettingsStore.isDiagramEditable) {
      closeDrawer();
      return;
    }

    const currentErrors = {} as { [key: string]: string };

    if (
      list?.some(
        (item) =>
          item?.key === formData.key && formData?.key !== selectedOptionKey
      )
    ) {
      currentErrors[FlowEnum.key] = t("keyNotUnique");
    }

    const assetConfigurationData = formattedData();

    if (!assetConfigurationData) return;

    if (Object.keys(currentErrors)?.length > 0) {
      setFieldsErrors(currentErrors);
      return;
    }

    const action = selectedOptionKey ? onEdit : onAdd;
    action(assetConfigurationData as unknown as FormData);

    closeDrawer();
  };

  const getButtonLabel = useMemo(() => {
    return !flowSettingsStore.isDiagramEditable
      ? t("close")
      : selectedOptionKey !== ""
      ? t("edit")
      : t("add");
  }, [t, selectedOptionKey, flowSettingsStore.isDiagramEditable]);

  return (
    <>
      {loading ? (
        <AppSuspense />
      ) : (
        <>
          {assetConfig ? (
            <CoreFormRenderer
              fields={formattedFields as unknown as FormField[]}
              data={formData}
              translation={t}
              onChange={onFormChange}
              isViewMode={!flowSettingsStore.isDiagramEditable}
            />
          ) : (
            <CoreNotificationBox
              type={NotificationTypes.error}
              description={t("failToFetchFields")}
            />
          )}

          <Box className={classes.footer}>
            <CoreButton
              variant={
                !flowSettingsStore.isDiagramEditable ? "outlined" : "contained"
              }
              onClick={handleConfirm}
              disabled={!assetConfig}
            >
              {getButtonLabel}
            </CoreButton>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomOptionConfig;
