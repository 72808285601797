import React, { FC, useCallback, useMemo } from "react";
import Konva from "konva";
import { observer } from "mobx-react";

import { Theme } from "@mui/material";

import {
  DocumentLineItem,
  DocumentZone,
} from "../../../../../../types/interfaces";
import { DocumentStore } from "../../../../../../stores/DocumentStore";
import {
  CANVAS_ACTIONS,
  EDITABLE_ZONE_ACTIONS,
} from "../../../../../../types/constants";
import Zone from "../zones/Zone";
import { RowLineDraft } from "./RowLineDraft";
import { ColLineDraft } from "./ColLineDraft";

interface Props {
  table: DocumentLineItem;
  documentStore: DocumentStore;
  theme: Theme;
  isReadOnly: boolean;
}

export const ZoneTable: FC<Props> = observer(
  ({ table, documentStore, theme, isReadOnly }) => {
    const tableEdges = useMemo(
      () => ({
        box: table?.coords?.root,
        type: table.type,
      }),
      [table?.coords?.root, table.type]
    );

    const onTableUpdate = useCallback(
      (updatedZone: DocumentZone, e?: Konva.KonvaEventObject<Event>) => {
        if (EDITABLE_ZONE_ACTIONS.includes(documentStore.canvasAction)) {
          documentStore.updateLineItemRoot(updatedZone, e, true);
        }
      },
      [documentStore]
    );

    const isMainTableEditable =
      documentStore.focusedZones.includes(tableEdges.type) ||
      tableEdges.type === documentStore.focusedFieldCanvas;

    return (
      <>
        {!isReadOnly && (
          <>
            {/* Row line draw */}
            {/* If no header is available, then line split should not be possible */}
            {table?.headers?.length > 0 && (
              <RowLineDraft
                documentStore={documentStore}
                theme={theme}
                table={table}
              />
            )}

            {/* Column line draw */}
            <ColLineDraft
              documentStore={documentStore}
              theme={theme}
              table={table}
            />
          </>
        )}

        <Zone
          disableRotate
          isDraggable={false}
          isReadOnly={isReadOnly}
          isEditable={!isReadOnly}
          isFocused={isMainTableEditable}
          noBackground={[CANVAS_ACTIONS.drawNewZone].includes(
            documentStore.canvasAction
          )}
          zone={tableEdges as unknown as DocumentZone}
          theme={theme}
          store={documentStore}
          onUpdate={onTableUpdate}
        />
      </>
    );
  }
);
