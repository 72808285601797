import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import CheckIcon from "@mui/icons-material/Check";

import CoreDrawer from "../../../../../core/CoreDrawer";
import CoreButton from "../../../../../core/CoreButton";
import { SubscriptionPlan } from "../../../../../../types/interfaces/subscription";
import BillingHelper from "../../../../../../helper/billingHelper";
import { SubscriptionPrice } from "../SubscriptionPrice";
import { useStores } from "../../../../../../stores/StoresProvider";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  description: {
    opacity: 0.6,
    fontSize: "12px",
    marginBottom: "10px",
    textAlign: "center",
  },
  benefitContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "3px",
  },
  benefitTitle: {
    opacity: 0.6,
    fontSize: "12px",
    marginBottom: "10px",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    marginTop: "auto",
    padding: "30px 0 0 0 ",
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  checkIcon: {
    marginRight: "10px",
  },
});

interface Props {
  isOpen: boolean;
  subscriptionPlan: SubscriptionPlan | undefined;
  isActive?: boolean;
  onClose: () => void;
  handleRedirect?: () => void;
}

export const SubscriptionPlanDrawer: FC<Props> = ({
  isOpen,
  subscriptionPlan,
  isActive = false,
  onClose,
  handleRedirect,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("subscription");
  const { userStore } = useStores();

  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={() => onClose()}
      title={subscriptionPlan?.name || t("active_subscription_modal_title")}
    >
      <Box className={classes.container}>
        {!subscriptionPlan ? (
          <Typography>{t("subscription_preview_no_data")}</Typography>
        ) : (
          <>
            <SubscriptionPrice
              isHeader
              price={subscriptionPlan?.price}
              discount={subscriptionPlan.discount}
              currency={subscriptionPlan?.currency}
              contractTerm={subscriptionPlan?.period}
              styleProps={{ marginBottom: "10px" }}
            />

            {!!subscriptionPlan?.description && (
              <Typography className={classes.description}>
                {subscriptionPlan?.description}
              </Typography>
            )}

            {subscriptionPlan?.subscriptionBenefits?.length > 0 && (
              <>
                <Divider className={classes.divider} />
                <Typography className={classes.benefitTitle}>
                  {t("subscription_preview_benefits_title")}
                </Typography>

                {subscriptionPlan?.subscriptionBenefits.map(
                  (benefit, index) => (
                    <Box key={index} className={classes.benefitContainer}>
                      <CheckIcon
                        fontSize="small"
                        className={classes.checkIcon}
                      />
                      <Typography variant="body2">{benefit}</Typography>
                    </Box>
                  )
                )}
              </>
            )}
          </>
        )}

        {subscriptionPlan && !!subscriptionPlan?.priceExtra && (
          <>
            <Divider className={classes.divider} />

            <Typography variant="body2">
              {t("subscription_upgrade_preview_additional_cost_label", {
                additionalCost: BillingHelper.convertSumToCurrency(
                  subscriptionPlan?.priceExtra,
                  subscriptionPlan?.currency
                ),
                additionalCostUnit: subscriptionPlan?.priceExtraUnit,
              })}
            </Typography>
          </>
        )}
      </Box>

      <Box className={classes.footer}>
        <CoreButton onClick={() => onClose()}>
          {t("subscription_preview_close_btn")}
        </CoreButton>

        {!isActive &&
          userStore.currentUserPermissions?.can("update", "subscription") && (
            <CoreButton variant="contained" onClick={handleRedirect}>
              {t("subscription_preview_upgrade_btn")}
            </CoreButton>
          )}
      </Box>
    </CoreDrawer>
  );
};
