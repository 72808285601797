import { Components, ThemeOptions, alpha } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiCssBaseline = (
  theme: ThemeOptions
): Components["MuiCssBaseline"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: `${alpha(palette.divider, 0.1)}`,
      },
      "&::-webkit-scrollbar-thumb": {
        background: `${alpha(palette.highlight.main, 0.3)}`,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: `${alpha(palette.highlight.main, 1)}`,
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: `${alpha(palette.highlight.main, 1)}`,
      },
    },
  };
};
