import React, { FC } from "react";

import { makeStyles } from "@mui/styles";

import { Typography, useTheme, alpha } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CoreButton from "./CoreButton";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface ModalProps {
  open: boolean;
  title?: string;
  subtitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  onClose: (value: boolean) => void;
  isDisable?: boolean;
  disableForCancel?: boolean;
}

const CoreConfirmModal: FC<ModalProps> = ({
  open,
  onClose,
  title,
  subtitle,
  cancelButtonLabel,
  confirmButtonLabel,
  onCancel,
  onConfirm,
  isDisable,
  disableForCancel,
}) => {
  const {
    mainStore: { currentTheme },
  } = useStores();
  const theme = useTheme();

  const useStyles = makeStyles({
    dialog: {
      padding: "15px 20px",
    },
    cancelButton: {
      "&:hover": {
        color:
          currentTheme === THEME_MODES.mono
            ? alpha(theme.palette.canvas.contrastText, 0.4)
            : undefined,
      },
    },
  });

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography variant="caption">{subtitle}</Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <CoreButton
          onClick={onCancel}
          disabled={isDisable || disableForCancel}
          isLoading={disableForCancel}
          variant="neutral"
          className={classes.cancelButton}
        >
          {cancelButtonLabel}
        </CoreButton>

        <CoreButton
          onClick={onConfirm}
          disabled={isDisable}
          isLoading={isDisable}
          variant="contained"
        >
          {confirmButtonLabel}
        </CoreButton>
      </DialogActions>
    </Dialog>
  );
};

export default CoreConfirmModal;
