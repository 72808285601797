import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  alpha,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Add,
  Clear,
  Search,
  Refresh,
  ImportExport,
  MoreVert,
} from "@mui/icons-material";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";

import { appRoutes } from "../../../../configs/routes";
import CoreInput from "../../../core/CoreInput";
import { useStores } from "../../../../stores/StoresProvider";
import { AppSuspense } from "../../../main/AppSuspense";
import CoreButton from "../../../core/CoreButton";
import CoreTooltip from "../../../core/CoreTooltip";
import { THEME_MODES } from "../../../../types/constants";
import ExpandableCardFlow from "./ExpandableCardFlow";
import FlowHelper from "../../../../helper/flowHelper";
import FlowSettingsDrawer from "../../flow/flow-settings/FlowSettingsDrawer";
import ImportFlow from "../../flow/importFlow/ImportFlow";

interface Props {
  setReload: (value: boolean) => void;
  reload: boolean;
  isRefreshDisabled: boolean;
}

const FlowsSidebar: FC<Props> = observer(
  ({ setReload, reload, isRefreshDisabled }) => {
    const { t, ready } = useTranslation("flows");
    const { mainStore, flowStore, userStore } = useStores();
    const [search, setSearch] = useState("");
    const [showFlowSettings, setShowFlowSettings] = useState(false);
    const [openImportDrawer, setOpenImportDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const filteredFlows = flowStore.flows.filter((flow) =>
      flow.name.toLowerCase().includes(search.toLowerCase())
    );

    const sortedFlows = filteredFlows.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const theme = useTheme();

    const classes = makeStyles({
      flowLink: {
        textDecoration: "none",
        color: "inherit",
        "&.Mui-selected": {
          color: theme.palette.highlight.main,
          "& .MuiTypography-root": {
            color: theme.palette.highlight.main,
          },
        },
        "& .MuiSvgIcon-root": {
          "&:hover": {
            color: theme.palette.highlight.main,
            cursor: "pointer",
          },
        },
      },
      container: {
        width: 275,
        maxWidth: "100%",
        padding: "12px",
        borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        height: "100%",
        overflowY: "scroll",
      },
      boxAddFlow: {
        display: "flex",
        justifyContent: "space-between",
      },
      flowsTitle: {
        marginBottom: "5px",
        marginRight: "5px",
      },
      input: {
        margin: "10px 0",
        height: 35,
      },
      addButton: {
        marginRight: "10px",
      },
      search: {
        marginRight: "10px",
        opacity: 0.4,
      },
      menu: {
        padding: 0,
        marginBottom: "8px",
      },
      allFlowsButton: {
        border: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
        padding: "1px 15px",
        marginBottom: 5,
        "&.Mui-selected": {
          color: theme.palette.highlight.main,
          "& .MuiTypography-root": {
            color: theme.palette.highlight.main,
          },
        },
      },
      clearButton: {
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.highlight.main,
        },
      },
      refreshButton: {
        marginRight: "4px",
        marginBottom: "5px",
        border: "none",
        padding: 0,
        color: "inherit",
        minWidth: 0,
        "&:hover": {
          border: "none",
          background: "inherit",
          color: theme.palette.highlight.main,
        },
        "&:disabled": {
          border: "none",
        },
      },
      moreVertIcon: {
        "&:hover": {
          color: theme.palette.highlight.main,
          cursor: "pointer",
        },
      },
      menuList: {
        "& ul": {
          padding: 0,
          boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
        },
      },
      paper: {
        padding: "10px",
        background: "none",
        backgroundColor: theme.palette.secondary.light,
      },
      list: {
        width: "275px",
        padding: "0px",
      },
      divContainer: {
        display: "flex",
        flexDirection: "row",
      },
      accordion: {
        backgroundColor:
          theme.palette.mode === THEME_MODES.light
            ? theme.palette.secondary.light
            : theme.palette.background.paper,
        "& .MuiAccordion-root": { backgroundImage: "none" },
        marginTop: "5px",
      },
      menuItem: {
        background: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          color: `${theme.palette.highlight.main} !important`,
        },
      },
      iconStyle: {
        color: "inherit",
      },
      importAction: {
        margin: "3px",
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        color: "inherit",
      },
      menuStyle: {
        "& ul": {
          padding: 0,
        },
      },
    })();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    };

    const resetSearch = () => {
      setSearch("");
    };

    const handleClickMoreVert = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const onClickImport = () => {
      setAnchorEl(null);
      setOpenImportDrawer(true);
    };

    if (!ready) {
      return <AppSuspense />;
    }

    return (
      <>
        <Box className={classes.container}>
          <Box className={classes.boxAddFlow}>
            <div className={classes.divContainer}>
              <Typography className={classes.flowsTitle}>
                {t("flows")}
              </Typography>
              {!reload && !flowStore.loadingFlows && isRefreshDisabled && (
                <CircularProgress size={20} />
              )}
            </div>
            <div className={classes.divContainer}>
              <CoreButton
                onClick={() => setReload(true)}
                className={classes.refreshButton}
                disabled={isRefreshDisabled}
              >
                <CoreTooltip title={t("refresh_flows")}>
                  <Refresh />
                </CoreTooltip>
              </CoreButton>
              {userStore.currentUserPermissions?.can("create", "flows") && (
                <CoreTooltip title={t("createFlowTooltip")}>
                  <div className={classes.flowLink}>
                    <Add onClick={() => setShowFlowSettings(true)} />
                  </div>
                </CoreTooltip>
              )}

              <div
                onClick={handleClickMoreVert}
                className={classes.moreVertIcon}
              >
                <MoreVert />
              </div>

              <Menu
                className={classes.menuStyle}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuList dense>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={onClickImport}
                  >
                    <ListItemIcon className={classes.iconStyle}>
                      <ImportExport />
                    </ListItemIcon>
                    <Typography className={classes.importAction}>
                      {t("importFlowTitle")}
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </Box>
          <Divider />
          <CoreInput
            fullWidth
            className={classes.input}
            placeholder={t("search_flows")}
            startAdornment={<Search className={classes.search} />}
            endAdornment={
              <Clear
                onClick={resetSearch}
                className={classes.clearButton}
                fontSize="small"
              />
            }
            onChange={handleChange}
            value={search}
          />

          <MenuList className={classes.menu}>
            {!reload && !flowStore.loadingFlows ? (
              <Box>
                <Link to={appRoutes.Flows()} className={classes.flowLink}>
                  <ListItemButton
                    onClick={() =>
                      FlowHelper.handleExpandCardFlow(flowStore, "", false)
                    }
                    className={classes.allFlowsButton}
                    selected={mainStore.currentPage === appRoutes.Flows()}
                  >
                    <ListItemText>{t("allFlows")}</ListItemText>
                  </ListItemButton>
                </Link>

                {sortedFlows.map((flow, index) => (
                  <Box
                    key={index}
                    onClick={(
                      event:
                        | React.MouseEvent<HTMLDivElement, MouseEvent>
                        | React.FormEvent<HTMLButtonElement>
                    ) =>
                      FlowHelper.handleExpandCardFlow(
                        flowStore,
                        flow.identifier,
                        false,
                        event as React.FormEvent<HTMLButtonElement>
                      )
                    }
                  >
                    <Link
                      to={appRoutes.FlowDetails(flow.identifier)}
                      className={classes.flowLink}
                    >
                      <Box className={classes.accordion}>
                        <ExpandableCardFlow flow={flow} />
                      </Box>
                    </Link>
                  </Box>
                ))}
              </Box>
            ) : (
              <AppSuspense />
            )}
          </MenuList>
        </Box>
        <FlowSettingsDrawer
          isOpen={showFlowSettings}
          onClose={() => setShowFlowSettings(false)}
        />
        <ImportFlow
          isOpen={openImportDrawer}
          onClose={() => setOpenImportDrawer(false)}
          t={t}
        />
      </>
    );
  }
);

export default FlowsSidebar;
