import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { CSSProperties, createStyles, makeStyles } from "@mui/styles";
import { useTheme, alpha } from "@mui/material";

import { observer } from "mobx-react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import { CoreCell } from "./CoreCell";
import { HOVER_COLUMNS } from "../../../types/constants";
import {
  TableCellStyleDef,
  TableContentDef,
  TableHeaderDef,
  TableRowStyleDef,
} from "../../../types/interfaces";

interface Props {
  headers?: TableHeaderDef[];
  content: TableContentDef[];
  setContent?: (content: TableContentDef[]) => void;
  rowsNumber: number;
  columnWidth?: TableContentDef;
  rowHeight?: TableContentDef;
  handleRowClick?: (event: React.MouseEvent<unknown>, id: string) => void;
  hoverActions?: boolean;
  noVisibleCells?: number; // Number of columns to display once the row is hovered (if hoverActions is enabled)
  customRowStyles?: TableRowStyleDef; // Style applied per row
  customCellStyle?: TableCellStyleDef; // Style applied per cell
  globalCellStyle?: CSSProperties; // Style applied to all cells
}

const CoreDraggableBody: FC<Props> = observer(
  ({
    headers,
    content,
    rowsNumber,
    handleRowClick,
    columnWidth = {},
    rowHeight = {},
    hoverActions = false,
    noVisibleCells = 1,
    customRowStyles = {},
    customCellStyle = {},
    globalCellStyle = {},
    setContent,
  }) => {
    const { t } = useTranslation("flow");
    const [hoveredRowIndex, setShowActions] = React.useState<number | null>(
      null
    );

    const theme = useTheme();

    const useStyles = makeStyles(() =>
      createStyles({
        message: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        noDataCell: {
          borderBottom: "none",
          paddingBottom: "3rem",
          paddingTop: "2rem",
        },
      })
    );

    const classes = useStyles();

    const handleMouse = (index: number | null) => {
      if (hoverActions) setShowActions(index);
    };

    const reorder = (
      list: TableContentDef[],
      startIndex: number,
      endIndex: number
    ) => {
      const result = [...list];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        content,
        result.source.index,
        result.destination.index
      );
      if (setContent) setContent(items);
    };

    const getItemStyle = (
      isDragging: boolean,
      draggableStyle?: DraggingStyle | NotDraggingStyle
    ) => ({
      // some basic styles to make the items look a bit nicer
      border: "1px solid",

      // change background color if dragging
      background: isDragging
        ? alpha(theme.palette.grey.A700, 0.8)
        : alpha(theme.palette.primary.main, 0.8),
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    return content.length === 0 ? (
      <TableBody>
        <TableRow>
          <TableCell colSpan={rowsNumber} className={classes.noDataCell}>
            <Typography className={classes.message}>
              {t("noDataToDisplay")}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    ) : (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {content.map((row, rowIndex) => {
                return (
                  <Draggable
                    key={
                      (row as unknown as { id: string }).id ||
                      (row as unknown as { identifier: string }).identifier ||
                      (row as unknown as { key: string }).key
                    }
                    draggableId={
                      (row as unknown as { id: string }).id ||
                      (row as unknown as { identifier: string }).identifier ||
                      (row as unknown as { key: string }).key
                    }
                    index={rowIndex}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={`${Object.values(row)[0] as string}-${rowIndex}`}
                        hover
                        sx={customRowStyles?.[rowIndex] || {}}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        onClick={
                          handleRowClick
                            ? (event) =>
                                handleRowClick(
                                  event,
                                  (row as unknown as { id: string }).id ||
                                    (row as unknown as { identifier: string })
                                      .identifier
                                )
                            : undefined
                        }
                        onMouseEnter={() => handleMouse(rowIndex)}
                        onMouseLeave={() => handleMouse(null)}
                      >
                        {headers?.map((headerDef, index, list) => {
                          const isRowHovered = hoveredRowIndex === rowIndex;
                          const cellStyle =
                            {
                              ...globalCellStyle,
                              ...customCellStyle?.[rowIndex]?.[
                                headerDef.accessor
                              ],
                            } || {};

                          if (
                            hoverActions && // row hovering is enabled
                            isRowHovered && // the current row iteration is the one hovered
                            !(index < noVisibleCells) && // index is not within noVisibleCells range
                            index !== list.length - 1 // index is not of the last element (which will display the actions)
                          ) {
                            return (
                              <TableCell
                                key={`${headerDef.accessor}-${index}`}
                                sx={cellStyle}
                              ></TableCell>
                            );
                          }

                          // Send 'actions' cell accessor to display row actions instead of the original cell content (if row is hovered)
                          const cellAccessor =
                            isRowHovered && index === list.length - 1
                              ? HOVER_COLUMNS.actions
                              : headerDef.accessor;

                          return (
                            <CoreCell
                              cell={cellAccessor}
                              row={row}
                              key={`${headerDef.accessor}-${index}`}
                              columnWidth={columnWidth}
                              rowHeight={rowHeight}
                              styleProps={cellStyle}
                            />
                          );
                        })}
                      </TableRow>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default CoreDraggableBody;
