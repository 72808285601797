import React, { FC, useMemo, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import classNames from "classnames";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  alpha,
  useTheme,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";

import Logo from "../../../../../src/icons/Logo";
import {
  CHAT_GPT_MESSAGE_TYPES,
  THEME_MODES,
} from "../../../../types/constants";
import CoreTooltip from "../../../core/CoreTooltip";
import { useNotification } from "../../../../context/useNotification";
import { useStores } from "../../../../stores/StoresProvider";
import ChatGptTypeWriter from "./ChatGptTypeWriter";
import ChatGptMessagesPagination from "./ChatGptMessagesPagination";
import { appRoutes } from "../../../../configs/routes";
import { ChatTextRenderer } from "./ChatTextRenderer";
import { ChatGptMessageFilters } from "../../../../types/interfaces";
interface Props {
  text: React.ReactNode | string | string[];
  type: string;
  position?: number; // Needed to inject the new response from regenerate action
  messageStyle?: string;
  isHomeChat?: boolean;
  enableTypeWritter?: boolean; // Usually used for last message box of type claritext
  forceHideActions?: boolean; // Hide actions, used from SearchingResponse component
  filters?: ChatGptMessageFilters;
  hideRefreshIcon?: boolean;
}

const ChatGptMessageBox: FC<Props> = observer(
  ({
    text: currentTextBatch,
    type,
    position = 0,
    messageStyle,
    isHomeChat,
    enableTypeWritter,
    forceHideActions = false,
    filters,
    hideRefreshIcon = false,
  }) => {
    const { t } = useTranslation("chatGpt");
    const history = useHistory();
    const notification = useNotification();
    const theme = useTheme();
    const { id } = useParams<{ id: string }>();
    const { pathname } = useLocation();

    const {
      chatGptStore: {
        isResponseGenerating,
        isResponseLoading,
        regenerateClariChatResponse,
        setIsScrollDisabled,
        homeMessages,
        messages,
      },
      mainStore: { currentTheme },
      flowStore,
    } = useStores();
    const [regenerateLoading, setIsRegenerateLoading] =
      useState<boolean>(false);
    const [isTypeWriterEnabled, setIsTypeWriterEnabled] = useState(false);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    const text = useMemo(() => {
      if (Array.isArray(currentTextBatch)) {
        return currentTextBatch[currentTextIndex] as string;
      }

      return currentTextBatch as string;
    }, [currentTextBatch, currentTextIndex]);

    const responseWasRegenerated =
      Array.isArray(currentTextBatch) &&
      (currentTextBatch as unknown as string[]).length > 1;

    const messagesList = useMemo(() => {
      return isHomeChat ? homeMessages : messages;
    }, [homeMessages, isHomeChat, messages]);

    const classes = makeStyles({
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        width: isHomeChat ? "80%" : "100%",
        padding: "15px 15px 5px 15px",
      },
      box: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "end",
        maxWidth: "100%",
        width: "auto",
        alignSelf:
          !isHomeChat && type === CHAT_GPT_MESSAGE_TYPES.user
            ? "flex-end"
            : "flex-start",
        opacity:
          ((!isResponseGenerating && !isResponseLoading) ||
            position !== messagesList.length - 1) &&
          messagesList[position]?.answer === "" &&
          type === CHAT_GPT_MESSAGE_TYPES.user
            ? 0.5
            : 1,
      },
      message: {
        padding: "10px",
        border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        borderRadius: "15px",
        backgroundColor:
          type === CHAT_GPT_MESSAGE_TYPES.user
            ? theme.palette.neutral.light
            : currentTheme === THEME_MODES.light
            ? theme.palette.highlight.contrastText
            : theme.palette.neutral.dark,
        color:
          type === CHAT_GPT_MESSAGE_TYPES.user
            ? currentTheme === THEME_MODES.light
              ? theme.palette.neutral.dark
              : theme.palette.primary.dark
            : currentTheme === THEME_MODES.light
            ? theme.palette.neutral.dark
            : theme.palette.primary.light,
        wordBreak: "break-word",
      },
      actionButton: {
        display: "flex",
        color:
          currentTheme === THEME_MODES.light
            ? theme.palette.canvas.dark
            : theme.palette.primary.light,
      },
      actionsBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        alignSelf:
          !isHomeChat && type === CHAT_GPT_MESSAGE_TYPES.user
            ? "flex-end"
            : "flex-start",
      },
      icon: {
        width: "15px",
        height: "15px",
      },
      logoDimension: {
        width: "25px",
        height: "30px",
      },
      logoBox: {
        alignSelf:
          !isHomeChat && type === CHAT_GPT_MESSAGE_TYPES.user
            ? "flex-end"
            : "flex-start",
        display: "flex",
        paddingTop: "5px",
        paddingBottom: "5px",
        gap: "5px",
        justifyContent: "center",
        alignItems: "center",
      },
      nameStyle: {
        fontSize: "14px",
      },
    })();

    const showActions = useMemo(() => {
      if (forceHideActions) {
        return false;
      }

      return type === CHAT_GPT_MESSAGE_TYPES.claritext && enableTypeWritter
        ? !isResponseLoading && !isResponseGenerating
        : true;
    }, [
      enableTypeWritter,
      isResponseGenerating,
      isResponseLoading,
      type,
      forceHideActions,
    ]);

    const showTypeWriter = useMemo(
      () =>
        type === CHAT_GPT_MESSAGE_TYPES.claritext &&
        enableTypeWritter &&
        isResponseGenerating,
      [enableTypeWritter, isResponseGenerating, type]
    );

    const isClaritextMessage = useMemo(
      () => type === CHAT_GPT_MESSAGE_TYPES.claritext,
      [type]
    );

    const isFlowDetailsChat = pathname.includes("/flows");

    const handleRegenerateResponse = () => {
      setIsRegenerateLoading(true);
      setIsTypeWriterEnabled(false);
      setIsScrollDisabled(true);

      regenerateClariChatResponse(
        position,
        isHomeChat,
        isFlowDetailsChat ? id : ""
      )
        .then(() => {
          setIsTypeWriterEnabled(true);

          if (Array.isArray(currentTextBatch)) {
            setCurrentTextIndex(currentTextBatch.length);
          } else {
            setCurrentTextIndex(currentTextIndex + 1);
          }
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "regenerateResponseError"));
        })
        .finally(() => {
          setIsRegenerateLoading(false);
        });
    };

    const messageCustomStyle = messageStyle
      ? `${classes.message} ${messageStyle}`
      : classes.message;

    const copyToClipboard = () => {
      void navigator.clipboard.writeText(text);
    };

    const handleChangePage = (page: number) => {
      setCurrentTextIndex(page);
    };

    const applyChatFilters = () => {
      if (filters && Object.keys(filters)?.length > 0) {
        flowStore.setFiltersValues({
          ...flowStore?.filters?.flowFilters,
          ...(filters || {}),
        });
        if (!isHomeChat) {
          flowStore.setRefetchForChatGptFilters();
        }
        if (
          isHomeChat &&
          typeof text === "string" &&
          text?.includes("${link}")
        ) {
          history.push(appRoutes.Flows());
        }
      }
    };

    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.logoBox}>
          {type === CHAT_GPT_MESSAGE_TYPES.claritext ? (
            <Logo threeStripes threeStripesClassName={classes.logoDimension} />
          ) : (
            <PersonIcon className={classes.logoDimension} />
          )}

          <Typography className={classes.nameStyle}>
            {t(
              type === CHAT_GPT_MESSAGE_TYPES.claritext
                ? "clariChatName"
                : "youName"
            )}
          </Typography>
        </Box>

        <Box className={classNames(classes.box, messageCustomStyle)}>
          {showTypeWriter || isTypeWriterEnabled ? (
            <ChatGptTypeWriter
              text={text}
              onDone={() => setIsTypeWriterEnabled(false)}
            />
          ) : (
            <ChatTextRenderer
              t={t}
              text={text}
              filters={filters}
              onFilterApply={applyChatFilters}
            />
          )}
        </Box>

        {(showActions || responseWasRegenerated) && (
          <Box className={classes.actionsBox}>
            <CoreTooltip title={t("copyText") ?? ""}>
              <span>
                <IconButton
                  size="small"
                  className={classes.actionButton}
                  onClick={copyToClipboard}
                  disabled={!text}
                >
                  <ContentCopyIcon fontSize="small" className={classes.icon} />
                </IconButton>
              </span>
            </CoreTooltip>

            {isClaritextMessage && !hideRefreshIcon && (
              <>
                {regenerateLoading ? (
                  <IconButton size="small">
                    <CircularProgress size={15} />
                  </IconButton>
                ) : (
                  <CoreTooltip title={t("regenerateResponse") ?? ""}>
                    <span>
                      <IconButton
                        size="small"
                        className={classes.actionButton}
                        onClick={() => handleRegenerateResponse()}
                      >
                        <ReplayIcon fontSize="small" className={classes.icon} />
                      </IconButton>
                    </span>
                  </CoreTooltip>
                )}
              </>
            )}

            {isClaritextMessage && responseWasRegenerated && (
              <ChatGptMessagesPagination
                totalPages={currentTextBatch.length}
                currentPage={currentTextIndex}
                onChangePage={handleChangePage}
              />
            )}
          </Box>
        )}
      </Box>
    );
  }
);

export default ChatGptMessageBox;
