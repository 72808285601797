import React, { memo } from "react";
import { TFunction } from "react-i18next";
import classnames from "classnames";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
//Icons
import PanToolIcon from "@mui/icons-material/PanTool";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestartAlt from "@mui/icons-material/RestartAlt";
import HeightIcon from "@mui/icons-material/Height";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";

import CoreTooltip from "../../../../core/CoreTooltip";

import {
  CANVAS_ACTIONS,
  CANVAS_ACTIONS_VIEW_MODE,
  CANVAS_ACTIONS_EDIT_MODE,
  CANVAS_EXTRA_INSTRUMENTS_ROTATE_ACTIONS,
  CANVAS_EXTRA_INSTRUMENTS_FIT_ACTIONS,
} from "../../../../../types/constants";
import { useStores } from "../../../../../stores/StoresProvider";

const FitWidthIcon = (props: Record<string, unknown>) => (
  <HeightIcon sx={{ transform: "rotate(90deg)" }} {...props} />
);

const TOOLBAR_ACTIONS = {
  [CANVAS_ACTIONS.pan]: PanToolIcon,
  [CANVAS_ACTIONS.drawNewZone]: HighlightAltIcon,
  [CANVAS_ACTIONS.zoomIn]: ZoomInIcon,
  [CANVAS_ACTIONS.zoomOut]: ZoomOutIcon,
  [CANVAS_ACTIONS.resetCanvas]: RestartAlt,
  [CANVAS_ACTIONS.fitWidth]: FitWidthIcon,
  [CANVAS_ACTIONS.fitHeight]: HeightIcon,
  [CANVAS_ACTIONS.rotateRight]: RedoIcon,
  [CANVAS_ACTIONS.rotateLeft]: UndoIcon,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as { [key: string]: any };

const TOOLTIP_ACTIONS = {
  [CANVAS_ACTIONS.pan]: "pan_tooltip",
  [CANVAS_ACTIONS.drawNewZone]: "drawNewShape_tooltip",
  [CANVAS_ACTIONS.zoomIn]: "zoomIn_tooltip",
  [CANVAS_ACTIONS.zoomOut]: "zoomOut_tooltip",
  [CANVAS_ACTIONS.resetCanvas]: "resetCanvas_tooltip",
  [CANVAS_ACTIONS.fitWidth]: "fitWidth_tooltip",
  [CANVAS_ACTIONS.fitHeight]: "fitHeight_tooltip",
  [CANVAS_ACTIONS.rotateRight]: "fitRotateRight_tooltip",
  [CANVAS_ACTIONS.rotateLeft]: "fitRotateLeft_tooltip",
} as { [key: string]: string };

interface Props {
  activeAction: CANVAS_ACTIONS;
  fitAction: CANVAS_ACTIONS | undefined;
  changeAction: (action: CANVAS_ACTIONS) => void;
  t: TFunction;
}

const ToolbarMenuList: React.FC<Props> = ({
  activeAction,
  fitAction,
  changeAction,
  t,
}) => {
  const { documentStore, flowStore } = useStores();
  const theme = useTheme();
  const useStyles = makeStyles({
    icon: {
      [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.down("md")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "20x",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "23px",
      },
    },
    activeIcon: {
      color: theme.palette.highlight.main,
    },
    menuList: {
      padding: 0,
      display: "flex",
      flexDirection: "row",
    },
    itemContainer: { display: "flex", flexDirection: "row" },
    menuItem: {
      minHeight: "auto",
      paddingLeft: "13px",
      paddingRight: "13px",
    },
    divider: { margin: "0px !important" },
  });
  const classes = useStyles();

  const renderAction = (
    action: CANVAS_ACTIONS,
    position: number,
    isActive: boolean,
    displayDivider?: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const Icon = TOOLBAR_ACTIONS[action];
    const tooltipTitle = t(TOOLTIP_ACTIONS[action]);

    return (
      <Box key={`${action}-${position}`} className={classes.itemContainer}>
        <CoreTooltip title={tooltipTitle} placement="bottom">
          <MenuItem
            onClick={() => changeAction(action)}
            className={classes.menuItem}
          >
            <Icon
              className={classnames([
                classes.icon,
                isActive && classes.activeIcon,
              ])}
            />
          </MenuItem>
        </CoreTooltip>
        {displayDivider && (
          <Divider orientation="vertical" className={classes.divider} />
        )}
      </Box>
    );
  };

  return (
    <MenuList className={classes.menuList}>
      {documentStore.viewMode || flowStore.flow?.flowType === "classification"
        ? CANVAS_ACTIONS_VIEW_MODE.map((action, index) =>
            renderAction(
              action,
              index,
              activeAction === action,
              index + 1 === CANVAS_ACTIONS_VIEW_MODE.length
            )
          )
        : CANVAS_ACTIONS_EDIT_MODE.map((action, index) =>
            renderAction(
              action,
              index,
              activeAction === action,
              index + 1 === CANVAS_ACTIONS_EDIT_MODE.length
            )
          )}

      {CANVAS_EXTRA_INSTRUMENTS_ROTATE_ACTIONS.map((action, index) =>
        renderAction(
          action,
          index,
          fitAction === action,
          index + 1 === CANVAS_EXTRA_INSTRUMENTS_ROTATE_ACTIONS.length
        )
      )}

      {CANVAS_EXTRA_INSTRUMENTS_FIT_ACTIONS.map((action, index) =>
        renderAction(action, index, fitAction === action)
      )}
    </MenuList>
  );
};

export default memo(ToolbarMenuList);
