import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Box, SvgIcon, useTheme } from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useStores } from "../../../../../stores/StoresProvider";
import CoreTooltip from "../../../../core/CoreTooltip";
import { AssetType } from "../../../../../types/interfaces";

interface Props {
  nodeKey: string;
  textBoxStyle: string;
  iconStyle: string;
  svgIconStyle: string;
  forDiagram?: boolean;
  nodeHasErrors?: boolean;
}

const DiagramNodeIcon: React.FC<Props> = observer(
  ({
    nodeKey,
    textBoxStyle,
    iconStyle,
    svgIconStyle,
    nodeHasErrors,
    forDiagram = false,
  }) => {
    const { t } = useTranslation("addFlow");
    const { flowSettingsStore } = useStores();
    const theme = useTheme();

    const node = useMemo(
      () => flowSettingsStore.nodesList?.find((node) => node.key === nodeKey),
      [flowSettingsStore.nodesList, nodeKey]
    );

    const content = (node: AssetType) => {
      return (
        <Box className={textBoxStyle}>
          {nodeHasErrors ? (
            <ErrorOutlineIcon
              key={node?.key}
              className={iconStyle}
              style={{ color: theme.palette.warning.main }}
            />
          ) : node?.iconPath ? (
            <img key={node?.key} src={node?.iconPath} />
          ) : node?.icon ? (
            <SvgIcon key={node?.key} className={svgIconStyle}>
              <g dangerouslySetInnerHTML={{ __html: node?.icon }} />
            </SvgIcon>
          ) : (
            <SystemUpdateAltIcon key={node?.key} className={iconStyle} />
          )}
        </Box>
      );
    };

    if (!node) {
      return (
        <Box className={textBoxStyle}>
          <SystemUpdateAltIcon className={iconStyle} />
        </Box>
      );
    }

    return forDiagram ? (
      <CoreTooltip
        title={
          <span style={{ display: "block", textAlign: "center" }}>
            {nodeHasErrors ? (
              <span style={{ fontSize: "12px" }}>{t("nodeFieldError")}</span>
            ) : (
              <>
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {node?.name || ""}
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>
                  {node?.description || ""}
                </span>
              </>
            )}
          </span>
        }
        placement="bottom"
      >
        {content(node)}
      </CoreTooltip>
    ) : (
      content(node)
    );
  }
);

export default DiagramNodeIcon;
