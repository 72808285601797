import { alpha, Components, ThemeOptions } from "@mui/material";
import { ThemePalette } from "../../types/interfaces";
import { THEME_MODES } from "../../types/constants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    neutral: true;
  }
}

export const initMuiButton = (theme: ThemeOptions): Components["MuiButton"] => {
  const palette = theme.palette as ThemePalette;
  const isLightMode = theme.palette?.mode === THEME_MODES.light;

  return {
    defaultProps: {
      size: "small",
      variant: "outlined",
    },
    styleOverrides: {
      root: {
        textTransform: "capitalize",
      },
    },
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          fontSize: "14px",
          color: palette.highlight.main,
          borderColor: palette.highlight.main,
          "&:hover": {
            borderColor: palette.neutral.main,
            color: palette.neutral.main,
          },
        },
      },
      {
        props: { variant: "contained" },
        style: {
          fontSize: "14px",
          backgroundColor: palette.highlight.main,
          color: palette.highlight.contrastText,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: palette.neutral.main,
          },
        },
      },
      {
        props: { variant: "text" },
        style: {
          fontSize: "14px",
          backgroundColor: "inherit",
          color: alpha(palette.highlight.light, 0.7),
          boxShadow: "none",
          "&:hover": {
            color: palette.highlight.main,
            backgroundColor: isLightMode
              ? palette.canvas.contrastText
              : undefined,
          },
        },
      },
      {
        props: { variant: "neutral" },
        style: {
          fontSize: "14px",
          color: isLightMode
            ? palette.highlight.light
            : alpha(palette.highlight.light, 0.5),
          padding: "3px 9px",
          "&:hover": {
            color: palette.neutral.main,
          },
        },
      },
    ],
  };
};
