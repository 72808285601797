import React, { useEffect, useState } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import CoreButton from "../../core/CoreButton";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { FIELD_TYPE } from "../../../types/constants";
import { FormData, FormField } from "../../../types/interfaces";
import { useApolloClient } from "@apollo/client";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import { passwordRegex } from "../../../types/regex";
import CoreDrawer from "../../core/CoreDrawer";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  userId: number | null;
}

interface Errors {
  password?: string;
  confirmPassword?: string;
}

const data: FormData = {
  password: "",
  confirmPassword: "",
};

const useStyles = makeStyles({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
    minHeight: "300px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
});

export const EditPassword: React.FC<Props> = ({
  t,
  isOpen,
  onClose,
  userId,
}) => {
  const classes = useStyles();
  const [isOperationInProgress, setOperationInProgress] = useState(false);
  const [formData, setFormData] = useState<FormData>(data);
  const [errors, setErrors] = useState<Errors>({});

  const client = useApolloClient();
  const { userStore } = useStores();
  const notification = useNotification();

  const fields: FormField[] = [
    {
      translationKey: "passwordInput_placeholder",
      key: "password",
      type: FIELD_TYPE.password,
      tooltipLocation: "title",
      props: {
        title: t("newPassword"),
        disabled: isOperationInProgress,
        description: t("password_tooltip"),
      },
      errorText: errors?.password,
      showStrengthBar: true,
      showRules: false,
    },
    {
      translationKey: "confirmPasswordInput_placeholder",
      key: "confirmPassword",
      type: FIELD_TYPE.password,
      props: {
        title: t("confirmPassword"),
        disabled: isOperationInProgress,
        description: t("confirmPassword_tooltip"),
      },
      errorText: errors?.confirmPassword,
    },
  ];

  const handleInput = (value: FormData, changedKey: string) => {
    setFormData(value);
    setErrors({
      ...errors,
      [changedKey as keyof Errors]: "",
    });
  };

  useEffect(() => {
    setFormData(data);
    setErrors({});
  }, []);

  const handleUpdate = () => {
    const passwordError =
      !formData ||
      !formData?.password ||
      (formData?.password as string).trim() === ""
        ? t("validationFieldsError")
        : !passwordRegex.test(formData?.password as string)
        ? t("password_tooltip")
        : "";

    const confirmPasswordError =
      !formData ||
      !formData?.confirmPassword ||
      (formData?.confirmPassword as string).trim() === ""
        ? t("validationFieldsError")
        : formData?.confirmPassword !== formData?.password
        ? t("confirmPassword_tooltip")
        : "";

    if (passwordError || confirmPasswordError) {
      setErrors({
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });

      return;
    }

    if (formData && userId) {
      setOperationInProgress(true);

      userStore
        .updatePassword(client, formData.password as string, userId)
        .then(() => {
          onClose();
          notification.success(t("passwordUpdated"));
          setFormData({});
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "update_error"));
        })
        .finally(() => {
          setOperationInProgress(false);
        });
    }
  };

  const handleClose = () => {
    onClose();
    setFormData({});
    setErrors({});
  };

  return (
    <CoreDrawer isOpen={isOpen} onClose={handleClose} title={t("changePass")}>
      <Box className={classes.contentContainer}>
        <CoreFormRenderer
          fields={fields}
          translation={t}
          data={formData}
          onChange={handleInput}
          // fieldSpacing={theme.spacing(5)}
        />
        <Box className={classes.buttonsContainer}>
          <CoreButton
            onClick={handleUpdate}
            isLoading={isOperationInProgress}
            disabled={isOperationInProgress}
            variant="contained"
          >
            {t("changePass")}
          </CoreButton>
        </Box>
      </Box>
    </CoreDrawer>
  );
};
