import jwtDecode from "jwt-decode";
import {
  PickerCallback,
  PickerConfiguration,
} from "react-google-drive-picker/dist/typeDefs";

import { MainStore } from "../../../../../../stores/MainStore";
import { FormData } from "../../../../../../types/interfaces";

export const Providers = {
  google: "google",
  //   dropbox: "dropbox",
  //   onedrive: "onedrive",
};
export const GOOGLE_CUSTOM_SCOPES = {
  googleDrive: "https://www.googleapis.com/auth/drive",
  googleProfile: "https://www.googleapis.com/auth/userinfo.profile",
};

export const getGooglePickerOptions = (
  mainStore: MainStore,
  tokens: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  },
  onChange: (formData: FormData) => void,
  customScopes: string[],
  setCode: (code: string) => void
): PickerConfiguration => {
  return {
    clientId: mainStore.settings?.googleClientId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    developerKey: mainStore.settings?.googleDeveloperKey,
    viewId: "DOCS",
    viewMimeTypes:
      "image/png,image/jpeg,image/jpg,image/tif,image/tiff,application/json,application/vnd.google-apps.document,application/vnd.google-apps.folder,application/vnd.google-apps.file,application/vnd.google-apps.spreadsheet",
    supportDrives: true,
    setSelectFolderEnabled: true,
    customScopes,
    callbackFunction: (data: PickerCallback) => {
      if (data.action === "picked") {
        const { email, name } = jwtDecode<{
          email: string;
          name: string;
        }>(tokens.idToken);
        onChange({
          googleDriveConnection: {
            credentials: {
              refreshToken: tokens.refreshToken,
            },
            directory: { id: data.docs[0].id, name: data.docs[0].name },
            user: {
              email,
              name,
            },
          },
        });
        setCode("");
      }
    },

    token: tokens.accessToken,
  } as PickerConfiguration;
};
