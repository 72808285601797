import { GraphQLError } from "graphql";
import { makeAutoObservable, runInAction } from "mobx";
import { UserRegistration } from "../components/pages/steps/constants";
import { setAccessToken } from "../helper/authHelper";
import BillingHelper from "../helper/billingHelper";
import { SubscriptionPlan } from "../types/interfaces/subscription";
import { ADD_USER_MUTATION, SIGNUP_JOURNEY } from "./mutations/signupJourney";
import {
  GET_SUBSCRIPTION_PACKAGES,
  QUERY_SUBSCRIPTION_PACKAGES_RESPONSE,
} from "./queries/subscription";
import { RootStore } from "./StoresProvider";

export class LandingStore {
  root: RootStore;
  userRegistration = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    namespace: "",
    subscriptionPlanId: "",
    oauth_id: "",
    isGoogleLogin: false,
    isCreateSuccessfully: false,
  };
  subscriptionPlans: SubscriptionPlan[] = [];
  registrationStep = 0;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  setUserRegistration = (value: Partial<UserRegistration>) => {
    this.userRegistration = { ...(this.userRegistration || {}), ...value };
  };

  loadSubscriptionPlans = async (): Promise<boolean> => {
    const {
      data: { subscriptionPlans },
      errors,
    } = await this.root.client.query<QUERY_SUBSCRIPTION_PACKAGES_RESPONSE>({
      query: GET_SUBSCRIPTION_PACKAGES,
    });

    if (errors) {
      throw new Error();
    }

    runInAction(() => {
      this.subscriptionPlans =
        BillingHelper.sortSubscriptionPlans(subscriptionPlans);
    });

    return true;
  };

  createUserAndContext = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      firstName,
      lastName,
      email,
      password,
      namespace,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      oauth_id,
    } = this.userRegistration;

    const response = await this.root.client.mutate<ADD_USER_MUTATION>({
      mutation: SIGNUP_JOURNEY,
      variables: {
        firstName,
        lastName,
        email: email?.trim(),
        password: password || null,
        namespace,
        userId: this.root.userStore.user?.id || null,
        oauth_id,
      },
    });

    const {
      data: { createUserAndContext },
      errors,
    } = response as unknown as {
      data: {
        createUserAndContext: {
          newAccessToken: string;
        };
      };
      errors: GraphQLError[];
    };

    if (!createUserAndContext || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    if (createUserAndContext.newAccessToken) {
      setAccessToken(createUserAndContext.newAccessToken);
      this.root.mainStore.setAuthState(new Date());
    }

    return true;
  };

  setRegistrationStep(number: number): void {
    this.registrationStep = number;
  }

  goNext = (): void => {
    this.setRegistrationStep(this.registrationStep + 1);
  };

  goBack = (): void => {
    this.setRegistrationStep(this.registrationStep - 1);
  };

  resetFields(): void {
    this.userRegistration = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      namespace: "",
      subscriptionPlanId: "",
      isGoogleLogin: false,
      oauth_id: "",
      isCreateSuccessfully: false,
    };
    this.subscriptionPlans = [];
    this.registrationStep = 0;
  }
}
