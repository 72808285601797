import { alpha, Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiCheckbox = (
  theme: ThemeOptions
): Components["MuiCheckbox"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        color: palette.highlight.light,
        "&:hover": {
          backgroundColor: alpha(palette.neutral.main as string, 0.2),
        },
        "&.Mui-disabled": {
          color: alpha(palette.highlight.light as string, 0.3),
        },
        "&.Mui-checked": {
          "& .MuiSvgIcon-root": {
            color: alpha(palette.highlight.main as string, 0.75),
          },
          "&.Mui-disabled": {
            "& .MuiSvgIcon-root": {
              color: alpha(palette.highlight.light as string, 0.25),
            },
          },
        },
        "&.MuiCheckbox-indeterminate": {
          "& .MuiSvgIcon-root": {
            color: alpha(palette.neutral.main as string, 0.75),
          },
        },
      },
    },
  };
};
