import React, { FC } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  noDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

interface Props {
  title: string;
  subtitle?: string;
}

export const CorePageMessage: FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.noDataContainer}>
      <Typography fontSize="40px" align="center" fontWeight={700}>
        {title}
      </Typography>

      {subtitle && (
        <Typography fontSize="20px" align="center" mt={3} mb={3}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
