import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import AddIcon from "@mui/icons-material/Add";

import { useTheme, alpha } from "@mui/material";

import CoreButton from "../../../core/CoreButton";
import FlowDataCategoryCrudDrawer from "./FlowDataCategoryCrudDrawer";
import { FLOW_DATA_TYPES_FRONTEND } from "../../../../types/constants";

interface Props {
  type: string;
  setRefreshData: (refreshData: boolean) => void;
}

const AddDataContentAction: FC<Props> = ({ type, setRefreshData }) => {
  const { t } = useTranslation("flow");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();

  const useStyles = makeStyles({
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
  });

  const classes = useStyles();

  const headerButtons = [
    {
      label: t("refreshButton"),
      onClick: () => {
        setRefreshData(true);
      },
      startIcon: <CachedOutlinedIcon />,
    },
    {
      label:
        type === FLOW_DATA_TYPES_FRONTEND.dataSources
          ? t("addDataSource")
          : t("addDataExport"),
      onClick: () => {
        setIsDrawerOpen(true);
      },
      startIcon: <AddIcon />,
    },
  ];

  return (
    <>
      {headerButtons.map((button, index) => {
        return (
          <CoreButton
            key={index}
            variant="neutral"
            size="large"
            onClick={button.onClick}
            startIcon={button.startIcon}
            className={classes.button}
          >
            {button.label}
          </CoreButton>
        );
      })}

      <FlowDataCategoryCrudDrawer
        isOpen={isDrawerOpen}
        type={type}
        onClose={(refresh?: boolean) => {
          if (refresh) setRefreshData(true);
          setIsDrawerOpen(false);
        }}
      />
    </>
  );
};

export default AddDataContentAction;
