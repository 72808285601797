import React, { FC, memo, useState } from "react";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { SelectOptionDef } from "../../types/interfaces";

interface Props {
  label: string;
  options: SelectOptionDef[];
  selections: string[];
  onUpdate: (newList: string[]) => void;
}

const CoreAutocomplete: FC<Props> = ({
  label,
  options,
  selections,
  onUpdate,
}) => {
  const [filterInputSearch, setFilterSearchValue] = useState("");

  const onFilterChange = (_: unknown, value: unknown) => {
    const selection = value as { key: string; label: string };

    if (selection && !selections?.some((item) => item === selection.key)) {
      onUpdate([...selections, selection.key]);
      setFilterSearchValue("");
    }
  };

  const handleInput = (event: unknown, value: string, reason: string) => {
    setFilterSearchValue(reason === "reset" ? "" : value);
  };

  return (
    <>
      <Box>
        <Autocomplete
          size="small"
          autoHighlight
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(item) => item.label || ""}
          options={options}
          onInputChange={handleInput}
          inputValue={filterInputSearch}
          value={null}
          onChange={onFilterChange}
          renderOption={(props, item) => (
            <Box component="li" {...props} key={`${item.key}-${item.label}`}>
              {item.label}
            </Box>
          )}
          renderInput={(params) => {
            return <TextField {...params} placeholder={label} size="small" />;
          }}
          sx={{ height: 40 }}
        />
      </Box>
    </>
  );
};

export default memo(CoreAutocomplete);
