export default class LocalStorageHelper {
  static getValue<T>(key: string, defaultValue: T): T {
    try {
      const storageValue: string | null = localStorage.getItem(key);
      let parsedValue = defaultValue;

      if (storageValue) {
        parsedValue = JSON.parse(storageValue) as T;
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue));
      }

      return parsedValue;
    } catch {
      localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    }
  }

  static setValue<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeProperty(key: string): void {
    localStorage.removeItem(key);
  }
}
