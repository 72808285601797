import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useApolloClient } from "@apollo/client";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { appRoutes } from "./configs/routes";
import CoreButton from "./components/core/CoreButton";
import { AppSuspense } from "./components/main/AppSuspense";
import { notifySentryEvent } from "./sentry/notifySentryEvent";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "20px",
  },
  action: {
    marginTop: "20px",
  },
});

const ErrorFallback: FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation("core");

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Paper className={classes.container}>
      <Typography className={classes.title}>
        {t("errorBoundary_title")}
      </Typography>
      <Typography className={classes.subtitle}>
        {t("errorBoundary_subtitle")}
      </Typography>

      <CoreButton
        className={classes.action}
        onClick={() => window.location.replace(appRoutes.Home())}
      >
        {t("errorBoundary_action_label")}
      </CoreButton>
    </Paper>
  );
};

export const ErrorBoundary: FC<{
  children: React.ReactNode;
  customFallback?: React.FC;
}> = ({ children, customFallback }) => {
  const apolloClient = useApolloClient();

  const handleError = (error: Error) => {
    // Send error to sentry
    notifySentryEvent(error, apolloClient);
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={customFallback ?? ErrorFallback}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
};
